import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { Container } from './styles';

import btc from '~/assets/icons/btc-icon.svg';

const BtcSignals: React.FC = () => {
  const [btnSelected, setBtnSelected] = useState('all');

  const sellHistory = [
    {
      date: '05 Jun 09:06 PM',
      prideDrop: '-8.3%',
      btcSold: '2,545',
      btcSignal: '51,323',
      btcNow: '41,923',
    },
    {
      date: '05 Jun 09:06 PM',
      prideDrop: '-8.3%',
      btcSold: '2,545',
      btcSignal: '51,323',
      btcNow: '41,923',
    },
    {
      date: '05 Jun 09:06 PM',
      prideDrop: '-8.3%',
      btcSold: '2,545',
      btcSignal: '51,323',
      btcNow: '41,923',
    },
  ];

  const recentActivity = [
    {
      exchange: 'BINANCE/ BTC',
      size: '1,290',
      buySell: 'Buy',
      reaction: '3.9%',
      volume: '12,232 BTC',
      time: '05 Apr 09:06 PM',
      action: 'Warning',
    },
    {
      exchange: 'COINBASE/ BTC',
      size: '2,500',
      buySell: 'Sell',
      reaction: '-8.9%',
      volume: '12,232 BTC',
      time: '05 Apr 09:06 PM',
      action: 'Sell',
    },
    {
      exchange: 'BINANCE/ BTC',
      size: '1,290',
      buySell: 'Buy',
      reaction: '3.9%',
      volume: '12,232 BTC',
      time: '05 Apr 09:06 PM',
      action: 'Warning',
    },
    {
      exchange: 'COINBASE/ BTC',
      size: '2,500',
      buySell: 'Sell',
      reaction: '-8.9%',
      volume: '12,232 BTC',
      time: '05 Apr 09:06 PM',
      action: 'Sell',
    },
    {
      exchange: 'BINANCE/ BTC',
      size: '1,290',
      buySell: 'Buy',
      reaction: '3.9%',
      volume: '12,232 BTC',
      time: '05 Apr 09:06 PM',
      action: 'Warning',
    },
    {
      exchange: 'COINBASE/ BTC',
      size: '2,500',
      buySell: 'Sell',
      reaction: '-8.9%',
      volume: '12,232 BTC',
      time: '05 Apr 09:06 PM',
      action: 'Sell',
    },
    {
      exchange: 'BINANCE/ BTC',
      size: '1,290',
      buySell: 'Buy',
      reaction: '3.9%',
      volume: '12,232 BTC',
      time: '05 Apr 09:06 PM',
      action: 'Warning',
    },
    {
      exchange: 'COINBASE/ BTC',
      size: '2,500',
      buySell: 'Sell',
      reaction: '-8.9%',
      volume: '12,232 BTC',
      time: '05 Apr 09:06 PM',
      action: 'Sell',
    },
  ];
  return (
    <Container>
      <div className="container container-xxl">
        <div className="row">
          <div className="col-12">
            <h1 className="mt-4 mb-5 pt-2">Activity Dashboard</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7 zoom">
            <div className="bg d-flex align-items-center justify-content-between">
              <img src={btc} alt="Bitcoin logo" className="btc-large" />
              <span className="all">$100,000.00</span>
              <div className="me-sm-4">
                <span className="triangle triangle-positive" />{' '}
                <span className="percentage positive">0.00%</span>
              </div>
            </div>
          </div>
          <div className="col-lg-5 zoom mt-4 mt-lg-0">
            <div className="bg h-100 py-4 px-4 px-md-5">
              <h2 className="text-center text-sm-start mt-2 mb-4">
                Price Movement
              </h2>
              <div className="d-sm-flex justify-content-between">
                <div className="d-flex d-sm-block align-items-center justify-content-center justify-content-sm-start mb-2 mb-sm-0">
                  <h3 className="mb-0 mb-sm-2">Today&nbsp;</h3>
                  <div className="">
                    <span className="triangle triangle-positive" />{' '}
                    <span className="percentage positive">0.00%</span>
                  </div>
                </div>
                <div className="d-flex d-sm-block align-items-center justify-content-center justify-content-sm-start mb-2 mb-sm-0">
                  <h3 className="mb-0 mb-sm-2">Yesterday&nbsp;</h3>

                  <div className="">
                    <span className="triangle triangle-positive" />{' '}
                    <span className="percentage positive">0.00%</span>
                  </div>
                </div>
                <div className="d-flex d-sm-block align-items-center justify-content-center justify-content-sm-start mb-2 mb-sm-0">
                  <h3 className="mb-0 mb-sm-2">Last Week&nbsp;</h3>
                  <div className="">
                    <span className="triangle triangle-negative" />{' '}
                    <span className="percentage negative">0.00%</span>
                  </div>
                </div>
                <div className="d-flex d-sm-block align-items-center justify-content-center justify-content-sm-start">
                  <h3 className="mb-0 mb-sm-2">Last Month&nbsp;</h3>
                  <div className="">
                    <span className="triangle triangle-negative" />{' '}
                    <span className="percentage negative">0.00%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 zoom">
            <div className="bg p-4 p-md-5 mt-4">
              <div className="d-lg-flex justify-content-between mb-2">
                <h2 className="text-center text-sm-start mb-0">
                  Sell Signal History
                </h2>
                <div className="btns-time text-center text-lg-start mt-5 mt-lg-0">
                  <button
                    type="button"
                    className={`${btnSelected === 'all' && 'active'}`}
                    onClick={() => setBtnSelected('all')}
                  >
                    All Time
                  </button>

                  <button
                    type="button"
                    className={`${btnSelected === 'three' && 'active'}`}
                    onClick={() => setBtnSelected('three')}
                  >
                    Last 3 Days
                  </button>
                  <button
                    type="button"
                    className={`${
                      btnSelected === 'seven' && 'active'
                    } mt-4 mt-sm-0`}
                    onClick={() => setBtnSelected('seven')}
                  >
                    Last 7 Days
                  </button>
                  <button
                    type="button"
                    className={`${
                      btnSelected === 'thirty' && 'active'
                    } mt-4 mt-sm-0`}
                    onClick={() => setBtnSelected('thirty')}
                  >
                    Last 30 Days
                  </button>
                </div>
              </div>
              <div className="row">
                {sellHistory.map((card) => (
                  <div className="col-lg-4">
                    <div className="bg-card p-4 mt-4">
                      <div className="d-flex justify-content-between">
                        <h4>{card.date}</h4>
                        <span className="circle red" />
                      </div>
                      <div className="d-flex py-2 px-3 mb-1">
                        <span className="w-50">
                          Price Drop:{' '}
                          <span className="fw-bold negative">
                            {card.prideDrop}
                          </span>
                        </span>
                        <span className="w-50 ps-3">
                          BTC Sold:{' '}
                          <span className="fw-bold negative">
                            {card.btcSold}
                          </span>
                        </span>
                      </div>
                      <div className="d-flex bg-btc-signal py-2 px-3">
                        <span className="w-50">
                          BTC Signal:{' '}
                          <span className="text-white fw-500">
                            ${card.btcSignal}
                          </span>
                        </span>
                        <span className="w-50 ps-3">
                          BTC Now:{' '}
                          <span className="fw-500 positive">
                            ${card.btcNow}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-12 zoom">
            <div className="bg p-4 p-md-5 mt-4">
              <div className="d-flex align-items-center justify-content-center justify-content-sm-start mb-3">
                <h2 className="mb-0">Recent Activity</h2>
                <button type="button" className="border-0 bg-transparent ms-3">
                  <IoIosArrowDown size={30} color="#dadada" />
                </button>
              </div>
              <div className="overflow-auto">
                <table>
                  <tr className="t-head d-flex">
                    <th className="text-center">EXCHANGE</th>
                    <th>Size</th>
                    <th>Buy / Sell</th>
                    <th>Market Reaction</th>
                    <th>Candle Volume</th>
                    <th>Time</th>
                    <th>Action</th>
                  </tr>
                  <tbody className="height d-block">
                    {recentActivity.map((activity) => (
                      <tr
                        className={`${
                          activity.action === 'Sell' && 'bd-sell'
                        } t-row d-flex`}
                      >
                        <td className="d-flex align-items-center">
                          <img src={btc} alt="Btc logo" />
                          {activity.exchange}
                        </td>
                        <td>{activity.size}</td>
                        <td
                          className={`${
                            activity.buySell === 'Buy' ? 'positive' : 'negative'
                          }`}
                        >
                          {activity.buySell}
                        </td>
                        <td
                          className={`${
                            activity.buySell === 'Buy' ? 'positive' : 'negative'
                          }`}
                        >
                          {activity.reaction}
                        </td>
                        <td>{activity.volume}</td>
                        <td>{activity.time}</td>
                        <td
                          className={`${
                            activity.action === 'Warning'
                              ? 'warning'
                              : 'negative'
                          } d-flex justify-content-between align-items-center`}
                        >
                          {activity.action}{' '}
                          <div
                            className={`${
                              activity.action === 'Warning' ? 'yellow' : 'red'
                            } circle`}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BtcSignals;
