import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IBoxShared {
  background?: string;
}

export const Modal = styled(ModalComponent)`
  .w-calc {
    //width: calc(100% + 0.5px);
    width: 476px;
    height: 267px;
  }

  .footer {
    color: #d4d4d4;
  }
  .text-ai {
    left: 0;
    top: 0;
  }
  .logo-ai-large {
    width: 15%;
  }

  .logo-ai-text-large {
    width: 50%;
    height: 27px;
  }

  .logo-ai-mid {
    width: 10%;
  }

  .logo-ai-text-mid {
    width: 35%;
    height: 15px;
  }

  .logo-ai-horizontal {
    width: 20%;
  }

  .logo-ai-text-horizontal {
    width: 100%;
  }
  .image-size {
    border-radius: 15px;
    border: 1px solid #3f3d3d;
    //width: 100%;
  }

  .header-img {
    background: rgba(100, 97, 97, 0.12);
    backdrop-filter: blur(7.5px);
    border-radius: 15px 15px 0 0;
    width: 100%;
  }

  .header-img div:nth-child(1) {
    width: 24px;
    height: 24px;
    background: rgba(100, 97, 97, 0.34);
    backdrop-filter: blur(7.5px);
    border-radius: 200px;
    margin: 9px;
  }

  .header-img div:nth-child(2) {
    width: 68px;
    height: 14px;
    background: rgba(100, 97, 97, 0.34);
    backdrop-filter: blur(7.5px);
    border-radius: 200px;
  }

  .text-size span:nth-child(1),
  .text-size span:nth-child(2) {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: block;
  }

  .text-size span:nth-child(1) {
    color: #595757;
  }

  .text-size span:nth-child(2) {
    color: #9e9e9e;
  }

  .footer-img {
    background: rgba(100, 97, 97, 0.12);
    backdrop-filter: blur(7.5px);
    border-radius: 0 0 15px 15px;
    width: 100%;
  }

  .footer-img div {
    width: 14px;
    height: 14px;
    border-radius: 20px;
    background: rgba(100, 97, 97, 0.34);
    backdrop-filter: blur(7.5px);
  }

  .btns-down-share {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 15px;

    line-height: 20px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    left: -1px;
    top: -1px;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease;
    :hover {
      opacity: 1;
      filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.2));
      border: 1px solid #3f3d3d;
    }
  }

  .btns-down-share button {
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    border: none;
    height: 35px;
    padding: 0 13px;
    margin: 0 5px;
  }
  .title-choose {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 48px;
    color: #dadada;
  }
  .img-nft {
    border-radius: 30px;
  }

  p {
    font-family: 'Quicksand';
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #d4d4d4;
  }

  .btns-share button,
  .btns-share a {
    background: #1c1c1d;
    margin-bottom: 10px;
    backdrop-filter: blur(15px);
    border-radius: 25px;
    border: none;
    padding: 24px;
  }

  .btns-share div {
    font-weight: 300;
    font-size: 18px;
    color: #7d7777;
  }

  .btn-like {
    position: absolute;
    bottom: 30px;
    left: 46px;
  }

  .btn-dislike {
    position: absolute;
    bottom: 30px;
    left: 103px;
    transform: rotate(180deg);
  }

  .btn-launchpad {
    background: #00f9ab;
    border-radius: 20px;
    color: #000;
    font-size: 24px;
    transition: all 0.3s ease;
  }

  .btn-launchpad:hover {
    opacity: 0.8;
  }

  .modal-xl {
    max-width: 1300px;
  }

  .padding-modal {
    padding: 0 60px 60px 60px;
  }

  .right-side {
    border: 1px solid #6b6161;
    border-radius: 30px;
  }

  .right-side img {
    height: 40px;
    width: 40px;
  }
  .modal-close {
    color: #fff;
    height: 50px;
    width: 50px;
    right: 15px;
  }

  .small {
    font-size: 10px;
  }

  @media screen and (min-width: 992px) {
    .modal-lg {
      max-width: 600px;
      width: 30vw;
    }
  }
`;

export const BoxShared = styled.div<IBoxShared>`
  padding: 29px;
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: none;
  text-align: left;
  width: 100%;
  border-radius: 15px;

  .final-image {
    font-family: 'Quicksand';
    background: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #bebebe;
    left: 0;
    top: 0;
    border: none;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease;
    :hover {
      opacity: 1;
    }
  }

  .logo {
    width: 70%;
  }

  .nft {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
    backdrop-filter: blur(5px);
    padding: 5px;
    border-radius: 20px;

    > div {
      border-radius: 18px;
      overflow: hidden;
      position: relative;

      > img {
        width: 100%;
      }

      > div {
        position: absolute;
        background: rgba(255, 255, 255, 0.33);
        backdrop-filter: blur(7.5px);
        bottom: 0;
        width: 100%;
        padding: 7px 7px 7px 10px;

        p {
          //width: calc(100% - 60px);
          font-size: 13px;
          color: #060606;
          //margin-bottom: 5px !important;
          padding-right: 10px;

          span {
            font-size: 12px;
          }
        }

        .bg-qr-code {
          width: 60px;
          height: 60px;
          background: rgba(255, 255, 255, 0.8);
          border: 1px solid rgba(0, 0, 0, 0.62);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 7px;
          opacity: 0.7;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .small {
    font-size: 10px;
  }

  .footer {
    color: #d4d4d4;
  }
  .details p {
    line-height: 20px;
  }
`;

export const ImgShared = styled.div`
  .margin-nft-large {
    margin: 10px;
  }

  .margin-nft-mid {
    margin: 5px 35px;
  }

  .margin-nft-horizontal {
    margin: 5px;
  }

  .nft {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
    backdrop-filter: blur(5px);
    padding: 5px;
    border-radius: 20px;

    > div {
      border-radius: 18px;
      overflow: hidden;
      position: relative;

      > img {
        width: 100%;
      }

      > div {
        position: absolute;
        background: rgba(255, 255, 255, 0.33);
        backdrop-filter: blur(7.5px);
        bottom: 0;
        width: 100%;
        padding: 7px 7px 7px 10px;

        .scan-large {
          font-size: 13px;
          color: #060606;
          //margin-bottom: 5px !important;
          padding-right: 10px;

          span {
            font-size: 15px;
          }
        }

        .scan-mid {
          font-size: 10px;
          color: #060606;
          //margin-bottom: 5px !important;
          padding-right: 10px;
          line-height: 15px;

          span {
            font-size: 12px;
          }
        }

        .scan-horizotal {
          font-size: 9px;
          color: #060606;
          //margin-bottom: 5px !important;
          padding-right: 10px;
          line-height: 15px;

          span {
            font-size: 10px;
          }
        }

        .bg-qr-code {
          background: rgba(255, 255, 255, 0.8);
          border: 1px solid rgba(0, 0, 0, 0.62);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 7px;
          opacity: 0.7;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .qr-code-large {
          width: 60px;
          height: 60px;
        }
        .qr-code-mid {
          width: 45px;
          height: 45px;
        }
        .qr-code-horizontal {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .small {
    font-size: 10px;
  }

  .footer {
    color: #d4d4d4;
  }
  .details p {
    line-height: 20px;
  }
`;
