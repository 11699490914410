import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';
import { Form } from '@unform/web';
import bgNft from '~/assets/banners/bg-modal.png';
import bgNftDark from '~/assets/banners/bg-modal-dark.png';
import light from '~/assets/defaults/light.svg';
import next from '~/assets/icons/arrow-next-carousel.svg';
import prev from '~/assets/icons/arrow-prev-carousel.svg';

interface IModelProps {
  showNft: boolean;
}

export const Container = styled(Form)`
  background-color: #18191a;
  background-position: center;
  background-size: cover;
  min-height: 100vh;

  h1 {
    background: linear-gradient(269.95deg, #fff 0.02%, #fff 97.45%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;

    > span {
      background: linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      );
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      font-weight: 900;
    }
  }

  h2 {
    color: #f2f2f2;
    font-weight: 500 !important;
  }

  h3 {
    font-weight: 400;
    font-size: 18px;
    color: #787878;
  }

  h4 {
    font-weight: 400;
    font-size: 20px;
    color: #c9c9c9;
  }

  h3,
  h4,
  textarea {
    font-family: 'Poppins', sans-serif;
  }

  .mg-left {
    margin-left: 17.5%;
  }

  .history-btn {
    margin-bottom: 10px;
    padding: 10px 30px;
    font-weight: 300;
    font-size: 14px;
    background: rgba(31, 32, 32, 0.67);
    border: 1px solid #282929;
    backdrop-filter: blur(5px);
    border-radius: 14px;
    color: #8d8d8d;
  }

  .hover div p,
  .hover div button {
    font-family: 'Quicksand', sans-serif;
    opacity: 0;
    transition: all 0.3s ease;
    font-size: 16px;
    color: #d4d4d4;
    font-weight: 300;
    margin: 0px;
  }

  .hover div button {
    background: rgba(152, 148, 148, 0.1);
    padding: 15px 20px;
    backdrop-filter: blur(15px);
    border-radius: 15px;
    font-weight: 600;
  }

  .hover div {
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;

    transition: all 0.3s ease;
    :hover {
      background-color: rgba(0, 0, 0, 80%);
      border-radius: 15px;
      p,
      button {
        opacity: 1;
      }
    }
  }

  .textarea {
    height: unset;

    textarea {
      color: #787878;
    }
  }

  .sample img {
    border-radius: 16.5px;
  }

  .area-send {
    bottom: 0px;
    right: 0px;
    width: 83.2%;
    padding: 0px 10px 10px 25px;
    background: linear-gradient(180deg, rgba(24, 25, 26, 0) 0%, #18191a 63%);
  }

  .description button {
    border: none;
    background: transparent;
    font-family: 'Poppins', sans-serif;

    font-weight: 400 !important;
    font-size: 12px;
    color: #c4c4c4;
    text-align: start;
  }

  .send {
    border: none;
    background-color: transparent;
    transition-duration: 0.3s;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    color: #c4c4c4;
  }

  .send:focus,
  .send:hover {
    color: ${lighten(0.1, '#c4c4c4')}!important;
  }

  input {
    resize: none;
    background-color: transparent;
    color: #d4d4d4 !important;
    font-weight: 400 !important;
    border: none;
    ::placeholder {
      color: #5a5b5b !important;
    }
  }

  .border-textarea {
    width: 65%;
    background: #1f2020;
    border: 1px solid #282929;
    box-shadow: 1px 16px 30px rgba(0, 0, 0, 0.1);
    border-radius: 22px;
    height: 82px;
  }

  .bg-imgs {
    background-color: #212122;
    border-radius: 30px;
    padding: 34px;
  }

  .bg-imgs img {
    border-radius: 24px;
  }

  .description {
    bottom: 3%;
    height: 64px;
    background: rgba(23, 23, 25, 0.5);
    border: 2px solid #707070;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(13.5914px);
    border-radius: 20px;
    width: 94%;
    left: 3%;
    display: flex;
    align-items: center;
  }

  .bg-imgs button {
  }

  .logo {
    right: 2rem;
    top: 2rem;
    z-index: 1;
  }

  .text-gray {
    color: #b7b7b7;
  }

  .timeline-box {
    > div {
      height: 100%;
    }
  }

  @media screen and (max-width: 1799px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    button {
      font-size: 20px;
    }
  }

  @media screen and (min-width: 2160px) {
    .max-width {
      max-width: 1800px;
      margin: 0 auto;
    }
  }
`;

export const Modal = styled(ModalComponent)<IModelProps>`
  backdrop-filter: blur(10px);
  .modal-content {
    background-color: #000;
    background-image: url(${bgNft});
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
    border: none;
    overflow: hidden;
    position: relative;
    :before {
      content: '';
      background: url(${light});
      width: 610px;
      height: 452px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      transition-duration: 0.1s;
      opacity: ${(props) => (props.showNft ? 1 : 0)};
      visibility: ${(props) => (props.showNft ? 'visible' : 'hidden')};
      z-index: 2;
    }
    :after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url(${bgNftDark});
      background-position: bottom;

      transition-duration: 0.3s;
      opacity: ${(props) => (props.showNft ? 0 : 1)};
      visibility: ${(props) => (props.showNft ? 'hidden' : 'visible')};
    }
  }

  .min-height {
    min-height: 470px;
  }

  .bg-main {
    background: rgba(23, 23, 25, 0.46);
    box-shadow: 0px -15px 30px rgba(0, 0, 0, 0.265139);
    backdrop-filter: blur(5px);
    border-radius: 37px 37px 0px 0px;
    width: 100%;
  }

  .try-again {
    background: rgba(26, 27, 29, 0.7);
    border: 2px solid #393c3d;
    border-radius: 22px;
    color: #f2f2f2;
    font-weight: 600;
    padding: 22px 33px;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
  }

  .btn-mint {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 22px;
    font-weight: 600;
    padding: 22px 49px;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    color: #000;
  }

  .bg-nft {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border: 1px solid ${(props) => (props.showNft ? '#fff' : '#000')};
    border-radius: 40px;
    //height: 470px;
    width: 100%;
    position: relative;
    z-index: 1;
    :after {
      border-radius: 40px;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0);

      transition-duration: 0.3s;
      opacity: ${(props) => (props.showNft ? 0 : 1)};
      visibility: ${(props) => (props.showNft ? 'hidden' : 'visible')};
    }
  }

  .bg-nft img {
    //height: 70%;
  }

  .bg-nft span {
    color: #fffefb;
    font-size: 35px;
    line-height: 50px;
  }

  .bg-serial-qrcode {
    position: absolute;
    bottom: 0.5rem;
    left: 8px;
    width: 512px;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(7.5px);

    border-radius: 0px 0px 35px 35px;
  }

  .bg-qrcode {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.62);
    border-radius: 25px;
  }

  .nft {
    filter: drop-shadow(5px 11px 140px rgba(0, 0, 0, 0.25));
    border-radius: 35px;
  }

  .w-60 {
    width: 60%;
  }

  .w-30 {
    width: 30%;
  }

  .header img {
    height: 52px;
    width: 52px;
  }

  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  .modal-xl {
    min-width: 1440px;
  }

  .btn-nft {
    color: #fff;
    padding: 18px;
    font-size: 24px;
    border-radius: 20px;
    background-color: #0c0d0d;
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .btn-nft {
      font-size: 20px;
    }

    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 575px) {
    .bg-nft,
    .bg-main {
      width: 329px;
    }
  }

  @media screen and (max-width: 450px) {
    .btn-nft {
      padding: 18px 10px;
      font-size: 18px;
    }
  }
`;

export const ModalHistory = styled(ModalComponent)`
  .slick-next:before {
    content: url(${next});
  }
  .slick-prev:before {
    content: url(${prev});
  }
  .slick-dots {
    bottom: -120px;
  }

  .slick-dots li.slick-active button:before {
    color: #c4c4c4;
    font-size: 15px;
  }

  .slick-dots li button:before {
    color: rgba(152, 148, 148, 0.1);
    opacity: 1;
    font-size: 15px;
  }

  .slick-next {
    right: calc(50% - 100px);
    top: unset;
    bottom: -100px;
    width: 60px;
    height: 60px;
    background: rgba(152, 148, 148, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 20px;
  }

  .slick-prev {
    left: calc(50% - 100px);
    top: unset;
    bottom: -100px;
    width: 60px;
    height: 60px;
    background: rgba(152, 148, 148, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 20px;
  }
  .hover img {
    border-radius: 16.5px;
  }
  .hover div p,
  .hover div button {
    font-family: 'Quicksand', sans-serif;
    opacity: 0;
    transition: all 0.3s ease;
    font-size: 16px;
    color: #d4d4d4;
    font-weight: 300;
    margin: 0px;
  }

  .hover div button {
    background: rgba(152, 148, 148, 0.1);
    padding: 15px 20px;
    backdrop-filter: blur(15px);
    border-radius: 15px;
    font-weight: 600;
  }

  .hover .hover-btns {
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;

    transition: all 0.3s ease;
    :hover {
      background-color: rgba(0, 0, 0, 80%);
      border-radius: 15px;
      p,
      button {
        opacity: 1;
      }
    }
  }
  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  @media screen and (min-width: 992px) {
    .modal-xl {
      --bs-modal-width: 85%;
    }
  }

  @media screen and (min-width: 1700px) {
    .modal-xl {
      max-width: 1600px;
    }
  }

  @media screen and (max-width: 1799px) {
    /* .modal-dialog {
      zoom: 0.8;
    } */
  }

  @media screen and (max-width: 1399px) {
    /* .modal-dialog {
      zoom: 0.7;
    } */
  }
`;

export const LoadingDiscover = styled.div`
  .loading-box {
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.25) -38.97%,
      rgba(0, 0, 0, 0.25) 75.53%
    );
    backdrop-filter: blur(25px);
    span {
      font-weight: 400;
      font-size: 20px;
      color: #fff;
      text-align: center;
    }
  }

  .animation-loading {
    height: 152px;
    width: 152px;

    img {
      position: relative;
      z-index: 300;
      top: 10px;
      left: 10px;
      height: 134px;
      width: 134px;
      background: rgba(0, 0, 0);
      backdrop-filter: blur(5px);
    }
  }

  .box-1 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: #fff;
    animation: position-box-1 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-1 {
    0% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    25% {
      top: calc(100% - 37px);
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    50% {
      top: calc(100% - 114px);
      left: 0;
      width: 37px;
      height: 114px;
    }

    75% {
      top: 0;
      left: 0;
      width: 114px;
      height: 37px;
    }

    100% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }
  }

  .box-2 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: #fff;
    animation: position-box-2 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-2 {
    0% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }

    25% {
      top: 0;
      left: 0;
      width: 37px;
      height: 114px;
    }

    50% {
      top: 0;
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    75% {
      top: calc(100% - 114px);
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    100% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }
  }
`;
