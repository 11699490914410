import styled from 'styled-components';

import { Modal as ModalComponent } from 'react-bootstrap';

export const ModalBefore = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    p {
      font-weight: 300;
      font-size: 20px;

      color: #dadada;
      span {
        font-weight: 700;
      }
    }

    .more {
      background: #a3c0e1;
      border-radius: 10px;
      border: none;
      font-weight: 700;
      font-size: 10px;
      padding: 8px 15px;
      color: #0927c6;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .texts p:nth-child(1) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 22px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts .terms {
      background: #1d1d1f;
      border-radius: 12px;
      font-weight: 300;
      font-size: 16px;
      padding: 10px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: #7d7777;
      }
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-confirm {
      background: #696ffd;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-cancel {
      background: #2b3247;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .texts p:nth-child(1) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 24px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts p:nth-child(2) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 20px;
      color: #7d7777;
    }

    .texts div:nth-child(3) {
      border: 1px solid #4f4f4f;
      border-radius: 20px;
      font-weight: 300;
      font-size: 14px;
      align-items: center;
      text-align: center;

      padding: 23px;
      a {
        color: #8767ed;
      }
    }

    .texts .terms {
      background: #1d1d1f;
      border-radius: 12px;
      font-weight: 300;
      font-size: 16px;
      padding: 10px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: #7d7777;
      }
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-confirm {
      background: #696ffd;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
  }
`;
