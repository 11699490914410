import React from 'react';

import { Container, Retangle } from './styles';
import { useResize } from '~/hooks/Resize';
import bubble1 from '~/assets/defaults/bubble_1.svg';
import bubble2 from '~/assets/defaults/bubble_2.svg';
import sample1 from '~/assets/defaults/sample_1.png';
import qrCode from '~/assets/defaults/qr-code-nft.svg';
import doubleArrow from '~/assets/icons/doubleArrow.svg';

const LpNft: React.FC = () => {
  const resize = useResize();
  const widthScreen = resize.width;

  return (
    <Container>
      <div className="banner position-relative">
        <Retangle width={widthScreen} className="retangle" />
        <div className="container">
          <div className="row align-items-center zoom">
            <div className="col-lg-5">
              <h1>
                WELCOME TO PROFIT<span>i</span>
              </h1>
              <h2>
                The decentralized community platform where rewards meets
                innovation.
              </h2>
              <div className="d-flex first-bubble my-5">
                <img src={bubble1} alt="bubble" />
                <h3 className="px-5">
                  Congratulations, you have just scanned an NFT IMAGE that was
                  created with PROFITi’s AI!
                  <br />
                  <span className="text-grad">
                    We are the leading provider of cutting-edge AI products.
                  </span>
                </h3>
              </div>
              <div className="d-flex second-bubble">
                <img src={bubble2} alt="bubble" />
                <h3 className="ps-5 ps-4">
                  Whether you're a beginner or experienced,{' '}
                  <span>
                    our suite of innovative no-code tools, powered by Artificial
                    Intelligence
                  </span>
                  , makes it easy for anyone to create images, NFTs, copy, and
                  much more!
                </h3>
              </div>
            </div>

            <div className="col-7 pt-5">
              <div className="rings d-flex align-items-center">
                <div className="right-side">
                  <div className="bg-nft p-2 ">
                    <div className="position-relative">
                      <img
                        src={sample1}
                        alt="NFT Generated"
                        className="w-100 nft"
                      />

                      <div className="d-flex pt-4 pb-3 bg-serial-qrcode w-100 justify-content-center align-items-center">
                        <div className="w-60">
                          <span>
                            Serial #<br />
                            <span className="fw-bold">00001234</span>
                          </span>
                        </div>
                        <div className="w-30 text-end">
                          <img
                            src={qrCode}
                            alt="Qr Code"
                            className="w-75 qr-code bg-qrcode p-3"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex w-100 mx-auto align-items-center position-absolute bottom">
              <div className="w-50">
                <div className="d-flex justify-content-between">
                  <span className="text-get-free">
                    Try PROFIT<span>i</span> today & get a FREE NFT!
                  </span>
                  <img src={doubleArrow} alt="Arrow" />
                </div>
              </div>
              <div className="w-40 ps-5">
                <div className="d-flex mx-auto px-4 py-3 justify-content-evenly bg-main">
                  <button type="button" className="w-100 btn-mint ms-2">
                    CREATE YOUR FREE NFT NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LpNft;
