import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    color: #dadada;
  }

  .bg-nft {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    border-radius: 40px;
  }

  .bg-nft img {
    height: 70%;
  }

  .bg-nft span {
    color: #1b1a18;
    font-size: 22px;
    line-height: 20px;
  }

  .nft {
    filter: drop-shadow(5px 11px 140px rgba(0, 0, 0, 0.25));
    border-radius: 35px;
  }

  .w-60 {
    width: 60%;
  }

  .w-30 {
    width: 30%;
  }
`;
