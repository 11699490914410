import React, { useCallback, useState, useEffect } from 'react';
import {
  ethers,
  utils,
  ContractReceipt,
  ContractTransaction,
  BigNumber,
} from 'ethers';

import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Container, Retangle, Modal } from './styles';
import { useResize } from '~/hooks/Resize';
import logo from '~/assets/logo/logo-p-gray.svg';
import bubble2 from '~/assets/defaults/bubble_2.svg';
import email from '~/assets/logo/email.png';
import metamask from '~/assets/logo/metamask.png';
import swalError from '~/utils/swalError';
import { web3store } from '../../store';
import api from '~/services/api';

const SignupAutoaffiliate: React.FC = () => {
  // console.log('+++++ SignupAutoaffiliate +++++');
  const [show, setShow] = useState(false);
  const resize = useResize();
  const widthScreen = resize.width;
  const [funnelType, setFunnelType] = web3store.useState('funnelType');
  const history = useHistory();
  const [account, setAccount] = web3store.useState('account');
  const { reference, placementRef, username } =
    useParams<{ reference: string; placementRef: string; username: string }>();
  // path={`${process.env.PUBLIC_URL}/aa/:reference/:placementRef?/:username?`}
  const [sponsorName, setSponsorName] = useState<string | null>(null);

  useEffect(() => {
    // setFunnelType('AA');
    if (reference === undefined || username === undefined) {
      swalError({
        message:
          'Invalid referral link.  Please be sure to use the link provided by the Autoaffiliate dashboard.',
        textButton: 'Try Again',
      });
    }
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleMetamask = useCallback(async () => {
    if (reference === undefined || username === undefined) {
      swalError({
        message:
          'Invalid referral link.  Please be sure to use the link provided by the Autoaffiliate dashboard.',
        textButton: 'Try Again',
      });
    } else {
      // Check if MetaMask is available
      // console.log('checking for metamask');
      if (typeof window.ethereum === 'undefined') {
        swalError({
          message: 'MetaMask is not available.',
          textButton: 'Try Again',
        });
        return;
      }

      // Request access to the MetaMask accounts
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        // Get the selected account address
        const signer = provider.getSigner();
        const selectedAddress = await signer.getAddress();
        // get nft list
        api
          .get('/v1/nft/list', {
            params: {
              registered: 2,
              owner: selectedAddress,
            },
          })
          .then(function (nftresponse) {
            // console.log(nftresponse.data.nft_list);
            if (nftresponse.data.nft_list.length === 0) {
              // log referral code
              api
                .post('/v2/aa/member/prospect', {
                  ref: reference,
                  place: placementRef,
                  username,
                  account: selectedAddress,
                })
                .then((response) => {
                  // console.log(response.data.result);
                  if (response.data.result === 'REGISTERED') {
                    swalError({
                      message: `Sorry, the username (${username}) has already been registered in Profiti.`,
                      textButton: 'Try Again',
                    });
                  } else if (response.data.result === 'BADREF') {
                    swalError({
                      message: `Sorry, ${reference} is not a valid Profiti referral code.`,
                      textButton: 'Try Again',
                    });
                  } else {
                    // ---------------------------------------------------
                    // AVAILABLE
                    // ---------------------------------------------------
                    // console.log(
                    //   '+++++ sponsor_name: %s +++++',
                    //   response.data.sponsor_name
                    // );
                    const paramData = {
                      selectedAddress,
                      reference,
                      placementRef,
                      username,
                      sponsorName: response.data.sponsor_name,
                      sponsorUsername: response.data.sponsor_username,
                    };
                    history.push({
                      pathname: '/autoaffiliate-member/order',
                      state: paramData,
                    });
                    // ---------------------------------------------------
                    // ---------------------------------------------------
                  }
                })
                .catch((error) => {
                  swalError({
                    message:
                      'Sorry, there was a problem checking your referral link.',
                    textButton: 'Try Again',
                  });
                });
            } else {
              console.log('has nft id: %s', nftresponse.data.nft_list[0]);
              // check member status
              // If member, setaccount
              // if not, error (mint only - ignore for now)
              setAccount(selectedAddress);
            }
          })
          .catch(function (error) {
            console.log('================= ERROR ===================');
            console.log(error);
          });
      } catch (error) {
        swalError({
          message: 'Connection to MetaMask failed.',
          textButton: 'Try Again',
        });
        console.error(error);
      }
    }
  }, []);

  return (
    <Container>
      <div className="banner position-relative">
        <Retangle
          width={widthScreen}
          className="d-flex flex-column justify-content-center retangle"
        >
          <img src={logo} alt="Logo" className="zoom" />
          <div className="d-flex mx-auto p-4 justify-content-evenly bg-main">
            <button
              type="button"
              className="btn-mint zoom"
              onClick={() => setShow(true)}
            >
              CONNECT WALLET TO SIGN UP
            </button>
          </div>
        </Retangle>
        <div className="container">
          <div className="row">
            <div className="col-12 rings d-flex align-items-center zoom">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h2 className="hello ">
                    Hello, <span className="fw-600">AutoAffiliate</span> member!
                    👋🏻
                  </h2>
                  <h1 className="">
                    WELCOME TO PROFIT<span>i</span>
                  </h1>
                  <h2 className="w-75 pt-3 ">
                    The decentralized community platform{' '}
                    <span className="fw-600">
                      where rewards meets innovation.
                    </span>
                  </h2>

                  <div className="d-flex align-items-center second-bubble mt-5">
                    <img src={bubble2} alt="bubble" className="" />
                    <h3 className="ps-5 ps-4 mb-0 ">
                      Whether you're a beginner or experienced,{' '}
                      <span>
                        our suite of innovative no-code tools, powered by
                        Artificial Intelligence
                      </span>
                      , makes it easy for anyone to create images, NFTs, copy,
                      and much more!
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        size="md"
        show={show}
        onHide={handleClose}
        className="modal-signin-auto"
      >
        <Modal.Header className="border-0 py-2" />
        <Modal.Body className="px-4 px-sm-5 body">
          {false && (
            <>
              <div className="text-center">
                <button type="button" className="mt-4">
                  <img src={email} alt="Email" className="d-block mx-auto" />
                  Use Your Email
                </button>
              </div>
              <hr className="my-4" />
            </>
          )}
          <div className="text-center my-5">
            <button type="button" className="mt-4" onClick={handleMetamask}>
              <img src={metamask} alt="Metamask" className="d-block mx-auto" />
              Use MetaMask Wallet
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-2" />
      </Modal>
    </Container>
  );
};

export default SignupAutoaffiliate;
