import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';

export const Container = styled.header`
  z-index: 202;

  .btn-wallet {
    color: #000;
    transition-duration: 0.3s;
    :hover {
      color: #7746fb;
    }
  }

  .width {
    width: 65px;
    height: 65px;
  }

  .row-logo {
    transition-duration: 0.3s;
  }

  .bg-black {
    background-color: #000;
    padding: 8px 41px;
    border-radius: 20px;
    :hover {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 991px) {
    .width {
      width: 45px;
      height: 45px;
    }
  }

  @media screen and (max-width: 575px) {
  }
`;

export const Modal = styled(ModalComponent)`
  z-index: 2000;

  .animation-gray {
    background-color: rgba(244, 247, 253, 0.5);
    border-radius: 30px;
    padding: 8px;

    div {
      width: 198px;
      height: 198px;
      border-radius: 30px;
      background-color: #fff;
    }
  }

  .description {
    padding: 3rem 12rem;

    h3 {
      color: #000000;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 35px;
    }

    p {
      color: #000000;
      font-size: 18px;
    }
  }

  .social-medias {
    background: #0c0d0d;
    backdrop-filter: blur(5px);
    border-radius: 60px;
  }
`;
