import React, { useState, useCallback } from 'react';

import { Container } from './styles';

import playIcon from '~/assets/icons/play.png';

interface IPlayer {
  src: string;
  thumbnail: string;
}

const Player: React.FC<IPlayer> = ({ src, thumbnail }) => {
  const [play, setPlay] = useState(false);

  const handleClick = useCallback(() => {
    const vid = document.getElementById('video') as HTMLVideoElement;
    if (vid) {
      if (!play) {
        vid.play();
      } else {
        vid.pause();
      }
    }
    setPlay((state) => !state);
  }, [play]);

  return (
    <Container className="video w-100 position-relative">
      <video src={src} poster={thumbnail} onClick={handleClick} id="video">
        <track default kind="captions" srcLang="pt-BR" src={src} />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <button
        type="button"
        className={`${
          play ? 'd-none' : 'd-block'
        } position-absolute border-0 bg-transparent zoom w-100 h-100`}
        onClick={handleClick}
      >
        <img src={playIcon} alt="Play" className="w-25 w-xl-auto" />
      </button>
    </Container>
  );
};

export default Player;
