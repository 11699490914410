import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Player from '~/components/Player';

import { Button, Modal } from './styles';

import thumb from '~/assets/defaults/thumb-congratulations.png';
import telegram from '~/assets/defaults/telegram.svg';
import discord from '~/assets/defaults/discord.svg';
import twitter from '~/assets/defaults/twitter.svg';
import tikTok from '~/assets/defaults/tik-tok.svg';
import facebook from '~/assets/defaults/facebook.svg';
import instagram from '~/assets/defaults/instagram.svg';
import youtube from '~/assets/defaults/youtube.svg';
import reddit from '~/assets/defaults/reddit.svg';

interface IModalCongratulations {
  showModal: boolean;
}

const ModalCongratulations: React.FC<IModalCongratulations> = ({
  showModal,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const handleClose = useCallback(() => {
    setShow(false);
    localStorage.removeItem('wasNoWish');
  }, []);

  useEffect(() => {
    if (showModal) {
      setShow(true);
    }
  }, [showModal]);

  return (
    <>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        className="modal-signin"
      >
        <button
          type="button"
          className="h4 modal-close m-3 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>

        <Modal.Header className="container border-0">
          <div className="row justify-content-center">
            <div className="col-11 col-sm-10 col-xl-9 text-center">
              <h2 className="fw-bold mb-4">Congratulations!</h2>
              <h3 className="px-3 px-lg-5">
                Your account has been successfully activated, you can log in to
                your dashboard by clicking the button&nbsp;below
              </h3>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="container">
          <div className="row px-4 pb-4 px-lg-3 px-xl-5 justify-content-center">
            <div className="col-11 col-lg-10 col-xl-9 coin">
              <Player
                src="https://prfti.s3.amazonaws.com/5.mov"
                thumbnail={thumb}
              />
            </div>
            <div className="col-11 col-lg-10 col-xl-9">
              <button
                type="button"
                className="w-100 btn to-dashboard mt-4 fw-bold"
                onClick={handleClose}
              >
                GO TO DASHBOARD
              </button>
            </div>
            <div className="col-xl-11 mt-4 overflow-auto bg-lg-social-medias">
              <div className="d-lg-flex justify-content-sm-around justify-content-lg-center social-medias  px-0 px-lg-5">
                <div className="bg-mobile-social-medias text-center px-2 px-lg-0">
                  <a
                    target="_blank"
                    href="https://t.me/ProfitiAi"
                    rel="noreferrer"
                  >
                    <img src={telegram} alt="Telegram" />
                  </a>
                  <a
                    target="_blank"
                    href="https://discord.gg/KxdwygBsKk"
                    rel="noreferrer"
                  >
                    <img src={discord} alt="Discord" />
                  </a>
                  <a
                    target="_blank"
                    href="https://twitter.com/profitiai"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt="Twitter" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.tiktok.com/@profitiai"
                    rel="noreferrer"
                  >
                    <img src={tikTok} alt="Tik-Tok" />
                  </a>
                </div>
                <div className="bg-mobile-social-medias text-center px-2 px-lg-0 mt-3 mt-lg-0">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/profitiai"
                    rel="noreferrer"
                  >
                    <img src={facebook} alt="Facebook" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/profitiai/"
                    rel="noreferrer"
                  >
                    <img src={instagram} alt="Instagram" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/@profitiai/channels"
                    rel="noreferrer"
                  >
                    <img src={youtube} alt="Youtube" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.reddit.com/r/profitiai/"
                    rel="noreferrer"
                  >
                    <img src={reddit} alt="Reddit" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCongratulations;
