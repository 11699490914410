/* eslint-disable new-cap */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { IoSunnyOutline, IoMoonOutline } from 'react-icons/io5';
import { BsArrowReturnRight } from 'react-icons/bs';
import { Form } from '@unform/web';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FiCompass, FiDownload, FiHelpCircle } from 'react-icons/fi';
import { AiOutlineCheck, AiOutlineEdit } from 'react-icons/ai';
import { TbTrash } from 'react-icons/tb';
import { HiOutlineRefresh } from 'react-icons/hi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { MdOutlineStopCircle } from 'react-icons/md';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import hljs from 'highlight.js';
import 'highlight.js/styles/vs2015.css';

import { web3store } from '~/store';
import like from '~/assets/icons/like-chatbot.svg';
import paigeTitle from '~/assets/defaults/paige-chatbot.png';
import logoWhite from '~/assets/logo/logo-p-white.svg';

import getValidationErros from '~/utils/getValidationsErrors';

import {
  Container,
  Menu,
  Sidebar,
  Content,
  Messages,
  Message,
  Avatar,
  Settings,
} from './styles';
import Input from '~/components/Input';
import Typist from '~/components/Typist';

import list from '~/assets/icons/list.svg';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';
import Loading from '~/components/Loading';

interface IParams {
  slug?: string;
}

interface ITalk {
  id: number;
  title: string;
  edit?: boolean;
}

interface IMessage {
  id: number;
  avatar: string;
  message: string;
  new?: boolean;
}

interface IFormData {
  thread: string;
  prompt: string;
}

const Chatbot: React.FC = () => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const chatListRef = useRef<HTMLDivElement>(null);
  const deleteChatRef = useRef<HTMLDivElement>(null);
  const exitPaigeRef = useRef<HTMLDivElement>(null);
  const params = useParams<IParams>();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [theme, setTheme] = useState<'dark' | 'light'>('dark');
  const [talks, setTalks] = useState<ITalk[]>([]);
  const [talkSelected, setTalkSelected] = useState({} as ITalk);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [connectedMember] = web3store.useState('connectedMember');
  const [loadingChat, setLoadingChat] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [screenTitle, setScreenTitle] = useState('');
  const [lockInputChat, setLockInputChat] = useState(false);
  const [deleteChat, setDeleteChat] = useState(false);
  const [chatId, setChatId] = useState<number>();
  const [exitPaige, setExitPaige] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oldPrompt, setOldPrompt] = useState('');
  const [buttonStopTypist, setButtonStopTypist] = useState(false);
  const [random, setRandom] = useState(0);
  const randomNumber = Math.floor(random * 11);
  const avatarPaige = 'https://qa.pcode.pro/v1/nft/image/paige.png';

  const listNewChat = useMemo(
    () => [
      "Hey, let's start fresh. What's on your mind?",
      "Hey, let's begin a new chat. What's up?",
      "Hey there, what's on your mind?",
      "Sure, what's your request?",
      'Hey again! What brings you back?',
      "Sure! What's your question?",
      "Sounds good, let's begin a new chat.",
      "Sure, let's start a new thread. What's on your mind?",
      "No problem, let's start fresh.",
      'What can I assist you with?',
      'Whats your request?',
    ],
    []
  );

  const pdf = document.getElementById('pdf');

  const handlePdf = async () => {
    try {
      setLoading(true);
      const doc = new jsPDF();

      const messagesElements = pdf?.querySelectorAll(
        '.message-box'
      ) as unknown as HTMLElement[];

      doc.setFontSize(30);
      doc.text(screenTitle, 20, 20);

      let y = 30;

      for (const messageElement of messagesElements) {
        const canvasMessage = await html2canvas(messageElement, {
          useCORS: true,
        });

        if (y + canvasMessage.height * 0.25 * 0.75 > 280) {
          doc.addPage();
          y = 20;
        }
        doc.addImage(
          canvasMessage,
          15,
          y,
          canvasMessage.width * 0.25 * 0.75,
          canvasMessage.height * 0.25 * 0.75
        );
        y += 5 + canvasMessage.height * 0.25 * 0.75;
      }
      doc.save('conversation.pdf');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const tokenID = connectedMember.memberId;
    api
      .get('/v1/paige/thread/list', {
        params: {
          tokenID,
        },
      })
      .then((response) => {
        setTalks(response.data.threads);
      })
      .catch((error) => {
        console.log('================= ERROR ===================');
        console.log(error);
      });
  }, [connectedMember.memberId]);

  const scrollMessages = useCallback(() => {
    const scroll = document.getElementById('messages');
    setTimeout(() => {
      scroll?.scrollTo(0, scroll.scrollHeight);
    }, 300);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          prompt: Yup.string().required('Message is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const member = connectedMember.memberId;

        if (user) {
          setMessages((state) => [
            ...state,
            {
              id: 1,
              avatar: user.nft_img,
              message: `<p>${data.prompt}</p>`,
            },
          ]);

          formRef.current?.reset();
          setOldPrompt(data.prompt);
        }

        setButtonStopTypist(true);
        setLoadingChat(true);
        setLockInputChat(true);
        scrollMessages();
        const response = await api.post('v2/paige/chat/generate', {
          tokenID: member,
          prompt: data.prompt,
          threadID: talkSelected.id,
        });
        setTalkSelected({
          id: response.data.threadID,
          title: response.data.title,
        });

        setMessages((state) => [
          ...state,
          {
            id: 2,
            avatar: response.data.paigeAvatar,
            message: response.data.message
              .replace('<p>', '')
              .replace('</p>', ''),

            new: true,
          },
        ]);

        console.log(response.data.message);

        setLoadingChat(false);
        scrollMessages();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          console.log('================= ERROR ===================');
          console.log(error);
        }
      }
    },
    [connectedMember.memberId, scrollMessages, talkSelected.id, user]
  );

  const handleRethinkAnswer = useCallback(async () => {
    setLoadingChat(true);
    try {
      const member = connectedMember.memberId;
      setButtonStopTypist(true);
      setLockInputChat(true);
      scrollMessages();
      const response = await api.post('v2/paige/chat/generate', {
        tokenID: member,
        prompt: oldPrompt,
        threadID: talkSelected.id,
      });

      setMessages((state) => [
        ...state,
        {
          id: 2,
          avatar: response.data.paigeAvatar,
          message: response.data.message.replace('<p>', '').replace('</p>', ''),

          new: true,
        },
      ]);

      setLoadingChat(false);
      setLockInputChat(false);
      scrollMessages();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        console.log('================= ERROR ===================');
        console.log(error);
      }
    }
  }, [connectedMember.memberId, oldPrompt, scrollMessages, talkSelected.id]);

  const handleClickShowMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const handleClickBack = useCallback(() => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setTimeout(() => {
        setExitPaige(true);
      }, 100);
    }
  }, [showMenu]);

  const handleClickTheme = useCallback(() => {
    if (theme === 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  }, [theme]);

  const alterMessage = useCallback((message: string) => {
    const messageData = message
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')

      .replace(/```([\s\S]*?)```/g, '<pre>$1</pre>')
      .replace(/`([\s\S]*?)`/g, '<code class="tag-code">$1</code>');

    let activePreTag = false;
    const messageParts = messageData
      .split(/(<pre>|<\/pre>)/g)
      .map((contentPart, index) => {
        if (contentPart === '<pre>') {
          activePreTag = true;
          return '';
        }
        if (contentPart === '</pre>') {
          activePreTag = false;
          return '';
        }

        if (activePreTag) {
          return {
            content: contentPart.replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
            pre: activePreTag,
            id: index,
            length: contentPart.length,
          };
        }
        return {
          content: contentPart
            .replace(/<\/?p>/gi, '')
            .replace(
              /^([^\n]*\n)([\s\S]*)(\n[^\n]*)$/,
              '<pre class="tag-pre">$1$2$3</pre>'
            ),
          pre: activePreTag,
          id: index,
          length: contentPart.length,
        };
      });

    const messagePartsData = messageParts.filter(
      (messagePart) => !!messagePart
    );

    const newMessageData = messagePartsData.reduce(
      (previous: string, current) => {
        if (typeof previous === 'string' && typeof current === 'object') {
          if (current.pre) {
            const firstBreakLineIndex = current.content.indexOf('\n');
            const language = current.content.slice(0, firstBreakLineIndex);
            const code = current.content.slice(firstBreakLineIndex + 1);
            try {
              return `${previous}<pre>${
                hljs.highlight(code, { language }).value
              }</pre>`;
            } catch (error) {
              return `${previous}<pre>${
                hljs.highlight(code, { language: 'html' }).value
              }</pre>`;
            }
          }
          return previous + current.content;
        }

        return '';
      },
      ''
    );

    return newMessageData;
  }, []);

  const threadSelected = useCallback(
    async (talk: ITalk) => {
      try {
        setTalkSelected({} as ITalk);
        setMessages([]);
        const tokenID = connectedMember.memberId;
        const response = await api.post('v1/paige/thread/get', {
          tokenID,
          threadID: talk.id,
        });
        const questions = response.data.thread_items.filter(
          (message: IMessage) => !message.avatar.includes('paige')
        );
        if (questions.length > 0) {
          setOldPrompt(questions[questions.length - 1].message);
        }
        const data: IMessage[] = response.data.thread_items.map(
          (message: IMessage) => {
            return {
              id: message.id,
              avatar: message.avatar,
              message: alterMessage(message.message),
            };
          }
        );
        setTalkSelected(talk);
        setMessages(data);
      } catch (error) {
        console.log('================= ERROR ===================');
        console.log(error);
      }
    },
    [alterMessage, connectedMember.memberId]
  );

  const newThread = useCallback(() => {
    setRandom(Math.random());

    setTalkSelected({
      id: 0,
      title: listNewChat[randomNumber],
    });

    setMessages([]);
  }, [listNewChat, randomNumber]);

  const handleChangeTitle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newTalks = talks.slice();
      const talkIndex = newTalks.findIndex(
        (talk) => talk.id === talkSelected.id
      );
      if (talkIndex >= 0) {
        newTalks[talkIndex].title = e.target.value;
      }
      setTalks(newTalks);
      setScreenTitle(e.target.value);
    },
    [talkSelected.id, talks]
  );

  const handleDisabled = useCallback(() => {
    setDisabled(!disabled);
    if (disabled === false) {
      const newTalks = talks.slice();
      const talkIndex = newTalks.findIndex(
        (talk) => talk.id === talkSelected.id
      );
      if (talkIndex >= 0) {
        newTalks[talkIndex].edit = false;
      }
      setTalks(newTalks);
    }
  }, [disabled, talkSelected.id, talks]);

  const handleClickEditTitle = useCallback((index) => {
    setTalks((state) => {
      const newTalks = state.slice();

      newTalks[index].edit = true;

      return newTalks;
    });
  }, []);

  const handleClickSaveTitle = useCallback((index) => {
    setTalks((state) => {
      const newTalks = state.slice();

      newTalks[index].edit = false;

      return newTalks;
    });
  }, []);

  const handleChangeTitleTalk = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index) => {
      setTalks((state) => {
        const newTalks = state.slice();

        newTalks[index].title = e.target.value;

        return newTalks;
      });
    },
    []
  );

  useEffect(() => {
    setScreenTitle(talkSelected.title);
  }, [talkSelected.title]);

  useEffect(() => {
    const btnEdit = document.querySelector('.chat-title') as HTMLElement;
    if (btnEdit && disabled === false) {
      btnEdit.focus();
    }
  }, [disabled]);

  const handleTypingDone = useCallback(() => {
    setLockInputChat(false);
    scrollMessages();
    setButtonStopTypist(false);
  }, [scrollMessages]);

  const handleDeleteChat = useCallback((id) => {
    setTimeout(() => {
      setChatId(id);
      setDeleteChat(true);
    }, 100);
  }, []);

  const handleOutsideClick = useCallback(
    (e) => {
      if (
        deleteChat &&
        deleteChatRef.current &&
        e.target !== deleteChatRef.current &&
        !deleteChatRef.current.contains(e.target)
      ) {
        setDeleteChat(false);
      }
      if (
        exitPaige &&
        exitPaigeRef.current &&
        e.target !== exitPaigeRef.current &&
        !exitPaigeRef.current.contains(e.target)
      ) {
        setExitPaige(false);
      }
    },
    [deleteChat, exitPaige]
  );

  const handleStopTypist = useCallback(() => {
    const newMessages = messages.slice();

    newMessages[newMessages.length - 1].new = false;
    newMessages[newMessages.length - 1].message = alterMessage(
      newMessages[newMessages.length - 1].message
    );

    setMessages(newMessages);
    handleTypingDone();
    scrollMessages();
  }, [alterMessage, handleTypingDone, messages, scrollMessages]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
  }, [handleOutsideClick]);

  return (
    <>
      <Container theme={theme} className="py-3 px-5 position-relative">
        {false && (
          <Settings theme={theme} className="position-absolute">
            <button
              type="button"
              className="ms-auto d-flex align-items-center justify-content-center"
              onClick={handleClickTheme}
            >
              {theme === 'dark' && <IoSunnyOutline size={20} color="#828282" />}
              {theme === 'light' && <IoMoonOutline size={20} color="#828282" />}
            </button>
          </Settings>
        )}
        <div className="d-flex no-wrap">
          <Menu
            theme={theme}
            className="w-20 d-flex flex-column align-items-start position-relative"
          >
            <div className="d-flex btn-back position-absolute">
              <button
                type="button"
                className="bg-transparent border-0 me-3"
                onClick={handleClickBack}
              >
                <IoMdArrowBack size={30} color="#4F4F4F" />
              </button>
              <img src={paigeTitle} alt="" />
              {exitPaige && (
                <div ref={exitPaigeRef} className="exit position-absolute p-4">
                  <h2 className="mb-4 text-disconnect text-center">
                    Go Back To
                    <br />
                    Dashboard?
                  </h2>

                  <button
                    type="button"
                    onClick={() =>
                      history.push(`${process.env.PUBLIC_URL}/dashboard`)
                    }
                    className="btn-yes btn-to-dashboard w-100 py-3 mb-2"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => setExitPaige(false)}
                    className="btn-no py-3  mb-2 w-100"
                  >
                    No
                  </button>
                </div>
              )}
            </div>
            <button
              type="button"
              className="bg-transparent border-0 d-flex align-items-center ps-3 pt-5 mt-4"
              onClick={handleClickShowMenu}
            >
              <span>
                <img src={list} alt="list" />
              </span>{' '}
              Saved Chats
            </button>
            <Sidebar
              theme={theme}
              active={showMenu}
              className="d-flex flex-column justify-content-between px-3 position-absolute"
            >
              <div>
                <button
                  type="button"
                  className="btn-new bg-transparent py-3 mb-4 w-100"
                  onClick={() => newThread()}
                >
                  New Conversation
                </button>

                <div className="d-flex justify-content-between">
                  <p className="mb-0">Conversations ({talks.length})</p>
                  {false && (
                    <button
                      type="button"
                      className="border-0 bg-transparent btn-clear"
                    >
                      CLEAR
                    </button>
                  )}
                </div>
                <hr />
                <div className="all-talks overflow-auto">
                  {talks.map((talk, index) => (
                    <div
                      key={talk.id}
                      className={`${chatId === talk.id && 'active'} talk`}
                    >
                      <div className="d-flex justify-content-between position-relative hover-conversation p-3">
                        {!talk.edit ? (
                          <button
                            type="button"
                            onClick={() => {
                              threadSelected(talk);
                              setChatId(talk.id);
                            }}
                            className="bg-transparent border-0 btn-talk w-100 text-start"
                          >
                            <p className="mb-0">{talk.title}</p>
                          </button>
                        ) : (
                          <input
                            type="text"
                            className="d-block bg-transparent border-0 chat-title"
                            onChange={(e) => handleChangeTitleTalk(e, index)}
                            value={talk.title}
                          />
                        )}
                        {/* <Link to={`/products/paige/${talk.id}`}>{talk.title}</Link> */}
                        <div className="position-absolute div-background" />
                        <div className="d-flex position-absolute btns-edit-trash">
                          {!talk.edit ? (
                            <button
                              type="button"
                              onClick={() => handleClickEditTitle(index)}
                              className="border-0 bg-transparent mx-1"
                            >
                              <AiOutlineEdit size={22} />
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => handleClickSaveTitle(index)}
                              className="border-0 bg-transparent mx-1"
                            >
                              <AiOutlineCheck size={22} />
                            </button>
                          )}
                          <button
                            type="button"
                            onClick={() => handleDeleteChat(talk.id)}
                            className="border-0 bg-transparent"
                          >
                            <TbTrash size={22} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  {deleteChat && (
                    <div
                      ref={deleteChatRef}
                      className="disconnect position-absolute p-4"
                    >
                      <h2 className="mb-4 text-disconnect text-center">
                        Delete <br />
                        Chat?
                      </h2>

                      <button type="button" className="btn-yes w-100 py-3 mb-2">
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => setDeleteChat(false)}
                        className="btn-no py-3  mb-2 w-100"
                      >
                        No
                      </button>
                    </div>
                  )}
                </div>
                <button type="button" className="btn-show-more py-3 mt-4 w-100">
                  Show More
                </button>
              </div>
              {false && (
                <div className="d-flex flex-column align-items-start more-options">
                  <Link to="/products/paige/1">
                    <FiCompass size={20} />
                    Show More
                  </Link>
                  <Link to="/" className="faq">
                    <FiHelpCircle size={20} />
                    FAQs
                  </Link>
                </div>
              )}
            </Sidebar>
          </Menu>
          <Content
            theme={theme}
            className={`${
              showMenu && 'ps-5'
            } w-60 mx-auto d-flex align-items-center`}
          >
            <div className="container h-100">
              <div className="row h-100">
                <div
                  className={`col-12 h-100 d-flex flex-column ${
                    messages.length > 0
                      ? 'justify-content-between'
                      : 'justify-content-center'
                  }`}
                >
                  {Object.keys(talkSelected).length > 0 ? (
                    <div id="pdf">
                      <div className="d-flex justify-content-center align-items-center mb-5 title-edit">
                        <div className="mx-auto w-100">
                          <input
                            type="text"
                            className="d-block bg-transparent border-0 chat-title w-100 "
                            onChange={handleChangeTitle}
                            value={screenTitle}
                            disabled={disabled}
                          />
                        </div>
                        {talkSelected.id !== 0 && (
                          <div>
                            <button
                              type="button"
                              onClick={handleDisabled}
                              className="border-0 bg-transparent "
                            >
                              {disabled ? (
                                <AiOutlineEdit size={26} />
                              ) : (
                                <AiOutlineCheck size={26} />
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                      <Messages id="messages" theme={theme}>
                        {messages.map((message, index) => (
                          <Message
                            key={index.toString()}
                            theme={theme}
                            className="mb-4 message-box"
                          >
                            <div className="d-flex position-relative justify-content-end pe-3">
                              <div className="avatar position-absolute">
                                <Avatar src={message.avatar} theme={theme} />
                              </div>
                              {message.avatar.includes('paige') &&
                              message.new ? (
                                <Typist
                                  text={message.message}
                                  className="message message-paige py-4 px-5"
                                  cursor={{
                                    show: false,
                                  }}
                                  onTypingDone={handleTypingDone}
                                  speed={5}
                                />
                              ) : (
                                <div
                                  className={`message py-4 px-5 ${
                                    message.avatar.includes('paige')
                                      ? 'message-paige'
                                      : 'message-user'
                                  }`}
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: message.message,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            {index === messages.length - 1 && loadingChat && (
                              <div className="d-flex position-relative justify-content-end pe-3 mt-4">
                                <div className="avatar position-absolute">
                                  <Avatar src={avatarPaige} theme={theme} />
                                </div>

                                <div className="message py-4 px-5 message-paige">
                                  <p className="p-skeleton">
                                    Working on it....
                                  </p>
                                  <div className="w-100 skeleton mb-2" />
                                  <div className="w-90 skeleton mb-2" />
                                  <div className="w-75 skeleton mb-2" />
                                  <div className="w-75 skeleton mb-2" />
                                </div>
                              </div>
                            )}
                            {false && (
                              <div>
                                <button
                                  type="button"
                                  className="border-0 bg-transparent btn-like"
                                >
                                  <img src={like} alt="Like" />
                                </button>
                                <button
                                  type="button"
                                  className="border-0 bg-transparent btn-dislike"
                                >
                                  <img src={like} alt="Like" />
                                </button>
                              </div>
                            )}
                          </Message>
                        ))}
                      </Messages>
                    </div>
                  ) : (
                    <>
                      <div>
                        <h1>Hey, I’m Paige!</h1>
                        <p>Your personal AI generative expert</p>
                      </div>
                      {messages.length > 0 && (
                        <Messages id="messages" theme={theme}>
                          {messages.map((message, index) => (
                            <Message
                              key={index.toString()}
                              theme={theme}
                              className="mb-4 message-box"
                            >
                              <div className="d-flex position-relative justify-content-end pe-3">
                                <div className="avatar position-absolute">
                                  <Avatar src={message.avatar} theme={theme} />
                                </div>
                                {message.avatar.includes('paige') &&
                                message.new ? (
                                  <Typist
                                    text={message.message}
                                    className="message message-paige py-4 px-5"
                                    cursor={{
                                      show: false,
                                    }}
                                    onTypingDone={handleTypingDone}
                                    speed={10}
                                  />
                                ) : (
                                  <div
                                    className={`message py-4 px-5 ${
                                      message.avatar.includes('paige')
                                        ? 'message-paige'
                                        : 'message-user'
                                    }`}
                                  >
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: message.message,
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              {index === messages.length - 1 && loadingChat && (
                                <div className="d-flex position-relative justify-content-end pe-3 mt-4">
                                  <div className="avatar position-absolute">
                                    <Avatar src={avatarPaige} theme={theme} />
                                  </div>

                                  <div className="message py-4 px-5 message-paige">
                                    <p className="p-skeleton">
                                      Working on it....
                                    </p>
                                    <div className="w-100 skeleton mb-2" />
                                    <div className="w-90 skeleton mb-2" />
                                    <div className="w-75 skeleton mb-2" />
                                    <div className="w-75 skeleton mb-2" />
                                  </div>
                                </div>
                              )}
                              {false && (
                                <div>
                                  <button
                                    type="button"
                                    className="border-0 bg-transparent btn-like"
                                  >
                                    <img src={like} alt="Like" />
                                  </button>
                                  <button
                                    type="button"
                                    className="border-0 bg-transparent btn-dislike"
                                  >
                                    <img src={like} alt="Like" />
                                  </button>
                                </div>
                              )}
                            </Message>
                          ))}
                        </Messages>
                      )}
                    </>
                  )}
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    {messages.length > 1 && (
                      <div className="d-flex action-buttons mb-n4 px-3 position-relative">
                        <div className="fade-chat" />
                        {!buttonStopTypist && (
                          <>
                            <button
                              type="button"
                              className={`${
                                loadingChat && 'opacity-0 invisible'
                              } d-flex align-items-center justify-content-center`}
                              onClick={handleRethinkAnswer}
                            >
                              <HiOutlineRefresh size={24} />
                              <span>RETHINK ANSWER</span>
                            </button>
                            <button
                              type="button"
                              className={`${
                                loadingChat && 'opacity-0 invisible'
                              } d-flex align-items-center justify-content-center`}
                              onClick={handlePdf}
                            >
                              <FiDownload size={24} />
                              <span>DOWNLOAD CHAT</span>
                            </button>
                          </>
                        )}
                        {buttonStopTypist && (
                          <button
                            type="button"
                            className={`${
                              loadingChat && 'opacity-0 invisible'
                            } d-flex align-items-center justify-content-center ms-auto me-0`}
                            onClick={handleStopTypist}
                          >
                            <MdOutlineStopCircle size={24} />
                            <span>STOP GENERATING</span>
                          </button>
                        )}
                      </div>
                    )}
                    <div className="d-flex input">
                      <Input
                        name="prompt"
                        disabled={lockInputChat}
                        placeholder="Ask me anything...."
                      />
                      <button
                        type="submit"
                        disabled={lockInputChat}
                        className="bg-transparent border-0 d-flex align-items-center"
                      >
                        {lockInputChat ? (
                          <span className="tiping">
                            <span />
                            <span />
                            <span />
                          </span>
                        ) : (
                          <>
                            <BsArrowReturnRight size={15} /> Send
                          </>
                        )}
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Content>
          {!showMenu && <div className="w-20" />}
        </div>
      </Container>
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="EXPORTING CONVERSATION..."
        active={loading}
      />
    </>
  );
};

export default Chatbot;
