import React from 'react';
import { FiDownload } from 'react-icons/fi';
import { Container, BgImage } from './styles';

import banner from '~/assets/banners/presentations.png';
import bannerProd from '~/assets/banners/products.png';
import stamp from '~/assets/defaults/stamp.png';

interface IProps {
  className: string;
}

const AllResources: React.FC<IProps> = ({ className }) => {
  const franchise = [
    {
      h3Title: 'Discover How To Profit From The Most Lucrative',
      spanTitle: 'Opportunity of The Decade',
      language: 'English',
      link: 'https://prfti.s3.amazonaws.com/PROFITi+Overview.pdf',
    },
    {
      h3Title: 'Descubra Como Lucrar Com a Melhor e Mais Lucrativa',
      spanTitle: 'Oportunidade Da Década',
      language: 'Portuguese',
      link: 'https://prfti.s3.amazonaws.com/Apresentac%CC%A7a%CC%83o+PROFITi.pdf',
    },
    {
      h3Title: 'Descubra Cómo Beneficiarse de la Mejor y Mas Rentable',
      spanTitle: 'Oportunidad de la Década',
      language: 'Spanish',
      link: 'https://prfti.s3.amazonaws.com/Presentacio%CC%81n+PROFITi.pdf',
    },
    {
      h3Title: 'Scopri come trarre profitto dalle più redditizie',
      spanTitle: 'Opportunità del Decennio',
      language: 'Italian',
      link: 'https://prfti.s3.amazonaws.com/it_presentazione_profiti.pdf',
    },
  ];

  const products = [
    {
      h3Title: '',

      link: '',
    },
  ];

  return (
    <Container className={`container-fluid container-xl ${className}`}>
      <div className="row">
        <div className="h2">
          <div className="col-xxl-9">
            <div className="row">
              <div className="col-12">
                <h2 className="h5 fw-bold mb-4">Presentations</h2>
              </div>
              {franchise.map((item) => (
                <div className="col-sm-6 col-md-4 mb-4">
                  <BgImage
                    href={item.link}
                    target="_blank"
                    src={banner}
                    className="d-flex flex-column text-start justify-content-between border-0 w-100"
                  >
                    <span className="title">
                      {item.h3Title}
                      <br />
                      <span>{item.spanTitle}</span>
                    </span>
                    <span className="h6 text-presentation mb-0 py-3 px-4 w-100 d-flex align-items-center justify-content-between">
                      {item.language} Presentation{' '}
                      <FiDownload size={25} color="#cbccce" />
                    </span>
                  </BgImage>
                </div>
              ))}
            </div>

            <div className="row">
              <div className="col-12">
                <h2 className="h5 fw-bold mb-4 mt-5">Products</h2>
              </div>

              <div className="col-sm-6 col-md-4 mb-4">
                <BgImage
                  href="https://prfti.s3.amazonaws.com/Profiti+Product+Roadmap.pdf"
                  target="_blank"
                  src={bannerProd}
                  className="d-flex flex-column text-start justify-content-between border-0 w-100"
                >
                  <span className="title-products">
                    PROFIT<span>i</span> PRODUCT ROADMAP
                  </span>
                  <div>
                    <div className="bg-stamp">
                      <img src={stamp} alt="Stamp" />
                    </div>
                    <span className="h6 text-presentation mb-0 py-3 px-4 w-100 d-flex align-items-center justify-content-between">
                      Product Roadmap
                      <FiDownload size={25} color="#cbccce" />
                    </span>
                  </div>
                </BgImage>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AllResources;
