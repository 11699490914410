import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { HiOutlineInformationCircle, HiArrowNarrowRight } from 'react-icons/hi';

import Swal from 'sweetalert2';
import getValidationErros from '~/utils/getValidationsErrors';

import api from '~/services/api';
import swalError from '~/utils/swalError';
import { web3store } from '~/store';
import { formatPrice } from '~/utils/format';

import { Container, Card, SelectCoin } from './styles';
import InputMask from '~/components/InputMask';
import ModalConfirmation from '~/pages/Sales/ModalConfirmation';
import Loading from '~/components/Loading';

import arrows from '~/assets/icons/arrows-swap.svg';
import logoSwap from '~/assets/logo/logo-swap.png';
import logoBusd from '~/assets/logo/logo-busd.svg';
import logoUsdpi from '~/assets/logo/logo-usdpi.svg';
import logoEth from '~/assets/logo/logo-eth.svg';
import logoBnb from '~/assets/logo/logo-bnb.svg';
import logoUsdt from '~/assets/logo/logo-usdt.svg';
import logoUsdc from '~/assets/logo/logo-usdc.svg';
import logoGusd from '~/assets/logo/logo-gusd.svg';
import arrow from '~/assets/icons/arrow.svg';

import logoWhite from '~/assets/logo/logo-p-white.svg';
import WalletLink from '~/components/WalletLink';

interface IToken {
  logo: string;
  name: string;
  descriptionBnb: string;
  descriptionEth: string;
  priceBnb: string;
  priceEth: string;
  cardStyle: string;
}

interface INetwork {
  logo: string;
  name: string;
}

const PageSwap: React.FC = () => {
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [invertCard, setInvertCard] = useState(false);
  const [balbusd] = web3store.useState('balbusd');
  const [balusdpi] = web3store.useState('balusdpi');
  const [exchangeValue, setExchangeValue] = useState('0,00');
  const [feeValue, setFeeValue] = useState('0,00');
  const [receiveValue, setReceiveValue] = useState('0,00');
  const [error, setError] = useState('');
  const [showCongrat, setShowCongrat] = useState(false);
  const [, setCartPurchase] = web3store.useState('cartPurchase');
  const [, setUsdpiPurchaseAmt] = web3store.useState('usdpiPurchaseAmt');
  const [, setUsdpiSaleAmt] = web3store.useState('usdpiSaleAmt');
  const [, setUsdpiPurchaseFee] = web3store.useState('usdpiPurchaseFee');
  const [isAlertOpen, setIsAlertOpen] = useState(true);
  const [cartAmt] = web3store.useState('cartAmt');
  const [buyStakingAmt] = web3store.useState('buyStakingAmt');
  const [usdpiPurchaseTX, setUsdpiPurchaseTX] =
    web3store.useState('usdpiPurchaseTX');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorExchange, setErrorExchange] = useState('');
  const [type, setType] = useState('');
  const [tokens, setTokens] = useState<IToken[]>([
    {
      logo: logoBusd,
      name: 'BUSD',
      descriptionBnb:
        'BUSD In the Binance Smart Chain (BEP20) network liquidity in reserves:',
      descriptionEth:
        'BUSD In the Binance Smart Chain (BEP20) network liquidity in reserves:',
      priceEth: '$200,450.25',
      priceBnb: '$200,450.25',
      cardStyle: 'linear-gradient(84.9deg, #F7951B 10.36%, #F8A642 97.55%)',
    },
    {
      logo: logoUsdt,
      name: 'USDT',
      descriptionBnb:
        'USDT In the Ethereum (ERC20) network liquidity in reserves:',
      descriptionEth:
        'USDT In the Binance Smart Chain (BEP20) network liquidity in reserves:',
      priceEth: '$90,550.34',
      priceBnb: '$90,550.34',
      cardStyle: 'linear-gradient(84.9deg, #26A183 10.36%, #25A07A 97.55%)',
    },
    {
      logo: logoUsdc,
      name: 'USDC',
      descriptionBnb:
        'USDC In the Ethereum (ERC20) network liquidity in reserves:',
      descriptionEth:
        'USDC In the Binance Smart Chain (BEP20)  network liquidity in reserves:',
      priceEth: '$112,580.88',
      priceBnb: '$112,580.88',
      cardStyle: 'linear-gradient(84.9deg, #2775CA 10.36%, #2E75C2 97.55%)',
    },
    {
      logo: logoGusd,
      name: 'GUSD',
      descriptionBnb:
        'GUSD In the Ethereum (ERC20) network liquidity in reserves:',
      descriptionEth:
        'GUSD In the Binance Smart Chain (BEP20)  network liquidity in reserves:',
      priceEth: '$112,580.88',
      priceBnb: '$112,580.88',
      cardStyle: 'linear-gradient(84.9deg, #00DCFA 10.36%, #0CBCD7 97.55%)',
    },
  ]);
  const [tokenSelected, setTokenSelected] = useState<IToken>({
    logo: logoBusd,
    name: 'BUSD',
    descriptionBnb:
      'BUSD In the Binance Smart Chain (BEP20) network liquidity in reserves:',
    descriptionEth:
      'BUSD In the Ethereum (ERC20) network liquidity in reserves:',
    priceEth: '$200,450.25',
    priceBnb: '$200,450.25',
    cardStyle: 'linear-gradient(84.9deg, #F7951B 10.36%, #F8A642 97.55%)',
  });
  const [networks, setNetworks] = useState<INetwork[]>([
    {
      logo: logoEth,
      name: 'ETH',
    },
    {
      logo: logoBnb,
      name: 'BNB',
    },
  ]);
  const [networkSelected, setNetworkSelected] = useState<INetwork>({
    logo: logoBnb,
    name: 'BNB',
  });
  // console.log('cartAmt: %s', cartAmt);
  // console.log('buyStakingAmt: %s', buyStakingAmt);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(exchangeValue);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [exchangeValue]);

  useEffect(() => {
    if (debouncedValue) {
      const value = parseFloat(debouncedValue.replaceAll(',', ''));
      const amt = value * 10 ** 18;
      api.get(`v1/usdpi/fee/estimate/${amt}`).then((response) => {
        const fee = response.data.estimate.fee / 10 ** 18;
        const net = response.data.estimate.net / 10 ** 18;

        setFeeValue(formatPrice(fee));
        setUsdpiPurchaseFee(fee);
        setReceiveValue(formatPrice(net));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const busd = useMemo(
    () =>
      formatPrice(parseFloat((parseInt(balbusd, 10) / 10 ** 18).toFixed(2))),
    [balbusd]
  );

  const usdpi = useMemo(
    () =>
      formatPrice(parseFloat((parseInt(balusdpi, 10) / 10 ** 18).toFixed(2))),
    [balusdpi]
  );

  const handleInvertCard = useCallback(() => {
    setError('');
    const value = parseFloat(exchangeValue.replaceAll(',', ''));
    const coinBusd = parseFloat(busd.replaceAll(',', ''));
    const coinUsdpi = parseFloat(usdpi.replaceAll(',', ''));
    if (invertCard) {
      if (value > coinUsdpi) {
        setError(
          'The value must be less than or equal to your amount of coins'
        );
      }
    } else if (value > coinBusd) {
      setError('The value must be less than or equal to your amount of coins');
    }

    setInvertCard(!invertCard);
  }, [busd, exchangeValue, invertCard, usdpi]);

  const handleChangePrice = useCallback(
    async (e) => {
      setError('');
      const value = parseFloat(e.target.value.slice(1).replaceAll(',', ''));
      const coinBusd = parseFloat(busd.replaceAll(',', ''));
      const coinUsdpi = parseFloat(usdpi.replaceAll(',', ''));
      if (!invertCard) {
        if (value > coinBusd) {
          setError(
            'The value must be less than or equal to your amount of coins BUSD'
          );
        }
      } else if (value > coinUsdpi) {
        setError(
          'The value must be less than or equal to your amount of coins USDPI'
        );
      }

      console.log('----------------------');
      console.log('total: %s type: %s', total, typeof total);
      console.log(total > 0);
      console.log(coinUsdpi + value);
      console.log('----------------------');

      if (total > 0 && coinUsdpi + value < total) {
        setError(
          'The value plus your balance must be greater than or equal to the amount needed.'
        );
      } else {
        console.log('FAILED');
      }

      setExchangeValue(e.target.value.slice(1));
    },
    [busd, invertCard, usdpi, total]
  );

  const handleMax = useCallback(() => {
    setError('');
    if (invertCard) {
      setExchangeValue(usdpi);
    } else {
      setExchangeValue(busd);
    }
  }, [busd, invertCard, usdpi]);

  const handleShowCongrat = useCallback(() => {
    if (location.pathname === '/activate-your-profit-centers') {
      setCartPurchase(true);
    } else {
      setShowCongrat(true);
    }
  }, [location.pathname, setCartPurchase]);

  useEffect(() => {
    if (usdpiPurchaseTX) {
      setLoading(false);
      if (typeof usdpiPurchaseTX === 'object' && usdpiPurchaseTX.reason) {
        if (JSON.stringify(usdpiPurchaseTX) !== errorExchange) {
          swalError({
            message: usdpiPurchaseTX.reason,
            textButton: 'Try Again',
          }).then(() => {
            setErrorExchange(JSON.stringify(usdpiPurchaseTX));
          });
        }
      } else if (
        typeof usdpiPurchaseTX === 'string' &&
        usdpiPurchaseTX.toLowerCase().includes('error')
      ) {
        swalError({
          message: usdpiPurchaseTX,
          textButton: 'Try Again',
        }).then(() => {
          setUsdpiPurchaseTX('');
        });
      } else if (!isAlertOpen) {
        setIsAlertOpen(true);
        setUsdpiPurchaseTX('');
      }
    }
  }, [errorExchange, isAlertOpen, setUsdpiPurchaseTX, usdpiPurchaseTX]);

  const handleSubmitExchange = useCallback(
    async (data) => {
      try {
        setIsAlertOpen(false);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          price: Yup.string().required('The value is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        if (invertCard) {
          // setUsdpiSaleAmt(parseFloat(data.price.slice(1).replaceAll(',', '')));
          setUsdpiSaleAmt(parseFloat(data.price.replaceAll(',', '')));
        } else {
          setUsdpiPurchaseAmt(
            // parseFloat(data.price.slice(1).replaceAll(',', ''))
            parseFloat(data.price.replaceAll(',', ''))
          );
        }
      } catch (err) {
        setIsAlertOpen(true);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [invertCard, setUsdpiPurchaseAmt, setUsdpiSaleAmt]
  );

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClick = useCallback((typeData) => {
    setType((state) => (state === typeData ? '' : typeData));
  }, []);

  const handleSelectToken = useCallback((token) => {
    setTokenSelected(token);
    setType('');
  }, []);

  const handleSelectNetwork = useCallback((network) => {
    setNetworkSelected(network);
    setType('');
  }, []);

  return (
    <>
      <Container className="p-5">
        <div className="container-fluid py-0 border-0">
          <div className="row align-items-center">
            <div className="col-3 col-xl-1 text-end">
              <img src={logoSwap} alt="Logo USDPI" className="logo-usdpi" />
            </div>
            <div className="col-7 col-xl-5">
              <h2 className="fw-bold mb-0">USDPI</h2>
            </div>
            <div className="col-lg-6">
              <WalletLink
                onShowNoReferrer={handleShow}
                btnText="Connect Wallet"
                className="btn-wallet me-4 me-lg-0"
              />
            </div>
            <div className="col-12">
              <h1 className="title">Swap</h1>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-9">
              <div className="row px-4 px-sm-5 justify-content-center">
                <div className="col-12 d-flex bd-bottom align-items-end px-0">
                  <div className="w-50">
                    <button type="button" className="btn-swap active px-4 py-3">
                      SWAP
                    </button>
                    <button type="button" className="btn-swap px-4 py-3">
                      ORDER HISTORY
                    </button>
                  </div>
                  <div className="w-50 text-end">
                    Balance
                    <br />
                    <span className="value">${usdpi}</span>
                  </div>
                </div>
                <div className="col-12 mt-3 text-end pe-0">
                  <span className="buy-text">
                    Don’t have {networkSelected.name} or {tokenSelected.name}?
                  </span>{' '}
                  <a
                    href="https://bit.ly/getbusd"
                    target="_blank"
                    className="btn buy-here ms-4 py-2 px-3"
                    rel="noreferrer"
                  >
                    BUY IT HERE
                  </a>
                </div>
              </div>

              <div className="row px-5 mt-3">
                <div className="col-8 d-flex flex-column px-0">
                  <div className="d-flex justify-content-between">
                    <div className="w-45 order-0">
                      <h4 className="ps-3 mb-4">Send</h4>
                    </div>
                    <div className="w-45 order-1">
                      <h4 className="ps-3 mb-4">Receive</h4>
                    </div>
                  </div>
                  <div className="d-flex align-items-center cards-shadow">
                    <div
                      className={`w-45 ${!invertCard ? 'order-2' : 'order-4'}`}
                    >
                      <Card
                        background={tokenSelected.cardStyle}
                        className="yellow-card p-4"
                      >
                        <div className="text-end">
                          <img src={tokenSelected.logo} alt="Logo BUSD" />
                        </div>
                        <h2>{tokenSelected.name}</h2>
                        <div className="d-flex mt-4">
                          <div className="w-50 h6 text-white">$1</div>
                          <div className="w-50 h6 text-white">
                            {busd} {tokenSelected.name}
                          </div>
                        </div>
                      </Card>
                    </div>
                    <div className="w-10 text-center order-3">
                      <button
                        type="button"
                        onClick={handleInvertCard}
                        className="rounded-circle border-0 arrows"
                      >
                        <img src={arrows} alt="Arrows" />
                      </button>
                    </div>
                    <div
                      className={`w-45 ${!invertCard ? 'order-4' : 'order-2'}`}
                    >
                      <Card
                        background="linear-gradient(84.9deg, #2fbf7f 10.36%, #3cc9ad 97.55%);"
                        className="green-card p-4"
                      >
                        <div className="text-end">
                          <img src={logoUsdpi} alt="Logo USDPI" />
                        </div>
                        <h2>USDPI</h2>
                        <div className="d-flex mt-4">
                          <div className="w-50 h6 text-white">$1</div>
                          <div className="w-50 h6 text-white">
                            {usdpi} USDPI
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className="col-4 px-0 ps-5">
                  <div className="d-flex justify-content-between mt-3">
                    <div>
                      <span
                        className="wm-tooltip text-table"
                        data-tooltip="Transaction fees can fluctuate based on network conditions such as demand and congestion. Higher demand can lead to higher fees, while lower demand can result in lower fees. The fees are not fixed, but rather vary based on network conditions at any given time."
                      >
                        Network Fees{' '}
                        <HiOutlineInformationCircle
                          className="ms-3"
                          size={25}
                          color="#4F89FF"
                        />
                        <br />
                        <span className="estimate">
                          Estimate only, fees will vary
                        </span>
                      </span>
                    </div>
                    <h3 className="values text-end">
                      ${feeValue}
                      <br />
                      <span>
                        {feeValue} {invertCard ? 'USDPI' : tokenSelected.name}
                      </span>
                    </h3>
                  </div>
                  <div className="d-flex justify-content-between text-end mt-2">
                    <span className="text-table">Send</span>
                    <h3 className="values color-negative">
                      -${exchangeValue}
                      <br />
                      <span>
                        -{exchangeValue}{' '}
                        {invertCard ? 'USDPI' : tokenSelected.name}
                      </span>
                    </h3>
                  </div>
                  <div className="d-flex justify-content-between text-end mt-2">
                    <span className="text-table">Receive</span>
                    <div>
                      <h3 className="values color-positive mb-0">
                        ${receiveValue}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <Form
                ref={formRef}
                onSubmit={handleSubmitExchange}
                className="row px-5 mt-5 pt-3"
              >
                <div className="col-12 px-0">
                  <div className="text-center bg-number py-3 position-relative">
                    <button
                      onClick={handleMax}
                      type="button"
                      className="position-absolute"
                    >
                      Max
                    </button>
                    <InputMask
                      kind="money"
                      name="price"
                      className="input-mask border-0 bg-transparent px-4"
                      options={{
                        unit: '$',
                        delimiter: ',',
                        separator: '.',
                      }}
                      placeholder="Enter amount here"
                      onChange={handleChangePrice}
                      value={exchangeValue !== '0,00' ? exchangeValue : ''}
                    />

                    <span className="h4 color pt-3">
                      {exchangeValue}{' '}
                      {invertCard ? 'USDPI' : tokenSelected.name}
                    </span>
                  </div>

                  {error && (
                    <span className="small text-danger error text-center w-100 d-block mt-1">
                      {error}
                    </span>
                  )}
                </div>
                <div className="col-12 px-0 mt-4 position-relative">
                  <button
                    type="submit"
                    className="btn btn-exchange fw-bold text-black"
                  >
                    Exchange
                  </button>
                </div>
              </Form>
            </div>
            <div className="col-lg-3">
              <div className="box token-information">
                <h3>Token Information</h3>
                <div className="token-information">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center coin">
                      <div className="space" />
                      <small>Token</small>
                    </div>
                    <div className="d-flex align-items-center coin">
                      <div className="space" />
                      <small>Network</small>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="position-relative">
                      <button
                        type="button"
                        className="border-0 bg-transparent d-flex align-items-center coin"
                        onClick={() => handleClick('token')}
                      >
                        <img
                          src={tokenSelected.logo}
                          alt={tokenSelected.name}
                        />
                        <p className="mb-0 coin-name">{tokenSelected.name}</p>
                        <img src={arrow} alt="Arrow" className="arrow-down" />
                      </button>
                      <SelectCoin
                        left="0"
                        active={type === 'token'}
                        className="position-absolute"
                      >
                        {tokens.map((token, index) => (
                          <Fragment key={token.name}>
                            {index !== 0 && <hr />}
                            <button
                              type="button"
                              className="border-0 bg-transparent d-flex align-items-center coin"
                              onClick={() => handleSelectToken(token)}
                            >
                              <img src={token.logo} alt={token.name} />
                              <p className="mb-0 coin-name">{token.name}</p>
                            </button>
                          </Fragment>
                        ))}
                      </SelectCoin>
                    </div>
                    <img src={arrow} alt="Arrow" />
                    <div className="position-relative">
                      <button
                        type="button"
                        className="border-0 bg-transparent d-flex align-items-center coin"
                        onClick={() => handleClick('network')}
                      >
                        <img
                          src={networkSelected.logo}
                          alt={networkSelected.name}
                        />
                        <p className="mb-0 coin-name">{networkSelected.name}</p>
                        <img src={arrow} alt="Arrow" className="arrow-down" />
                      </button>
                      <SelectCoin
                        right="0"
                        active={type === 'network'}
                        className="position-absolute"
                      >
                        {networks.map((network, index) => (
                          <Fragment key={network.name}>
                            {index !== 0 && <hr />}
                            <button
                              type="button"
                              className="border-0 bg-transparent d-flex align-items-center coin"
                              onClick={() => handleSelectNetwork(network)}
                            >
                              <img src={network.logo} alt={network.name} />
                              <p className="mb-0 coin-name">{network.name}</p>
                            </button>
                          </Fragment>
                        ))}
                      </SelectCoin>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box">
                <h3>Liquidity Information</h3>

                <div className="tokens-information overflow-auto">
                  {tokens.map((token) => (
                    <div key={token.name} className="subbox">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center coin">
                          <img src={token.logo} alt={token.name} />
                          <p className="mb-0 coin-name">{token.name}</p>
                        </div>
                        <img src={arrow} alt="Arrow" />
                        <div className="d-flex align-items-center coin">
                          <img
                            src={networkSelected.logo}
                            alt={networkSelected.name}
                          />
                          <p className="mb-0 coin-name">
                            {networkSelected.name}
                          </p>
                        </div>
                      </div>
                      <small>
                        {networkSelected.name === 'ETH'
                          ? token.descriptionEth
                          : token.descriptionBnb}
                      </small>
                      <p className="price">
                        {networkSelected.name === 'BNB'
                          ? token.priceEth
                          : token.priceBnb}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="SWAPPING TOKENS"
        className="zoom-1-3"
        active={loading}
      />
    </>
  );
};

export default PageSwap;
