import styled from 'styled-components';

export const Container = styled.button`
  position: relative;
  background: transparent;
  border: none;
  font-size: 12px;
  color: #7d7777 !important;
  span {
    background: transparent !important;
    color: #7d7777 !important;
    -webkit-text-fill-color: unset !important;
  }
`;
