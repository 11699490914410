import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';

export const Container = styled.button`
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  border: none;
  padding: 1px;
  background-color: transparent;
  span {
    padding: 5px 10px;
    margin: 0 18px;
    display: block;
  }
  @media screen and (max-width: 1399px) {
    font-size: 20px;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-close {
    color: #000;
    height: 50px;
    width: 50px;
  }
  .modal-xl {
    width: 1140px;
  }
  .btn-nft {
    color: #fff;
    padding: 18px;
    font-size: 24px;
    border-radius: 20px;
    background-color: #0c0d0d;
  }
  .left-ride,
  .right-ride {
    img {
      padding: 43px;
      background: rgba(244, 247, 253, 0.5);
      box-shadow: 30px 70px 120px rgb(27 49 66 / 13%);
      //backdrop-filter: blur(10px);
      border-radius: 30px;
      transition: 0.2s;
    }
    button:hover,
    button:focus {
      img {
        background: ${lighten(0.1, 'rgba(244, 247, 253, 0.5)')};
      }
      span {
        color: ${lighten(0.1, '#000')};
      }
    }
    .description {
      color: #000;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
    }
  }
  @media screen and (max-width: 1399px) {
    .left-ride,
    .right-ride {
      img {
        padding: 30px;
      }
      .description {
        font-size: 15px;
      }
    }
    .btn-nft {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 991px) {
    .left-ride,
    .right-ride {
      img {
        padding: 25px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .btn-nft {
      padding: 18px 10px;
      font-size: 18px;
    }
  }
  @media screen and (min-width: 576px) {
    .left-ride {
      border-right: 1px solid rgba(20, 20, 20, 0.8);
    }
  }
`;
