import styled from 'styled-components';

interface IContainerLinksProps {
  thumbnail: string;
}

export const Container = styled.div`
  .bg-gray {
    background: #1d2023;
    border-radius: 20px;

    .border-right {
      border-color: #393c3d !important;
    }

    h2 {
      color: #dadada;
    }
  }

  .bg-light-gray {
    background: #333638;
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #dadada;
  }

  p {
    color: #969696;
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  background: #1d2023;
  border-radius: 20px;
  padding: 0px 20px 20px 20px;
  margin-top: 40px;

  h2 {
    color: #dadada;
  }

  .inputDate {
    border: 1.5px solid #393c3d;
    box-sizing: border-box;
    border-radius: 15px;
    min-width: 150px;
    width: 150px;
    height: 40px;
    background-color: transparent;

    > div {
      width: 100%;

      input {
        height: 35px;
        font-size: 14px;
        color: #6f7070 !important;
        text-align: center;
        background-color: transparent;
        padding: 0 10px;
        border: 0px;
      }
    }
  }

  .btn-date {
    min-width: 75px;
    width: 75px;

    cursor: pointer;
  }
  .btn-date:hover {
    background-color: #f7f7f7;
  }
  .btn-date > img {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;

    cursor: pointer;
  }
  .btn-date > img:hover {
  }
`;

export const Graph = styled.div`
  position: relative;
  display: block;

  width: 100%;
  background: #222527;
  border-radius: 20px;
  padding: 20px;
`;

export const ContentInstructions = styled.div`
  p {
    color: #ababab;
  }
`;

export const ContainerLinks = styled.div<IContainerLinksProps>`
  .card-social-media {
    color: #828282;
    background: #1d2023;

    .social-media-group {
      button {
        opacity: 0.3;
        transition-duration: 0.3s;

        :hover {
          opacity: 1;
        }
      }
    }

    .thumbnail {
      width: 100%;
      height: 245px;
      background-image: url(${(props) => props.thumbnail});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      border-radius: 20px;
    }

    .register-name {
      color: #ffffff;
    }
  }
`;
