import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BigNumber,
  ethers,
  utils,
  ContractTransaction,
  providers,
} from 'ethers';
import { format } from 'date-fns';
import Lottie from 'react-lottie';

import { HiArrowNarrowRight } from 'react-icons/hi';
import { Form } from '@unform/web';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';
import { web3store } from '~/store';
// import {
//   getPermitHashes as getFundHashesUSDC,
//   getPermitSignature as getUsdcPermitSignature,
// } from '~/utils/usdc';
import Usdc from '~/utils/usdc';
import {
  Container,
  Welcome,
  P,
  Modal,
  ModalFinish,
  ModalNeed,
  BtnHowBillingWorks,
  ModalRewards,
  BtnCurrency,
} from './styles';

import Table from '~/components/Table';
import WalletRow from '~/components/WalletRow';
import Loading from '~/components/Loading';
import swalError from '~/utils/swalError';
import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';
import ModalHowBillingWorks from '~/components/ModalHowBillingWorks';
import ModalTokensClaimed from '~/components/ModalTokensClaimed';
import { formatToCommonDate } from '~/utils/convertTime';

import logoWhite from '~/assets/logo/logo-p-white.svg';
import checkmark from '~/assets/icons/checkmark.svg';
import xCircle from '~/assets/icons/x-circle.svg';
import info from '~/assets/icons/info.svg';
import stakingTerms from '~/assets/animations/staking-terms-details.json';
import earningsDone from '~/assets/animations/earnings-done.json';
import Input from '~/components/Input';
import { formatPrice } from '~/utils/format';
import walletFinish from '~/assets/defaults/wallet-finish.png';
import currencyETH from '~/assets/icons/logo-eth.svg';
import currencyUSDC from '~/assets/icons/logo-usdc.svg';
import currencyBNB from '~/assets/logo/logo-bnb-border-white.svg';
import currencyUSDPI from '~/assets/logo/logo-usdpi-border-white.svg';

import infoGreen from '~/assets/icons/Info-green.svg';
import { abi as EthUsdtSwapV3Abi } from '~/abi/EthUsdtSwapV3.json';

interface IReferralResponse {
  referrals: {
    total_count: number;
    referrals: {
      id: number;
      name: string;
      nft_img: string;
      rank: string;
      status: string;
      date_registered: number;
    }[];
  };
}

interface IProfitiResponse {
  pool: {
    available: string;
    claimed: string;
  };
}

interface IPerformanceResponse {
  ppool: {
    available: string;
    claimed: string;
  };
}

interface IProfiti {
  id: number;
  available: string;
  claimed: string;
}

interface IPerformance {
  id: number;
  available: string;
  claimed: string;
}

interface IBilling {
  id: number;
  invoice: string;
  amount: string;
  date: string;
  status: string;
}

interface ISubscription {
  annual: string;
  annual_due: string;
  monthly: string;
  monthly_due: string;
  balance: string;
}

interface FeeTimestampStructure {
  fee: number;
  feeFormatted: string;
  timestamp: number;
  result: string;
}

/* eslint no-underscore-dangle: 0 */
const Referrers: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [selectCoin, setSelectCoin] = useState('usdpi');
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const [subscription, setSubscription] = useState({} as ISubscription);
  const [billings, setBillings] = useState<IBilling[]>([]);
  const [billingSelected, setBillingSelected] = useState({} as IBilling);
  const [show, setShow] = useState(false);
  const [showFundAccountWallet, setShowFundAccountWallet] = useState(false);
  const [showFinish, setShowFinish] = useState(false);
  const explorerURL = process.env.REACT_APP_NETWORK_BLOCK_EXPLORER_URLS;
  const explorerURLEth = process.env.REACT_APP_PAY_BLOCK_EXPLORER_URLS;
  const [withdrawalResult, setWithdrawalResult] =
    web3store.useState('withdrawalResult');
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');
  const [subBalance, setSubBalance] = useState(0);
  const [amtNeeded, setAmtNeeded] = useState(0);
  const [enoughText, setEnoughText] = useState('');
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const [subDue, setSubDue] = useState(0);
  const [fund, setFund] = useState('');
  const [debouncedFund, setDebouncedFund] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [showFund, setShowFund] = useState(false);
  const [errorTextFund, setErrorTextFund] = useState('');
  const [amtNeededFund, setAmtNeededFund] = useState(0);
  const [formattedAmtNeededFund, setFormattedAmtNeededFund] = useState('$0.00');
  const [balusdpi, setBalusdpi] = web3store.useState('balusdpi');
  const [acctBalance, setAcctBalance] = useState('0');
  const [showNeed, setShowNeed] = useState(false);
  const [account, setAccount] = web3store.useState('account');
  const [processText, setProcessText] = web3store.useState('processText');
  const [depositTX, setDepositTX] = useState('');
  const [depositTXChain, setDepositTXChain] = useState('NETWORK');
  const [showHowBillingWorks, setShowHowBillingWorks] = useState(false);
  const [profiti, setProfiti] = useState<IProfiti[]>([]);
  const [performance, setPerformance] = useState<IPerformance[]>([]);
  const [modalPerformanceRewards, setModalPerformanceRewards] = useState(false);
  const [modalProfitiRewards, setModalProfitiRewards] = useState(false);
  const [withdrawalPerfPoolUsdpiResult, setWithdrawalPerfPoolUsdpiResult] =
    useState('');
  const [withdrawalPoolUsdpiResult, setWithdrawalPoolUsdpiResult] =
    useState('');
  const [weiAmt2Exchange, setWeiAmt2Exchange] = useState('0');
  const [weiAmt2ExchangeF, setWeiAmt2ExchangeF] = useState('0');
  const [ethBalance, setEthBalance] = useState('0');
  const [usdpiBalance, setUsdpiBalance] = useState('0');
  const [usdcBalance, setUsdcBalance] = useState('0');
  const [bnbBalance, setBnbBalance] = useState('0');
  const [selectedCurrency, setSelectedCurrency] = useState('USDPI');
  const currencyChoices = useMemo(
    () => [
      {
        name: 'USDPI',
        icon: currencyUSDPI,
        balance: usdpiBalance,
        balance_formatted: (Number(usdpiBalance) / 1e18).toFixed(2),
        disabled: false,
        selected: false,
        tooltip: null,
      },
      {
        name: 'USDC',
        icon: currencyUSDC,
        balance: usdcBalance,
        balance_formatted: (Number(usdcBalance) / 1e6).toFixed(2),
        disabled: true,
        selected: false,
        tooltip: 'Coming soon',
      },
      {
        name: 'ETH',
        icon: currencyETH,
        balance: ethBalance,
        balance_formatted: (Number(ethBalance) / 1e18).toFixed(6),
        disabled: false,
        selected: false,
        tooltip: null,
      },
      {
        name: 'BNB',
        icon: currencyBNB,
        balance: bnbBalance,
        balance_formatted: (Number(bnbBalance) / 1e18).toFixed(6),
        disabled: true,
        selected: false,
        tooltip: 'Coming soon',
      },
    ],
    [ethBalance, usdpiBalance, usdcBalance, bnbBalance]
  );
  const [usdc, setUsdc] = useState<Usdc | null>(null);
  const [feeTimestamp, setFeeTimestamp] = useState<FeeTimestampStructure>({
    fee: 0.0,
    feeFormatted: '$0.00',
    timestamp: 1,
    result: 'init',
  });

  const calculateAmtNeeded = useCallback((feeIn: number, debounced: string) => {
    console.log('+++++ calculateAmtNeeded +++++');
    console.log('feeIn: %s', feeIn);
    console.log(
      '+++++ weiAmt2Exchange: %s, type: %s +++++',
      weiAmt2Exchange,
      typeof weiAmt2Exchange
    );
    console.log(
      '+++++ weiAmt2ExchangeF: %s, type: %s +++++',
      weiAmt2ExchangeF,
      typeof weiAmt2ExchangeF
    );
    console.log(
      '+++++ debounced: %s, type: %s +++++',
      debounced,
      typeof debounced
    );
    const newWeiAmt2Exchange = Math.floor(
      (parseFloat(debounced) + feeIn) * 10 ** 6
    );
    // const newWeiAmt2Exchange = Math.floor(parseFloat(debounced) * 10 ** 6);
    console.log(
      'newWeiAmt2Exchange: %s type: %s',
      newWeiAmt2Exchange,
      typeof newWeiAmt2Exchange
    );
    const newWeiAmt2ExchangeF = parseFloat(debounced) + feeIn;
    console.log('newWeiAmt2ExchangeF: %s', newWeiAmt2ExchangeF);
    setWeiAmt2Exchange(newWeiAmt2Exchange.toString());
    setWeiAmt2ExchangeF(newWeiAmt2ExchangeF.toFixed(2));
  }, []);

  // Callback function to update the fee and timestamp
  const updateFeeTimestamp = useCallback(
    (
      newFee: number,
      newTimestamp: number,
      newResult: string,
      debounced: string
    ) => {
      // console.log('+++++ updateFeeTimestamp callback +++++');
      const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(newFee);
      setFeeTimestamp({
        fee: newFee,
        feeFormatted: formattedValue,
        timestamp: newTimestamp,
        result: newResult,
      });
      // console.log('newFee: %s type: %s', newFee, typeof newFee);
      // console.log('newTimestamp: %s', newTimestamp);
      // console.log('newResult: %s', newResult);
      calculateAmtNeeded(newFee, debounced);
    },
    [calculateAmtNeeded]
  );

  useEffect(() => {
    // console.log('+++++ instantiating usdc +++++');
    const newUsdc = new Usdc({ account }, updateFeeTimestamp);
    setUsdc(newUsdc);
  }, [account]);

  useEffect(() => {
    console.log('+++++ debouncedFund changed to: %s +++++', debouncedFund);
  }, [debouncedFund]);

  // useEffect(() => {
  //   if (usdc) {
  //     usdc.refreshFeeAndTimestamp(feeTimestamp);
  //   }
  // }, [usdc]);

  useEffect(() => {
    console.log('+++++ feeTimestamp updated +++++');
    console.log(feeTimestamp);
  }, [feeTimestamp]);

  const handleCurrencyClick = (currencyName: string) => {
    setSelectedCurrency(currencyName);
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const fetchBalances = async () => {
    console.log('+++++ fetchBalances +++++');
    try {
      if (account !== '') {
        const response = await api.get(`v3/member/tokenBalances/${account}`);
        console.log(
          'response.data.balances.wallet_usdc: %s',
          response.data.balances.wallet_usdc
        );
        if (response.data.balances.wallet_eth !== ethBalance) {
          setEthBalance(response.data.balances.wallet_eth);
        }
        if (response.data.balances.wallet_usdpi !== usdpiBalance) {
          setUsdpiBalance(response.data.balances.wallet_usdpi);
        }
        if (response.data.balances.wallet_usdc !== usdcBalance) {
          console.log('+++++ USDC BALANCE CHANGE +++++');
          setUsdcBalance(response.data.balances.wallet_usdc);
        }
        if (response.data.balances.wallet_bnb !== bnbBalance) {
          setBnbBalance(response.data.balances.wallet_bnb);
        }
      }
    } catch (error) {
      console.log(error);
      setEthBalance('0');
      setUsdpiBalance('0');
      setUsdcBalance('0');
      setBnbBalance('0');
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const fetchHistory = useCallback(() => {
    // console.log('+++++ fetchHistory running +++++');
    // console.log(connectedMember);
    setProcessText('Loading subscription billing history');
    setLoading(true);
    api
      .get('v3/billing/history', {
        params: {
          owner: connectedMember.memberId,
        },
      })
      .then(function (response) {
        // console.log(response.data);
        setBillings(response.data.history);
        setSubDue(parseFloat(response.data.total_due));
        // console.log(response.data.total_due);
        // console.log(typeof response.data.total_due);
        setLoading(false);
      })
      .catch(function (error) {
        console.log('================= ERROR ===================');
        console.log(error);
      });
  }, [connectedMember]);

  const getSubscription = async (memberId: number) => {
    console.log('getSubscription');
    api
      .get(`v1/member/subscription/${memberId}`)
      .then(async (responseSubscription) => {
        // console.log(responseSubscription);
        // console.log(responseSubscription.data.subscription);
        // console.log(responseSubscription.data.subscription.balance);
        setAmtNeeded(
          parseInt(responseSubscription.data.subscription.monthly, 10) +
            10 * 10 ** 18
        );
        if (responseSubscription.data.subscription.balance !== undefined) {
          const bn_SubBalanceWei = BigNumber.from(
            responseSubscription.data.subscription.balance
          );
          const bn_SubBalanceEthers = ethers.utils.formatUnits(
            bn_SubBalanceWei,
            18
          );
          // console.log('bn_SubBalanceEthers: %s', bn_SubBalanceEthers);
          // console.log(parseFloat(bn_SubBalanceEthers).toFixed(2));
          setAcctBalance(parseFloat(bn_SubBalanceEthers).toFixed(2));
        }
        // setSubBalance(
        //   parseInt(responseSubscription.data.subscription.balance, 10)
        // );
        setSubscription({
          annual: formatPrice(
            parseFloat(
              (
                parseInt(responseSubscription.data.subscription.annual, 10) /
                10 ** 18
              ).toFixed(2)
            )
          ),
          annual_due: responseSubscription.data.subscription.annual_due,
          monthly: formatPrice(
            parseFloat(
              (
                parseInt(responseSubscription.data.subscription.monthly, 10) /
                10 ** 18
              ).toFixed(2)
            )
          ),
          monthly_due: responseSubscription.data.subscription.monthly_due,
          balance: formatPrice(
            parseFloat(
              (
                parseInt(responseSubscription.data.subscription.balance, 10) /
                10 ** 18
              ).toFixed(2)
            )
          ),
        });
      });
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const fetchSubscription = useCallback(() => {
    getSubscription(connectedMember.memberId);
  }, [connectedMember]);

  useEffect(() => {
    // console.log('+++++ USER +++++');
    // console.log(user);
    if (user !== undefined) {
      fetchSubscription();
      fetchHistory();
      fetchBalances();
    }
  }, [user]);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowFundAccountWallet(false);
    setShowFinish(false);
    setShowNeed(false);
    setShowHowBillingWorks(false);
    setModalProfitiRewards(false);
    setModalPerformanceRewards(false);
    setWithdrawalPoolUsdpiResult('');
    setWithdrawalPerfPoolUsdpiResult('');
    getSubscription(connectedMember.memberId);
  }, [connectedMember]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // UPHOLD
  const handleBuyNowUpholdButtonClick = useCallback(() => {
    const url = `https://uphold.com/en-us/assets/crypto/buy-eth`;
    window.open(url, '_blank');
  }, [ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // XCOINS
  const handleBuyNowXCButtonClick = useCallback(() => {
    const url = `https://xcoins.com/en/buy-crypto/ethereum/`;
    window.open(url, '_blank');
  }, [ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // PAYBIS
  const handleBuyNowPBButtonClick = useCallback(() => {
    const url = `https://paybis.com/buy-ethereum/`;
    window.open(url, '_blank');
  }, [ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleCloseFund = useCallback(() => {
    setFund('');
    setShow(false);
    setShowFundAccountWallet(false);
    setShowFinish(false);
    setIsValid(false);
    setErrorTextFund('');
    setShowHowBillingWorks(false);
  }, []);

  const handleShowTx = useCallback((billing: IBilling) => {
    setBillingSelected(billing);
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Invoice',
        selector: 'invoice',
        sortable: true,
      },
      {
        name: 'Amount',
        selector: 'amount',
        sortable: false,
      },
      {
        name: 'Date',
        selector: 'date',
        sortable: false,
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
        cell: (row: IBilling) => (
          <P className="mb-0" status={row.status}>
            {row.status}
          </P>
        ),
      },
      {
        name: '',
        selector: 'id',
        sortable: true,
        cell: (row: IBilling) => (
          <button
            type="button"
            className="border-0 bg-transparent"
            disabled={row.status !== 'paid'}
            onClick={() => handleShowTx(row)}
          >
            {row.status === 'paid' ? 'View TX' : '-'}
          </button>
        ),
      },
    ],
    []
  );

  const handleSearch = useCallback((value) => {
    setName(value);
  }, []);

  const handleChangeStartDate = useCallback((value) => {
    setStartDate(value);
  }, []);

  const handleChangeLastDate = useCallback((value) => {
    setEndDate(value);
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleClickFundWallet = useCallback(() => {
    // console.log('formattedAmtNeededFund: %s', formattedAmtNeededFund);
    setFund(formattedAmtNeededFund.replace('$', ''));
    setIsValid(true);
    // setShowFundAccountWallet(true);
    setShow(true);
  }, [formattedAmtNeededFund]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const getFundHashes = (usdpi2Send: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        if (account === '') {
          reject(new Error('Wallet address not found'));
        } else {
          // setProcessText('Activating - Preparing transaction');
          api
            .get('/v1/billing/fund/', {
              params: {
                wallet_address: account,
                amt2authorize: usdpi2Send,
              },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              swalError({
                message: 'There was a problem preparing your deposit.',
                textButton: 'Try Again',
              });
              reject(error);
            });
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const getSignature = async (hashData: any) => {
    try {
      if (!account) {
        throw new Error('Wallet address not found');
      } else {
        // setProcessText('Activating - Waiting for wallet signature.');
        const {
          deadline,
          amt2authorize,
          nonce,
          tokname,
          chain,
          spender,
          version,
        } = hashData;
        // console.log('tokname: %s', tokname);
        // const version = '1';
        const domainData = {
          name: tokname,
          version,
          chainId: chain,
          verifyingContract: process.env.REACT_APP_BSC_USDPI,
        };
        const types = {
          Permit: [
            {
              name: 'owner',
              type: 'address',
            },
            {
              name: 'spender',
              type: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
            },
            {
              name: 'nonce',
              type: 'uint256',
            },
            {
              name: 'deadline',
              type: 'uint256',
            },
          ],
        };
        const val = {
          owner: account,
          spender,
          value: amt2authorize,
          nonce,
          deadline,
        };
        // console.log(account);
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        const sig = await tsigner._signTypedData(domainData, types, val); // eslint-disable-line no-underscore-dangle
        return sig;
      }
    } catch (error) {
      swalError({
        message: 'There was a problem Getting your signature.',
        textButton: 'Try Again',
      });
      throw error;
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleUSDPIFundAccount = useCallback(() => {
    return new Promise((resolve, reject) => {
      // console.log('+++++ handleUSDPIFundAccount running +++++');
      // console.log('fund: %s, balusdpi: %s', weiAmt2Exchange, balusdpi);
      const f_fund = parseFloat(weiAmt2Exchange) / 10 ** 6;
      const f_balusdpi = parseFloat(balusdpi) / 10 ** 18;
      console.log('f_fund: %s, f_balusdpi: %s', f_fund, f_balusdpi);
      console.log('f_fund: %2 type: %s', f_fund, typeof f_fund);
      console.log('f_balusdpi: %2 type: %s', f_balusdpi, typeof f_balusdpi);
      if (f_fund > 0 && f_fund > f_balusdpi) {
        setShowNeed(true);
        reject(new Error('Insufficient balance'));
      } else if (f_fund > 0) {
        setProcessText('Preparing deposit - please confirm in wallet');
        setLoading(true);
        networkSwitch('NETWORK')
          .then((response) => {
            const s_fund = utils.parseEther(f_fund.toString()).toString();
            return getFundHashes(s_fund);
          })
          .then((hashes) => {
            // console.log(hashes);
            // Pass 'hashes' along with the next promise
            return getSignature(hashes).then((sigResult) => ({
              hashes,
              sigResult,
            }));
          })
          .then(({ hashes, sigResult }) => {
            // Now 'hashes' is accessible here
            setProcessText('Sending deposit to the blockchain');
            return api.post('/v1/billing/fund', {
              owner: account,
              amt: hashes.amt2authorize,
              deadline: hashes.deadline,
              sig: sigResult,
              nft_id: connectedMember.memberId,
            });
          })
          .then((fundResult) => {
            fetchHistory();
            fetchSubscription();
            setShowFundAccountWallet(false);
            setLoading(false);
            setDepositTX(fundResult.data.tx);
            setDepositTXChain('NETWORK');
            setShowFinish(true);
            resolve(fundResult);
          })
          .catch((error) => {
            setLoading(false);
            swalError({
              message: 'There was a problem with the transaction',
              textButton: 'Try Again',
            });
            reject(error);
          });
      } else {
        reject(new Error('Invalid fund amount'));
      }
    });
  }, [fund, balusdpi, account, connectedMember, weiAmt2Exchange]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleUSDCFundAccount = useCallback(() => {
    return new Promise((resolve, reject) => {
      console.log('+++++ handleUSDCFundAccount running +++++');
      console.log('fund: %s, usdcBalance: %s', fund, usdcBalance);
      const f_fund = parseFloat(weiAmt2Exchange);
      // const f_balusdc = parseFloat(usdcBalance) / 10 ** 6;
      const f_balusdc = parseFloat(usdcBalance);
      console.log('f_fund: %s', f_fund);
      console.log('f_balusdc: %s', f_balusdc);
      if (f_fund > 0 && f_fund > f_balusdc) {
        setShowNeed(true);
        reject(new Error('Insufficient balance'));
      } else if (f_fund > 0) {
        setProcessText('Preparing deposit - please confirm in wallet');
        setLoading(true);
        networkSwitch('PAY')
          .then((response) => {
            // const s_fund = utils.parseEther(f_fund.toString()).toString();
            const s_fund = Math.trunc(f_fund * 1000000).toString();
            if (!usdc) {
              throw new Error('usdc instance is not available');
            }
            return usdc.getRWAHashes(weiAmt2Exchange);
          })
          .then((hashes) => {
            console.log(hashes);
            // Pass 'hashes' along with the next promise
            if (!usdc) {
              throw new Error('usdc instance is not available');
            }
            console.log('+++++ getting signature +++++');
            return usdc.getRWASignature(hashes).then((sigResult) => ({
              hashes,
              sigResult,
            }));
          })
          .then(({ hashes, sigResult }) => {
            console.log(sigResult);
            // Now 'hashes' is accessible here
            setProcessText('Sending deposit to the blockchain');
            return api.post('/v2/billing/fundusdc', {
              owner: account,
              amt: hashes.amt2authorize,
              deadline: hashes.deadline,
              sig: sigResult,
              nft_id: connectedMember.memberId,
              valid_after: hashes.start,
              nonce: ethers.utils.hexZeroPad(`0x${hashes.nonce}`, 32),
              use_account: true,
            });
          })
          .then((fundResult) => {
            fetchHistory();
            fetchSubscription();
            setShowFundAccountWallet(false);
            setLoading(false);
            setDepositTX(fundResult.data.tx);
            setDepositTXChain('NETWORK');
            setShowFinish(true);
            resolve(fundResult);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            swalError({
              message: 'There was a problem with the transaction',
              textButton: 'Try Again',
            });
            reject(error);
          });
      } else {
        reject(new Error('Invalid fund amount'));
      }
    });
  }, [fund, balusdpi, account, connectedMember, weiAmt2Exchange]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleETHFundAccount = useCallback(() => {
    return new Promise((resolve, reject) => {
      console.log('+++++ handleETHFundAccount running +++++');
      console.log(
        'weiAmt2Exchange: %s type: %s',
        weiAmt2Exchange,
        typeof weiAmt2Exchange
      );
      console.log('weithebalance: %s type: %s', ethBalance, typeof ethBalance);
      console.log(isValid);
      const f_amt = parseFloat(weiAmt2Exchange);
      const f_bal = parseFloat(ethBalance);
      if (f_amt > 0 && f_amt > f_bal) {
        setShowNeed(true);
        reject(new Error('Insufficient balance'));
      } else if (f_amt === 0) {
        reject(new Error('Invalid fund amount'));
      } else {
        console.log('+++++ SWAP ETH +++++');
        setProcessText(
          'Preparing Swap.  Please confirm transaction in your wallet.'
        );
        setLoading(true);

        networkSwitch('PAY')
          .then(async (response) => {
            const tprovider = new ethers.providers.Web3Provider(
              window.ethereum
            );
            const tsigner = tprovider.getSigner();
            const swapBank = new ethers.Contract(
              process.env.REACT_APP_ETH_SWAP as string,
              EthUsdtSwapV3Abi,
              tprovider
            );
            // Execute exchange
            const ethExchangeValueFloat = weiAmt2Exchange;
            // parseFloat(weiAmt2Exchange) * 10 ** 18;
            const ethExchangeValue = ethExchangeValueFloat.toString();
            console.log(
              '+++++ ethExchangeValueFloat: %s +++++',
              ethExchangeValueFloat
            );
            console.log('+++++ ethExchangeValue: %s +++++', ethExchangeValue);
            swapBank
              .connect(tsigner)
              .swapEth4Usdt(ethExchangeValue, 0, { value: ethExchangeValue })
              .then(async (tx: ContractTransaction) => {
                setProcessText(
                  'Exchange - waiting for transaction to complete'
                );
                console.log('Transaction sent successfully:', tx);

                setProcessText('Waiting for blockchain confirmations.');
                try {
                  // Define the number of confirmations you want to wait for
                  const desiredConfirmations = 3;

                  console.log(
                    `Waiting for ${desiredConfirmations} confirmations...`
                  );
                  const receipt = await tsigner.provider.waitForTransaction(
                    tx.hash,
                    desiredConfirmations
                  );

                  console.log(
                    `Transaction confirmed with ${receipt.confirmations} confirmations:`,
                    receipt
                  );
                  setProcessText('Minting USDPI to your account.');
                  // logExchange(tx.hash)
                  api
                    .post('/v1/usdpi/depositacct/usdt/tx', {
                      nft_id: user?.id,
                      tx: tx.hash,
                    })
                    .then(function (acctresponse) {
                      setProcessText(
                        'Exchange accepted. Waiting for bridged USDPI to arrive.'
                      );
                      setLoading(false);
                      fetchSubscription();
                      setShowFundAccountWallet(false);
                      setLoading(false);
                      setDepositTX(tx.hash);
                      setDepositTXChain('PAY');
                      setShowFinish(true);
                      resolve('Complete');
                    })
                    .catch(function (logError) {
                      console.log('== ERROR ==');
                      console.error(logError);
                      setLoading(false);
                      reject(logError);
                    });
                } catch (waitError) {
                  console.error(
                    'Error while waiting for transaction confirmations:',
                    waitError
                  );
                  setLoading(false);
                  reject(waitError);
                }
              })
              .catch((ethSwapError: Error) => {
                console.error('Error during swap:', ethSwapError);
                setLoading(false);
                reject(ethSwapError);
              });
          })
          .catch((switchError) => {
            console.log(switchError);
            setLoading(false);
            swalError({
              message: 'There was a problem switching networks in your wallet',
              textButton: 'Try Again',
            });
            reject(switchError);
          });
      }
    });
  }, [fund, balusdpi, account, weiAmt2Exchange, ethBalance]);

  useEffect(() => {
    console.log('+++++ weiAmt2Exchange updated: %s +++++', weiAmt2Exchange);
  }, [weiAmt2Exchange, isValid]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleSubmitFundAccount = useCallback(() => {
    if (selectedCurrency === 'USDPI') {
      handleUSDPIFundAccount()
        .then((result) => {
          // Handle success
          console.log('SUCCESS');
        })
        .catch((error) => {
          // Handle error
          console.log('FAIL');
        });
    } else if (selectedCurrency === 'USDC') {
      console.log('Depositing USDC');
      handleUSDCFundAccount()
        .then((result) => {
          // Handle success
          console.log('SUCCESS');
        })
        .catch((error) => {
          // Handle error
          console.log('FAIL');
        });
    } else if (selectedCurrency === 'ETH') {
      console.log('Depositing ETH');
      handleETHFundAccount()
        .then((result) => {
          // Handle success
          console.log('SUCCESS');
          setLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.log('FAIL');
          setLoading(false);
        });
    }
  }, [handleUSDPIFundAccount, selectedCurrency, weiAmt2Exchange, ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Input delay
  useEffect(() => {
    const handler = setTimeout(() => {
      console.log('setting debouncedFund to: %s', fund);
      setDebouncedFund(fund);
    }, 1000); // 500 ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [fund]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const convertDollarsToWei = (dollarAmount: string): string => {
    // Assuming 1 dollar is equivalent to 1,000,000 Wei (10^6)
    const conversionRate = 1e6;

    // Convert the dollar amount to a number, then multiply by the conversion rate
    const amountInWei = parseFloat(dollarAmount) * conversionRate;

    // Return as a string rounded to 6 decimal places
    return amountInWei.toFixed(0);
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const formatWeiToEther = (weiValue: string): string => {
    const weiPerEther = BigInt(1e18);
    const wei = BigInt(weiValue);
    const ether = (wei * BigInt(1e6)) / weiPerEther; // Perform the conversion

    // Convert to a string
    let etherString = ether.toString();

    // Ensure the string has 6 characters after the decimal point
    while (etherString.length < 6) {
      etherString = `0${etherString}`; // Pad with leading zeros if necessary
    }

    // Insert decimal point to create a fixed 6 decimal places
    etherString = `${etherString.slice(0, -6)}.${etherString.slice(-6)}`;

    // Ensure there is a leading zero for amounts less than 1
    if (etherString.startsWith('.')) {
      etherString = `0${etherString}`;
    }

    return etherString;
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Exchange rate API
  const convertCurrency = useCallback(
    async (amount: string, token: string) => {
      console.log('calling api with %s type: %s', amount, typeof amount);
      const amountWei = convertDollarsToWei(amount);
      console.log('amount to test: %s of token: %s', amountWei, token);

      if (token === 'ETH') {
        const response = await api.get(
          `/v1/exchange/quote/USD/ETH/${amountWei}`
        );
        console.log('ETH - setWeiAmt2Exchange: %s', response.data.estimate.net);
        setWeiAmt2Exchange(response.data.estimate.net);
        const formattedValue = formatWeiToEther(response.data.estimate.net);
        setWeiAmt2ExchangeF(formattedValue);
      } else if (token === 'BNB') {
        setWeiAmt2Exchange('0');
        setWeiAmt2ExchangeF('0.000000');
      } else if (token === 'USDC') {
        // setWeiAmt2Exchange(amountWei);
        // setWeiAmt2ExchangeF(parseFloat(amount).toFixed(2));
        console.log('calling update fee');
        console.log(feeTimestamp);
        if (usdc) {
          console.log('calling refreshFeeAndTimestamp');
          usdc.refreshFeeAndTimestamp(feeTimestamp, debouncedFund);
        }
      } else {
        // console.log('ELSE!!!');
        setWeiAmt2Exchange(amountWei);
        setWeiAmt2ExchangeF(parseFloat(amount).toFixed(2));
      }
      // Other logic
    },
    [setWeiAmt2Exchange, setWeiAmt2ExchangeF, feeTimestamp, usdc, debouncedFund]
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Get exchange rate
  useEffect(() => {
    if (isValid) {
      console.log('converting currency');
      convertCurrency(debouncedFund, selectedCurrency);
    } else {
      console.log('INVALID');
    }
  }, [debouncedFund, selectedCurrency, isValid]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Input form change
  const handleFundChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Regular expression to match USD currency format (e.g., $1,234.56)
    // Allows amounts from $0.00 to $99,999.99 but we will check for the minimum value separately
    const usdRegex = /^(?:\$?\d{1,5}(?:,\d{3})*(?:\.\d{2})?|\$?99999.99)$/;

    // Check if the input matches the USD format
    const isFormatValid = usdRegex.test(inputValue);

    // Convert the input value to a numerical value for minimum value validation
    // Remove the dollar sign and commas before converting
    const numericValue = Number(inputValue.replace(/[$,]/g, ''));

    // Check for minimum value ($1.00 in this example)
    const isValueValid = numericValue >= 1.0;

    // Determine if the input is overall valid (both format and value)
    const isValidInput = isFormatValid && isValueValid;

    // Update the state with the validity of the input
    setIsValid(isValidInput);
    setFund(inputValue);

    // Set the error text conditionally based on whether the input is valid or not
    let errorTextValidate = '';
    if (inputValue === '') {
      errorTextValidate = '';
    } else if (!isFormatValid) {
      errorTextValidate = 'Please enter a valid USD amount (e.g., $1,234.56).';
    } else if (!isValueValid) {
      errorTextValidate = 'Amount must be at least $1.00.';
    }

    // Update the error text state
    setErrorTextFund(errorTextValidate);
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Format amt. needed for fund window
  useEffect(() => {
    // Format the amtNeededFund as USD currency when it changes
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amtNeededFund);

    setFormattedAmtNeededFund(formattedValue);
  }, [amtNeededFund]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Set amt. needed for fund window
  useEffect(() => {
    const n_acctBalance = parseFloat(acctBalance);
    if (subDue > n_acctBalance) {
      setAmtNeededFund(subDue - n_acctBalance);
    } else {
      setAmtNeededFund(0);
    }
  }, [subDue, balusdpi, subscription.balance, acctBalance]);

  const getProfitiPoolAvailable = async () => {
    console.log('getProfitiPoolAvailable');
    api
      .get<IProfitiResponse>(`v1/member/pool/${user?.id}`)
      .then(async (responseProfiti) => {
        setProfiti([
          {
            id: 1,
            // available: responseProfiti.data.pool.available,
            available: formatPrice(
              parseFloat(
                (
                  parseInt(responseProfiti.data.pool.available, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            claimed: formatPrice(
              parseFloat(
                (
                  parseInt(responseProfiti.data.pool.claimed, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
          },
        ]);
      });
  };

  const getPerfPoolAvailable = async () => {
    console.log('getPerfPoolAvailable');
    api
      .get<IPerformanceResponse>(`v3/member/perfpool/${user?.id}`)
      .then(async (responsePerfpool) => {
        setPerformance([
          {
            id: 1,
            // available: responsePerfpool.data.ppool.available,
            available: formatPrice(
              parseFloat(
                (
                  parseInt(responsePerfpool.data.ppool.available, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            claimed: formatPrice(
              parseFloat(
                (
                  parseInt(responsePerfpool.data.ppool.claimed, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
          },
        ]);
      });
  };

  useEffect(() => {
    if (show) {
      console.log('fetch claims');
      getProfitiPoolAvailable();
      getPerfPoolAvailable();
    }
  }, [show, withdrawalPoolUsdpiResult]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // BUY USDPI NOW BUTTON
  const handleBuyNowButtonClick = useCallback(() => {
    // console.log('+++++ amtNeededFund +++++');
    // console.log(amtNeededFund);
    setShowNeed(false);
    setFund('');
    setShowFundAccountWallet(false);
    setIsValid(false);
    setErrorTextFund('');
    const exchangeUSDPIValue = amtNeededFund.toString();
    const usdpiId = '5910b23a-0fa6-46f8-ab1c-6e6a71cc0a69';
    const clientId = '9594c88f-2edf-407f-82da-b4ca569690be';
    const url = `https://pay.c14.money/?clientId=${clientId}&targetAssetId=${usdpiId}&targetAmount=${fund}&targetAddress=${connectedMember.memberId}&bgColor=282828&mainColor=17171a&targetAssetIdLock=true`;
    window.open(url, '_blank');
  }, [connectedMember.memberId, amtNeededFund, fund]);

  const clickOnHowBillingWorks = () => {
    setShowHowBillingWorks(true);
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // CLAIM POOL REWARDS
  const handleClickClaimPoolUsdpi = useCallback(() => {
    networkSwitch('NETWORK')
      .then((response) => {
        api
          .get('/v1/net/fee/estimate', {
            params: {
              registered: 2,
              owner: account,
            },
          })
          .then(function ({
            data: {
              estimate: { fee },
            },
          }) {
            // const fee = feeresponse.data.estimate.fee;
            interface Request {
              nftId: number;
              userAddress: string;
              message: string;
              deadline: number;
              transaction_fee: BigNumber;
            }
            // console.log(feeresponse);
            // processClaimPool(response.data.estimate.fee);
            const purser = process.env.REACT_APP_BSC_PURSER;
            console.log('verifyingContract: %s', purser);
            const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
            console.log('deadline: %s', deadline);
            const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
            const chainInt = parseInt(chainNo || '0', 16);
            console.log(chainInt);
            const domain = {
              name: 'Purser',
              version: '1',
              chainId: chainInt,
              verifyingContract: purser,
            };
            const types = {
              Request: [
                { name: 'nftId', type: 'uint256' },
                { name: 'userAddress', type: 'address' },
                { name: 'message', type: 'string' },
                { name: 'deadline', type: 'uint256' },
                { name: 'transaction_fee', type: 'uint256' },
              ],
            };
            const transaction_fee = BigNumber.from(fee);
            const transaction_fee_in_ether = parseFloat(
              ethers.utils.formatEther(transaction_fee)
            );
            const message = `I authorize this withdrawal, less a transaction fee of $${transaction_fee_in_ether.toFixed(
              2
            )}`;
            console.log(message);
            const request: Request = {
              nftId: connectedMember.memberId,
              userAddress: account,
              message,
              deadline,
              transaction_fee: BigNumber.from(fee),
            };
            const tprovider = new ethers.providers.Web3Provider(
              window.ethereum
            );
            const tsigner = tprovider.getSigner();
            tsigner
              ._signTypedData(domain, types, request)
              .then((sig) => {
                console.log('sig: %s', sig);
                api
                  .post('/v1/staking/claimrewards', {
                    nftId: connectedMember.memberId,
                    userAddress: account,
                    message,
                    deadline,
                    signature: sig,
                    transaction_fee: parseInt(fee, 10),
                  })
                  .then(function (poolresponse) {
                    console.log(poolresponse.data.tx);
                    setModalProfitiRewards(true);
                    setWithdrawalPoolUsdpiResult(poolresponse.data.tx);
                    getProfitiPoolAvailable();
                    // setRunClaimPoolUsdpi(false);
                    // setWithdrawalPoolUsdpiResult(response.data.tx);
                  })
                  .catch(function (error) {
                    console.log('================= ERROR ===================');
                    console.log(error);
                    // setRunClaimPoolUsdpi(false);
                    // setWithdrawalPoolUsdpiResult('ERROR');
                  });
              })
              .catch((sigerror) => {
                console.error(sigerror);
              });
          })
          .catch(function (error) {
            console.log('================= ERROR ===================');
            console.log(error);
          });
      })
      .catch((error) => {
        swalError({
          message: 'There was a problem with your transaction.',
          textButton: 'Try Again',
        });
      });
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // CLAIM PERF POOL REWARDS
  const handleClickClaimPerfPoolUsdpi = useCallback(() => {
    const testAmt =
      performance[0]?.available === undefined
        ? 0
        : parseFloat(performance[0]?.available.replace(/,/g, ''));
    console.log(performance[0]?.available);
    if (testAmt >= 1) {
      api
        .get('/v1/net/fee/estimate')
        .then(function (response) {
          api
            .post('/v3/perfpool/claimrewards', {
              nftId: user?.id,
              fee: parseInt(response.data.estimate.fee, 10),
            })
            .then(function (claimresponse) {
              setWithdrawalPerfPoolUsdpiResult(claimresponse.data.tx);
              setModalPerformanceRewards(true);
            })
            .catch(function (error) {
              console.log('================= ERROR ===================');
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
        });
    }
  }, [performance]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-sm-11 col-lg-12 mb-2 mt-4">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-center mb-4">
                  <div className="col-lg-6 px-0">
                    <h1 className="h3 h2-lg font-weight-bold mb-lg-0">
                      Subscriptions
                    </h1>
                    <p>Manage your plan and billing history</p>
                  </div>
                  <div className="col-lg-6 px-0">
                    <WalletRow />
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <BtnHowBillingWorks onClick={clickOnHowBillingWorks}>
            <img src={info} alt="info" />
            Learn how billing works - <strong>Click Here</strong>
          </BtnHowBillingWorks>

          <div className="col-lg-4">
            <div className="box px-5 py-4">
              <p className="mb-0">Yearly - Affiliate Activation</p>
              <h2>${subscription.annual}/Year</h2>
              {/* <p className="small">Due on: April 12th, 2024</p> */}
              <p className="small">
                Due on:{' '}
                {formatToCommonDate(parseInt(subscription.annual_due, 10))}
              </p>
              <div className="infos d-flex align-items-start">
                <img src={checkmark} alt="checkmark" />
                <small className="d-block mt-n1">
                  Includes access to all current and future products & $50 worth
                  of PRFTI Token Credits
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box px-5 py-4">
              <p className="mb-0">Monthly Subscription</p>
              <h2>${subscription.monthly}/Month</h2>
              {/* <p className="small"> */}
              <p className="small">
                Due on:{' '}
                <span
                  className={`
                  ${parseInt(subscription.monthly_due, 10) === 0 ? 'green' : ''}
                    `}
                >
                  {parseInt(subscription.monthly_due, 10) === 0
                    ? 'Current'
                    : formatToCommonDate(
                        parseInt(subscription.monthly_due, 10)
                      )}
                </span>
              </p>
              <p className="mb-0">Total Subscription Due</p>
              {/* <h2 className="amt-due-h2">${subDue}</h2> */}
              <h2 className="amt-due-h2">
                $
                {subDue.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h2>
              <p className="small">Includes current & unpaid invoices</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box px-5 py-4 last">
              <p className="mb-0">Wallet balance</p>
              <p className="mb-0 small">
                <em>(includes pre-pay and deposits)</em>
              </p>
              <h2>${subscription.balance}</h2>
              <div className="infos d-flex align-items-start errors">
                {/* <img src={xCircle} alt="xCircle" /> */}
                <small className="d-block mt-n1">
                  {/* Not enough to pay subscription */}
                  {/* {subBalance >= amtNeeded
                    ? '\u00A0'
                    : 'Not enough to pay subscription'} */}
                </small>
                {/* <img src={info} alt="info" /> */}
              </div>
              <button
                type="button"
                className="btn btn-fund-wallet"
                onClick={handleClickFundWallet}
              >
                Pay Subscription
              </button>
            </div>
          </div>
          <div className="col-sm-11 col-lg-12 mt-4">
            <div className="bg-gray table p-2 p-lg-4">
              <Table
                title="Billing History"
                columns={columns}
                data={billings}
                // searchable
                onSearch={handleSearch}
                date
                initialDate={startDate}
                finalDate={endDate}
                onChangeStartDate={handleChangeStartDate}
                onChangeLastDate={handleChangeLastDate}
                exportable
                pagination
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: stakingTerms,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={220}
              width={300}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mb-4 fw-bold text-center w-100">
            Not enough to pay subscription?
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4">
              Remember to check your Revenue Share Rewards account and the
              Performance pool. If you qualify for them, likely there could be
              funds waiting for you there.
            </p>
            <div className="d-flex terms justify-content-between px-5 mb-2">
              <div className="d-flex justify-content-between w-100 me-4">
                <span>PROFITi Revenue Share Pool Available Rewards:</span>
                <span className="color-span">
                  {profiti[0]?.available !== undefined
                    ? profiti[0].available
                    : 0}{' '}
                  USDPI
                </span>
              </div>
              <button
                type="button"
                className="claim px-3 py-2 border-0"
                onClick={handleClickClaimPoolUsdpi}
              >
                Claim
              </button>
            </div>

            <div className="d-flex terms justify-content-between px-5 mb-2">
              <div className="d-flex justify-content-between w-100 me-4">
                <span>Performance Pool Available Rewards:</span>
                <span className="color-span">
                  {performance[0]?.available !== undefined
                    ? performance[0].available
                    : 0}{' '}
                  USDPI
                </span>
              </div>
              <button
                type="button"
                className="claim px-3 py-2 border-0"
                onClick={handleClickClaimPerfPoolUsdpi}
              >
                Claim
              </button>
            </div>

            <div className="d-flex terms justify-content-between px-5 mb-2">
              <div className="d-flex justify-content-between w-40 me-4">
                <span>Need to buy USDPI?</span>
              </div>
              <button
                type="button"
                className="buy px-3 py-2 border-0"
                onClick={() => setShowNeed(true)}
              >
                Buy USDPI With Your Debit Card Here
              </button>
            </div>

            <p className="text-center mt-4">
              Amount Needed: <b>{formattedAmtNeededFund} USDPI</b>
            </p>

            <button
              type="button"
              onClick={() => {
                // setShowFinish(true);
                setShowFundAccountWallet(true);
              }}
              className="btn-confirm w-100 my-4"
            >
              Deposit{' '}
              <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn-done w-100 mb-3"
            >
              <span className="">DONE</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>

      <Modal
        size="xl"
        show={showFundAccountWallet}
        onHide={handleCloseFund}
        className="modal-wrong-network"
      >
        <Form onSubmit={handleSubmitFundAccount}>
          <button
            type="button"
            className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
            onClick={handleCloseFund}
          >
            x
          </button>
          <Modal.Header className="px-4 px-lg-5 justify-content-center border-0 pb-0 pb-lg-0 pt-5">
            <div className="mt-4 d-flex align-items-center justify-content-center overflow-hidden w-100 px-4">
              <div className="text-center">
                <Modal.Title className="pe-3 fw-semibold fund-wallet-modal-title">
                  Deposit USDPI from your wallet
                </Modal.Title>
                <div className="fund-wallet-modal-title-2">
                  See your connected wallet available balances below
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="px-4 px-lg-5 pt-5">
            <div className="d-flex flex-row flex-wrap justify-content-center justify-content-xl-between">
              {currencyChoices.map((currency, index) => (
                <BtnCurrency
                  type="button"
                  key={currency.name}
                  className="mb-5 mx-2 mx-xl-0"
                  selected={currency.name === selectedCurrency}
                  disabled={currency.disabled}
                  onClick={() => {
                    if (!currency.disabled) {
                      handleCurrencyClick(currency.name);
                    }
                  }}
                  {...(currency.tooltip ? { title: currency.tooltip } : {})}
                >
                  <img src={currency.icon} alt={currency.name} />
                  <span className="currency-name">{currency.name}</span>
                  <span className="currency-balance">
                    {currency.balance_formatted}
                  </span>
                </BtnCurrency>
              ))}
            </div>
            <label className="d-block w-100">
              <span className="d-block mb-4 amt-needed-text">
                <span>Subscription Amount Due:</span> {formattedAmtNeededFund}
                {selectedCurrency === 'USDC' && (
                  <>
                    {' + '}
                    {feeTimestamp.feeFormatted}
                    <i>
                      {' '}
                      (Transaction Fees){' '}
                      <img src={infoGreen} title="Coming soon" alt="Info" />
                    </i>
                  </>
                )}
              </span>
              <Input
                name="fund"
                type="number"
                placeholder="Enter amount you wish to fund wallet here"
                className="input amt-needed-input"
                value={fund}
                onChange={handleFundChange}
              />
              {isValid ? null : (
                <p className="error-message">{errorTextFund}</p>
              )}
              <div className="d-flex justify-content-center align-items-center">
                <span className="eth-needed-text">
                  {selectedCurrency} Needed:&nbsp;
                </span>
                <span className="eth-needed-amount">{weiAmt2ExchangeF}</span>
              </div>
            </label>
          </Modal.Body>
          <Modal.Footer className="d-flex border-0 px-4 px-lg-5 pt-4 pb-5 mb-4 justify-content-between">
            <button
              type="button"
              className="order-1 order-lg-0 w-100 w-lg-25 btn btn-cancel"
              onClick={handleCloseFund}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="order-0 order-lg-1 w-100 w-lg-70 btn btn-fund-wallet mb-4 mb-lg-0"
              disabled={!isValid}
            >
              Fund Wallet To Pay Subscription
            </button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ModalFinish
        size="xl"
        show={showFinish}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <ModalFinish.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: earningsDone,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={220}
              width={300}
            />
          </div>
        </ModalFinish.Header>
        <ModalFinish.Body className="px-4 px-sm-5">
          <h2 className="mb-4 fw-bold text-center w-100">
            Wallet Successfully Funded!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4 px-xl-5">
              Your account wallet was successfully funded. If you have a balance
              due for your subscription, the amount funded will be applied
              towards it.
            </p>
            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${
                  depositTXChain === 'NETWORK' ? explorerURL : explorerURLEth
                }/tx/${depositTX}`}
                // href={`${explorerURL}/tx/${depositTX}`}
                target="_blank"
                rel="noreferrer"
              >
                {depositTX}
              </a>
            </div>
            <p className="mb-4">
              * Unpaid subscription invoices are automatically paid once your
              account has enough funds from commissions and deposits.
            </p>
          </div>
        </ModalFinish.Body>
        <ModalFinish.Footer className="border-0 py-4" />
      </ModalFinish>
      {/* Buy USDPI modal */}
      <ModalNeed
        size="lg"
        show={showNeed}
        onHide={handleClose}
        className="modal-signin-auto"
        centered
      >
        <button
          type="button"
          className="h4 modal-close m-2 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden pe-none">
            <Lottie
              options={{
                animationData: stakingTerms,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={157}
              width={157}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mb-2 fw-bold text-center w-100">Need Funds?</h2>
          <div className="texts w-100 px-3">
            <p className="text-center mb-4">
              We could not find ETH, BNB or USPI in your wallet
            </p>
            <div className="d-flex btns-coin justify-content-between">
              {/* <button
                type="button"
                className={`${selectCoin === 'usdpi' && 'active'}`}
                onClick={() => setSelectCoin('usdpi')}
              >
                <img src={currencyUSDPI} alt="USDPI" /> USDPI
              </button>
              <button
                type="button"
                className={`${selectCoin === 'usdc' && 'active'}`}
                onClick={() => setSelectCoin('usdc')}
              >
                <img src={currencyUSDC} alt="USDC" /> USDC
              </button>
              <button
                type="button"
                className={`${selectCoin === 'eth' && 'active'}`}
                onClick={() => setSelectCoin('eth')}
              >
                <img src={currencyETH} alt="ETH" /> ETH
              </button>
              <button
                type="button"
                className={`${selectCoin === 'bnb' && 'active'}`}
                onClick={() => setSelectCoin('bnb')}
              >
                <img src={currencyBNB} alt="BNB" /> BNB
              </button> */}
            </div>
            <p className="my-4">
              <span>Buy at least</span> 0.235 {selectCoin.toUpperCase()}
            </p>
          </div>
          <div className="w-lg-90 mx-auto">
            <p className="text-worry text-center mb-4">
              Don’t worry, you can buy it instantly at the{' '}
              <span className="fw-semibold">options&nbsp;below:</span>
            </p>
            {selectCoin === 'usdpi' && (
              <div>
                <button
                  type="button"
                  className="btn-c14 w-100 my-1"
                  onClick={handleBuyNowButtonClick}
                >
                  BUY USDPI NOW AT C14{' '}
                  <HiArrowNarrowRight className="ms-3" color="#000" size={28} />
                </button>
              </div>
            )}
            {selectCoin === 'usdc' && (
              <div>
                <button type="button" className="btn-c14 w-100 my-1">
                  BUY USDC NOW AT C14{' '}
                  <HiArrowNarrowRight className="ms-3" color="#000" size={28} />
                </button>
                <button type="button" className="btn-paybis w-100 my-1">
                  BUY USDC NOW AT PAYBIS{' '}
                  <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
                </button>
                <button type="button" className="btn-xcoins w-100 my-1">
                  BUY USDC NOW AT XCOINS{' '}
                  <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
                </button>
                <button type="button" className="btn-uphold w-100 my-1">
                  BUY USDC NOW AT UPHOLD{' '}
                  <HiArrowNarrowRight className="ms-3" color="#000" size={28} />
                </button>
              </div>
            )}
            {selectCoin === 'eth' && (
              <div>
                <button
                  type="button"
                  className="btn-uphold w-100 my-1"
                  onClick={handleBuyNowUpholdButtonClick}
                >
                  BUY ETH NOW AT UPHOLD{' '}
                  <HiArrowNarrowRight className="ms-3" color="#000" size={28} />
                </button>
                <button type="button" className="btn-xcoins w-100 my-1">
                  BUY ETH NOW AT COINBASE{' '}
                  <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
                </button>
              </div>
            )}
            {selectCoin === 'bnb' && (
              <div>
                <button type="button" className="btn-c14 w-100 my-1">
                  BUY BNB NOW AT C14{' '}
                  <HiArrowNarrowRight className="ms-3" color="#000" size={28} />
                </button>

                <button type="button" className="btn-xcoins w-100 my-1">
                  BUY BNB NOW AT COINBASE{' '}
                  <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
                </button>
              </div>
            )}
            <button
              type="button"
              onClick={handleClose}
              className="btn-cancel w-100 my-1"
            >
              <span className="">Cancel</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </ModalNeed>
      <ModalHowBillingWorks
        showModal={showHowBillingWorks}
        handleClose={handleClose}
      />
      <ModalRewards
        size="xl"
        show={modalPerformanceRewards}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <ModalRewards.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-5 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={walletFinish} alt="Wallet" />
          </div>
        </ModalRewards.Header>
        <ModalRewards.Body className="px-4 px-sm-5">
          <h2 className="mt-n5 mb-4 fw-bold text-center w-100">
            Rewards Claimed!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4 px-xl-2">
              Your Performance Pool shares have been successfully claimed and
              applied to your account.
            </p>
            <p className="text-center mb-4 px-xl-2">
              Please note: If you still have a balance due for your next month
              subscription the rewards will be allocated ot that wallet first.
            </p>
            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${explorerURL}/tx/${withdrawalPerfPoolUsdpiResult}`}
                target="_blank"
                rel="noreferrer"
              >
                {withdrawalPerfPoolUsdpiResult}
              </a>
            </div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn-confirm w-100 mt-0 mb-4"
            >
              <span className="">Done</span>
            </button>
          </div>
        </ModalRewards.Body>
        <ModalRewards.Footer className="border-0 py-4" />
      </ModalRewards>
      <ModalRewards
        size="xl"
        show={modalProfitiRewards}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <ModalRewards.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-5 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={walletFinish} alt="Wallet" />
          </div>
        </ModalRewards.Header>
        <ModalRewards.Body className="px-4 px-sm-5">
          <h2 className="mt-n5 mb-4 fw-bold text-center w-100">
            Rewards Claimed!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4 px-xl-4">
              Your PROFITi Revenue Share rewards have been successfully claimed
              and applied to your account.
            </p>
            <p className="text-center mb-4 px-xl-2">
              Please note: If you still have a balance due for your next month
              subscription the rewards will be allocated ot that wallet first.
            </p>
            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${explorerURL}/tx/${withdrawalPoolUsdpiResult}`}
                target="_blank"
                rel="noreferrer"
              >
                {withdrawalPoolUsdpiResult}
              </a>
            </div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn-confirm w-100 mt-0 mb-4"
            >
              <span className="">Done</span>
            </button>
          </div>
        </ModalRewards.Body>
        <ModalRewards.Footer className="border-0 py-4" />
      </ModalRewards>
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="PLEASE WAIT..."
        active={loading}
      />
    </Container>
  );
};

export default Referrers;
