/* eslint-disable import/no-duplicates */
import React from 'react';

import { HiOutlineVideoCamera } from 'react-icons/hi';

import { Container, Welcome, ContainerVideoListing } from './styles';

const Training: React.FC = () => {
  const videoListing = [
    {
      id: 1,
      Thumbnail: 'https://placeimg.com/800/500/any',
      timer: '10:00',
      title: 'How to connect new partner?',
      avatar: 'https://placeimg.com/50/50/any',
      legend: 'by Legacy Video',
    },
    {
      id: 2,
      Thumbnail: 'https://placeimg.com/800/500/any',
      timer: '10:00',
      title: 'How to connect new partner?',
      avatar: 'https://placeimg.com/50/50/any',
      legend: 'by Legacy Video',
    },
    {
      id: 3,
      Thumbnail: 'https://placeimg.com/800/500/any',
      timer: '10:00',
      title: 'How to connect new partner?',
      avatar: 'https://placeimg.com/50/50/any',
      legend: 'by Legacy Video',
    },
    {
      id: 4,
      Thumbnail: 'https://placeimg.com/800/500/any',
      timer: '10:00',
      title: 'How to connect new partner?',
      avatar: 'https://placeimg.com/50/50/any',
      legend: 'by Legacy Video',
    },
    {
      id: 5,
      Thumbnail: 'https://placeimg.com/800/500/any',
      timer: '10:00',
      title: 'How to connect new partner?',
      avatar: 'https://placeimg.com/50/50/any',
      legend: 'by Legacy Video',
    },
    {
      id: 6,
      Thumbnail: 'https://placeimg.com/800/500/any',
      timer: '10:00',
      title: 'How to connect new partner?',
      avatar: 'https://placeimg.com/50/50/any',
      legend: 'by Legacy Video',
    },
  ];

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 mb-2">
            <Welcome>
              <div className="container-fluid mt-4 ">
                <div className="row align-items-center justify-content-end justify-content-sm-between">
                  <div className="col-sm-6 px-0 order-1 order-sm-0 mt-4 mt-sm-0">
                    <h1 className="h3 h2-lg ps-3 mb-0 mt-2 mt-lg-0 fw-bold">
                      Trainings
                    </h1>
                  </div>
                  {/* <div className="col-8 col-sm-6 col-md-3 mt-4 mt-sm-0 order-0 order-sm-1">
                    <div className="ms-auto border-gradient-light rounded-pill text-center">
                      <button
                        type="button"
                        className="w-100 border-0 py-2 bg-transparent d-flex align-items-center justify-content-center"
                      >
                        <HiOutlineVideoCamera
                          size={30}
                          color="#dadada"
                          className="me-2"
                        />
                        New training
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </Welcome>
          </div>
        </div>

        <div className="row mt-5">
          {videoListing.map((video) => (
            <ContainerVideoListing
              to={`${process.env.PUBLIC_URL}/learning/${video.title
                .toLocaleLowerCase()
                .replaceAll(' ', '-')}`}
              className="col-md-6 col-xl-4 mb-5"
            >
              <div className="w-100 position-relative mb-2">
                <img
                  src={video.Thumbnail}
                  className="thumbnail img-fluid w-100"
                  alt="thumbnail"
                />
                <span className="position-absolute duration">
                  {video.timer}
                </span>
              </div>
              <p className="my-3 details-link h5">{video.title}</p>
              <div className="d-flex align-items-center">
                <img
                  src={video.avatar}
                  alt="Avatar"
                  className="rounded-circle avatar me-3"
                />
                <p className="mb-0">{video.legend}</p>
              </div>
            </ContainerVideoListing>
          ))}
        </div>
      </div>
    </Container>
  );
};
export default Training;
