import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ethers } from 'ethers';
import { Form } from '@unform/web';
import { useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { HiOutlineInformationCircle, HiArrowNarrowRight } from 'react-icons/hi';
// import InputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';
// import { PatternFormat } from 'react-number-format';

import Swal from 'sweetalert2';
import getValidationErros from '~/utils/getValidationsErrors';

import api from '~/services/api';
import swalError from '~/utils/swalError';

import { Button, Card, Modal, SelectCoin } from './styles';
// import InputMask from '~/components/InputMask';
import ModalConfirmation from '~/pages/Sales/ModalConfirmation';
import Loading from '~/components/Loading';

import logoSwap from '~/assets/logo/logo-swap.png';
import logoBusd from '~/assets/logo/logo-busd.svg';
import logoUsdpi from '~/assets/logo/logo-usdpi.svg';
import logoEth from '~/assets/logo/logo-eth.svg';
import logoBnb from '~/assets/logo/logo-bnb.svg';
import logoUsdt from '~/assets/logo/logo-usdt.svg';
import logoUsdc from '~/assets/logo/logo-usdc.svg';
import logoGusd from '~/assets/logo/logo-gusd.svg';
import arrows from '~/assets/icons/arrows-swap.svg';
import { web3store } from '~/store';
import { formatPrice, formatEthValue } from '~/utils/format';

import logoWhite from '~/assets/logo/logo-p-white.svg';
import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';

interface IProps {
  btnText: string;
  className?: string;
  onExchangeFinished?(): void;
  showNft?: boolean;
  disabled?: boolean;
}

interface IToken {
  logo: string;
  name: string;
  balance: string;
  balanceWei: string;
  cardStyle: string;
  decimalPlaces: number;
  wei: number;
}

interface INetwork {
  logo: string;
  name: string;
}

const ModalSwap: React.FC<IProps> = ({
  btnText,
  className,
  onExchangeFinished,
  disabled,
}) => {
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);
  const [totalNeeded, setTotalNeeded] = useState(0);
  const [invertCard, setInvertCard] = useState(false);
  const [balbusd] = web3store.useState('balbusd');
  const [balusdpi] = web3store.useState('balusdpi');
  const [baleth] = web3store.useState('baleth');
  const [balusdt] = web3store.useState('balusdt');
  const [exchangeValue, setExchangeValue] = useState('0,00');
  const [feeValue, setFeeValue] = useState('0,00');
  const [receiveValue, setReceiveValue] = useState('0,00');
  const [error, setError] = useState('');
  const [showCongrat, setShowCongrat] = useState(false);
  const [, setCartPurchase] = web3store.useState('cartPurchase');
  const [, setUsdpiPurchaseAmt] = web3store.useState('usdpiPurchaseAmt');
  const [, setUsdpiPurchaseAmtEth] = web3store.useState('usdpiPurchaseAmtEth');
  const [, setUsdpiPurchaseAmtUsdt] = web3store.useState(
    'usdpiPurchaseAmtUsdt'
  );
  const [, setUsdpiSaleAmt] = web3store.useState('usdpiSaleAmt');
  const [, setUsdpiPurchaseFee] = web3store.useState('usdpiPurchaseFee');
  const [isAlertOpen, setIsAlertOpen] = useState(true);
  const [cartAmt] = web3store.useState('cartAmt');
  const [buyStakingAmt] = web3store.useState('buyStakingAmt');
  const [usdpiPurchaseTX, setUsdpiPurchaseTX] =
    web3store.useState('usdpiPurchaseTX');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorExchange, setErrorExchange] = useState('');
  const [intervalId, setIntervalId] = useState<number | undefined>(undefined);
  const [refBalances, setRefBalances] = web3store.useState('refBalances');
  const [tokenChain, setTokenChain] = web3store.useState('tokenChain');
  const [chainMode, setChainMode] = web3store.useState('chainMode');
  const [holdChainMode, setHoldChainMode] = useState(chainMode);
  const [skipModal, setSkipModal] = web3store.useState('skipModal');
  const [inputValue, setInputValue] = useState('');
  const [inputMask, setInputMask] = useState('999.99');
  const [decimalPlaces, setDecimalPlaces] = useState(6);
  // Balance polling
  const [balanceInterval, setBalanceInterval] =
    web3store.useState('balanceInterval');
  const [balanceId, setBalanceId] = web3store.useState('balanceId');

  const busd = useMemo(
    () =>
      formatPrice(parseFloat((parseInt(balbusd, 10) / 10 ** 18).toFixed(2))),
    [balbusd]
  );

  const usdpi = useMemo(
    () =>
      formatPrice(parseFloat((parseInt(balusdpi, 10) / 10 ** 18).toFixed(2))),
    [balusdpi]
  );

  const eth = useMemo(
    () => parseFloat((parseInt(baleth, 10) / 10 ** 18).toFixed(6)).toString(),
    [baleth]
  );

  const usdt = useMemo(
    () => formatPrice(parseFloat((parseInt(balusdt, 10) / 10 ** 6).toFixed(2))),
    [balusdt]
  );

  const [tokens, setTokens] = useState<IToken[]>([
    {
      logo: logoEth,
      name: 'ETH',
      balance: eth,
      balanceWei: baleth,
      cardStyle: 'linear-gradient(84.9deg, #0554BB 10.36%, #0B65DB 97.55%);',
      decimalPlaces: 6,
      wei: 18,
    },
    {
      logo: logoUsdt,
      name: 'USDT',
      balance: usdt,
      balanceWei: balusdt,
      cardStyle: 'linear-gradient(84.9deg, #26A183 10.36%, #25A07A 97.55%)',
      decimalPlaces: 2,
      wei: 6,
    },
    {
      logo: logoBusd,
      name: 'BUSD',
      balance: busd,
      balanceWei: balbusd,
      cardStyle: 'linear-gradient(84.9deg, #F7951B 10.36%, #F8A642 97.55%)',
      decimalPlaces: 2,
      wei: 18,
    },
  ]);

  // UPDATE TOKEN BALANCE LIST
  useEffect(() => {
    setTokens((prevTokens) => {
      const updatedTokens = prevTokens.map((token) => {
        switch (token.name) {
          case 'ETH':
            console.log('++++++++++++ Eth balance changed to %s', baleth);
            console.log('++++++++++++ Eth balance changed to %s', eth);
            return { ...token, balance: eth, balanceWei: baleth };
          case 'USDT':
            return { ...token, balance: usdt, balanceWei: balusdt };
          case 'BUSD':
            return { ...token, balance: busd, balanceWei: balbusd };
          default:
            return token;
        }
      });
      return updatedTokens;
    });
  }, [eth, baleth, usdt, balusdt, busd, balbusd]);

  const [tokenSelected, setTokenSelected] = useState<IToken>({
    logo: logoEth,
    name: 'ETH',
    balance: eth,
    balanceWei: baleth,
    cardStyle: 'linear-gradient(84.9deg, #0554BB 10.36%, #0B65DB 97.55%);',
    decimalPlaces: 6,
    wei: 18,
  });

  // ON OPEN
  useEffect(() => {
    console.log('===== totalNeeded: %s', totalNeeded);
    if (show) {
      setInvertCard(false);
      // setSkipModal(true);
      // setChainMode('PAY');
      // setTokenChain('PAY');
      setExchangeValue(tokenSelected.balance);
      const newTokenSelected: IToken = {
        logo: logoEth,
        name: 'ETH',
        balance: eth,
        balanceWei: baleth,
        cardStyle: 'linear-gradient(84.9deg, #0554BB 10.36%, #0B65DB 97.55%);',
        decimalPlaces: 6,
        wei: 18,
      };
      setTokenSelected(newTokenSelected);
      // setExchangeValue(newTokenSelected.balance);
      setExchangeValue('0');
      setDebouncedValue('0');
      setInputValue('');
    }
  }, [show]);

  // BALANCE CHANGE
  useEffect(() => {
    console.log('NEW BALANCE DETECTED');
    switch (tokenSelected.name) {
      case 'ETH':
        setTokenSelected((prevToken) => ({
          ...prevToken,
          balanceWei: baleth,
        }));
        break;
      case 'USDT':
        setTokenSelected((prevToken) => ({
          ...prevToken,
          balanceWei: balusdt,
        }));
        break;
      case 'BUSD':
        setTokenSelected((prevToken) => ({
          ...prevToken,
          balanceWei: balbusd,
        }));
        break;
      case 'USDPI':
        setTokenSelected((prevToken) => ({
          ...prevToken,
          balanceWei: balusdpi,
        }));
        break;
      default:
        break;
    }
  }, [baleth, balusdt, balbusd, balusdpi, tokenSelected.name]);

  useEffect(() => {
    // update the display whenever tokenSelected.balance changes
  }, [tokenSelected.balance]);

  // console.log('cartAmt: %s', cartAmt);
  // console.log('buyStakingAmt: %s', buyStakingAmt);

  useEffect(() => {
    console.log('exchangeValue: %s', exchangeValue);
    const handler = setTimeout(() => {
      if (!Number.isNaN(parseFloat(exchangeValue))) {
        setDebouncedValue(exchangeValue);
      }
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [exchangeValue]);

  // FORM CHANGE
  useEffect(() => {
    if (debouncedValue) {
      let value = parseFloat(debouncedValue.replaceAll(',', ''));
      if (Number.isNaN(value)) {
        value = 0;
      }
      const amt = BigInt(value * 10 ** tokenSelected.wei);
      let token2Use = tokenSelected.name;

      if (invertCard) {
        token2Use = 'USDPI';
      }
      api
        .get(`v1/usdpi/fee/estimate/${token2Use}/${amt.toString()}`)
        .then((response) => {
          const fee = response.data.estimate.fee / 10 ** 18;
          const wei =
            tokenSelected.name === 'USDT' || tokenSelected.name === 'ETH'
              ? 6
              : 18;
          const net = response.data.estimate.net / 10 ** wei;

          setFeeValue(formatPrice(fee));
          setUsdpiPurchaseFee(fee);
          setReceiveValue(formatPrice(net));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, tokenSelected]);

  // RECEIVED VALUE CHANGE
  useEffect(() => {
    console.log('+++++ Received value change to: %s', receiveValue);
    console.log('+++++ Total needed: %s', totalNeeded);
    if (parseFloat(receiveValue.replaceAll(',', '')) === 0) {
      setError('Amounts must be greater than zero');
    } else if (
      totalNeeded > 0 &&
      parseFloat(receiveValue.replaceAll(',', '')) +
        parseFloat(usdpi.replaceAll(',', '')) <
        totalNeeded
    ) {
      setError(
        'The value plus your balance must be greater than or equal to the amount needed.'
      );
    } else {
      console.log('FAILED');
    }
  }, [receiveValue, totalNeeded]);

  //

  // INVERT CARD
  const handleInvertCard = useCallback(() => {
    console.log('===== tokenSelected.name: %s', tokenSelected.name);
    console.log('===== invertCard: %s', invertCard);
    if (!invertCard && tokenSelected.name === 'BUSD') {
      // Only allow invert if using BUSD (for now)
      setError('');
      const value = balusdpi;
      setExchangeValue(usdpi);
      console.log('===== value: %s', value);
      setInvertCard(!invertCard);
    } else if (invertCard) {
      setInvertCard(!invertCard);
      setExchangeValue(tokenSelected.balance);
    }
  }, [busd, invertCard, usdpi, tokenSelected]);

  // ON CLOSE
  const handleClose = useCallback(() => {
    setDebouncedValue('');
    setExchangeValue('');
    setUsdpiPurchaseTX('');
    setTotalNeeded(0);
    // setChainMode(holdChainMode);
    setShow(false);
  }, []);

  // SHOW BUTTON
  const handleShow = useCallback(() => {
    console.log('+-+-+ btnText: %s', btnText);
    console.log('+-+-+ buyStakingAmt: %s', buyStakingAmt);
    if (btnText === 'FINALIZE ORDER') {
      setTotalNeeded(parseFloat(buyStakingAmt) + parseFloat('1.60'));
    } else if (btnText === 'Buy Now') {
      setTotalNeeded(parseFloat(buyStakingAmt) + parseFloat('1.50'));
    } else if (btnText === 'Get more') {
      // console.log('===============Get more================');
      setTotalNeeded(0);
    } else if (tokenSelected.name === 'ETH') {
      setTotalNeeded(0);
    } else if (cartAmt > 0) {
      setTotalNeeded(cartAmt + 10); // Activation
    } else {
      setTotalNeeded(0);
    }
    setShow(true);
  }, [btnText, buyStakingAmt, cartAmt]);

  const handleInputChange = useCallback(
    (floatValue, formattedValue) => {
      console.log('----- floatValue: %s', floatValue);
      setError('');
      let value = 0;
      if (floatValue !== undefined) {
        value = floatValue;
      }
      const coinBusd = parseFloat(busd.replaceAll(',', ''));
      const coinUsdpi = parseFloat(usdpi.replaceAll(',', ''));
      const coinEth = parseFloat(eth.replaceAll(',', ''));
      if (!invertCard) {
        if (tokenSelected.name === 'BUSD') {
          if (value > coinBusd) {
            setError(
              'The value must be less than or equal to your amount of coins BUSD'
            );
          }
        } else if (tokenSelected.name === 'ETH') {
          if (value > coinEth) {
            setError(
              'The value must be less than or equal to your amount of coins ETH'
            );
          }
        }
      } else if (value > coinUsdpi) {
        setError(
          'The value must be less than or equal to your amount of coins USDPI'
        );
      }
      setExchangeValue(formattedValue);
    },
    [busd, invertCard, usdpi, usdt, eth, totalNeeded, tokenSelected]
  );

  // MAX BUTTON
  const handleMax = useCallback(() => {
    console.log('totalNeeded: %s', totalNeeded);
    console.log(typeof totalNeeded);
    let maxValue = '';
    console.log('invertCard: %s', invertCard);
    if (invertCard) {
      maxValue = usdpi;
    } else if (tokenSelected.name === 'ETH') {
      maxValue = eth;
    } else if (tokenSelected.name === 'BUSD') {
      maxValue = busd;
    } else if (tokenSelected.name === 'USDT') {
      console.log('444');
      maxValue = usdt;
    }
    console.log('maxValue: %s', maxValue);
    setError('');
    if (parseFloat(maxValue) === 0) {
      setError('Exchange value must be greater than zero');
    } else if (
      totalNeeded > 0 &&
      !invertCard &&
      totalNeeded < parseFloat(busd.replaceAll(',', ''))
    ) {
      // console.log(totalNeeded.toFixed(2));
      setExchangeValue(totalNeeded.toFixed(2));
    } else if (
      totalNeeded > 0 &&
      !invertCard &&
      totalNeeded > parseFloat(busd.replaceAll(',', ''))
    ) {
      setExchangeValue(busd);
      // console.log('TOO LITTLE');
      // setError('The value must be greater than or equal to the amount needed');
    } else if (invertCard) {
      setExchangeValue(usdpi);
    } else {
      console.log('catch all');
      setExchangeValue(maxValue);
    }
    setExchangeValue(maxValue);
  }, [busd, invertCard, usdpi, totalNeeded, tokenSelected]);

  const handleShowCongrat = useCallback(() => {
    if (location.pathname === '/activate-your-profit-centers') {
      setCartPurchase(true);
    } else {
      setShowCongrat(true);
    }
  }, [location.pathname, setCartPurchase]);

  // POST PROCESS
  useEffect(() => {
    if (usdpiPurchaseTX) {
      setLoading(false);
      if (typeof usdpiPurchaseTX === 'object' && usdpiPurchaseTX.reason) {
        if (JSON.stringify(usdpiPurchaseTX) !== errorExchange) {
          swalError({
            message: usdpiPurchaseTX.reason,
            textButton: 'Try Again',
          }).then(() => {
            setErrorExchange(JSON.stringify(usdpiPurchaseTX));
          });
        }
      } else if (
        typeof usdpiPurchaseTX === 'string' &&
        usdpiPurchaseTX.toLowerCase().includes('error')
      ) {
        swalError({
          message: usdpiPurchaseTX,
          textButton: 'Try Again',
        }).then(() => {
          setUsdpiPurchaseTX('');
        });
      } else if (!isAlertOpen) {
        setIsAlertOpen(true);
        setUsdpiPurchaseTX('');
        if (onExchangeFinished) {
          onExchangeFinished();
        } else {
          handleClose();
        }
      }
      setUsdpiPurchaseTX('');
    }
  }, [
    errorExchange,
    handleShowCongrat,
    isAlertOpen,
    onExchangeFinished,
    setUsdpiPurchaseTX,
    usdpiPurchaseTX,
  ]);

  // SUBMIT
  // setTokenSelected
  const handleSubmitExchange = useCallback(
    async (data) => {
      console.log('handleSubmitExchange');
      console.log(data);
      let netNeeded = 'PAY';

      if (invertCard) {
        netNeeded = 'NETWORK';
      } else if (tokenSelected.name === 'ETH') {
        netNeeded = 'PAY';
      } else if (tokenSelected.name === 'USDT') {
        netNeeded = 'PAY';
      } else if (tokenSelected.name === 'BUSD') {
        netNeeded = 'NETWORK';
      }

      networkSwitch(netNeeded)
        .then((response) => {
          try {
            setIsAlertOpen(false);
            formRef.current?.setErrors({});
            setLoading(true);

            if (invertCard) {
              setUsdpiSaleAmt(
                // parseFloat(data.price.replaceAll(',', ''))
                parseFloat(debouncedValue.replaceAll(',', ''))
              );
            } else {
              // console.log('tokenSelected.name: %s', tokenSelected.name);
              const xx = 0;
              if (tokenSelected.name === 'BUSD') {
                setUsdpiPurchaseAmt(
                  // parseFloat(data.price.slice(1).replaceAll(',', ''))
                  // parseFloat(data.price.replaceAll(',', ''))
                  parseFloat(debouncedValue.replaceAll(',', ''))
                );
              } else if (tokenSelected.name === 'ETH') {
                // console.log('===== Calling web3stats');
                // console.log(parseFloat(data.price.replaceAll(',', '')));
                // setUsdpiPurchaseAmtEth(parseFloat(data.price.replaceAll(',', '')));
                console.log('debouncedValue: %s', debouncedValue);
                setUsdpiPurchaseAmtEth(
                  parseFloat(debouncedValue.replaceAll(',', ''))
                );
              } else if (tokenSelected.name === 'USDT') {
                // setUsdpiPurchaseAmtUsdt(parseFloat(data.price.replaceAll(',', '')));
                setUsdpiPurchaseAmtUsdt(
                  parseFloat(debouncedValue.replaceAll(',', ''))
                );
              }
            }
          } catch (err) {
            setIsAlertOpen(true);
            if (err instanceof Yup.ValidationError) {
              const errors = getValidationErros(err);
              formRef.current?.setErrors(errors);
            }
          }
        })
        .catch((switchError) => {
          console.log(switchError);
        });
    },
    [
      invertCard,
      setUsdpiPurchaseAmt,
      setUsdpiSaleAmt,
      setTokenSelected,
      debouncedValue,
    ]
  );

  // CHANGE TOKEN
  const handleSelectToken = useCallback((token) => {
    setTokenSelected(token);
    console.log('CHANGE TOKEN - token.balance: %s', token.balance);
    if (token.name === 'ETH') {
      // setSkipModal(true);
      // setChainMode('PAY');
      setInvertCard(false);
      setDecimalPlaces(6);
    } else if (token.name === 'USDT') {
      // setSkipModal(true);
      // setChainMode('PAY');
      setInvertCard(false);
      setDecimalPlaces(2);
    } else {
      // setSkipModal(true);
      // setChainMode('NETWORK');
      setDecimalPlaces(2);
    }
    // setExchangeValue(token.balance);
    setExchangeValue('0');
    // setDebouncedValue(token.balance);
    setDebouncedValue('0');
    // eslint-disable-next-line quotes
    setInputValue('0.0');
  }, []);

  // SET POLLING
  useEffect(() => {
    if (show) {
      setBalanceInterval(15000);
      // setBalanceInterval(0);
    } else {
      setBalanceInterval(60000);
    }
    return () => {
      setBalanceInterval(60000);
    };
  }, [show]);

  return (
    <>
      <Button
        type="button"
        onClick={handleShow}
        className={`${className}`}
        disabled={disabled}
      >
        {btnText}
        {/* {location.pathname === '/token-vault' && (
          <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
        )} */}
      </Button>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        className="modal-signin"
        loading={loading}
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>

        <Modal.Header className="container py-0 border-0">
          <div className="row w-100 justify-content-center">
            <div className="col-10 d-flex justify-content-center">
              <img src={logoSwap} alt="Logo USDPI" className="logo-usdpi" />
              <h2 className="ms-3 fw-bold mb-0">USDPI Swap</h2>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="container">
          <div className="row px-4 px-sm-5 justify-content-center">
            <div className="col-11 d-flex bd-bottom align-items-end px-0">
              <div className="w-50">
                <button type="button" className="btn-swap px-4 py-3">
                  SWAP
                </button>
              </div>
              <div className="w-50 text-end">
                USDPI Balance
                <br />
                <span className="value">${usdpi}</span>
              </div>
            </div>

            <div className="col-11 px-0">
              <SelectCoin className="d-flex justify-content-between my-4">
                {tokens.map((token) => (
                  <Fragment key={token.name}>
                    <button
                      type="button"
                      className={`${
                        token.name === tokenSelected.name && 'active'
                      } rounded-pill p-2 d-flex align-items-center coin`}
                      onClick={() => handleSelectToken(token)}
                    >
                      <img src={token.logo} alt={token.name} />
                      <p className="mb-0 coin-name me-2 text-end">
                        <span className="me-1" title={token.balance}>
                          {/* {token.balance.slice(0, -3)} */}
                          {token.balance}
                        </span>
                        {token.name}
                      </p>
                    </button>
                  </Fragment>
                ))}
              </SelectCoin>
            </div>
          </div>
          <div className="row px-5 mt-3 justify-content-center">
            <div className="col-7 d-flex flex-column px-0">
              <div className="d-flex justify-content-between">
                <div className="w-45 order-0">
                  <h4 className="ps-3 mb-4">Send</h4>
                </div>
                <div className="w-45 order-1">
                  <h4 className="ps-3 mb-4">Receive</h4>
                </div>
              </div>
              <div className="d-flex align-items-center cards-shadow">
                <div className={`w-45 ${!invertCard ? 'order-2' : 'order-4'}`}>
                  <Card
                    background={tokenSelected.cardStyle}
                    className="yellow-card p-4"
                  >
                    <div className="text-end">
                      <img src={tokenSelected.logo} alt="Logo BUSD" />
                    </div>
                    <h2>{tokenSelected.name}</h2>
                    <div className="d-flex mt-4">
                      <div className="w-25 h6 text-white">$1</div>
                      <div className="w-75 h6 text-white text-end pe-4">
                        {tokenSelected.balance} {tokenSelected.name}
                      </div>
                    </div>
                  </Card>
                </div>
                <div
                  className="w-10 text-center order-3"
                  style={{
                    visibility:
                      totalNeeded > 0 ||
                      (tokenSelected.name !== 'BUSD' &&
                        tokenSelected.name !== 'USDPI')
                        ? 'hidden'
                        : undefined, // setting to undefined lets the default behavior (visible) take over
                  }}
                >
                  <button
                    type="button"
                    onClick={handleInvertCard}
                    className="rounded-circle border-0 arrows"
                  >
                    <img src={arrows} alt="Arrows" />
                  </button>
                </div>
                <div className={`w-45 ${!invertCard ? 'order-4' : 'order-2'}`}>
                  <Card
                    background="linear-gradient(84.9deg, #2fbf7f 10.36%, #3cc9ad 97.55%);"
                    className="green-card p-4"
                  >
                    <div className="text-end">
                      <img src={logoUsdpi} alt="Logo USDPI" />
                    </div>
                    <h2>USDPI</h2>
                    <div className="d-flex mt-4">
                      <div className="w-25 h6 text-white">$1</div>
                      <div className="w-75 h6 text-white text-end pe-4">
                        {usdpi} USDPI
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-4 pe-0 pad-left mt-2">
              <div className="d-flex align-items-center justify-content-between text-end mt-4">
                <span className="text-table">Amount Needed</span>
                <h3 className="text-table mb-0">
                  {totalNeeded === 0 ||
                  btnText === 'GET USDPI HERE' ||
                  btnText === 'Buy/Sell USDPI'
                    ? '--'
                    : `$${formatPrice(totalNeeded)}`}
                </h3>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <div>
                  <span
                    className="wm-tooltip text-table"
                    data-tooltip="Transaction fees can fluctuate based on network conditions such as demand and congestion. Higher demand can lead to higher fees, while lower demand can result in lower fees. The fees are not fixed, but rather vary based on network conditions at any given time."
                  >
                    Network Fees{' '}
                    <HiOutlineInformationCircle
                      className="ms-3"
                      size={25}
                      color="#4F89FF"
                    />
                    <br />
                    <span className="estimate">
                      Estimate only, fees will vary
                    </span>
                  </span>
                </div>
                <h3 className="values text-end">
                  ${feeValue}
                  <br />
                  <span>
                    {feeValue} {invertCard ? 'USDPI' : tokenSelected.name}
                  </span>
                </h3>
              </div>
              <div className="d-flex justify-content-between text-end mt-2">
                <span className="text-table">Send</span>
                <h3 className="values color-negative">
                  {tokenSelected.name === 'ETH' ? '-' : '-$'}
                  {exchangeValue}
                  <br />
                  <span>
                    -{exchangeValue} {invertCard ? 'USDPI' : tokenSelected.name}
                  </span>
                </h3>
              </div>
              <div className="d-flex justify-content-between text-end mt-2">
                <span className="text-table">Receive</span>
                <div>
                  <h3 className="values color-positive mb-0">
                    ${receiveValue}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <Form
            ref={formRef}
            onSubmit={handleSubmitExchange}
            className="row px-5 mt-5 justify-content-center"
          >
            <div className="col-11 px-0">
              <div className="text-center bg-number py-3 position-relative">
                {/* <button
                  onClick={handleMax}
                  type="button"
                  className="position-absolute"
                >
                  Max
                </button> */}
                <div className="input-mask border-0 bg-transparent px-4">
                  <NumericFormat
                    value={inputValue}
                    name="price"
                    allowLeadingZeros
                    thousandSeparator=","
                    decimalScale={decimalPlaces}
                    onValueChange={(values, sourceInfo) => {
                      handleInputChange(
                        values.floatValue,
                        values.formattedValue
                      );
                      console.log(values, sourceInfo);
                    }}
                    onChange={(ev) => {
                      setInputValue(ev.currentTarget.value);
                    }}
                  />
                </div>
                <span className="h4 color pt-3">
                  {exchangeValue} {invertCard ? 'USDPI' : tokenSelected.name}
                </span>
              </div>

              {error && (
                <span className="small text-danger error text-center w-100 d-block mt-1">
                  {error}
                </span>
              )}
            </div>
            <div className="col-11 text-end mt-4 mb-n4 pe-0">
              <span className="buy-text">Don’t have Crypto?</span>{' '}
              <a
                href="https://bit.ly/getbusd"
                target="_blank"
                className="btn buy-here ms-4 py-2 px-3"
                rel="noreferrer"
              >
                BUY IT HERE
              </a>
            </div>
            <div className="col-11 position-relative">
              <ModalConfirmation
                type="submit"
                disabled={debouncedValue === '0,00' || !!error}
                showCongrat={showCongrat}
                setShowCongrat={setShowCongrat}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 px-4 py-5" />
      </Modal>
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="SWAPPING TOKENS"
        className="zoom-1-3"
        active={loading}
      />
    </>
  );
};

export default ModalSwap;
