import React from 'react';

import {
  Container,
  Retangle,
  RoadMap,
  Community,
  ArtCreator,
  NftLaunchpad,
  Paige,
  Cube,
  Meta,
  Defi,
  Ideas,
  Final,
} from './styles';

import logo from '~/assets/logo/logo-p-gray.svg';
import bubble2 from '~/assets/defaults/bubble_2.svg';
import stamp from '~/assets/defaults/stamp-large.png';
import future from '~/assets/defaults/future-here.png';
import aiLarge from '~/assets/defaults/ai-art-large.png';
import launchpadLarge from '~/assets/defaults/launchpad-large.png';
import launchpadTitle from '~/assets/defaults/launchpad-title-large.png';
import paigeLarge from '~/assets/defaults/paige-large.png';
import paigePage from '~/assets/defaults/paige-page.png';
import defiLarge from '~/assets/defaults/defi-large.png';
import defiPage from '~/assets/defaults/defi-page.png';
import cubePage from '~/assets/defaults/cube-page.png';
import cubeLarge from '~/assets/defaults/cube-title-large.png';
import metaPage from '~/assets/defaults/meta-page.png';
import metaLarge from '~/assets/defaults/meta-large.png';
import aiCreator from '~/assets/defaults/ai-art-page.png';
import aiArt from '~/assets/icons/community-ai-art-icon.png';
import launchpad from '~/assets/icons/community-launchpad-icon.png';
import paige from '~/assets/icons/community-paige-icon.png';
import cube from '~/assets/icons/community-cube-icon.png';
import metaverse from '~/assets/icons/community-metaverse-icon.png';
import defi from '~/assets/icons/community-defi-icon.png';
import p from '~/assets/defaults/p.png';
import ai from '~/assets/defaults/ai.png';
import g from '~/assets/defaults/g.png';
import e from '~/assets/defaults/e.png';
import paint1 from '~/assets/defaults/paint-1.png';
import paint2 from '~/assets/defaults/paint-2.png';
import paint3 from '~/assets/defaults/paint-3.png';
import paint4 from '~/assets/defaults/paint-4.png';
import paint5 from '~/assets/defaults/paint-5.png';
import paint6 from '~/assets/defaults/paint-6.png';
import paint7 from '~/assets/defaults/paint-7.png';
import paint8 from '~/assets/defaults/paint-8.png';
import paint9 from '~/assets/defaults/paint-9.png';
import paigeText from '~/assets/defaults/paige-text.png';
import ideasText from '~/assets/defaults/ideas-text.png';
import ideasStamp from '~/assets/defaults/stamp-ideas.png';

const ProductRoadmap: React.FC = () => {
  return (
    <Container>
      <RoadMap>
        <Retangle className="retangle zoom">
          <img src={logo} alt="Logo" />
        </Retangle>
        <div className="container zoom">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <h1>
                PROFIT<span>i</span> PRODUCT ROADMAP
              </h1>
              <h2>
                Everything you need to know about the current and near future
                products development.
              </h2>

              <div className="d-flex second-bubble">
                <img src={bubble2} alt="bubble" />
                <h3 className="ps-4">
                  Whether you're a beginner or experienced,{' '}
                  <span>
                    our suite of innovative no-code tools, powered by Artificial
                    Intelligence
                  </span>
                  , makes it easy for anyone to create images, NFTs, copy, and
                  much more!
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="stamp zoom">
          <hr />
          <img src={stamp} alt="Stamp" />
        </div>
      </RoadMap>
      <Community>
        <div className="container zoom">
          <div className="row">
            <div className="col-12 text-center mt-3">
              <h2>Discover a new world of freedom and possibilities with</h2>
              <h3>PROFITi's decentralized community</h3>
              <p>...and innovative, easy-to-use products</p>
              <div className="d-flex justify-content-center mt-5 pt-5 products">
                <img src={aiArt} alt="Ai Art" />
                <img src={launchpad} alt="Launchpad" />
                <img src={paige} alt="Paige" className="height" />
                <img src={cube} alt="Cube" className="height" />
                <img src={metaverse} alt="Metaverse" />
                <img src={defi} alt="Trading defi" />
              </div>
              <div className="future mt-5">
                <img src={future} alt="Future is here" />
              </div>
            </div>
          </div>
        </div>
      </Community>
      <ArtCreator>
        <div className="container zoom">
          <div className="row align-items-center justify-content-center">
            <div className="col-4 pe-5">
              <img src={aiLarge} alt="Ai Art" className="w-100 mb-5" />
              <p>
                The ultimate tool for <span>unleashing your creativity</span>{' '}
                and generating one-of-a-kind art pieces{' '}
                <span>using the power of artificial intelligence</span>. With
                the user-friendly platform, you can{' '}
                <span>create stunning images</span> for virtually anything you
                can think of, whether it's for personal or commercial use.
              </p>
              <p>
                Gone are the days of struggling with a blank canvas or trying to
                master complex art techniques.{' '}
                <span>
                  The AI Art Creator empowers you to explore new artistic
                  horizons
                </span>
                , experiment with different styles and colors, and let your
                imagination run wild.
              </p>
              <p>
                From creating{' '}
                <span>unique designs for marketing campaigns</span> to producing{' '}
                <span>custom digital art for personal use</span>, the AI Art
                Creator offers{' '}
                <span>
                  a multitude of use cases that includes creating super rare
                  NFTs
                </span>
                . Whether you're an established artist or a novice, this tool is
                perfect for exploring your creativity and generating stunning,
                one-of-a-kind pieces.
              </p>
              <p className="mb-0">
                <span>
                  Experience the limitless possibilities of art with the AI Art
                  Creator.
                </span>
              </p>
            </div>
            <div className="col-8">
              <img src={aiCreator} alt="Art Creator" className="w-100" />
            </div>
          </div>
        </div>
      </ArtCreator>
      <NftLaunchpad>
        <div className="container zoom">
          <div className="row align-items-center justify-content-center">
            <div className="col-8">
              <img src={launchpadLarge} alt="Art Creator" className="w-100" />
            </div>
            <div className="col-4 ps-5 text-end">
              <img
                src={launchpadTitle}
                alt="Ai Art"
                className="w-100 ms-auto mb-5"
              />

              <p>
                <span>
                  The easiest and most efficient way to launch your own NFTs on
                  the blockchain
                </span>
                . With its user-friendly interface and{' '}
                <span>no-code approach</span>, the Launchpad makes it simple for
                artists and creators to customize and mint NFTs, giving them
                access to the booming NFT market
              </p>
              <p>
                The NFT Launchpad{' '}
                <span>empowers you to showcase your digital creations</span> to
                a global audience and benefit from the growing demand for NFTs.
                Beyond NFT creation, the Launchpad is a powerful tool for
                promoting your work, building your brand, and connecting with
                other artists and collectors.
              </p>
              <p>
                <span>
                  Take your creativity to the next level with the NFT Launchpad
                  - the one-click solution for launching your NFTs.
                </span>
              </p>
            </div>
          </div>
        </div>
      </NftLaunchpad>
      <Paige>
        <div className="container zoom">
          <div className="row align-items-center">
            <div className="col-4 pe-5">
              <img src={paigeLarge} alt="Ai Art" className="w-100 mb-5" />
              <p>
                Introducing Paige,{' '}
                <span>
                  your{' '}
                  <span>
                    <img src={p} alt="Paige" />
                  </span>
                  ersonal{' '}
                  <span>
                    <img src={ai} alt="Paige" />
                  </span>{' '}
                  <span>
                    <img src={g} alt="Paige" className="pt-1" />
                  </span>
                  enerative{' '}
                  <span>
                    <img src={e} alt="Paige" />
                  </span>
                  xpert
                </span>
                . Whether you're a writer, artist, marketer, or just someone who
                needs help generating new ideas,{' '}
                <span>Paige is the perfect tool for you</span>. With its{' '}
                <span>advanced AI technology</span>, Paige can quickly analyze
                your input and{' '}
                <span>create content that's tailored to your needs.</span>
              </p>
              <p>
                With Paige, you can easily create high-quality content in a
                <span> variety of everyday situations.</span>
              </p>
              <div className="d-flex align-items-center py-4">
                <img src={paint1} alt="Paint" className="pe-4" />
                <p className="mb-0 pe-4">
                  <span>
                    Need help coming up with ideas for your next blog post or
                    social media campaign?
                  </span>{' '}
                  Paige has got you covered.
                </p>
              </div>
              <div className="d-flex align-items-center py-4">
                <img src={paint2} alt="Paint" className="pe-4" />
                <p className="mb-0 pe-4">
                  <span>
                    Want to generate unique and engaging content for your
                    website or online store?
                  </span>{' '}
                  Paige can help with that too.
                </p>
              </div>
              <div className="d-flex align-items-center py-4">
                <img src={paint3} alt="Paint" className="pe-4" />
                <p className="mb-0 pe-4">
                  <span>
                    Looking for inspiration for your next recipe or meal plan?
                  </span>{' '}
                  Paige can provide you with a range of options based on your
                  preferences and dietary requirements.
                </p>
              </div>
            </div>
            <div className="col-8">
              <img src={paigePage} alt="Art Creator" className="w-100" />
            </div>
            <div className="col-4 pe-5">
              <div className="d-flex align-items-center py-4">
                <img src={paint4} alt="Paint" className="pe-4" />
                <p className="mb-0 pe-4">
                  <span>
                    Want to create personalized workout plans or fitness
                    programs?
                  </span>{' '}
                  Paige can analyze your fitness goals and generate a plan
                  that's tailored to your needs.
                </p>
              </div>
              <div className="d-flex align-items-center py-4">
                <img src={paint5} alt="Paint" className="pe-4" />
                <p className="mb-0 pe-4">
                  <span>
                    Want to translate text or documents into another language?
                  </span>{' '}
                  Paige can translate for you quickly and accurately.
                </p>
              </div>
            </div>
            <div className="col-4 pe-5">
              <div className="d-flex align-items-center py-4">
                <img src={paint6} alt="Paint" className="pe-4" />
                <p className="mb-0 pe-4">
                  <span>
                    Need help with writing code or developing software?
                  </span>{' '}
                  Paige can provide you with snippets of code or suggest new
                  approaches to solving problems.
                </p>
              </div>
              <div className="d-flex align-items-center py-4">
                <img src={paint7} alt="Paint" className="pe-4" />
                <p className="mb-0 pe-4">
                  <span>
                    Do you struggle with writer's block or need inspiration for
                    your next creative project?
                  </span>{' '}
                  Paige can help spark your creativity with new ideas and fresh
                  perspectives.
                </p>
              </div>
            </div>
            <div className="col-4 pe-5">
              <div className="d-flex align-items-center py-4">
                <img src={paint8} alt="Paint" className="pe-4" />
                <p className="mb-0 pe-4">
                  <span>
                    Are you a small business owner who needs help writing copy
                    for your website or marketing materials?
                  </span>{' '}
                  Paige can assist with generating compelling and effective
                  language that resonates with your target audience.
                </p>
              </div>
              <div className="d-flex align-items-center py-4">
                <img src={paint9} alt="Paint" className="pe-4" />
                <p className="mb-0 pe-4">
                  <span>
                    Are you planning an event and need help with writing
                    invitations or announcements?
                  </span>{' '}
                  Paige can generate text that captures the tone and style
                  you're looking for.
                </p>
              </div>
            </div>
            <div className="col-12 paragraph">
              <p className="my-4">
                <span>
                  Paige is a versatile tool that can assist you with many
                  different tasks.
                </span>{' '}
                Whether you're{' '}
                <span>writing a book, creating a presentation</span>, or just
                need help <span>brainstorming ideas</span>, Paige is always
                ready to lend a helping hand.{' '}
                <span>
                  Paige is your go-to expert for all things content creation,
                  and she's always ready to assist you whenever you need it.
                </span>
              </p>
              <p>
                <span>...and by the way, in case you were wondering,</span> this
                entire document was written by{' '}
                <img src={paigeText} alt="Paige" />
              </p>
            </div>
          </div>
        </div>
      </Paige>
      <Cube>
        <div className="container zoom">
          <div className="row align-items-center justify-content-center">
            <div className="col-8">
              <img src={cubePage} alt="Cube" className="w-100" />
            </div>
            <div className="col-4 ps-5 text-end">
              <img src={cubeLarge} alt="Cube" className="w-100 ms-auto mb-5" />

              <p>
                Introducing The Cube -{' '}
                <span>
                  PROFITi's groundbreaking blockchain-based game that takes fun
                  and rewards to the next level!
                </span>
              </p>
              <p>
                Get ready to{' '}
                <span>experience a gaming world like no other</span>, where you
                have chances to play for free and earn amazing rewards just for
                having fun.
              </p>
              <p>
                The Cube is the <span>first of many exciting games</span> from
                PROFITi that are{' '}
                <span>designed to bring blockchain gaming to the masses</span>.
                With its
                <span>intuitive gameplay</span> and unique features, The Cube
                will keep you entertained for hours on end. And{' '}
                <span>
                  with the opportunity to earn rewards that you can use in the
                  real world
                </span>
                , you'll never want to stop playing!
              </p>
              <p>
                <span>Join the gaming revolution</span> with The Cube and{' '}
                <span>start having fun while earning amazing rewards!</span>
              </p>
            </div>
          </div>
        </div>
      </Cube>
      <Meta>
        <div className="container zoom">
          <div className="row align-items-center justify-content-center">
            <div className="col-4 pe-5">
              <img src={metaLarge} alt="Metaverse" className="w-100 mb-5" />
              <p>
                Welcome to the Metaverse,{' '}
                <span>
                  the platform that brings together PROFITi's decentralized
                  community.
                </span>{' '}
                It's a virtual world where you can{' '}
                <span>connect, socialize</span>, and <span>engage</span> in a
                variety of activities with like-minded individuals from all over
                the world. Whether you want to <span>play games</span>, attend{' '}
                <span>virtual events</span>, or simply
                <span>hang out</span> and chat with friends, the{' '}
                <span>Metaverse has something for everyone.</span>
              </p>
              <p>
                One of the best things about the Metaverse is its{' '}
                <span>unparalleled focus on security and privacy</span>. You can
                rest assured that your personal information and activities are
                always safe and secure. And with its{' '}
                <span>intuitive interface and user-friendly design</span>, the
                Metaverse is easy to navigate and use, making it the{' '}
                <span>
                  perfect platform for both tech enthusiasts and casual users
                </span>
                .
              </p>
              <p>
                With the Metaverse, you'll{' '}
                <span>discover a world of limitless possibilities</span>.
                Whether you want to{' '}
                <span>explore new places, learn new things</span>, or{' '}
                <span>connect with new people</span>, the Metaverse has got you
                covered.
              </p>
              <p className="mb-0">
                In the Metaverse you will experience{' '}
                <span>the future of online communities.</span>
              </p>
            </div>
            <div className="col-8">
              <img src={metaPage} alt="Metaverse" className="w-100" />
            </div>
          </div>
        </div>
      </Meta>
      <Defi>
        <div className="container zoom">
          <div className="row align-items-center justify-content-center">
            <div className="col-8">
              <img src={defiPage} alt="Cube" className="w-100" />
            </div>
            <div className="col-4 ps-5 text-end">
              <img src={defiLarge} alt="Cube" className="w-100 ms-auto mb-5" />

              <p>
                The ultimate tool for crypto arbitrage on decentralized
                exchanges.{' '}
                <span>
                  This cutting-edge protocol uses advanced artificial
                  intelligence to identify arbitrage opportunities
                </span>{' '}
                on decentralized exchanges, eliminating the guesswork and
                generating opportunities for users without requiring any prior
                trading experience.
              </p>
              <p>
                The bot{' '}
                <span>
                  works by scanning multiple exchanges for potential price
                  discrepancies
                </span>
                , and <span>uses smart contracts to execute trades</span> on
                your behalf. And if the parameters of the trade don't align with
                the bot's algorithm, the entire transaction is voided -{' '}
                <span>so you'll never have to worry about losing money.</span>
              </p>
              <p>
                But the AI-powered DeFi trading bot is more than just powerful -
                <span>it's also incredibly easy to use</span>. Designed with
                user-friendliness in mind, the system is a breeze to set up and
                requires no technical knowledge. Once you're up and running, you
                can sit back and relax while the bot makes informed decisions
                and executes trades on your behalf.
              </p>
            </div>
          </div>
        </div>
      </Defi>
      <Ideas>
        <div className="container zoom">
          <div className="row align-items-center justify-content-center">
            <div className="col-8">
              <div className="text-center texts">
                <img src={ideasStamp} alt="Stamp" className="" />

                <p>... this page was intentionally left blank</p>
                <img src={ideasText} alt="Bring your ideas" className="w-75" />
                <p className="px-5">
                  PROFITi was{' '}
                  <span>created by the community for the community</span>, do
                  you have a talent or product that can help us grow?
                  <br />
                  <span>Our next product feature could be yours!</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Ideas>
      <Final>
        <div className="container zoom">
          <div className="row align-items-center justify-content-center">
            <div className="col-10">
              <div className="title text-center">
                <h2>
                  PROFITi is revolutionizing the digital landscape with
                  innovative products that empower
                </h2>
                <h2>
                  our users to explore new horizons and, connect with
                  like-minded individuals
                </h2>
                <h2>and unleash their creativity like never before.</h2>
              </div>
              <div className="bg">
                <div className="bg-products d-flex align-items-center justify-content-center">
                  <div className="text-center mb-4">
                    <h2>LET’S BUILD THE MOST INNOVATIVE</h2>
                    <h3>Decentralized Community</h3>
                    <p>and grow together</p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <img src={logo} alt="Logo" className="logo" />
              </div>
            </div>
          </div>
        </div>
      </Final>
    </Container>
  );
};

export default ProductRoadmap;
