import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Button = styled.button`
  background-color: transparent;
  color: #fff;
  border-radius: 20px;
  font-size: 20px;
  padding: 16px;
  border: none;
  :hover {
    color: #fff;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    width: 530px !important;
    background-color: #17171a !important;
  }
  .m-3 {
    margin-bottom: 0px !important;
    color: red;
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    color: #dadada;
    font-size: 48px;
  }

  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 118%;
    text-align: center;
    color: #aeabab;
    b {
      color: #a6a2a2;
    }
  }

  .modal-header {
    display: block;
  }

  .to-dashboard {
    padding: 14px;
    border-radius: 15px;
    font-weight: bold;
    background-color: #00f9ab;
    color: #000;
    font-weight: 700;
    font-size: 20px;
    transition-duration: 0.3s;
    :hover {
      background-color: ${darken(0.1, '#00f9ab')};
    }
  }

  .coin video {
    border: 1px solid #565656;
    border-radius: 30px;
  }
  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  .bg-lg-social-medias {
    background: #0c0d0d;
    backdrop-filter: blur(5px);
    border-radius: 60px;
  }

  .social-medias {
    width: auto;
  }

  .social-medias img {
    width: 90px;
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 1199px) {
    .social-medias img {
      width: 75px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 42px;
    }
    .social-medias img {
      width: 85px;
    }

    .bg-mobile-social-medias {
      background: #0c0d0d;
      backdrop-filter: blur(5px);
      border-radius: 60px;
    }

    .bg-lg-social-medias {
      background: transparent;
      backdrop-filter: none;
      border-radius: unset;
    }
  }
`;
