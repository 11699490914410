import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';

export const Container = styled.button`
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  border: none;
  padding: 1px;
  background-color: transparent;

  span {
    padding: 5px 10px;
    margin: 0 18px;
    display: block;
  }

  @media screen and (max-width: 1399px) {
    font-size: 20px;
  }
`;

export const Modal = styled(ModalComponent)`
  font-family: 'Barlow', sans-serif;
  .modal-close {
    color: #000;
    height: 50px;
    width: 50px;
  }
  .modal-xl {
    width: 1140px;
  }

  .btn-nft {
    color: #fff;
    padding: 18px;
    font-size: 24px;
    border-radius: 20px;
    background-color: #0c0d0d;
    transition-duration: 0.3s;
  }

  .btn-nft:hover {
    background-color: ${lighten(0.1, '#0c0d0d')};
  }

  .img-metamask {
    background: rgba(244, 247, 253, 0.5);
    box-shadow: 30px 70px 120px rgba(61, 52, 63, 0.13);
    backdrop-filter: blur(10px);
    padding: 8px;
    border-radius: 30px;
  }

  .left-ride,
  .right-ride {
    img {
      padding: 43px;
      background: rgba(244, 247, 253, 0.5);

      box-shadow: 30px 70px 120px rgb(27 49 66 / 13%);
      //backdrop-filter: blur(10px);
      border-radius: 30px;
      transition: 0.2s;
    }

    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: #000000;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    .description {
      color: #000;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 1399px) {
    .left-ride,
    .right-ride {
      img {
        padding: 30px;
      }

      .description {
        font-size: 15px;
      }
    }

    .btn-nft {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    .left-ride,
    .right-ride {
      img {
        padding: 25px;
      }

      h2 {
        font-size: 28px;
      }
      p {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 420px) {
    .btn-nft {
      padding: 18px 10px;
    }
  }

  @media screen and (min-width: 576px) {
  }
`;
