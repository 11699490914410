import styled from 'styled-components';

export const Container = styled.div`
  background-color: #181818;
  min-height: 100vh;

  h1 {
    color: #dadada;
    font-size: 36px;
    font-weight: 700;
  }

  h2 {
    color: #dadada;
    font-size: 18px;
    font-weight: 700;
  }

  h3 {
    color: #929292;
    font-size: 18px;
    font-weight: 400;
  }

  .fw-500 {
    font-weight: 500 !important;
  }

  .bg {
    border-radius: 30px;
    background: #131313;
    padding: 8px 11px;
  }
  .btc-large {
    width: 60px;
    height: 60px;
  }
  .all {
    color: #e9e9e9;
    font-size: 33px;
    font-weight: 500;
  }
  .triangle {
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);

    min-width: 11px;
    min-height: 11px;
    display: inline-block;
  }

  .triangle-positive {
    background-color: #00f9ab;
  }

  .triangle-negative {
    background-color: #ff1a50;
    transform: rotate(180deg);
  }

  .percentage {
    font-size: 20px;
    font-weight: 400;
  }

  .positive {
    color: #00f9ab !important;
  }

  .negative {
    color: #ff1a50 !important;
  }

  .btns-time button {
    position: relative;
    color: #929292;
    font-size: 18px;
    font-weight: 400;
    border: none;
    background-color: transparent;
    margin: 0 20px;
    transition: all 0.2s ease-in-out;
    :hover {
      color: #dadada;
    }
  }

  .active {
    color: #dadada !important;

    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      border-radius: 2px;
      bottom: -8px;
      left: 0;
      transition-duration: 0.3s;
      background: linear-gradient(
        270deg,
        #9df3ff 7.84%,
        #bcffe5 55.62%,
        #deefd1 75.94%,
        #ffe0be 99.96%
      );
    }
  }

  .bg-card {
    border-radius: 20px;
    background: #181818;
  }

  .bg-card h4 {
    color: #dadada;
    font-size: 18px;
    font-weight: 400;
  }

  .bg-card span {
    color: #929292;
    font-size: 18px;
    font-weight: 400;
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }

  .red {
    background-color: #ff1a50;
  }
  .bg-btc-signal {
    border-radius: 10px;
    background: #0c0c0c;
  }
  .t-head {
    padding-right: 0px;
  }

  .t-head th:nth-child(1),
  .t-row td:nth-child(1) {
    width: 17%;
  }

  .t-head th,
  .t-row td {
    width: calc(83% / 6);
    padding: 10px 15px;
  }

  .t-head th {
    color: #bdbdbd;
    font-size: 18px;
    font-weight: 500;
  }

  .t-row {
    border-radius: 10px;
    background: #181818;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    border: 1px solid #181818;
  }

  .t-row td {
    color: #a19e9e;
    font-size: 18px;
    font-weight: 400;
  }

  .t-row img {
    width: 39px;
    height: 39px;
    margin-right: 7px;
  }

  .warning {
    color: #ffae52 !important;
  }
  .yellow {
    background-color: #ffae52;
  }

  .bd-sell {
    border: 1px solid #ff1a50 !important;
  }

  tbody.height {
    max-height: auto;
  }
  table {
    width: 1300px;
  }

  @media screen and (min-width: 575px) {
    .btc-large {
      width: 80px;
      height: 80px;
    }
    .all {
      font-size: 50px;
    }
    .btns-time button {
      margin: 0 10px;
    }
  }

  @media screen and (min-width: 768px) {
    .btc-large {
      width: 100px;
      height: 100px;
    }
    .all {
      font-size: 60px;
    }
  }

  @media screen and (min-width: 992px) {
    .btc-large {
      width: 154px;
      height: 154px;
    }
    tbody.height {
      max-height: 430px;
      overflow: auto;
      padding-right: 7px;
    }
    table {
      width: 100%;
    }
    .t-head {
      padding-right: 10px;
    }
    .zoom {
      zoom: 0.65;
    }
  }

  @media screen and (min-width: 1200px) {
    .zoom {
      zoom: 0.75;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 0.9;
    }
  }

  @media screen and (min-width: 1500px) {
    .zoom {
      zoom: 1;
    }
    .container-xxl {
      max-width: 1480px;
    }
  }
`;
