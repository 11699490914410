import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  height: 274px;
  overflow: auto;
  padding-right: 10px;
  margin-bottom: 15px;

  img {
    width: 42px;
    height: 42px;
  }

  h3 {
    font-weight: 300;
    font-size: 12px;
    color: #8d8d8d;
  }

  .btn-mature,
  span {
    font-weight: 400;
    font-size: 26px;
    color: #e4e4e4;
    transition-duration: 0.3s;
  }

  .btn-mature:hover {
    opacity: 0.8;
  }

  .claim {
    background: #a3c0e1;
    border-radius: 10px;
    font-weight: 700;
    font-size: 10px;
    color: #0927c6;
    transition-duration: 0.3s;

    :hover {
      opacity: 0.8;
    }

    :disabled {
      opacity: 0.7;
    }
  }

  .btn-reload {
    width: 16px;
    height: 14px;
    background: #18191a !important;
    border-radius: 5px !important;

    img {
      width: 8px;
      height: 8px;
      transition-duration: 0.3s;
    }

    :hover {
      img {
        transform: rotateZ(-360deg);
      }
    }
  }

  .rewards div:nth-child(2) {
    padding-left: 6%;
  }

  .rewards div:nth-child(3) {
    padding-left: 21%;
  }

  .rewards div:nth-child(4) {
    margin-left: auto;
  }

  .performance-rewards div:nth-child(2) {
    padding-left: 6%;
  }

  .performance-rewards div:nth-child(3) {
    padding-left: 24.5%;
  }

  .performance-rewards div:nth-child(4) {
    margin-left: auto;
  }
`;

export const ModalRewards = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .texts p:nth-child(1) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 24px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts p:nth-child(2) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 20px;
      color: #7d7777;
    }

    .texts p:nth-child(3) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 20px;
      line-height: 118%;
      text-align: center;
      color: #a6a2a2;
    }

    .texts div:nth-child(4) {
      border: 1px solid #4f4f4f;
      border-radius: 20px;
      font-weight: 300;
      font-size: 14px;
      align-items: center;
      text-align: center;

      padding: 23px;
      a {
        color: #8767ed;
      }
    }

    .texts .terms {
      background: #1d1d1f;
      border-radius: 12px;
      font-weight: 300;
      font-size: 16px;
      padding: 10px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: #7d7777;
      }
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-confirm {
      background: #696ffd;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
  }
`;
