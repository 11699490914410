/* eslint-disable react/no-unused-prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import { BiChevronDown } from 'react-icons/bi';

import { useAuth } from '~/hooks/Auth';
import { formatPrice } from '~/utils/format';
import api from '~/services/api';
import abbreviate from '~/utils/abbreviate';

import { Container, Welcome, Places, RankingTable, Ranking } from './styles';
import ProgressBar from '~/components/ProgressBar';
import Table from '~/components/Table';

import avatarDefault from '~/assets/defaults/default-avatar.png';
import info from '~/assets/icons/info-icon.svg';
import details from '~/assets/defaults/details_new.png';
import first from '~/assets/icons/first-place.svg';
import second from '~/assets/icons/second-place.svg';
import third from '~/assets/icons/third-place.svg';
import badge from '~/assets/icons/badge.svg';
import WalletRow from '~/components/WalletRow';

interface ILearderboard {
  id: number;
  name: string;
  referral_code: string;
  indication: number;
  team: number;
  avatar?: {
    avatar_url: string;
  };
}

interface ITableData {
  id: number;
  avatar: string;
  name: string;
  referral_code: string;
  indication: number;
  team: number;
  next_bonus: string;
  percentage: number;
}

const Learderboard: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [firstPlace, setFirstPlace] = useState<ITableData>({} as ITableData);
  const [secondPlace, setSecondPlace] = useState<ITableData>({} as ITableData);
  const [thirdPlace, setThirdPlace] = useState<ITableData>({} as ITableData);
  const [myPlace, setMyPlace] = useState<ITableData>({} as ITableData);
  // const [learderboards, setLearderboards] = useState<ITableData[]>([]);

  const learderboards = [
    {
      id: 1,
      avatar: avatarDefault,
      name: 'Name user',
      referral_code: ' @user',
      indication: 10,
      team: 15,
      next_bonus: 500,
      percentage: 30,
    },
    {
      id: 2,
      avatar: avatarDefault,
      name: 'Name user',
      referral_code: ' @user',
      indication: 10,
      team: 15,
      next_bonus: 1000,
      percentage: 50,
    },
  ];

  useEffect(() => {
    // setLoading(true);
    // api
    //   .get('learderboards')
    //   .then((response) => {
    //     const datas: ITableData[] = response.data.map(
    //       (learderboard: ILearderboard, index: number) => ({
    //         id: index + 1,
    //         avatar: learderboard.avatar
    //           ? learderboard.avatar.avatar_url
    //           : avatarDefault,
    //         name: abbreviate(learderboard.name),
    //         referral_code: learderboard.referral_code,
    //         indication: learderboard.indication,
    //         team: learderboard.team,
    //         next_bonus:
    //           learderboard.indication > 4 ? formatPrice(1000) : formatPrice(50),
    //         percentage:
    //           learderboard.indication > 4
    //             ? Math.ceil((learderboard.team * 100) / 750)
    //             : Math.ceil((learderboard.indication * 100) / 5),
    //       })
    //     );
    //     const myPlaceData = datas.find(
    //       (data) => data.referral_code === user.referral_code
    //     );
    //     const firstPlaceData: ITableData = {
    //       id: datas[0] ? datas[0].id : 0,
    //       avatar: datas[0] ? datas[0].avatar : avatarDefault,
    //       name: datas[0] ? abbreviate(datas[0].name) : 'A Definir',
    //       referral_code: datas[0] ? datas[0].referral_code : '@a-definir',
    //       indication: datas[0] ? datas[0].indication : 0,
    //       team: datas[0] ? datas[0].team : 0,
    //       next_bonus: datas[0] ? datas[0].next_bonus : formatPrice(50),
    //       percentage: datas[0] ? datas[0].percentage : 0,
    //     };
    //     const secondPlaceData: ITableData = {
    //       id: datas[1] ? datas[1].id : 0,
    //       avatar: datas[1] ? datas[1].avatar : avatarDefault,
    //       name: datas[1] ? abbreviate(datas[1].name) : 'A Definir',
    //       referral_code: datas[1] ? datas[1].referral_code : '@a-definir',
    //       indication: datas[1] ? datas[1].indication : 0,
    //       team: datas[1] ? datas[1].team : 0,
    //       next_bonus: datas[1] ? datas[1].next_bonus : formatPrice(50),
    //       percentage: datas[1] ? datas[1].percentage : 0,
    //     };
    //     const thirdPlaceData: ITableData = {
    //       id: datas[2] ? datas[2].id : 0,
    //       avatar: datas[2] ? datas[2].avatar : avatarDefault,
    //       name: datas[2] ? abbreviate(datas[2].name) : 'A Definir',
    //       referral_code: datas[2] ? datas[2].referral_code : '@a-definir',
    //       indication: datas[2] ? datas[2].indication : 0,
    //       team: datas[2] ? datas[2].team : 0,
    //       next_bonus: datas[2] ? datas[2].next_bonus : formatPrice(50),
    //       percentage: datas[2] ? datas[2].percentage : 0,
    //     };
    //     const myPlaceObject: ITableData = {
    //       id: myPlaceData ? myPlaceData.id : 0,
    //       avatar: user.avatar.avatar_url,
    //       name: abbreviate(user.name),
    //       referral_code: user.referral_code,
    //       indication: myPlaceData ? myPlaceData.indication : 0,
    //       team: myPlaceData ? myPlaceData.team : 0,
    //       next_bonus: myPlaceData ? myPlaceData.next_bonus : formatPrice(50),
    //       percentage: myPlaceData ? myPlaceData.percentage : 0,
    //     };
    //     setMyPlace(myPlaceObject);
    //     setFirstPlace(firstPlaceData);
    //     setSecondPlace(secondPlaceData);
    //     setThirdPlace(thirdPlaceData);
    //     setLearderboards(datas);
    //   })
    //   .finally(() => setLoading(false));
  }, [user]);

  const columns = useMemo(
    () => [
      {
        name: 'Ranking',
        selector: 'id',
        sortable: false,
        cell: ({ id, avatar, name, indication }: ITableData) => (
          <Ranking
            data-tag="allowRowEvents"
            className="d-flex align-items-center justify-content-center"
          >
            <p className="mb-0 fw-bold">{id}º</p>
            <img src={avatar} alt={name} className="profile mx-3" />
            <p className="mb-0 name">{name}</p>
            <img
              src={badge}
              alt="Badge"
              className={`badge-icon ms-2 ${
                indication >= 5 ? 'visible' : 'hidden'
              }`}
            />
          </Ranking>
        ),
      },
      {
        name: 'Indication',
        selector: 'indication',
        sortable: false,
      },
      {
        name: 'Total team',
        selector: 'team',
        sortable: false,
      },
      {
        name: 'Next bonus',
        selector: 'next_bonus',
        sortable: false,
      },
      {
        name: 'For qualification',
        selector: 'percentage',
        sortable: false,
        cell: ({ percentage }: ITableData) => (
          <ProgressBar percentage={percentage} />
        ),
      },
    ],
    []
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-12 mb-3 mt-4">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-center my-4 mt-lg-0">
                  <div className="col-md-6 col-lg-6 px-0">
                    <h1 className="h3 h2-xl text-center text-sm-start mb-0 fw-bold">
                      Learderboard
                    </h1>
                  </div>
                  {/* <div className="col-md-6 col-lg-4 d-flex my-4 my-md-0 align-items-center justify-content-center">
                    <p className="h6 fw-bold mb-0 me-3">
                      Overall leaders podium
                    </p>
                    <button
                      type="button"
                      className="btn-filter rounded-pill bg-tranparent d-flex align-items-center"
                    >
                      <span className="d-block me-3">All team</span>
                      <BiChevronDown size={25} />
                    </button>
                  </div> */}
                  <div className="col-lg-6 px-0">
                    <WalletRow />
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <Places className="col-12">
            <div className="row align-items-end justify-content-center">
              <div className="mb-2 mb-xl-0 col-11 col-sm-6 col-md-4 col-xl px-lg-2">
                <div className="d-flex flex-column align-items-center placing first">
                  <img src={first} alt="First" />
                  <p>1st Place</p>
                </div>
                <div className="box first-place w-100">
                  <div className="px-3 w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                    <img
                      src={
                        Object.keys(firstPlace).length > 0
                          ? firstPlace.avatar
                          : avatarDefault
                      }
                      alt={
                        Object.keys(firstPlace).length > 0
                          ? firstPlace.name
                          : 'To Define'
                      }
                      className="mb-2"
                    />
                    <h2 className="h4 text-center">
                      {Object.keys(firstPlace).length > 0
                        ? firstPlace.name
                        : 'To Define'}
                    </h2>
                    <p className="mb-0">
                      {Object.keys(firstPlace).length > 0
                        ? firstPlace.referral_code
                        : '@to-define'}
                    </p>
                    <hr />
                    <div className="d-flex align-items-center justify-content-center w-100 text-center">
                      <p className="me-2 mb-2 me1 text-light-gray">
                        Personal Referral:
                      </p>
                      <p className="mb-2 big fw-bold text-gray">
                        {Object.keys(firstPlace).length > 0
                          ? firstPlace.indication
                          : 0}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center w-100 text-center">
                      <p className="me-2 mb-0 me1 text-light-gray">
                        Total Team:
                      </p>
                      <p className="mb-0 big fw-bold text-gray">
                        {Object.keys(firstPlace).length > 0
                          ? firstPlace.team
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-2 mb-xl-0 col-11 col-sm-6 col-md-4 col-xl px-lg-2">
                <div className="d-flex flex-column align-items-center placing second">
                  <img src={second} alt="Second" />
                  <p>2nd Place</p>
                </div>
                <div className="box second-place w-100">
                  <div className="px-3 w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                    <img
                      src={
                        Object.keys(secondPlace).length > 0
                          ? secondPlace.avatar
                          : avatarDefault
                      }
                      alt={
                        Object.keys(secondPlace).length > 0
                          ? secondPlace.name
                          : 'To Define'
                      }
                      className="mb-2"
                    />
                    <h2 className="h4 text-center">
                      {Object.keys(secondPlace).length > 0
                        ? secondPlace.name
                        : 'To Define'}
                    </h2>
                    <p className="mb-0">
                      {Object.keys(secondPlace).length > 0
                        ? secondPlace.referral_code
                        : '@to-define'}
                    </p>
                    <hr />
                    <div className="d-flex align-items-center justify-content-center w-100 text-center">
                      <p className="me-2 mb-2 me1 text-light-gray">
                        Personal Referral:
                      </p>
                      <p className="mb-2 big fw-bold text-gray">
                        {Object.keys(secondPlace).length > 0
                          ? secondPlace.indication
                          : 0}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center w-100 text-center">
                      <p className="me-2 mb-0 me1 text-light-gray">
                        Total Team:
                      </p>
                      <p className="mb-0 big fw-bold text-gray">
                        {Object.keys(secondPlace).length > 0
                          ? secondPlace.team
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-0 mb-md-2 mb-xl-0 col-11 col-sm-6 col-md-4 col-xl px-lg-2">
                <div className="d-flex flex-column align-items-center placing third">
                  <img src={third} alt="Third" />
                  <p>3rd Placer</p>
                </div>
                <div className="box third-place w-100">
                  <div className="px-3 w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                    <img
                      src={
                        Object.keys(thirdPlace).length > 0
                          ? thirdPlace.avatar
                          : avatarDefault
                      }
                      alt={
                        Object.keys(thirdPlace).length > 0
                          ? thirdPlace.name
                          : 'To Define'
                      }
                      className="mb-2"
                    />
                    <h2 className="h4 text-center">
                      {Object.keys(thirdPlace).length > 0
                        ? thirdPlace.name
                        : 'To Define'}
                    </h2>
                    <p className="mb-0">
                      {Object.keys(thirdPlace).length > 0
                        ? thirdPlace.referral_code
                        : '@to-define'}
                    </p>
                    <hr />
                    <div className="d-flex align-items-center justify-content-center w-100 text-center">
                      <p className="me-2 mb-2 me1 text-light-gray">
                        Personal Referral:
                      </p>
                      <p className="mb-2 big fw-bold text-gray">
                        {Object.keys(thirdPlace).length > 0
                          ? thirdPlace.indication
                          : 0}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center w-100 text-center">
                      <p className="me-2 mb-0 me1 text-light-gray">
                        Total Team:
                      </p>
                      <p className="mb-0 big fw-bold text-gray">
                        {Object.keys(thirdPlace).length > 0
                          ? thirdPlace.team
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 col-sm-6 col-xl px-lg-2">
                <div className="d-flex flex-column align-items-center your-position">
                  <p className="mb-0 text-light-gray fw-semibold">
                    Your Position{' '}
                  </p>
                  <p className="h4 mb-3">
                    {myPlace.id ? `${myPlace.id} ° Place` : 'to define'}
                  </p>
                </div>
                <div className="box w-100">
                  <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                    <img
                      src={myPlace.avatar || avatarDefault}
                      alt="avatar"
                      className="mb-2 border-gradient"
                    />
                    <h2 className="h4 text-center">
                      {myPlace.name || 'To Define'}
                    </h2>
                    <p className="mb-0">
                      {myPlace.referral_code || '@to-define'}
                    </p>
                    <hr />
                    <div className="d-flex align-items-center justify-content-center text-center w-100 px-xxl-4">
                      <p className="me-2 mb-2 me1 text-light-gray">
                        Personal Referral:
                      </p>
                      <p className="mb-2 big fw-bold text-gray">
                        {myPlace.indication || 0}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center text-center w-100 px-xxl-4">
                      <p className="me-2 mb-0 me1 text-light-gray">
                        Total Team:
                      </p>
                      <p className="mb-0 big fw-bold text-gray">
                        {myPlace.team || 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href="/"
                className="col-11 col-sm-12 col-md-6 col-xl-2 px-lg-2 mt-3 mt-lg-0"
              >
                <div className="details-box w-lg-100 height">
                  <div className="d-flex flex-column justify-content-around align-items-center py-3 px-3 px-xxl-3 w-100 h-100">
                    <img src={info} alt="Details about the Promotion" />
                    <p className="h5 h6-xxl text-center my-3">
                      Details about <br className="d-none d-xxl-block" />
                      the Promotion
                    </p>
                    <img
                      src={details}
                      alt="Details about the Promotion"
                      className="w-75 w-sm-50 w-md-75 w-xl-100 thumb"
                    />
                  </div>
                </div>
              </a>
            </div>
          </Places>
          <RankingTable className="col-11 col-sm-12 px-lg-2 mt-3 mt-lg-5">
            <div className="ranking p-3 pt-5">
              <div className="d-flex align-items-center px-3">
                <h3 className="h6 h5-md mb-0 me-4">Ranking</h3>
                {/* <button
                  type="button"
                  className="btn-filter rounded-pill bg-tranparent d-flex align-items-center"
                >
                  <span className="d-block me-3">Today</span>
                  <BiChevronDown size={25} />
                </button> */}
              </div>
              <Table data={learderboards} columns={columns} pagination />
            </div>
          </RankingTable>
        </div>
      </div>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Learderboard;
