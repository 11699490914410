import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';
import banner from '~/assets/banners/banner-user-profile.png';

export const Container = styled.div`
  padding: 0 20px;
  a {
    font-weight: 300;
    font-size: 16px;
    color: #7d7777;
  }

  .bg-serial-qrcode {
    position: relative;
    bottom: 163px;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(7.5px);
    margin-bottom: -149px;

    border-radius: 0px 0px 35px 35px;
  }

  .bg-qrcode {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.62);
    border-radius: 25px;
  }

  .btn-edit {
    height: 30px;
    background-color: transparent;
    border: none;
  }

  .nft {
    filter: drop-shadow(5px 11px 140px rgba(0, 0, 0, 0.25));
    border-radius: 35px;
  }

  .w-60 {
    width: 60%;
  }

  .w-30 {
    width: 30%;
  }

  .bg-right-side button {
    background: rgba(255, 255, 255, 0.32);
    backdrop-filter: blur(5px);
    border-radius: 18px;
    padding: 15px;
  }

  .bg-nft {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #fff;
    backdrop-filter: blur(5px);
    border-radius: 40px;
    box-shadow: 0px 80px 37px -15px rgba(0, 0, 0, 0.25);
    width: none;
  }

  .bg-nft img.nft {
    height: 100%;
  }

  .bg-nft span {
    color: #fffefb;
    font-size: 35px;
    line-height: 50px;
  }
  .member-id {
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: #d6d6d6;
  }

  .nft-number {
    font-weight: 600;
    font-size: 64px;
    line-height: 77px;
    color: #ffffff;
  }

  .rank {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #d6d6d6;
  }

  .refered {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  .btn-image,
  .btn-image button,
  .btn-image button img {
    border-radius: 28px;
    transition: all 0.2s ease;
  }

  .btn-image button :hover {
    box-shadow: 10px 10px 21px 0px rgba(0, 0, 0, 0.6);
    opacity: 0.9;
    transform: scale(1.1);
  }

  .bg-right-side {
    background-image: url(${banner});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }

  @media screen and (max-width: 1799px) {
    .zoom {
      zoom: 0.8;
    }

    .bg-nft span {
      font-size: 28px;
      line-height: 40px;
    }

    .bg-nft {
      width: 75%;
    }

    .bg-serial-qrcode {
      bottom: 148px;
    }
  }

  @media screen and (max-width: 1399px) {
    .zoom {
      zoom: 0.7;
    }

    .bg-serial-qrcode {
      bottom: 132px;
    }
  }

  @media screen and (min-height: 730px) {
    .bg-right-side {
      height: 100vh;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .bg-close {
    background-color: #282a2d;
    border-radius: 42px 42px 0px 0px;
  }

  .bg-btn {
    background: rgba(152, 148, 148, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 20px;
  }

  .bg-btn:hover {
    //box-shadow: 10px 10px 21px 0px rgba(0, 0, 0, 0.6);
    opacity: 0.9;
    //transform: scale(1.05);
    border-radius: 20px;
  }

  .prize {
    font-weight: 500;
    font-size: 12px;
    line-height: 35px;
    color: #ffffff;
  }

  .prize-value {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #bbbbbb;
  }

  .avaliable {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400;
    font-size: 20px;
    color: #c9c9c9;
  }

  .w-60 {
    width: 60%;
  }

  .w-30 {
    width: 30%;
  }

  .header img {
    height: 52px;
    width: 52px;
  }
  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }
  .modal-xl {
    width: 1140px;
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }
`;

export const ModalChangeNft = styled(ModalComponent)`
  p {
    font-weight: 300;
    font-size: 24px;
    color: #606060;
  }

  button {
    background: #18191a;
    border-radius: 20px;
    color: #f0ebeb;
    font-size: 24px;
    transition: all 0.3s ease;
  }

  form {
    border: 2px dashed #5e5e5e !important;
    border-radius: 25px;
  }

  label {
    cursor: pointer;
    width: 900px;
    :hover p {
      text-decoration: underline;
    }
  }

  /* .modal-dialog {
    min-width: 500px;
  } */

  .preview img {
    max-height: 400px;
    min-height: 350px;
    max-width: 680px;
    border-radius: 25px 25px 0 0;
  }

  .preview button {
    background: #18191a;
    border-radius: 20px;
    color: #f0ebeb;
    font-size: 24px;
    transition: all 0.3s ease;
  }

  .preview button:hover {
    background: ${lighten(0.1, '#18191a')};
  }

  .preview :nth-child(2) {
    font-weight: 400;
    font-size: 22px;
    color: #dadada;
  }

  .preview :nth-child(4) {
    font-weight: 400;
    font-size: 22px;
    color: #999393;
    :hover {
      text-decoration: underline;
    }
  }

  .icon-plus {
    height: 118px;
  }

  .modal-close {
    color: #fff;
    height: 50px;
    width: 50px;
    right: 0px;
  }

  @media screen and (max-width: 992px) {
    .preview {
      max-width: 450px;
    }
  }

  @media screen and (max-width: 625px) {
    .preview {
      max-width: 100%;
    }
  }
`;
