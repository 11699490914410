import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface IProgressBarProps {
  percentage: number;
}

const ProgressBar: React.FC<IProgressBarProps> = ({ percentage }) => {
  const options = useMemo(() => {
    const data: ApexOptions = {
      colors: [`${percentage >= 0 ? '#00F9AB' : '#f9000f'}`],
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          hollow: {
            size: '50%',
          },
          track: {
            show: true,
            background: '#fff',
            strokeWidth: '97%',
            opacity: 1,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: true,
              offsetY: 5,
              color: 'white',
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: [`${percentage >= 0 ? 'Lucros' : 'Perdas'}`],
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, w }) => {
          return `<div class="arrow_box">
              <span>${w.globals.labels[0]}: ${series[seriesIndex]}%</span>
            </div>`;
        },
      },
    };

    return data;
  }, [percentage]);

  const series = useMemo(
    () => [percentage >= 0 ? percentage : percentage * -1],
    [percentage]
  );

  const location = useLocation();

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height={location.pathname === '/trader/trading' ? 140 : 110}
    />
  );
};

export default ProgressBar;
