import React, { useEffect, useState, useCallback } from 'react';
import Lottie from 'react-lottie';
import { Container, Modal } from './styles';
import ConnectWallet from '~/components/ConnectWallet';
import WalletLink from '~/components/WalletLink';

import logo from '~/assets/logo/logo-p-black.svg';
import noReferrer from '~/assets/animations/no-referrer.json';
import telegram from '~/assets/defaults/telegram.svg';
import discord from '~/assets/defaults/discord.svg';
import twitter from '~/assets/defaults/twitter.svg';
import tikTok from '~/assets/defaults/tik-tok.svg';
import facebook from '~/assets/defaults/facebook.svg';
import instagram from '~/assets/defaults/instagram.svg';
import youtube from '~/assets/defaults/youtube.svg';
import reddit from '~/assets/defaults/reddit.svg';

const Header: React.FC = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Container className="py-3 pb-xxl-0 pt-xxl-5 position-absolute w-100">
      <div className="container-fluid">
        <div className="row row-logo justify-content-between align-items-center">
          <div className="col-4 col-sm-6 col-lg-2 text-lg-center px-0">
            <img
              src={logo}
              alt="logo"
              className="ms-4 ms-sm-5 ms-lg-0 mx-lg-auto width"
            />
          </div>

          <div className="col-8 col-sm-6 col-lg-3 text-end text-lg-center">
            <WalletLink
              onShowNoReferrer={handleShow}
              btnText="Connect Wallet"
              className="btn-wallet me-4 me-lg-0"
            />
          </div>
        </div>
      </div>
      <ConnectWallet />
      <Modal
        className="modal-connect-wallet text-white d-flex"
        size="xl"
        show={show}
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <div className="animation-gray">
              <div className="d-flex align-items-center">
                <Lottie
                  options={{
                    animationData: noReferrer,
                    autoplay: true,
                    loop: true,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={165}
                  width={198}
                />
              </div>
            </div>
            <div className="d-flex flex-column justify-content-around align-items-center text-center description">
              <h3>We could not find your referral code \=</h3>
              <p>
                PROFITi is a invite-only community,{' '}
                <b>please double check the referral link</b> that brought you
                here other wise if you do not have a referral code yet, you can
                find one by asking around in our social channels below.
              </p>
              <p>We hope to see you inside soon!</p>
            </div>
            <div className="overflow-auto bg-lg-social-medias">
              <div className="d-lg-flex justify-content-sm-around social-medias  px-2 px-lg-5">
                <div className="bg-mobile-social-medias px-4 px-lg-0">
                  <a
                    target="_blank"
                    href="https://t.me/ProfitiAi"
                    rel="noreferrer"
                  >
                    <img src={telegram} alt="Telegram" />
                  </a>
                  <a
                    target="_blank"
                    href="https://discord.gg/KxdwygBsKk"
                    rel="noreferrer"
                  >
                    <img src={discord} alt="Discord" />
                  </a>
                  <a
                    target="_blank"
                    href="https://twitter.com/profitiai"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt="Twitter" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.tiktok.com/@profitiai"
                    rel="noreferrer"
                  >
                    <img src={tikTok} alt="Tik-Tok" />
                  </a>
                </div>
                <div className="bg-mobile-social-medias px-4 px-lg-0 mt-3 mt-lg-0">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/profitiai"
                    rel="noreferrer"
                  >
                    <img src={facebook} alt="Facebook" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/profitiai/"
                    rel="noreferrer"
                  >
                    <img src={instagram} alt="Instagram" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/@profitiai/channels"
                    rel="noreferrer"
                  >
                    <img src={youtube} alt="Youtube" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.reddit.com/r/profitiai/"
                    rel="noreferrer"
                  >
                    <img src={reddit} alt="Reddit" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Header;
