import React, { useState, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import { web3store } from '~/store';

import WalletRow from '~/components/WalletRow';
import Examples from './NftExamples';
import LoadingNft from './LoadingNft';
import NftResult from './NftResult';
import NftUploaded from './NftUploaded';
import { Modal } from './styles';
import paint from '~/assets/icons/paint-palette.svg';
import plusUploadImage from '~/assets/icons/plus-upload-image.svg';
import title from '~/assets/logo/ai-creator.svg';
import AiCreatorTitle from '~/components/AiCreatorTitle';

const Nft: React.FC = () => {
  const [testLoading, setTestLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState('');
  const [imageChoise, setImageChoise] = useState('');
  const location = useLocation();
  const history = useHistory();
  // AI Image generator - global
  const [aiigSeed, setAiigSeed] = web3store.useState('aiigSeed');
  const [aiigPrompt, setAiigPrompt] = web3store.useState('aiigPrompt');
  const [aiigResult, setAiigResult] = web3store.useState('aiigResult');
  const [aiigCount, setAiigCount] = web3store.useState('aiigCount');
  const [aiigCreate, setAiigCreate] = web3store.useState('aiigCreate');

  const handleSearch = useCallback(() => {
    console.log('searching...');
    console.log(aiigPrompt);
    setAiigCreate(true);
    history.push(`${process.env.PUBLIC_URL}/products/ai-art/result`);

    setTestLoading(true);
    setTimeout(() => {
      setTestLoading(false);
    }, 50000);
  }, [history, aiigPrompt, aiigCount]);

  const handleImg = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setImage(URL.createObjectURL(file));
    }
  }, []);

  return (
    <>
      <AiCreatorTitle loading={testLoading} />

      {location.pathname === '/products/ai-art/generation' && !testLoading && (
        <Examples onClickSearch={handleSearch} />
      )}
      {location.pathname === '/products/ai-art/result' && !testLoading && (
        <NftResult onClickSearch={handleSearch} />
      )}
      {/* {location.pathname === '/products/ai-art/image-upload' &&
        !testLoading && (
          <NftUploaded onClick={handleShow} imageSelected={imageChoise} />
        )} */}

      {testLoading && <LoadingNft />}
      {/* <LoadingNft /> */}
    </>
  );
};

export default Nft;
