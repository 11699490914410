import { darken } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  .border-gradient-light {
    padding: 1px;
    transition-duration: 0.3s;
    button {
      font-weight: 500;
      font-size: 11px;
      color: #e9e9e9;
    }
  }

  .border-gradient-light:hover {
    opacity: 0.8;
  }
  .bd-botton {
    border-bottom: solid 1px #393c3d;
  }
  .bd-gray {
    border: 1px solid #333333;
    border-radius: 30px;
  }

  .btns-filter {
    button {
      background: #1e1f20;
      border-radius: 6px;
      border: none;

      padding: 4px 20px;
      font-weight: 400;
      font-size: 12px;
      color: #5d5b5b;
      transition-duration: 0.3s;
    }

    .active,
    button:hover {
      color: #e4e4e4;
    }
  }
  .value-row {
    font-weight: 300;
    font-size: 16px;
    color: #cbccce;
    span {
      font-weight: 600;
      font-size: 32px;
    }
  }
  .bg-box {
    background: #1d2023;
    border-radius: 20px;
  }

  .box-title h3,
  .box-lock h3 {
    font-weight: 300;
    font-size: 16px;
    color: #cbccce;
  }

  .box-title img {
    filter: grayscale(100%);
    width: 20px;
    height: 20px;
  }

  .box-title h5 {
    font-weight: 400;
    font-size: 36px;
    color: #e4e4e4;
    span {
      color: rgba(228, 228, 228, 0.4);
    }
  }

  .box-title h6 {
    font-weight: 300;
    font-size: 12px;
    color: #37c694;
  }

  .box-lock .date div {
    font-weight: 700;
    font-size: 36px;
    color: #dadada;
    span {
      font-weight: 300;
      font-size: 12px;
      color: #8d8d8d;
    }
  }

  .bg-box-blur {
    background: rgba(34, 37, 39, 60%);
    border-radius: 24px 24px 0 0;
    //height: 210px;
  }

  .bg-grad-blur {
    background-image: linear-gradient(
        262.29deg,
        rgba(227, 35, 255, 0.6) 2.98%,
        rgba(54, 109, 250, 0.6) 184.94%
      ),
      linear-gradient(
        265.14deg,
        rgba(227, 35, 255, 0.6) -104.79%,
        rgba(54, 109, 250, 0.6) 129.19%
      );

    filter: blur(50px);
    width: 40%;
    height: 40%;
    top: 30%;
    left: 30%;
    z-index: 0;
  }

  .index1 {
    z-index: 1;
  }

  .apy span:nth-child(1) {
    font-weight: 300;
    font-size: 16px;
    color: #cbccce;
  }

  .apy span:nth-child(2) {
    font-weight: 800;
    font-size: 48px;
    color: #dadada;
  }

  .apy span:nth-child(3) {
    font-weight: 300;
    font-size: 12px;
    color: #8d8d8d;
  }

  .amount-stake h3,
  .box-buy h3 {
    font-weight: 300;
    font-size: 16px;
    color: #cbccce;
    span {
      font-weight: 600;
      font-size: 18px;
      color: #cbccce;
    }
  }

  .bd-input {
    border: 1px solid #4f4f4f;
    border-radius: 15px;
  }

  .bd-input input {
    background: transparent;
    border: none;
    font-weight: 300;
    font-size: 16px;
    color: #828282;
  }

  .bd-input button {
    background: #1d2023;
    border: 1px solid #2f2f2f;
    border-radius: 10px;
    font-weight: 700;
    font-size: 10px;
    color: #797979;
  }

  .amount-stake .btn-stake {
    background: linear-gradient(
      190.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border-radius: 20px;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    border: none;
    padding: 12px;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }
  }

  .box-buy .btn-buy {
    background: #00f9ab;
    border-radius: 20px;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    border: none;
    padding: 12px;
    transition-duration: 0.3s;

    :hover {
      opacity: 0.8;
    }

    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .max-height {
    height: 352px;
    overflow: auto;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #dadada;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .texts p:nth-child(1) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 22px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts .terms {
      background: #1d1d1f;
      border-radius: 12px;
      font-weight: 300;
      font-size: 16px;
      padding: 10px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: #7d7777;
      }
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-confirm {
      background: #696ffd;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-cancel {
      background: #2b3247;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;

export const ModalFinish = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .texts p:nth-child(1),
    .texts p:nth-child(2) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 22px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts .terms {
      background: #1d1d1f;
      border-radius: 12px;
      font-weight: 300;
      font-size: 16px;
      padding: 10px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: #7d7777;
      }
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-confirm {
      background: #696ffd;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
  }
`;
