import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Welcome, Option, Cards, CV, Board } from './styles';
import TransactionHistory from '~/components/TransactionHistory';
import MyEarnings from '~/components/MyEarnings';

import dolar from '~/assets/icons/dolar-icon.svg';
import calendar from '~/assets/icons/calendar-icon.svg';
import watch from '~/assets/icons/watch-icon.svg';

const Winnings: React.FC = () => {
  return (
    <Container className="h-100 d-flex align-items-center">
      <div className="container">
        <div className="row mb-4 mt-4">
          <div className="col-12 mb-2">
            <Welcome>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <div className="mr-2">
                  <span className="h6-xs h4 h2-lg font-weight-bold">
                    <Option>
                      <Link
                        className="Link Active"
                        to={`${process.env.PUBLIC_URL}/atividades/ganhos`}
                      >
                        Ganhos
                      </Link>
                    </Option>
                  </span>
                </div>
                <div className="ml-2">
                  <span className="h6-xs h4 h2-lg font-weight-bold">
                    <Option>
                      <Link
                        to={`${process.env.PUBLIC_URL}/atividades/carteira`}
                        className="Link Disable"
                      >
                        Carteira
                      </Link>
                    </Option>
                  </span>
                </div>
              </div>
            </Welcome>
          </div>
        </div>

        <div className="row justify-content-around">
          <div className="d-none d-xxl-block col-lg-3 pl-0">
            <Cards>
              <div className="Cards d-flex flex-lg-column mx-0">
                <div className="">
                  <div className="mb-3 Card h-75">
                    <div className="col-12 py-3 px-4">
                      <div className="d-flex Title">
                        <p className="h6 mb-1 mt-3">Matriz Anual 5x4</p>
                      </div>
                      <div className="d-flex justify-content-end">
                        <p className="Value mt-xxl-2 mb-xxl-3 py-xxl-1">
                          $ 505.50
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Cards>
          </div>
          <div className="col-11 col-sm-12 col-xxl-9 mb-3">
            <CV>
              <div className="row justify-content-center justify-content-lg-between CV py-5 py-lg-3 pr-xxl-4">
                <div className="col-12 col-sm-7 col-md-4 col-lg-3">
                  <div className="row">
                    <div className="col-5 col-sm-6 ImgCv">
                      <img src={dolar} alt="Centros de Volume" />
                    </div>
                    <div className="col-5 col-sm-3 col-md-6 col-lg-6 h6 TitleCv pl-0 mb-0 mb-lg-2">
                      <span>
                        Centros de <br className="d-none d-lg-block" />
                        Volume
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-9">
                  <div className="row justify-content-around pt-4 pt-lg-0">
                    <div className="col-12 col-lg py-2 py-lg-0">
                      <div className="list-group-item CardCV pr-3 py-3 py-xl-4">
                        <div className="d-flex TitleCardCV justify-content-end">
                          <span>CV 1</span>
                        </div>
                        <div className="d-flex justify-content-end ValueCardCV">
                          <span>$25</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg py-2 py-lg-0">
                      <div className="list-group-item CardCV pr-3 py-3 py-xl-4">
                        <div className="d-flex TitleCardCV justify-content-end">
                          <span>CV 2</span>
                        </div>
                        <div className="d-flex ValueCardCV justify-content-end">
                          <span>$15</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg py-2 py-lg-0">
                      <div className="list-group-item CardCV pr-3 py-3 py-xl-4">
                        <div className="d-flex TitleCardCV justify-content-end">
                          <span>CV 3</span>
                        </div>
                        <div className="d-flex ValueCardCV justify-content-end">
                          <span>$75</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg py-2 py-lg-0">
                      <div className="list-group-item CardCV pr-3 py-3 py-xl-4">
                        <div className="d-flex TitleCardCV justify-content-end">
                          <span>CV 4</span>
                        </div>
                        <div className="d-flex ValueCardCV justify-content-end">
                          <span>$25</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg py-2 py-lg-0">
                      <div className="list-group-item CardCV pr-3 py-3 py-xl-4">
                        <div className="d-flex TitleCardCV justify-content-end">
                          <span>CV 5</span>
                        </div>
                        <div className="d-flex ValueCardCV justify-content-end">
                          <span>$205</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CV>
          </div>
          <div className="col-lg-3 pl-lg-0">
            <Cards>
              <div className="Cards d-flex flex-lg-column overflow-auto justify-content-between mx-0 mb-3 mb-lg-0">
                <div className="my-2 mt-lg-0 mb-lg-3 mx-2 mx-lg-0 d-xxl-none">
                  <div className="Card d-flex flex-column justify-content-between p-3 p-xxl-4">
                    <p className="Title h6-xl mb-0">Matriz Anual 5 x 4</p>
                    <p className="Value mb-0 align-self-end">$ 505.50</p>
                  </div>
                </div>
                <div className="my-2 mt-lg-0 mb-lg-3 mx-2 mx-lg-0">
                  <div className="Card d-flex flex-column justify-content-between p-3 p-xxl-4">
                    <p className="Title h6-xl mb-0">Matriz Mensal 5 x 6</p>
                    <p className="Value mb-0 align-self-end">$ 1,125.66</p>
                  </div>
                </div>
                <div className="my-2 mt-lg-0 mb-lg-3 mx-2 mx-lg-0">
                  <div className="Card d-flex flex-column justify-content-between p-3 p-xxl-4">
                    <div>
                      <p className="Title h6-xl mb-0">
                        Bônus Trading Performance
                      </p>
                      <small className="Description">
                        10% de Referidos Diretos
                      </small>
                    </div>
                    <p className="Value mb-0 align-self-end">$ 8.00</p>
                  </div>
                </div>
                <div className="my-2 mt-lg-0 mb-lg-3 mx-2 mx-lg-0">
                  <div className="Card d-flex flex-column justify-content-between p-3 p-xxl-4">
                    <div>
                      <p className="Title h6-xl mb-0">Bônus Consultor</p>
                      <small className="Description">
                        Licença Anual - 4% Pool
                      </small>
                    </div>
                    <p className="Value mb-0 align-self-end">$ 8.00</p>
                  </div>
                </div>
                <div className="my-2 my-lg-0 mx-2 mx-lg-0">
                  <div className="Card d-flex flex-column justify-content-between p-3 p-xxl-4">
                    <div>
                      <p className="Title h6-xl mb-0">Bônus Gerente</p>
                      <small className="Description">
                        Licença Anual - 1.5% Pool
                      </small>
                    </div>
                    <p className="Value mb-0 align-self-end">$ 3.00</p>
                  </div>
                </div>
              </div>
            </Cards>
          </div>
          <div className="bg-gray col-11 col-sm-12 col-lg-9 p-2 p-sm-3 p-lg-2 p-xl-3 d-xl-flex align-items-xl-center">
            <div className="row">
              <div className="col-12 col-xxl-7 table-xxl order-1 order-sm-0">
                <TransactionHistory />
              </div>
              <div className="col col-xxl-5 order-0 order-sm-1">
                <div className="Data order-0 order-lg-1 pb-1 pb-xxl-0 px-0 px-lg-2 px-xl-0 px-xxl-3 mb-0 mt-sm-4 mt-lg-0">
                  <div className="row align-items-xxl-center justify-content-center">
                    <div className="col-xxl-12 col-sm-9 mt-lg-3 mt-xxl-0 pr-lg-0 px-xxl-2">
                      <MyEarnings />
                    </div>
                    <div className="col-xxl-12 col-sm-3 pl-sm-0 pl-lg-3">
                      <div className="row mt-lg-3">
                        <div className="col-6 col-sm-12 col-xxl-6 mt-2 mt-sm-0 mb-3 mb-xxl-0 pl-sm-0 pr-1 px-sm-3 px-xxl-2">
                          <Board>
                            <div className="p-3 py-lg-4">
                              <div className="BoardImg mb-2">
                                <img
                                  src={calendar}
                                  alt="Disponiveis Proxima Semana"
                                />
                              </div>
                              <p className="small text-center Title">
                                Disponiveis Proxima <br />
                                Semana
                              </p>
                              <p className="Value mb-lg-1 mb-xl-2 h5">
                                $3,056.33
                              </p>
                            </div>
                          </Board>
                        </div>
                        <div className="col-6 col-sm-12 col-xxl-6 mt-2 mt-sm-0 pl-1 pr-sm-1 px-sm-3 px-xxl-2">
                          <Board>
                            <div className="p-3 py-lg-4">
                              <div className="BoardImg mb-0">
                                <img src={watch} alt="Comissões Pendentes" />
                              </div>
                              <p className="small text-center Title">
                                Comissões <br />
                                Pendentes
                              </p>
                              <p className="Value h5 mb-2 mb-lg-0 mb-xl-3 mb-xxl-2">
                                $598.50
                              </p>
                            </div>
                          </Board>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Winnings;
