import React, { useState, useCallback, useEffect, useRef } from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { XyzTransition } from '@animxyz/react';
import { FormHandles } from '@unform/core';
import { BsArrowReturnRight } from 'react-icons/bs';
import { Container, Modal, ModalNft, Avatar } from './styles';
import picasso from '~/assets/defaults/picasso-example.png';
import sun from '~/assets/defaults/sun-example.png';
import monkey from '~/assets/defaults/monkey-example.png';
import car from '~/assets/defaults/car-example.png';
import hero from '~/assets/defaults/hero-example.png';
import rabit from '~/assets/defaults/rabit-example.png';
import nftIcon from '~/assets/icons/nft-icon.svg';
import wand from '~/assets/icons/wand-icon.svg';
import bookmark from '~/assets/icons/bookmark-icon.svg';
import download from '~/assets/icons/download-icon.svg';
import share from '~/assets/icons/share-icon.svg';
import like from '~/assets/icons/like-icon.svg';
import basketExample from '~/assets/defaults/basket-example.png';
import qrCode from '~/assets/defaults/qr-code-example.png';
import logo from '~/assets/logo/logo-p-purple.svg';
import sample1 from '~/assets/defaults/sample_1.png';

import { web3store } from '~/store';
import { useAuth } from '~/hooks/Auth';
import Input from '~/components/Input';
import ButtonDownload from '~/components/ButtonDownload';
import ModalDownloadShare from '~/components/ModalDownloadShare';
import bookmarkAct from '~/assets/icons/bookmark-icon-active.svg';

interface ISearch {
  onClickSearch(): void;
}

interface ImageHistoryItem {
  id: number;
  img: string;
  description: string;
  seed: string;
  collection: number;
}

const Result: React.FC<ISearch> = ({ onClickSearch }) => {
  const history = useHistory();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);
  const [showNft, setShowNft] = useState(false);
  const [imgNft, setImgNft] = useState('');
  const [avatar, setAvatar] = useState('');
  const [descriptionNft, setDescriptionNft] = useState('');
  const [valueInput, setValueInput] = useState('');

  // AI Image generator - global
  const [aiigSeed, setAiigSeed] = web3store.useState('aiigSeed');
  const [aiigPrompt, setAiigPrompt] = web3store.useState('aiigPrompt');
  const [aiigResult, setAiigResult] = web3store.useState('aiigResult');
  const [aiigCount, setAiigCount] = web3store.useState('aiigCount');
  const [aiigCreate, setAiigCreate] = web3store.useState('aiigCreate');
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');
  const apiUrl = process.env.REACT_APP_API as string;

  type ImageResultItem = {
    id: number;
    img: string;
    description: string;
    seed: string;
    collection: number;
  };

  const [imageResult, setImageResult] = useState<ImageResultItem[]>([]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback((nft, desc) => {
    setShow(true);

    setImgNft(nft);
    setDescriptionNft(desc);
  }, []);

  useEffect(() => {
    if (user) {
      setAvatar(user.nft_img);
    }
  }, [user]);

  const handleValueInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValueInput(e.target.value);
    },
    []
  );

  useEffect(() => {
    setImageResult(aiigResult);
  }, [aiigResult]);

  const handleBookmark = useCallback((id) => {
    console.log('Bookmark ID: %s', id);
    const member = connectedMember.memberId;
    console.log('Member: %s', member);
    axios
      .post(apiUrl.concat('v1/ai/image/bookmark'), {
        tokenID: member,
        imageID: id,
      })
      .then(function (acctresponse) {
        console.log(acctresponse.data);
        setImageResult((prevImageResult) =>
          prevImageResult.map((item) =>
            item.id === id
              ? {
                  ...item,
                  img: acctresponse.data.img,
                  collection: acctresponse.data.collection,
                }
              : item
          )
        );
      })
      .catch(function (error) {
        console.log('================= ERROR ===================');
        console.log(error);
        // setImgCommand('');
      });
  }, []);

  return (
    <>
      <Container ref={formRef} onSubmit={() => '/'}>
        <div className="container">
          <div className="row img-example">
            <div className="col-12 mt-5">
              <div className="row h-100 justify-content-center">
                {aiigResult.map((item: any) => (
                  <div key={item.id} className="col-3 pt-4 px-2">
                    <div className="border-nft p-2">
                      <div className="hover-btns position-relative">
                        <button
                          type="button"
                          className="hover border-0 bg-transparent position-relative w-100"
                          onClick={() => handleShow(item.img, item.description)}
                        >
                          <img
                            src={item.img}
                            alt={item.img}
                            className="w-100"
                          />
                          <div className="position-absolute p-3 bg" />
                        </button>
                        {/* <div className="btns d-flex justify-content-between position-absolute w-100">
                          <button type="button">Upscale</button>
                          <button type="button">Regenerate</button>
                        </div> */}
                      </div>

                      <div className="d-flex justify-content-between align-items-center px-3 py-3">
                        <div className="d-flex align-items-center w-auto avatar-text">
                          <div className="avatar me-2 d-flex align-items-center text-center rounded-circle">
                            <Avatar src={avatar} className="rounded-circle" />
                          </div>
                          {user?.screen_name}
                        </div>
                        <div>
                          <button
                            type="button"
                            className="border-0 bg-transparent"
                            onClick={() =>
                              handleShow(item.img, item.description)
                            }
                          >
                            <img
                              src={nftIcon}
                              title="Nft"
                              alt="Nft"
                              className="px-2"
                            />
                          </button>
                          <ButtonDownload
                            img={item.img}
                            className="border-0 bg-transparent"
                          >
                            <img
                              src={download}
                              title="Download"
                              alt="Download"
                              className="px-2"
                            />
                          </ButtonDownload>

                          <button
                            type="button"
                            className="border-0 bg-transparent"
                            onClick={() => handleBookmark(item.id)}
                          >
                            <img
                              src={bookmark}
                              title="Save"
                              alt="Bookmark"
                              className="px-2"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="area-send position-fixed">
          <div className="d-flex mx-auto align-items-center zoom border-textarea">
            <Input
              name="prompt"
              className="w-100 border-0 bg-transparent ms-4 textarea"
              placeholder="Type anything you can dream here..."
              onChange={handleValueInput}
              value={valueInput}
            />
            <button
              type="button"
              className="send d-flex py-3 me-5"
              onClick={onClickSearch}
            >
              <BsArrowReturnRight className="me-2" color="#c4c4c4" size={18} />
              Send
            </button>
          </div>
        </div>
      </Container>

      <ModalDownloadShare
        selectImg={imgNft}
        description={descriptionNft}
        show={show}
        onCloseShare={handleClose}
      />
    </>
  );
};

export default Result;
