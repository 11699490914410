/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import * as THREE from 'three';

import { gsap } from 'gsap';
import { Canvas } from './styles';

interface IRevealEffectProps {
  initializeScene: boolean;
  className?: string;
}

const TMath = THREE.MathUtils;

const conf = {
  color: 0x202020,
  objectWidth: 12,
  objectThickness: 3,
  ambientColor: 0x808080,
  light1Color: 0x202020,
  shadow: false,
  perspective: 75,
  cameraZ: 75,
};

let renderer: any = null;
let scene: any = null;
let camera: any = null;
let width: any = 0;
let height: any = 0;
let wWidth: any = 0;
let wHeight: any = 0;
let nx: any = 0;
let ny: any = 0;
let geometry: any = null;
let objects: any = [];

const RevealEffect: React.FC<IRevealEffectProps> = ({
  initializeScene,
  className,
}) => {
  const [finished, setFinished] = useState(false);
  const getRendererSize = useCallback(() => {
    const cam = new THREE.PerspectiveCamera(conf.perspective, camera.aspect);
    const vFOV = (cam.fov * Math.PI) / 180;
    width = 2 * Math.tan(vFOV / 2) * Math.abs(conf.cameraZ);
    height *= cam.aspect;
    return [width, height];
  }, []);

  const onResize = useCallback(() => {
    width = window.innerWidth;
    height = window.innerHeight;
    camera.aspect = 1;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);

    [wWidth, wHeight] = getRendererSize();
  }, [getRendererSize]);

  const initLights = useCallback(() => {
    scene.add(new THREE.AmbientLight(conf.ambientColor));
    const light = new THREE.PointLight(0xffffff);
    light.position.z = 100;
    scene.add(light);
  }, []);

  const startAnim = useCallback(() => {
    objects.forEach((mesh: any) => {
      mesh.rotation.set(0, 0, 0);
      mesh.material.opacity = 1;
      mesh.position.z = 0;
      const delay = TMath.randFloat(1, 2);
      const rx = TMath.randFloatSpread(2 * Math.PI);
      const ry = TMath.randFloatSpread(2 * Math.PI);
      const rz = TMath.randFloatSpread(2 * Math.PI);
      gsap.to(mesh.rotation, { duration: 2, x: rx, y: ry, z: rz, delay });
      gsap.to(mesh.position, {
        duration: 2,
        z: 80,
        delay: delay + 0.5,
        ease: 'power1.out',
      });
      gsap.to(mesh.material, { duration: 2, opacity: 0, delay: delay + 0.5 });
    });
    setTimeout(() => {
      setFinished(true);
    }, 4500);
  }, []);

  const initObjects = useCallback(() => {
    objects = [];
    nx = Math.round(wWidth / conf.objectWidth) + 1;
    ny = Math.round(wHeight / conf.objectWidth) + 1;
    let mesh;
    let x;
    let y;
    for (let i = 0; i < nx; i += 1) {
      for (let j = 0; j < ny; j += 1) {
        const materialData = new THREE.MeshLambertMaterial({
          color: conf.color,
          transparent: true,
          opacity: 1,
        });
        mesh = new THREE.Mesh(geometry, materialData);
        x = -wWidth / 2 + i * conf.objectWidth;
        y = -wHeight / 2 + j * conf.objectWidth;
        mesh.position.set(x, y, 0);
        objects.push(mesh);
        scene.add(mesh);
      }
    }
    startAnim();
  }, [startAnim]);

  const initScene = useCallback(() => {
    if (renderer && camera) {
      onResize();
      scene = new THREE.Scene();
      initLights();
      initObjects();
    }
  }, [initLights, initObjects, onResize]);

  const animate = useCallback(() => {
    if (!finished) {
      if (renderer && scene && camera) {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      }
    }
  }, [finished]);

  useEffect(() => {
    renderer = new THREE.WebGLRenderer({
      canvas: document.getElementById('reveal-effect') as HTMLCanvasElement,
      antialias: true,
      alpha: true,
    });

    camera = new THREE.PerspectiveCamera(
      conf.perspective,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    scene = new THREE.Scene();
    geometry = new THREE.BoxGeometry(
      conf.objectWidth,
      conf.objectWidth,
      conf.objectThickness
    );
  }, []);

  useEffect(() => {
    if (renderer) {
      renderer.setSize(window.innerWidth, window.innerHeight);
      document.body.appendChild(renderer.domElement);
    }
  }, []);

  useEffect(() => {
    if (camera) {
      camera.position.z = conf.cameraZ;
    }
  }, []);

  useEffect(() => {
    if (camera && renderer && scene) {
      if (initializeScene) {
        initScene();
      }

      animate();
    }
  }, [animate, initScene, initializeScene]);

  return (
    <Canvas className={className} finished={finished} id="reveal-effect" />
  );
};

export default RevealEffect;
