import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    font-weight: 300;
    font-size: 16px;
    color: #5d5b5b;
  }
  .container {
    max-width: 1700px;
  }

  .bg-favorites {
    color: #dadada;
    background: #1f2020;
    border-radius: 20px;
    width: 100%;
    height: 401px;
  }

  .btn-create {
    border: 1.5px solid #1f2020;
    border-radius: 20px;
    color: #dadada;
    font-size: 16px;
    padding-bottom: 4rem;
  }
`;
