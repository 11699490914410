import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from './styles';

import graph from '~/assets/defaults/graph-value-crypto.png';
import shield from '~/assets/defaults/shield-coin.png';
import cube from '~/assets/defaults/cube-crypto.png';
import ButtonBack from '~/components/ButtonBack';

const ValueCrypto: React.FC = () => {
  const history = useHistory();
  const [hover, setHover] = useState(0);
  const [semiHover, setSemiHover] = useState(false);

  const handleHover = useCallback((e) => {
    setHover(e);
  }, []);

  return (
    <Container className="h-100">
      <div className="d-none d-lg-flex ps-4 pt-4 mb-n5">
        <ButtonBack
          className="btn-back"
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/token-vault`);
          }}
        />
      </div>
      <img src={shield} alt="" className="shield" />
      <img src={cube} alt="" className="cube" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 px-lg-0">
            <h2 className="mt-4 mb-3">The tokenomics</h2>
            <h1 className="mb-3">The real value of Crypto</h1>
            <p>
              PROFITi’s utility token was designed to bring the utmost usability
              as every product of the ecosystem is available as a credit based
              system paid in PROFTI, while also being deflationary as credits
              paid buy users are burned as well as the tokens were strategically
              allocated to eliminate the chances of “dumping”, “rug pull” and
              large wales
            </p>
          </div>
        </div>
        <div className="row mt-4 align-items-center">
          <div className="col-lg-6 px-lg-0">
            <img src={graph} alt="Graph" className="image" />
          </div>
          <div className="col-lg-6">
            <div
              onMouseEnter={() => handleHover(1)}
              onMouseLeave={() => handleHover(0)}
              className={`${hover === 1 && 'grow-item'} ${
                hover === 2 && 'semi'
              } d-flex pb-4 list justify-content-end`}
            >
              <div className="bd-left bg-grad-1" />
              <div className="d-flex bd-blur">
                <div>
                  <h3>
                    <span className="grad-1">78%</span> &nbsp;Staking Rewards
                  </h3>
                  <p className="mb-1">
                    To be minted into the market overtime only through staking
                    rewards
                  </p>
                  <a href="/">wallet: 0xdosdfk243k40adiu32e</a>
                </div>
                <div className="tokens-value d-flex flex-column justify-content-center text-end">
                  <span className="d-block">tokens</span>
                  <span className="d-block">16,380,000</span>
                </div>
              </div>
            </div>

            <div
              onMouseEnter={() => handleHover(2)}
              onMouseLeave={() => handleHover(0)}
              className={`${hover === 2 && 'grow-item'} ${
                hover === 1 || hover === 3 ? 'semi' : ''
              } d-flex pb-4 list justify-content-end`}
            >
              <div className="bd-left bg-grad-2" />
              <div className="d-flex bd-blur">
                <div>
                  <h3>
                    <span className="grad-2">2%</span> &nbsp;Team Allocation
                  </h3>
                  <p className="mb-1">
                    6 months vesting, then released at 8% per month over 12
                    months
                  </p>
                  <a href="/">wallet: 0xdosdfk243k40adiu32e</a>
                </div>
                <div className="tokens-value d-flex flex-column justify-content-center text-end">
                  <span className="d-block">tokens</span>
                  <span className="d-block">420,000</span>
                </div>
              </div>
            </div>

            <div
              onMouseEnter={() => handleHover(3)}
              onMouseLeave={() => handleHover(0)}
              className={`${hover === 3 && 'grow-item'} ${
                hover === 2 || hover === 4 ? 'semi' : ''
              } d-flex pb-4 list justify-content-end`}
            >
              <div className="bd-left bg-grad-3" />
              <div className="d-flex bd-blur">
                <div>
                  <h3>
                    <span className="grad-3">5%</span> &nbsp;Rewards &
                    Promotions
                  </h3>
                  <p className="mb-1">
                    Allocation towards special rewards and partnerships to help
                    grow the ecosystem
                  </p>
                  <a href="/">wallet: 0xdosdfk243k40adiu32e</a>
                </div>
                <div className="tokens-value d-flex flex-column justify-content-center text-end">
                  <span className="d-block">tokens</span>
                  <span className="d-block">1,050,000</span>
                </div>
              </div>
            </div>

            <div
              onMouseEnter={() => handleHover(4)}
              onMouseLeave={() => handleHover(0)}
              className={`${hover === 4 && 'grow-item'} ${
                hover === 3 || hover === 5 ? 'semi' : ''
              } d-flex pb-4 list justify-content-end`}
            >
              <div className="bd-left bg-grad-4" />
              <div className="d-flex bd-blur">
                <div>
                  <h3>
                    <span className="grad-4">5%</span> &nbsp;Marketing Campaign
                  </h3>
                  <p className="mb-1">
                    Used for Project Marketing promotion and special campaigns
                  </p>
                  <a href="/">wallet: 0xdosdfk243k40adiu32e</a>
                </div>
                <div className="tokens-value d-flex flex-column justify-content-center text-end">
                  <span className="d-block">tokens</span>
                  <span className="d-block">1,050,000</span>
                </div>
              </div>
            </div>

            <div
              onMouseEnter={() => handleHover(5)}
              onMouseLeave={() => handleHover(0)}
              className={`${hover === 5 && 'grow-item'} ${
                hover === 4 && 'semi'
              } d-flex list justify-content-end`}
            >
              <div className="bd-left bg-grad-5" />
              <div className="d-flex bd-blur">
                <div>
                  <h3>
                    <span className="grad-5">10%</span> &nbsp;Launch Campaign
                  </h3>
                  <p className="mb-1">
                    Used for “Token Back” promotion and initial staking contract
                    sale
                  </p>
                  <a href="/">wallet: 0xdosdfk243k40adiu32e</a>
                </div>
                <div className="tokens-value d-flex flex-column justify-content-center text-end">
                  <span className="d-block">tokens</span>
                  <span className="d-block">2,100,000</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ValueCrypto;
