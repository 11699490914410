import styled from 'styled-components';

interface IProps {
  src: string;
}

export const Container = styled.div`
  h2 {
    color: #cbccce;
  }
`;

export const BgImage = styled.a<IProps>`
  background-image: url(${(props) => props.src});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  height: 400px;

  .title {
    font-family: 'Barlow';
    padding: 2.5rem 1.5rem;
    font-weight: 400;
    font-size: 19px;
    line-height: 19px;

    color: #000000;
    span {
      font-weight: 700;
      font-size: 30px;
      line-height: 34px;
      background: transparent;
      border-radius: 0px;
      color: #000000;
      display: block;
      padding-right: 2rem;
      margin-top: 0.75rem;
    }
  }

  .title-products {
    font-family: 'Bebas Neue';
    font-weight: 400;
    font-size: 44px;
    line-height: 46px;
    letter-spacing: -0.04em;
    color: #cdcdcd;
    padding: 2.5rem 1.5rem;
    span {
      font-family: 'Expletus Sans' !important;
    }
  }

  .bg-stamp {
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid #cdcdcd;
    backdrop-filter: blur(5px);
    height: 6rem;
    img {
      margin-top: -45px;
      margin-left: 1.5rem;
    }
  }

  .text-presentation {
    font-weight: 600;
    color: #cbccce;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 0px 0px 20px 20px;
  }

  @media screen and (max-width: 400px) {
    height: 300px;
  }
`;
