import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import party from 'party-js';
import Slider from 'react-slick';
import { BsArrowReturnRight } from 'react-icons/bs';
import { SlRefresh } from 'react-icons/sl';
import { addDays, addMinutes, parseISO } from 'date-fns';
import getValidationErros from '~/utils/getValidationsErrors';
import { web3store } from '~/store';

import { Container, Modal, ModalHistory } from './styles';
import Input from '~/components/Input';
import Loading from '~/components/Loading';
import RevealEffect from '~/components/RevealEffect';
import WalletAvatar from '~/components/WalletAvatar';

import logo from '~/assets/logo/logo-p-purple.svg';
import sample1 from '~/assets/defaults/sample_1.png';
import sample2 from '~/assets/defaults/sample_2.png';
import sample3 from '~/assets/defaults/sample_3.png';
import sample4 from '~/assets/defaults/sample_4.png';
import sample5 from '~/assets/defaults/sample_5.png';
import sample6 from '~/assets/defaults/sample_6.png';
import sample7 from '~/assets/defaults/sample_7.png';
import sample8 from '~/assets/defaults/sample_8.png';
import sample9 from '~/assets/defaults/sample_9.png';
import sample10 from '~/assets/defaults/sample_10.png';
import sample11 from '~/assets/defaults/sample_11.png';
import sample12 from '~/assets/defaults/sample_12.png';
import sample13 from '~/assets/defaults/sample_13.png';
import sample14 from '~/assets/defaults/sample_14.png';
import sample15 from '~/assets/defaults/sample_15.png';
import sample16 from '~/assets/defaults/sample_16.png';
import qrCode from '~/assets/defaults/qr-code-nft.svg';
import logoWhite from '~/assets/logo/logo-p-white.svg';

interface IFormData {
  seed: string;
  prompt: string;
}

interface IUrlHistory {
  imgURL: string;
  prompt: string;
  seed: number;
  expired_in: Date;
}

const ClaimNft: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [imgSeed, setImgSeed] = web3store.useState('imgSeed');
  const [, setImgCommand] = web3store.useState('imgCommand');
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showNft, setShowNft] = useState(false);
  const [imgURL, setImgURL] = web3store.useState('imgURL');
  const [, setNftimg] = web3store.useState('nftimg');
  const [loading, setLoading] = useState(false);

  const [valueInput, setValueInput] = useState('');
  const [seed, setSeed] = useState('');
  const [revealEffectRef, setRevealEffectRef] = useState<any>({
    current: null,
  });
  const [urlHistory, setUrlHistory] = useState<IUrlHistory[]>([]);

  const sampleImage = useMemo(
    () => [
      [
        {
          image: sample1,
          description:
            'gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
        },
        {
          image: sample2,
          description:
            'wolf knight, finely detailed armor, intricate design, silver, silk, cinematic lighting, 4k 16K, professional color grading, soft shadows, high contrast, clean sharp focus, award-winning cinematic photography, 4k up light',
        },
        {
          image: sample3,
          description:
            'Goddess of nature, native American Indian, green vines and flowers with black flowing for hair, feathers, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
        },
        {
          image: sample4,
          description:
            'beautiful female warrior wearing Dark Brotherhood armour , beautiful face, glowing eyes, epic war zone, full shot, intricate details, symmetrical, Todd Mcfarlane, Diego Dayer, Anna, Dittmann, Unreal 5, hyperrealistic, dynamic lighting, neon ambience, volumetric lighting, fantasy art',
        },
      ],
      [
        {
          image: sample5,
          description:
            'robotic goddess, cyborg, ornate translucent glowing sapphire and amethyst and emerald and silver, impressionist painting on canvas, female android, flowers, in the style of Jean-Baptiste Monge:20 , outer space, vanishing point, super highway, high speed, digital render, digital painting, beeple, noah bradley, cyril roland, ross tran, trending on artstation',
        },
        {
          image: sample6,
          description:
            'Dark skin, handsome outlaw, futuristic gunslinger, sci-fi, white hero suit, bad boy, HD, dark background,  unreal engine, 8k',
        },
        {
          image: sample7,
          description:
            'beautiful horse, portrait, finely detailed armor, intricate design, silver, silk, cinematic lighting, 4k long hair, blue eyes, colorful synthwave neon',
        },
        {
          image: sample8,
          description:
            'cute dragon holding heart, stunning, not ugly, up lighting',
        },
      ],
      [
        {
          image: sample9,
          description:
            'Viking, barbarian, warrior, glowing eyes, horned helmet, beard, angry face, high detail, inky',
        },
        {
          image: sample10,
          description:
            'A beautiful female super model, mixed media, wet paint, Jewel bug, color background, flowing liquid color down her face, shot by ZHANG JINGNA',
        },
        {
          image: sample11,
          description:
            'black woman, gorgeous cyberpunk hacker, wires cybernetic implants, in the style of adrian ghenie esao andrews jenny saville surrealism dark art by james jean takato yamamoto and blii sienkiewicz, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by greg rutkowski',
        },
        {
          image: sample12,
          description:
            'White lion, full fur, facing at the camera, very colorful, 8k, highly detailed synth wave neon',
        },
      ],
      [
        {
          image: sample13,
          description:
            'character design of a handsome young warrior with armor, cel shading, stunning quality, octane render, close-up portrait shot, intricate details, highly detailed, a 70mm portrait, iso 100, focus mode, f/100, locs, blasian, perfect composition, beautiful detailed intricate insanely detailed octane render artistic photography, concept art, cinematic perfect light, masterpiece',
        },
        {
          image: sample14,
          description:
            'cyberpunk Trooper warrior portrait, red eyes, Tribal tattoo on face, horn on the head, neon color, realistic pencil drawing, hyper-detailed, cinematic, oil painting style, HD',
        },
        {
          image: sample15,
          description:
            'blonde paladin, black and white, old man face, blue eyes, high details, cinematic lighting, moody, wrinkles in the face, uhq',
        },
        {
          image: sample16,
          description:
            'An epic portrait of an ancient warrior, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by WLOP, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
        },
      ],
    ],
    []
  );

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <button type="button">next</button>,
  };

  useEffect(() => {
    // setLoading(false); // Delete this after
    // sole.log(imgURL, 'img url');
    // if (imgURL) {
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 4000);
    // }

    console.clear();
    console.log('seed:', imgSeed);
    setSeed(imgSeed);
    console.log('AQUI');
  }, [imgSeed]);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowNft(false);

    setTimeout(() => {
      setImgURL('');
    }, 2000);
  }, [setImgURL]);

  const handleShow = useCallback(() => {
    setLoading(true);
    setShow(true);

    setTimeout(() => {
      if (cardRef.current) {
        party.confetti(cardRef.current, {
          count: 50,
          size: 2,
        });
      }
      setShowNft(true);
    }, 2000);
  }, []);

  const handleShowHistory = useCallback(() => {
    setShowHistory(true);
  }, []);

  const handleCloseHistory = useCallback(() => {
    setShowHistory(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          prompt: Yup.string().required('This value is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        revealEffectRef.current?.restartAnimation();

        console.log(seed);

        setImgSeed(seed);
        setImgCommand(data.prompt);
        setTimeout(() => {
          handleShow();
          setLoading(false);
        }, 20000);
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        }

        setLoading(false);
      }
    },
    [handleShow, revealEffectRef, seed, setImgCommand, setImgSeed]
  );

  const handleClickMint = useCallback(
    (nftImgUrl?: string) => {
      setNftimg(nftImgUrl || imgURL);
      setImgURL(nftImgUrl || imgURL);

      setTimeout(() => {
        history.push(`${process.env.PUBLIC_URL}/how-to-participate`);
      }, 100);
    },
    [history, imgURL, setImgURL, setNftimg]
  );

  const handleValueInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValueInput(e.target.value);
    },
    []
  );

  const handleClickGenerate = useCallback((item) => {
    setSeed(item.seed);
    setValueInput(item.prompt);
    setTimeout(() => {
      formRef.current?.submitForm();
    }, 100);
  }, []);

  useEffect(() => {
    const imagesHistory = localStorage.getItem('@PROFIT:imagesHistory');
    if (imagesHistory) {
      const data = JSON.parse(imagesHistory) as IUrlHistory[];
      const newUrlHistory = data.filter((item) => {
        return (
          parseISO(item.expired_in as unknown as string) >
          addMinutes(new Date(), 30)
        );
      });
      setUrlHistory(newUrlHistory);
    }
  }, []);

  useEffect(() => {
    if (urlHistory.length > 0) {
      localStorage.setItem('@PROFIT:imagesHistory', JSON.stringify(urlHistory));
    }
  }, [urlHistory]);

  const handleHistory = useCallback(
    (prompt) => {
      setUrlHistory((state: any) => [
        { imgURL, prompt, seed, expired_in: addDays(new Date(), 3) },
        ...state,
      ]);
    },
    [imgURL, seed]
  );

  const handleTryAgain = useCallback(() => {
    setTimeout(() => {
      setImgURL('');
    }, 2000);
    setShow(false);
  }, [setImgURL]);

  const handleClickSendPrompt = useCallback(() => {
    setSeed('');
    console.log('AQUI');
    setTimeout(() => {
      formRef.current?.submitForm();
    }, 100);
  }, []);

  return (
    <>
      <Container
        ref={formRef}
        onSubmit={handleSubmit}
        className="col-lg-10 max-width pb-3 p-4 p-xxl-5 mb-5 text-center text-lg-end"
      >
        <div className="d-none d-lg-flex justify-content-end logo mb-n5">
          <WalletAvatar />
        </div>
        <div className="row justify-content-center px-lg-5 mb-2 mb-lg-4">
          <div className="col-lg-11 zoom text-center">
            <h1 className="h2 display-lg-4">
              <span>Anything You Can Dream Of</span>
            </h1>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h2 className="h5 my-4 my-lg-2">
                  Here you can create your exclusive NFT using your own words
                  with our state-of-the-art AI technology.
                </h2>
                <h3 className="my-3">
                  Simply type in what you want for your unique NFT and own the
                  only one in the world.
                </h3>
                <h4>Here are some samples of what you can type:</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          {sampleImage.map((cols) => (
            <div className="col-lg-3 sample zoom px-1">
              {cols.map((item) => (
                <div className="hover position-relative mb-2">
                  <img src={item.image} alt="Sample Image" className="w-100" />
                  <div className="d-flex flex-column justify-content-between align-items-end position-absolute p-4">
                    <button
                      type="button"
                      className="border-0 text-end"
                      onClick={() => {
                        setValueInput(item.description);
                      }}
                    >
                      Try this
                    </button>

                    <p className="text-center">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}

          <div className="area-send position-absolute">
            <div className="text-start mg-left">
              <button
                type="button"
                onClick={handleShowHistory}
                className={`history-btn ${
                  urlHistory.length === 0 && 'invisible'
                }`}
              >
                <SlRefresh color="#8d8d8d" size={16} className="me-2" /> VIEW
                HISTORY
              </button>
            </div>
            <div className="d-flex mx-auto align-items-center zoom border-textarea">
              <Input
                name="prompt"
                className="w-100 border-0 bg-transparent ms-4 textarea"
                placeholder="Type anything you can dream here..."
                onChange={handleValueInput}
                value={valueInput}
              />
              <button
                type="button"
                className="send d-flex py-3 me-5"
                onClick={handleClickSendPrompt}
              >
                <BsArrowReturnRight
                  className="me-2"
                  color="#c4c4c4"
                  size={18}
                />
                Send
              </button>
            </div>
          </div>
        </div>

        <ModalHistory
          size="xl"
          show={showHistory}
          onHide={handleCloseHistory}
          className="modal-history-nft"
        >
          <button
            type="button"
            className="h4 modal-close m-3 ms-auto border-0 bg-transparent"
            onClick={handleCloseHistory}
          >
            x
          </button>

          <Modal.Header className="border-0" />

          <Modal.Body>
            <div className="px-4 px-sm-5">
              <Slider {...settings}>
                {urlHistory.map((item: any) => (
                  <div className="px-2">
                    <div className="hover position-relative mb-2">
                      <img
                        src={item.imgURL}
                        alt="History Image"
                        className="w-100"
                      />
                      <div className="d-flex hover-btns flex-column justify-content-between align-items-end position-absolute p-4">
                        <div className="d-flex justify-content-between w-100">
                          <button
                            type="button"
                            onClick={() => handleClickMint(item.imgURL)}
                            className="border-0 text-end"
                          >
                            Mint NFT
                          </button>
                          <button
                            type="button"
                            onClick={() => handleClickGenerate(item)}
                            className="border-0 text-end"
                          >
                            Regenerate
                          </button>
                        </div>

                        <p className="text-center">{item.prompt}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 px-4 py-5 mb-5" />
        </ModalHistory>

        <Modal
          className="modal-claim-nft text-white d-flex"
          size="xl"
          show={show}
          showNft={showNft}
          onHide={() => {
            handleHistory(valueInput);
            handleClose();
          }}
        >
          <RevealEffect className="d-none d-lg-block" initializeScene={show} />
          <Modal.Header className="border-0 p-3 pb-0">
            <div className="d-flex justify-content-between w-100 header">
              <img src={logo} alt="Logo" className="" />
              <button
                type="button"
                className="h4 modal-close mb-0 ms-auto border-0 bg-transparent"
                onClick={() => {
                  handleHistory(valueInput);
                  handleClose();
                }}
              >
                x
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="py-0">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-9 col-lg-6 d-flex justify-content-center">
                  <div className="item-group">
                    {!loading && (
                      <div
                        ref={cardRef}
                        className="bg-nft p-2 position-relative"
                      >
                        <img
                          src={imgURL}
                          alt="NFT Generated"
                          className="w-100 nft"
                        />
                        <div className="d-flex pt-4 pb-3 bg-serial-qrcode justify-content-center align-items-center">
                          <div className="w-60">
                            <span>
                              Serial #<br />
                              <span className="fw-bold">0000123</span>
                            </span>
                          </div>
                          <div className="w-30 text-end">
                            <img
                              src={qrCode}
                              alt="Qr Code"
                              className="w-75 qr-code bg-qrcode p-3"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-sm-9 col-lg-6 px-4 pt-5 mt-5">
                  <div className="d-flex mx-auto py-3 justify-content-evenly bg-main px-3">
                    <button
                      type="button"
                      onClick={() => {
                        handleTryAgain();
                        handleHistory(valueInput);
                      }}
                      className="w-50 try-again me-2"
                    >
                      Try Again
                    </button>
                    <button
                      type="button"
                      className="w-50 btn-mint ms-2"
                      onClick={() => handleClickMint()}
                    >
                      MINT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="GENERATING ART..."
        active={loading}
      />
    </>
  );
};

export default ClaimNft;
