/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

interface ILanguage {
  sign_in: {
    h1: string;
    h2: string;
    p: string;
    label_1: string;
    label_2: string;
    a: string;
    button: string;
    erro_1: string;
    erro_2: string;
    erro_3: string;
    erro_4: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  sign_up: {
    h1: string;
    h2: string;
    p: string;
    label_1: string;
    label_2: string;
    label_3: string;
    button_1: string;
    label_4: string;
    button_2: string;
    erro_1: string;
    erro_2: string;
    erro_3: string;
    erro_4: string;
    erro_5: string;
    erro_6: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  forgot_password: {
    h1: string;
    h2: string;
    label_1: string;
    a: string;
    button: string;
    erro_1: string;
    erro_2: string;
    success: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  change_password: {
    h1: string;
    label_1: string;
    label_2: string;
    a: string;
    button: string;
    erro_1: string;
    erro_2: string;
    success: string;
    erro_3: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  header: {
    small_1: string;
    small_2: string;
    small_3: string;
    small_4: string;
    small_5: string;
    small_6: string;
    small_7: string;
    small_8: string;
  };
  courses: {
    h1: string;
    button_1: string;
    button_2: string;
    button_3: string;
    button_4: string;
    button_5: string;
  };
  course: {
    p_1: string;
    p_2: string;
    p_3: string;
    button: string;
    h2: string;
    p_4: string;
    h4: string;
  };
  dashboard: {
    h3_1: string;
    h3_2: string;
  };
  discover: {
    p: string;
  };
  my_courses: {
    p: string;
  };
  upcoming: {
    b: string;
    p: string;
  };
  certificates: {
    p: string;
  };
  lesson: {
    p_1: string;
    small: string;
    button_1: string;
    button_2: string;
    button_3: string;
    button_5: string;
    h2_1: string;
    h2_2: string;
    p_2: string;
    h2_3: string;
    h3: string;
    p_3: string;
  };
  profile: {
    h1: string;
    button_1: string;
    button_2: string;
    button_3: string;
  };
  my_profile: {
    label_1: string;
    h3_1: string;
    label_2: string;
    label_3: string;
    label_4: string;
    h3_2: string;
    label_5: string;
    label_6: string;
    label_7: string;
    label_8: string;
    h3_3: string;
    label_9: string;
    label_10: string;
    label_11: string;
    button_1: string;
    button_2: string;
  };
  subscriptions_cards: {
    h2: string;
    th_1: string;
    th_2: string;
    th_3: string;
    th_4: string;
    th_5: string;
    th_6: string;
    button_1: string;
    button_2: string;
    h4_1: string;
    input_1: string;
    input_2: string;
    input_3: string;
    input_4: string;
    input_5: string;
    button_3: string;
    button_4: string;
    h4_2: string;
    p: string;
    button_5: string;
    button_6: string;
  };
  receipts: {
    b_1: string;
    b_2: string;
    b_3: string;
  };
  resources: {
    h1: string;
    p: string;
    button_1: string;
    button_2: string;
    button_3: string;
  };
  become_affiliate: {
    h1: string;
    h2_1: string;
    p_1: string;
    p_2: string;
    h3: string;
    img_1: string;
    h2_2: string;
    p_3: string;
    p_4: string;
    p_5: string;
    p_6: string;
    p_7: string;
    p_8: string;
    p_9: string;
    p_10: string;
    p_11: string;
    p_12: string;
    p_13: string;
    p_14: string;
    a_1: string;
    button_1: string;
    img_2: string;
    h2_3: string;
    p_15: string;
    p_16: string;
    p_17: string;
    p_18: string;
    p_19: string;
    p_20: string;
    p_21: string;
    p_22: string;
    p_23: string;
    p_24: string;
    p_25: string;
    p_26: string;
    a_2: string;
    button_2: string;
  };
  products: {
    h1: string;
  };
  faq: {
    h1: string;
    input: string;
    img: string;
  };
  footer: {
    h3_1: string;
    h3_2: string;
    h2: string;
    p_1: string;
    small: string;
    button: string;
    h4: string;
    b: string;
    p_2: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  buy_component: {
    button_1: string;
    button_2: string;
    h4: string;
    b_1: string;
    b_2: string;
    button_3: string;
    button_4: string;
    button_5: string;
    error_message: string;
  };
  credit_card_component: {
    input_1: string;
    input_2: string;
    input_3: string;
    input_4: string;
    input_5: string;
  };
  quiz_component: {
    button_1: string;
    button_2: string;
    h4: string;
    p: string;
    button_3: string;
    error_message: string;
  };
  select_component: {
    input: string;
  };
  password_component: {
    button_1: string;
    button_2: string;
  };
}

interface LanguageContextData {
  language: ILanguage;
  handleSelectIdiom(idiom: string): void;
  idiom: string;
}

export const LanguageContext = createContext<LanguageContextData>(
  {} as LanguageContextData
);

export const LanguageProvider: React.FC = ({ children }) => {
  const [idiom, setIdiom] = useState(
    localStorage.getItem('@Membership:language') || 'en'
  );

  const handleSelectIdiom = useCallback((idiomSelected) => {
    setIdiom(idiomSelected);
  }, []);

  const language = useMemo(() => {
    localStorage.setItem('@Membership:language', idiom);
    return require(`./languages/${idiom}`);
  }, [idiom]);

  return (
    <LanguageContext.Provider value={{ language, handleSelectIdiom, idiom }}>
      {children}
    </LanguageContext.Provider>
  );
};

export function useLanguage(): LanguageContextData {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within an LanguageProvider');
  }

  return context;
}
