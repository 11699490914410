import React, { useCallback, useMemo, useEffect, useState } from 'react';
import {
  BigNumber,
  ethers,
  utils,
  ContractTransaction,
  providers,
} from 'ethers';

import { BsPlus } from 'react-icons/bs';
import { BsThreeDotsVertical } from 'react-icons/bs';

import Lottie from 'react-lottie';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import { Form } from '@unform/web';
import {
  Container,
  Trade,
  Welcome,
  Modal,
  ModalFinishRegister,
  ModalFinishWithdraw,
  ModalFinishDeposit,
  ModalFinishWallet,
  ModalNeed,
} from './styles';
import WalletRow from '~/components/WalletRow';
import swalError from '~/utils/swalError';
import Loading from '~/components/Loading';
import logoWhite from '~/assets/logo/logo-p-white.svg';
import earningsDone from '~/assets/animations/earnings-done.json';
import agreement from '~/assets/animations/agreement-trade.json';
import active from '~/assets/animations/active-trade.json';
import recalibrating from '~/assets/animations/recalibrating-trade.json';

import usdpi from '~/assets/logo/logo-usdpi.svg';
import usdt from '~/assets/logo/logo-usdt.svg';
import usdc from '~/assets/logo/logo-usdc.svg';
import dai from '~/assets/logo/token-dai.png';
// BUY CRYPTO
import currencyETH from '~/assets/icons/logo-eth.svg';
import currencyUSDC from '~/assets/icons/logo-usdc.svg';
import currencyBNB from '~/assets/logo/logo-bnb-border-white.svg';
import currencyUSDPI from '~/assets/logo/logo-usdpi-border-white.svg';
// BUY CRYPTO
import walletFinish from '~/assets/defaults/wallet-finish.png';
import stakingTerms from '~/assets/animations/staking-terms-details.json';
import { formatPrice } from '~/utils/format';
import Table from '~/components/Table';
import Terms from './Terms';
import InputMask from '~/components/InputMask';
import { web3store } from '~/store';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import {
  getConfigAddress,
  getConfigNumber,
  getConfigString,
} from '~/utils/configContract';
import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';

const logoMap: { [key: string]: string } = {
  usdt,
  usdpi,
  usdc,
  dai,
  default: usdt,
};

interface ITrending {
  id: number;
  name: string;
  percentage: number;
  img: string;
}

interface IPair {
  asset: string;
  stable: string;
}

interface ITrade {
  id: number;
  entry: number;
  exit: number;
  roi: number;
  roi_amt: number;
  fee_amt: number;
  ts: number;
  profit_loss: number;
  pair: IPair;
  txid: string;
  tx_chain: string;
  img: string;
}

interface IRollup {
  total_roi_today: number | null;
  total_roi_amt_today: number | null;
  total_roi_wtd: number | null;
  total_roi_amt_wtd: number | null;
  total_roi_mtd: number | null;
  total_roi_amt_mtd: number | null;
  total_roi_all_time: number | null;
  total_roi_amt_all_time: number | null;
}

interface IWithdrawalRequest {
  nftId: number;
  userAddress: string;
  message: string;
  deadline: number;
  transaction_fee: BigNumber;
}

/* eslint no-underscore-dangle: 0 */
const SmartTrade: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [processText, setProcessText] = web3store.useState('processText');
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');
  const [account, setAccount] = web3store.useState('account');
  const [trending, setTrending] = useState<ITrending[]>([]);
  const [trades, setTrades] = useState<ITrade[]>([]);
  const [rollups, setRollups] = useState<IRollup | null>(null);
  // const [balance, setBalance] = useState<number | null>(null);
  const [balance, setBalance] = useState(0);
  const [tradeBalance, setTradeBalance] = useState<number | null>(null);
  const [accountStatus, setAccountStatus] = useState<string | null>(null);
  const [modalCongratulations, setModalCongratulations] = useState(false);
  const [show, setShow] = useState(false);
  const [showFinishWallet, setShowFinishWallet] = useState(false);
  const [showFinishWithdrawal, setShowFinishWithdrawal] = useState(false);
  const [showFinishDeposit, setShowFinishDeposit] = useState(false);
  const [valueDeposit, setValueDeposit] = useState('0');
  const [valueWithdraw, setValueWithdraw] = useState('0');
  const [isDeposit, setIsDeposit] = useState(true);
  const [showTerms, setShowTerms] = useState(false);
  const [usdpiBalance, setUsdpiBalance] = useState('0');
  const [available, setAvailable] = useState('0.00');
  const [fixedAmount, setFixedAmount] = useState(0);
  // BUY CRYPTO
  const [showNeed, setShowNeed] = useState(false);
  const [selectCoin, setSelectCoin] = useState('usdpi');
  const [ethBalance, setEthBalance] = useState('0');
  // BUY CRYPTO
  // DEPOSIT
  const [selectedSource, setSelectedSource] = useState<string | null>(
    'metamask'
  );
  const [depositTX, setDepositTX] = useState('');
  const [depositTXChain, setDepositTXChain] = useState('NETWORK');
  const [depositError, setDepositError] = useState<string | null>(null);
  const [withdrawError, setWithdrawError] = useState<string | null>(null);
  const [fixedDepositAmount, setFixedDepositAmount] = useState<string | null>(
    null
  );
  const [fixedWithdrawalAmount, setFixedWithdrawalAmount] = useState<
    string | null
  >(null);
  const explorerURL = process.env.REACT_APP_NETWORK_BLOCK_EXPLORER_URLS;
  const explorerURLEth = process.env.REACT_APP_PAY_BLOCK_EXPLORER_URLS;
  // DEPOSIT

  const topTrades = useMemo(
    () => [
      {
        id: 1,
        name: 'USDT/SOL',
        percentage: +0.4,
        img: usdt,
      },
      {
        id: 2,
        name: 'UNI/USDC',
        percentage: +2.45,
        img: usdt,
      },
      {
        id: 3,
        name: 'WRX/USDT',
        percentage: -1.3,
        img: usdt,
      },
    ],
    []
  );

  const tradeColumns = useMemo(
    () => [
      {
        name: 'Trades',
        selector: 'trades',
        sortable: false,
        className: 'left-align',
      },
      {
        name: 'Entry',
        selector: 'entry',
        sortable: false,
        className: 'right-align',
      },
      {
        name: 'Exit',
        selector: 'exit',
        sortable: false,
        className: 'right-align',
      },
      {
        name: 'ROI',
        selector: 'roi',
        sortable: false,
      },
      {
        name: 'Profit/Loss',
        selector: 'profit_loss',
        sortable: false,
        className: 'right-align',
      },
      // {
      //   name: 'Date',
      //   selector: 'date',
      //   sortable: false,
      // },
      {
        name: 'Confirmation',
        selector: 'confirmation',
        sortable: false,
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        name: 'Trades',
        selector: 'trades',
        sortable: false,
      },
      {
        name: 'Entry',
        selector: 'entry',
        sortable: false,
      },
      {
        name: 'Exit',
        selector: 'exit',
        sortable: false,
      },
      {
        name: 'ROI',
        selector: 'roi',
        sortable: false,
      },
      {
        name: 'Profit/Loss',
        selector: 'profit_loss',
        sortable: false,
      },
      {
        name: 'Date',
        selector: 'date',
        sortable: false,
      },
      {
        name: 'Confirmation',
        selector: 'confirmation',
        sortable: false,
      },
    ],
    []
  );

  const abbreviateTxId = (txId: string, startChars = 3, endChars = 5) => {
    if (!txId) return '';
    return `${txId.substring(0, startChars)}...${txId.substring(
      txId.length - endChars
    )}`;
  };

  const handleTxClick = (fullTxId: string, chain: string) => {
    if (fullTxId !== 'Pending') {
      if (chain === 'DEVNET') {
        window.open(
          `https://explorer.solana.com/tx/${fullTxId}?cluster=devnet`,
          '_blank'
        );
      }
      if (chain === 'MAINNET') {
        window.open(
          `https://explorer.solana.com/tx/${fullTxId}?cluster=devnet`,
          '_blank'
        );
      }
    }
  };

  useEffect(() => {
    if (user) {
      api
        .get(`/v2/trader/mbalances/${connectedMember.memberId}`)
        .then((response) => {
          // Parse and calculate the value as a number
          const accountValue: number = parseInt(response.data.account, 10);
          const roundedValue: number =
            Math.floor((accountValue / 10 ** 18) * 100) / 100;

          // Pass the roundedValue directly to formatPrice as a number
          setAvailable(formatPrice(roundedValue));
        });
    }
  }, [user]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const fetchBalances = useCallback(async () => {
    try {
      if (account !== '') {
        // First API call
        const response = await api.get(`v3/member/tokenBalances/${account}`);
        // console.log(response.data.balances.wallet_usdpi.toString());
        if (response.data.balances.wallet_usdpi !== usdpiBalance) {
          setUsdpiBalance(
            formatPrice(
              parseFloat(
                (
                  parseInt(response.data.balances.wallet_usdpi, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            )
          );
          // console.log(usdpiBalance);
        }
      }
      // Second API call
      if (connectedMember?.memberId) {
        const traderResponse = await api.get(
          `/v2/trader/mbalances/${connectedMember.memberId}`
        );

        // Parse and calculate the value as a number
        const accountValue: number = parseInt(traderResponse.data.account, 10);
        const roundedValue: number =
          Math.floor((accountValue / 10 ** 18) * 100) / 100;

        // Pass the roundedValue directly to formatPrice as a number
        setAvailable(formatPrice(roundedValue));
      }

      // Second API call
      // if (connectedMember?.memberId) {
      //   const traderResponse = await api.get(
      //     `/v2/trader/mbalances/${connectedMember.memberId}`
      //   );
      //   setAvailable(
      //     formatPrice(
      //       parseFloat(
      //         (parseInt(traderResponse.data.account, 10) / 10 ** 18).toFixed(2)
      //       )
      //     )
      //   );
      // }
    } catch (error) {
      console.log(error);
      // Handle errors for both API calls
      setUsdpiBalance('0');
      setAvailable('0'); // Set available balance to 0 in case of an error
    }
  }, [account, usdpiBalance, connectedMember]);

  const formatToSixDecimalPlaces = (value: number) => {
    if (typeof value !== 'number') {
      return parseFloat(value).toFixed(6); // Return the value as is if it's not a number
    }
    return value.toFixed(6); // Format to 6 decimal places
  };

  const formatToTwoDecimalPlaces = (
    value: number | string | null | undefined
  ): string => {
    if (typeof value === 'number') {
      return value.toFixed(2);
    }

    if (typeof value === 'string' && !Number.isNaN(parseFloat(value))) {
      return parseFloat(value).toFixed(2);
    }

    return 'N/A'; // No need for 'else' here
  };

  const tradeData = useMemo(
    () =>
      trades.map((trade) => ({
        trades: (
          <div>
            <img src={trade.img} alt="Logo" className="me-2" />
            {`${trade.pair.stable}/${trade.pair.asset}`}
          </div>
        ),
        entry: `$${formatToSixDecimalPlaces(trade.entry)}`,
        exit: `$${formatToSixDecimalPlaces(trade.exit)}`,
        roi: (
          <p
            className={`mb-0 d-flex align-items-center h6 fw-normal ${
              trade.roi >= 0 ? 'positive' : 'negative'
            }`}
          >
            <div
              className={`arrow-${
                trade.roi >= 0 ? 'positive' : 'negative'
              } me-2`}
            />
            {`${(trade.roi * 100).toFixed(4)}%`}
          </p>
        ),
        profit_loss: `${
          trade.profit_loss >= 0 ? '+' : ''
        }$${formatToSixDecimalPlaces(trade.profit_loss)}`,
        date: new Date(trade.ts).toLocaleDateString(),
        // confirmation: abbreviateTxId(trade.txid),
        confirmation: (
          <button
            type="button"
            onClick={() => handleTxClick(trade.txid, trade.tx_chain)}
            style={{
              cursor: 'pointer',
              color: '#438AFF',
              textDecoration: 'underline',
              border: 'none',
              background: 'none',
              padding: 0,
            }}
            title="Click to view full transaction details"
          >
            {abbreviateTxId(trade.txid)}
          </button>
        ),
      })),
    [trades]
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setIsDeposit(true);
    setModalCongratulations(false);
    setShowNeed(false);
    setShowFinishWallet(false);
    setShowFinishWithdrawal(false);
    setShowFinishDeposit(false);
  }, [
    setShow,
    setIsDeposit,
    setModalCongratulations,
    setShowNeed,
    setShowFinishWallet,
    setShowFinishWithdrawal,
    setShowFinishDeposit,
  ]);

  const handleDepositChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value;
      // Remove non-numeric characters except for the decimal point
      const numericValue = rawValue.replace(/[^0-9.]/g, '');
      console.log(numericValue);
      // Set the cleaned value to the state
      setValueDeposit(numericValue);
    },
    []
  );
  const handleWithdrawChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value;
      // Remove non-numeric characters except for the decimal point
      const numericValue = rawValue.replace(/[^0-9.]/g, '');
      // Set the cleaned value to the state
      setValueWithdraw(numericValue);
    },
    []
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const parseFloatOrNull = (
    value: string | number | null | undefined
  ): number | null => {
    if (typeof value === 'string' && !Number.isNaN(parseFloat(value))) {
      return parseFloat(value);
    }
    return value !== null && value !== undefined ? Number(value) : null;
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // BUY USDPI NOW BUTTON
  const handleBuyNowButtonClick = useCallback(() => {
    // setShowNeed(false);
    // setFund('');
    // setShowFundAccountWallet(false);
    // setIsValid(false);
    // setErrorTextFund('');
    // const exchangeUSDPIValue = amtNeededFund.toString();
    const usdpiId = '5910b23a-0fa6-46f8-ab1c-6e6a71cc0a69';
    const clientId = '9594c88f-2edf-407f-82da-b4ca569690be';
    // const url = `https://pay.c14.money/?clientId=${clientId}&targetAssetId=${usdpiId}&targetAmount=${fund}&targetAddress=
    // ${connectedMember.memberId}&bgColor=282828&mainColor=17171a&targetAssetIdLock=true`;
    const url = `https://pay.c14.money/?clientId=${clientId}&targetAssetId=${usdpiId}&targetAddress=${connectedMember.memberId}&bgColor=282828&mainColor=17171a&targetAssetIdLock=true`;
    window.open(url, '_blank');
  }, [connectedMember.memberId]);
  // }, [connectedMember.memberId, amtNeededFund, fund]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // UPHOLD
  const handleBuyNowUpholdButtonClick = useCallback(() => {
    const url = `https://uphold.com/en-us/assets/crypto/buy-eth`;
    window.open(url, '_blank');
  }, [ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // XCOINS
  const handleBuyNowXCButtonClick = useCallback(() => {
    const url = `https://xcoins.com/en/buy-crypto/ethereum/`;
    window.open(url, '_blank');
  }, [ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // PAYBIS
  const handleBuyNowPBButtonClick = useCallback(() => {
    const url = `https://paybis.com/buy-ethereum/`;
    window.open(url, '_blank');
  }, [ethBalance]);

  const fetchTraderDash = useCallback(() => {
    setProcessText('Loading trading dashboard');
    setLoading(true);
    api
      .get('v2/trader/dash', {
        params: {
          owner: connectedMember.memberId,
        },
      })
      .then(function (response) {
        // console.log(response.data);
        const roundedBalance =
          Math.floor(parseFloat(response.data.balance) * 100) / 100;
        setBalance(roundedBalance);
        // setBalance(response.data.balance);
        setTradeBalance(response.data.trade_balance);
        setAccountStatus(response.data.account_status);

        const transformedTrending = response.data.trending.map(
          (trade: ITrending) => ({
            ...trade,
            img: logoMap[trade.img.toLowerCase()] || logoMap.default,
          })
        );
        setTrending(transformedTrending);
        // setTrending(response.data.trending);

        const transformedRecent = response.data.recent.map((trade: ITrade) => ({
          ...trade,
          img: logoMap[trade.pair.stable.toLowerCase()] || logoMap.default,
        }));
        setTrades(transformedRecent);
        // setRollups(response.data.rollups);

        const parsedRollups: IRollup = {
          total_roi_today: parseFloatOrNull(
            response.data.rollups.total_roi_today
          ),
          total_roi_amt_today: parseFloatOrNull(
            response.data.rollups.total_roi_amt_today
          ),
          total_roi_wtd: parseFloatOrNull(response.data.rollups.total_roi_wtd),
          total_roi_amt_wtd: parseFloatOrNull(
            response.data.rollups.total_roi_amt_wtd
          ),
          total_roi_mtd: parseFloatOrNull(response.data.rollups.total_roi_mtd),
          total_roi_amt_mtd: parseFloatOrNull(
            response.data.rollups.total_roi_amt_mtd
          ),
          total_roi_all_time: parseFloatOrNull(
            response.data.rollups.total_roi_all_time
          ),
          total_roi_amt_all_time: parseFloatOrNull(
            response.data.rollups.total_roi_amt_all_time
          ),
        };
        setRollups(parsedRollups);
        setLoading(false);
      })
      .catch(function (error) {
        console.log('================= ERROR ===================');
        console.log(error);
      });
  }, [connectedMember]);

  const handleDepositClick = useCallback(() => {
    if (accountStatus === 'AGREEMENT') {
      setModalCongratulations(false);
      setShowTerms(true);
    } else {
      setModalCongratulations(false);
      fetchBalances();
      fetchTraderDash();
      setShow(true);
    }
  }, [accountStatus, user, setShowTerms, setShow]);

  const handleRegister = useCallback(() => {
    if (!connectedMember) {
      console.error('No connected member found');
      return;
    }
    api
      .post('v1/trader/register', {
        owner: connectedMember.memberId,
      })
      .then(function (response) {
        console.log('Registration completed', response.data);
        setShowTerms(false); // Close the modal after registration
        fetchTraderDash();
        setModalCongratulations(true);
      })
      .catch(function (error) {
        console.error('Error registering the account', error);
        // Handle any errors here
      });
  }, [connectedMember]);

  const handleSelectDepSrc = (walletType: string) => {
    if (selectedSource === walletType) {
      // setSelectedSource(null); // Deselect if already selected
      console.log('No change');
    } else {
      setSelectedSource(walletType); // Select the clicked one
      // console.log(walletType);
    }
  };

  const handleKeyPressDepSrc = (
    event: React.KeyboardEvent<HTMLDivElement>,
    walletType: string
  ) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleSelectDepSrc(walletType);
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const getSignature = async (hashData: any) => {
    try {
      if (!account) {
        throw new Error('Wallet address not found');
      } else {
        // setProcessText('Activating - Waiting for wallet signature.');
        const {
          deadline,
          amt2authorize,
          nonce,
          tokname,
          chain,
          spender,
          version,
        } = hashData;
        // console.log('tokname: %s', tokname);
        // const version = '1';
        const domainData = {
          name: tokname,
          version,
          chainId: chain,
          verifyingContract: process.env.REACT_APP_BSC_USDPI,
        };
        const types = {
          Permit: [
            {
              name: 'owner',
              type: 'address',
            },
            {
              name: 'spender',
              type: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
            },
            {
              name: 'nonce',
              type: 'uint256',
            },
            {
              name: 'deadline',
              type: 'uint256',
            },
          ],
        };
        const val = {
          owner: account,
          spender,
          value: amt2authorize,
          nonce,
          deadline,
        };
        // console.log(account);
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        const sig = await tsigner._signTypedData(domainData, types, val); // eslint-disable-line no-underscore-dangle
        return sig;
      }
    } catch (error) {
      swalError({
        message: 'There was a problem Getting your signature.',
        textButton: 'Try Again',
      });
      throw error;
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const getFundHashes = (usdpi2Send: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        if (account === '') {
          reject(new Error('Wallet address not found'));
        } else {
          // setProcessText('Activating - Preparing transaction');
          api
            .get('/v1/billing/fund/', {
              params: {
                wallet_address: account,
                amt2authorize: usdpi2Send,
              },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              swalError({
                message: 'There was a problem preparing your deposit.',
                textButton: 'Try Again',
              });
              reject(error);
            });
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleUSDPIFundAccount = useCallback(() => {
    return new Promise((resolve, reject) => {
      console.log('+++++ handleUSDPIFundAccount running +++++');
      // const f_fund = parseFloat(valueDeposit) / 10 ** 6;
      const f_fund = parseFloat(valueDeposit);
      // const f_balusdpi = parseFloat(usdpiBalance) / 10 ** 18;
      const f_balusdpi = parseFloat(usdpiBalance.replace(/,/g, ''));
      console.log('f_fund: %s, f_balusdpi: %s', f_fund, f_balusdpi);
      console.log('f_fund: %2 type: %s', f_fund, typeof f_fund);
      console.log('f_balusdpi: %2 type: %s', f_balusdpi, typeof f_balusdpi);
      if (f_fund > 0 && f_fund > f_balusdpi) {
        setShowNeed(true);
        reject(new Error('Insufficient balance'));
      } else if (f_fund > 0) {
        setProcessText('Preparing deposit - please confirm in wallet');
        setLoading(true);
        networkSwitch('NETWORK')
          .then((response) => {
            const s_fund = utils.parseEther(f_fund.toString()).toString();
            return getFundHashes(s_fund);
          })
          .then((hashes) => {
            // console.log(hashes);
            // Pass 'hashes' along with the next promise
            return getSignature(hashes).then((sigResult) => ({
              hashes,
              sigResult,
            }));
          })
          .then(({ hashes, sigResult }) => {
            // Now 'hashes' is accessible here
            setProcessText('Sending deposit to the blockchain');
            return api.post('/v1/billing/fund', {
              owner: account,
              amt: hashes.amt2authorize,
              deadline: hashes.deadline,
              sig: sigResult,
              nft_id: connectedMember.memberId,
            });
          })
          .then((fundResult) => {
            // Introduce a 5-second delay
            // setTimeout(() => {
            setLoading(false);
            setDepositTX(fundResult.data.tx);
            setDepositTXChain('NETWORK');
            setShowFinishWallet(true);
            resolve(fundResult);
            // }, 10000);
          })
          .catch((error) => {
            setLoading(false);
            swalError({
              message: 'There was a problem with the transaction',
              textButton: 'Try Again',
            });
            reject(error);
          });
      } else {
        reject(new Error('Invalid fund amount'));
      }
    });
  }, [usdpiBalance, account, connectedMember, valueDeposit]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleWithdraw = useCallback(async () => {
    // const amt2Claim = parseFloat(inputValue.replace(/,/g, ''));
    const amt2Claim = parseFloat(valueWithdraw);
    console.log(amt2Claim);
    console.log(balance);
    if (amt2Claim > 0 && balance !== null && amt2Claim <= balance) {
      if (user) {
        networkSwitch('NETWORK')
          .then((response) => {
            const amtFloat = parseFloat(valueWithdraw);
            // console.log('amtFloat: %s', amtFloat);
            if (amtFloat > 1) {
              setProcessText('Preparing withdrawal transaction');
              setLoading(true);
              // setRunWithdrawal(true);
              // GET FEE ESTIMATE
              api
                .get('/v1/trader/fee/estimate')
                .then(function (feeresponse) {
                  // console.log(feeresponse.data);
                  // const fee = feeresponse.data.estimate.fee;
                  const { fee } = feeresponse.data.estimate;
                  // processOrder(response.data.estimate.fee);
                  // const purser = process.env.REACT_APP_BSC_PURSER;
                  getConfigAddress('contract_ptb_v1')
                    .then((purserresult) => {
                      const purser = purserresult?.toString();
                      // console.log('purser: %s', purser);
                      // console.log('verifyingContract: %s', purser);
                      const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
                      console.log('deadline: %s', deadline);
                      const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
                      const chainInt = parseInt(chainNo || '0', 16);
                      console.log(chainInt);
                      // const purserold = process.env.REACT_APP_BSC_PURSER;
                      // console.log(purserold, typeof purserold);
                      const domain = {
                        name: 'Ptb',
                        version: '1',
                        chainId: chainInt,
                        verifyingContract: purser,
                      };
                      // console.log('++++++++++++++++++++++++++++++++++++++++++');
                      // console.log(domain);
                      // console.log('++++++++++++++++++++++++++++++++++++++++++');
                      const types = {
                        Request: [
                          { name: 'nftId', type: 'uint256' },
                          { name: 'userAddress', type: 'address' },
                          { name: 'message', type: 'string' },
                          { name: 'deadline', type: 'uint256' },
                          { name: 'transaction_fee', type: 'uint256' },
                        ],
                      };
                      const transaction_fee = BigNumber.from(fee);
                      const transaction_fee_in_ether = parseFloat(
                        ethers.utils.formatEther(transaction_fee)
                      );
                      const message = `I authorize this withdrawal of $${amtFloat.toFixed(
                        2
                      )}, less a transaction fee of $${transaction_fee_in_ether.toFixed(
                        2
                      )}`;
                      // console.log(message);
                      const request: IWithdrawalRequest = {
                        nftId: parseInt(user.id, 10),
                        userAddress: account,
                        message,
                        deadline,
                        transaction_fee: BigNumber.from(fee),
                      };
                      const tprovider = new ethers.providers.Web3Provider(
                        window.ethereum
                      );
                      // console.log('+++++ Wei Value +++++ ');
                      // console.log((amtFloat * 1e18).toString());
                      // console.log(
                      //   ethers.utils.parseEther(amtFloat.toString()).toString()
                      // );
                      // console.log('+++++ Wei Value +++++ ');
                      setProcessText('Waiting for signature from wallet.');
                      const tsigner = tprovider.getSigner();
                      tsigner
                        ._signTypedData(domain, types, request)
                        .then((sig) => {
                          // Handle the result (sig) here
                          // console.log('signature: %s', sig);
                          setProcessText(
                            'Sending transaction to the blockchain.'
                          );
                          api
                            .post('/v3/trader/withdraw', {
                              nftId: user.id,
                              userAddress: account,
                              message,
                              deadline,
                              signature: sig,
                              transaction_fee: fee,
                              amount_requested: ethers.utils
                                .parseEther(amtFloat.toString())
                                .toString(),
                            })
                            .then(function (withdrawalresponse) {
                              // console.log(withdrawalresponse);
                              setLoading(false);
                              setDepositTX(withdrawalresponse.data.tx);
                              fetchTraderDash();
                              fetchBalances();
                              setShowFinishWithdrawal(true);
                              // setShowFinish(true);
                              // processOrder(response.data.estimate.fee);
                              // setWithdrawalResult(response.data.tx);
                            })
                            .catch(function (withdrawalerror) {
                              console.log('============ ERROR ==============');
                              console.log(withdrawalerror);
                              setLoading(false);
                              // setWithdrawalResult('ERROR');
                              swalError({
                                message:
                                  'Sorry, there was a problem processing your withdrawal',
                                textButton: 'Close',
                              });
                            });
                        })
                        .catch((sigerror) => {
                          // Handle any errors that occur during the function call
                          console.log(sigerror);
                          setLoading(false);
                        });
                    })
                    .catch((purserError) => {
                      console.log(purserError);
                      setLoading(false);
                      swalError({
                        message:
                          'Sorry, there was a problem processing your withdrawal',
                        textButton: 'Close',
                      });
                    });
                })
                .catch(function (feeerror) {
                  console.log('================= ERROR ===================');
                  console.log(feeerror);
                  setLoading(false);
                  swalError({
                    message:
                      'Sorry, there was a problem getting network fees for this transaction',
                    textButton: 'Close',
                  });
                });
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
      //  if (amt2Claim > 0 && amt2Claim <= parseFloat(available.replace(/,/g, '')))
    } else {
      console.log('Invalid withdrawal value');
    }
  }, [balance, valueWithdraw, user]);
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleDeposit = useCallback(async () => {
    // const amt2Claim = parseFloat(inputValue.replace(/,/g, ''));
    const amt2Claim = parseFloat(valueDeposit);
    if (
      amt2Claim > 0 &&
      amt2Claim <= parseFloat(available.replace(/[^0-9.]/g, ''))
    ) {
      if (user) {
        networkSwitch('NETWORK')
          .then((response) => {
            const amtFloat = parseFloat(valueDeposit);
            // console.log('amtFloat: %s', amtFloat);
            if (amtFloat > 1) {
              setProcessText('Preparing deposit transaction');
              setLoading(true);
              // setRunWithdrawal(true);
              // GET FEE ESTIMATE
              api
                .get('/v1/trader/fee/estimate')
                .then(function (feeresponse) {
                  // console.log(feeresponse.data);
                  // const fee = feeresponse.data.estimate.fee;
                  const { fee } = feeresponse.data.estimate;
                  // processOrder(response.data.estimate.fee);
                  // const purser = process.env.REACT_APP_BSC_PURSER;
                  getConfigAddress('contract_ptb_v2')
                    .then((purserresult) => {
                      const purser = purserresult?.toString();
                      // console.log('purser: %s', purser);
                      // console.log('verifyingContract: %s', purser);
                      const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
                      // console.log('deadline: %s', deadline);
                      const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
                      const chainInt = parseInt(chainNo || '0', 16);
                      // console.log(chainInt);
                      // const purserold = process.env.REACT_APP_BSC_PURSER;
                      // console.log(purserold, typeof purserold);
                      const domain = {
                        name: 'Ptb',
                        version: '2',
                        chainId: chainInt,
                        verifyingContract: purser,
                      };
                      // console.log('++++++++++++++++++++++++++++++++++++++++++');
                      // console.log(domain);
                      // console.log('++++++++++++++++++++++++++++++++++++++++++');
                      const types = {
                        Request: [
                          { name: 'nftId', type: 'uint256' },
                          { name: 'userAddress', type: 'address' },
                          { name: 'message', type: 'string' },
                          { name: 'deadline', type: 'uint256' },
                          { name: 'transaction_fee', type: 'uint256' },
                        ],
                      };
                      const transaction_fee = BigNumber.from(fee);
                      const transaction_fee_in_ether = parseFloat(
                        ethers.utils.formatEther(transaction_fee)
                      );
                      const message = `I authorize this deposit of $${amtFloat.toFixed(
                        2
                      )}, less a transaction fee of $${transaction_fee_in_ether.toFixed(
                        2
                      )}`;
                      // console.log(message);
                      const request: IWithdrawalRequest = {
                        nftId: parseInt(user.id, 10),
                        userAddress: account,
                        message,
                        deadline,
                        transaction_fee: BigNumber.from(fee),
                      };
                      const tprovider = new ethers.providers.Web3Provider(
                        window.ethereum
                      );
                      // console.log('+++++ Wei Value +++++ ');
                      // console.log((amtFloat * 1e18).toString());
                      // console.log(
                      //   ethers.utils.parseEther(amtFloat.toString()).toString()
                      // );
                      // console.log('+++++ Wei Value +++++ ');
                      setProcessText('Waiting for signature from wallet.');
                      const tsigner = tprovider.getSigner();
                      tsigner
                        ._signTypedData(domain, types, request)
                        .then((sig) => {
                          // Handle the result (sig) here
                          // console.log('signature: %s', sig);
                          setProcessText(
                            'Sending transaction to the blockchain.'
                          );
                          api
                            .post('/v3/trader/deposit', {
                              nftId: user.id,
                              userAddress: account,
                              message,
                              deadline,
                              signature: sig,
                              transaction_fee: fee,
                              amount_requested: ethers.utils
                                .parseEther(amtFloat.toString())
                                .toString(),
                            })
                            .then(function (withdrawalresponse) {
                              // console.log(withdrawalresponse);
                              setLoading(false);
                              setDepositTX(withdrawalresponse.data.tx);
                              fetchTraderDash();
                              fetchBalances();
                              setShowFinishDeposit(true);
                              // setShowFinish(true);
                              // processOrder(response.data.estimate.fee);
                              // setWithdrawalResult(response.data.tx);
                            })
                            .catch(function (withdrawalerror) {
                              console.log('============ ERROR ==============');
                              console.log(withdrawalerror);
                              setLoading(false);
                              // setWithdrawalResult('ERROR');
                              swalError({
                                message:
                                  'Sorry, there was a problem processing your deposit',
                                textButton: 'Close',
                              });
                            });
                        })
                        .catch((sigerror) => {
                          // Handle any errors that occur during the function call
                          console.log(sigerror);
                          setLoading(false);
                        });
                    })
                    .catch((purserError) => {
                      console.log(purserError);
                      setLoading(false);
                      swalError({
                        message:
                          'Sorry, there was a problem processing your deposit',
                        textButton: 'Close',
                      });
                    });
                })
                .catch(function (feeerror) {
                  console.log('================= ERROR ===================');
                  console.log(feeerror);
                  setLoading(false);
                  swalError({
                    message:
                      'Sorry, there was a problem getting network fees for this transaction',
                    textButton: 'Close',
                  });
                });
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
      //  if (amt2Claim > 0 && amt2Claim <= parseFloat(available.replace(/,/g, '')))
    } else {
      console.log('Invalid deposit value');
    }
  }, [available, valueDeposit, user]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleSubmitFundAccount = useCallback(() => {
    // handleDeposit
    if (selectedSource === 'metamask') {
      handleUSDPIFundAccount()
        .then((result) => {
          // Handle success
          fetchTraderDash();
          fetchBalances();
        })
        .catch((error) => {
          // Handle error
          console.log('FAIL');
        });
    } else if (selectedSource === 'member') {
      handleDeposit();
    }
  }, [handleUSDPIFundAccount, selectedSource]);

  const handleSetFixedDepositAmount = useCallback(
    (amount: string): void => {
      setValueDeposit(amount);
    },
    [setValueDeposit, available]
  );

  const handleSetFixedWithdrawalAmount = useCallback(
    (amount: string): void => {
      setValueWithdraw(amount);
      console.log(usdpiBalance);
    },
    [setValueWithdraw, usdpiBalance]
  );

  // EFFECTS
  useEffect(() => {
    // console.log('useEffect');
    if (selectedSource === 'member') {
      setValueDeposit(available);
    } else if (selectedSource === 'metamask') {
      setValueDeposit(usdpiBalance);
    }
  }, [selectedSource, usdpiBalance, available]);

  // DEPOSIT ERROR
  useEffect(() => {
    if (parseFloat(valueDeposit) > 0 && parseFloat(valueDeposit) <= 1) {
      setDepositError('Deposit must be greater than $1.00');
    } else if (
      parseFloat(valueDeposit) >
        parseFloat(available.replace(/[^0-9.]/g, '')) &&
      selectedSource === 'member'
    ) {
      setDepositError('Amount exceeds balance.  Deposit from Metamask');
    } else {
      setDepositError(null);
    }
  }, [valueDeposit, available]);

  // WITHDRAWAL ERROR
  useEffect(() => {
    if (parseFloat(valueWithdraw) > balance) {
      setWithdrawError('Amount exceeds available balance.');
    } else if (
      parseFloat(valueWithdraw) <= 1 &&
      parseFloat(valueWithdraw) > 0
    ) {
      setWithdrawError('Amount must be greater than $1.00');
    } else {
      setWithdrawError(null);
    }
  }, [valueWithdraw, balance]);

  // PAGE LOAD
  useEffect(() => {
    if (user !== undefined) {
      fetchTraderDash();
      fetchBalances();
    }
  }, [user]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-sm-11 col-lg-12 mb-2 mt-4">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-center mb-4 pb-3">
                  <div className="col-lg-6 px-0">
                    <h1 className="h3 h2-lg font-weight-bold mb-lg-0">
                      <span>Smart</span>Trading
                    </h1>
                  </div>
                  <div className="col-lg-6 px-0">
                    <WalletRow />
                  </div>
                </div>
              </div>
            </Welcome>
            <Trade>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-xl-5 col-xxl-4">
                    <h2 className="text-white h3 fw-normal mb-3">
                      Your Wallet
                    </h2>
                    <div className="bg-graph p-4">
                      <div className="d-flex justify-content-between">
                        <img src={usdpi} alt="Usdpi" />
                        <button
                          type="button"
                          className="h6 mb-0 py-2 px-3 rounded-pill d-flex align-items-center"
                          onClick={() => handleDepositClick()}
                        >
                          <BsPlus size={24} color="#0D0D0D" />
                          {/* Deposit / Withdraw */}
                          {accountStatus === 'AGREEMENT'
                            ? 'Register for Trading'
                            : 'Deposit / Withdraw'}
                        </button>
                      </div>
                      <div className="price mt-4">
                        <h3 className="h6">USDPI</h3>
                        {/* <span>${balance?.toString()}</span> */}
                        <span>
                          ${balance !== null ? formatPrice(balance) : 'N/A'}
                        </span>
                      </div>
                      <div className="mt-5 pt-5">
                        <h4 className="mb-0">
                          {rollups?.total_roi_all_time !== null &&
                          rollups?.total_roi_all_time !== undefined
                            ? `${rollups.total_roi_all_time.toFixed(2)}%`
                            : 'N/A'}
                          <span>All time</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4 col-xxl-6 top">
                    <h3 className="mt-3 mb-3">Today's Top Trades</h3>
                    <div className="top-trades p-4">
                      <h4 className="mb-3">🔥Trending</h4>
                      {trending.map((trade, index) => (
                        <div
                          key={trade.id}
                          className="d-flex align-items-center mb-3 p-3 bg-coins"
                        >
                          <span className=" text-white h6 mb-0 fw-normal">
                            {index + 1}
                            {/* <img src={usdt} alt="Coin" className="mx-2" />{' '} */}
                            <img src={trade.img} alt="Coin" className="mx-2" />{' '}
                            {trade.name}
                          </span>
                          <span
                            className={`${
                              trade.percentage < 0 ? 'negative' : 'positive'
                            } h6 fw-normal mb-0 ms-auto`}
                          >
                            {trade.percentage}%
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-xl-3 col-xxl-2 times">
                    <div className="d-flex flex-column flex-lg-row flex-xl-column  justify-content-between pt-4 h-100 pb-3">
                      <div>
                        <h4>All time profit</h4>
                        <p className="d-flex align-items-center h6 fw-normal positive">
                          <div className="arrow-positive me-2" />
                          {rollups?.total_roi_all_time !== null &&
                          rollups?.total_roi_all_time !== undefined
                            ? `${rollups.total_roi_all_time.toFixed(2)}%`
                            : 'N/A'}
                          {' (+$'}
                          {formatToTwoDecimalPlaces(
                            rollups?.total_roi_amt_all_time
                          )}
                          )
                        </p>
                      </div>
                      <div>
                        <h4>Today’s profit</h4>
                        <p className="d-flex align-items-center h6 fw-normal positive">
                          <div className="arrow-positive me-2" />
                          {rollups?.total_roi_today !== null &&
                          rollups?.total_roi_today !== undefined
                            ? `${rollups.total_roi_today.toFixed(2)}%`
                            : 'N/A'}
                          {' (+$'}
                          {formatToTwoDecimalPlaces(
                            rollups?.total_roi_amt_today
                          )}
                          )
                        </p>
                      </div>
                      <div>
                        <h4>This week’s profit</h4>
                        <p className="d-flex align-items-center h6 fw-normal positive">
                          <div className="arrow-positive me-2" />
                          {rollups?.total_roi_wtd !== null &&
                          rollups?.total_roi_wtd !== undefined
                            ? `${rollups.total_roi_wtd.toFixed(2)}%`
                            : 'N/A'}
                          {' (+$'}
                          {formatToTwoDecimalPlaces(rollups?.total_roi_amt_wtd)}
                          )
                        </p>
                      </div>
                      <div>
                        <h4>This month’s profit</h4>
                        {/* <p className="d-flex align-items-center h6 fw-normal negative"> */}
                        <p className="d-flex align-items-center h6 fw-normal positive">
                          <div className="arrow-positive me-2" />
                          {rollups?.total_roi_mtd !== null &&
                          rollups?.total_roi_mtd !== undefined
                            ? `${rollups.total_roi_mtd.toFixed(2)}%`
                            : 'N/A'}
                          {' (+$'}
                          {formatToTwoDecimalPlaces(rollups?.total_roi_amt_mtd)}
                          )
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center mb-4 mt-4">
                      <h2 className="text-white h3 fw-normal mb-4 mt-4">
                        Recent Trades
                      </h2>
                      <span className="status d-flex align-items-center">
                        Account Status:
                        <span className="ms-3 d-flex bg-status h6 mb-0 fw-normal fst-normal text-white align-items-center">
                          <span
                            className={`${
                              accountStatus === 'recalibrating'
                                ? 'bg-black'
                                : ''
                            } pe-none`}
                          >
                            <Lottie
                              options={{
                                animationData:
                                  (accountStatus === 'AGREEMENT' &&
                                    agreement) ||
                                  (accountStatus === 'UNFUNDED' && agreement) ||
                                  (accountStatus === 'BILLING' && agreement) ||
                                  (accountStatus === 'UNDERFUNDED' &&
                                    agreement) ||
                                  (accountStatus === 'ACTIVE' && active) ||
                                  (accountStatus === 'RECALIBRATING' &&
                                    recalibrating),

                                autoplay: true,
                                loop: true,
                                rendererSettings: {
                                  preserveAspectRatio: 'xMidYMid slice',
                                },
                              }}
                              height={44}
                              width={44}
                            />{' '}
                          </span>
                          <span className="d-block ms-2">
                            {accountStatus === 'AGREEMENT' &&
                              'Pending Agreement'}
                            {accountStatus === 'UNFUNDED' && 'Deposit Needed'}
                            {accountStatus === 'BILLING' && 'Subscription Due'}
                            {accountStatus === 'UNDERFUNDED' &&
                              '$100 Minimum to Trade'}
                            {accountStatus === 'ACTIVE' && 'Trading Active'}
                            {accountStatus === 'RECALIBRATING' &&
                              'Recalibrating'}
                          </span>
                        </span>
                      </span>
                    </div>
                    <Table
                      data={tradeData}
                      columns={tradeColumns}
                      pagination
                      className="table h-auto"
                    />
                  </div>
                </div>
              </div>
            </Trade>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose} className="modal-terms">
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          <MdClose color="#e9e9e9" size={20} />
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pt-4">
          <div className="pe-none my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: stakingTerms,
                autoplay: false,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={152}
              width={152}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 py-0 px-lg-5">
          <Form onSubmit={() => console.log('submit')}>
            <div className="texts w-100 mx-auto px-lg-3">
              <div className="btns-actions p-2 mb-3">
                <button
                  type="button"
                  className={isDeposit ? 'active' : ''}
                  onClick={() => setIsDeposit(true)}
                >
                  Deposit
                </button>
                <button
                  type="button"
                  className={!isDeposit ? 'active' : ''}
                  onClick={() => setIsDeposit(false)}
                >
                  Withdraw
                </button>
              </div>
              {isDeposit ? (
                <>
                  <div>
                    <InputMask
                      name="deposit"
                      kind="money"
                      options={{ unit: '$', separator: '.', delimiter: ',' }}
                      placeholder="$0.00"
                      className="input amt-needed-input"
                      value={valueDeposit}
                      onChange={handleDepositChange}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center text-center text-danger small"
                    style={{ height: '50px' }}
                  >
                    {depositError}
                  </div>
                  <div className="d-flex flex-wrap btns-values">
                    <div className="w-50 w-lg-25 px-2">
                      <button
                        type="button"
                        onClick={() => handleSetFixedDepositAmount('100.00')}
                        className={`${
                          fixedAmount === 100 ? 'active' : ''
                        } w-100 mb-3`}
                      >
                        $100
                      </button>
                    </div>
                    <div className="w-50 w-lg-25 px-2">
                      <button
                        type="button"
                        onClick={() => handleSetFixedDepositAmount('500.00')}
                        className={`${
                          fixedAmount === 500 ? 'active' : ''
                        } w-100 mb-3`}
                      >
                        $500
                      </button>
                    </div>
                    <div className="w-50 w-lg-25 px-2">
                      <button
                        type="button"
                        onClick={() => handleSetFixedDepositAmount('1000.00')}
                        className={`${
                          fixedAmount === 1000 ? 'active' : ''
                        } w-100 mb-3`}
                      >
                        $1,000
                      </button>
                    </div>
                    <div className="w-50 w-lg-25 px-2">
                      <button
                        type="button"
                        onClick={() => handleSetFixedDepositAmount('5000.00')}
                        className={`${
                          fixedAmount === 5000 ? 'active' : ''
                        } w-100 mb-3`}
                      >
                        $5,000
                      </button>
                    </div>
                  </div>
                  <div className="d-flex terms justify-content-between ps-5 mb-2">
                    <div className="d-flex justify-content-between align-items-center w-40 me-4">
                      <span>Need to buy USDPI?</span>
                    </div>
                    <button
                      type="button"
                      onClick={() => setShowNeed(true)}
                      className="buy border-0"
                    >
                      Buy USDPI With Your Debit Card Here
                    </button>
                  </div>
                  <div
                    className={`d-flex toggle justify-content-between py-2 px-5 mb-2 ${
                      selectedSource === 'member' ? 'highlight' : ''
                    }`}
                    onClick={() => handleSelectDepSrc('member')}
                    onKeyDown={(e) => handleKeyPressDepSrc(e, 'member')}
                    role="button"
                    tabIndex={0}
                    style={{ cursor: 'pointer' }}
                  >
                    <span>Available USDPI In Your Member Wallet:</span>
                    <span className="color-span">{available} USDPI</span>
                  </div>
                  <div
                    className={`d-flex toggle justify-content-between py-2 px-5 mb-2 ${
                      selectedSource === 'metamask' ? 'highlight' : ''
                    }`}
                    onClick={() => handleSelectDepSrc('metamask')}
                    onKeyDown={(e) => handleKeyPressDepSrc(e, 'metamask')}
                    role="button"
                    tabIndex={0}
                    style={{ cursor: 'pointer' }}
                  >
                    <span>USDPI In Your Metamask Wallet:</span>
                    <span className="color-span">{usdpiBalance} USDPI</span>
                  </div>
                  <button
                    type="button"
                    className="btn-confirm w-100 my-3"
                    onClick={handleSubmitFundAccount}
                  >
                    {selectedSource === 'member'
                      ? 'Deposit from Member Account'
                      : 'Deposit from Wallet'}{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#fff"
                      size={28}
                    />
                  </button>
                </>
              ) : (
                <>
                  <div>
                    <InputMask
                      name="withdraw"
                      kind="money"
                      options={{ unit: '$', separator: '.', delimiter: ',' }}
                      placeholder="$0.00"
                      className="input amt-needed-input"
                      value={valueWithdraw}
                      onChange={handleWithdrawChange}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center text-center text-danger small"
                    style={{ height: '50px' }}
                  >
                    {withdrawError}
                  </div>
                  <div className="d-flex terms justify-content-between py-2 px-5 mb-2 mt-5">
                    <span>
                      Funds Available Be Withdrawn To Your Member Wallet:
                    </span>
                    {/* ================================= */}
                    <span className="color-span">
                      {formatToTwoDecimalPlaces(balance)} USDPI
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn-confirm w-100 my-4"
                    onClick={handleWithdraw}
                  >
                    Confirm Withdrawal{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#fff"
                      size={28}
                    />
                  </button>
                </>
              )}
              <button
                type="button"
                onClick={handleClose}
                className="btn-done w-100 mb-0"
              >
                <span className="">DONE</span>
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>

      <Terms
        show={showTerms}
        onClose={() => setShowTerms(false)}
        onRegister={handleRegister}
      />

      <ModalFinishRegister
        size="xl"
        show={modalCongratulations}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-5 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={walletFinish} alt="Wallet" />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mt-n4 mb-5 fw-bold text-center w-100">
            Congratulations!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4">Your Trading Account is Ready!</p>
            <p className="text-center mb-4 px-4">
              Please proceed to deposit funds to start trading.
            </p>

            <button
              type="button"
              onClick={handleDepositClick}
              // onClick={() =>
              //   history.push(`${process.env.PUBLIC_URL}/dashboard`)
              // }
              className="btn-confirm w-100 mt-5 mb-4"
            >
              <span className="">Deposit Now</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </ModalFinishRegister>

      <ModalFinishWallet
        size="xl"
        show={showFinishWallet}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <ModalFinishWallet.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: earningsDone,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={220}
              width={300}
            />
          </div>
        </ModalFinishWallet.Header>
        <ModalFinishWallet.Body className="px-4 px-sm-5">
          <h2 className="mb-4 fw-bold text-center w-100">
            Wallet Successfully Funded!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4 px-xl-5">
              Your account wallet was successfully funded. If you have a balance
              due for your subscription, the amount funded will be applied
              towards it.
            </p>
            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${
                  depositTXChain === 'NETWORK' ? explorerURL : explorerURLEth
                }/tx/${depositTX}`}
                // href={`${explorerURL}/tx/${depositTX}`}
                target="_blank"
                rel="noreferrer"
              >
                {depositTX}
              </a>
            </div>
            <p className="mb-4">
              * Unpaid subscription invoices are automatically paid once your
              account has enough funds from commissions and deposits.
            </p>
          </div>
        </ModalFinishWallet.Body>
        <ModalFinishWallet.Footer className="border-0 py-4" />
      </ModalFinishWallet>

      <ModalFinishWithdraw
        size="xl"
        show={showFinishWithdrawal}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-5 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={walletFinish} alt="Wallet" />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mt-n4 mb-5 fw-bold text-center w-100">
            Congratulations!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4">Your Withdrawal is Complete!</p>
            <p className="text-center mb-4 px-4">
              Funds have been credited to your member balance.
            </p>
            <p className="text-center mb-4 px-4">
              * Unpaid subscription invoices are automatically paid once your
              account has enough funds from commissions and deposits.
            </p>

            {/* <button
              type="button"
              onClick={handleDepositClick}
              // onClick={() =>
              //   history.push(`${process.env.PUBLIC_URL}/dashboard`)
              // }
              className="btn-confirm w-100 mt-5 mb-4"
            >
              <span className="">Deposit Now</span>
            </button> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </ModalFinishWithdraw>

      <ModalFinishDeposit
        size="xl"
        show={showFinishDeposit}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-5 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={walletFinish} alt="Wallet" />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mt-n4 mb-5 fw-bold text-center w-100">
            Congratulations!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4">
              Congratulations! Your Deposit Is Confirmed!
            </p>
            <p className="text-center mb-4 px-4">
              Your account is now one step closer to trading. Please note that
              it may take a little time to calibrate your SmartTrading settings
              and ensure everything is optimized for the next trading rotation.
            </p>
            <p className="text-center mb-4 px-4">
              We appreciate your patience—rest assured, you're on the right path
              to potential growth. Keep an eye on your dashboard for updates,
              and welcome to the next phase of your financial journey!
            </p>

            {/* <button
              type="button"
              onClick={handleDepositClick}
              // onClick={() =>
              //   history.push(`${process.env.PUBLIC_URL}/dashboard`)
              // }
              className="btn-confirm w-100 mt-5 mb-4"
            >
              <span className="">Deposit Now</span>
            </button> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </ModalFinishDeposit>

      {/* Buy USDPI modal */}
      <ModalNeed
        size="lg"
        show={showNeed}
        onHide={handleClose}
        className="modal-signin-auto"
        centered
      >
        <button
          type="button"
          className="h4 modal-close m-2 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden pe-none">
            <Lottie
              options={{
                animationData: stakingTerms,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={157}
              width={157}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mb-2 fw-bold text-center w-100">Need Funds?</h2>
          <div className="texts w-100 px-3">
            <p className="text-center mb-4">
              We could not find USPI in your wallet
            </p>
            <div className="d-flex btns-coin justify-content-between">
              {/* <button
                type="button"
                className={`${selectCoin === 'usdpi' && 'active'}`}
                onClick={() => setSelectCoin('usdpi')}
              >
                <img src={currencyUSDPI} alt="USDPI" /> USDPI
              </button> */}
              {/* <button
                type="button"
                className={`${selectCoin === 'usdc' && 'active'}`}
                onClick={() => setSelectCoin('usdc')}
              >
                <img src={currencyUSDC} alt="USDC" /> USDC
              </button>
              <button
                type="button"
                className={`${selectCoin === 'eth' && 'active'}`}
                onClick={() => setSelectCoin('eth')}
              >
                <img src={currencyETH} alt="ETH" /> ETH
              </button>
              <button
                type="button"
                className={`${selectCoin === 'bnb' && 'active'}`}
                onClick={() => setSelectCoin('bnb')}
              >
                <img src={currencyBNB} alt="BNB" /> BNB
              </button> */}
            </div>
            <p className="my-4">
              <span>Buy at least</span> 0.235 {selectCoin.toUpperCase()}
            </p>
          </div>
          <div className="w-lg-90 mx-auto">
            <p className="text-worry text-center mb-4">
              Don’t worry, you can buy it instantly at the{' '}
              <span className="fw-semibold">options&nbsp;below:</span>
            </p>
            {selectCoin === 'usdpi' && (
              <div>
                <button
                  type="button"
                  className="btn-c14 w-100 my-1"
                  onClick={handleBuyNowButtonClick}
                >
                  BUY USDPI NOW AT C14{' '}
                  <HiArrowNarrowRight className="ms-3" color="#000" size={28} />
                </button>
              </div>
            )}
            {selectCoin === 'usdc' && (
              <div>
                <button type="button" className="btn-c14 w-100 my-1">
                  BUY USDC NOW AT C14{' '}
                  <HiArrowNarrowRight className="ms-3" color="#000" size={28} />
                </button>
                <button type="button" className="btn-paybis w-100 my-1">
                  BUY USDC NOW AT PAYBIS{' '}
                  <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
                </button>
                <button type="button" className="btn-xcoins w-100 my-1">
                  BUY USDC NOW AT XCOINS{' '}
                  <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
                </button>
                <button type="button" className="btn-uphold w-100 my-1">
                  BUY USDC NOW AT UPHOLD{' '}
                  <HiArrowNarrowRight className="ms-3" color="#000" size={28} />
                </button>
              </div>
            )}
            {selectCoin === 'eth' && (
              <div>
                <button
                  type="button"
                  className="btn-uphold w-100 my-1"
                  onClick={handleBuyNowUpholdButtonClick}
                >
                  BUY ETH NOW AT UPHOLD{' '}
                  <HiArrowNarrowRight className="ms-3" color="#000" size={28} />
                </button>
                <button type="button" className="btn-xcoins w-100 my-1">
                  BUY ETH NOW AT COINBASE{' '}
                  <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
                </button>
              </div>
            )}
            {selectCoin === 'bnb' && (
              <div>
                <button type="button" className="btn-c14 w-100 my-1">
                  BUY BNB NOW AT C14{' '}
                  <HiArrowNarrowRight className="ms-3" color="#000" size={28} />
                </button>

                <button type="button" className="btn-xcoins w-100 my-1">
                  BUY BNB NOW AT COINBASE{' '}
                  <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
                </button>
              </div>
            )}
            <button
              type="button"
              onClick={handleClose}
              className="btn-cancel w-100 my-1"
            >
              <span className="">Cancel</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </ModalNeed>
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="PLEASE WAIT..."
        active={loading}
      />
    </Container>
  );
};

export default SmartTrade;
