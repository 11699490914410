import { darken } from 'polished';
import styled from 'styled-components';

export const Banner = styled.div`
  background-color: #18191a;
  background-position: center;
  background-size: cover;
  min-height: 100vh;

  h1 {
    background: linear-gradient(269.95deg, #fff 0.02%, #fff 97.45%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;

    > span {
      background: linear-gradient(
        239.23deg,
        #9df3ff 1.84%,
        #bcffe5 2.62%,
        #deefd1 7.94%,
        #ffe0be 22.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      );
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
    }
  }

  p {
    color: #f4f4f4;
    font-weight: 400 !important;
  }

  .container {
    max-width: 1920px;
  }

  .btn-arrow {
    background: rgba(152, 148, 148, 0.1);
    box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
    backdrop-filter: blur(15px);
    border-radius: 19px;

    border: none;
    rotate: 90deg;
    width: 60px;
    height: 60px;
  }
  .btn-claim button {
    background-color: #00f9ab;
    color: #000;
    border-radius: 20px;
    font-size: 24px;
    padding: 16px;
    transition-duration: 0.3s;

    :hover {
      background-color: ${darken(0.1, '#00f9ab')};
    }
  }

  .logo {
    right: 2rem;
    top: 2rem;
  }

  .text-gray {
    color: #b7b7b7;
  }

  .timeline-box {
    > div {
      height: 100%;
    }
  }

  .back-retangle-right {
    position: absolute;
    right: -27px;
    top: 20%;
    height: 18vw;
    max-height: 400px;
  }

  .back-retangle-left {
    position: absolute;
    left: -27px;
    top: 20%;
    height: 18vw;
    max-height: 400px;
  }

  @media screen and (max-width: 1799px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    button {
      font-size: 20px;
    }
    .back-retangle-right {
      height: 25vw;
      right: -1vw;
    }
    .back-retangle-left {
      height: 25vw;
      left: -1vw;
    }
  }

  @media screen and (min-width: 2160px) {
    .max-width {
      max-width: 1800px;
      margin: 0 auto;
    }
  }
`;

export const Video = styled.video`
  background-color: #000 !important;
  position: fixed;
  min-width: 100%;
  left: 0%;
  z-index: 1;
  height: 100vh;
  //top: 50%;
  //left: 50%;
  //min-height: 100%;
  //width: auto;

  //transform: translateX(-50%) translateY(-50%);
  transition: top 1s ease-in-out;
`;
