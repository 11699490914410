import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  ethers,
  utils,
  ContractReceipt,
  ContractTransaction,
  BigNumber,
} from 'ethers';
import { Provider } from 'ethers-multicall';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { AbiItem } from 'web3-utils';
import { string } from 'yup';
import Cookies from 'universal-cookie';
import { web3store } from '../../store';
import { useAuth } from '~/hooks/Auth';
// import { abi as usdpiAbi } from '../../abi/usdpi.json';
import { abibusd as busdAbi } from '../../abi/busd.json';
import { abibank as bankAbi } from '../../abi/bank.json';
import { abi as EthUsdtSwapV3Abi } from '~/abi/EthUsdtSwapV3.json';
import { abi as erc20Abi } from '~/abi/erc20.json';

/* eslint no-underscore-dangle: 0 */
const Web3Stats: React.FC = () => {
  const [funnelType, setFunnelType] = web3store.useState('funnelType');

  const [reference, setReference] = web3store.useState('reference');
  const [placementRef, setPlacementRef] = web3store.useState('placementRef');
  const [username, setUsername] = web3store.useState('username');

  const [account, setAccount] = web3store.useState('account');
  const [provider, setProvider] = web3store.useState('provider');
  const [signer, setSigner] = web3store.useState('signer');
  // ---------------------
  // Required Blockchain
  const [reqChainHex, setReqChainHex] = web3store.useState('reqChainHex');
  // const [reqChainInt, setReqChainInt] = web3store.useState('reqChainInt');
  const [chainMode, setChainMode] = web3store.useState('chainMode');
  const [currentChain, setCurrentChain] = web3store.useState('currentChain');
  const [skipModal, setSkipModal] = web3store.useState('skipModal');
  // Required Blockchain
  // ---------------------
  const [balusdpi, setBalusdpi] = web3store.useState('balusdpi');
  const [balbusd, setBalbusd] = web3store.useState('balbusd');
  const [balprfti, setBalprfti] = web3store.useState('balprfti');
  const [balbnb, setBalbnb] = web3store.useState('balbnb');
  const [baleth, setBaleth] = web3store.useState('baleth');
  const [balusdt, setBalusdt] = web3store.useState('balusdt');

  const [contractusdpi, setContractusdpi] = web3store.useState('contractusdpi');
  const [authBusd2Bank, setAuthBusd2Bank] = web3store.useState('authBusd2Bank'); // Authorized BUSD to USDPI Bank
  const [authUsdpi2Subs, setAuthUsdpi2Subs] =
    web3store.useState('authUsdpi2Subs'); // Authorized BUSD to USDPI Bank
  const [nftlist, setNftlist] = web3store.useState('nftlist');
  const [refW3, setRefW3] = web3store.useState('refW3');
  const [refContractDef, setRefContractDef] =
    web3store.useState('refContractDef');
  const [refNftList, setRefNftList] = web3store.useState('refNftList');
  const [memberStatus, setMemberStatus] = web3store.useState('memberStatus');
  const [refBalances, setRefBalances] = web3store.useState('refBalances');
  const [imgCommand, setImgCommand] = web3store.useState('imgCommand');
  const [imgURL, setImgURL] = web3store.useState('imgURL');
  const [imgSeed, setImgSeed] = web3store.useState('imgSeed');
  const [cartProfitCenters, setCartProfitCenters] =
    web3store.useState('cartProfitCenters');
  const [cartPrepayMonths, setCartPrepayMonths] =
    web3store.useState('cartPrepayMonths');
  const [cartPurchase, setCartPurchase] = web3store.useState('cartPurchase');
  const [cartPurchaseStake, setCartPurchaseStake] =
    web3store.useState('cartPurchaseStake');
  const [cartHashes, setCartHashes] = web3store.useState('cartHashes');
  const [cartAmt, setCartAmt] = web3store.useState('cartAmt');
  const [buyStakingAmt, setBuyStakingAmt] = web3store.useState('buyStakingAmt');
  const [buyStakingHashes, setBuyStakingHashes] =
    web3store.useState('buyStakingHashes');
  const [cartPurchaseStakeTX, setCartPurchaseStakeTX] = web3store.useState(
    'cartPurchaseStakeTX'
  );
  const [cartPurchaseTX, setCartPurchaseTX] =
    web3store.useState('cartPurchaseTX');
  //  ===== TOKEN SWAP =====
  const [usdpiPurchaseAmt, setUsdpiPurchaseAmt] =
    web3store.useState('usdpiPurchaseAmt');
  const [usdpiPurchaseAmtEth, setUsdpiPurchaseAmtEth] = web3store.useState(
    'usdpiPurchaseAmtEth'
  );
  const [usdpiPurchaseAmtUsdt, setUsdpiPurchaseAmtUsdt] = web3store.useState(
    'usdpiPurchaseAmtUsdt'
  );
  const [usdpiSaleAmt, setUsdpiSaleAmt] = web3store.useState('usdpiSaleAmt');
  const [usdpiPurchaseFee, setUsdpiPurchaseFee] =
    web3store.useState('usdpiPurchaseFee');
  const [usdpiPurchaseTX, setUsdpiPurchaseTX] =
    web3store.useState('usdpiPurchaseTX');
  const [usdpiPurchaseToken, setUsdpiPurchaseToken] =
    web3store.useState('usdpiPurchaseToken');
  //  ===== TOKEN SWAP =====
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');
  const [invalidRef, setInvalidRef] = web3store.useState('invalidRef');
  const [wrongChain, setWrongChain] = web3store.useState('wrongChain');
  const [activating, setActivating] = web3store.useState('activating');
  const [mintOnly, setMintOnly] = web3store.useState('mintOnly');
  const [lastPageServed, setLastPageServed] =
    web3store.useState('lastPageServed');
  const [processText, setProcessText] = web3store.useState('processText');
  const history = useHistory();
  const cookies = new Cookies();
  const { setStatus, setConnected } = useAuth();
  const url = process.env.REACT_APP_API as string;
  // console.log('url: %s', url);
  const [harvestStake, setHarvestStake] = web3store.useState('harvestStake');
  // Withdrawal
  const [runWithdrawal, setRunWithdrawal] = web3store.useState('runWithdrawal');
  const [withdrawalResult, setWithdrawalResult] =
    web3store.useState('withdrawalResult');
  // Claim Revenue Share USDPI
  const [runClaimPoolUsdpi, setRunClaimPoolUsdpi] =
    web3store.useState('runClaimPoolUsdpi');
  const [withdrawalPoolUsdpiResult, setWithdrawalPoolUsdpiResult] =
    web3store.useState('withdrawalPoolUsdpiResult');
  // Claim Performance Share USDPI
  const [runClaimPerfPoolUsdpi, setRunClaimPerfPoolUsdpi] = web3store.useState(
    'runClaimPerfPoolUsdpi'
  );
  const [withdrawalPerfPoolUsdpiResult, setWithdrawalPerfPoolUsdpiResult] =
    web3store.useState('withdrawalPerfPoolUsdpiResult');
  // Claim Performance Share USDPI
  const [runClaimDripPrfti, setRunClaimDripPrfti] =
    web3store.useState('runClaimDripPrfti');
  const [withdrawalDripResult, setWithdrawalDripResult] = web3store.useState(
    'withdrawalDripResult'
  );
  // Claim Performance Share USDPI
  const [runClaimStakingPrfti, setRunClaimStakingPrfti] = web3store.useState(
    'runClaimStakingPrfti'
  );
  const [withdrawalStakingResult, setWithdrawalStakingResult] =
    web3store.useState('withdrawalStakingResult');
  // Add tokens
  const [walletAddPRFTI, setWalletAddPRFTI] =
    web3store.useState('walletAddPRFTI');
  const [walletAddUSDPI, setWalletAddUSDPI] =
    web3store.useState('walletAddUSDPI');
  // AI Image generator
  const [aiigSeed, setAiigSeed] = web3store.useState('aiigSeed');
  const [aiigPrompt, setAiigPrompt] = web3store.useState('aiigPrompt');
  const [aiigResult, setAiigResult] = web3store.useState('aiigResult');
  const [aiigCount, setAiigCount] = web3store.useState('aiigCount');
  const [aiigCreate, setAiigCreate] = web3store.useState('aiigCreate');
  // Balance polling
  const [balanceInterval, setBalanceInterval] =
    web3store.useState('balanceInterval');
  const [balanceId, setBalanceId] = web3store.useState('balanceId');

  const currentHostname = window.location.hostname;
  // console.log('****** currentHostname %s ******', currentHostname);
  // const hostnameParts = currentHostname.split('.');
  // console.log(hostnameParts);
  // const prefix = hostnameParts[1]; // Get the second part of the hostname
  // console.log('****** PREFIX %s ******', prefix);
  // ======================================
  // Account changed
  // ======================================
  useEffect(() => {
    // console.log('===== balanceId: %s', balanceId);
    if (account !== '') {
      // console.log('-----------------------------');
      // console.log(' Acct Changed - Get NFT List ');
      // console.log('-----------------------------');
      axios
        .get(url.concat('/v1/nft/list'), {
          params: {
            registered: 2,
            owner: account,
          },
        })
        .then(function (response) {
          // console.log(response);
          setNftlist(response.data.nft_list);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
        });
      setRefBalances(true);
      setBalanceInterval(300000);
    } else {
      setBalanceInterval(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  // Balance Polling
  useEffect(() => {
    if (!refBalances) {
      if (balanceId !== null) {
        // console.log('===== STOP POLLING =====');
        window.clearInterval(balanceId);
        setBalanceId(null);
      }
      if (balanceInterval > 0) {
        // console.log('===== START POLLING =====');
        const newIntervalId = window.setInterval(() => {
          // console.log('Poll time: %s', refBalances);
          if (!refBalances) {
            setRefBalances(true);
          }
        }, balanceInterval);
        setBalanceId(newIntervalId);
      }
    }
  }, [balanceInterval, refBalances]);

  // ======================================
  // Refresh NFT List
  // ======================================
  useEffect(() => {
    if (account !== '' && refNftList) {
      console.log('-----------------------');
      console.log('       Get NFT List       ');
      console.log('-----------------------');
      // const url = process.env.REACT_APP_API as string;
      axios
        .get(url.concat('/v1/nft/list'), {
          params: {
            registered: 2,
            owner: account,
          },
        })
        .then(function (response) {
          console.log(response);
          setNftlist(response.data.nft_list);
          setRefNftList(false);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNftList]);

  // ======================================
  // NFT LIST UPDATED
  // Set Member & Route User
  // ======================================
  useEffect(() => {
    // console.log('======================================');
    // console.log('NFT LIST UPDATED');
    // console.log('======================================');
    if (account !== '') {
      // const url = process.env.REACT_APP_API as string;
      // console.log(nftlist);
      let refCode;
      let placeCode;
      let aaName;
      const saveReferral = async (ref: any, place: any, usernm: any) => {
        console.log('*********** saveReferral ***********');
        console.log('ref: %s', ref);
        console.log('place: %s', place);
        console.log('usernm: %s', usernm);
        console.log('*********** saveReferral ***********');
        axios
          .post(url.concat('/v1/ref/assign'), {
            ref,
            account,
            place,
            usernm,
          })
          .then(function (acctresponse) {
            // console.log(acctresponse.data);
            if (acctresponse.data.success) {
              // console.log('**************************');
              // console.log('REFERRAL FOUND OR ASSIGNED');
              // console.log('**************************');
              // console.log(acctresponse.data.ref_code);
              if (acctresponse.data.ref_code !== null) {
                // console.log('saving new ref code: %s', acctresponse.data);
                cookies.set('reference', '', { expires: new Date(0) });
                cookies.set('reference', acctresponse.data.ref_code);
                setReference(acctresponse.data.ref_code);
              } else {
                // console.log('ref %s was accepted by database', ref);
                cookies.set('reference', ref);
                // console.log('ref: %s', ref);
                setReference(ref);
                // console.log(cookies.get('reference'));
              }
              setInvalidRef(false);
              setMemberStatus('entry');
              setConnected(true);
              history.push('/get-your-free-nft');
            } else {
              setInvalidRef(true);
            }
          })
          .catch(function (error) {
            setInvalidRef(true);
            console.log('================= ERROR ===================');
            console.log(error);
          });
      };
      if (nftlist.length === 0) {
        console.log('New user - no NFT - Check referral code');
        // console.log('***** NOOBIE ***** %s', reference);
        // console.log('***** NOOBIE ***** %s', placementRef);
        // console.log('***** NOOBIE ***** %s', username);

        // setMemberStatus('entry');
        refCode = reference;
        placeCode = placementRef;
        aaName = username;
        if (refCode === undefined) {
          console.log('No url ref code');
          // no referral code from url
          refCode = cookies.get('reference');
          placeCode = cookies.get('placementRef');
          aaName = cookies.get('username');
        } else {
          console.log('Url ref code found: %s', refCode);
          // console.log('Url placement code found: %s', placeCode);
          // console.log('Url username found: %s', aaName);
          cookies.set('reference', refCode, { path: '/' });
          cookies.set('placementRef', placeCode, { path: '/' });
          cookies.set('username', aaName, { path: '/' });
          saveReferral(refCode, placeCode, aaName);
        }
        if (refCode === undefined) {
          console.log('No url or cookie ref code...checking database');
          // no referral code from url or cookie
          axios
            .get(url.concat('/v1/ref/lookup'), {
              params: {
                account,
              },
            })
            .then(function (response) {
              console.log(response);
              console.log(response.data.reference);
              // if (response.data.reference.length === 0) {
              if (response.data.reference === null) {
                console.log('No database record found...sending to stub page');
                history.push('/');
                setInvalidRef(true);
              } else {
                setInvalidRef(false);
                saveReferral(
                  response.data.reference,
                  'x',
                  response.data.aa_username
                );
              }
            })
            .catch(function (error) {
              console.log('================= ERROR ===================');
              console.log(error);
              setInvalidRef(true);
            });
        } else {
          // console.log('found in cookie');
          saveReferral(refCode, placeCode, aaName);
        }
      } else if (nftlist[0].is_active) {
        /*
        User has an activated NFT.  Go to the dashboard
          */
        // console.log('setting memberstatus to member');
        // login(nftlist[0].id);
        setMemberStatus('member');
        setInvalidRef(false);
        setConnectedMember({
          memberId: nftlist[0].id,
          nft: nftlist[0],
        });
      } else {
        /*
        User has an NFT, but it isn't activated.  Go to the activation page.
        */
        console.log('setting memberstatus to minted');
        setInvalidRef(false);
        setMemberStatus('minted');
        setConnectedMember({
          memberId: nftlist[0].id,
          nft: nftlist[0],
        });
      }
      // } else {
      //   console.log('ERROR ERROR');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftlist]);

  // ======================================
  // MEMBER RECORD CHANGED
  // Route user
  // ======================================
  useEffect(() => {
    if (connectedMember !== null) {
      // console.log('connectedMember:');
      // console.log('=============================');
      // console.log('connectedMember');
      // console.log(connectedMember);
      // console.log('=============================');
      if (lastPageServed.length > 0 && lastPageServed !== 'FUNNEL') {
        history.push(lastPageServed);
      } else if (connectedMember.nft.is_active) {
        if (activating) {
          if (mintOnly) {
            history.push('/dashboard');
          } else {
            history.push('/earn-even-more');
          }
        } else {
          history.push('/dashboard');
        }
      } else if (activating) {
        if (mintOnly) {
          history.push('/dashboard');
        } else {
          history.push('/how-to-participate');
        }
      } else {
        history.push('/how-to-participate');
      }

      setConnected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedMember]);

  // ======================================
  // REFRESH BALANCES
  // ======================================
  useEffect(() => {
    if (refBalances) {
      // console.log('account: %s', account);
      if (account !== '') {
        // console.log('-----------------------');
        // console.log('   Get Balances    ');
        // console.log(url.concat('v1/member/tokenBalances/', account));
        // console.log('-----------------------');
        axios
          .get(url.concat('/v1/member/tokenBalances/', account))
          .then(function (response) {
            setBalusdpi(response.data.balances.wallet_usdpi);
            setBalbusd(response.data.balances.wallet_busd);
            setBalprfti(response.data.balances.wallet_prfti);
            setBalbnb(response.data.balances.wallet_bnb);
            setBaleth(response.data.balances.wallet_eth);
            setBalusdt(response.data.balances.wallet_usdt);
            // const [baleth, setBaleth] = web3store.useState('baleth');
            // const [balusdt, setBalusdt] = web3store.useState('balusdt');
            setAuthBusd2Bank(response.data.balances.auth_bnb_bank);
            setAuthUsdpi2Subs(response.data.balances.auth_usdpi_subs);
          })
          .catch(function (error) {
            console.log('================= ERROR ===================');
            console.log(error);
          });
      }
      setRefBalances(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refBalances]);

  // ======================================
  // GENERATE IMAGE
  // ======================================
  useEffect(() => {
    if (imgCommand !== '') {
      console.log('-----------------------');
      console.log('   Generate image    ');
      console.log(imgCommand);
      console.log('-----------------------');
      // API CALL HERE
      interface Params {
        command: string;
        seed?: string; // add a "?" to make the "seed" key optional
      }
      const params: Params = {
        command: imgCommand,
      };
      if (imgSeed !== '') {
        params.seed = imgSeed;
      }
      axios
        .post(url.concat('/v1/image/create'), params)
        .then(function (acctresponse) {
          console.log(acctresponse.data);
          setImgURL(url.concat('/v1/image/get/', acctresponse.data.image));
          setImgSeed(acctresponse.data.seed);
          setImgCommand('');
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
          setImgCommand('');
        });
      // setImgURL(
      //   'https://stablediffusion.fr/assets/cats/000_cat_art_portrait_stable_diffusion_artwork_by_smeagol_0005.webp'
      // ); // Sample image
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgCommand]);

  // ======================================
  // ACTIVATION
  // ======================================
  useEffect(() => {
    if (cartPurchase) {
      setActivating(true);
      console.log('-----------------------');
      console.log('   Activating    ');
      console.log('cartPurchase: %s', cartPurchase);
      console.log('-----------------------');
      // const url = process.env.REACT_APP_API as string;
      const processOrder = async (response: any) => {
        // setProcessText('Waiting for wallet signature...');
        const { deadline, amt2authorize, nonce, name, chain } = response;
        const version = '1';
        const domainData = {
          name,
          version,
          chainId: chain,
          verifyingContract: process.env.REACT_APP_BSC_USDPI,
        };
        const types = {
          Permit: [
            {
              name: 'owner',
              type: 'address',
            },
            {
              name: 'spender',
              type: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
            },
            {
              name: 'nonce',
              type: 'uint256',
            },
            {
              name: 'deadline',
              type: 'uint256',
            },
          ],
        };
        const val = {
          owner: account,
          spender: process.env.REACT_APP_BSC_USDPISUBS,
          // spender: process.env.REACT_APP_BSC_MEMBEROPS,
          value: amt2authorize,
          nonce,
          deadline,
        };
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        console.log('processOrder 7');
        try {
          console.log('cartProfitCenters: %s', cartProfitCenters);
          const sig = await tsigner._signTypedData(domainData, types, val);
          if (cartProfitCenters === 0) {
            setMintOnly(true);
            axios
              .post(url.concat('/v1/member/mint/activate'), {
                owner: account,
                amt: amt2authorize.toString(),
                deadline,
                sig,
              })
              .then(function (acctresponse) {
                console.log(acctresponse.data);
                setRefBalances(true);
                setRefNftList(true);
                setCartAmt(0);
                setCartPurchaseTX(acctresponse.data.tx);
              })
              .catch(function (error) {
                console.log('================= ERROR ===================');
                console.log(error);
                setCartPurchase(false);
                setCartPurchaseTX('ERROR: Activation error');
              });
          } else {
            console.log('processOrder 8');
            let nft2activate;
            if (connectedMember !== null) {
              nft2activate = connectedMember.memberId;
            }
            console.log('processOrder 9');
            axios
              .post(url.concat('/v1/member/activate'), {
                owner: account,
                prepay: cartPrepayMonths,
                pcount: cartProfitCenters,
                amt: amt2authorize.toString(),
                deadline,
                sig,
                nft_id: nft2activate,
                img_name: imgURL.substring(imgURL.lastIndexOf('/') + 1),
              })
              .then(function (acctresponse) {
                console.log(acctresponse.data);
                setRefBalances(true);
                setRefNftList(true);
                console.log('clearing cart amount');
                setCartAmt(0);
                setCartPurchaseTX(acctresponse.data.tx);
              })
              .catch(function (error) {
                console.log('================= ERROR ===================');
                console.log(error);
                setCartPurchase(false);
                setCartPurchaseTX('ERROR: Activation error');
              });
          }
        } catch (error) {
          setCartPurchaseTX('ERROR: Activation error');
        }
        setCartPurchase(false);
      };
      if (cartProfitCenters === 0) {
        console.log('****MINT ONLY****');
        // Get hashes
        axios
          .get(url.concat('/v1/member/mint/activate'), {
            params: {
              wallet_address: account,
            },
          })
          .then(function (acctresponse) {
            console.log(acctresponse.data);
            processOrder(acctresponse.data);
          })
          .catch(function (error) {
            console.log('================= ERROR ===================');
            console.log(error);
            setCartPurchase(false);
            setCartPurchaseTX('ERROR: API Error');
          });
      } else {
        // Get hashes
        console.log('Cart Amount: %s', cartAmt);
        console.log(ethers.utils.parseEther(cartAmt.toString()).toString());
        const amtAuth = ethers.utils.parseEther(cartAmt.toString()).toString();
        axios
          .get(url.concat('/v1/member/activate/'), {
            params: {
              wallet_address: account,
              amt2authorize: amtAuth,
            },
          })
          .then(function (acctresponse) {
            console.log('acctresponse');
            console.log(acctresponse.data);
            processOrder(acctresponse.data);
          })
          .catch(function (error) {
            console.log('================= ERROR ===================');
            console.log(error);
            setCartPurchase(false);
            setCartPurchaseTX('ERROR: API Error');
          });
      }
      // // set member id
      // // change member status
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartPurchase]);

  // ======================================
  // USDPI Purchase - ETH
  // ======================================
  useEffect(() => {
    // console.log('usdpiPurchaseAmtEth detected!!!');
    // console.log(usdpiPurchaseAmtEth);
    if (usdpiPurchaseAmtEth > 0) {
      const authamount = ethers.utils.parseEther(
        usdpiPurchaseAmtEth.toString()
      );
      // console.log('authamount: %s', authamount);
      // console.log(process.env.REACT_APP_ETH_SWAP);
      const tprovider = new ethers.providers.Web3Provider(window.ethereum);
      const tsigner = tprovider.getSigner();
      const swapBank = new ethers.Contract(
        process.env.REACT_APP_ETH_SWAP as string,
        EthUsdtSwapV3Abi,
        tprovider
      );
      const exchange = async () => {
        try {
          setProcessText('Waiting for wallet approval...');
          const tx = await swapBank
            .connect(tsigner)
            .swapEth4Usdt(authamount, 0, {
              value: authamount,
            });
          setProcessText('Exchange complete.  Your balances will update soon');
          // console.log(tx);
          const receipt = await tx.wait();
          // console.log(receipt.transactionHash);
          axios
            .post(url.concat('/v1/usdpi/deposit/usdt/tx'), {
              nft_id: connectedMember ? connectedMember.memberId : null,
              tx: receipt.transactionHash,
            })
            .then(function (acctresponse) {
              setUsdpiPurchaseAmtEth(0);
              setUsdpiPurchaseTX(acctresponse.data.tx);
            })
            .catch(function (error) {
              console.log('================= ERROR ===================');
              console.log(error);
              setUsdpiPurchaseAmtEth(0);
              // setUsdpiSaleAmt('');
              // setUsdpiPurchaseTX('ERROR: Exchange error');
            });
        } catch (error: any) {
          if (error.code === 'CALL_EXCEPTION') {
            console.error('Transaction reverted:', error);
            setUsdpiPurchaseAmtEth(0);
            setUsdpiPurchaseTX('ERROR: Exchange error');
          } else {
            console.error('Error occurred:', error);
            setUsdpiPurchaseAmtEth(0);
            setUsdpiPurchaseTX('ERROR: Exchange error');
          }
        }
      };
      exchange();
    }
  }, [usdpiPurchaseAmtEth]);

  // usdpiPurchaseAmtUsdt
  // ======================================
  // USDPI Purchase - USDT
  // ======================================
  useEffect(() => {
    if (usdpiPurchaseAmtUsdt > 0) {
      console.log('-----------------------');
      console.log(' Purchase USDPI w/USDT ');
      console.log('usdpiPurchaseAmt: %s', usdpiPurchaseAmtUsdt);
      console.log('-----------------------');
      const tprovider = new ethers.providers.Web3Provider(window.ethereum);
      const tsigner = tprovider.getSigner();
      const contractbank = new ethers.Contract(
        process.env.REACT_APP_ETH_SWAP as string,
        EthUsdtSwapV3Abi,
        tprovider
      );
      const usdtInterface = new ethers.utils.Interface(erc20Abi);
      const contractusdt = new ethers.Contract(
        process.env.REACT_APP_BSC_USDT as string,
        usdtInterface,
        tprovider
      );
      const amountInToken = usdpiPurchaseAmtUsdt * 10 ** 6; // Convert the amount to the token's base unit (with 6 decimal places)
      const authamount = ethers.BigNumber.from(amountInToken); // Convert the base unit to a BigNumber
      console.log(authamount);
      // const authamount = ethers.utils.parseEther(
      //   usdpiPurchaseAmtUsdt.toString()
      // );
      const feeamount = ethers.utils.parseEther(usdpiPurchaseFee.toString());
      console.log('authamount: %s', authamount);
      console.log('feeamount: %s', feeamount);
      console.log(
        'process.env.REACT_APP_ETH_SWAP: %s',
        process.env.REACT_APP_ETH_SWAP
      );
      const swapBank = new ethers.Contract(
        process.env.REACT_APP_ETH_SWAP as string,
        EthUsdtSwapV3Abi,
        tprovider
      );
      const exchange = async () => {
        console.log('Exchange - payamt: %s', usdpiPurchaseAmtUsdt);
        console.log('+++++ authamount: %s', authamount);
        console.log(typeof authamount);
        setProcessText('Waiting for wallet approval 2/2...');
        swapBank
          .connect(tsigner)
          .depositUsdt(account, authamount, {
            from: account,
          })
          .then((trxexchange: ContractTransaction) => {
            console.log(trxexchange);
            setProcessText('Waiting for blockchain confirmations...');
            return trxexchange.wait(3);
          })
          .then((receipt: ContractReceipt) => {
            setProcessText('');
            console.log('***********************');
            console.log(receipt.transactionHash);
            console.log('***********************');
            setRefBalances(true);
            setUsdpiPurchaseTX(receipt.transactionHash);
            axios
              .post(url.concat('/v1/usdpi/deposit/usdt/tx'), {
                nft_id: connectedMember ? connectedMember.memberId : null,
                tx: receipt.transactionHash,
              })
              .then(function (acctresponse) {
                setUsdpiPurchaseAmt(0);
                setUsdpiPurchaseTX(acctresponse.data.tx);
              })
              .catch(function (error) {
                console.log('================= ERROR ===================');
                console.log(error);
                setUsdpiPurchaseAmt(0);
                // setUsdpiSaleAmt('');
                setUsdpiPurchaseTX('ERROR: Exchange error');
              });
          })
          .catch((error: any) => {
            setProcessText('');
            // console.error(error);
            setUsdpiPurchaseTX('ERROR: Exchange error');
            setUsdpiPurchaseAmt('');
          });
      };

      // Approve the amount
      const approve = async () => {
        console.log('Approving authamount: %s', authamount);
        setProcessText('Waiting for wallet approval 1/2...');
        const overrides = { gasLimit: 500000 };
        contractusdt
          .connect(tsigner)
          .approve(process.env.REACT_APP_ETH_SWAP, authamount, {
            from: await tsigner.getAddress(),
            ...overrides,
          })
          .then((trxapprove: ContractTransaction) => {
            console.log(trxapprove);
            setProcessText('Waiting for blockchain confirmations...');
            return trxapprove.wait(3);
          })
          .then((receipt: ContractReceipt) => {
            console.log('***********************');
            console.log(receipt);
            console.log('***********************');
            exchange();
          })
          .catch((error: any) => {
            setProcessText('');
            console.error(error);
            setUsdpiPurchaseAmt('');
            setUsdpiPurchaseTX('ERROR: Approval error');
          });
      };
      approve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usdpiPurchaseAmtUsdt]);

  // ======================================
  // USDPI Purchase - BUSD
  // ======================================
  useEffect(() => {
    if (usdpiPurchaseAmt > 0) {
      console.log('-----------------------');
      console.log('    Purchase USDPI     ');
      console.log('usdpiPurchaseAmt: %s', usdpiPurchaseAmt);
      console.log('-----------------------');
      const tprovider = new ethers.providers.Web3Provider(window.ethereum);
      const tsigner = tprovider.getSigner();
      const contractbank = new ethers.Contract(
        process.env.REACT_APP_BSC_USDPIBANK as string,
        bankAbi,
        tprovider
      );
      const busdInterface = new ethers.utils.Interface(busdAbi);
      const contractbusd = new ethers.Contract(
        process.env.REACT_APP_BSC_BUSD as string,
        busdInterface,
        tprovider
      );

      const authamount = ethers.utils.parseEther(
        (usdpiPurchaseAmt - 1).toString()
      );
      const feeamount = ethers.utils.parseEther(usdpiPurchaseFee.toString());
      console.log('authamount: %s', authamount);
      console.log('feeamount: %s', feeamount);
      const exchange = async () => {
        console.log('Exchange - payamt: %s', usdpiPurchaseAmt);
        setProcessText('Waiting for wallet approval 2/2...');
        contractbank
          .connect(tsigner)
          .buy(authamount, feeamount, {
            from: await tsigner.getAddress(),
          })
          .then((trxexchange: ContractTransaction) => {
            console.log(trxexchange);
            setProcessText('Waiting for blockchain confirmations...');
            return trxexchange.wait(3);
          })
          .then((receipt: ContractReceipt) => {
            setProcessText('');
            console.log('***********************');
            console.log(receipt.transactionHash);
            console.log('***********************');
            setRefBalances(true);
            setUsdpiPurchaseTX(receipt.transactionHash);
            setUsdpiPurchaseAmt('');
          })
          .catch((error: any) => {
            setProcessText('');
            // console.error(error);
            setUsdpiPurchaseTX('ERROR: Exchange error');
            setUsdpiPurchaseAmt('');
          });
      };
      // Approve the amount
      const approve = async () => {
        console.log('Approving authamount: %s', authamount);
        setProcessText('Waiting for wallet approval 1/2...');
        const overrides = { gasLimit: 500000 };
        contractbusd
          .connect(tsigner)
          .approve(process.env.REACT_APP_BSC_USDPIBANK, authamount, {
            from: await tsigner.getAddress(),
            ...overrides,
          })
          .then((trxapprove: ContractTransaction) => {
            console.log(trxapprove);
            setProcessText('Waiting for blockchain confirmations...');
            return trxapprove.wait(3);
          })
          .then((receipt: ContractReceipt) => {
            console.log('***********************');
            console.log(receipt);
            console.log('***********************');
            exchange();
          })
          .catch((error: any) => {
            setProcessText('');
            console.error(error);
            setUsdpiPurchaseAmt('');
            setUsdpiPurchaseTX('ERROR: Approval error');
          });
      };
      approve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usdpiPurchaseAmt]);

  // ======================================
  // USDPI Sale
  // ======================================
  useEffect(() => {
    if (usdpiSaleAmt > 0) {
      console.log('Selling USDPI');
      console.log('-----------------------');
      console.log('    Sell USDPI     ');
      console.log('usdpiSaleAmt: %s', usdpiSaleAmt);
      console.log('-----------------------');
      // const url = process.env.REACT_APP_API as string;
      const processSell = async (response: any) => {
        const { deadline, amt2authorize, nonce, name, chain } = response;
        const version = '1';
        const domainData = {
          name,
          version,
          chainId: chain,
          verifyingContract: process.env.REACT_APP_BSC_USDPI,
        };
        const types = {
          Permit: [
            {
              name: 'owner',
              type: 'address',
            },
            {
              name: 'spender',
              type: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
            },
            {
              name: 'nonce',
              type: 'uint256',
            },
            {
              name: 'deadline',
              type: 'uint256',
            },
          ],
        };
        const val = {
          owner: account,
          spender: process.env.REACT_APP_BSC_USDPIBANK,
          value: amt2authorize,
          nonce,
          deadline,
        };
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        let sig;
        try {
          sig = await tsigner._signTypedData(domainData, types, val);
        } catch (error) {
          // code to handle errors during signing of typed data
          console.error(error); // log the error message to the console
          setUsdpiSaleAmt('');
          setUsdpiPurchaseTX('ERROR: User rejected');
        }
        // console.log(sig);
        // console.log(amt2authorize);
        axios
          .post(url.concat('/v1/usdpi/sell/'), {
            owner: account,
            amount: amt2authorize.toString(),
            deadline,
            signature: sig,
          })
          .then(function (acctresponse) {
            console.log(acctresponse.data);
            setUsdpiSaleAmt('');
            setUsdpiPurchaseTX(acctresponse.data.msg);
            setRefBalances(true);
          })
          .catch(function (error) {
            console.log('================= ERROR ===================');
            console.log(error);
            setUsdpiSaleAmt('');
            setUsdpiPurchaseTX('ERROR: Exchange error');
          });
      };
      // Get hashes
      // console.log('Cart Amount: %s', usdpiSaleAmt);
      // console.log(ethers.utils.parseEther(usdpiSaleAmt.toString()).toString());
      const amtAuth = ethers.utils
        .parseEther(usdpiSaleAmt.toString())
        .toString();
      axios
        .get(url.concat('/v1/usdpi/sell/'), {
          params: {
            wallet_address: account,
            amt2authorize: amtAuth,
          },
        })
        .then(function (acctresponse) {
          // console.log(acctresponse.data);
          processSell(acctresponse.data);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
          setUsdpiSaleAmt('');
          setUsdpiPurchaseTX('ERROR: Exchange error');
        });
    }
  }, [usdpiSaleAmt]);

  // ======================================
  // Staking purchase
  // ======================================
  useEffect(() => {
    if (cartPurchaseStake) {
      console.log('-----------------------');
      console.log('      Buy Stake        ');
      console.log('cartPurchase: %s', cartPurchaseStake);
      console.log('-----------------------');
      console.log('Cart Amount: %s', buyStakingAmt);
      console.log(ethers.utils.parseEther(buyStakingAmt.toString()).toString());
      const amtAuth = ethers.utils
        .parseEther(buyStakingAmt.toString())
        .toString();
      // console.log('amtAuth: %s', amtAuth);
      const processPurchase = async (response: any) => {
        const { deadline, amt2authorize, nonce, name, chain, spender } =
          response;
        const version = '1';
        // console.log('amt2authorize: %s', amt2authorize);
        const domainData = {
          name,
          version,
          chainId: chain,
          verifyingContract: process.env.REACT_APP_BSC_USDPI,
        };
        const types = {
          Permit: [
            {
              name: 'owner',
              type: 'address',
            },
            {
              name: 'spender',
              type: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
            },
            {
              name: 'nonce',
              type: 'uint256',
            },
            {
              name: 'deadline',
              type: 'uint256',
            },
          ],
        };
        const val = {
          owner: account,
          spender,
          value: amt2authorize,
          nonce,
          deadline,
        };
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        try {
          const sig = await tsigner._signTypedData(domainData, types, val);
          // console.log(sig);
          axios
            .post(url.concat('/v1/staking/purchase'), {
              owner: account,
              tokenId: connectedMember.memberId,
              amt: amt2authorize.toString(),
              deadline,
              sig,
            })
            .then(function (acctresponse) {
              console.log(acctresponse.data);
              setRefBalances(true);
              setCartPurchaseStake(false);
              setCartPurchaseStakeTX(acctresponse.data.tx);
            })
            .catch(function (error) {
              console.log('================= ERROR ===================');
              console.log(error);
              setCartPurchaseStake(false);
              setCartPurchaseStakeTX('ERROR: xxx');
            });
        } catch (error) {
          // setCartPurchaseStake(false);
          setCartPurchaseStakeTX('ERROR: User rejected signature');
        }
      };
      axios
        .get(url.concat('/v1/staking/purchase/'), {
          params: {
            wallet_address: account,
            amt2authorize: amtAuth,
          },
        })
        .then(function (acctresponse) {
          console.log(acctresponse.data);
          processPurchase(acctresponse.data);
          // setCartPurchaseStakeTX('xxx');
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
          setCartPurchaseStakeTX('ERROR: xxx');
          setCartPurchaseStake(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartPurchaseStake]);

  useEffect(() => {
    // console.log('------------------------');
    // console.log('PAGE CHANGE DETECTED');
    // console.log('------------------------');
    // console.log(lastPageServed);
  }, [lastPageServed]);

  useEffect(() => {
    // console.log('------------------------');
    // console.log('HARVEST STAKING REWARDS');
    // console.log('------------------------');
    // console.log(harvestStake);
  }, [harvestStake]);

  useEffect(() => {
    if (runWithdrawal) {
      console.log('------------------------');
      console.log('    MEMBER WITHDRAWAL   ');
      console.log('NFT ID: %s', connectedMember.memberId);
      console.log('account: %s', account);
      console.log('------------------------');
      interface Request {
        nftId: number;
        userAddress: string;
        message: string;
        deadline: number;
        transaction_fee: BigNumber;
      }
      const processOrder = async (fee: any) => {
        const purser = process.env.REACT_APP_BSC_PURSER;
        console.log('+++++++++++++++++++++++++++++++++++++++++++++++');
        console.log('verifyingContract: %s', purser);
        const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
        console.log('deadline: %s', deadline);
        const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
        const chainInt = parseInt(chainNo || '0', 16);
        console.log(chainInt);
        const domain = {
          name: 'Purser',
          version: '1',
          chainId: chainInt,
          verifyingContract: purser,
        };
        console.log('+++++++++++++++++++++++++++++++++++++++++++++++');
        console.log(domain);
        console.log('+++++++++++++++++++++++++++++++++++++++++++++++');
        const types = {
          Request: [
            { name: 'nftId', type: 'uint256' },
            { name: 'userAddress', type: 'address' },
            { name: 'message', type: 'string' },
            { name: 'deadline', type: 'uint256' },
            { name: 'transaction_fee', type: 'uint256' },
          ],
        };
        const transaction_fee = BigNumber.from(fee);
        const transaction_fee_in_ether = parseFloat(
          ethers.utils.formatEther(transaction_fee)
        );
        const message = `I authorize this withdrawal, less a transaction fee of $${transaction_fee_in_ether.toFixed(
          2
        )}`;
        console.log(message);
        const request: Request = {
          nftId: connectedMember.memberId,
          userAddress: account,
          message,
          deadline,
          transaction_fee: BigNumber.from(fee),
        };
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        // const sig = await tsigner._signTypedData(domain, types, request);
        try {
          const sig = await tsigner._signTypedData(domain, types, request);
          // continue with your code
          // console.log(sig);
          axios
            .post(url.concat('/v1/member/withdraw'), {
              nftId: connectedMember.memberId,
              userAddress: account,
              message,
              deadline,
              signature: sig,
              transaction_fee: parseInt(fee, 10),
            })
            .then(function (response) {
              // console.log(response);
              // processOrder(response.data.estimate.fee);
              setWithdrawalResult(response.data.tx);
            })
            .catch(function (error) {
              console.log('================= ERROR ===================');
              console.log(error);
              setWithdrawalResult('ERROR');
            });
        } catch (error) {
          if (
            error instanceof Error &&
            error.message === 'User denied message signature.'
          ) {
            // handle the case where the user rejected the signature
            setRunWithdrawal(false);
            setWithdrawalResult('ERROR');
          } else {
            // handle other errors
            setRunWithdrawal(false);
            setWithdrawalResult('ERROR');
          }
        }
        setRunWithdrawal(false);
      };
      axios
        .get(url.concat('/v1/net/fee/estimate'), {
          params: {
            registered: 2,
            owner: account,
          },
        })
        .then(function (response) {
          console.log(response);
          processOrder(response.data.estimate.fee);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
        });
    }
  }, [runWithdrawal]);

  useEffect(() => {
    if (runClaimPoolUsdpi) {
      console.log('-----------------------------');
      console.log('  MEMBER CLAIM POOL REWARDS  ');
      console.log('NFT ID: %s', connectedMember.memberId);
      console.log('account: %s', account);
      console.log('-----------------------------');
      interface Request {
        nftId: number;
        userAddress: string;
        message: string;
        deadline: number;
        transaction_fee: BigNumber;
      }
      const processClaimPool = async (fee: any) => {
        const purser = process.env.REACT_APP_BSC_PURSER;
        console.log('verifyingContract: %s', purser);
        const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
        console.log('deadline: %s', deadline);
        const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
        const chainInt = parseInt(chainNo || '0', 16);
        console.log(chainInt);
        const domain = {
          name: 'Purser',
          version: '1',
          chainId: chainInt,
          verifyingContract: purser,
        };
        const types = {
          Request: [
            { name: 'nftId', type: 'uint256' },
            { name: 'userAddress', type: 'address' },
            { name: 'message', type: 'string' },
            { name: 'deadline', type: 'uint256' },
            { name: 'transaction_fee', type: 'uint256' },
          ],
        };
        const transaction_fee = BigNumber.from(fee);
        const transaction_fee_in_ether = parseFloat(
          ethers.utils.formatEther(transaction_fee)
        );
        const message = `I authorize this withdrawal, less a transaction fee of $${transaction_fee_in_ether.toFixed(
          2
        )}`;
        console.log(message);
        const request: Request = {
          nftId: connectedMember.memberId,
          userAddress: account,
          message,
          deadline,
          transaction_fee: BigNumber.from(fee),
        };
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        try {
          const sig = await tsigner._signTypedData(domain, types, request);
          console.log('sig: %s', sig);
          axios
            .post(url.concat('/v1/staking/claimrewards'), {
              nftId: connectedMember.memberId,
              userAddress: account,
              message,
              deadline,
              signature: sig,
              transaction_fee: parseInt(fee, 10),
            })
            .then(function (response) {
              setRunClaimPoolUsdpi(false);
              setWithdrawalPoolUsdpiResult(response.data.tx);
            })
            .catch(function (error) {
              console.log('================= ERROR ===================');
              console.log(error);
              setRunClaimPoolUsdpi(false);
              setWithdrawalPoolUsdpiResult('ERROR');
            });
        } catch (error) {
          // console.log(error);
          if (
            error instanceof Error &&
            error.message === 'User denied message signature.'
          ) {
            // handle the case where the user rejected the signature
            setRunClaimPoolUsdpi(false);
            setWithdrawalPoolUsdpiResult('ERROR');
          } else {
            // handle other errors
            setRunClaimPoolUsdpi(false);
            setWithdrawalPoolUsdpiResult('ERROR');
          }
        }
        console.log('procedure end');
      };
      axios
        .get(url.concat('/v1/net/fee/estimate'), {
          params: {
            registered: 2,
            owner: account,
          },
        })
        .then(function (response) {
          console.log(response);
          processClaimPool(response.data.estimate.fee);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
        });
    }
  }, [runClaimPoolUsdpi]);

  useEffect(() => {
    if (runClaimPerfPoolUsdpi) {
      console.log('-----------------------------------------');
      console.log('  MEMBER CLAIM PERFORMANCE POOL REWARDS  ');
      console.log('NFT ID: %s', connectedMember.memberId);
      console.log('account: %s', account);
      console.log('-----------------------------------------');
      interface Request {
        nftId: number;
        userAddress: string;
        message: string;
        deadline: number;
        transaction_fee: BigNumber;
      }
      const processClaimPerfPool = async (fee: any) => {
        const purser = process.env.REACT_APP_BSC_PURSER;
        console.log('verifyingContract: %s', purser);
        const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
        console.log('deadline: %s', deadline);
        const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
        const chainInt = parseInt(chainNo || '0', 16);
        console.log(chainInt);
        const domain = {
          name: 'Purser',
          version: '1',
          chainId: chainInt,
          verifyingContract: purser,
        };
        const types = {
          Request: [
            { name: 'nftId', type: 'uint256' },
            { name: 'userAddress', type: 'address' },
            { name: 'message', type: 'string' },
            { name: 'deadline', type: 'uint256' },
            { name: 'transaction_fee', type: 'uint256' },
          ],
        };
        const transaction_fee = BigNumber.from(fee);
        const transaction_fee_in_ether = parseFloat(
          ethers.utils.formatEther(transaction_fee)
        );
        const message = `I authorize this withdrawal, less a transaction fee of $${transaction_fee_in_ether.toFixed(
          2
        )}`;
        console.log(message);
        const request: Request = {
          nftId: connectedMember.memberId,
          userAddress: account,
          message,
          deadline,
          transaction_fee: BigNumber.from(fee),
        };
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        try {
          const sig = await tsigner._signTypedData(domain, types, request);
          axios
            .post(url.concat('/v1/perfpool/claimrewards'), {
              nftId: connectedMember.memberId,
              userAddress: account,
              message,
              deadline,
              signature: sig,
              transaction_fee: parseInt(fee, 10),
            })
            .then(function (response) {
              // console.log(response);
              // processOrder(response.data.estimate.fee);
              setRunClaimPerfPoolUsdpi(false);
              setWithdrawalPerfPoolUsdpiResult(response.data.tx);
            })
            .catch(function (error) {
              console.log('================= ERROR ===================');
              console.log(error);
              setRunClaimPerfPoolUsdpi(false);
              setWithdrawalPerfPoolUsdpiResult('ERROR');
            });
        } catch (error) {
          console.log(error);
          if (
            error instanceof Error &&
            error.message === 'User denied message signature.'
          ) {
            // handle the case where the user rejected the signature
            setRunClaimPerfPoolUsdpi(false);
            setWithdrawalPerfPoolUsdpiResult('ERROR');
          } else {
            // handle other errors
            setRunClaimPerfPoolUsdpi(false);
            setWithdrawalPerfPoolUsdpiResult('ERROR');
          }
        }
      };
      axios
        .get(url.concat('/v1/net/fee/estimate'), {
          params: {
            registered: 2,
            owner: account,
          },
        })
        .then(function (response) {
          console.log(response);
          processClaimPerfPool(response.data.estimate.fee);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
          setRunClaimPerfPoolUsdpi(false);
          setWithdrawalPerfPoolUsdpiResult('ERROR');
        });
    }
  }, [runClaimPerfPoolUsdpi]);

  useEffect(() => {
    if (runClaimDripPrfti !== '') {
      console.log('-----------------------------------');
      console.log('  MEMBER CLAIM DRIP PRFTI REWARDS  ');
      console.log('NFT ID: %s', connectedMember.memberId);
      console.log('account: %s', account);
      console.log('runClaimDripPrfti: %s', runClaimDripPrfti);
      console.log('-----------------------------------');
      interface Request {
        nftId: number;
        userAddress: string;
        message: string;
        deadline: number;
        transaction_fee: BigNumber;
      }
      const processClaimDrip = async (response: any) => {
        const { deadline, amt2authorize, nonce, name, chain, spender } =
          response;
        const version = '1';
        const domainData = {
          name,
          version,
          chainId: chain,
          verifyingContract: process.env.REACT_APP_BSC_USDPI,
        };
        const types = {
          Permit: [
            { name: 'owner', type: 'address' },
            { name: 'spender', type: 'address' },
            { name: 'value', type: 'uint256' },
            { name: 'nonce', type: 'uint256' },
            { name: 'deadline', type: 'uint256' },
          ],
        };
        const val = {
          owner: account,
          spender,
          value: amt2authorize,
          nonce,
          deadline,
        };
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        try {
          const sig = await tsigner._signTypedData(domainData, types, val);
          console.log(sig);
          axios
            .post(url.concat('/v1/drip/claimtokens'), {
              owner: account,
              tokenId: connectedMember.memberId,
              amt: amt2authorize.toString(),
              deadline,
              sig,
              drip_id: runClaimDripPrfti,
            })
            .then(function (acctresponse) {
              console.log(acctresponse.data);
              setRefBalances(true);
              setRunClaimDripPrfti('');
              setWithdrawalDripResult(acctresponse.data.tx);
              // setCartPurchaseStakeTX(acctresponse.data.tx);
            })
            .catch(function (error) {
              console.log('================= ERROR ===================');
              console.log(error);
              setRunClaimDripPrfti('');
              setWithdrawalDripResult('ERROR');
              // setCartPurchaseStake(false);
              // setCartPurchaseStakeTX('ERROR: xxx');
            });
        } catch (error) {
          console.log(error);
          if (
            error instanceof Error &&
            error.message === 'User denied message signature.'
          ) {
            // handle the case where the user rejected the signature
            setRunClaimDripPrfti('');
            setWithdrawalDripResult('ERROR');
          } else {
            // handle other errors
            setRunClaimDripPrfti('');
            setWithdrawalDripResult('ERROR');
          }
        }
      };

      axios
        .get(url.concat('/v1/drip/claimtokens/'), {
          params: {
            wallet_address: account,
          },
        })
        .then(function (acctresponse) {
          console.log(acctresponse.data);
          processClaimDrip(acctresponse.data);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
          setRunClaimDripPrfti('');
        });
    }
  }, [runClaimDripPrfti]);

  useEffect(() => {
    if (runClaimStakingPrfti !== '') {
      console.log('--------------------------------------');
      console.log('  MEMBER CLAIM STAKING PRFTI REWARDS  ');
      console.log('NFT ID: %s', connectedMember.memberId);
      console.log('account: %s', account);
      console.log('runClaimStakingPrfti: %s', runClaimStakingPrfti);
      console.log('--------------------------------------');
      interface Request {
        nftId: number;
        userAddress: string;
        message: string;
        deadline: number;
        transaction_fee: BigNumber;
      }
      const processClaimStaking = async (response: any) => {
        const { deadline, amt2authorize, nonce, name, chain, spender } =
          response;
        const version = '1';
        const domainData = {
          name,
          version,
          chainId: chain,
          verifyingContract: process.env.REACT_APP_BSC_USDPI,
        };
        const types = {
          Permit: [
            { name: 'owner', type: 'address' },
            { name: 'spender', type: 'address' },
            { name: 'value', type: 'uint256' },
            { name: 'nonce', type: 'uint256' },
            { name: 'deadline', type: 'uint256' },
          ],
        };
        const val = {
          owner: account,
          spender,
          value: amt2authorize,
          nonce,
          deadline,
        };
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        try {
          const sig = await tsigner._signTypedData(domainData, types, val);
          console.log(sig);
          axios
            .post(url.concat('/v1/staking/claimtokens'), {
              owner: account,
              tokenId: connectedMember.memberId,
              amt: amt2authorize.toString(),
              deadline,
              sig,
              contract_id: runClaimStakingPrfti,
            })
            .then(function (acctresponse) {
              console.log(acctresponse.data);
              setRefBalances(true);
              setRunClaimStakingPrfti('');
              setWithdrawalStakingResult(acctresponse.data.tx);
              // setCartPurchaseStakeTX(acctresponse.data.tx);
            })
            .catch(function (error) {
              console.log('================= ERROR ===================');
              console.log(error);
              setRunClaimStakingPrfti('');
              setWithdrawalStakingResult('ERROR');
              // setCartPurchaseStake(false);
              // setCartPurchaseStakeTX('ERROR: xxx');
            });
        } catch (error) {
          console.log(error);
          if (
            error instanceof Error &&
            error.message === 'User denied message signature.'
          ) {
            // handle the case where the user rejected the signature
            setRunClaimStakingPrfti('');
            setWithdrawalStakingResult('ERROR');
          } else {
            // handle other errors
            setRunClaimStakingPrfti('');
            setWithdrawalStakingResult('ERROR');
          }
        }
      };

      axios
        .get(url.concat('/v1/staking/claimtokens/'), {
          params: {
            wallet_address: account,
          },
        })
        .then(function (acctresponse) {
          console.log(acctresponse.data);
          processClaimStaking(acctresponse.data);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
          setRunClaimStakingPrfti('');
          setWithdrawalStakingResult('ERROR');
        });
    }
  }, [runClaimStakingPrfti]);

  // ======================================
  // WALLET LISTENER
  // ======================================
  useEffect(() => {
    // console.log('WALLET LISTENER');
    // console.log(funnelType);
    if (funnelType === 'STANDARD') {
      if (typeof window.ethereum !== 'undefined') {
        const handleNetworkChange = (network: any) => {
          // console.log('------------------------');
          // console.log('Network change detected!');
          // setCurrentChain(network.chainId);
          // console.log('------------------------');
        };
        const handleAccountChange = (accounts: string[]) => {
          // console.log('------------------------');
          // console.log('Account change detected!');
          // console.log(accounts[0]);
          // console.log('------------------------');
          localStorage.removeItem('@PROFITi:status');
          localStorage.removeItem('@PROFITi:lastPage');
          setConnectedMember(null);
          setStatus('');
          setConnected(false);
          setAccount('');
          setLastPageServed('');
          if (accounts.length > 0) {
            setAccount(ethers.utils.getAddress(accounts[0]));
          }
        };

        const nprovider = new ethers.providers.Web3Provider(
          window.ethereum,
          'any'
        );
        nprovider.on('network', handleNetworkChange);
        window.ethereum.on('accountsChanged', handleAccountChange);
        return () => {
          nprovider.removeAllListeners('accountsChanged');
          nprovider.removeAllListeners('network');
        };
      }
    }
    return undefined;
  }, [funnelType]);

  useEffect(() => {
    // console.log('currentChain: %s, chainMode: %s', currentChain, chainMode);

    if (chainMode === null) {
      setReqChainHex(null);
    } else if (chainMode === 'NETWORK') {
      const newReqChainNet = process.env.REACT_APP_NETWORK_CHAIN_ID as string;
      // console.log('newReqChainNet: %s', newReqChainNet);
      const newReqChainNetInt = parseInt(newReqChainNet, 16);
      // console.log('newReqChainNetInt: %s', newReqChainNetInt);
      setReqChainHex(newReqChainNet);
      setWrongChain(currentChain !== newReqChainNetInt);
    } else if (chainMode === 'PAY') {
      const newReqChainPay = process.env.REACT_APP_PAY_CHAIN_ID as string;
      // console.log('newReqChainPay: %s', newReqChainPay);
      const newReqChainPayInt = parseInt(newReqChainPay, 16);
      // console.log('newReqChainPayInt: %s', newReqChainPayInt);
      setReqChainHex(newReqChainPay);
      setWrongChain(currentChain !== newReqChainPayInt);
    }
  }, [currentChain, chainMode]);

  useEffect(() => {
    if (walletAddPRFTI) {
      // console.log('------------------------');
      // console.log('ADD PRFTI');
      // console.log('------------------------');
      const tokenName = 'Profiti Token';
      const tokenAddress = process.env.REACT_APP_BSC_PRFTI;
      const tokenSymbol = 'PRFTI';
      const tokenDecimals = '18';
      const tokenImage = '';
      const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
      const chainInt = parseInt(chainNo || '0', 16);
      const tprovider = (window as any).ethereum;
      const eprovider = new ethers.providers.Web3Provider(window.ethereum);
      const ethCallProvider = new Provider(eprovider, chainInt);

      const token = {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: tokenImage,
        },
      };

      const processAdd = async () => {
        console.log(token);
        try {
          await tprovider.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: token.options,
              chainId: chainInt,
              token,
            },
          });
          console.log(`Added ${tokenName} (${tokenSymbol}) to MetaMask`);
          setWalletAddPRFTI(false);
        } catch (error) {
          console.error(
            `Error adding ${tokenName} (${tokenSymbol}) to MetaMask`,
            error
          );
          setWalletAddPRFTI(false);
        }
      };
      processAdd();
    }
  }, [walletAddPRFTI]);

  useEffect(() => {
    if (walletAddUSDPI) {
      // console.log('------------------------');
      // console.log('ADD USDPI');
      // console.log('------------------------');
      const processAdd = async () => {
        const tokenName = 'USDPI';
        const tokenAddress = process.env.REACT_APP_BSC_USDPI;
        const tokenSymbol = 'USDPI';
        const tokenDecimals = '18';
        const tokenImage = '';
        const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
        const chainInt = parseInt(chainNo || '0', 16);
        const tprovider = (window as any).ethereum;
        const token = {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        };
        try {
          await tprovider.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: token.options,
              chainId: chainInt,
              token,
            },
          });
          console.log(`Added ${tokenName} (${tokenSymbol}) to MetaMask`);
          setWalletAddUSDPI(false);
        } catch (error) {
          console.error(
            `Error adding ${tokenName} (${tokenSymbol}) to MetaMask`,
            error
          );
          setWalletAddUSDPI(false);
        }
      };
      processAdd();
    }
  }, [walletAddUSDPI]);

  useEffect(() => {
    if (aiigCreate) {
      console.log(connectedMember);
      console.log(connectedMember.id);
      axios
        .post(url.concat('/v2/ai/image/create/'), {
          tokenID: connectedMember.memberId,
          command: aiigPrompt,
          seed: '0',
          images: aiigCount,
        })
        .then(function (acctresponse) {
          console.log(acctresponse.data);
          setAiigResult(acctresponse.data.images);
          // processClaimStaking(acctresponse.data);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
          setRunClaimStakingPrfti('');
          setWithdrawalStakingResult('ERROR');
        });
      setAiigCreate(false);
    }
  }, [aiigCreate, aiigPrompt, aiigCount]);

  // const [aiigSeed, setAiigSeed] = web3store.useState('aiigSeed');
  // const [aiigPrompt, setAiigPrompt] = web3store.useState('aiigPrompt');
  // const [aiigResult, setAiigResult] = web3store.useState('aiigResult');
  // const [aiigCount, setAiigCount] = web3store.useState('aiigCount');
  // const [aiigCreate, setAiigCreate] = web3store.useState('aiigCreate');

  return <></>;
};

export default Web3Stats;
