import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { IoMdLogOut } from 'react-icons/io';
import { truncate } from 'truncate-ethereum-address';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlineLightMode } from 'react-icons/md';
import { useAuth } from '~/hooks/Auth';

import { web3store } from '~/store';
import ModalSwap from '../ModalSwap';
import { Container, BtnAvatar, Avatar } from './styles';
import { formatPrice } from '~/utils/format';

interface WidgetProps {
  selectedAddress: string;
  refCode: string;
  placement: string;
  username: string;
  sponsorName: string;
  sponsorUsername: string;
}

const WalletAvatar: React.FC<WidgetProps> = ({
  selectedAddress,
  refCode,
  placement,
  username,
  sponsorName,
  sponsorUsername,
}) => {
  // console.log('+++++ refCode: %s +++++', refCode);
  const btnDisconnect = useRef<HTMLButtonElement>(null);
  const disconnectRef = useRef<HTMLDivElement>(null);
  const disconnectBalanceRef = useRef<HTMLDivElement>(null);
  const chooseTokenRef = useRef<HTMLDivElement>(null);
  const closeAddBusdRef = useRef<HTMLDivElement>(null);
  const closeAddUsdtRef = useRef<HTMLDivElement>(null);
  const closeAddUsdcRef = useRef<HTMLDivElement>(null);
  const closeAddGusdRef = useRef<HTMLDivElement>(null);
  const closeAddDaiRef = useRef<HTMLDivElement>(null);
  const { user, signOut } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [account] = web3store.useState('account');
  const [balusdpi] = web3store.useState('balusdpi');
  const [reference] = web3store.useState('reference');
  const [balance, setBalance] = useState(false);
  const [chooseToken, setChooseToken] = useState(false);
  const [disconnect, setDisconnect] = useState(false);
  const [imgURL] = web3store.useState('imgURL');
  const [nftimg] = web3store.useState('nftimg');
  const [avatar, setAvatar] = useState('');
  const [selected, setSelected] = useState('');
  const [addMetamask, setAddMetamask] = useState('');

  useEffect(() => {
    setAvatar(imgURL);
  }, [imgURL, location.pathname, nftimg, user]);

  const locationBar = [
    '/get-your-free-nft',
    '/how-to-participate',
    '/activate-your-profit-centers',
    '/activate-your-profit-centers/skip',
    '/earn-even-more',
    '/final-step',
    '/ai-avatar',
    '/what-to-expect',
    '/autoaffiliate-member/order',
  ];

  const handleNoAddMetamask = useCallback(() => {
    setTimeout(() => {
      setChooseToken(true);
      setSelected('');
    }, 100);
  }, []);

  const signOut2 = useCallback(() => {
    // do something
    console.log('Signing out...');
    history.push({
      pathname: `/aa/${refCode}/${placement}/${username}`,
    });
  }, []);

  const handleOutsideClick = useCallback(
    (e) => {
      const modalElement = document.querySelector('.modal-backdrop');
      if (
        disconnect &&
        disconnectRef.current &&
        e.target !== disconnectRef.current &&
        !disconnectRef.current.contains(e.target)
      ) {
        setDisconnect(false);
      }
    },
    [addMetamask, balance, chooseToken, disconnect, handleNoAddMetamask]
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
  }, [handleOutsideClick]);

  // const balanceData = useMemo(() => {
  //   return {
  //     usdpi: formatPrice(
  //       parseFloat((parseInt(balusdpi, 10) / 10 ** 18).toFixed(2))
  //     ),
  //   };
  // }, [balusdpi]);

  const handleBalance = useCallback(() => {
    setTimeout(() => {
      setBalance(!balance);
      setDisconnect(false);
    }, 100);
  }, [balance]);

  const handleDisconnect = useCallback(() => {
    setTimeout(() => {
      setBalance(false);
      setDisconnect(!disconnect);
    }, 100);
  }, [disconnect]);

  return (
    <Container className="zoom position-relative">
      <BtnAvatar
        type="button"
        data-tooltip="Disconnect Wallet"
        onClick={handleBalance}
        className="disconnect-tooltip d-flex align-items-center btn-avatar btn-avatar-wallet text-end"
        isFunnel={false}
      >
        <div className="avatar d-flex align-items-center text-center rounded-circle">
          <Avatar src={avatar} className="rounded-circle" />
        </div>
        {location.pathname !== '/products/cube' && (
          <div className="d-flex flex-column">
            <span className="px-3 wallet-num">{truncate(selectedAddress)}</span>
            {locationBar.includes(location.pathname) && (
              <div className="reference px-3 text-end">
                <span>
                  Invited by: <span>{reference}</span>
                </span>
              </div>
            )}
          </div>
        )}
      </BtnAvatar>
      {location.pathname === '/autoaffiliate-member/order' && (
        <div className="reference-aa text-end pe-3">
          <span className="d-block">
            Invited by: <span>{sponsorUsername}</span>
          </span>
          <span className="d-block">
            AA Name: <span>{sponsorName}</span>
          </span>
        </div>
      )}
      {balance && (
        <div
          ref={disconnectBalanceRef}
          className="disconnect position-absolute p-4"
        >
          <div className="d-flex align-items-center">
            <div className="avatar d-flex align-items-center text-center rounded-circle">
              <Avatar src={imgURL} className="rounded-circle" />
            </div>
            <span className="px-3 wallet-num">{truncate(selectedAddress)}</span>
            <button
              ref={btnDisconnect}
              type="button"
              data-tooltip="Disconnect Wallet"
              onClick={handleDisconnect}
              className="disconnect-tooltip btn-logout border-0 p-2 ms-auto"
            >
              <IoMdLogOut size={30} color="#FF1A50" />
            </button>
          </div>
        </div>
      )}

      {disconnect && (
        <div ref={disconnectRef} className="disconnect position-absolute p-4">
          <h2 className="mb-4 text-disconnect text-center">
            Disconnect Wallet?
          </h2>

          <button
            type="button"
            onClick={signOut2}
            className="btn-yes w-100 py-2 mb-2"
          >
            Yes
          </button>
          <button
            type="button"
            onClick={handleDisconnect}
            className="btn-no py-2  mb-2 w-100"
          >
            No
          </button>
        </div>
      )}
    </Container>
  );
};

export default WalletAvatar;
