import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { lighten } from 'polished';

interface IAvatar {
  src: string;
}

export const Nfts = styled(Form)`
  .container {
    max-width: 1700px;
  }
  .btns {
    top: 1rem;
    left: 0;
    opacity: 0;
    button {
      font-family: 'Quicksand';
      font-weight: 600;
      font-size: 16px;
      color: #bebebe;
      background: rgba(152, 148, 148, 0.1);
      padding: 15px 20px;
      backdrop-filter: blur(15px);
      border-radius: 12px;
      border: none;
    }
    button:nth-child(1) {
      margin-left: 1rem;
    }
    button:nth-child(2) {
      margin-right: 1rem;
    }
  }

  .hover-btns:hover {
    .btns {
      opacity: 1;
    }
    .hover .bg {
      background-color: rgba(14, 14, 14, 0.86);
    }
  }

  .hover {
    border-radius: 25px;
  }

  .hover .bg {
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    transition: all 0.3s ease;

    :hover {
      span,
      button {
        opacity: 1;
      }
    }
  }

  .hover img {
    border-radius: 25px;
  }

  .avatar-text {
    color: #cbccce;
    font-size: 14px;
    font-weight: 500;
  }

  .avatar {
    height: 30px;
    width: 30px;
    padding: 3px;
    background-image: linear-gradient(180deg, #242526, #242526),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 15px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    cursor: unset;
  }

  .avatar img {
    width: 30px;
    height: 30px;
    cursor: unset;
  }
  .border-nft {
    border: 1px solid #4f4f4f;
    border-radius: 30px;
  }

  .area-send {
    bottom: 0px;
    right: 0px;
    width: calc(100% - 160px);
    padding: 25px 10px 10px 25px;
    background: linear-gradient(180deg, rgba(24, 25, 26, 0) 0%, #18191a 63%);
  }

  .border-textarea {
    width: 60%;
    max-width: 1000px;
    background: #1f2020;
    border: 1px solid #282929;
    box-shadow: 1px 16px 30px rgba(0, 0, 0, 0.1);
    border-radius: 22px;
    height: 82px;
  }

  .textarea {
    height: unset;

    textarea {
      color: #787878;
    }
  }

  .send {
    border: none;
    background-color: transparent;
    transition-duration: 0.3s;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    color: #c4c4c4;
  }

  .send:focus,
  .send:hover {
    color: ${lighten(0.1, '#c4c4c4')}!important;
  }

  input {
    resize: none;
    background-color: transparent;
    color: #d4d4d4 !important;
    font-weight: 400 !important;
    border: none;
    ::placeholder {
      color: #5a5b5b !important;
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: top;
`;
