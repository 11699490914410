// configUtils.ts

// Import any necessary dependencies, such as axios for making API requests
import api from '~/services/api';

// Function to fetch configuration values from an API endpoint
const fetchConfigValue = async (key: string): Promise<any> => {
  try {
    const response = await api.get(`/v1/config/get?key=${key}`);
    return response.data; // Adjust this based on your API response structure
  } catch (error) {
    console.error(`Error fetching config value for key "${key}":`, error);
    return null; // Handle the error gracefully
  }
};

// Function to get an Ethereum address configuration
export const getConfigAddress = async (key: string): Promise<string | null> => {
  const value = await fetchConfigValue(key);
  return value;
};

// Function to get a number configuration
export const getConfigNumber = async (key: string): Promise<number | null> => {
  const value = await fetchConfigValue(key);
  return parseFloat(value); // Adjust as needed
};

// Function to get a string configuration
export const getConfigString = async (key: string): Promise<string | null> => {
  const value = await fetchConfigValue(key);
  return value;
};
