import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { SlRefresh } from 'react-icons/sl';
import { BsArrowReturnRight } from 'react-icons/bs';
import { FormHandles } from '@unform/core';
import Dropdown from 'react-dropdown';
import { Container } from './styles';
import { web3store } from '~/store';

import Input from '~/components/Input';

import sample1 from '~/assets/defaults/sample_1.png';
import sample2 from '~/assets/defaults/sample_2.png';
import sample3 from '~/assets/defaults/sample_3.png';
import sample4 from '~/assets/defaults/sample_4.png';
import sample5 from '~/assets/defaults/sample_5.png';
import sample6 from '~/assets/defaults/sample_6.png';
import sample7 from '~/assets/defaults/sample_7.png';
import sample8 from '~/assets/defaults/sample_8.png';
import sample9 from '~/assets/defaults/sample_9.png';
import sample10 from '~/assets/defaults/sample_10.png';
import sample11 from '~/assets/defaults/sample_11.png';
import sample12 from '~/assets/defaults/sample_12.png';
import sample13 from '~/assets/defaults/sample_13.png';
import sample14 from '~/assets/defaults/sample_14.png';
import sample15 from '~/assets/defaults/sample_15.png';
import sample16 from '~/assets/defaults/sample_16.png';

interface ISearch {
  onClickSearch(): void;
}

const Examples: React.FC<ISearch> = ({ onClickSearch }) => {
  const formRef = useRef<FormHandles>(null);

  // const [seed, setSeed] = useState('');
  // const [valueInput, setValueInput] = useState('');

  // AI Image generator - global
  const [aiigSeed, setAiigSeed] = web3store.useState('aiigSeed');
  const [aiigPrompt, setAiigPrompt] = web3store.useState('aiigPrompt');
  const [aiigResult, setAiigResult] = web3store.useState('aiigResult');
  const [aiigCount, setAiigCount] = web3store.useState('aiigCount');
  const [aiigCreate, setAiigCreate] = web3store.useState('aiigCreate');

  const sampleImage = useMemo(
    () => [
      [
        {
          image: sample1,
          description:
            'gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
        },
        {
          image: sample2,
          description:
            'wolf knight, finely detailed armor, intricate design, silver, silk, cinematic lighting, 4k 16K, professional color grading, soft shadows, high contrast, clean sharp focus, award-winning cinematic photography, 4k up light',
        },
        {
          image: sample3,
          description:
            'Goddess of nature, native American Indian, green vines and flowers with black flowing for hair, feathers, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
        },
        {
          image: sample4,
          description:
            'beautiful female warrior wearing Dark Brotherhood armour , beautiful face, glowing eyes, epic war zone, full shot, intricate details, symmetrical, Todd Mcfarlane, Diego Dayer, Anna, Dittmann, Unreal 5, hyperrealistic, dynamic lighting, neon ambience, volumetric lighting, fantasy art',
        },
      ],
      [
        {
          image: sample5,
          description:
            'robotic goddess, cyborg, ornate translucent glowing sapphire and amethyst and emerald and silver, impressionist painting on canvas, female android, flowers, in the style of Jean-Baptiste Monge:20 , outer space, vanishing point, super highway, high speed, digital render, digital painting, beeple, noah bradley, cyril roland, ross tran, trending on artstation',
        },
        {
          image: sample6,
          description:
            'Dark skin, handsome outlaw, futuristic gunslinger, sci-fi, white hero suit, bad boy, HD, dark background,  unreal engine, 8k',
        },
        {
          image: sample7,
          description:
            'beautiful horse, portrait, finely detailed armor, intricate design, silver, silk, cinematic lighting, 4k long hair, blue eyes, colorful synthwave neon',
        },
        {
          image: sample8,
          description:
            'cute dragon holding heart, stunning, not ugly, up lighting',
        },
      ],
      [
        {
          image: sample9,
          description:
            'Viking, barbarian, warrior, glowing eyes, horned helmet, beard, angry face, high detail, inky',
        },
        {
          image: sample10,
          description:
            'A beautiful female super model, mixed media, wet paint, Jewel bug, color background, flowing liquid color down her face, shot by ZHANG JINGNA',
        },
        {
          image: sample11,
          description:
            'black woman, gorgeous cyberpunk hacker, wires cybernetic implants, in the style of adrian ghenie esao andrews jenny saville surrealism dark art by james jean takato yamamoto and blii sienkiewicz, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by greg rutkowski',
        },
        {
          image: sample12,
          description:
            'White lion, full fur, facing at the camera, very colorful, 8k, highly detailed synth wave neon',
        },
      ],
      [
        {
          image: sample13,
          description:
            'character design of a handsome young warrior with armor, cel shading, stunning quality, octane render, close-up portrait shot, intricate details, highly detailed, a 70mm portrait, iso 100, focus mode, f/100, locs, blasian, perfect composition, beautiful detailed intricate insanely detailed octane render artistic photography, concept art, cinematic perfect light, masterpiece',
        },
        {
          image: sample14,
          description:
            'cyberpunk Trooper warrior portrait, red eyes, Tribal tattoo on face, horn on the head, neon color, realistic pencil drawing, hyper-detailed, cinematic, oil painting style, HD',
        },
        {
          image: sample15,
          description:
            'blonde paladin, black and white, old man face, blue eyes, high details, cinematic lighting, moody, wrinkles in the face, uhq',
        },
        {
          image: sample16,
          description:
            'An epic portrait of an ancient warrior, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by WLOP, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
        },
      ],
    ],
    []
  );

  const sampleDescription = useMemo(
    () => [
      'gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',

      'wolf knight, finely detailed armor, intricate design, silver, silk, cinematic lighting, 4k 16K, professional color grading, soft shadows, high contrast, clean sharp focus, award-winning cinematic photography, 4k up light',

      'Goddess of nature, native American Indian, green vines and flowers with black flowing for hair, feathers, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',

      'beautiful female warrior wearing Dark Brotherhood armour , beautiful face, glowing eyes, epic war zone, full shot, intricate details, symmetrical, Todd Mcfarlane, Diego Dayer, Anna, Dittmann, Unreal 5, hyperrealistic, dynamic lighting, neon ambience, volumetric lighting, fantasy art',

      'robotic goddess, cyborg, ornate translucent glowing sapphire and amethyst and emerald and silver, impressionist painting on canvas, female android, flowers, in the style of Jean-Baptiste Monge:20 , outer space, vanishing point, super highway, high speed, digital render, digital painting, beeple, noah bradley, cyril roland, ross tran, trending on artstation',

      'Dark skin, handsome outlaw, futuristic gunslinger, sci-fi, white hero suit, bad boy, HD, dark background,  unreal engine, 8k',

      'beautiful horse, portrait, finely detailed armor, intricate design, silver, silk, cinematic lighting, 4k long hair, blue eyes, colorful synthwave neon',

      'cute dragon holding heart, stunning, not ugly, up lighting',

      'Viking, barbarian, warrior, glowing eyes, horned helmet, beard, angry face, high detail, inky',

      'A beautiful female super model, mixed media, wet paint, Jewel bug, color background, flowing liquid color down her face, shot by ZHANG JINGNA',

      'black woman, gorgeous cyberpunk hacker, wires cybernetic implants, in the style of adrian ghenie esao andrews jenny saville surrealism dark art by james jean takato yamamoto and blii sienkiewicz, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by greg rutkowski',

      'White lion, full fur, facing at the camera, very colorful, 8k, highly detailed synth wave neon',

      'character design of a handsome young warrior with armor, cel shading, stunning quality, octane render, close-up portrait shot, intricate details, highly detailed, a 70mm portrait, iso 100, focus mode, f/100, locs, blasian, perfect composition, beautiful detailed intricate insanely detailed octane render artistic photography, concept art, cinematic perfect light, masterpiece',

      'cyberpunk Trooper warrior portrait, red eyes, Tribal tattoo on face, horn on the head, neon color, realistic pencil drawing, hyper-detailed, cinematic, oil painting style, HD',

      'blonde paladin, black and white, old man face, blue eyes, high details, cinematic lighting, moody, wrinkles in the face, uhq',

      'An epic portrait of an ancient warrior, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by WLOP, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
    ],
    []
  );
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [imgNft, setImgNft] = useState('');
  const [descriptionNft, setDescriptionNft] = useState('');
  const [valueDropdown, setValueDropdown] = useState('');
  const [random, setRandom] = useState(0);
  const randomNumber = Math.floor(random * 16 + 1);

  const dropOptions = useMemo(
    () => [
      {
        value: '1',
        label: '1',
      },
      {
        value: '2',
        label: '2',
      },
      {
        value: '3',
        label: '3',
      },
      {
        value: '4',
        label: '4',
      },
    ],
    []
  );

  const handleValueDropdown = useCallback(
    (e) => {
      // setValueDropdown(e.value);
      setAiigCount(e.value);
    },
    [aiigCount]
  );

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  const handleShow = useCallback((nft, desc) => {
    setShow(true);
    setImgNft(nft);
    setDescriptionNft(desc);
  }, []);

  const handleClickSendPrompt = useCallback(() => {
    // setSeed('');
    setAiigSeed('');
    console.log('AQUI');
    setTimeout(() => {
      formRef.current?.submitForm();
    }, 100);
  }, []);

  const handleValueInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // setValueInput(e.target.value);
      setAiigPrompt(e.target.value);
    },
    [aiigPrompt]
  );

  const handleRandomDescription = useCallback(() => {
    setRandom(Math.random());

    if (randomNumber === 16) {
      // setValueInput(sampleDescription[0]);
      setAiigPrompt(sampleDescription[0]);
    } else {
      // setValueInput(sampleDescription[randomNumber]);
      setAiigPrompt(sampleDescription[randomNumber]);
    }
  }, [randomNumber, sampleDescription]);

  return (
    <Container
      ref={formRef}
      onSubmit={() => 'http://127.0.0.1:8000/ai/image/create'}
    >
      <div className="container">
        <div className="row">
          <div className="col-6 d-flex align-items-center surprise mt-5 mb-4">
            <p className="mb-0">Start with a detailed description </p>
            <button
              type="button"
              className="bg-surprise ms-4 px-3 py-2 rounded"
              onClick={handleRandomDescription}
            >
              Surprise me
            </button>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-end mt-5 mb-4 drop">
            <span>Number of images:</span>
            <div className="position-relative">
              <div className="d-flex z-index position-relative">
                <Dropdown
                  className="ms-3"
                  onChange={handleValueDropdown}
                  options={dropOptions}
                  placeholder="1"
                />
              </div>
              <span className="values-drop">
                {aiigCount === '1' ? 'image' : 'images'}
                {/* {valueDropdown === '1' ? 'image' : 'images'} */}
              </span>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          {sampleImage.map((cols) => (
            <div className="col-lg-3 sample zoom px-1">
              {cols.map((item) => (
                <div className="hover position-relative mb-2">
                  <img src={item.image} alt="Sample Image" className="w-100" />
                  <div className="d-flex flex-column justify-content-between align-items-end position-absolute p-4">
                    <button
                      type="button"
                      className="border-0 text-end"
                      onClick={() => {
                        // setValueInput(item.description);
                        setAiigPrompt(item.description);
                      }}
                    >
                      Try this
                    </button>

                    <p className="text-center">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}

          <div className="area-send position-fixed">
            <div className="d-flex mx-auto align-items-center zoom border-textarea">
              <Input
                name="prompt"
                className="w-100 border-0 bg-transparent ms-4 textarea"
                placeholder="Type anything you can dream here..."
                onChange={handleValueInput}
                // value={valueInput}
                value={aiigPrompt}
              />
              <button
                type="button"
                className="send d-flex py-3 me-5"
                onClick={onClickSearch}
              >
                <BsArrowReturnRight
                  className="me-2"
                  color="#c4c4c4"
                  size={18}
                />
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Examples;
