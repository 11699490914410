import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Container, Welcome, NameTable } from './styles';
import Table from '~/components/Table';

import api from '~/services/api';
import WalletRow from '~/components/WalletRow';
import { useAuth } from '~/hooks/Auth';
import Loading from '~/components/Loading';
import logoWhite from '~/assets/logo/logo-p-white.svg';

interface IReferralResponse {
  referrals: {
    total_count: number;
    referrals: {
      id: number;
      name: string;
      nft_img: string;
      rank: string;
      status: string;
      date_registered: number;
    }[];
  };
}

interface IReferral {
  id: number;
  name: string;
  nft_img: string;
  rank: string;
  status: string;
  date_registered: string;
}

const Referrers: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const [members, setMembers] = useState<IReferral[]>([]);

  useEffect(() => {
    api
      .get<IReferralResponse>(`/v1/member/referrals/${user?.id}`)
      .then((response) => {
        const data = response.data.referrals.referrals.map<IReferral>(
          (referral) => ({
            ...referral,
            date_registered: format(
              referral.date_registered * 1000,
              'MMM dd, yyyy'
            ),
          })
        );
        setMembers(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user]);

  const columns = useMemo(
    () => [
      {
        name: 'Member',
        selector: 'member',
        sortable: true,
        cell: (row: IReferral) => (
          <NameTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.nft_img} alt="teste" className="me-2 my-2" />
            <p className="mb-0">{row.name}</p>
          </NameTable>
        ),
      },
      {
        name: 'Member ID',
        selector: 'id',
        sortable: true,
        cell: (row: IReferral) => <p className="mb-0">{row.id}</p>,
      },
      {
        name: 'Rank',
        selector: 'rank',
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
      },
      {
        name: 'Date Registered',
        selector: 'date_registered',
        sortable: true,
      },
    ],
    []
  );

  const handleSearch = useCallback((value) => {
    setName(value);
  }, []);

  const handleChangeStartDate = useCallback((value) => {
    setStartDate(value);
  }, []);

  const handleChangeLastDate = useCallback((value) => {
    setEndDate(value);
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-sm-11 col-lg-12 mb-2 mt-4">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-center mb-4">
                  <div className="col-lg-6 px-0">
                    <h1 className="h3 h2-lg font-weight-bold mb-lg-0">
                      Referrals
                    </h1>
                  </div>
                  <div className="col-lg-6 px-0">
                    <WalletRow />
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-sm-11 col-lg-12">
            <div className="bg-gray table p-2 p-lg-4">
              <Table
                title="My Referrals"
                columns={columns}
                data={members}
                searchable
                onSearch={handleSearch}
                date
                initialDate={startDate}
                finalDate={endDate}
                onChangeStartDate={handleChangeStartDate}
                onChangeLastDate={handleChangeLastDate}
                exportable
                pagination
              />
            </div>
          </div>
        </div>
      </div>

      <Loading
        type="dark"
        srcImg={logoWhite}
        text="PLEASE WAIT..."
        active={loading}
      />
    </Container>
  );
};

export default Referrers;
