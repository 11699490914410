import React from 'react';
import { FiDownload } from 'react-icons/fi';
import { Container, BgImage } from './styles';

interface IProps {
  className: string;
}

const Downloads: React.FC<IProps> = ({ className }) => {
  const downloads = [
    { background: 'https://placeimg.com/1140/350/animals' },
    { background: 'https://placeimg.com/1140/350/animals' },
    { background: 'https://placeimg.com/1140/350/animals' },
    { background: 'https://placeimg.com/1140/350/animals' },
    { background: 'https://placeimg.com/1140/350/animals' },
    { background: 'https://placeimg.com/1140/350/animals' },
  ];

  return (
    <Container className={`container-fluid container-xl ${className}`}>
      <div className="row">
        <div className="h2">
          <div className="col-xxl-9">
            <div className="row">
              <div className="col-12">
                <h2 className="h5 fw-bold mb-4">Downloads</h2>
              </div>
              {downloads.map((downloadsSrc) => (
                <div className="col-sm-6 col-md-4 mb-4">
                  <BgImage
                    type="button"
                    src={downloadsSrc.background}
                    className="border-0 w-100 d-flex align-items-end"
                  >
                    <span className="h6 mb-0 py-3 px-4 w-100 d-flex align-items-center justify-content-between">
                      Download now <FiDownload size={25} color="#cbccce" />
                    </span>
                  </BgImage>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Downloads;
