import React, { useState, useEffect } from 'react';
import Typist from 'react-typist';
import { web3store } from '~/store';

import { Container } from './styles';

interface IText {
  type: 'dark' | 'white';
  text: string;
  srcImg: string;
  active: boolean;
  className?: string;
}

const Loading: React.FC<IText> = ({
  type,
  text,
  srcImg,
  className,
  active,
}) => {
  const [processText] = web3store.useState('processText');
  const [displayText, setDisplayText] = useState(text);
  useEffect(() => {
    if (processText.length > 0) {
      setDisplayText(processText);
    }
  }, [processText, displayText]);
  return (
    <Container type={type} active={active}>
      <div className={`loading-box ${className}`}>
        <div className="animation-loading position-relative mb-4">
          <div className="box-1" />
          <div className="box-2" />
          <img src={srcImg} alt="Logo" className="p-4" />
        </div>
        {active && (
          <>
            {displayText.startsWith('THE FUTURE IS HERE') ||
            displayText.startsWith('PLEASE WAIT...') ? (
              <Typist key={displayText} className="text-center">
                <span>{displayText}</span>
              </Typist>
            ) : (
              <Typist key={displayText} className="text-center">
                <span>{displayText}</span>
                <br />
                <small>
                  <i>(Please do not refresh or close this window)</i>
                </small>
              </Typist>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Loading;
