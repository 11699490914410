import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Banner, Card, Modal, Range } from './styles';
import Timeline from '~/components/Timeline';
import ResumContract from './ResumContract';
import ButtonBack from '~/components/ButtonBack';

import { formatPrice } from '~/utils/format';

import attention from '~/assets/icons/attention-icon.svg';

const Payment: React.FC = () => {
  const history = useHistory();
  const [value, setValue] = useState(100);
  const [activePayment] = useState(true);
  const [show, setShow] = useState(false);
  const [percentageBar, setPercentageBar] = useState(1);
  const [valueExample, setvalueExample] = useState(10000);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  const handleShow = useCallback(() => setShow(true), []);

  if (Number.isNaN(value)) {
    setValue(100);
  }

  if (value > 5000) {
    setValue(5000);
  }

  useEffect(() => {
    // const user_id = localStorage.getItem('hash_user');
    // api.get(`users/${user_id}`).then(async (response) => {
    //   if (response.data.step === 'como-fazer-parte') {
    //     await api.patch(`users/${user_id}`, {
    //       step: 'como-fazer-parte',
    //     });
    //   }
    // });

    switch (percentageBar) {
      case 1: {
        setvalueExample(10000);
        break;
      }
      case 2: {
        setvalueExample(50000);
        break;
      }
      case 3: {
        setvalueExample(100000);
        break;
      }
      case 4: {
        setvalueExample(250000);
        break;
      }
      case 5: {
        setvalueExample(500000);
        break;
      }
      case 6: {
        setvalueExample(1000000);
        break;
      }
      default: {
        // console.log('values');
      }
    }
  }, [percentageBar]);

  const steps = useMemo(
    () => [
      {
        value: 'Get Your Free NFT',
        active: true,
      },
      {
        value: 'How To Participate',
        active: true,
      },

      {
        value: 'Activate Your Profit Centers',
        active: true,
      },
      {
        value: 'Earn Even More!',
        active: true,
      },
      {
        value: 'Final Step',
        active: true,
      },
    ],
    []
  );

  return (
    <>
      <Banner className="position-relative overflow-hidden w-100">
        <div className="container-fluid">
          <div className="row vh-lg-100 overflow-auto">
            <div className="col-lg-2 px-0">
              <Timeline steps={steps} />
            </div>
            <div
              className={`col-lg-7 col-xl-8 py-lg-5 ${
                !activePayment && 'mx-auto'
              }`}
            >
              <div className="d-none d-lg-flex ps-4 mb-n5">
                <ButtonBack
                  onClick={() => {
                    history.push(`${process.env.PUBLIC_URL}/earn-even-more`);
                  }}
                />
              </div>
              <div className="row justify-content-center px-xl-5 mb-4 transition">
                <div className="col-xxl-9 zoom-resum text-center d-flex justify-content-center">
                  {/* <div className="align-items-center d-none d-lg-flex">
                    <button
                      type="button"
                      onClick={() =>
                        history.push(`${process.env.PUBLIC_URL}/earn-even-more`)
                      }
                      className="arrow d-flex align-items-center"
                    >
                      <BsArrowLeftShort size={30} />
                      <span className="mr-2 d-block">Back</span>
                    </button>
                  </div> */}
                  <div>
                    <h1 className="display-lg-4 fw-bold">
                      <span>
                        <span>Earn Even More!</span>
                      </span>
                    </h1>
                    <div className="row justify-content-center">
                      <div className="col-lg-11 col-xl-9 col-xxl-8">
                        <p className="gradient-parts">
                          <span>When you purchase a</span>{' '}
                          <b>staking contract</b>{' '}
                          <span>you earn a passive</span>{' '}
                          <b>60% APY from minting new tokens</b>{' '}
                          <span>as well as you qualify for</span>{' '}
                          <b>PROFITI Pool</b> <span>and participate in a</span>{' '}
                          <b>20% Revenue Share</b>{' '}
                          <span>from subscriptions</span>
                        </p>
                        <button
                          type="button"
                          className="border-0 bg-transparent btn-example"
                          onClick={handleShow}
                        >
                          <img src={attention} alt="Attention icon" /> See
                          Revenue Share Pool Example
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center pb-3 pb-lg-0 px-lg-5 transition">
                <Card className="col-sm-11 col-md-8 col-lg-7 col-xl-5  col-xxl-8 zoom-resum mt-3 mt-lg-0 px-4 px-lg-2 mb-5 mb-xl-0">
                  <div className="h-100 card-price max-wid best-price mx-auto">
                    <div className="p-3 py-4 w-100">
                      <h2 className="h3 h2-lg text-center text-white mb-4">
                        STAKING CONTRACT
                      </h2>
                      <div className="text-center value position-relative text-center justify-content-center">
                        <p className="mb-0">60%</p>
                        <span>APY</span>
                      </div>
                      <div className="d-flex text-center value-token position-relative text-center justify-content-center">
                        <span className="px-0 pt-4">$</span>
                        <input
                          type="number"
                          name="teste"
                          min={100}
                          max={5000}
                          onChange={(e) =>
                            setValue(parseInt(e.target.value, 10))
                          }
                          value={value}
                          className="input-text text-center w-75 w-sm-50 border-0"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-center mt-sm-n2 mb-5">
                        <input
                          type="range"
                          name="teste"
                          step={100}
                          min={100}
                          max={5000}
                          onChange={(e) =>
                            setValue(parseInt(e.target.value, 10))
                          }
                          value={value}
                          className="slider-pic w-75"
                        />
                      </div>
                      <ul className="ps-2">
                        <li>
                          <small>
                            Staking contracts mints new tokens to your wallet at
                            a rate of 5% per month, minimum contract amount is
                            $100
                          </small>
                        </li>
                      </ul>
                      {/* <button
                        type="button"
                        onClick={handleClick}
                        className="btn-gradient-light w-100 fw-bold"
                      >
                        BUY STAKING CONTRACT
                      </button> */}
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <ResumContract activeResum={activePayment} qtd={value} />
          </div>
        </div>
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
          className="modal-signin"
        >
          <button
            type="button"
            className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
            onClick={handleClose}
          >
            x
          </button>

          <Modal.Header className="container justify-content-center py-0 border-0">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="fw-bold mb-0">
                  Revenue Share Pool&nbsp;Example
                </h2>
              </div>
            </div>
          </Modal.Header>

          <Modal.Body className="container">
            <div className="row px-3 px-lg-5 mt-5 justify-content-center">
              <div className="col-lg-11 col-xl-8 mb-4">
                <div className="row">
                  <div className="col-lg-9">
                    <h3 className="mb-3">1. Total Subscribers</h3>
                    <Range
                      percentage={
                        percentageBar >= 4
                          ? (percentageBar - 1) * 19.7
                          : (percentageBar - 1) * 20.2
                      }
                      className="position-relative"
                    >
                      <>
                        <input
                          type="range"
                          name="teste"
                          min={1}
                          max={6}
                          value={percentageBar}
                          onChange={(e) =>
                            setPercentageBar(parseInt(e.target.value, 10))
                          }
                          className="slider-progress-bar"
                        />
                        <span className="thumb-progress-bar" />
                        {percentageBar > 1 && (
                          <div
                            className="progress-bar-centers"
                            role="progressbar"
                          />
                        )}
                      </>
                    </Range>
                    <div className="values-total-members network d-flex justify-content-between mt-3">
                      <span className="d-flex">
                        10<span className="d-flex d-lg-none">K</span>
                        <span className="d-none d-lg-flex">,000</span>
                      </span>
                      <span className="d-flex">
                        50<span className="d-flex d-lg-none">K</span>
                        <span className="d-none d-lg-flex">,000</span>
                      </span>
                      <span className="d-flex">
                        100<span className="d-flex d-lg-none">K</span>
                        <span className="d-none d-lg-flex">,000</span>
                      </span>
                      <span className="d-flex">
                        250<span className="d-flex d-lg-none">K</span>
                        <span className="d-none d-lg-flex">,000</span>
                      </span>
                      <span className="d-flex">
                        500<span className="d-flex d-lg-none">K</span>
                        <span className="d-none d-lg-flex">,000</span>
                      </span>
                      <span className="d-flex">
                        1<span className="d-flex d-lg-none">M</span>
                        <span className="d-none d-lg-flex">,000,000</span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-5 mt-lg-0">
                    <h6 className="mb-0 w-lg-100 text-center mt-lg-2">
                      Revenue at $50
                    </h6>
                    <div className="bg-value-revenue w-lg-100 d-flex align-items-center justify-content-center">
                      <div className="value-revenue text-center d-flex align-items-center justify-content-center">
                        <span className="mx-3">
                          ${formatPrice(valueExample * 50).slice(0, -3)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11 col-xl-8 my-5">
                <h3>2. Revenue Share Distribution to Members at&nbsp;20%</h3>
              </div>
              <div className="col-12 px-0 mt-lg-5">
                <div className="text-center bg-number py-3">
                  <span className="h1 fw-bold px-4 py-3 number">
                    ${formatPrice(valueExample * 10).slice(0, -3)}
                  </span>
                  <br />
                  <span className="h6 h4-lg d-block mt-4 color">
                    Distributed to members every month!
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 px-4 py-5" />
        </Modal>
      </Banner>
    </>
  );
};

export default Payment;
