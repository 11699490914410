import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { IoMdLogOut } from 'react-icons/io';
import { truncate } from 'truncate-ethereum-address';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlineLightMode } from 'react-icons/md';
import { useAuth } from '~/hooks/Auth';

import { web3store } from '~/store';
import ModalSwap from '../ModalSwap';
import { Container, BtnAvatar, Avatar, Button } from './styles';
import { formatPrice } from '~/utils/format';

import busd from '~/assets/logo/token-busd.png';
import usdt from '~/assets/logo/token-usdt.png';
import usdc from '~/assets/logo/token-usdc.png';
import gusd from '~/assets/logo/token-gusd.png';
import dai from '~/assets/logo/token-dai.png';
import check from '~/assets/icons/token-check.svg';
import ButtonBack from '../ButtonBack';

const WalletAvatar: React.FC = () => {
  const btnDisconnect = useRef<HTMLButtonElement>(null);
  const disconnectRef = useRef<HTMLDivElement>(null);
  const disconnectBalanceRef = useRef<HTMLDivElement>(null);
  const chooseTokenRef = useRef<HTMLDivElement>(null);
  const closeAddBusdRef = useRef<HTMLDivElement>(null);
  const closeAddUsdtRef = useRef<HTMLDivElement>(null);
  const closeAddUsdcRef = useRef<HTMLDivElement>(null);
  const closeAddGusdRef = useRef<HTMLDivElement>(null);
  const closeAddDaiRef = useRef<HTMLDivElement>(null);
  const { user, signOut } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [account] = web3store.useState('account');
  const [balusdpi] = web3store.useState('balusdpi');
  const [reference] = web3store.useState('reference');
  const [balance, setBalance] = useState(false);
  const [chooseToken, setChooseToken] = useState(false);
  const [disconnect, setDisconnect] = useState(false);
  const [imgURL] = web3store.useState('imgURL');
  const [nftimg] = web3store.useState('nftimg');
  const [avatar, setAvatar] = useState('');
  const [selected, setSelected] = useState('');
  const [addMetamask, setAddMetamask] = useState('');

  useEffect(() => {
    if (location.pathname.includes('get-your-free-nft')) {
      setAvatar(imgURL);
    } else if (user) {
      setAvatar(user.nft_img);
    } else if (nftimg) {
      setAvatar(nftimg);
    } else if (imgURL) {
      setAvatar(imgURL);
    }
  }, [imgURL, location.pathname, nftimg, user]);

  useEffect(() => {
    switch (selected) {
      case 'busd':
        setAddMetamask('busd');
        break;

      case 'usdt':
        setAddMetamask('usdt');
        break;
      case 'usdc':
        setAddMetamask('usdc');
        break;
      case 'gusd':
        setAddMetamask('gusd');
        break;
      case 'dai':
        setAddMetamask('dai');
        break;

      default:
        setAddMetamask('');
        break;
    }
  }, [selected]);

  const locationBar = [
    '/get-your-free-nft',
    '/how-to-participate',
    '/activate-your-profit-centers',
    '/activate-your-profit-centers/skip',
    '/earn-even-more',
    '/final-step',
    '/ai-avatar',
    '/what-to-expect',
    '/autoaffiliate-member/order',
  ];

  const handleNoAddMetamask = useCallback(() => {
    setTimeout(() => {
      setChooseToken(true);
      setSelected('');
    }, 100);
  }, []);

  const handleOutsideClick = useCallback(
    (e) => {
      const modalElement = document.querySelector('.modal-backdrop');
      if (
        disconnect &&
        disconnectRef.current &&
        e.target !== disconnectRef.current &&
        !disconnectRef.current.contains(e.target)
      ) {
        setDisconnect(false);
      }

      if (
        chooseToken &&
        chooseTokenRef.current &&
        e.target !== chooseTokenRef.current &&
        !chooseTokenRef.current.contains(e.target)
      ) {
        setChooseToken(false);
      }

      if (
        addMetamask === 'busd' &&
        closeAddBusdRef.current &&
        e.target !== closeAddBusdRef.current &&
        !closeAddBusdRef.current.contains(e.target)
      ) {
        handleNoAddMetamask();
      }

      if (
        addMetamask === 'usdt' &&
        closeAddUsdtRef.current &&
        e.target !== closeAddUsdtRef.current &&
        !closeAddUsdtRef.current.contains(e.target)
      ) {
        handleNoAddMetamask();
      }

      if (
        addMetamask === 'usdc' &&
        closeAddUsdcRef.current &&
        e.target !== closeAddUsdcRef.current &&
        !closeAddUsdcRef.current.contains(e.target)
      ) {
        handleNoAddMetamask();
      }

      if (
        addMetamask === 'gusd' &&
        closeAddGusdRef.current &&
        e.target !== closeAddGusdRef.current &&
        !closeAddGusdRef.current.contains(e.target)
      ) {
        handleNoAddMetamask();
      }

      if (
        addMetamask === 'dai' &&
        closeAddDaiRef.current &&
        e.target !== closeAddDaiRef.current &&
        !closeAddDaiRef.current.contains(e.target)
      ) {
        handleNoAddMetamask();
      }

      // console.log(modalElement);
      if (
        balance &&
        disconnectBalanceRef.current &&
        e.target !== disconnectBalanceRef.current &&
        !disconnectBalanceRef.current.contains(e.target)
      ) {
        if (
          !modalElement ||
          (e.target !== modalElement && modalElement?.contains(e.target))
        ) {
          setBalance(false);
        }
      }
    },
    [addMetamask, balance, chooseToken, disconnect, handleNoAddMetamask]
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
  }, [handleOutsideClick]);

  const balanceData = useMemo(() => {
    return {
      usdpi: formatPrice(
        parseFloat((parseInt(balusdpi, 10) / 10 ** 18).toFixed(2))
      ),
    };
  }, [balusdpi]);

  const handleBalance = useCallback(() => {
    setTimeout(() => {
      setBalance(!balance);
      setDisconnect(false);
    }, 100);
  }, [balance]);

  const handleDisconnect = useCallback(() => {
    setTimeout(() => {
      setBalance(false);
      setDisconnect(!disconnect);
    }, 100);
  }, [disconnect]);

  const handleChooseToken = useCallback(() => {
    setTimeout(() => {
      setChooseToken(!chooseToken);
      setBalance(!balance);
    }, 100);
  }, [balance, chooseToken]);

  const handleCoinSelected = useCallback((coin) => {
    setTimeout(() => {
      setSelected(coin);
      setChooseToken(false);
    }, 100);
  }, []);

  const handleExchange = useCallback(() => {
    window.open('https://usdpi.finance', '_blank');
  }, []);

  return (
    <Container className="zoom position-relative">
      <BtnAvatar
        type="button"
        data-tooltip="Disconnect Wallet"
        onClick={handleBalance}
        className="disconnect-tooltip d-flex align-items-center btn-avatar btn-avatar-wallet text-end"
        isFunnel={locationBar.includes(location.pathname)}
      >
        <div className="avatar d-flex align-items-center text-center rounded-circle">
          <Avatar src={avatar} className="rounded-circle" />
        </div>
        {location.pathname !== '/products/cube' && (
          <div className="d-flex flex-column">
            <span className="px-3 wallet-num">{truncate(account)}</span>
            {locationBar.includes(location.pathname) && (
              <div className="reference px-3 text-end">
                <span>
                  Invited by: <span>{reference}</span>
                </span>
              </div>
            )}
          </div>
        )}
      </BtnAvatar>

      {balance && (
        <div
          ref={disconnectBalanceRef}
          className="disconnect position-absolute p-4"
        >
          <div className="d-flex align-items-center">
            {user && (
              <div className="avatar d-flex align-items-center text-center rounded-circle">
                <Avatar src={user.nft_img} className="rounded-circle" />
              </div>
            )}
            <span className="px-3 wallet-num">{truncate(account)}</span>
            <button
              ref={btnDisconnect}
              type="button"
              data-tooltip="Disconnect Wallet"
              onClick={handleDisconnect}
              className="disconnect-tooltip btn-logout border-0 p-2 ms-auto"
            >
              <IoMdLogOut size={30} color="#FF1A50" />
            </button>
          </div>
          {!locationBar.includes(location.pathname) && (
            <>
              <h2 className="mt-4 mb-0 text-balance text-center">
                USDPI Balance
              </h2>
              <span className="d-block mb-4 text-center balance">
                {balanceData.usdpi}
              </span>
              <button
                type="button"
                onClick={() =>
                  history.push(`${process.env.PUBLIC_URL}/my-nfts`)
                }
                className="btn-view w-100 py-2 mb-2"
              >
                View my NFTs
              </button>

              {/* <ModalSwap
                btnText="Buy/Sell USDPI"
                className="w-100 btn-buy py-2 mb-2"
              /> */}

              <Button
                type="button"
                onClick={handleExchange}
                className="w-100 btn-buy py-2 mb-2"
              >
                Buy/Sell USDPI
              </Button>

              <hr />

              <button
                type="button"
                onClick={handleChooseToken}
                className="d-flex mb-3 btns-color justify-content-between w-100 border-0 bg-transparent"
              >
                <span>Add Tokens</span>
                <span>
                  <IoIosArrowDown size={20} color="#7d7777" />
                </span>
              </button>

              <button
                type="button"
                disabled
                title="coming soon"
                className="d-flex mb-3 btns-color justify-content-between w-100 border-0 bg-transparent"
              >
                <span>Transactions</span>
                <span>
                  <IoIosArrowDown size={20} color="#444" />
                </span>
              </button>

              <button
                type="button"
                disabled
                title="coming soon"
                className="d-flex mb-3 btns-color justify-content-between w-100 border-0 bg-transparent"
              >
                <span>Languages</span>
                <span>
                  EN <IoIosArrowDown size={20} color="#444" />
                </span>
              </button>

              <button
                type="button"
                disabled
                title="coming soon"
                className="d-flex btns-color justify-content-between w-100 border-0 bg-transparent"
              >
                <span>Light theme</span>
                <span>
                  <MdOutlineLightMode size={20} color="#444" />
                </span>
              </button>
            </>
          )}
        </div>
      )}

      {chooseToken && (
        <div ref={chooseTokenRef} className="token position-absolute p-4">
          <div className="title px-4 py-3 mb-4">Choose Token Below</div>
          <button
            type="button"
            onClick={() => handleCoinSelected('busd')}
            className="my-1 border-0 coin w-100 d-flex justify-content-between align-items-center px-3 py-2"
          >
            <span>
              <img src={busd} alt="busd" />
              &nbsp;BUSD
            </span>
            <img
              src={check}
              alt="check"
              className={`${
                selected === 'busd' ? 'opacity-100' : 'opacity-0'
              } check`}
            />
          </button>
          <button
            type="button"
            onClick={() => handleCoinSelected('usdt')}
            className="my-1 border-0 coin w-100 d-flex justify-content-between align-items-center px-3 py-2"
          >
            <span>
              <img src={usdt} alt="usdt" />
              &nbsp;USDT
            </span>
            <img
              src={check}
              alt="check"
              className={`${
                selected === 'usdt' ? 'opacity-100' : 'opacity-0'
              } check`}
            />
          </button>
          <button
            type="button"
            onClick={() => handleCoinSelected('usdc')}
            className="my-1 border-0 coin w-100 d-flex justify-content-between align-items-center px-3 py-2"
          >
            <span>
              <img src={usdc} alt="usdc" />
              &nbsp;USDC
            </span>
            <img
              src={check}
              alt="check"
              className={`${
                selected === 'usdc' ? 'opacity-100' : 'opacity-0'
              } check`}
            />
          </button>
          <button
            type="button"
            onClick={() => handleCoinSelected('gusd')}
            className="my-1 border-0 coin w-100 d-flex justify-content-between align-items-center px-3 py-2"
          >
            <span>
              <img src={gusd} alt="gusd" />
              &nbsp;GUSD
            </span>
            <img
              src={check}
              alt="check"
              className={`${
                selected === 'gusd' ? 'opacity-100' : 'opacity-0'
              } check`}
            />
          </button>
          <button
            type="button"
            onClick={() => handleCoinSelected('dai')}
            className="my-1 border-0 coin w-100 d-flex justify-content-between align-items-center px-3 py-2"
          >
            <span>
              <img src={dai} alt="dai" />
              &nbsp;DAI
            </span>
            <img
              src={check}
              alt="check"
              className={`${
                selected === 'dai' ? 'opacity-100' : 'opacity-0'
              } check`}
            />
          </button>
          <ButtonBack onClick={handleChooseToken} className="btn-back mt-5" />
        </div>
      )}

      {addMetamask === 'busd' && (
        <div ref={closeAddBusdRef} className="disconnect position-absolute p-4">
          <h2 className="mb-4 text-disconnect text-center">
            Add BUSD To MetaMask
          </h2>

          <button type="button" className="btn-yes-busd w-100 py-2 mb-2">
            Yes
          </button>
          <button
            type="button"
            onClick={handleNoAddMetamask}
            className="btn-no py-2  mb-2 w-100"
          >
            No
          </button>
        </div>
      )}

      {addMetamask === 'usdt' && (
        <div ref={closeAddUsdtRef} className="disconnect position-absolute p-4">
          <h2 className="mb-4 text-disconnect text-center">
            Add USDT To MetaMask
          </h2>

          <button type="button" className="btn-yes-usdt w-100 py-2 mb-2">
            Yes
          </button>
          <button
            type="button"
            onClick={handleNoAddMetamask}
            className="btn-no py-2  mb-2 w-100"
          >
            No
          </button>
        </div>
      )}

      {addMetamask === 'usdc' && (
        <div ref={closeAddUsdcRef} className="disconnect position-absolute p-4">
          <h2 className="mb-4 text-disconnect text-center">
            Add USDC To MetaMask
          </h2>

          <button type="button" className="btn-yes-usdc w-100 py-2 mb-2">
            Yes
          </button>
          <button
            type="button"
            onClick={handleNoAddMetamask}
            className="btn-no py-2  mb-2 w-100"
          >
            No
          </button>
        </div>
      )}

      {addMetamask === 'gusd' && (
        <div ref={closeAddGusdRef} className="disconnect position-absolute p-4">
          <h2 className="mb-4 text-disconnect text-center">
            Add GUSD To MetaMask
          </h2>

          <button type="button" className="btn-yes-gusd w-100 py-2 mb-2">
            Yes
          </button>
          <button
            type="button"
            onClick={handleNoAddMetamask}
            className="btn-no py-2  mb-2 w-100"
          >
            No
          </button>
        </div>
      )}

      {addMetamask === 'dai' && (
        <div ref={closeAddDaiRef} className="disconnect position-absolute p-4">
          <h2 className="mb-4 text-disconnect text-center">
            Add DAI To MetaMask
          </h2>

          <button type="button" className="btn-yes-dai w-100 py-2 mb-2">
            Yes
          </button>
          <button
            type="button"
            onClick={handleNoAddMetamask}
            className="btn-no py-2  mb-2 w-100"
          >
            No
          </button>
        </div>
      )}

      {disconnect && (
        <div ref={disconnectRef} className="disconnect position-absolute p-4">
          <h2 className="mb-4 text-disconnect text-center">
            Disconnect Wallet?
          </h2>

          <button
            type="button"
            onClick={signOut}
            className="btn-yes w-100 py-2 mb-2"
          >
            Yes
          </button>
          <button
            type="button"
            onClick={handleDisconnect}
            className="btn-no py-2  mb-2 w-100"
          >
            No
          </button>
        </div>
      )}
    </Container>
  );
};

export default WalletAvatar;
