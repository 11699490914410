import React, { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import { HiArrowNarrowRight } from 'react-icons/hi';
import stakingTerms from '~/assets/animations/staking-terms-details.json';
import { Modal, ModalBefore } from './styles';
import { web3store } from '../../store';
import ModalSwap from '~/components/ModalSwap';

import tokensClaimed from '~/assets/defaults/tokens-claimed.png';
import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';

interface IShow {
  showModal?: boolean;
  handleClose?(): void;
}

const ModalHowBillingWorks: React.FC<IShow> = ({ showModal, handleClose }) => {
  const [show, setShow] = useState(false);
  // const handleClose = useCallback(() => {
  //   setShow(false);
  // }, []);

  return (
    <>
      <Modal
        size="xl"
        show={showModal}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-3 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={tokensClaimed} alt="How billing works" />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mt-n3 mb-4 fw-bold text-center w-100">
            How billing works
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-left my-5 px-xl-2">
              Your subscription bills get paid automatically by the system
              whenever you have enough USDPI in your account. These payments
              happen every day at midnight UTC. You can check the payment status
              on your subscription page.
            </p>
          </div>
          <div className="texts w-75 mx-auto">
            <div className="text-left my-5 px-xl-2">
              <p>
                If your account doesn't have enough funds, you have a few
                options:
              </p>
              <ul className="mt-4">
                <li>
                  You can claim pending USDPI rewards from Profiti and
                  Performance pools on the Token Vault page.
                </li>
                <li>You can exchange USDT for USDPI on USDPI.finance.</li>
                <li>
                  You can buy USDPI directly using a debit card through our
                  payment partner, C14.money.
                </li>
                <li>
                  You can deposit USDPI directly to your account from your
                  crypto wallet.
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="texts w-75 mx-auto">
            <p className="text-left my-5 px-xl-2">
              If your account doesn't have enough funds, you have a few options:
              <ul className="mt-4">
                <li>
                  You can claim pending USDPI rewards from Profiti and
                  Performance pools on the Token Vault page.
                </li>
                <li>You can exchange USDT for USDPI on USDPI.finance.</li>
                <li>
                  You can buy USDPI directly using a debit card through our
                  payment partner, C14.money.
                </li>
                <li>
                  You can deposit USDPI directly to your account from your
                  crypto wallet.
                </li>
              </ul>
            </p>
          </div> */}
          <div className="texts w-75 mx-auto">
            <p className="text-left my-5 px-xl-2">
              Once your bill is paid, you can withdraw any remaining rewards and
              commissions from your dashboard's wallet page.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>
    </>
  );
};

export default ModalHowBillingWorks;
