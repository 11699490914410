import styled from 'styled-components';

interface CopyAlertProps {
  active: boolean;
}

export const CopyButton = styled.button`
  .bg-light-gray {
    background: #333638;
  }

  @media screen and (max-width: 400px) {
    .size-mobile {
      font-size: 3vw;
    }
  }

  @media (min-width: 992px) and (max-width: 1350px) {
    .height-mob {
      font-size: 0.8vw;
    }
  }
`;

export const CopyAlert = styled.div<CopyAlertProps>`
  background: #37c694;
  position: fixed;
  transition-duration: 0.3s;
  bottom: 10px;
  right: ${(props) => (props.active ? '10px' : '-1000px')};
  opacity: ${(props) => (props.active ? '1' : '0')};
  z-index: 100;

  p {
    color: #fdfdfd !important;
  }
`;
