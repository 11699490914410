import styled from 'styled-components';
import thumb from '~/assets/icons/thumb.svg';

interface IBoxNft {
  show: boolean;
  positionX: number;
  positionY: number;
}

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  .bg-gray {
    background: #1d2023;
    border-radius: 20px;

    .border-right {
      border-color: #393c3d !important;
    }

    h2 {
      color: #dadada;
    }

    .input {
      border: 1.5px solid #393c3d;
      box-sizing: border-box;
      border-radius: 15px;
      height: 40px;

      input {
        color: #efefef !important;
      }
    }

    button.btn-gradient {
      border-radius: 15px;
    }
  }

  .table {
    .rdt_TableHeadRow,
    .rdt_TableRow {
      > div:nth-child(2),
      > div:nth-child(4) {
        max-width: 175px;
        width: 175px;
      }
    }
  }

  .contact {
    cursor: pointer;

    input {
      color: #8c8c8c;
      font-size: 13px;
      background-color: transparent;
      border: none;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #606060;
    span {
      color: #dadada;
    }
  }

  p {
    color: #969696;
  }

  button.border-gradient {
    height: 40px;
    padding: 1px;
  }

  .btn-group {
    background-color: #4b4c4c;
    position: relative;

    .btn-background {
      display: block;
      position: absolute;
      height: 35px;
      width: 152px;
      background: linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      );
      border: 2px solid #d7d7d7;
      transition-duration: 0.3s;
    }

    .btn-background.right {
      left: 47%;
    }

    .btn-background.left {
      left: 0;
    }

    .btn-check {
      border: none;
      background: transparent;
      color: #bdbdbd;
      height: 35px;
      width: 152px;
      position: relative;
      z-index: 1;
      transition-duration: 0.3s;
    }

    .btn-check.active {
      color: #17171a;
    }
  }
`;

export const BoxMatrix = styled.div`
  .overdue-payment {
    border: 2px solid #ff1a50 !important;
  }

  .outstanding-payment {
    border: 2px solid #fbff4d !important;
  }

  .border-gray {
    border-radius: 90px;
    border: 1px solid #333;
    padding: 4px 0px;
    input {
      color: #8c8c8c;
    }
    input::placeholder {
      text-align: center;
      font-size: 14px;
    }
  }

  .avatar {
    width: 50px;
    height: 50px;
    padding: 2px !important;
    cursor: unset;
  }
  .border-gradient:not(img) {
    padding: 1px;
  }
  .h-line {
    border-right: solid 1.5px #8c8c8c;
    height: 40px;
  }
  .text-light-gray {
    color: #cbccce;
  }

  .button-group {
    transition-duration: 0.3s;
  }

  .button-group .hide {
    opacity: 0;
    visibility: hidden;
  }

  .text-green {
    color: #96ffd9;
  }

  .btn-actions {
    height: 40px;
    width: 150px;
  }

  .box-top-user {
    background: #222527;
    border: 1px solid #5e5b5b;
    box-sizing: border-box;
    box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
    border-radius: 50px;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .box-sub-user {
    background: #222527;
    border: 1px solid #5d5b5b;
    box-sizing: border-box;
    box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
    font-size: 90%;
    transition-duration: 0.3s;
    min-height: 50px;

    .default-avatar {
      width: 40px;
      height: 40px;
      background: #222527;
      border: 1px solid #5d5b5b;
      border-radius: 50%;
    }

    .w-custom {
      width: 80px;
      p {
        font-size: 12px;
      }
    }

    img {
      width: 40px;
      height: 40px;
    }

    :hover {
      border: 1px solid #cbccce;
    }
  }

  .box-user {
    width: 35px;
    height: 35px;
    background: #222527;
    border: 1px solid #5d5b5b;
    box-sizing: border-box;
    transition-duration: 0.3s;

    img {
      width: 36px;
      height: 36px;
    }

    :hover {
      border: 1px solid #cbccce;
    }
  }

  svg {
    width: 17px;
    height: 17px;
  }

  @media screen and (min-width: 1400px) {
  }

  @media screen and (min-width: 1200px) {
    .w-xl-20 {
      width: 20%;
    }
  }

  @media screen and (max-width: 767px) {
    .box-top-user {
      border-radius: 24px;
    }
  }
`;

export const BoxNft = styled.div<IBoxNft>`
  position: absolute;
  top: ${(props) => props.positionY}px;
  left: ${(props) => props.positionX}px;
  z-index: 2000;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition: opacity 0.3s, visibility 0.3s;

  width: 344px;
  background: #222527;
  border-radius: 20px;

  .pay-amount {
    background: #6d50d0;
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
  }

  .message {
    background: transparent;
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    border: solid 1px #3778ff;
  }

  .pay-amount,
  .message {
    transition-duration: 0.3s;
    :hover {
      opacity: 0.9;
    }
  }

  .qualified {
    font-weight: 600;
    color: #dadada;
  }

  .bsPlus-border {
    border-radius: 11px;
    border: solid 1.5px #373738;
  }

  .wallpaper {
    margin-bottom: -72px;
  }

  .avatar-nft {
    width: 70px;
    height: 70px;
    border-radius: 22px;
    cursor: unset;
  }

  .img-nft {
    border-radius: 20px;
  }

  p {
    font-size: 24px;
    line-height: 22px;
    color: #cbccce;
  }

  .nft-name-selected {
    font-size: 18px;
    line-height: 22px;
    color: #dadada;
  }

  .nft-name,
  .new-member {
    color: #dadada;
    padding: 8px 22px;
    border-radius: 10px;
    background: rgba(13, 13, 13, 0.28);
    backdrop-filter: blur(2px);
  }

  .nft-name {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }
  .new-member {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .p-box {
    border: 1px solid #373738;
    border-radius: 16px;
    padding: 12px;
    span {
      width: max-content;
    }
  }
`;

export const BoxNewNft = styled.div<IBoxNft>`
  position: absolute;
  top: ${(props) => props.positionY}px;
  left: ${(props) => props.positionX}px;
  z-index: 2000;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition: opacity 0.3s, visibility 0.3s;

  width: 344px;
  background: #222527;
  border-radius: 20px;

  h2 {
    color: #dadada;
    text-align: center;
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 300;
  }

  .pay-amount {
    background: #6d50d0;
    border-radius: 16px;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    border: solid 1px #6d50d0;
  }

  .sign-up {
    border-radius: 16px;
    border: 1px solid #3778ff;
    color: #fff;
    background-color: transparent;
    font-family: 'Inter';
    font-size: 20px;

    font-weight: 700;
  }

  .pay-amount {
    transition-duration: 0.3s;
    :hover {
      opacity: 0.9;
    }
  }

  .btn-next {
    background: #6d50d0;
    border-radius: 16px;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    border: solid 1px #6d50d0;
    padding: 12px 20px;
  }

  .btn-cancel {
    color: #dadada;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    border-radius: 18px;
    background: #0f0f0f;
    border: solid 1px #0f0f0f;
    padding: 12px 20px;
  }

  .qualified {
    font-weight: 600;
    color: #dadada;
  }

  .bsPlus-border {
    border-radius: 11px;
    border: solid 1.5px #6d50d0;
  }

  .wallpaper {
    margin-bottom: -83px;
  }

  .avatar-nft {
    width: 70px;
    height: 70px;
    border-radius: 22px;
    cursor: unset;
  }

  .img-nft {
    border-radius: 20px;
  }

  .enter-infos {
    border-radius: 16px;
    border: 1px solid #373738;
  }

  .enter-infos .height {
    height: 60px !important;
  }

  p {
    font-size: 24px;
    line-height: 22px;
    color: #cbccce;
  }

  .nft-name-selected {
    font-size: 18px;
    line-height: 22px;
    color: #dadada;
  }

  .nft-name,
  .new-member {
    color: #fff;
    padding: 8px 19px;
    border-radius: 10px;
    background: rgba(13, 13, 13, 0.28);
    backdrop-filter: blur(2px);
  }

  .nft-name {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }
  .new-member {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
  }

  .p-box {
    border: 1px solid #373738;
    border-radius: 16px;
    padding: 12px;
    span {
      width: max-content;
    }
  }

  .value-token {
    span {
      left: 0;
      top: 20px;
      font-weight: bold !important;
      font-size: 2rem;
      color: #e9e9e9;
    }
    p {
      font-size: 72px;
      font-weight: 600 !important;
      color: #e9e9e9;
      line-height: normal;
    }
  }

  .slider-pic {
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    height: 2px;
    border-radius: 2px;
    background: #595757;
    outline: none;
    opacity: 1;
  }

  .slider-pic::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    border: 0;
    background: url(${thumb});
    background-position: center;
    background-size: cover;
    cursor: pointer;
  }
  .text-change {
    color: #827777;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 125%;
  }
  .eth-amt-wrap {
    width: 227px;
    padding: 10px;
    border-radius: 20px;
    background: #1c1c1c;
    .inner {
      .txt {
        display: flex;

        color: #7d7777;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: top;
`;
