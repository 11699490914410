import React, { useState, useCallback } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { FiLock } from 'react-icons/fi';

import { Form } from '@unform/web';
import { Container, Navigator, Modal } from './styles';

import AiCreatorTitle from '~/components/AiCreatorTitle';
import ButtonBack from '~/components/ButtonBack';
import Private from './Private';
import Public from './Public';
import Input from '~/components/Input';

const NftCollections: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  return (
    <>
      <AiCreatorTitle />
      <Container>
        <div className="container">
          <div className="row">
            <Navigator className="col-12 my-5">
              <NavLink
                to={`${process.env.PUBLIC_URL}/products/ai-art/collections/all`}
                activeClassName="active pb-1"
                className="me-4"
                exact
              >
                All collections
              </NavLink>

              <NavLink
                to={`${process.env.PUBLIC_URL}/products/ai-art/collections/private`}
                activeClassName="active pb-1"
                className="me-4"
              >
                Private
              </NavLink>

              <NavLink
                to={`${process.env.PUBLIC_URL}/products/ai-art/collections/public`}
                activeClassName="active pb-1"
              >
                Public
              </NavLink>
            </Navigator>
          </div>
        </div>
        {(location.pathname === '/products/ai-art/collections/private' ||
          location.pathname === '/products/ai-art/collections/all') && (
          <Private onClick={handleShow} />
        )}
        {(location.pathname === '/products/ai-art/collections/public' ||
          location.pathname === '/products/ai-art/collections/all') && (
          <Public onClick={handleShow} />
        )}
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        className="modal-wrong-network"
      >
        <Form onSubmit={() => '/'}>
          <button
            type="button"
            className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
            onClick={handleClose}
          >
            x
          </button>
          <Modal.Header className="justify-content-center border-0 px-5 pb-0 pb-lg-4 pt-5">
            <div className="mt-4 d-flex align-items-center justify-content-start overflow-hidden w-100 px-4">
              <Modal.Title className="pe-3 fw-semibold">
                Create collection
              </Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body className="px-4 px-sm-5">
            <label className="d-block w-100 px-4">
              <span className="d-block mb-3">Name</span>
              <Input
                name="fund"
                placeholder="e.g. How I see the world"
                className="input"
              />
            </label>
            <div className="form-check form-switch d-flex justify-content-between mt-5 px-4">
              <p className="mb-0">Make this collection private</p>
              {/* <label
                className="form-check-label"
                htmlFor="flexSwitchCheckDefault"
              >
                Make this collection private
              </label> */}
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 px-5 py-4 mb-5 justify-content-between">
            <button
              type="button"
              className="btn btns ms-4"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button type="button" className="btn btns create me-4">
              Create collection
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default NftCollections;
