import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@animxyz/core';
import 'react-dropdown/style.css';

import Routes from '~/routes';
import GlobalStyles from './styles/global';
import Web3Stats from './components/Web3Stats';

import AppProvider from './hooks';

const App: React.FC = () => {
  return (
    <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
      <BrowserRouter>
        <AppProvider>
          <Routes />
          <Web3Stats />
        </AppProvider>
        <GlobalStyles />
      </BrowserRouter>
    </HttpsRedirect>
  );
};

export default App;
