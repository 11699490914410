import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { Modal, ImgShared, BoxShared } from './styles';

import { useAuth } from '~/hooks/Auth';
import { web3store } from '~/store';

import like from '~/assets/icons/like-icon.svg';
import bookmark from '~/assets/icons/bookmark-icon.svg';
import download from '~/assets/icons/download-icon.svg';
import share from '~/assets/icons/share-icon.svg';
import sample1 from '~/assets/defaults/sample_1.png';
import aiArtCreatorShared from '~/assets/logo/ai-art-creator-shared.svg';
import bgWaves from '~/assets/defaults/background-waves.png';
import bgCube from '~/assets/defaults/background-cube.png';
import bgRetangles from '~/assets/defaults/background-retangles.png';
import bgBubble from '~/assets/defaults/background-bubble.png';
import bgPurpleWaves from '~/assets/defaults/background-purple-waves.png';
import bgBlur from '~/assets/defaults/background-blur.png';
import bgDarkblur from '~/assets/defaults/background-darkblur.png';
import bgDark from '~/assets/defaults/background-dark.png';
import qrCode from '~/assets/defaults/qr-code-nft.svg';
import instagram from '~/assets/icons/instagram-share.svg';
import facebook from '~/assets/icons/face-share.svg';
import tiktok from '~/assets/icons/tiktok-share.svg';
import twiter from '~/assets/icons/twiter-share.svg';
import downloadShare from '~/assets/icons/download-modal-share.svg';
import chainShare from '~/assets/icons/chain-modal-share.svg';

import bgLargeWaves from '~/assets/defaults/bg-waves-large.png';
import bgMidWaves from '~/assets/defaults/bg-waves-mid.png';
import bgHorizontalWaves from '~/assets/defaults/bg-waves-horizontal.png';

import bgLargeCubes from '~/assets/defaults/bg-cubes-large.png';
import bgMidCubes from '~/assets/defaults/bg-cubes-mid.png';
import bgHorizontalCubes from '~/assets/defaults/bg-cubes-horizontal.png';

import bgLargeRetangles from '~/assets/defaults/bg-retangles-large.png';
import bgMidRetangles from '~/assets/defaults/bg-retangles-mid.png';
import bgHorizontalRetangles from '~/assets/defaults/bg-retangles-horizontal.png';

import bgLargeBubble from '~/assets/defaults/bg-bubble-large.png';
import bgMidBubble from '~/assets/defaults/bg-bubble-mid.png';
import bgHorizontalBubble from '~/assets/defaults/bg-bubble-horizontal.png';

import bgLargePurpleWaves from '~/assets/defaults/bg-purple-waves-large.png';
import bgMidPurpleWaves from '~/assets/defaults/bg-purple-waves-mid.png';
import bgHorizontalPurpleWaves from '~/assets/defaults/bg-purple-waves-horizontal.png';

import bgLargeBlur from '~/assets/defaults/bg-blur-large.png';
import bgMidBlur from '~/assets/defaults/bg-blur-mid.png';
import bgHorizontalBlur from '~/assets/defaults/bg-blur-horizontal.png';

import bgLargeDarkBlur from '~/assets/defaults/bg-dark-blur-large.png';
import bgMidDarkBlur from '~/assets/defaults/bg-dark-blur-mid.png';
import bgHorizontalDarkBlur from '~/assets/defaults/bg-dark-blur-horizontal.png';

import bgLargeDark from '~/assets/defaults/bg-dark-large.png';
import bgMidDark from '~/assets/defaults/bg-dark-mid.png';
import bgHorizontalDark from '~/assets/defaults/bg-dark-horizontal.png';

import aiLogo from '~/assets/logo/ai-logo.svg';
import aiLogoText from '~/assets/logo/ai-logo-text.svg';

import logoBlack from '~/assets/logo/logo-p-black.png';
import bookmarkAct from '~/assets/icons/bookmark-icon-active.svg';
import ButtonDownload from '../ButtonDownload';

interface IDownloadShare {
  show: boolean;
  selectImg: string;
  description: string;
  onCloseShare(): void;
}

const ModalDownloadShare: React.FC<IDownloadShare> = ({
  show,
  selectImg,
  description,
  onCloseShare,
}) => {
  const { user } = useAuth();
  const [reference] = web3store.useState('reference');
  const div1920Ref = useRef<HTMLDivElement>(null);
  const div1350Ref = useRef<HTMLDivElement>(null);
  const div1200Ref = useRef<HTMLDivElement>(null);
  const [cardIndex, setCardIndex] = useState(0);
  const [showShared, setShowShared] = useState(false);
  const [showImageSize, setShowImageSize] = useState(false);
  const [selectImgNft, setSelectImgNft] = useState('');

  const referralCode = useMemo(() => {
    return user?.referral_link;
  }, [user]);

  const code = referralCode?.split('/')[4];

  const cardShare = useMemo(
    () => [
      {
        background: bgWaves,
        nft: selectImgNft,
        qrCode,
        referrer: reference,
      },
      {
        background: bgCube,
        nft: selectImgNft,
        qrCode,
        referrer: reference,
      },
      {
        background: bgPurpleWaves,
        nft: selectImgNft,
        qrCode,
        referrer: reference,
      },
      {
        background: bgBubble,
        nft: selectImgNft,
        qrCode,
        referrer: reference,
      },
      {
        background: bgRetangles,
        nft: selectImgNft,
        qrCode,
        referrer: reference,
      },
      {
        background: bgBlur,
        nft: selectImgNft,
        qrCode,
        referrer: reference,
      },
      {
        background: bgDarkblur,
        nft: selectImgNft,
        qrCode,
        referrer: reference,
      },
      {
        background: bgDark,
        nft: selectImgNft,
        qrCode,
        referrer: reference,
      },
    ],
    [reference, selectImgNft]
  );

  const bgSizes = useMemo(
    () => [
      {
        large: bgLargeWaves,
        mid: bgMidWaves,
        horizontal: bgHorizontalWaves,
      },
      {
        large: bgLargeCubes,
        mid: bgMidCubes,
        horizontal: bgHorizontalCubes,
      },
      {
        large: bgLargePurpleWaves,
        mid: bgMidPurpleWaves,
        horizontal: bgHorizontalPurpleWaves,
      },
      {
        large: bgLargeBubble,
        mid: bgMidBubble,
        horizontal: bgHorizontalBubble,
      },

      {
        large: bgLargeRetangles,
        mid: bgMidRetangles,
        horizontal: bgHorizontalRetangles,
      },
      {
        large: bgLargeBlur,
        mid: bgMidBlur,
        horizontal: bgHorizontalBlur,
      },
      {
        large: bgLargeDarkBlur,
        mid: bgMidDarkBlur,
        horizontal: bgHorizontalDarkBlur,
      },
      {
        large: bgLargeDark,
        mid: bgMidDark,
        horizontal: bgHorizontalDark,
      },
    ],
    []
  );

  const handleClickShared = useCallback(() => {
    setShowShared(true);
  }, []);

  const handleCloseShared = useCallback(() => {
    setShowShared(false);
  }, []);

  const handleClickImageSize = useCallback((index) => {
    setShowImageSize(true);
    setCardIndex(index);
  }, []);

  const handleCloseImageSize = useCallback(() => {
    setShowImageSize(false);
  }, []);

  const handleClickDownloadImage = useCallback((size) => {
    if (div1920Ref.current && div1350Ref.current && div1200Ref.current) {
      let element: HTMLDivElement | undefined;
      let sizeObject = {
        width: 0,
        height: 0,
      };

      switch (size) {
        case 1920:
          element = div1920Ref.current;
          sizeObject = {
            width: 1080,
            height: 1920,
          };
          break;
        case 1350:
          element = div1350Ref.current;
          sizeObject = {
            width: 1080,
            height: 1350,
          };
          break;

        default:
          element = div1200Ref.current;
          sizeObject = {
            width: 1200,
            height: 675,
          };
          break;
      }

      const scale = Math.max(
        sizeObject.width / element.offsetWidth,
        sizeObject.height / element.offsetHeight
      );

      html2canvas(element, { scale }).then((canvas) => {
        const url = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = url;
        link.download = `my-image-${sizeObject.width}x${sizeObject.height}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }, []);

  useEffect(() => {
    axios
      .get(selectImg, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        setSelectImgNft(
          `data:image/png;base64, ${Buffer.from(
            response.data,
            'binary'
          ).toString('base64')}`
        );
      });
  }, [selectImg]);

  return (
    <>
      <Modal
        className="modal-nft text-white d-flex"
        size="xl"
        show={show}
        onHide={onCloseShare}
      >
        <Modal.Header className="border-0 pb-0">
          <button
            type="button"
            className="modal-close ms-auto fw-bold border-0 bg-transparent"
            onClick={onCloseShare}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body className="padding-modal">
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 text-center position-relative" />

              <div className="col-6 pe-5 position-relative">
                <img
                  src={selectImgNft}
                  alt="Logo fox"
                  className="w-100 img-nft "
                />
                <button
                  type="button"
                  className="border-0 bg-transparent btn-like"
                >
                  <img src={like} alt="Like" />
                </button>
                <button
                  type="button"
                  className="border-0 bg-transparent btn-dislike"
                >
                  <img src={like} alt="Like" />
                </button>
              </div>

              <div className="col-6 text-center">
                <div className="right-side p-5 h-100 d-flex flex-column justify-content-between">
                  <p className="mt-4">{`“${description}”`}</p>
                  <div className="d-flex justify-content-around btns-share">
                    <div className="text-center">
                      <ButtonDownload img={selectImg} className="d-flex">
                        <img src={download} alt="Download" />
                      </ButtonDownload>
                      Download
                    </div>
                    <div className="text-center">
                      <button type="button">
                        <img
                          src={selectImg.includes('-') ? bookmark : bookmarkAct}
                          alt="Bookmark"
                        />
                      </button>
                      <br />
                      Collection
                    </div>
                    <div className="text-center">
                      <button type="button" onClick={handleClickShared}>
                        <img src={share} alt="share" />
                      </button>
                      <br />
                      Share
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn-launchpad mt-4 py-3 border-0 fw-bold w-100"
                  >
                    Mint With NFT Launchpad
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="modal-nft choose-nft text-white d-flex"
        size="xl"
        show={showShared}
        onHide={handleCloseShared}
      >
        <Modal.Header className="ms-auto border-0 pb-0">
          <button
            type="button"
            className="modal-close ms-auto fw-bold border-0 bg-transparent"
            onClick={handleCloseShared}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body className="padding-modal">
          <h2 className="title-choose text-center mt-n2 mb-5">
            Choose the image background
          </h2>
          <div className="row justify-content-center">
            {cardShare.map((item, index) => (
              <div className="col-lg-3 mb-4">
                <BoxShared
                  background={item.background}
                  className="position-relative"
                >
                  <button
                    type="button"
                    className="h-100 w-100 position-absolute final-image"
                    onClick={() => handleClickImageSize(index)}
                  >
                    Click to view
                    <br />
                    final image
                  </button>
                  <img
                    src={aiArtCreatorShared}
                    alt="aiArtCreatorShared"
                    className="ms-2 mb-2 logo"
                  />
                  <div className="nft">
                    <div>
                      <img src={selectImgNft} alt="imgNft" />
                      <div className="details ">
                        <p className="fw-medium mb-0 text-center">
                          Visit to create yours{' '}
                          <span className="fw-semibold">
                            <b className="text-white">FREE</b>
                          </span>
                        </p>
                        <p className="fw-bold mb-1 text-center">
                          <span className="d-block">
                            {`profiti.io/try/i/${code}`}
                          </span>
                        </p>
                        {/* <div className="bg-qr-code">
                          <img src={item.qrCode} alt="qrCode" />
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between footer">
                    <span className="text-end mb-0 mt-2 ms-2 small">
                      {`profiti.io/try/i/${code}`}
                    </span>
                    <span className="text-end mb-0 mt-2 me-2 small">
                      #profitiai
                    </span>
                  </div>
                </BoxShared>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-nft choose-nft text-white d-flex"
        size="xl"
        show={showImageSize}
        onHide={handleCloseImageSize}
      >
        <Modal.Header className="ms-auto border-0 pb-0">
          <button
            type="button"
            className="modal-close ms-auto fw-bold border-0 bg-transparent"
            onClick={handleCloseImageSize}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body className="padding-modal">
          <h2 className="title-choose text-center mt-n2 mb-5">
            Choose the image size
          </h2>
          <div className="row justify-content-around align-items-end">
            {bgSizes.map((bg, index) => (
              <>
                {cardIndex === index && (
                  <>
                    <div className="col-3">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src={instagram}
                          alt="Logo instagram"
                          className="mx-2"
                        />
                        <img
                          src={facebook}
                          alt="Logo facebook"
                          className="mx-2"
                        />
                        <img src={tiktok} alt="Logo tiktok" className="mx-2" />
                      </div>
                      <div className="image-size position-relative">
                        <div className="btns-down-share position-absolute d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            onClick={() => handleClickDownloadImage(1920)}
                          >
                            <img
                              src={downloadShare}
                              title="Download"
                              alt="Download"
                            />
                          </button>
                          <button type="button">
                            <img src={chainShare} title="Share" alt="Chain" />
                          </button>
                        </div>
                        <div className="header-img d-flex align-items-center">
                          <div />
                          <div />
                        </div>
                        <div ref={div1920Ref} className="position-relative">
                          <div>
                            <img src={bg.large} alt="Large" className="w-100" />
                            <div className="position-absolute text-ai">
                              <div className="d-flex align-items-center ps-3 pt-4">
                                <img
                                  src={aiLogo}
                                  alt=""
                                  className="logo-ai-large me-2"
                                />
                                <img
                                  src={aiLogoText}
                                  alt=""
                                  className="logo-ai-text-large"
                                />
                              </div>
                              <ImgShared className="position-relative">
                                <div className="nft margin-nft-large">
                                  <div>
                                    <img
                                      src={selectImgNft}
                                      alt="imgNft"
                                      crossOrigin="anonymous"
                                    />
                                    <div className="details text-center">
                                      <p className="fw-medium mb-0 scan-large">
                                        <span className="d-block fw-semibold">
                                          Visit to create yours{' '}
                                          <b className="text-white">FREE</b>
                                        </span>
                                      </p>
                                      <p className="scan-large fw-bold mb-1">
                                        <span>{`profiti.io/try/i/${code}`}</span>
                                      </p>
                                      {/* <div className="bg-qr-code qr-code-large">
                                        <QRCode
                                          value={`https://profiti.io/try/i/${code}`}
                                          size={44}
                                          bgColor="transparent"
                                          renderAs="svg"
                                          level="H"
                                          imageSettings={{
                                            src: logoBlack,
                                            height: 13,
                                            width: 13,
                                            excavate: true,
                                          }}
                                        />
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between footer px-3">
                                  <span className="text-end mb-0 mt-1 ms-2 small">
                                    {`profiti.io/try/i/${code}`}
                                  </span>
                                  <span className="text-end mb-0 mt-1 me-2 small">
                                    #profitiai
                                  </span>
                                </div>
                              </ImgShared>
                            </div>
                          </div>
                        </div>
                        <div className="footer-img d-flex align-items-center px-2">
                          <div className="mx-1 my-2" />
                          <div className="mx-1 my-2" />
                          <div className="mx-1 my-2" />
                          <div className="ms-auto me-1" />
                        </div>
                      </div>
                      <div className="text-size text-center mt-4">
                        <span>1080x1920</span>
                        <span>Story / Reels</span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src={instagram}
                          alt="Logo instagram"
                          className="mx-2"
                        />
                        <img
                          src={facebook}
                          alt="Logo facebook"
                          className="mx-2"
                        />
                      </div>
                      <div className="image-size position-relative">
                        <div className="btns-down-share position-absolute d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            onClick={() => handleClickDownloadImage(1350)}
                          >
                            <img
                              src={downloadShare}
                              title="Download"
                              alt="Download"
                            />
                          </button>
                          <button type="button">
                            <img src={chainShare} title="Share" alt="Chain" />
                          </button>
                        </div>
                        <div className="header-img d-flex align-items-center">
                          <div />
                          <div />
                        </div>
                        <div ref={div1350Ref} className="position-relative">
                          <img src={bg.mid} alt="Large" className="w-100" />
                          <div className="position-absolute text-ai">
                            <div className="d-flex align-items-center ps-4 pt-2">
                              <img
                                src={aiLogo}
                                alt=""
                                className="logo-ai-mid ms-3 me-2"
                              />
                              <img
                                src={aiLogoText}
                                alt=""
                                className="logo-ai-text-mid"
                              />
                            </div>
                            <ImgShared className="position-relative">
                              <div className="nft margin-nft-mid">
                                <div>
                                  <img
                                    src={selectImgNft}
                                    alt="imgNft"
                                    crossOrigin="anonymous"
                                  />
                                  <div className="details text-center">
                                    <p className="fw-medium mb-0 scan-mid">
                                      <span className="d-block fw-semibold">
                                        Visit to create yours{' '}
                                        <b className="text-white">FREE</b>
                                      </span>
                                    </p>
                                    <p className="scan-mid fw-bold mb-1">
                                      <span>{`profiti.io/try/i/${code}`}</span>
                                    </p>
                                    {/* <div className="bg-qr-code qr-code-mid">
                                      <QRCode
                                        value={`https://profiti.io/try/i/${code}`}
                                        size={29}
                                        bgColor="transparent"
                                        renderAs="svg"
                                        level="H"
                                        imageSettings={{
                                          src: logoBlack,
                                          height: 10,
                                          width: 10,
                                          excavate: true,
                                        }}
                                      />
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between footer px-5">
                                <span className="text-end mb-0 mt-1 small">
                                  {`profiti.io/try/i/${code}`}
                                </span>
                                <span className="text-end mb-0 mt-1 small">
                                  #profitiai
                                </span>
                              </div>
                            </ImgShared>
                          </div>
                        </div>
                        <div className="footer-img d-flex align-items-center px-2">
                          <div className="mx-1 my-2" />
                          <div className="mx-1 my-2" />
                          <div className="mx-1 my-2" />
                          <div className="ms-auto me-1" />
                        </div>
                      </div>
                      <div className="text-size text-center mt-4">
                        <span>1080x1350</span>
                        <span>Post Tile</span>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src={facebook}
                          alt="Logo facebook"
                          className="mx-2"
                        />
                        <img src={twiter} alt="Logo Twiter" className="mx-2" />
                      </div>
                      <div className="image-size position-relative">
                        <div className="btns-down-share position-absolute d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            onClick={() => handleClickDownloadImage(1200)}
                          >
                            <img
                              src={downloadShare}
                              title="Download"
                              alt="Download"
                            />
                          </button>
                          <button type="button">
                            <img src={chainShare} title="Share" alt="Chain" />
                          </button>
                        </div>
                        <div className="header-img d-flex align-items-center">
                          <div />
                          <div />
                        </div>
                        <div ref={div1200Ref} className="position-relative">
                          <img
                            src={bg.horizontal}
                            alt="Large"
                            className="w-calc"
                          />
                          <div className="d-flex position-absolute text-ai pt-2">
                            <div className="w-30 d-flex flex-column ps-3 pt-2">
                              <img
                                src={aiLogo}
                                alt=""
                                className="d-block logo-ai-horizontal mb-1 me-2"
                              />
                              <img
                                src={aiLogoText}
                                alt=""
                                className="d-block logo-ai-text-horizontal"
                              />
                              <span className="mb-0 mt-auto small footer">
                                {`profiti.io/try/i/${code}`}
                              </span>
                            </div>
                            <ImgShared className="w-40 position-relative">
                              <div className="nft margin-nft-horizontal">
                                <div>
                                  <img
                                    src={selectImgNft}
                                    alt="imgNft"
                                    crossOrigin="anonymous"
                                  />
                                  <div className="details text-center">
                                    <p className="fw-medium mb-0 scan-horizotal">
                                      <span className="d-block fw-semibold">
                                        Visit to create yours{' '}
                                        <b className="text-white">FREE</b>
                                      </span>
                                    </p>
                                    <p className="scan-horizotal fw-bold mb-1">
                                      <span>{`profiti.io/try/i/${code}`}</span>
                                    </p>
                                    {/* <div className="bg-qr-code qr-code-horizontal">
                                      <QRCode
                                        value={`https://profiti.io/try/i/${code}`}
                                        size={24}
                                        bgColor="transparent"
                                        renderAs="svg"
                                        level="H"
                                        imageSettings={{
                                          src: logoBlack,
                                          height: 8,
                                          width: 8,
                                          excavate: true,
                                        }}
                                      />
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </ImgShared>
                            <div className="w-30 d-flex justify-content-end align-items-end">
                              <span className="text-end mb-0 mt-0 me-3 small footer">
                                #profitiai
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="footer-img d-flex align-items-center px-2">
                          <div className="mx-1 my-2" />
                          <div className="mx-1 my-2" />
                          <div className="mx-1 my-2" />
                          <div className="ms-auto me-1" />
                        </div>
                      </div>
                      <div className="text-size text-center mt-4">
                        <span>1200x675</span>
                        <span>Lanscape Post</span>
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalDownloadShare;
