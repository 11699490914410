import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IButtonProps {
  disabled?: boolean;
}

export const Button = styled.div<IButtonProps>`
  top: 10px;
  left: calc(50% - 98px);
  height: 196px;
  width: 196px;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};

  button {
    background-color: transparent;
  }
  button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .white {
    background-color: #fff;
    backdrop-filter: blur(46.1721px);
    height: 126px;
    width: 126px;
    top: 35px;
    left: 35px;
  }

  .transparent {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) -3.78%,
      rgba(255, 255, 255, 0.096) 96.22%
    );
    opacity: 0.3;
    box-shadow: inset 0px 39px 52px rgba(255, 255, 255, 0.5),
      inset 6px 1px 1px #ffffff, inset -6px -7px 8px #ffffff,
      inset 0px 7px 3px #ffffff, inset 51px -73px 72px rgba(255, 255, 255, 0.3),
      inset 0px 98px 100px rgba(255, 255, 255, 0.3),
      inset 0px 4px 18px rgba(255, 255, 255, 0.3),
      inset 0px 1px 40px rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(6.5px);
    height: 196px;
    width: 196px;
  }

  .gradient-swap {
    background: linear-gradient(
        195deg,
        rgba(255, 218, 85, 0.8) 0%,
        rgba(255, 0, 138, 0.8) 100%
      ),
      linear-gradient(
        171deg,
        rgba(255, 255, 255, 0.15) 36.08%,
        rgba(255, 255, 255, 0.12) 86.19%
      );
    opacity: 0.9;
    backdrop-filter: blur(46.1721px);
    top: 12.5px;
    left: 12.5px;
    height: 171px;
    width: 171px;
  }
`;

export const Modal = styled(ModalComponent)`
  h2 {
    font-family: 'Poppins', sans-serif;
    color: #dadada;
    font-size: 48px;
  }

  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 118%;
    text-align: center;
    color: #aeabab;
    b {
      color: #a6a2a2;
    }
  }

  .bg-nft {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #fff;
    backdrop-filter: blur(5px);
    border-radius: 40px;
  }

  /* .bg-nft img {
    height: 70%;
  } */

  .bg-nft span {
    color: #fffefb;
    font-size: 30px;
    line-height: 40px;
  }

  .nft {
    filter: drop-shadow(5px 11px 140px rgba(0, 0, 0, 0.25));
    border-radius: 35px;
  }

  .bg-serial-qrcode {
    position: absolute;
    bottom: 0.5rem;
    left: 8px;
    width: 389px;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(7.5px);

    border-radius: 0px 0px 35px 35px;
  }

  .bg-qrcode {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.62);
    border-radius: 25px;
  }

  .w-60 {
    width: 60%;
  }

  .w-30 {
    width: 30%;
  }
  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  /* .modal-xl {
    width: 1140px;
  } */

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }
`;

export const ModalCongrat = styled(ModalComponent)`
  h2 {
    font-family: 'Poppins', sans-serif;
    color: #dadada;
    font-size: 48px;
  }

  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 118%;
    text-align: center;
    color: #aeabab;
    b {
      color: #a6a2a2;
    }
  }

  .modal-header {
    display: block;
  }

  .to-dashboard {
    padding: 14px;
    border-radius: 15px;
    font-weight: bold;
    background-color: #00f9ab;
    color: #000;
    font-weight: 700;
    font-size: 20px;
    transition-duration: 0.3s;
    :hover {
      background-color: ${darken(0.1, '#00f9ab')};
    }
  }

  .coin video {
    border: 1px solid #565656;
    border-radius: 30px;
  }
  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  .bg-lg-social-medias {
    background: #0c0d0d;
    backdrop-filter: blur(5px);
    border-radius: 60px;
  }

  .social-medias {
    width: auto;
  }

  .social-medias img {
    width: 90px;
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 1199px) {
    .social-medias img {
      width: 75px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 42px;
    }
    .social-medias img {
      width: 85px;
    }

    .bg-mobile-social-medias {
      background: #0c0d0d;
      backdrop-filter: blur(5px);
      border-radius: 60px;
    }

    .bg-lg-social-medias {
      background: transparent;
      backdrop-filter: none;
      border-radius: unset;
    }
  }
`;
