import React, { useCallback, useState, useEffect } from 'react';

import { Container, Retangle, Modal, ModalFinish } from './styles';
import { useResize } from '~/hooks/Resize';
import logo from '~/assets/logo/logo-p-gray.svg';
import bubble2 from '~/assets/defaults/bubble_2.svg';
import metamask from '~/assets/logo/metamask.png';
import walletFinish from '~/assets/defaults/wallet-finish.png';

const Wr: React.FC = () => {
  const [show, setShow] = useState(false);
  const [showModalFinish, setShowModalFinish] = useState(false);
  const resize = useResize();
  const widthScreen = resize.width;

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleCloseModalFinish = useCallback(() => {
    setShowModalFinish(false);
  }, []);

  return (
    <Container>
      <div className="banner position-relative">
        <Retangle
          width={widthScreen}
          className="d-flex flex-column justify-content-center retangle"
        >
          <img src={logo} alt="Logo" className="zoom" />
          <div className="w-75 d-flex mx-auto p-4 justify-content-evenly bg-main">
            <button
              type="button"
              className="w-100 btn-mint zoom"
              onClick={() => setShow(true)}
            >
              CONNECT WALLET
            </button>
          </div>
        </Retangle>
        <div className="container">
          <div className="row">
            <div className="col-12 rings d-flex align-items-center zoom">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h1 className="">
                    WELCOME TO PROFIT<span>i</span>
                  </h1>
                  <h2 className="w-75 pt-3 ">
                    On this page you can{' '}
                    <span>register your wallet to receive an account NFT</span>{' '}
                    from a current member
                  </h2>

                  <div className="d-flex align-items-center second-bubble mt-5">
                    <img src={bubble2} alt="bubble" className="" />
                    <h3 className="ps-5 ps-4 mb-0 ">
                      <span>Click the button</span> on the right to connect and
                      authorize your wallet. When done, inform the member who
                      sent you this link.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        size="md"
        show={show}
        onHide={handleClose}
        className="modal-signin-auto"
      >
        <Modal.Header className="border-0 py-2" />
        <Modal.Body className="px-4 px-sm-5 body">
          <div className="text-center my-5">
            <button
              type="button"
              className="mt-4"
              onClick={() => console.log('wallet')}
            >
              <img src={metamask} alt="Metamask" className="d-block mx-auto" />
              Use MetaMask Wallet
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-2" />
      </Modal>
      <ModalFinish
        size="lg"
        show={showModalFinish}
        onHide={handleCloseModalFinish}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleCloseModalFinish}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-5 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={walletFinish} alt="Wallet" />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mt-n4 mb-4 fw-bold text-center w-100">
            Congratulations!
          </h2>
          <div className="texts w-90 mx-auto">
            <p className="text-center mb-5">
              Your wallet has been authorized to receive an Account NFT
            </p>
            <p className="text-center mb-4 px-4">
              Now, please reach out to the person who sent you this link to
              finalize the sign up process.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </ModalFinish>
    </Container>
  );
};

export default Wr;
