import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  .bg-no-avatar {
    min-width: 60px;
    height: 60px;
    border: solid 1px #5d5b5b;
    border-radius: 50%;
    background-color: #1d2023;
  }
  .bg-gray {
    background: #1d2023;
    border-radius: 20px;

    .border-right {
      border-color: #393c3d !important;
    }

    h2 {
      color: #dadada;
    }

    .input {
      border: 1.5px solid #393c3d;
      box-sizing: border-box;
      border-radius: 15px;
      height: 40px;

      input {
        color: #efefef !important;
      }
    }

    button.btn-gradient {
      border-radius: 15px;
    }
  }

  .table {
    .rdt_TableHeadRow,
    .rdt_TableRow {
      > div:nth-child(2),
      > div:nth-child(4) {
        max-width: 175px;
        width: 175px;
      }
    }
  }

  .contact {
    cursor: pointer;

    input {
      color: #8c8c8c;
      font-size: 13px;
      background-color: transparent;
      border: none;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #dadada;
    span {
      color: #606060;
      font-weight: 500;
    }
  }

  p {
    color: #bdbdbd;
  }

  button.border-gradient {
    height: 40px;
  }

  .dropdown ul {
    background-color: #242526;
    min-width: 108px;
    top: 35px;
    li a {
      color: #e9e9e9;
      text-align: center;
      :hover {
        background-color: ${lighten(0.1, '#242526')};
      }
    }
  }

  .bg-dropdown {
    display: inline-block;
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );

    button {
      margin: 1px;
      background-color: #242526;
      color: #e9e9e9;
    }
  }

  .btn-group {
    background-color: #4b4c4c;
    position: relative;

    .btn-background {
      display: block;
      position: absolute;
      height: 35px;
      width: 152px;
      background: linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      );
      border: 2px solid #d7d7d7;
      transition-duration: 0.3s;
      opacity: 0.8;
    }

    .btn-background.right {
      left: 47%;
    }

    .btn-background.left {
      left: 0;
    }

    .btn-check {
      border: none;
      background: transparent;
      color: #222527;
      height: 35px;
      width: 152px;
      position: relative;
      z-index: 1;
      transition-duration: 0.3s;
    }

    .btn-check.active {
      color: #17171a;
    }
  }
`;

export const BoxMatrix = styled.div`
  .text-light-gray {
    color: #cbccce;
  }

  .button-group {
    transition-duration: 0.3s;
  }

  .button-group.hide {
    opacity: 0;
    visibility: hidden;
  }

  .text-green {
    color: #96ffd9;
  }

  .text-red {
    color: #ff1a50;
  }

  .btn-actions {
    height: 40px;
    width: 150px;
  }

  .size {
    font-size: 24px;
    p {
      font-size: 14px;
    }
  }

  .bd-gray {
    height: 35px;
    width: 1.5px;
    background-color: #8c8c8c;
  }

  .box-top-user {
    background: #222527;
    border: 1px solid #5e5b5b;
    box-sizing: border-box;
    box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
    border-radius: 50px;

    img {
      width: 70px;
      height: 70px;
    }
  }

  .box-sub-user {
    background: #222527;
    border: 1px solid #5d5b5b;
    box-sizing: border-box;
    box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
    font-size: 90%;
    transition-duration: 0.3s;
    min-height: 50px;

    .w-custom {
      width: 100px;
    }

    img {
      width: 60px;
      height: 60px;
    }
    p {
      font-size: 12px;
    }
  }

  .box-user {
    width: 35px;
    height: 35px;
    background: #222527;
    border: 1px solid #5d5b5b;
    box-sizing: border-box;
    transition-duration: 0.3s;

    :hover {
      border: 1px solid #cbccce;
    }
  }

  svg {
    width: 17px;
    height: 17px;
  }

  @media screen and (min-width: 1400px) {
  }
  @media screen and (min-width: 1200px) {
    .w-xl-20 {
      width: 20%;
    }
  }

  @media screen and (max-width: 1199px) {
    .box-top-user .size {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    .box-top-user {
      border-radius: 24px;
    }
  }
`;
