import React, { useCallback, useState, useEffect } from 'react';
import { utils } from 'ethers';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Container } from './styles';
import cubeImg from '~/assets/icons/cube.svg';
import cubeSmall from '~/assets/defaults/cube-small.png';
import cubeCum from '~/assets/defaults/cube-cum.png';
import arrow from '~/assets/icons/arrow-bottom.svg';
// import { formatUSDValueFromWei } from '~/utils/format';
import ProgressBar from '~/components/ProgressBar';

interface Cube {
  cube_id: number;
  balance: number;
  cube_max_players: number;
  cube_payoff: number;
  entry_fee: number;
  free_available: number;
  percent_filled: number;
  paid_user: number;
  is_progressive: boolean;
}

interface CubeHistory {
  cube_id: number;
  cube_description: string;
  entry_fee: string;
  paid_date: string;
  paid_user: string;
  cube_payoff: string;
}

interface IActiveCubes {
  cubes: Cube[];
}

interface IHistoryCubes {
  cubes: CubeHistory[];
}

interface ICubeProps {
  cubes: IActiveCubes | null;
  winners: IHistoryCubes | null;
  selectFunction: (cube: Cube) => void; // Define the prop for the parent function
  selected: number;
}

export function formatUSDValue(valueInWei: number): string {
  // Convert Wei to Ether
  const valueInEther = utils.formatUnits(valueInWei.toString(), 'ether');
  // Assuming 1 Ether = $100, replace with your actual conversion rate
  const usdValue = parseFloat(valueInEther);
  return `$${usdValue.toFixed(0)}`;
}

const MoreCubes: React.FC<ICubeProps> = ({
  cubes,
  winners,
  selectFunction,
  selected,
}) => {
  const [hasActiveCubes, setHasActiveCubes] = useState(true);
  const [moreActive, setMoreActive] = useState('more-cubes');
  const handleActiveCubes = useCallback(() => {
    setHasActiveCubes(!hasActiveCubes);
  }, [hasActiveCubes]);
  const handleCubeClick = (cube: Cube) => {
    // Call the parent select function with the selected cube
    selectFunction(cube);
  };

  if (!cubes) {
    // Handle the case where "cubes" is null (e.g., display a loading indicator)
    return <div>Loading...</div>;
  }

  const getSeries = (percentage: number) => {
    return percentage >= 0 ? percentage : percentage * -1;
  };

  const getChartOptions = (percentage: number) => {
    const data: ApexOptions = {
      colors: [`${percentage >= 0 ? '#00F9AB' : '#f9000f'}`],
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          hollow: {
            size: '30%',
          },
          track: {
            show: true,
            background: '#4a4949',
            strokeWidth: '97%',
            opacity: 1,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
              offsetY: 5,
              color: 'white',
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: [`${percentage >= 0 ? 'Lucros' : 'Perdas'}`],
    };
    return data;
  };

  return (
    <Container className="pt-4">
      <div className="d-flex justify-content-between align-items-center px-4 pb-4">
        <button
          onClick={() => setMoreActive('more-cubes')}
          type="button"
          className="bg-transparent border-0"
        >
          <span
            className={`${
              moreActive === 'more-cubes' ? 'more-active' : 'more'
            }  d-flex align-items-center`}
          >
            More Cubes <img src={cubeImg} alt="Cube" className="ms-3" />
          </span>
        </button>
        <button
          onClick={() => setMoreActive('winners')}
          type="button"
          // disabled
          className="bg-transparent border-0"
        >
          <span
            className={`${
              moreActive === 'winners' ? 'more-active' : 'more'
            }  d-flex align-items-center`}
          >
            Winners
          </span>
        </button>
        <button
          type="button"
          onClick={handleActiveCubes}
          className={`${
            !hasActiveCubes && 'rotateActive'
          } bg-transparent border-0 arrow`}
        >
          <img src={arrow} alt="Arrow" />
        </button>
      </div>
      {moreActive === 'more-cubes' && hasActiveCubes && (
        <div className="px-3 pb-2">
          <div className="d-flex flex-wrap height-cards-more-cubes">
            {cubes.cubes.map((cube) => (
              <div key={cube.cube_id} className="w-100 pe-2 py-2">
                <button
                  type="button"
                  // className="d-flex w-100 bg-btn p-1 ${cube.cube_id === selected ? 'cube-selected' : ''}"
                  className={`d-flex w-100 bg-btn p-1 ${
                    cube.cube_id === selected ? 'cube-selected' : ''
                  }`}
                  onClick={() => handleCubeClick(cube)}
                >
                  <div className="d-flex w-100 col-12">
                    <div className="">
                      {/* <img src={cubeSmall} alt="Cube Small" /> */}
                      <img
                        src={cube.is_progressive ? cubeCum : cubeSmall}
                        alt={cube.is_progressive ? 'Cuumulative' : 'Standard'}
                      />
                    </div>
                    <div className=" ms-2 text-start">
                      <div className="d-flex align-items-center">
                        <span className="cube-size pr-2">
                          {cube.entry_fee / 10 ** 18} X {}
                          {cube.cube_payoff / cube.entry_fee}
                        </span>
                        <span className="mx-2 text-white">|</span>
                        <span className="cube-entry">
                          ${cube.entry_fee / 10 ** 18} Entry
                        </span>
                      </div>
                      <div className="d-block payout-label mt-2">
                        Minimum Payout
                      </div>
                      <div className="prize-value flex-grow-1">
                        {formatUSDValue(cube.cube_payoff)}
                      </div>
                    </div>
                    <div className="ms-auto me-lg-n2">
                      <ReactApexChart
                        options={getChartOptions(cube.percent_filled)}
                        series={[cube.percent_filled]}
                        type="radialBar"
                        width="100px"
                        height="100px"
                        className="float-end"
                      />
                    </div>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      {moreActive === 'winners' && hasActiveCubes && winners && (
        <div className="px-3 pb-2">
          <div className="d-flex flex-wrap height-cards-more-cubes">
            {winners.cubes.map((cube) => (
              <div key={cube.cube_id} className="w-100 pe-2 py-2">
                <div className="d-flex w-100 bg-btn p-1 pe-none">
                  <div className="d-flex w-100 col-12">
                    <div className="">
                      <img src={cubeSmall} alt="Standard" />
                    </div>
                    <div className=" ms-2 text-start">
                      <div className="d-flex align-items-center">
                        <span className="cube-size pr-2">
                          {cube.cube_description}
                        </span>
                        <span className="mx-2 text-white">|</span>
                        <span className="cube-entry">
                          {cube.entry_fee} Entry
                        </span>
                      </div>
                      <div className="d-block payout-label mt-2">
                        Winner NFT
                      </div>
                      <div className="prize-value flex-grow-1">
                        {cube.paid_user}
                      </div>
                    </div>
                    <div className="ms-auto me-2 text-end">
                      <div className="d-block payout-label mt-1">
                        {cube.paid_date}
                      </div>
                      <div className="d-block payout-label mt-2">
                        Amount Paid
                      </div>
                      <div className="prize-value flex-grow-1">
                        {cube.cube_payoff}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};

export default MoreCubes;
