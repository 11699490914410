import React, { useCallback, useState, useEffect } from 'react';
import { web3store } from '../../store';

import { Modal } from './styles';

import aiverifyGif from '~/assets/animations/aiverify.gif';

const ModalNetworkSwitch: React.FC = () => {
  return null;
};

export const networkSwitch = async (newNetwork: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    let nativeCurrency: any;
    let network: any;
    let buttonTxt: any;
    const provider = (window as any).ethereum;

    provider
      .request({
        method: 'eth_chainId',
      })
      .then((currentNetworkId: string) => {
        if (newNetwork === 'PAY') {
          nativeCurrency = {
            name: process.env.REACT_APP_PAY_CURRENCY_NAME as string,
            symbol: process.env.REACT_APP_PAY_CURRENCY_SYMBOL as string,
            decimals: parseInt(
              process.env.REACT_APP_PAY_CURRENCY_DECIMALS as string,
              10
            ),
          };
          network = {
            chainId: process.env.REACT_APP_PAY_CHAIN_ID as string,
            chainName: process.env.REACT_APP_PAY_CHAIN_NAME as string,
            rpcUrls: [process.env.REACT_APP_PAY_RPC_URLS as string],
            blockExplorerUrls: [
              process.env.REACT_APP_PAY_BLOCK_EXPLORER_URLS as string,
            ],
            nativeCurrency,
          };
          buttonTxt = process.env.REACT_APP_PAY_BUTTON_TEXT;
        } else {
          nativeCurrency = {
            name: process.env.REACT_APP_NATIVE_CURRENCY_NAME as string,
            symbol: process.env.REACT_APP_NATIVE_CURRENCY_SYMBOL as string,
            decimals: parseInt(
              process.env.REACT_APP_NATIVE_CURRENCY_DECIMALS as string,
              10
            ),
          };
          network = {
            chainId: process.env.REACT_APP_NETWORK_CHAIN_ID as string,
            chainName: process.env.REACT_APP_NETWORK_CHAIN_NAME as string,
            rpcUrls: [process.env.REACT_APP_NETWORK_RPC_URLS as string],
            blockExplorerUrls: [
              process.env.REACT_APP_NETWORK_BLOCK_EXPLORER_URLS as string,
            ],
            nativeCurrency,
          };
          buttonTxt = process.env.REACT_APP_NETWORK_BUTTON_TEXT;
        }
        // console.log('++++++++++++++++++++++++++++++++++++++++++');
        // console.log('++++++++++++++++++++++++++++++++++++++++++');
        // console.log('++++++++++++++++++++++++++++++++++++++++++');
        // console.log('++++++++++++++++++++++++++++++++++++++++++');
        // console.log('++++++++++++++++++++++++++++++++++++++++++');
        // console.log('newNetwork: %s', newNetwork);
        // console.log('currentNetworkId: %s', currentNetworkId);
        // console.log('nativeCurrency:');
        // console.log(nativeCurrency);
        // console.log('network:');
        // console.log(network);
        // console.log('buttonTxt: %s', buttonTxt);
        if (currentNetworkId === network.chainId) {
          // Network is already correct, no need to switch
          resolve(true);
        }

        // const provider = (window as any).ethereum;

        provider
          .request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: network.chainId }],
          })
          .then(() => {
            console.log('Network switch complete');
            resolve(true);
          })
          .catch((neterror: any) => {
            console.error('Failed to switch network:', neterror);
            provider
              .request({
                method: 'wallet_addEthereumChain',
                params: [network],
              })
              .then(() => {
                console.log('New network added successfully!');
                resolve(true);
              })
              .catch((error: any) => {
                console.error('Failed to add new network:', error);
                reject(error);
              });
          });
        // reject(new Error('ERROR'));
      }) // Get current network Id
      .catch((error: Error) => {
        reject(error);
      });
  });
};

export default ModalNetworkSwitch;
