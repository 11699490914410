import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';

import { format } from 'date-fns';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import { Container, Welcome, Option } from './styles';
import MyEarnings from '~/components/MyEarnings';
import ModalWalletAddAddress, {
  IFormData as ModalWalletAddAddressFormData,
} from '~/components/ModalWalletAddAddress';
import TransactionHistory from '~/components/TransactionHistory';

import letter from '~/assets/icons/letter-icon.svg';
import WalletRow from '~/components/WalletRow';
import { formatPrice } from '~/utils/format';
import swalError from '~/utils/swalError';

interface IWallet {
  id: string;
  amount: number;
}

interface IPool {
  accounts: number;
  mtdRevenueShare: string;
  totalRevenueShare: string;
  mtdPerformanceReferrals: number;
  mtdPerformanceValue: string;
  // members: number;
  // revenue: string;
}

interface IPoolResponse {
  pool: IPool;
}

const Wallet: React.FC = () => {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [wallet, setWallet] = useState<IWallet>({} as IWallet);
  const [pool, setPool] = useState({} as IPool);
  const history = useHistory();

  useEffect(() => {
    api.get<IPoolResponse>('v3/pool/totals').then((response) => {
      console.log(response.data.pool.mtdPerformanceValue);
      setPool({
        accounts: response.data.pool.accounts,
        mtdRevenueShare: formatPrice(
          parseFloat(
            (
              parseInt(response.data.pool.mtdRevenueShare, 10) /
              10 ** 18
            ).toFixed(2)
          )
        ),
        totalRevenueShare: formatPrice(
          parseFloat(
            (
              parseInt(response.data.pool.totalRevenueShare, 10) /
              10 ** 18
            ).toFixed(2)
          )
        ),
        mtdPerformanceReferrals: response.data.pool.mtdPerformanceReferrals,
        mtdPerformanceValue: formatPrice(
          parseFloat(
            (
              parseInt(response.data.pool.mtdPerformanceValue, 10) /
              10 ** 18
            ).toFixed(2)
          )
        ),
      });
    });
  }, []);

  const handleShow = useCallback(async () => {
    // await api.post(`users/2fa/${user?.id}`);
    setShowModal(true);
  }, [user]);

  const handleClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: ModalWalletAddAddressFormData) => {
      try {
        // await api.patch(`wallets/${wallet.id}`, data);
        Swal.fire(
          'Tudo certo!',
          'Endereço cadastrado com sucesso.',
          'success'
        ).then(() => {
          handleClose();
        });
      } catch (error) {
        const err: any = error;
        swalError({
          message: err.response.data.message,
          textButton: 'Close',
        });
      }
    },
    [handleClose]
  );

  return (
    <Container>
      <div className="container-fluid">
        <div className="row mb-4 mt-4 align-items-center">
          <div className="col-lg-6">
            <Welcome>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <div className="ms-2">
                  <span className="h6-xs h4 h2-lg font-weight-bold">
                    <Option>
                      <h1 className="h2 mb-0 Link Active">Wallet</h1>
                    </Option>
                  </span>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-lg-6">
            <WalletRow />
          </div>
        </div>
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-lg-7 col-xxl-5 pe-1">
            <div className="h-100 bg-gray p-5">
              <TransactionHistory />
            </div>
          </div>
          <div className="col-lg-5 col-xxl-4 mb-4 mb-lg-0">
            <div className="row h-100">
              <div className="col-12 order-0 order-lg-1 px-1">
                <div className="h-100 w-100 bg-earning-gray px-2">
                  <MyEarnings />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 d-flex px-1 share-pool">
            <div className="bg-border d-flex flex-column justify-content-between h-lg-100 p-2">
              <div className="d-flex justify-content-between px-4">
                <h2 className="mt-4">
                  PROFITi Revenue <span>Share Pool</span>
                </h2>
                <div className="line-side my-4" />
                <h2 className="text-end mt-4">
                  Performance <span>Share Pool</span>
                </h2>
              </div>
              <div>
                <h6 className="mb-0 text-center mt-4">
                  Total PROFITi Accounts
                </h6>
                <div className="bg-value-revenue d-flex align-items-center justify-content-center mx-3">
                  <div className="value-revenue text-center d-flex justify-content-center align-items-center">
                    <span className="mx-3">{pool.accounts}</span>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className="w-100">
                  <h6 className="mb-0 text-center">
                    Revenue Shared
                    <br />
                    This Month
                  </h6>
                  <div className="bg-value-revenue d-flex align-items-center justify-content-center mx-3">
                    <div className="value-revenue text-center d-flex justify-content-center align-items-center">
                      <span className="mx-3">${pool.mtdRevenueShare}</span>
                    </div>
                  </div>
                  <h6 className="mb-0 text-center mt-4">
                    Lifetime Revenue
                    <br />
                    Shared
                  </h6>
                  <div className="bg-value-revenue d-flex align-items-center justify-content-center mx-3">
                    <div className="value-revenue text-center d-flex justify-content-center align-items-center">
                      <span className="mx-3">${pool.totalRevenueShare}</span>
                    </div>
                  </div>
                </div>
                <div className="line-side my-3" />
                <div className="w-100">
                  <h6 className="mb-0 text-center">
                    New Referrals
                    <br />
                    This Month
                  </h6>
                  <div className="bg-value-revenue d-flex align-items-center justify-content-center mx-3">
                    <div className="value-revenue text-center d-flex justify-content-center align-items-center">
                      <span className="mx-3">
                        {pool.mtdPerformanceReferrals}
                      </span>
                    </div>
                  </div>
                  <h6 className="mb-0 text-center mt-4">
                    Bonus Per Referral <br />
                    This Month
                  </h6>
                  <div className="bg-value-revenue d-flex align-items-center justify-content-center mx-3">
                    <div className="value-revenue text-center d-flex justify-content-center align-items-center">
                      <span className="mx-3">${pool.mtdPerformanceValue}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-relative bg-box overflow-hidden mt-4 px-3 py-4">
                <div className="position-relative text-center index1">
                  <p className="paragraph mb-4 mt-2 px-4">
                    Get your share of the pool by{' '}
                    <span>holding a staking&nbsp;contract</span> &amp; referring
                    a new member
                    {/* Get your share of the pool by{' '}
                    <span>holding a staking&nbsp;contract</span> */}
                  </p>
                  <button
                    type="button"
                    className="mt-2 w-100"
                    onClick={() =>
                      history.push(`${process.env.PUBLIC_URL}/token-vault`)
                    }
                  >
                    Get Yours Here
                  </button>
                </div>
                <div className="position-absolute bg-grad-blur" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Wallet;
