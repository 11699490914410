import styled, { keyframes } from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import { Form } from '@unform/web';
import bgNft from '~/assets/banners/bg-modal.png';
import bgNftDark from '~/assets/banners/bg-modal-dark.png';
import bgReveal from '~/assets/defaults/banner-reveal.png';
import light from '~/assets/defaults/light.svg';
import bgWinner from '~/assets/defaults/bg-winner.png';
import underMaintenance from '~/assets/defaults/maintenance.png';

interface IModelProps {
  showNft: boolean;
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const rollIn = keyframes`
  from {
    transform: translateX(-50%) translateY(100%); // Start off-screen
    opacity: 0;
  }
  to {
    transform: translateX(-50%) translateY(0); // End at original position
    opacity: 1;
  }
`;

const rollOut = keyframes`
  from {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
  to {
    transform: translateX(-50%) translateY(100%); // Move back off-screen
    opacity: 0;
  }
`;

export const ToastAlert = styled(Toast)`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 583px;
  height: 162px;
  flex-shrink: 0;
  border-radius: 44px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(152, 148, 148, 0.1);
  box-shadow: 3px 4px 32px 0px rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(15px);
  z-index: 1000;

  &.fade-in {
    animation: ${fadeIn} 1.5s ease-in-out forwards;
  }

  &.fade-out {
    animation: ${fadeOut} 0.5s ease-in-out forwards;
  }

  &.roll-in {
    animation: ${rollIn} 0.5s ease-out forwards;
  }

  &.roll-out {
    animation: ${rollOut} 0.5s ease-out forwards;
  }

  .toast-body {
    padding: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toast-img {
    width: 151px;
    height: 148px;
    flex-shrink: 0;
    margin-right: 20px;
    border-radius: 40px;
    border: 3px solid rgba(255, 255, 255, 0.5);
  }

  .action {
    width: 172px;
    color: #ff008a;
    text-align: center;
    font-family: Barlow;
    font-size: 90px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 22px;
  }

  .win {
    color: #00f9ab;
  }

  .bid {
    color: #ff008a;
  }

  .info {
    width: 203px;
  }

  .line1 {
    color: #f5eeee;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .line2 {
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .line3 {
    color: #c4c4c4;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .line4 {
    color: #00f9ab;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const Banner = styled.div`
  padding: 0 20px;

  .btn-avatar {
    border-radius: 30px !important;
    background: transparent !important;
    border: unset !important;
  }
  .container-zindex {
    z-index: 2;
  }

  .arrow {
    width: 30px;
    height: 30px;
    transition: all 0.3s ease;
  }

  .rotateActive {
    transform: rotate(-90deg);
  }

  .fw-600 {
    font-weight: 600 !important;
  }

  @media screen and (min-width: 992px) {
    .zoom {
      zoom: 0.6;
    }
  }

  @media screen and (min-width: 1200px) {
    .zoom {
      zoom: 0.65;
    }
  }

  @media screen and (min-width: 1300px) {
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1600px) {
    .zoom {
      zoom: 0.9;
    }
  }

  @media screen and (min-width: 1700px) {
    .zoom {
      zoom: 0.95;
    }
  }

  @media screen and (min-width: 1800px) {
    .zoom {
      zoom: 1;
    }
  }
`;

export const Video = styled.video`
  background-color: #000 !important;
  position: fixed;
  min-width: 100%;
  left: 0%;
  z-index: 1;
  height: 100vh;
  //top: 50%;
  //left: 50%;
  //min-height: 100%;
  //width: auto;

  //transform: translateX(-50%) translateY(-50%);
  transition: top 1s ease-in-out;
`;

export const Modal = styled(ModalComponent)<IModelProps>`
  backdrop-filter: blur(10px);
  .modal-content {
    background-color: #000;
    background-image: url(${bgNft});
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
    border: none;
    overflow: hidden;
    position: relative;
    :before {
      content: '';
      background: url(${light});
      width: 610px;
      height: 452px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      transition-duration: 0.1s;
      opacity: ${(props) => (props.showNft ? 1 : 0)};
      visibility: ${(props) => (props.showNft ? 'visible' : 'hidden')};
      z-index: 2;
    }
    :after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url(${bgNftDark});
      background-position: bottom;

      transition-duration: 0.3s;
      opacity: ${(props) => (props.showNft ? 0 : 1)};
      visibility: ${(props) => (props.showNft ? 'hidden' : 'visible')};
    }
  }

  .min-height {
    min-height: 470px;
  }

  .bg-main {
    background: rgba(23, 23, 25, 0.46);
    box-shadow: 0px -15px 30px rgba(0, 0, 0, 0.265139);
    backdrop-filter: blur(5px);
    border-radius: 37px 37px 0px 0px;
    width: 100%;
  }

  .try-again {
    background: rgba(26, 27, 29, 0.7);
    border: 2px solid #393c3d;
    border-radius: 22px;
    color: #f2f2f2;
    font-weight: 600;
    padding: 22px 33px;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
  }

  .btn-play-again {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 22px;
    font-weight: 600;
    padding: 13px 49px;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    color: #000;
  }

  .bg-nft {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border: 1px solid ${(props) => (props.showNft ? '#fff' : '#000')};
    border-radius: 40px;
    //height: 470px;
    width: 100%;
    position: relative;
    z-index: 1;
    :after {
      border-radius: 40px;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0);

      transition-duration: 0.3s;
      opacity: ${(props) => (props.showNft ? 0 : 1)};
      visibility: ${(props) => (props.showNft ? 'hidden' : 'visible')};
    }
  }

  .bg-nft img {
    //height: 70%;
  }

  .bg-nft span {
    color: #fffefb;
    font-size: 35px;
    line-height: 50px;
  }

  .bg-serial-qrcode {
    position: absolute;
    bottom: 0.5rem;
    left: 8px;
    width: calc(100% - 16px);
    border-radius: 0 0 35px 35px;
    background-image: url(${bgReveal});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    backdrop-filter: blur(7.5px);
    //background: rgba(255, 255, 255, 0.24);
  }

  /* .fw-bold {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 13px rgba(99, 78, 6, 0.25);
    font-family: Alumni Sans Collegiate One !important;
    font-size: 120px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  } */

  .bg-serial-qrcode span {
    text-align: center;
    text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    font-family: 'Audiowide';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 5.5px;
    background: linear-gradient(
      239.23deg,
      #9df3ff 5.84%,
      #bcffe5 9.62%,
      #deefd1 38.94%,
      #ffe0be 56.96%,
      #fbc2d3 78.82%,
      #fabcd6 92.63%,
      #f281ff 99.88%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    //-webkit-text-stroke: 1.5px black;
  }

  .bg-qrcode {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.62);
    border-radius: 25px;
  }

  .nft {
    filter: drop-shadow(5px 11px 140px rgba(0, 0, 0, 0.25));
    border-radius: 35px;
  }

  .w-60 {
    width: 60%;
  }

  .w-30 {
    width: 30%;
  }

  .header img {
    height: 52px;
    width: 52px;
  }

  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  .modal-xl {
    min-width: 1440px;
  }

  .btn-nft {
    color: #fff;
    padding: 18px;
    font-size: 24px;
    border-radius: 20px;
    background-color: #0c0d0d;
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .btn-nft {
      font-size: 20px;
    }

    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 575px) {
    .bg-nft,
    .bg-main {
      width: 329px;
    }
  }

  @media screen and (max-width: 450px) {
    .btn-nft {
      padding: 18px 10px;
      font-size: 18px;
    }
  }
`;

/* Style for the Maintenance modal */
export const Maintenance = styled(ModalComponent)<IModelProps>`
  backdrop-filter: blur(100px);
  background-color: black; // Black background for the modal

  .modal-content {
    background-color: black; // Black background for the modal content
    color: white;
  }

  /* Style for the container with the background image */
  .image-container {
    background-image: url(${underMaintenance});
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; // Centers children vertically
    align-items: center; // Centers children horizontally
    height: 1080px;
    padding-top: 125px;
  }

  /* Style for the first line of text */
  .maintenance-text-1 {
    color: #e4e1e1;
    text-align: center;
    font-family: Jura;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 107.523%; /* 36.558px */
  }

  /* Style for the 'backSoon' image */
  .back-soon-image {
    padding-top: 41px;
    // Add styling as needed, such as width, height, etc.
  }
`;
