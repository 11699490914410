import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BsArrowLeftShort } from 'react-icons/bs';
import { Banner } from './styles';
import ModalCongratulations from '~/components/ModalCongratulations';
import Timeline from '~/components/Timeline';
import Player from '~/components/Player';
import retangle from '~/assets/defaults/thumbnail-opportunity-rectangle.png';

import thumbnail from '~/assets/defaults/learn-how.png';
import Attention from '~/components/Attention';
import WalletAvatar from '~/components/WalletAvatar';
import ButtonBack from '~/components/ButtonBack';

const Home: React.FC = () => {
  const history = useHistory();

  const steps = useMemo(
    () => [
      {
        value: 'Get Your Free NFT',
        active: true,
      },
      {
        value: 'How To Participate',
        active: true,
      },

      {
        value: 'Activate Your Profit Centers',
        active: true,
      },
      {
        value: 'Earn Even More!',
        active: true,
      },
      {
        value: 'Final Step',
        active: false,
        semi: true,
      },
    ],
    []
  );

  const handleNoWish = useCallback(() => {
    localStorage.setItem('wasNoWish', 'noWish');
    history.push(`${process.env.PUBLIC_URL}/dashboard`);
  }, [history]);

  return (
    <>
      <Banner className="position-relative overflow-hidden w-100">
        <div className="container-fluid">
          <div className="row vh-lg-100 overflow-auto">
            <div className="col-12 px-0">
              <Attention />
            </div>
            <div className="bg-gray col-lg-2 px-0">
              <Timeline steps={steps} />
            </div>
            <div className="col-lg-10 max-width pb-3 p-lg-4 p-xxl-5 text-center text-lg-left">
              <div className="d-none d-lg-flex justify-content-between logo mb-n5">
                <ButtonBack
                  onClick={() =>
                    history.push(
                      `${process.env.PUBLIC_URL}/activate-your-profit-centers`
                    )
                  }
                />
                <WalletAvatar />
              </div>
              <div className="row justify-content-center px-lg-5 mb-2 mb-lg-4">
                <div className="col-lg-9 zoom text-center d-flex justify-content-center">
                  {/* <div className="align-items-center d-none d-lg-flex">
                    <button
                      type="button"
                      onClick={() =>
                        history.push(
                          `${process.env.PUBLIC_URL}/activate-your-profit-centers`
                        )
                      }
                      className="arrow d-flex align-items-center"
                    >
                      <BsArrowLeftShort size={30} />
                      <span className="mr-2 d-block">Back</span>
                    </button>
                  </div> */}
                  <div>
                    <h1 className="display-lg-4 fw-bold">
                      <span>
                        <span>Earn Even More!</span>
                      </span>
                    </h1>
                    <div className="row justify-content-center">
                      <div className="col-lg-9 col-xxl-7">
                        <p className="gradient-parts">
                          <span>When you purchase a</span>{' '}
                          <b>staking contract</b>{' '}
                          <span>you earn a passive</span>{' '}
                          <b>60% APY from minting new tokens</b>{' '}
                          <span>as well as you qualify for</span>{' '}
                          <b>PROFITI Pool</b> <span>and participate in a</span>{' '}
                          <b>20% Revenue Share</b>{' '}
                          <span>from subscriptions</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center px-lg-5">
                <div className="col-11 col-md-9 position-relative">
                  <img src={retangle} alt="" className="back-retangle-right" />
                  <img src={retangle} alt="" className="back-retangle-left" />

                  <Player
                    src="https://prfti.s3.amazonaws.com/3.mov"
                    thumbnail={thumbnail}
                  />
                </div>
              </div>

              <div className="row justify-content-center px-lg-5 mt-4">
                <div className="col-11 zoom col-md-9 ">
                  <button
                    type="button"
                    onClick={() =>
                      history.push(`${process.env.PUBLIC_URL}/final-step`)
                    }
                    className="w-100 btn mt-4 mt-lg-0 btn-activate"
                  >
                    <b>EARN MORE NOW</b>
                  </button>
                </div>
                <div className="col-11 zoom col-md-9">
                  <button
                    type="button"
                    onClick={handleNoWish}
                    className="w-100 btn-no-wish mt-4 btn"
                  >
                    No, I do not wish to earn more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Banner>
    </>
  );
};

export default Home;
