import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import axios from 'axios';

import { Link, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { FiLock } from 'react-icons/fi';
import { useAuth } from '~/hooks/Auth';
import WalletRow from '~/components/WalletRow';

import { Avatar, Modal, Nfts, Container } from './styles';
import title from '~/assets/logo/ai-creator.svg';
import like from '~/assets/icons/like-icon.svg';
import bookmark from '~/assets/icons/bookmark-icon.svg';
import bookmarkActive from '~/assets/icons/bookmark-icon-active.svg';
import download from '~/assets/icons/download-icon.svg';
import share from '~/assets/icons/share-icon.svg';
import nftIcon from '~/assets/icons/nft-icon.svg';
import sample1 from '~/assets/defaults/sample_1.png';
import AiCreatorTitle from '~/components/AiCreatorTitle';
import ButtonBack from '~/components/ButtonBack';
import { web3store } from '~/store';

const NftCollectionsPrivate: React.FC = () => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [imgNft, setImgNft] = useState('');
  const [descriptionNft, setDescriptionNft] = useState('');
  const [avatar, setAvatar] = useState('');
  const apiUrl = process.env.REACT_APP_API as string;
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');

  type ImageCollectionItem = {
    id: number;
    img: string;
    description: string;
    seed: string;
    collection: number;
  };

  const [imageCollection, setImageCollection] = useState<ImageCollectionItem[]>(
    []
  );

  const [nftExample, setNftExample] = useState([
    {
      id: 1,
      actived: false,
      img: sample1,
      description:
        'A Gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
    },

    {
      id: 2,
      actived: false,
      img: sample1,
      description:
        'A Gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
    },

    {
      id: 3,
      actived: false,
      img: sample1,
      description:
        'A Gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
    },

    {
      id: 4,
      actived: false,
      img: sample1,
      description:
        'A Gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
    },
    {
      id: 5,
      actived: false,
      img: sample1,
      description:
        'A Gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
    },

    {
      id: 6,
      actived: false,
      img: sample1,
      description:
        'A Gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
    },

    {
      id: 7,
      actived: false,
      img: sample1,
      description:
        'A Gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
    },

    {
      id: 8,
      actived: false,
      img: sample1,
      description:
        'A Gorgeous Native American-indian warrior queen, feathers, cinematic, realistic, trending on Artstation, sharp focus, studio photo, intricate details, highly detailed, by Greg Rutkowski',
    },
  ]);

  useEffect(() => {
    if (user) {
      setAvatar(user.nft_img);
      axios
        .get(apiUrl.concat('v1/ai/image/favorites/', connectedMember.memberId))
        .then(function (acctresponse) {
          console.log(acctresponse.data);
          setImageCollection(acctresponse.data.images);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
        });
    }
  }, [user]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback((nft, desc) => {
    setShow(true);

    setImgNft(nft);
    setDescriptionNft(desc);
  }, []);

  const handleActivateNft = useCallback((index: number) => {
    setNftExample((prevNftExample) =>
      prevNftExample.map((nft) =>
        nft.id === index ? { ...nft, actived: !nft.actived } : nft
      )
    );
  }, []);

  return (
    <>
      <AiCreatorTitle />
      <Container>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ButtonBack
                onClick={() =>
                  history.push(
                    `${process.env.PUBLIC_URL}/products/ai-art/collections/all`
                  )
                }
                className="my-4 btn-back-art"
              />
              <h2>Favorites</h2>
              <h3 className="d-flex align-items-center my-4">
                <FiLock size={20} color="#5D5B5B" />
                <span className="ms-2 pt-1">Private collections</span>
              </h3>
            </div>
          </div>
        </div>
      </Container>
      <Nfts ref={formRef} onSubmit={() => '/'}>
        <div className="container">
          <div className="row img-example">
            <div className="col-12">
              <div className="row h-100">
                {nftExample.map((item, index) => (
                  <div key={item.id} className="col-3 my-2 px-2">
                    <div className="border-nft p-2">
                      <div className="hover-btns position-relative">
                        <button
                          type="button"
                          className="hover border-0 bg-transparent position-relative w-100"
                          onClick={() => handleShow(item.img, item.description)}
                        >
                          <img
                            src={item.img}
                            alt={item.img}
                            className="w-100"
                          />
                          <div className="position-absolute p-3 bg" />
                        </button>
                        <div className="btns d-flex justify-content-between position-absolute w-100">
                          {/* <button type="button">Mint NFT</button> */}
                          {/* <button type="button">Regenerate</button> */}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center px-3 py-3">
                        <div className="d-flex align-items-center w-auto avatar-text">
                          <div className="avatar me-2 d-flex align-items-center text-center rounded-circle">
                            <Avatar src={avatar} className="rounded-circle" />
                          </div>
                          {user?.screen_name}
                        </div>
                        <div>
                          <button
                            type="button"
                            className="border-0 bg-transparent"
                            onClick={() =>
                              handleShow(item.img, item.description)
                            }
                          >
                            <img
                              src={nftIcon}
                              title="Nft"
                              alt="Nft"
                              className="px-2"
                            />
                          </button>
                          <a
                            href={item.img}
                            download="My_image"
                            className="border-0 bg-transparent"
                          >
                            <img
                              src={download}
                              title="Download"
                              alt="Download"
                              className="px-2"
                            />
                          </a>

                          <button
                            type="button"
                            className="border-0 bg-transparent"
                            onClick={() => handleActivateNft(index + 1)}
                          >
                            <img
                              src={item.actived ? bookmarkActive : bookmark}
                              title="Save"
                              alt="Bookmark"
                              className="px-2"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Nfts>
      <Modal
        className="modal-nft text-white d-flex"
        size="xl"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0 pb-0">
          <button
            type="button"
            className="modal-close ms-auto fw-bold border-0 bg-transparent"
            onClick={handleClose}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body className="padding-modal">
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 text-center position-relative" />

              <div className="col-6 pe-5 position-relative">
                <img src={imgNft} alt="Logo fox" className="w-100 img-nft " />
                <button
                  type="button"
                  className="border-0 bg-transparent btn-like"
                >
                  <img src={like} alt="Like" />
                </button>
                <button
                  type="button"
                  className="border-0 bg-transparent btn-dislike"
                >
                  <img src={like} alt="Like" />
                </button>
              </div>

              <div className="col-6 text-center">
                <div className="right-side p-5 h-100 d-flex flex-column justify-content-between">
                  <p className="mt-4">{`“${descriptionNft}”`}</p>
                  <div className="d-flex justify-content-around btns-share">
                    <div className="text-center">
                      <a href={imgNft} className="d-flex" download="My_image">
                        <img src={download} alt="Download" />
                      </a>
                      Download
                    </div>
                    <div className="text-center">
                      <button type="button">
                        <img src={bookmark} alt="Bookmark" />
                      </button>
                      <br />
                      Collection
                    </div>
                    <div className="text-center">
                      <button type="button">
                        <img src={share} alt="share" />
                      </button>
                      <br />
                      Share
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn-launchpad mt-4 py-3 border-0 fw-bold w-100"
                  >
                    Mint With NFT Launchpad
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NftCollectionsPrivate;
