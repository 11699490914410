import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { formatPrice } from '~/utils/format';
import { web3store } from '~/store';

import { Container } from './styles';
import { CongratsModal } from '~/pages/Sales/ModalConfirmation';
import Loading from '~/components/Loading';
import ModalSwap from '~/components/ModalSwap';
import WalletAvatar from '~/components/WalletAvatar';

import product from '~/assets/defaults/product.png';
import logoWhite from '~/assets/logo/logo-p-white.svg';
import swalError from '~/utils/swalError';

interface IResumProps {
  qtd: number;
  activeResum?: boolean;
  disabled?: boolean;
}

const Resum: React.FC<IResumProps> = ({ activeResum, qtd, disabled }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [, setBuyStakingAmt] = web3store.useState('buyStakingAmt');
  const [balusdpi] = web3store.useState('balusdpi');
  const [, setCartPurchaseStake] = web3store.useState('cartPurchaseStake');
  const [cartPurchaseStakeTX, setCartPurchaseStakeTX] = web3store.useState(
    'cartPurchaseStakeTX'
  );
  const [loading, setLoading] = useState(false);
  const [exchangeFinished, setExchangeFinished] = useState(false);

  const usdpi = useMemo(
    () => parseFloat((parseInt(balusdpi, 10) / 10 ** 18).toFixed(2)),
    [balusdpi]
  );

  useEffect(() => {
    setBuyStakingAmt(qtd.toString().slice(0, 4));
  }, [qtd, setBuyStakingAmt]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleClickPay = useCallback(() => {
    if (usdpi >= qtd) {
      console.log('**************'); // ToDo: Remove this line after debug
      // ToDo: Display Mask
      setLoading(true);
      setCartPurchaseStake(true);
      // setShow(true);
    } else {
      swalError({
        message: 'Insufficient balance',
        textButton: 'Try Again',
      });
    }
    setExchangeFinished(false);
  }, [qtd, setCartPurchaseStake, usdpi]);

  const handleExchangeFinished = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setExchangeFinished(true);
    }, 3000);
  }, []);

  useEffect(() => {
    if (exchangeFinished) {
      handleClickPay();
    }
  }, [exchangeFinished, handleClickPay, usdpi]);

  const handleNoWish = useCallback(() => {
    localStorage.setItem('wasNoWish', 'noWish');
    history.push(`${process.env.PUBLIC_URL}/dashboard`);
  }, [history]);

  useEffect(() => {
    if (cartPurchaseStakeTX !== '') {
      // ToDo: Close mask
      setLoading(false);
      localStorage.setItem('wasNoWish', 'noWish');
      history.push(`${process.env.PUBLIC_URL}/dashboard`);
      setCartPurchaseStakeTX('');
    }
  }, [cartPurchaseStakeTX, history, setCartPurchaseStakeTX]);

  return (
    <Container
      active={activeResum}
      className="zoom-resum mt-4 mt-lg-0 h-100 bg-gray p-4 text-center text-lg-start"
      id="resum"
    >
      <div className="d-flex justify-content-center mt-4 mb-5">
        <WalletAvatar />
      </div>
      <div className="">
        <h4 className="mb-4">Summary</h4>
        <div className="d-flex flex-nowrap align-items-center justify-content-between mb-5 mb-xxl-4 px-3 px-lg-0">
          <div className="d-flex align-items-center w-100 w-sm-auto">
            <img src={product} alt="Logo" />
            <p className="small normal-xxl mb-0 ms-3 me-xxl-5">
              Staking <br />
              Contract
            </p>
          </div>
          <div className="d-flex w-100 w-lg-auto justify-content-between justify-content-lg-start align-items-center">
            <div />
            <p className="h5 mb-0 text-green">
              ${formatPrice(qtd).slice(0, -3)}
            </p>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between box-dark-gray p-3">
          <p className="mb-0">Total to pay today:</p>
          <p className="h3 text- mb-0">${formatPrice(qtd).slice(0, -3)}</p>
        </div>
        <div className="ps me-3 mt-1">*Blockchain/network fees apply</div>
        {usdpi < qtd ? (
          <ModalSwap
            btnText="FINALIZE ORDER"
            className="btn-swap w-100 mt-4 fw-bold"
            onExchangeFinished={handleExchangeFinished}
          />
        ) : (
          <button
            type="button"
            onClick={handleClickPay}
            className="payment w-100 mt-4"
            disabled={disabled}
          >
            FINALIZE ORDER
          </button>
        )}
        <button
          type="button"
          onClick={handleNoWish}
          className="btn-skip h5 text-center mt-4"
        >
          <u>
            No, I want to <b>miss out on this once in a lifetime opportunity</b>{' '}
            even though I might never be able to get tokens and at this price
            ever again and miss the revenue distribution from the
            PROFITi&nbsp;Pool
          </u>
        </button>
        <span className="get-usdpi-title text-center d-block mt-5 mb-3">
          Don’t have USDPI on your wallet?
        </span>
        <div className="position-relative btn-get-usdpi d-flex">
          <ModalSwap btnText="GET USDPI HERE" className="w-100 py-3 px-4" />
        </div>
      </div>
      <CongratsModal show={show} onHide={handleClose} />
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="WAITING FOR BLOCKCHAIN..."
        className="zoom-1-3"
        active={loading}
      />
    </Container>
  );
};

export default Resum;
