import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';

import { Container } from './styles';
import logoSwap from '~/assets/logo/logo-swap.png';
import ethLogo from '~/assets/logo/eth-wallet.png';

interface BalanceProps {
  ethBalance: string;
  usdpiBalance: string;
}

const WalletRowAutoAffiliate: React.FC<BalanceProps> = ({
  ethBalance,
  usdpiBalance,
}) => {
  const [displayedEthBalance, setDisplayedEthBalance] = useState(ethBalance);
  const [displayedUsdpiBalance, setDisplayedUsdpiBalance] =
    useState(usdpiBalance);

  useEffect(() => {
    const weiEthValue = ethers.BigNumber.from(ethBalance);
    const formattedEthValue = ethers.utils.formatUnits(weiEthValue, 18);
    // Convert the formatted value to a JavaScript Number
    const numericEthValue = parseFloat(formattedEthValue);
    // Use toPrecision() to display 8 significant digits
    const displayedEthValue = numericEthValue.toPrecision(4);

    const weiUsdpiValue = ethers.BigNumber.from(usdpiBalance);
    const formattedUsdpiValue = ethers.utils.formatUnits(weiUsdpiValue, 18);
    const numericUsdpiValue = parseFloat(formattedUsdpiValue);
    const displayedUsdpiValue = numericUsdpiValue.toFixed(2);

    setDisplayedEthBalance(displayedEthValue);
    setDisplayedUsdpiBalance(displayedUsdpiValue);
  }, [ethBalance, usdpiBalance]);

  return (
    <Container className="ps-5">
      <div className="d-flex align-items-center mb-3">
        <div
          className="bg-values d-flex wm-tooltip"
          data-tooltip="Your balance of $ETH Tokens"
        >
          <button type="button" className="pe-none border-0 bg-transparent">
            <img src={ethLogo} alt="Logo" />
          </button>
          <span className="d-flex align-items-center h6 text-white mb-0 px-3 px-xl-4">
            {displayedEthBalance}
          </span>
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div
          className="bg-values d-flex wm-tooltip"
          data-tooltip="Your balance of $USDPI Tokens"
        >
          <button type="button" className="pe-none border-0 bg-transparent">
            <img src={logoSwap} alt="Logo" />
          </button>
          <span className="d-flex align-items-center h6 text-white mb-0 px-3 px-xl-4">
            $ {displayedUsdpiBalance}
          </span>
        </div>
      </div>
    </Container>
  );
};

export default WalletRowAutoAffiliate;
