import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { XyzTransition, XyzTransitionGroup } from '@animxyz/react';
import { Container, DivFade } from './styles';

import sun from '~/assets/defaults/sun-example.png';
import picasso from '~/assets/defaults/picasso-example.png';
import rabit from '~/assets/defaults/rabit-example.png';

const LoadingNft: React.FC = () => {
  const [progress, setProgress] = useState(0);
  const [random, setRandom] = useState(Math.random());
  const randomNumber = Math.floor(random * 3 + 1);

  useEffect(() => {
    if (progress < 100) {
      setTimeout(() => {
        setProgress(progress + 10);
        setRandom(Math.random());
      }, 4900);
    }
  }, [progress]);

  return (
    <Container>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-9 px-0 pb-4 mt-5 pt-5">
            <ProgressBar now={progress} className="my-5" />
          </div>
          <div className="col-9 px-0 position-relative">
            <XyzTransitionGroup
              xyz="fade small duration-7"
              className="transitions"
            >
              {(progress === 10 || progress === 40 || progress === 70) && (
                <DivFade key="1" className="d-flex bg-card xyz-absolute">
                  <div>
                    <img src={sun} alt="Sun" className="" />
                  </div>
                  <div className="d-flex flex-column p-5">
                    <span>Tip</span>
                    <p className="mt-2 mb-5">
                      Describe a famous fotografer style as inspiration
                    </p>
                    <p>
                      “A synthwave style sunset above the reflecting water of
                      the sea, digital art by Paul Reiffer”
                    </p>
                  </div>
                </DivFade>
              )}

              {(progress === 20 || progress === 50 || progress === 80) && (
                <DivFade key="2" className="d-flex bg-card xyz-absolute">
                  <div>
                    <img src={picasso} alt="picasso" className="" />
                  </div>
                  <div className="d-flex flex-column p-5">
                    <span>Tip</span>
                    <p className="mt-2 mb-5">
                      Describe a famous artist as inspiration
                    </p>
                    <p>“Picasso painting of an angry monkey”</p>
                  </div>
                </DivFade>
              )}

              {(progress === 30 ||
                progress === 60 ||
                progress === 90 ||
                progress === 0) && (
                <DivFade key="3" className="d-flex bg-card xyz-absolute">
                  <div>
                    <img src={rabit} alt="rabit" className="" />
                  </div>
                  <div className="d-flex flex-column p-5">
                    <span>Tip</span>
                    <p className="mt-2 mb-5">
                      Describe the lighting to improve aesthetics
                    </p>
                    <p>
                      “bored rabbit, cyberpunk 2077 cyborg face cybernetics,
                      realistic, uplight, dramatic light”
                    </p>
                  </div>
                </DivFade>
              )}
            </XyzTransitionGroup>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LoadingNft;
