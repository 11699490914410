import React from 'react';

import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      {/* <div className="container">
        <div className="row align-items-end justify-content-between text-center text-md-left">
          <div className="col-md-4 col-lg-2 text-center">
            <Bounce left cascade>
              <img src={logo} alt="logo" className="w-50" />
              <div className="d-flex justify-content-between mt-4 px-5 px-md-0">
                <a href="/#">
                  <img src={twitter} alt="Twitter" />
                </a>
                <a href="/#">
                  <img src={facebook} alt="Facebook" />
                </a>
                <a href="/#">
                  <img src={telegram} alt="Telegram" />
                </a>
                <a href="/#">
                  <img src={instagram} alt="Instagram" />
                </a>
              </div>
            </Bounce>
          </div>
          <div className="col-md-6 col-lg-5 px-0 px-lg-3 mt-5">
            <Bounce right>
              <p className="mb-0 small nomal-md text-md-right text-white">
                © 2021 Wiserr, Inc. All right reserved <br />
                <span className="small normal-md">
                  <a href="#/" className="text-white">
                    Privacy Policy
                  </a>{' '}
                  |{' '}
                  <a href="#/" className="text-white">
                    Terms of Service
                  </a>{' '}
                  |{' '}
                  <a href="#/" className="text-white">
                    Security
                  </a>{' '}
                  |{' '}
                  <a href="#/" className="text-white">
                    Cookies
                  </a>
                </span>
              </p>
            </Bounce>
          </div>
        </div>
      </div> */}
    </Container>
  );
};

export default Footer;
