import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IModal {
  loading: boolean;
}

interface ICard {
  background: string;
}

export const Button = styled.button`
  :disabled {
    opacity: 0.7;
  }
`;

export const Card = styled.div<ICard>`
  background: ${(props) => props.background};
  border-radius: 20px;

  h2 {
    font-weight: 700;
    font-size: 40px;
    color: #ffffff;
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 0;
  }
`;

export const SelectCoin = styled.div`
  button {
    font-family: 'Poppins';
    color: #fff;
    background: #1e1e20;
    border: 1px solid #1e1e20;
    transition-duration: 0.3s;
    padding: 5px;
    border-radius: 5px;
    :hover {
      background: transparent;
      border: 1px solid #494949;
    }
  }

  p {
    width: 100px;
  }

  .active {
    background: transparent;
    border: 1px solid #494949;
  }
`;

export const Modal = styled(ModalComponent)<IModal>`
  font-family: 'Poppins', sans-serif;
  z-index: ${(props) => (props.loading ? 800 : 1055)};

  h2 {
    color: #dadada;
    font-size: 64px;
  }

  h4 {
    font-weight: 600;
    font-size: 14px;

    color: #dadada;
  }

  .buy-text {
    font-weight: 400;
    font-size: 14px;
    color: #f7951c;
  }

  .buy-here {
    background: linear-gradient(
      84.9deg,
      rgba(247, 149, 27, 0.8) 10.36%,
      rgba(248, 166, 66, 0.8) 97.55%
    );
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    border: none;
    color: #ffffff;
  }

  .w-45 {
    width: 45%;
  }

  .w-10 {
    width: 10%;
  }

  .text-table {
    font-weight: 600;
    font-size: 18px;
    color: #cbccce;
  }

  .wm-tooltip {
    position: relative;
  }

  .wm-tooltip:after {
    background-color: #212122;
    color: #8c8c8c;
    border: 1px solid #262626;
    border-radius: 15px;
    box-sizing: border-box;
    content: attr(data-tooltip);
    display: none;
    padding: 15px;
    font-size: 10px;
    position: absolute;
    right: -109px;
    bottom: 60px;
    z-index: 10000;
    text-align: justify;
  }

  .wm-tooltip:hover:after {
    display: block;
  }

  .estimate {
    font-weight: 400;
    font-size: 12px;
    display: block;
    color: #505253;
  }

  .values {
    font-weight: 600;
    font-size: 20px;
    color: #5e5e5e;
  }

  .text-enough {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ff1a50;
  }

  .bg-number {
    background: rgba(34, 37, 39, 0.3);
    border: 1px solid #393c3d;
    border-radius: 20px;

    button {
      top: calc(50% - 16px);
      right: 5%;
      background: #1d2023;
      border: 1px solid #00f9ab;
      border-radius: 10px;
      font-weight: 700;
      font-size: 12px;
      color: #797979;
      padding: 6px 19px;
    }
  }

  .number {
    font-size: 64px;
    color: #dadada;
    border-bottom: 1px solid #393c3d;
  }

  .color {
    border-top: 1px solid #393c3d;
    color: #5e5e5e;
    width: 300px;
    display: block;
    margin: 0 auto;
  }

  .values span {
    font-weight: 400;
    font-size: 16px;
    color: #505253;
  }

  .color-negative {
    color: #ff1a50;
  }

  .color-positive {
    color: #96ffd9;
  }

  .arrows {
    background-color: #262728;
    padding: 10px 8px;
  }

  .yellow-card {
    border-radius: 20px;
  }

  .green-card {
    border-radius: 20px;
  }

  .yellow-card h2,
  .green-card h2 {
    font-weight: 700;
    font-size: 40px;
    color: #ffffff;
  }

  .yellow-card h3,
  .green-card h3 {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 0;
  }

  .value {
    font-weight: 700;
    font-size: 32px;
    color: #dadada;
  }

  .bd-bottom {
    border-bottom: 1px solid #393c3d;
  }

  .btn-swap {
    font-weight: 600;
    font-size: 14px;
    background-color: transparent;
    color: #dadada;
    border: none;
    border-bottom: 2px solid #96ffd9;
  }

  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  .input-mask {
    opacity: 1;

    input {
      font-weight: bold;
      text-align: center;
      font-size: 64px;
      color: #dadada;
      background-color: transparent;

      ::placeholder {
        color: #252526;
        font-weight: 800;
      }
    }
  }

  .logo-usdpi {
    height: 70px;
    width: 70px;
  }

  .pad-left {
    padding-left: 5rem;
  }

  @media screen and (min-width: 1400px) {
    .modal-xl {
      min-width: 1340px;
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.75;
    }
  }

  @media screen and (max-width: 1399px) {
    .modal-dialog {
      zoom: 0.65;
    }
  }
`;

export const LoadingDiscover = styled.div`
  z-index: 2000;
  position: fixed;

  .loading-box {
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.25) -38.97%,
      rgba(0, 0, 0, 0.25) 75.53%
    );
    backdrop-filter: blur(25px);
    span {
      font-weight: 400;
      font-size: 20px;
      color: #fff;
      text-align: center;
    }
  }

  .animation-loading {
    height: 152px;
    width: 152px;

    img {
      position: relative;
      z-index: 300;
      top: 10px;
      left: 10px;
      height: 134px;
      width: 134px;
      background: rgba(0, 0, 0);
      backdrop-filter: blur(5px);
    }
  }

  .box-1 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: #fff;
    animation: position-box-1 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-1 {
    0% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    25% {
      top: calc(100% - 37px);
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    50% {
      top: calc(100% - 114px);
      left: 0;
      width: 37px;
      height: 114px;
    }

    75% {
      top: 0;
      left: 0;
      width: 114px;
      height: 37px;
    }

    100% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }
  }

  .box-2 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: #fff;
    animation: position-box-2 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-2 {
    0% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }

    25% {
      top: 0;
      left: 0;
      width: 37px;
      height: 114px;
    }

    50% {
      top: 0;
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    75% {
      top: calc(100% - 114px);
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    100% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }
  }
`;
