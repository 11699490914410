import styled from 'styled-components';

export const Container = styled.div`
  .container-fluid {
    max-width: 1800px;
  }

  .bg-nft {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border: 1px solid #fff;
    border-radius: 40px;
    //height: 470px;
    width: 100%;
  }

  .bg-nft img {
    //height: 70%;
  }

  .bg-nft span {
    color: #fffefb;
    font-size: 30px;
    line-height: 37px;
  }

  .bg-serial-qrcode {
    position: absolute;
    bottom: 0px;
    left: 0px;

    background-color: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(7.5px);

    border-radius: 0px 0px 35px 35px;
  }

  .bg-qrcode {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.62);
    border-radius: 25px;
  }

  .nft {
    //filter: drop-shadow(5px 11px 140px rgba(0, 0, 0, 0.25));
    border-radius: 35px;
  }
`;
