import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(152, 148, 148, 0.1);
  box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(15px);
  border-radius: 30px;

  .more {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #7d7777;
    transition-duration: 0.3s;
  }

  .more-active {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #ffffff;
  }

  .height-cards-more-cubes {
    max-height: 430px;
    overflow: auto;
  }

  .bg-btn {
    background: rgba(152, 148, 148, 0.1);
    //box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
    backdrop-filter: blur(15px);
    border-radius: 20px;
    transition: border 0.3s;
    border: 1.5px solid transparent;
  }

  .bg-btn:hover {
    border: 1.5px solid var(--Gradient-2, #9df3ff);
  }

  .cube-selected {
    border: 1.5px solid var(--Gradient-2, #9df3ff);
  }

  .prize {
    font-weight: 500;
    font-size: 12px;
    line-height: 35px;
    color: #ffffff;
  }

  .prize-value {
    color: #fff;
    font-family: 'Inter';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .cube-size {
    color: #fff;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .cube-entry {
    color: #fff;
    font-family: 'Inter';
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
  }

  .payout-label {
    color: #bbb;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;
