import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import banner from '~/assets/banners/nft-lp.png';
import rings from '~/assets/banners/signup-rings.png';

interface IWidthScreen {
  width: number;
}

export const Container = styled.div`
  h1 {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 400;
    font-size: 180px;
    line-height: 143px;
    letter-spacing: -0.04em;
    color: #fff;
    //padding: 2.5rem 1.5rem;
    padding-top: 18%;
    span {
      font-family: 'Expletus Sans', sans-serif !important;
    }
  }

  h2 {
    font-family: 'Source Sans Pro', sans-serif;

    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    color: #d6d6d6;
    span {
      font-weight: 600;
      color: #fff;
    }
  }

  .second-bubble h3 {
    font-family: 'Source Sans Pro', sans-serif;

    font-weight: 300;
    font-size: 22px;
    line-height: 31px;

    color: #dadada;
    span {
      font-weight: 600;
    }
  }

  .fw-600 {
    font-weight: 600;
  }

  .hello {
    color: #fff;
    font-family: 'Quicksand';
    font-size: 40px;
    font-weight: 400;
    line-height: 143px;
    letter-spacing: -1.6px;
  }

  .rings {
    background: url(${rings});
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right;
    //padding-top: 10%;
  }

  .banner {
    background: url(${banner});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
  }

  .bg-main {
    border-radius: 35px;
    background: rgba(23, 23, 25, 0.66);
    box-shadow: 0px -15px 30px 0px rgba(0, 0, 0, 0.27);
    backdrop-filter: blur(5px);
  }

  .btn-mint {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 24px;
    font-weight: 700;
    padding: 22px 49px;
    font-size: 24px;
    color: #000;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }
  }

  @media screen and (min-width: 992px) {
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1200px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
  }

  @media screen and (min-width: 1600px) {
    .container {
      max-width: 1500px;
    }
  }
`;

export const Retangle = styled.div<IWidthScreen>`
  background: rgba(255, 255, 255, 0.1);
  border-left: 1px solid #ffffff;
  backdrop-filter: blur(5px);
  width: 40%;
  //width: calc(${(props) => props.width - 1920 + 615}px);

  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;

  img {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 30px;
    right: 34px;
  }

  @media screen and (min-width: 2200px) {
    width: 36%;
  }
`;

export const Modal = styled(ModalComponent)`
  hr {
    border-top: solid 1px #434343;
    opacity: 1;
  }

  .body {
    button {
      background: transparent;
      border: none;
      color: #bebebe;
      text-align: center;
      font-family: 'Quicksand';
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 70px;
      letter-spacing: -1.04px;
      transition-duration: 0.3s;

      img {
        width: 88px;
        height: 88px;
      }

      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1200px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1400px) {
    .modal-dialog {
      zoom: 1;
    }
  }
`;

export const ModalFinish = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      color: #dadada;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .texts p:nth-child(1),
    .texts p:nth-child(2) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 22px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts p:nth-child(1) {
      color: #dadada !important;
    }

    .texts .terms {
      background: #1d1d1f;
      border-radius: 12px;
      font-weight: 300;
      font-size: 16px;
      padding: 10px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: #7d7777;
      }
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-confirm {
      background: #696ffd;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .modal-lg {
      min-width: 940px;
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
  }
`;
