/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';

import Lottie from 'react-lottie';

import { MdClose } from 'react-icons/md';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Modal } from './styles';
import InputCheckbox from '~/components/InputCheckbox';

import attention from '~/assets/animations/attention.json';

interface ITerms {
  show?: boolean;
  onClose?(): void;
  onRegister?(): void;
}

export const TermsProvider: React.FC<ITerms> = ({
  show,
  onClose,
  onRegister,
}) => {
  const scroll = useRef<HTMLDivElement>(null);
  const [checked, setChecked] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState('');

  useEffect(() => {
    const now = new Date();
    const formattedDate = format(now, 'MMMM dd, yyyy');
    setEffectiveDate(formattedDate);
    // setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setChecked(false);
  }, [onClose]);

  const handleRegister = useCallback(() => {
    if (onRegister) {
      onRegister(); // Call parent method when registration button is clicked
    }
    handleClose(); // Optionally close the modal afterward
  }, [onRegister, handleClose]);

  return (
    <Modal show={show} size="xl" className="modal-terms" centered>
      <button
        type="button"
        className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
        onClick={handleClose}
      >
        <MdClose color="#E9E9E9" size={20} />
      </button>

      <Modal.Header className="justify-content-center border-0 px-3 px-sm-5 pb-0 pt-4">
        <div className="overflow-hidden w-100 px-sm-4">
          <Modal.Title className="pr-3">
            <Lottie
              options={{
                animationData: attention,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={160}
              width={160}
            />
            <h2 className="h5 text-center">Please Read</h2>
            <h6>You must understand and agree to the terms and conditions.</h6>
            <p className="center-italic">
              (scroll to the bottom of the terms to agree)
            </p>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-4 pb-0 px-3 px-lg-5">
        <div className="bg-content px-3 py-4 pt-lg-2 px-lg-5 pb-lg-5">
          <div ref={scroll} className="content scroll-click">
            <h2>
              How <b>Smart</b>
              <span>Trading</span> works
            </h2>
            <h3 className="my-4">
              <b>SmartTrading: Terms and Conditions</b>
            </h3>
            <h3 className="mb-4">
              Effective Date: <span>{effectiveDate}</span>
            </h3>
            <h4>1. Introduction</h4>
            <p className="mb-3">
              Welcome to SmartTrading. These Terms and Conditions govern your
              use of our automated trading bot system. By accessing or using
              SmartTrading, you agree to comply with and be bound by these Terms
              and Conditions. Please read them carefully.
            </p>
            <h4>2. Eligibility</h4>
            <p className="mb-3">
              To use SmartTrading, you must be at least 18 years old and possess
              the legal capacity to enter into this Agreement. By using
              SmartTrading, you represent and warrant that you meet these
              eligibility requirements.
            </p>
            <h4>3. Account Registration</h4>
            <ul>
              <li>
                <p>
                  <span>Registration:</span> o use SmartTrading, you must create
                  an account with PROFITi. You agree to provide accurate and
                  complete information during the registration process.
                </p>
              </li>
              <li>
                <p>
                  <span>Security:</span> You are responsible for maintaining the
                  confidentiality of your account credentials and for all
                  activities that occur under your account. You must notify
                  PROFITi immediately of any unauthorized use of your account.
                </p>
              </li>
            </ul>
            <h4>4. Use of SmartTrading</h4>
            <ul>
              <li>
                <p>
                  <span>License:</span> PROFITi grants you a limited,
                  non-exclusive, non-transferable license to use SmartTrading
                  for personal, non-commercial purposes, subject to these Terms
                  and Conditions.
                </p>
              </li>
              <li>
                <p>
                  <span>Subscription Payments:</span> Access to SmartTrading is
                  included with your PROFITi subscription. Subscription payments
                  are made in USDPI. If your subscription payments are not
                  current, your ability to trade using SmartTrading will be
                  suspended until payments are up to date.
                </p>
              </li>
              <li>
                <p>
                  <span>Restrictions:</span> You agree not to:
                  <ul>
                    <li>Use SmartTrading for any unlawful purpose.</li>
                    <li>
                      Modify, distribute, or create derivative works based on
                      SmartTrading.
                    </li>
                    <li>
                      Reverse engineer, decompile, or disassemble SmartTrading.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
            <h4>5. Trading and Risks</h4>
            <ul>
              <li>
                <p>
                  <span>Automated Trading:</span> SmartTrading uses algorithms
                  to execute trades on your behalf. You understand and agree
                  that all trading decisions are made by the algorithm without
                  human intervention.
                </p>
              </li>
              <li>
                <p>
                  <span>Risk Disclosure:</span> Trading in financial markets
                  involves significant risk. You may lose some or all of your
                  invested capital. You should only trade with money you can
                  afford to lose. PROFITi does not guarantee any specific
                  results or returns from using SmartTrading.
                </p>
              </li>
              <li>
                <p>
                  <span>No Advice:</span> The information provided by
                  SmartTrading is not intended as financial advice. You should
                  consult with a qualified financial advisor before making any
                  trading decisions.
                </p>
              </li>
            </ul>
            <h4>6. Deposits and Withdrawals</h4>
            <ul>
              <li>
                <p>
                  <span>Deposits:</span> You may deposit funds into your trading
                  account at any time without restrictions.
                </p>
              </li>
              <li>
                <p>
                  <span>Withdrawals:</span> You may withdraw funds from your
                  trading account at any time without restrictions. However, the
                  system will always check if you have any balance due to cover
                  your subscription payment. If there is a balance due, the
                  system will deduct the amount needed to cover your
                  subscription payment before processing the withdrawal.
                </p>
              </li>
            </ul>
            <h4>7. Trading Limits</h4>
            <p>
              Each PROFIT Center allows you to trade up to $5,000. If you have
              multiple PROFIT Centers, you can trade up to the combined limit.
              For example, if you have six PROFIT Centers, you can trade up to
              $30,000.
            </p>
            <h4>8. Additional Benefits</h4>
            <p>
              Members can increase their potential returns by purchasing staking
              contracts. Your maximum monthly profit cap may increase up to 15%
              per month based on the amount of staking. Once the monthly profit
              cap is reached, the system will stop trading on your behalf and
              will resume trading the following month. The profit caps are as
              follows:
            </p>
            <ul className="mt-4">
              <li>
                <p>
                  <span>No staking to $500 staking:</span> Up to 5% monthly
                  profit cap
                </p>
              </li>
              <li>
                <p>
                  <span>$500 to $2,000 staking:</span> Up to 10% monthly profit
                  cap
                </p>
              </li>
              <li>
                <p>
                  <span>Above $2,000 staking:</span> Up to 15% monthly profit
                  cap
                </p>
              </li>
            </ul>
            <h4>9. Fees and Payments</h4>
            <ul>
              <li>
                <p>
                  <span>Subscription Fees:</span> Access to SmartTrading is
                  included in your PROFITi subscription. Subscription payments
                  are made in USDPI.
                </p>
              </li>
              <li>
                <p>
                  <span>Payment Method:</span> You must provide a valid payment
                  method for subscription payments. By providing payment
                  information, you authorize PROFITi to charge the specified
                  fees to your payment method.
                </p>
              </li>
            </ul>
            <h4>10. Termination</h4>
            <ul>
              <li>
                <p>
                  <span>Termination by You:</span> You may terminate your use of
                  SmartTrading at any time by closing your account.
                </p>
              </li>
              <li>
                <p>
                  <span>Termination by PROFITi:</span> PROFITi reserves the
                  right to suspend or terminate your access to SmartTrading at
                  any time for any reason, including breach of these Terms and
                  Conditions.
                </p>
              </li>
            </ul>
            <h4>11. Limitation of Liability</h4>
            <ul>
              <li>
                <p>
                  <span>Disclaimer of Warranties:</span> SmartTrading is
                  provided “as is” and “as available” without warranties of any
                  kind, either express or implied. PROFITi disclaims all
                  warranties, including but not limited to merchantability,
                  fitness for a particular purpose, and non-infringement.
                </p>
              </li>
              <li>
                <p>
                  <span>Limitation of Liability:</span> PROFITi shall not be
                  liable for any indirect, incidental, special, consequential,
                  or exemplary damages arising from your use of SmartTrading,
                  even if PROFITi has been advised of the possibility of such
                  damages.
                </p>
              </li>
            </ul>
            <h4>12. Indemnification</h4>
            <p>
              You agree to indemnify and hold harmless PROFITi and its
              affiliates, officers, agents, and employees from any claim or
              demand, including reasonable attorneys’ fees, made by any third
              party due to or arising out of your use of SmartTrading, your
              violation of these Terms and Conditions, or your violation of any
              rights of another.
            </p>
            <h4>13. Governing Law and Dispute Resolution</h4>
            <ul>
              <li>
                <p>
                  <span>Governing Law:</span> These Terms and Conditions shall
                  be governed by and construed in accordance with the laws of
                  Dubai, UAE, without regard to its conflict of law principles.
                </p>
              </li>
              <li>
                <p>
                  <span>Dispute Resolution:</span> Any disputes arising under
                  these Terms and Conditions shall be resolved through good
                  faith negotiation. If negotiation fails, the dispute shall be
                  submitted to mediation or arbitration in accordance with the
                  rules of a mutually agreed-upon mediation or arbitration
                  entity.
                </p>
              </li>
            </ul>
            <h4>14. Amendments</h4>
            <p>
              PROFITi reserves the right to modify or update these Terms and
              Conditions at any time. You will be notified of any changes
              through the contact information provided during your registration
              process or via the SmartTrading platform. Continued use of
              SmartTrading after any such modifications will constitute your
              consent to such changes. It is your responsibility to regularly
              review these Terms and Conditions and stay informed of any
              updates.
            </p>
            <h4>15. Miscellaneous</h4>
            <ul>
              <li>
                <p>
                  <span>Entire Agreement:</span> These Terms and Conditions
                  constitute the entire agreement between you and PROFITi
                  regarding your use of SmartTrading and supersede all prior
                  agreements and understandings, whether written or oral.
                </p>
              </li>
              <li>
                <p>
                  <span>Severability:</span> If any provision of these Terms and
                  Conditions is found to be invalid or unenforceable, the
                  remaining provisions will remain in full force and effect.
                </p>
              </li>
              <li>
                <p>
                  <span>Waiver:</span> No waiver of any provision of these Terms
                  and Conditions shall be deemed a further or continuing waiver
                  of such provision or any other provision.
                </p>
              </li>
              <li>
                <p>
                  <span>Assignment:</span> You may not assign or transfer any of
                  your rights or obligations under these Terms and Conditions
                  without prior written consent from PROFITi. However, PROFITi
                  may assign this Agreement without restriction.
                </p>
              </li>
            </ul>
            <h3>
              <b>SmartTrading: Disclosures</b>
            </h3>
            <h4>1. Risk Disclosure</h4>
            <p>
              Trading in financial markets involves significant risk. The use of
              SmartTrading may result in losses, including the loss of all
              invested capital. You should only trade with money you can afford
              to lose. Past performance is not indicative of future results. No
              guarantees are made regarding the results or returns that may be
              achieved through the use of SmartTrading.
            </p>
            <h4>2. Automated Trading</h4>
            <p>
              SmartTrading operates using automated trading algorithms that make
              trading decisions without human intervention. While these
              algorithms are designed to execute trades based on specific
              criteria, there is no assurance that their performance will be
              successful. Market conditions can change rapidly, and automated
              systems may not respond as expected.
            </p>
            <h4>3. No Financial Advice</h4>
            <p>
              The information and signals provided by SmartTrading are not
              intended as financial advice and should not be construed as such.
              PROFITi does not provide personalized financial advice and does
              not consider your individual financial situation or goals. You
              should consult with a qualified financial advisor before making
              any trading decisions.
            </p>
            <h4>4. Performance and Results</h4>
            <p>
              The performance of SmartTrading may vary based on market
              conditions, account settings, and other factors. PROFITi does not
              guarantee any specific results or that the system will achieve any
              particular level of performance. All trading carries inherent
              risks, and you should be prepared for the possibility of losses.
            </p>
            <h4>5. Fees and Charges</h4>
            <p>
              Using SmartTrading may involve subscription fees, transaction
              fees, and other charges. These fees are outlined during the
              registration process and are subject to change. It is your
              responsibility to review and understand all applicable fees before
              using the service.
            </p>
            <h4>6. Data and Privacy</h4>
            <p>
              PROFITi respects your privacy and is committed to protecting your
              personal information. We collect, use, and disclose information in
              accordance with our Privacy Policy. By using SmartTrading, you
              consent to the collection and use of your information as described
              in the Privacy Policy.
            </p>
            <h4>7. Amendments to Disclosures</h4>
            <p className="mb-4">
              PROFITi reserves the right to update these Disclosures at any
              time. You will be notified of any changes through the contact
              information provided during your registration process or via the
              SmartTrading platform. It is your responsibility to regularly
              review these Disclosures and stay informed of any updates.
            </p>
            <p className="mb-4">
              By using SmartTrading, you acknowledge that you have read,
              understood, and agreed to these Terms and Conditions and
              Disclosures.
            </p>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="checkbox-input"
                name="check"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <span>
                I AGREE: By using SmartTrading,
                <b>you acknowledge that you have read, understood</b>, and{' '}
                <b>agreed to these Terms and Conditions</b> and Disclosures.{' '}
                <br />
                (Click here to agree)
              </span>
            </label>
            {/* <label htmlFor="check" className="d-flex ml-2">
              <InputCheckbox
                name="check"
                options={[
                  {
                    labelHtml: true,
                    label: `<span>I AGREE:</span> By using SmartTrading, <b>you acknowledge that you have read, understood</b>, and <b>agreed to these Terms and Conditions</b> and Disclosures.`,
                    value: `I AGREE: By using SmartTrading, you acknowledge that you have read, understood, and agreed to these Terms and Conditions and Disclosures.`,
                    selected: checked,
                  },
                ]}
                onChange={() => setChecked(!checked)}
              />
            </label> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 px-3 px-sm-5 pt-0 pb-3 my-4">
        <div className="d-flex justify-content-center justify-content-lg-between w-100">
          <button
            type="button"
            className="btn btn-decline ml-3"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`${
              checked ? 'opacity-100' : 'opacity-25 pe-none '
            } btn btn-accept`}
            onClick={handleRegister}
          >
            COMPLETE REGISTRATION{' '}
            <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsProvider;
