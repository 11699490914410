import styled from 'styled-components';

interface IDivFade {
  xyz?: string;
}

export const Container = styled.div`
  font-family: 'Poppins', sans-serif;
  img {
    border-radius: 25px;
    height: 342px;
  }

  span {
    font-weight: 600;
    font-size: 20px;
    color: #c9c9c9;
  }

  div p:nth-child(2) {
    font-weight: 400;
    font-size: 20px;
    line-height: 118%;
    color: #787878;
  }

  div p:nth-child(3) {
    font-weight: 400;
    font-size: 32px;
    line-height: 118%;
    color: #c9c9c9;
  }

  .transitions {
    --xyz-in-delay: 0.1s;
    --xyz-out-delay: 0.01s;
  }

  .vh-75 {
    height: 75vh;
  }

  .bg-card {
    background-color: #212122;
    border-radius: 25px;
  }

  .progress {
    background-color: #333;
    height: 5px;
  }

  .progress-bar {
    background-color: #e9e9e9;
  }
`;

export const DivFade = styled.div<IDivFade>``;
