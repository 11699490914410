import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  ::-webkit-scrollbar {
    background-color: #18191a;
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #1d2023;
  }

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body{
    -webkit-font-smoothing: antialiased;
    padding: 0 !important;
    background-color: #000;


  }

  body, input, button {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #606060;
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 500
  }

  button {
    cursor: pointer;
  }

  button:focus{
    outline: none;
    box-shadow: none !important;
  }

  a {
    text-decoration: none;
  }

  #qrCode{
    position: absolute;
    top: -10000px;
  }

  .font-weight-300{
    font-weight: 300 !important;
  }

  .font-weight-400{
    font-weight: 400 !important;
  }

  .font-weight-600{
    font-weight: 600 !important;
  }

  .h-42{
    height: 42px;
  }

  .reveal-w {
    width: 1440px !important;
    height: 983px !important;
    zoom: 1;
  }

  // inicio estlilização datepicker

  .react-datepicker {
    border-radius: 15px !important;
  }

  .react-datepicker__month-container {
    background: #17171a !important;
    border-radius: 15px !important;
  }

  .react-datepicker__header {
    background: #17171a !important;
    border-radius: 15px 15px 0 0 !important;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #bdbdbd !important;
  }

  .react-datepicker__month {
    border-radius: 15px !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #bdbdbd !important;
    :hover {
      color: #17171a !important;
    }
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    color: #bdbdbd !important;
    background: #17171a !important;
  }

  // fim estilização datepicker



  .border-silver {
    cursor: pointer;
    border: none;
    padding: 2px;
    background-image: linear-gradient(0deg, #242526, #242526),
      linear-gradient(180deg, #4A4B4B 0%, #c3c3c3 100%);
      background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;
    color: #e4e4e4;

    span {
      padding: 5px 10px;
      display: block;
    }
  }

  a.border-silver:hover, a.border-silver.active, button.border-silver:hover, button.border-silver.active {
    background-image: linear-gradient(180deg, #4A4B4B, #c3c3c3),
    linear-gradient(180deg, #4A4B4B, #c3c3c3);
    color: #fff;
    text-decoration: none;
  }

  .border-golden {
    cursor: pointer;
    border: none;
    padding: 2px;
    background-image: linear-gradient(180deg, #242526, #242526),
      linear-gradient(180deg, rgba(168, 107, 15), rgba(242, 201, 76, 1));
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;
    transition-duration: 0.3s;
    color: #fff;

    span {
      padding: 5px 10px;
      display: block;
    }
  }

  a.border-golden:hover:not([disabled]), a.border-golden.active, button.border-golden:hover:not([disabled]), button.border-golden.active {
    background-image: linear-gradient(180deg, rgba(168, 107, 15), rgba(242, 201, 76, 1)),
    linear-gradient(180deg, rgba(168, 107, 15), rgba(242, 201, 76, 1));
    color: #fff;
    text-decoration: none;
  }

  a.border-golden:disabled, button.border-golden:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .btn-gradient {
    background: linear-gradient(269.95deg, #e323ff 0.02%, #6A9AAE 97.45%);
    border-radius: 15px;
    color: #fff;
    border: none;
    height: 52px;
    transition-duration: 0.3s;
    font-size: 1.5rem;

    :hover {
      background: linear-gradient(
        269.95deg,
        rgba(227, 35, 255, 0.8) 0.02%,
        rgba(121, 209, 248, 0.8) 97.45%
      ) !important;
      color: #fff;
    }

    :disabled{
      background: linear-gradient(269.95deg, #e323ff 0.02%, #79d1f8 97.45%) !important;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .border-gradient {
    cursor: pointer;
    border: none;
    padding: 2px;
    background-image: linear-gradient(180deg, #242526, #242526),
    linear-gradient(239.23deg, #9DF3FF 7.84%, #BCFFE5 28.62%, #DEEFD1 38.94%, #FFE0BE 49.96%, #FBC2D3 64.82%, #FABCD6 67.63%, #F281FF 91.88%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 15px;
    transition-duration: 0.3s;
    color: #fff;

    span {
      padding: 5px 10px;
      display: block;
    }
  }

  .border-gradient-light {
    cursor: pointer;
    border: none;
    padding: 2px;
    background-image: linear-gradient(180deg, #323336, #323336),
    linear-gradient(239.23deg, #9DF3FF 7.84%, #BCFFE5 28.62%, #DEEFD1 38.94%, #FFE0BE 49.96%, #FBC2D3 64.82%, #FABCD6 67.63%, #F281FF 91.88%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 10px;
    transition-duration: 0.3s;
    color: #fff;

    span {
      padding: 5px 10px;
      display: block;
    }
  }

  .border-gradient-cancel {
    cursor: pointer;
    border: none;
    padding: 2px;
    background-image: linear-gradient(180deg, #242526, #242526),
    linear-gradient(269.95deg, #E323FF 0.02%, #6A9AAE 97.45%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 15px;
    transition-duration: 0.3s;
    color: #fff;

    span {
      padding: 5px 10px;
      display: block;
    }
  }



  .border-noGradient {
    cursor: pointer;
    border: none;
    padding: 2px;
    background-image: linear-gradient(180deg, #323336, #323336),
      linear-gradient(269.95deg, #323336 0.02%, #323336 97.45% ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 10px;
    transition-duration: 0.3s;
    color: #A3A3A4;

    span {
      padding: 5px 10px;
      display: block;
    }
  }

  button.border-gradient,
  button.border-gradient-cancel {
    transition-duration: 0.3;
    height: 50px;

    :hover{
      opacity: 0.7;
    }

    :disabled{
      opacity: 0.7;
      cursor: no-drop;
    }
  }

  label.border-noGradient {
    transition-duration: 0.3;

    :hover{
      background-image: linear-gradient(180deg, #323336, #323336),
        linear-gradient(239.23deg, #9DF3FF 7.84%, #BCFFE5 28.62%, #DEEFD1 38.94%, #FFE0BE 49.96%, #FBC2D3 64.82%, #FABCD6 67.63%, #F281FF 91.88%) !important;
      color: #fff;
    }
  }

  .error-input{
    border-color: #c53030 !important;
  }

  .loading-box {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: #00000055;
  }

  .zoom-1-3{
    zoom: 1.3;
  }

  .gradient-parts {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;

    b {
      font-weight: bold;
    }

    span {
      background-clip: unset;
      -webkit-background-clip: unset;
      color: #fff;
      -webkit-text-fill-color: #fff;
    }
  }

  .modal-wrong-network{
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.75) -38.97%,
      rgba(0, 0, 0, 0.85) 75.53%
    );
    backdrop-filter: blur(10px);

    .modal-content {
      background: #030303;
      box-shadow: 19px 49px 112px rgba(0, 0, 0, 0.4),
    }
  }

  .modal-signin-auto {
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(10px);

    .modal-content {
      background: #171719;
      box-shadow: 19px 49px 112px rgba(0, 0, 0, 0.4),
        inset 1px 1px 2px rgba(107, 107, 107, 0.3),
        inset -1px -1px 2px rgba(43, 43, 43, 0.5);
      border-radius: 40px;

      .close{
        color: #fff;
      }
    }
  }

  .modal-signin, .modal-news {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.15) -38.97%,
      rgba(0, 0, 0, 0.15) 75.53%
    );
    backdrop-filter: blur(10px);

    .modal-content {
      background: #17171a;
      box-shadow: 19px 49px 112px rgba(0, 0, 0, 0.4),
        inset 1px 1px 2px rgba(107, 107, 107, 0.3),
        inset -1px -1px 2px rgba(43, 43, 43, 0.5);
      border-radius: 30px;

      .close{
        color: #fff;
      }
    }
  }

  .modal-history-nft {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.15) -38.97%,
      rgba(0, 0, 0, 0.15) 75.53%
    );
    backdrop-filter: blur(10px);

    .modal-content {
      background: #171719;
      border-radius: 40px;
      border: none;
      .close{
        color: #fff;
      }
    }
  }

  .modal-terms{
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(2.5px);
  }

  .modal-backdrop{
    background-color: transparent!important;
  }

  .modal-news .modal-dialog .modal-content {
    box-shadow: none !important;
  }

  .modal-nft {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) -38.97%, rgba(0, 0, 0, 0.25) 75.53%);
    backdrop-filter: blur(25px);
      .modal-dialog .modal-content {
      box-shadow: none !important;
      border-radius: 25px;
      border: none!important;
      background: #212122;
    }
  }

  .choose-nft{
    .modal-content{
      width: 1300px;
    }
  }

  .modal-user-profile {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) -38.97%, rgba(0, 0, 0, 0.25) 75.53%);
    backdrop-filter: blur(25px);
    .modal-dialog .modal-content {
      box-shadow: none !important;
      background: #1D2023;
      border-radius: 42px;
      border: none!important;
    }
  }

  .modal-img-upload {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) -38.97%, rgba(0, 0, 0, 0.25) 75.53%);
    backdrop-filter: blur(25px);
      .modal-dialog .modal-content {
        box-shadow: none !important;
        border-radius: 25px;

        background: #212122;
      }

      /* .modal-dialog{
        width: 80%;
      } */

  }



  .modal-connect-wallet {
    background: rgba(214, 203, 210, 0.69);
    backdrop-filter: blur(7.5px);

    .modal-content {
      background: rgba(244, 247, 253, 0.42) !important;
      backdrop-filter: blur(10px);
      border-radius: 30px;
      border: none!important;
    }
  }

  .modal-news {
    .bg-title {
      background: rgba(0, 0, 0, 0.5);
    }

    .slick-prev {
      left: 15px;
      z-index: 100;
      top: 75%;
    }
    .slick-next {
      right: 15px;
      z-index: 100;
      top: 75%;
    }

    .slick-dots li.slick-active button:before {
      color: #cbccce !important;
      font-size: 13px !important;
    }

    .slick-dots li button:before {
      color: #4f4f4f !important;
      font-size: 13px !important;
    }

    .slick-dots {
      bottom: 25px;
    }

    .slick-dots li {
      margin: 0 !important;
    }

    .slick-prev:before,
    .slick-next:before {
      color: #cbccce;
    }
  }

  .font-weight-tiny{
    font-weight: 100 !important;
  }

  .opacity-0 {
    opacity: 0 !important;
  }

  .error-swal-box{
    padding-bottom: 76px;

    img{
      width: 230px;
    }

    p.h1{
      color: #DADADA;
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      color: #A6A2A2;
    }

    .swal2-actions{
      width: 100%;

      button.swal2-confirm{
        width: 75%;
        height: 50px;
        background: #6D50D0 !important;
        border-radius: 10px !important;
      }
    }

    @media screen and (min-width: 992px) {
      width: 630px;
      padding: 0 40px 76px;
    }
  }

  .swal2-container{
    z-index: 2000 !important;
  }

  .swal2-popup{
    background: #17171a !important;
    box-shadow: 19px 49px 112px rgba(0, 0, 0, 0.4),
      inset 1px 1px 2px rgba(107, 107, 107, 0.3),
      inset -1px -1px 2px rgba(43, 43, 43, 0.5) !important;
    border-radius: 30px;

    .swal2-title {
      color: #fff;
    }

    .swal2-html-container{
      color: #949494;
    }

    .swal2-confirm{
      background: linear-gradient(269.95deg, #e323ff 0.02%, #79d1f8 97.45%) !important;
      border-radius: 20px !important;
      color: #fff;
      border: none;
      height: 45px;
      transition-duration: 0.3s;
      width: 100%;
      font-weight: 600;

      :hover {
        background: linear-gradient(
          269.95deg,
          rgba(227, 35, 255, 0.8) 0.02%,
          rgba(121, 209, 248, 0.8) 97.45%
        ) !important;
        color: #fff;
      }

      :disabled{
        background: linear-gradient(269.95deg, #e323ff 0.02%, #79d1f8 97.45%) !important;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .btn-filter{
    background: #1D2023;
    border: 1px solid #4A4B4B;
    padding: 0 25px;
    height: 40px;
  }

  @media screen and (max-width: 1799px){
    .reveal-w {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px){
    .btn-gradient{
      font-size: 1.3rem;
    }

    .reveal-w {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 1199px){

  }

  @media screen and (max-width: 991px){
    .btn-gradient{
      height: 50px;
    }

    .modal-signin {
      .modal-content {
        .close{
          position: absolute;
          right: 1rem;
        }
      }
    }
  }

  /* COMPLEMENTO BS5 */
  body{
    .fw-medium {
      font-weight: 500 !important;
    }

    .fw-semibold {
      font-weight: 600 !important;
    }

    .h1, .h2, .h3, .h4, .h5, .h6,
    .h1-sm, .h2-sm, .h3-sm, .h4-sm, .h5-sm, .h6-sm,
    .h1-md, .h2-md, .h3-md, .h4-md, .h5-md, .h6-md,
    .h1-lg, .h2-lg, .h3-lg, .h4-lg, .h5-lg, .h6-lg,
    .h1-xl, .h2-xl, .h3-xl, .h4-xl, .h5-xl, .h6-xl,
    .h1-xxl, .h2-xxl, .h3-xxl, .h4-xxl, .h5-xxl, .h6-xxl {
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }

    .display-1 {
      font-size: 6rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-2 {
      font-size: 5.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-3 {
      font-size: 4.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-4 {
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .h1 {
      font-size: 2.5rem;
    }

    .h2 {
      font-size: 2rem;
    }

    .h3 {
      font-size: 1.75rem;
    }

    .h4 {
      font-size: 1.5rem;
    }

    .h5 {
      font-size: 1.25rem;
    }

    .h6 {
      font-size: 1rem;
    }

    .w-20 {
      width: 20% !important;
    }

    .w-25 {
      width: 25% !important;
    }

     .w-30 {
      width: 30% !important;
    }

    .w-35 {
      width: 35% !important;
    }

    .w-40 {
      width: 40% !important;
    }

    .w-45 {
      width: 45% !important;
    }

    .w-50 {
      width: 50% !important;
    }

    .w-55 {
      width: 55% !important;
    }

    .w-60 {
      width: 60% !important;
    }

    .w-65 {
      width: 65% !important;
    }

    .w-70 {
      width: 70% !important;
    }

    .w-75 {
      width: 75% !important;
    }

    .w-80 {
      width: 80% !important;
    }

    .w-85 {
      width: 85% !important;
    }

    .w-90 {
      width: 90% !important;
    }

    .w-95 {
      width: 95% !important;
    }

    .w-100 {
      width: 100% !important;
    }

    .w-auto {
      width: auto !important;
    }

    .h-25 {
      height: 25% !important;
    }

    .h-50 {
      height: 50% !important;
    }

    .h-75 {
      height: 75% !important;
    }

    .h-100 {
      height: 100% !important;
    }

    .h-auto {
      height: auto !important;
    }

    .mw-100 {
      max-width: 100% !important;
    }

    .mh-100 {
      max-height: 100% !important;
    }

    .min-vw-100 {
      min-width: 100vw !important;
    }

    .min-vh-100 {
      min-height: 100vh !important;
    }

    .vw-100 {
      width: 100vw !important;
    }

    .vh-100 {
      height: 100vh !important;
    }

    .small {
      font-size: 90%;
      font-weight: 400;
    }

    .normal{
      font-size: 100%;
    }

    .big{
      font-size: 120%;
    }

    .m-n1 {
      margin: -0.25rem !important;
    }
    .m-n2 {
      margin: -0.5rem !important;
    }
    .m-n3 {
      margin: -1rem !important;
    }
    .m-n4 {
      margin: -1.5rem !important;
    }
    .m-n5 {
      margin: -3rem !important;
    }

    .mt-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-n2 {
        margin-top: -0.5rem !important;
    }
    .mt-n3 {
        margin-top: -1rem !important;
    }
    .mt-n4 {
        margin-top: -1.5rem !important;
    }
    .mt-n5 {
        margin-top: -3rem !important;
    }

    .me-n1 {
      margin-right: -0.25rem !important;
    }
    .me-n2 {
        margin-right: -0.5rem !important;
    }
    .me-n3 {
        margin-right: -1rem !important;
    }
    .me-n4 {
        margin-right: -1.5rem !important;
    }
    .me-n5 {
        margin-right: -3rem !important;
    }

    .mb-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-n2 {
        margin-bottom: -0.5rem !important;
    }
    .mb-n3 {
        margin-bottom: -1rem !important;
    }
    .mb-n4 {
        margin-bottom: -1.5rem !important;
    }
    .mb-n5 {
        margin-bottom: -3rem !important;
    }

    .ms-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-n2 {
        margin-left: -0.5rem !important;
    }
    .ms-n3 {
        margin-left: -1rem !important;
    }
    .ms-n4 {
        margin-left: -1.5rem !important;
    }
    .ms-n5 {
        margin-left: -3rem !important;
    }

    @media (min-width: 576px) {
      .fw-sm-medium {
        font-weight: 500 !important;
      }

      .fw-sm-semibold {
        font-weight: 600 !important;
      }

      .display-sm-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-sm-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-sm-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-sm-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-sm {
        font-size: 2.5rem;
      }

      .h2-sm {
        font-size: 2rem;
      }

      .h3-sm {
        font-size: 1.75rem;
      }

      .h4-sm {
        font-size: 1.5rem;
      }

      .h5-sm {
        font-size: 1.25rem;
      }

      .h6-sm {
        font-size: 1rem;
      }

      .w-sm-25 {
        width: 25% !important;
      }

      .w-sm-50 {
        width: 50% !important;
      }

      .w-sm-75 {
        width: 75% !important;
      }

      .w-sm-100 {
        width: 100% !important;
      }

      .w-sm-auto {
        width: auto !important;
      }

      .h-sm-25 {
        height: 25% !important;
      }

      .h-sm-50 {
        height: 50% !important;
      }

      .h-sm-75 {
        height: 75% !important;
      }

      .h-sm-100 {
        height: 100% !important;
      }

      .h-sm-auto {
        height: auto !important;
      }

      .mw-sm-100 {
        max-width: 100% !important;
      }

      .mh-sm-100 {
        max-height: 100% !important;
      }

      .min-vw-sm-100 {
        min-width: 100vw !important;
      }

      .min-vh-sm-100 {
        min-height: 100vh !important;
      }

      .vw-sm-100 {
        width: 100vw !important;
      }

      .vh-sm-100 {
        height: 100vh !important;
      }

      .small-sm {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-sm {
        font-size: 100%;
      }

      .big-sm {
        font-size: 120%;
      }

      .m-sm-n1 {
        margin: -0.25rem !important;
      }
      .m-sm-n2 {
        margin: -0.5rem !important;
      }
      .m-sm-n3 {
        margin: -1rem !important;
      }
      .m-sm-n4 {
        margin: -1.5rem !important;
      }
      .m-sm-n5 {
        margin: -3rem !important;
      }

      .mt-sm-n1 {
        margin-top: -0.25rem !important;
      }
      .mt-sm-n2 {
          margin-top: -0.5rem !important;
      }
      .mt-sm-n3 {
          margin-top: -1rem !important;
      }
      .mt-sm-n4 {
          margin-top: -1.5rem !important;
      }
      .mt-sm-n5 {
          margin-top: -3rem !important;
      }

      .me-sm-n1 {
        margin-right: -0.25rem !important;
      }
      .me-sm-n2 {
          margin-right: -0.5rem !important;
      }
      .me-sm-n3 {
          margin-right: -1rem !important;
      }
      .me-sm-n4 {
          margin-right: -1.5rem !important;
      }
      .me-sm-n5 {
          margin-right: -3rem !important;
      }

      .mb-sm-n1 {
        margin-bottom: -0.25rem !important;
      }
      .mb-sm-n2 {
          margin-bottom: -0.5rem !important;
      }
      .mb-sm-n3 {
          margin-bottom: -1rem !important;
      }
      .mb-sm-n4 {
          margin-bottom: -1.5rem !important;
      }
      .mb-sm-n5 {
          margin-bottom: -3rem !important;
      }

      .ms-sm-n1 {
        margin-left: -0.25rem !important;
      }
      .ms-sm-n2 {
          margin-left: -0.5rem !important;
      }
      .ms-sm-n3 {
          margin-left: -1rem !important;
      }
      .ms-sm-n4 {
          margin-left: -1.5rem !important;
      }
      .ms-sm-n5 {
          margin-left: -3rem !important;
      }
    }

    @media (min-width: 768px) {
      .fw-md-medium {
        font-weight: 500 !important;
      }

      .fw-md-semibold {
        font-weight: 600 !important;
      }

      .display-md-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-md-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-md-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-md-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-md {
        font-size: 2.5rem;
      }

      .h2-md {
        font-size: 2rem;
      }

      .h3-md {
        font-size: 1.75rem;
      }

      .h4-md {
        font-size: 1.5rem;
      }

      .h5-md {
        font-size: 1.25rem;
      }

      .h6-md {
        font-size: 1rem;
      }

      .w-md-25 {
        width: 25% !important;
      }

      .w-md-50 {
        width: 50% !important;
      }

      .w-md-75 {
        width: 75% !important;
      }

      .w-md-100 {
        width: 100% !important;
      }

      .w-md-auto {
        width: auto !important;
      }

      .h-md-25 {
        height: 25% !important;
      }

      .h-md-50 {
        height: 50% !important;
      }

      .h-md-75 {
        height: 75% !important;
      }

      .h-md-100 {
        height: 100% !important;
      }

      .h-md-auto {
        height: auto !important;
      }

      .mw-md-100 {
        max-width: 100% !important;
      }

      .mh-md-100 {
        max-height: 100% !important;
      }

      .min-vw-md-100 {
        min-width: 100vw !important;
      }

      .min-vh-md-100 {
        min-height: 100vh !important;
      }

      .vw-md-100 {
        width: 100vw !important;
      }

      .vh-md-100 {
        height: 100vh !important;
      }

      .small-md {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-md {
        font-size: 100%;
      }

      .big-md {
        font-size: 120%;
      }

      .m-md-n1 {
        margin: -0.25rem !important;
      }
      .m-md-n2 {
        margin: -0.5rem !important;
      }
      .m-md-n3 {
        margin: -1rem !important;
      }
      .m-md-n4 {
        margin: -1.5rem !important;
      }
      .m-md-n5 {
        margin: -3rem !important;
      }

      .mt-md-n1 {
        margin-top: -0.25rem !important;
      }
      .mt-md-n2 {
          margin-top: -0.5rem !important;
      }
      .mt-md-n3 {
          margin-top: -1rem !important;
      }
      .mt-md-n4 {
          margin-top: -1.5rem !important;
      }
      .mt-md-n5 {
          margin-top: -3rem !important;
      }

      .me-md-n1 {
        margin-right: -0.25rem !important;
      }
      .me-md-n2 {
          margin-right: -0.5rem !important;
      }
      .me-md-n3 {
          margin-right: -1rem !important;
      }
      .me-md-n4 {
          margin-right: -1.5rem !important;
      }
      .me-md-n5 {
          margin-right: -3rem !important;
      }

      .mb-md-n1 {
        margin-bottom: -0.25rem !important;
      }
      .mb-md-n2 {
          margin-bottom: -0.5rem !important;
      }
      .mb-md-n3 {
          margin-bottom: -1rem !important;
      }
      .mb-md-n4 {
          margin-bottom: -1.5rem !important;
      }
      .mb-md-n5 {
          margin-bottom: -3rem !important;
      }

      .ms-md-n1 {
        margin-left: -0.25rem !important;
      }
      .ms-md-n2 {
          margin-left: -0.5rem !important;
      }
      .ms-md-n3 {
          margin-left: -1rem !important;
      }
      .ms-md-n4 {
          margin-left: -1.5rem !important;
      }
      .ms-md-n5 {
          margin-left: -3rem !important;
      }
    }

    @media (min-width: 992px) {
      .fw-lg-medium {
        font-weight: 500 !important;
      }

      .fw-lg-semibold {
        font-weight: 600 !important;
      }

      .display-lg-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-lg-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-lg-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-lg-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-lg {
        font-size: 2.5rem;
      }

      .h2-lg {
        font-size: 2rem;
      }

      .h3-lg {
        font-size: 1.75rem;
      }

      .h4-lg {
        font-size: 1.5rem;
      }

      .h5-lg {
        font-size: 1.25rem;
      }

      .h6-lg {
        font-size: 1rem;
      }

      .w-lg-25 {
        width: 25% !important;
      }

      .w-lg-50 {
        width: 50% !important;
      }

      .w-lg-70 {
        width: 70% !important;
      }

      .w-lg-75 {
        width: 75% !important;
      }

      .w-lg-80 {
        width: 80% !important;
      }

      .w-lg-90 {
        width: 90% !important;
      }

      .w-lg-100 {
        width: 100% !important;
      }

      .w-lg-auto {
        width: auto !important;
      }

      .h-lg-25 {
        height: 25% !important;
      }

      .h-lg-50 {
        height: 50% !important;
      }

      .h-lg-75 {
        height: 75% !important;
      }

      .h-lg-100 {
        height: 100% !important;
      }

      .h-lg-auto {
        height: auto !important;
      }

      .mw-lg-100 {
        max-width: 100% !important;
      }

      .mh-lg-100 {
        max-height: 100% !important;
      }

      .min-vw-lg-100 {
        min-width: 100vw !important;
      }

      .min-vh-lg-100 {
        min-height: 100vh !important;
      }

      .vw-lg-100 {
        width: 100vw !important;
      }

      .vh-lg-100 {
        height: 100vh !important;
      }

      .small-lg {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-lg {
        font-size: 100%;
      }

      .big-lg {
        font-size: 120%;
      }

      .m-lg-n1 {
        margin: -0.25rem !important;
      }
      .m-lg-n2 {
        margin: -0.5rem !important;
      }
      .m-lg-n3 {
        margin: -1rem !important;
      }
      .m-lg-n4 {
        margin: -1.5rem !important;
      }
      .m-lg-n5 {
        margin: -3rem !important;
      }

      .mt-lg-n1 {
        margin-top: -0.25rem !important;
      }
      .mt-lg-n2 {
          margin-top: -0.5rem !important;
      }
      .mt-lg-n3 {
          margin-top: -1rem !important;
      }
      .mt-lg-n4 {
          margin-top: -1.5rem !important;
      }
      .mt-lg-n5 {
          margin-top: -3rem !important;
      }

      .me-lg-n1 {
        margin-right: -0.25rem !important;
      }
      .me-lg-n2 {
          margin-right: -0.5rem !important;
      }
      .me-lg-n3 {
          margin-right: -1rem !important;
      }
      .me-lg-n4 {
          margin-right: -1.5rem !important;
      }
      .me-lg-n5 {
          margin-right: -3rem !important;
      }

      .mb-lg-n1 {
        margin-bottom: -0.25rem !important;
      }
      .mb-lg-n2 {
          margin-bottom: -0.5rem !important;
      }
      .mb-lg-n3 {
          margin-bottom: -1rem !important;
      }
      .mb-lg-n4 {
          margin-bottom: -1.5rem !important;
      }
      .mb-lg-n5 {
          margin-bottom: -3rem !important;
      }

      .ms-lg-n1 {
        margin-left: -0.25rem !important;
      }
      .ms-lg-n2 {
          margin-left: -0.5rem !important;
      }
      .ms-lg-n3 {
          margin-left: -1rem !important;
      }
      .ms-lg-n4 {
          margin-left: -1.5rem !important;
      }
      .ms-lg-n5 {
          margin-left: -3rem !important;
      }
    }

    @media (min-width: 1200px) {
      .fw-xl-medium {
        font-weight: 500 !important;
      }

      .fw-xl-semibold {
        font-weight: 600 !important;
      }

      .display-xl-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xl-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xl-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xl-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-xl {
        font-size: 2.5rem;
      }

      .h2-xl {
        font-size: 2rem;
      }

      .h3-xl {
        font-size: 1.75rem;
      }

      .h4-xl {
        font-size: 1.5rem;
      }

      .h5-xl {
        font-size: 1.25rem;
      }

      .h6-xl {
        font-size: 1rem;
      }

      .w-xl-25 {
        width: 25% !important;
      }

      .w-xl-50 {
        width: 50% !important;
      }

      .w-xl-75 {
        width: 75% !important;
      }

      .w-xl-100 {
        width: 100% !important;
      }

      .w-xl-auto {
        width: auto !important;
      }

      .h-xl-25 {
        height: 25% !important;
      }

      .h-xl-50 {
        height: 50% !important;
      }

      .h-xl-75 {
        height: 75% !important;
      }

      .h-xl-100 {
        height: 100% !important;
      }

      .h-xl-auto {
        height: auto !important;
      }

      .mw-xl-100 {
        max-width: 100% !important;
      }

      .mh-xl-100 {
        max-height: 100% !important;
      }

      .min-vw-xl-100 {
        min-width: 100vw !important;
      }

      .min-vh-xl-100 {
        min-height: 100vh !important;
      }

      .vw-xl-100 {
        width: 100vw !important;
      }

      .vh-xl-100 {
        height: 100vh !important;
      }

      .small-xl {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-xl {
        font-size: 100%;
      }

      .big-xl {
        font-size: 120%;
      }

      .m-xl-n1 {
        margin: -0.25rem !important;
      }
      .m-xl-n2 {
        margin: -0.5rem !important;
      }
      .m-xl-n3 {
        margin: -1rem !important;
      }
      .m-xl-n4 {
        margin: -1.5rem !important;
      }
      .m-xl-n5 {
        margin: -3rem !important;
      }

      .mt-xl-n1 {
        margin-top: -0.25rem !important;
      }
      .mt-xl-n2 {
          margin-top: -0.5rem !important;
      }
      .mt-xl-n3 {
          margin-top: -1rem !important;
      }
      .mt-xl-n4 {
          margin-top: -1.5rem !important;
      }
      .mt-xl-n5 {
          margin-top: -3rem !important;
      }

      .me-xl-n1 {
        margin-right: -0.25rem !important;
      }
      .me-xl-n2 {
          margin-right: -0.5rem !important;
      }
      .me-xl-n3 {
          margin-right: -1rem !important;
      }
      .me-xl-n4 {
          margin-right: -1.5rem !important;
      }
      .me-xl-n5 {
          margin-right: -3rem !important;
      }

      .mb-xl-n1 {
        margin-bottom: -0.25rem !important;
      }
      .mb-xl-n2 {
          margin-bottom: -0.5rem !important;
      }
      .mb-xl-n3 {
          margin-bottom: -1rem !important;
      }
      .mb-xl-n4 {
          margin-bottom: -1.5rem !important;
      }
      .mb-xl-n5 {
          margin-bottom: -3rem !important;
      }

      .ms-xl-n1 {
        margin-left: -0.25rem !important;
      }
      .ms-xl-n2 {
          margin-left: -0.5rem !important;
      }
      .ms-xl-n3 {
          margin-left: -1rem !important;
      }
      .ms-xl-n4 {
          margin-left: -1.5rem !important;
      }
      .ms-xl-n5 {
          margin-left: -3rem !important;
      }
    }

    @media (min-width: 1400px) {
      .fw-xxl-medium {
        font-weight: 500 !important;
      }

      .fw-xxl-semibold {
        font-weight: 600 !important;
      }

      .display-xxl-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xxl-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xxl-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xxl-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-xxl {
        font-size: 2.5rem;
      }

      .h2-xxl {
        font-size: 2rem;
      }

      .h3-xxl {
        font-size: 1.75rem;
      }

      .h4-xxl {
        font-size: 1.5rem;
      }

      .h5-xxl {
        font-size: 1.25rem;
      }

      .h6-xxl {
        font-size: 1rem;
      }

      .w-xxl-25 {
        width: 25% !important;
      }

      .w-xxl-50 {
        width: 50% !important;
      }

      .w-xxl-75 {
        width: 75% !important;
      }

      .w-xxl-100 {
        width: 100% !important;
      }

      .w-xxl-auto {
        width: auto !important;
      }

      .h-xxl-25 {
        height: 25% !important;
      }

      .h-xxl-50 {
        height: 50% !important;
      }

      .h-xxl-75 {
        height: 75% !important;
      }

      .h-xxl-100 {
        height: 100% !important;
      }

      .h-xxl-auto {
        height: auto !important;
      }

      .mw-xxl-100 {
        max-width: 100% !important;
      }

      .mh-xxl-100 {
        max-height: 100% !important;
      }

      .min-vw-xxl-100 {
        min-width: 100vw !important;
      }

      .min-vh-xxl-100 {
        min-height: 100vh !important;
      }

      .vw-xxl-100 {
        width: 100vw !important;
      }

      .vh-xxl-100 {
        height: 100vh !important;
      }

      .small-xxl {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-xxl {
        font-size: 100%;
      }

      .big-xxl {
        font-size: 120%;
      }

      .m-xxl-n1 {
        margin: -0.25rem !important;
      }
      .m-xxl-n2 {
        margin: -0.5rem !important;
      }
      .m-xxl-n3 {
        margin: -1rem !important;
      }
      .m-xxl-n4 {
        margin: -1.5rem !important;
      }
      .m-xxl-n5 {
        margin: -3rem !important;
      }

      .mt-xxl-n1 {
        margin-top: -0.25rem !important;
      }
      .mt-xxl-n2 {
          margin-top: -0.5rem !important;
      }
      .mt-xxl-n3 {
          margin-top: -1rem !important;
      }
      .mt-xxl-n4 {
          margin-top: -1.5rem !important;
      }
      .mt-xxl-n5 {
          margin-top: -3rem !important;
      }

      .me-xxl-n1 {
        margin-right: -0.25rem !important;
      }
      .me-xxl-n2 {
          margin-right: -0.5rem !important;
      }
      .me-xxl-n3 {
          margin-right: -1rem !important;
      }
      .me-xxl-n4 {
          margin-right: -1.5rem !important;
      }
      .me-xxl-n5 {
          margin-right: -3rem !important;
      }

      .mb-xxl-n1 {
        margin-bottom: -0.25rem !important;
      }
      .mb-xxl-n2 {
          margin-bottom: -0.5rem !important;
      }
      .mb-xxl-n3 {
          margin-bottom: -1rem !important;
      }
      .mb-xxl-n4 {
          margin-bottom: -1.5rem !important;
      }
      .mb-xxl-n5 {
          margin-bottom: -3rem !important;
      }

      .ms-xxl-n1 {
        margin-left: -0.25rem !important;
      }
      .ms-xxl-n2 {
          margin-left: -0.5rem !important;
      }
      .ms-xxl-n3 {
          margin-left: -1rem !important;
      }
      .ms-xxl-n4 {
          margin-left: -1.5rem !important;
      }
      .ms-xxl-n5 {
          margin-left: -3rem !important;
      }
    }
  }
`;
