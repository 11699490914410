import styled from 'styled-components';
import { darken } from 'polished';

interface IContainerProps {
  active?: boolean;
}

export const Container = styled.div<IContainerProps>`
  background: #282828;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 6px 28px 35px rgba(21, 21, 21, 0.25);
  right: ${(props) => (props.active ? '0px' : '-1000px')};
  top: 0;
  z-index: 900;
  transition-duration: 0.3s;

  p u {
    color: #a6a3a3;
  }

  h4 {
    color: #f2f2f2;
  }

  .ps {
    font-weight: 300;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: end;
    color: #a6a3a3;
  }

  .items {
    background: #222222;
    border-radius: 15px;
    padding: 13px 20px;
  }

  .items span {
    font-weight: 900;
    font-size: 16px;
    color: #e9e9e9;
  }

  .items button {
    font-weight: 300;
    font-size: 12px;
    color: #f2f2f2;
    border: none;
    background: transparent;
  }

  .resum-width {
    width: 90px;
  }

  .width {
    width: 70px;
    height: 70px;
  }

  .text-green {
    color: #6fcf97;
    width: 56px;
  }

  .input-border {
    background: #323336;
    border: 1px solid #626262;
    box-sizing: border-box;
    border-radius: 6px;
    width: 40px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right: 20px;

    input {
      width: 40px;
      border: none;
      background: transparent;
      margin-left: 30px;
      color: #bdbdbd;
    }
  }

  .box-dark-gray {
    background: #222222;
    border-radius: 20px;

    p {
      color: #f2f2f2;
    }

    p.h3 {
      background: linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 900 !important;
    }
  }

  .payment {
    background-color: #00f9ab;
    color: #000;
    font-weight: 700;
    border-radius: 20px;
    font-size: 20px;
    padding: 16px;
    transition-duration: 0.3s;

    :hover {
      background-color: ${darken(0.1, '#00f9ab')};
    }
  }

  .get-usdpi-title {
    font-weight: 400;
    font-size: 16px;
    color: #f2f2f2;
  }

  .btn-get-usdpi {
    background: linear-gradient(90deg, #2fbf7f, #3cc9ad);

    border-radius: 15px;
    button {
      background: #282828;
      margin: 2px;
      border: none;
      border-radius: 15px;
      font-weight: 600;
      font-size: 20px;
      color: #ffffff;
      transition: all 0.2s ease;
    }

    button:hover {
      opacity: 0.9;
    }
  }

  @media screen and (max-width: 1399px) {
    .resum-width {
      width: 70px;
    }
  }

  @media screen and (max-width: 991px) {
    display: ${(props) => (props.active ? 'block' : 'none')} !important;
    border-radius: 40px 40px 0px 0px;

    .input-border {
      width: 69px;
    }
  }

  @media screen and (min-width: 1399px) {
    max-width: 360px;
  }
  @media screen and (min-width: 992px) {
    max-width: 334px;
    width: 34%;
    position: absolute;
  }

  @media screen and (min-width: 1200px) {
    width: 25%;
  }
`;

export const LoadingDiscover = styled.div`
  z-index: 2000;
  position: fixed;

  .loading-box {
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.25) -38.97%,
      rgba(0, 0, 0, 0.25) 75.53%
    );
    backdrop-filter: blur(25px);
    span {
      font-weight: 400;
      font-size: 20px;
      color: #fff;
      text-align: center;
    }
  }

  .animation-loading {
    height: 152px;
    width: 152px;

    img {
      position: relative;
      z-index: 300;
      top: 10px;
      left: 10px;
      height: 134px;
      width: 134px;
      background: rgba(0, 0, 0);
      backdrop-filter: blur(5px);
    }
  }

  .box-1 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: #fff;
    animation: position-box-1 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-1 {
    0% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    25% {
      top: calc(100% - 37px);
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    50% {
      top: calc(100% - 114px);
      left: 0;
      width: 37px;
      height: 114px;
    }

    75% {
      top: 0;
      left: 0;
      width: 114px;
      height: 37px;
    }

    100% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }
  }

  .box-2 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: #fff;
    animation: position-box-2 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-2 {
    0% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }

    25% {
      top: 0;
      left: 0;
      width: 37px;
      height: 114px;
    }

    50% {
      top: 0;
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    75% {
      top: calc(100% - 114px);
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    100% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }
  }
`;
