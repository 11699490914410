import styled from 'styled-components';
import { Form } from '@unform/web';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';

export const Container = styled(Form)`
  background-color: #18191a;
  background-position: center;
  background-size: cover;
  min-height: 100vh;

  h1 {
    background: linear-gradient(269.95deg, #fff 0.02%, #fff 97.45%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;

    > span {
      background: linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      );
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      font-weight: 900;
    }
  }

  h2 {
    color: #f2f2f2;
    font-weight: 500 !important;
  }

  h3 {
    font-weight: 400;
    font-size: 18px;
    color: #787878;
  }

  h4 {
    font-weight: 400;
    font-size: 20px;
    color: #c9c9c9;
  }

  h3,
  h4,
  textarea {
    font-family: 'Poppins', sans-serif;
  }

  .container {
    max-width: 1700px;
  }

  .surprise {
    p {
      color: #8c8c8c;
      font-weight: 400;
    }
  }

  .bg-surprise {
    background-color: #1d2023;
    color: #dadada;
    border: none;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
      color: ${lighten(0.2, '#dadada')};
    }
  }

  .mg-left {
    margin-left: 17.5%;
  }

  .history-btn {
    margin-bottom: 10px;
    padding: 10px 30px;
    font-weight: 300;
    font-size: 14px;
    background: rgba(31, 32, 32, 0.67);
    border: 1px solid #282929;
    backdrop-filter: blur(5px);
    border-radius: 14px;
    color: #8d8d8d;
  }

  .hover div p,
  .hover div button {
    font-family: 'Quicksand', sans-serif;
    opacity: 0;
    transition: all 0.3s ease;
    font-size: 16px;
    color: #d4d4d4;
    font-weight: 300;
    margin: 0px;
  }

  .hover div button {
    background: rgba(152, 148, 148, 0.1);
    padding: 15px 20px;
    backdrop-filter: blur(15px);
    border-radius: 15px;
    font-weight: 600;
  }

  .hover div {
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;

    transition: all 0.3s ease;
    :hover {
      background-color: rgba(0, 0, 0, 80%);
      border-radius: 15px;
      p,
      button {
        opacity: 1;
      }
    }
  }

  .textarea {
    height: unset;

    textarea {
      color: #787878;
    }
  }

  .sample img {
    border-radius: 16.5px;
  }

  .area-send {
    bottom: 0px;
    right: 0px;
    width: calc(100% - 160px);
    padding: 25px 10px 10px 25px;
    background: linear-gradient(180deg, rgba(24, 25, 26, 0) 0%, #18191a 63%);
  }

  .description button {
    border: none;
    background: transparent;
    font-family: 'Poppins', sans-serif;

    font-weight: 400 !important;
    font-size: 12px;
    color: #c4c4c4;
    text-align: start;
  }

  .send {
    border: none;
    background-color: transparent;
    transition-duration: 0.3s;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    color: #c4c4c4;
  }

  .send:focus,
  .send:hover {
    color: ${lighten(0.1, '#c4c4c4')}!important;
  }

  input {
    resize: none;
    background-color: transparent;
    color: #d4d4d4 !important;
    font-weight: 400 !important;
    border: none;
    ::placeholder {
      color: #5a5b5b !important;
    }
  }

  .border-textarea {
    width: 60%;
    max-width: 1000px;
    background: #1f2020;
    border: 1px solid #282929;
    box-shadow: 1px 16px 30px rgba(0, 0, 0, 0.1);
    border-radius: 22px;
    height: 82px;
  }

  .bg-imgs {
    background-color: #212122;
    border-radius: 30px;
    padding: 34px;
  }

  .bg-imgs img {
    border-radius: 24px;
  }

  .description {
    bottom: 3%;
    height: 64px;
    background: rgba(23, 23, 25, 0.5);
    border: 2px solid #707070;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(13.5914px);
    border-radius: 20px;
    width: 94%;
    left: 3%;
    display: flex;
    align-items: center;
  }

  .bg-imgs button {
  }

  .logo {
    right: 2rem;
    top: 2rem;
    z-index: 1;
  }

  .text-gray {
    color: #b7b7b7;
  }

  .timeline-box {
    > div {
      height: 100%;
    }
  }

  .drop span {
    font-weight: 400;
    font-size: 16px;

    color: #8c8c8c;
  }

  .Dropdown-menu {
    background: #18191a;
    color: #dadada;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid #373737;
    border-radius: 15px;
    text-align: center;
  }

  .Dropdown-option.is-selected,
  .Dropdown-option:hover {
    background-color: #141313;
  }

  .Dropdown-option {
    color: #dadada;
    font-size: 18px;
    font-weight: 600;
    padding: 11px 10px;
  }

  .Dropdown-control {
    background: transparent;
    color: #dadada;
    border: none;
    font-weight: 400;
    font-size: 16px;
    padding: 8px 25px 8px 10px;
    width: 110px;
  }

  .Dropdown-arrow {
    top: 17px;
    right: 10px;
  }

  .z-index {
    z-index: 1;
  }

  .values-drop {
    position: absolute;
    top: 8px;
    left: 47px;
    display: flex;
    z-index: 0;
    color: #dadada !important;
  }

  @media screen and (max-width: 1799px) {
    /* .zoom {
      zoom: 0.8;
    } */
  }

  @media screen and (max-width: 1399px) {
    /* .zoom {
      zoom: 0.7;
    } */
  }

  @media screen and (max-width: 991px) {
    button {
      font-size: 20px;
    }
  }

  @media screen and (min-width: 2160px) {
    .max-width {
      max-width: 1800px;
      margin: 0 auto;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  img {
    border-radius: 25px 25px 0 0;
  }

  p {
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #dadada;
  }

  button {
    background: #18191a;
    border-radius: 20px;
    color: #f0ebeb;
    font-size: 24px;
    transition: all 0.3s ease;
  }

  button:hover {
    background: ${lighten(0.1, '#18191a')};
  }
  .modal-close {
    color: #fff;
    height: 50px;
    width: 50px;
    right: 15px;
  }

  @media screen and (min-width: 992px) {
    .modal-lg {
      max-width: 600px;
      width: 30vw;
    }
  }
`;
