import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '~/components/HeaderPrivate';
import ModalDisconnected from '~/components/ModalDisconnected';

import { Wrapper, HeaderSpacer, Separator } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const [unshowMenu, setUnshowMenu] = useState(false);

  const locationPath = [
    '/user-profile',
    '/products/cube',
    '/products/paige',
    '/tokenomics',
    '/get-your-free-nft',
    '/how-to-participate',
    '/activate-your-profit-centers',
    '/activate-your-profit-centers/skip',
    '/earn-even-more',
    '/final-step',
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setUnshowMenu(
      location.pathname.includes('user-profile') ||
        location.pathname.includes('products/cube') ||
        location.pathname.includes('/products/paige') ||
        location.pathname.includes('tokenomics') ||
        location.pathname.includes('get-your-free-nft') ||
        location.pathname.includes('how-to-participate') ||
        location.pathname.includes('activate-your-profit-centers') ||
        location.pathname.includes('earn-even-more') ||
        location.pathname.includes('final-step') ||
        location.pathname.includes('ai-avatar') ||
        location.pathname.includes('what-to-expect')
    );
  }, [location]);

  return (
    <Wrapper>
      {!unshowMenu && (
        <HeaderSpacer>
          <Header />
        </HeaderSpacer>
      )}
      <Separator
        className={`${location.pathname === '/products/cube' && 'bg-black'}`}
        userProfile={unshowMenu}
      >
        {children}
      </Separator>
    </Wrapper>
  );
};

export default DefaultLayout;
