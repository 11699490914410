import React, { useCallback, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Modal } from './styles';
import logo from '~/assets/logo/logo-p-purple.svg';

import qrCode from '~/assets/defaults/qr-code-nft.svg';
import { web3store } from '~/store';

interface IModalNft {
  show?: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalNft: React.FC<IModalNft> = ({ show, setShow }) => {
  const history = useHistory();
  const [imgURL] = web3store.useState('imgURL');
  const [nftimg] = web3store.useState('nftimg');

  const handleClose = useCallback(() => {
    if (setShow) {
      setShow(false);
    }

    history.push(`${process.env.PUBLIC_URL}/earn-even-more`);
  }, [history, setShow]);

  return (
    <Modal
      className="modal-claim-nft text-white d-flex"
      size="xl"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className="border-0 p-3 pb-0">
        <div className="d-flex justify-content-between w-100 header">
          <img src={logo} alt="Logo" className="" />
          <button
            type="button"
            className="h4 modal-close mb-0 ms-auto border-0 bg-transparent"
            onClick={handleClose}
          >
            x
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-6">
              <div className="bg-nft p-2 position-relative">
                <img
                  src={nftimg || imgURL}
                  alt="NFT Generated"
                  className="w-100 nft"
                />
                <div className="d-flex pt-4 pb-3 bg-serial-qrcode justify-content-center align-items-center">
                  <div className="w-60">
                    <span>
                      Serial #<br />
                      <span className="fw-bold">0000123</span>
                    </span>
                  </div>
                  <div className="w-30 text-end">
                    <img
                      src={qrCode}
                      alt="Qr Code"
                      className="w-75 qr-code bg-qrcode p-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-6 col-lg-7 pt-4 mt-5">
              <div className="d-flex mt-5 py-4 justify-content-around bg-main">
                <p className="mb-0">
                  Your NFT has been Minted and added to your wallet!
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

interface IModalNftWallet {
  showNft?: boolean;
  setShowNft?: React.Dispatch<React.SetStateAction<boolean>>;
  onActivate?(): void;
}

const ModalNftWallet: React.FC<IModalNftWallet> = ({
  showNft,
  setShowNft,
  onActivate,
}) => {
  const [cartPurchaseTX] = web3store.useState('cartPurchaseTX');

  useEffect(() => {
    if (cartPurchaseTX) {
      if (
        typeof cartPurchaseTX === 'string' &&
        !cartPurchaseTX.toLowerCase().includes('error')
      ) {
        if (setShowNft) {
          setShowNft(true);
        }
      }
    }
  }, [cartPurchaseTX, setShowNft]);

  const handleShow = useCallback(() => {
    if (onActivate) {
      onActivate();
    }
  }, [onActivate]);

  return (
    <>
      <Button type="button" onClick={handleShow} className="w-100 mt-4 fw-bold">
        ACTIVATE & MINT MY UNIQUE NFT
      </Button>
      <ModalNft show={showNft} setShow={setShowNft} />
    </>
  );
};

export default ModalNftWallet;
