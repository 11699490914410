import React from 'react';
import { Button, Modal } from './styles';

interface IframeModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  iframeSrc: string | undefined;
}

const ModalC14: React.FC<IframeModalProps> = ({
  isOpen,
  onRequestClose,
  iframeSrc,
}) => {
  const iframeContent = isOpen ? (
    <iframe src={iframeSrc} title="C14" width="500px" height="700px" />
  ) : null;

  return (
    <div>
      {iframeSrc && ( // Conditionally render the modal when iframeSrc is not empty
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          // ariaHideApp={false} // Disable accessibility warnings
          className="modal-signin"
          show={isOpen}
        >
          <button
            type="button"
            className="h4 modal-close m-3 ms-auto border-0 bg-transparent"
            onClick={onRequestClose}
          >
            x
          </button>
          <Modal.Body className="container">{iframeContent}</Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default ModalC14;
