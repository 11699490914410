import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IoMdLogOut } from 'react-icons/io';
import { truncate } from 'truncate-ethereum-address';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlineLightMode } from 'react-icons/md';
import { useAuth } from '~/hooks/Auth';

import WalletAvatar from '../WalletAvatar';
import { Container } from './styles';
import logoSwap from '~/assets/logo/logo-swap.png';
import logoPurple from '~/assets/logo/logo-p-purple.svg';
import { formatPrice } from '~/utils/format';
import { web3store } from '~/store';

const WalletRow: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const addUSDPIRef = useRef<HTMLDivElement>(null);
  const addPRFTIRef = useRef<HTMLDivElement>(null);
  const [balusdpi] = web3store.useState('balusdpi');
  const [balprfti] = web3store.useState('balprfti');
  const [account, setAccount] = web3store.useState('account');
  const [balance, setBalance] = useState(false);
  const [disconnect, setDisconnect] = useState(false);
  const [addPRFTI, setAddPRFTI] = useState(false);
  const [addUSDPI, setAddUSDPI] = useState(false);
  // Globals
  const [walletAddPRFTI, setWalletAddPRFTI] =
    web3store.useState('walletAddPRFTI');
  const [walletAddUSDPI, setWalletAddUSDPI] =
    web3store.useState('walletAddUSDPI');

  const handleOutsideClick = useCallback(
    (e) => {
      if (
        addUSDPI &&
        addUSDPIRef.current &&
        e.target !== addUSDPIRef.current &&
        !addUSDPIRef.current.contains(e.target)
      ) {
        setTimeout(() => {
          setAddUSDPI(false);
        }, 200);
      }
      if (
        addPRFTI &&
        addPRFTIRef.current &&
        e.target !== addPRFTIRef.current &&
        !addPRFTIRef.current.contains(e.target)
      ) {
        setTimeout(() => {
          setAddPRFTI(false);
        }, 200);
      }
    },
    [addUSDPI, addPRFTI]
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
  }, [handleOutsideClick]);

  const balanceData = useMemo(() => {
    return {
      usdpi: formatPrice(
        parseFloat((parseInt(balusdpi, 10) / 10 ** 18).toFixed(2))
      ),
      prfti: formatPrice(
        parseFloat((parseInt(balprfti, 10) / 10 ** 18).toFixed(2))
      ),
    };
  }, [balprfti, balusdpi]);

  const handleClickAddPRFTI = useCallback(() => {
    if (addUSDPI) {
      setAddUSDPI(false);
    }
    setTimeout(() => {
      setAddPRFTI(!addPRFTI);
    }, 100);
  }, [addPRFTI, addUSDPI]);

  const handleClickClosePRFTI = useCallback(() => {
    setAddPRFTI(false);
  }, []);

  const handleClickAddUSDPI = useCallback(() => {
    if (addPRFTI) {
      setAddPRFTI(false);
    }
    setTimeout(() => {
      setAddUSDPI(!addUSDPI);
    }, 100);
  }, [addPRFTI, addUSDPI]);

  const handleClickCloseUSDPI = useCallback(() => {
    setAddUSDPI(false);
  }, []);

  const handleClickPRFTIYes = useCallback(() => {
    setWalletAddPRFTI(true);
    setAddPRFTI(false);
  }, [setWalletAddPRFTI]);

  const handleClickUSDPIYes = useCallback(() => {
    setWalletAddUSDPI(true);
    setAddUSDPI(false);
  }, [setWalletAddUSDPI]);

  const locationBar = [
    '/get-your-free-nft',
    '/how-to-participate',
    '/activate-your-profit-centers',
    '/activate-your-profit-centers/skip',
    '/earn-even-more',
    '/final-step',
    '/ai-avatar',
    '/what-to-expect',
  ];

  return (
    <Container
      className={`${
        locationBar.includes(location.pathname) ? 'd-none d-lg-block' : 'd-flex'
      } justify-content-end align-items-center px-2`}
    >
      <div
        className={`d-flex align-items-center ${
          locationBar.includes(location.pathname) ? 'mb-4' : 'me-3'
        }`}
      >
        <div
          className="bg-values d-flex wm-tooltip"
          data-tooltip="Your balance of $PRFTI Tokens"
        >
          <button
            type="button"
            className={`${
              locationBar.includes(location.pathname) ? 'pe-none' : ''
            } border-0 bg-transparent`}
            onClick={handleClickAddPRFTI}
          >
            <img src={logoPurple} alt="Logo" />
          </button>
          <span className="d-flex align-items-center h6 text-white mb-0 px-3 px-xl-4">
            {balanceData.prfti}
          </span>
        </div>
        {addPRFTI && (
          <div ref={addPRFTIRef} className="addPRFTI position-absolute p-4">
            <h2 className="mb-4 text-disconnect text-center">
              Add PRFTI To MetaMask?
            </h2>

            <button
              type="button"
              className="btn-yes w-100 py-2 mb-2"
              onClick={handleClickPRFTIYes}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn-no py-2  mb-2 w-100"
              onClick={handleClickClosePRFTI}
            >
              No
            </button>
          </div>
        )}
      </div>

      <div
        className={`d-flex align-items-center  ${
          locationBar.includes(location.pathname) ? '' : 'me-3'
        }`}
      >
        <div
          className="bg-values d-flex wm-tooltip"
          data-tooltip="Your balance of $USDPI Tokens"
        >
          <button
            type="button"
            className={`${
              locationBar.includes(location.pathname) ? 'pe-none' : ''
            } border-0 bg-transparent`}
            onClick={handleClickAddUSDPI}
          >
            <img src={logoSwap} alt="Logo" />
          </button>
          <span className="d-flex align-items-center h6 text-white mb-0 px-3 px-xl-4">
            {balanceData.usdpi}
          </span>
        </div>
        {addUSDPI && (
          <div ref={addUSDPIRef} className="addUSDPI position-absolute p-4">
            <h2 className="mb-4 text-disconnect text-center">
              Add USDPI To MetaMask?
            </h2>

            <button
              type="button"
              className="btn-yes w-100 py-2 mb-2"
              onClick={handleClickUSDPIYes}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn-no py-2  mb-2 w-100"
              onClick={handleClickCloseUSDPI}
            >
              No
            </button>
          </div>
        )}
      </div>

      <div
        className={`${locationBar.includes(location.pathname) ? 'd-none' : ''}`}
      >
        <WalletAvatar />
      </div>
    </Container>
  );
};

export default WalletRow;
