import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BigNumber,
  ethers,
  utils,
  ContractTransaction,
  providers,
} from 'ethers';
import api from '~/services/api';

class Usdc {
  private account: any;

  private updateParentState: (
    newFee: number,
    newTimestamp: number,
    newResult: string,
    debounced: string
  ) => void;

  constructor(
    account: any,
    updateParentState: (
      newFee: number,
      newTimestamp: number,
      newResult: string,
      debounced: string
    ) => void
  ) {
    this.account = account.account;
    this.updateParentState = updateParentState;
  }

  getPermitHashes = (usdc2Send: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        if (this.account === '') {
          reject(new Error('Wallet address not found'));
        } else {
          api
            .get('/v1/billing/fundusdc/', {
              params: {
                wallet_address: this.account,
                amt2authorize: usdc2Send,
              },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  getRWAHashes = (usdc2Send: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        if (this.account === '') {
          reject(new Error('Wallet address not found'));
        } else {
          // Call v2 to get random nonce
          api
            .get('/v2/billing/fundusdc/', {
              params: {
                wallet_address: this.account,
                amt2authorize: usdc2Send,
              },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  getPermitSignature = async (hashData: any): Promise<any> => {
    if (!this.account) {
      throw new Error('Wallet address not found');
    } else {
      const {
        start,
        deadline,
        amt2authorize,
        nonce,
        tokname,
        chain,
        spender,
        version,
      } = hashData;
      const domainData = {
        name: tokname,
        version,
        chainId: chain,
        verifyingContract: process.env.REACT_APP_BSC_USDC,
      };
      const types = {
        Permit: [
          { name: 'owner', type: 'address' },
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'deadline', type: 'uint256' },
        ],
      };
      const val = {
        owner: this.account,
        spender,
        value: amt2authorize,
        nonce,
        deadline,
      };
      const tprovider = new ethers.providers.Web3Provider(window.ethereum);
      const tsigner = tprovider.getSigner();
      const sig = await tsigner._signTypedData(domainData, types, val); // eslint-disable-line no-underscore-dangle
      return sig;
    }
  };

  getRWASignature = async (hashData: any): Promise<any> => {
    console.log('getRWASignature');
    if (!this.account) {
      console.log('Wallet address not found');
      throw new Error('Wallet address not found');
    } else {
      console.log(hashData);
      const {
        start,
        deadline,
        amt2authorize,
        nonce,
        tokname,
        chain,
        spender,
        version,
      } = hashData;
      const domainData = {
        name: tokname,
        version,
        chainId: chain,
        verifyingContract: process.env.REACT_APP_BSC_USDC,
      };
      const types = {
        // TransferWithAuthorization: [
        ReceiveWithAuthorization: [
          { name: 'from', type: 'address' },
          { name: 'to', type: 'address' },
          { name: 'value', type: 'uint256' },
          { name: 'validAfter', type: 'uint256' },
          { name: 'validBefore', type: 'uint256' },
          { name: 'nonce', type: 'bytes32' },
        ],
      };
      const val = {
        from: this.account,
        to: spender,
        value: amt2authorize,
        validAfter: start,
        validBefore: deadline,
        nonce: ethers.utils.hexZeroPad(`0x${nonce}`, 32),
      };
      const tprovider = new ethers.providers.Web3Provider(window.ethereum);
      const tsigner = tprovider.getSigner();
      const sig = await tsigner._signTypedData(domainData, types, val); // eslint-disable-line no-underscore-dangle
      return sig;
    }
  };

  refreshFeeAndTimestamp = async (
    feeTimestamp: {
      fee: number;
      timestamp: number;
      result: string;
    },
    debouncedFund: string
  ) => {
    const oneMinute = 60 * 1000; // One minute in milliseconds
    const currentTime = Date.now();

    // Check if the timestamp is more than a minute old
    if (currentTime - feeTimestamp.timestamp > oneMinute) {
      const newTimestamp = currentTime; // The current time as the new timestamp
      try {
        const response = await api.get('/v1/billing/usdcestimatefee/');
        const newFee = parseFloat(response.data.usd_fee);
        this.updateParentState(
          newFee,
          newTimestamp,
          'refreshed',
          debouncedFund
        );
      } catch (error) {
        this.updateParentState(
          feeTimestamp.fee,
          feeTimestamp.timestamp,
          'error',
          debouncedFund
        );
      }
    } else {
      this.updateParentState(
        feeTimestamp.fee,
        feeTimestamp.timestamp,
        'no update',
        debouncedFund
      );
    }
  };
}

export default Usdc;
