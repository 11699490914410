import React, { useCallback, useState } from 'react';

import { CopyButton, CopyAlert } from './styles';

import copyHighter from '~/assets/icons/copy-highter.svg';

interface ICopyProps {
  content: string;
  className?: string;
}

const Copy: React.FC<ICopyProps> = ({ content, className }) => {
  const [copied, setCopied] = useState(false);

  const handleClickCopy = useCallback((url) => {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 800);
  }, []);

  return (
    <>
      <CopyButton
        type="button"
        className={`d-flex justify-content-between align-items-center bg-light-gray border-0 rounded-pill px-3 py-2 my-lg-2 ${className}`}
        onClick={() => handleClickCopy(content)}
      >
        <small className="text-white size-mobile height-mob">{content}</small>
        <div className="border-0 bg-transparent">
          <img src={copyHighter} alt="Copy" />
        </div>
      </CopyButton>
      <CopyAlert active={copied} className="card card-success">
        <p className="h4 m-2">Copied!</p>
      </CopyAlert>
    </>
  );
};

export default Copy;
