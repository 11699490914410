import React from 'react';
import { FiLock, FiHeart } from 'react-icons/fi';
import { BiPlus } from 'react-icons/bi';

import { useHistory } from 'react-router-dom';
import { Container } from './styles';

interface IModal {
  onClick(): void;
}

const Private: React.FC<IModal> = ({ onClick }) => {
  const history = useHistory();
  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="d-flex align-items-center mb-4">
              <FiLock size={20} color="#5D5B5B" />
              <span className="ms-2 pt-1">Private collections</span>
            </h3>
          </div>
          <div className="col-md-3 mt-2 mb-5">
            <div>
              <button
                onClick={() =>
                  history.push(
                    `${process.env.PUBLIC_URL}/products/ai-art/collections-private`
                  )
                }
                type="button"
                className="bg-favorites d-flex p-5 border-0"
              >
                <FiHeart color="#dadada" size={25} className="me-3" /> Favorites
              </button>
            </div>
          </div>
          {/* <div className="col-md-3 mt-2">
            <div>
              <button
                onClick={onClick}
                type="button"
                className="btn-create bg-transparent d-flex px-5 pt-5 w-100"
              >
                <BiPlus color="#dadada" size={25} className="me-3" /> Create
                Collection
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </Container>
  );
};

export default Private;
