import React, { useState, useCallback, useEffect } from 'react';

import InputDate from '~/components/InputDate';

import { Container, Welcome, Content, Graph, ContainerLinks } from './styles';
import Copy from '~/components/Copy';

import facebookShare from '~/assets/icons/facebook-share.svg';
import twitterShare from '~/assets/icons/twitter-share.svg';
import linkedinShare from '~/assets/icons/linkedin-share.svg';
import dateUpdate from '~/assets/icons/date-update.svg';
import api from '~/services/api';

interface ILinkResponse {
  id: string;
  link: string;
  description: string;
  thumbnail: {
    thumbnail_url: string;
  };
}
interface ILink {
  id: string;
  link: string;
  description: string;
  thumbnail: string;
}

const Links: React.FC = () => {
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  // const [links, setLinks] = useState<ILink[]>([]);

  const links = [
    {
      id: '1',
      name: 'Jane Doe',
      link: 'https://www.nett.ai/james9123',
      description:
        'I love how much I learned from this book! And I just found out you can get a free copy',
      thumbnail: 'https://placeimg.com/800/500/any',
    },
    {
      id: '2',
      name: 'katerine Zeta',
      link: 'https://www.nett.ai/james9123',
      description:
        'I love how much I learned from this book! And I just found out you can get a free copy',
      thumbnail: 'https://placeimg.com/800/500/any',
    },
    {
      id: '3',
      name: 'Jhon Jones',
      link: 'https://www.nett.ai/james9123',
      description:
        'I love how much I learned from this book! And I just found out you can get a free copy',
      thumbnail: 'https://placeimg.com/800/500/any',
    },
  ];

  useEffect(() => {
    // api.get<ILinkResponse[]>('links/users').then((response) => {
    //   const data: ILink[] = response.data.map((link) => ({
    //     id: link.id,
    //     link: link.link,
    //     description: link.description,
    //     thumbnail: link.thumbnail.thumbnail_url,
    //   }));
    //   setLinks(data);
    // });
  }, []);

  const handleChangeDateStart = useCallback((e) => {
    setDateStart(e);
  }, []);

  const handleChangeDateEnd = useCallback((e) => {
    setDateEnd(e);
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 mb-2">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-9 px-0">
                    <h1 className="h3 h2-lg text-center text-lg-start mt-4 mt-lg-0 fw-bold">
                      Promotional Links
                    </h1>
                    <p className="small">
                      Below you will find several links to promotional content,
                      funnels, blogs, etc. Each Link carries your personal
                      referal code.
                      <br /> These high converting pages are created to help you
                      grow your your referral list within our platorm. Enjoy!
                    </p>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
        </div>

        <div className="row">
          {links.map((link) => (
            <ContainerLinks
              // key={link.id}
              thumbnail={link.thumbnail}
              className="col-md-6 col-xl-4 my-2"
            >
              <div className="bg-gray card-social-media p-4">
                <div className="row">
                  <div className="col-7 small">
                    <span className="register-name">{link.name}</span>
                    <br />
                    Shared a link
                  </div>

                  <div className="col-5 text-end social-media-group">
                    <button type="button" className="border-0 bg-transparent">
                      <img src={facebookShare} alt="Facebook" />
                    </button>

                    <button
                      type="button"
                      className="border-0 bg-transparent mx-2"
                    >
                      <img src={twitterShare} alt="Twitter" />
                    </button>

                    <button type="button" className="border-0 bg-transparent">
                      <img src={linkedinShare} alt="Linkedin" />
                    </button>
                  </div>
                </div>
                <p className="small my-3">{link.description}</p>
                <div className="thumbnail mb-3" />
                <Copy content={link.link} className="w-100" />
              </div>
            </ContainerLinks>
          ))}
        </div>

        {false && (
          <div className="row mx-1">
            <Content>
              <div className="row mt-4 ml-0 mr-0">
                <div className="col-md-5">
                  <h2 className="h4">Campaigns</h2>
                </div>

                <div className="col-md-7 text-center text-md-right">
                  <div className="row justify-content-md-end justify-content-center pr-md-3 pr-0">
                    <div className="col-4 inputDate">
                      <InputDate
                        onChange={handleChangeDateStart}
                        selected={dateStart}
                        className="input w-100"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div className="col-4 ml-2 inputDate">
                      <InputDate
                        onChange={handleChangeDateEnd}
                        selected={dateEnd}
                        className="input w-100"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div className="col-1 ml-2 inputDate btn-date">
                      <img
                        src={dateUpdate}
                        alt="Date Update"
                        className="align-self-center"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4 ml-0 mr-0">
                <div className="col-sm-6 mb-2 mb-sm-0">
                  <Graph className="h-100">data</Graph>
                </div>

                <div className="col-sm-6 mb-2 mb-sm-0">
                  <div className="row">
                    <div className="col-sm-6 mb-2 mb-sm-0">
                      <Graph>data</Graph>
                    </div>
                    <div className="col-sm-6">
                      <Graph>data</Graph>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-sm-6 mb-2 mb-sm-0">
                      <Graph>data</Graph>
                    </div>
                    <div className="col-sm-6">
                      <Graph>data</Graph>
                    </div>
                  </div>
                </div>
              </div>
            </Content>
          </div>
        )}
      </div>
    </Container>
  );
};
export default Links;
