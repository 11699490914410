import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  > a {
    height: 100%;
  }

  .timeline-box {
    background-color: #212122;
    box-shadow: 8px 16px 40px rgba(0, 0, 0, 0.25);
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    .sinalizer {
      > div:nth-child(1) {
        height: 50px;
        width: 1px;
        background: linear-gradient(
          239.23deg,
          #9df3ff 7.84%,
          #bcffe5 28.62%,
          #deefd1 38.94%,
          #ffe0be 49.96%,
          #fbc2d3 64.82%,
          #fabcd6 67.63%,
          #f281ff 91.88%
        );
        margin: 0px auto;
        opacity: 0;
      }

      > div:nth-child(2) {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #333333;
        }
      }

      > div:nth-child(3) {
        height: 50px;
        width: 1px;
        background: linear-gradient(
          239.23deg,
          #9df3ff 7.84%,
          #bcffe5 28.62%,
          #deefd1 38.94%,
          #ffe0be 49.96%,
          #fbc2d3 64.82%,
          #fabcd6 67.63%,
          #f281ff 91.88%
        );
        margin: 0px auto;
        opacity: 0;
      }
    }

    p {
      width: 130px;
      color: #404040;
      font-weight: 700 !important;
      font-size: 18px;
    }

    a {
      pointer-events: none;
      cursor: default;
      text-decoration: none;
    }

    a.active {
      pointer-events: initial;
      cursor: pointer;
    }

    .active {
      .sinalizer {
        > div:nth-child(1),
        div:nth-child(3) {
          opacity: 1;
        }

        > div:nth-child(2) {
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 255, 255, 0.096) 100%
          );
          box-shadow: inset 0px 39px 52px -29px rgba(255, 255, 255, 0),
            inset 6px 1px 1px -4px rgba(255, 255, 255, 0.2),
            inset -6px -7px 8px -4px rgba(255, 255, 255, 0.2),
            inset 0px 7px 3px -4px rgba(255, 255, 255, 0.2),
            inset 51px -73px 72px -60px rgba(255, 255, 255, 0),
            inset 0px 98px 100px -48px rgba(255, 255, 255, 0),
            inset 0px 4px 18px rgba(255, 255, 255, 0),
            inset 0px 1px 40px rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(2px);
          border-radius: 50%;

          div {
            background: linear-gradient(
              239.23deg,
              #9df3ff 7.84%,
              #bcffe5 28.62%,
              #deefd1 38.94%,
              #ffe0be 49.96%,
              #fbc2d3 64.82%,
              #fabcd6 67.63%,
              #f281ff 91.88%
            );
          }
        }
      }

      p {
        background: linear-gradient(
          239.23deg,
          #9df3ff 7.84%,
          #bcffe5 28.62%,
          #deefd1 38.94%,
          #ffe0be 49.96%,
          #fbc2d3 64.82%,
          #fabcd6 67.63%,
          #f281ff 91.88%
        );
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .timeline-box {
      padding-left: 2.75rem;
      padding-right: 2.75rem;
    }
    .active .sinalizer p,
    .timeline-box p,
    .timeline-box .active p {
      font-size: 14px;
    }

    .timeline-box .sinalizer > div:nth-child(2) div {
      width: 23px;
      height: 23px;
    }

    .timeline-box .sinalizer > div:nth-child(2) {
      width: 37px;
      height: 37px;
    }

    .timeline-box .sinalizer > div:nth-child(1),
    .timeline-box .sinalizer > div:nth-child(3) {
      height: 37px;
    }
  }
  @media screen and (max-width: 1399px) {
    .timeline-box {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
  }

  @media screen and (max-width: 1199px) {
    .timeline-box {
      padding-left: 1rem;
      padding-right: 1rem;
      .sinalizer {
        > div:nth-child(2) {
          width: 40px;
          height: 40px;

          div {
            width: 25px;
            height: 25px;
          }
        }
      }

      p {
        font-size: 90%;
      }
    }
  }

  @media screen and (max-width: 991px) {
    overflow: auto;
    ::-webkit-scrollbar-thumb {
      background: transparent !important;
    }
    .timeline-box {
      background: transparent;
      box-shadow: 8px 16px 40px transparent;
      > a {
        display: none !important;
        width: 50%;
      }

      .sinalizer {
        > div:nth-child(1),
        > div:nth-child(3) {
          height: 1px;
          width: 100px;
        }
      }

      p {
        text-align: center;
      }

      .active,
      .semi {
        display: flex !important;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .timeline-box .sinalizer {
      > div:nth-child(1),
      > div:nth-child(3) {
        width: 20vw;
      }
    }
  }

  @media screen and (max-width: 390px) {
    .timeline-box .sinalizer {
      > div:nth-child(1),
      > div:nth-child(3) {
        width: 19vw;
      }
    }
  }
`;
