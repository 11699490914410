import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Banner, ChooseNft } from './styles';
import Timeline from '~/components/Timeline';
import Player from '~/components/Player';
import ButtonBack from '~/components/ButtonBack';

import retangle from '~/assets/defaults/thumbnail-opportunity-rectangle.png';

import basketExample from '~/assets/defaults/basket-example.png';
import heroExample from '~/assets/defaults/hero-example.png';

import qrCode from '~/assets/defaults/qr-code-example.png';

import thumbnail from '~/assets/defaults/thumbnail-learn-now.png';
import Attention from '~/components/Attention';
import WalletAvatar from '~/components/WalletAvatar';

const ActiveNft: React.FC = () => {
  const history = useHistory();

  const [chooseNft, setChooseNft] = useState(true);

  const nft = [
    {
      id: 1,
      nftImg: basketExample,
      serial: '0000123',
      qrCode,
    },

    {
      id: 2,
      nftImg: heroExample,
      serial: '0000456',
      qrCode,
    },
  ];

  const steps = useMemo(
    () => [
      {
        value: 'Get Your Free NFT',
        active: true,
      },
      {
        value: 'How To Participate',
        active: true,
      },
      {
        value: 'Activate Your Profit Centers',
        active: false,
        semi: true,
      },
      {
        value: 'Earn Even More!',
        active: false,
      },
      {
        value: 'Final Step',
        active: false,
      },
    ],
    []
  );

  const handleChooseNft = () => {
    setChooseNft(false);
  };

  const handleClick = useCallback(
    (id) => {
      history.push(`${process.env.PUBLIC_URL}/activate-your-profit-centers`);
    },
    [history]
  );

  return (
    <Banner className="position-relative overflow-hidden w-100">
      <div className="container-fluid">
        <div className="row vh-lg-100 overflow-auto">
          {chooseNft && (
            <div className="col-12 px-0">
              <Attention />
            </div>
          )}
          <div className="bg-gray col-lg-2 px-0">
            <Timeline steps={steps} />
          </div>
          <div className="col-lg-10 max-width pb-3 p-lg-4 p-xxl-5 text-center text-lg-left">
            <div className="d-none d-lg-flex justify-content-between logo mb-n5">
              <ButtonBack
                onClick={() => {
                  history.push(`${process.env.PUBLIC_URL}/get-your-free-nft`);
                }}
              />
              <WalletAvatar />
            </div>
            {chooseNft && (
              <>
                <div className="row justify-content-center px-lg-5 mb-2 mb-lg-4">
                  <div className="col-lg-9 zoom text-center d-flex justify-content-center">
                    <div className="w-100">
                      <h1 className="display-lg-4 fw-bold">
                        <span>
                          <span>Start Earning Today</span>
                        </span>
                      </h1>
                      <div className="row justify-content-center">
                        <div className="col-lg-9 col-xxl-7">
                          <p className="gradient-parts">
                            <span>
                              Be part of our exclusive affiliate community and
                              take a step towards
                            </span>{' '}
                            <b>Financial Freedom</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center px-lg-5">
                  <div className="col-11 col-md-9 position-relative">
                    <img
                      src={retangle}
                      alt=""
                      className="back-retangle-right"
                    />
                    <img src={retangle} alt="" className="back-retangle-left" />
                    <Player
                      src="https://prfti.s3.amazonaws.com/2.mov"
                      thumbnail={thumbnail}
                    />
                  </div>
                </div>

                <div className="row justify-content-center px-lg-5 mt-4">
                  <div className="col-11 col-md-9 zoom">
                    <button
                      type="button"
                      onClick={handleClick}
                      className="w-100 btn mt-4 mt-lg-0 btn-activate"
                    >
                      <b>ACTIVATE YOUR NFT ACCOUNT NOW</b>
                    </button>
                  </div>
                  <div className="col-11 col-md-9 zoom">
                    <button
                      type="button"
                      className="w-100 btn mt-4 btn-skip"
                      // onClick={() =>
                      //   history.push(`${process.env.PUBLIC_URL}/earn-even-more`)
                      // }
                      onClick={() =>
                        history.push(
                          `${process.env.PUBLIC_URL}/activate-your-profit-centers/skip`
                        )
                      }
                    >
                      <b>Skip for now</b>
                    </button>
                  </div>
                </div>
              </>
            )}

            {!chooseNft && (
              <>
                <div className="row justify-content-center px-lg-5 mb-2 mb-lg-4">
                  <div className="col-lg-9 text-center d-flex justify-content-center">
                    <div className="w-100">
                      <h1 className="display-lg-4 fw-bold">
                        <span>
                          <span>Choose An NFT</span>
                        </span>
                      </h1>
                      <div className="row justify-content-center">
                        <div className="col-lg-9 col-xxl-7">
                          <p className="gradient-parts">
                            <span>
                              Looks like you have more than one PROFITi NFT in
                              your wallet, choose which one you would like to
                              activate
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ChooseNft className="row justify-content-center px-lg-5">
                  {nft.map((nfts) => (
                    <div
                      key={nfts.id}
                      className="col-sm-6 col-xl-5 col-xxl-4 d-flex justify-content-center"
                    >
                      <div className="d-flex flex-column">
                        <div className="bg-nft">
                          <img
                            src={nfts.nftImg}
                            alt="Basket Example"
                            className="w-100 nft p-2"
                          />
                          <div className="d-flex pt-4 pb-3 justify-content-center align-items-center">
                            <div className="w-60 text-start">
                              <span>
                                Serial #<br />
                                <span className="fw-bold">{nfts.serial}</span>
                              </span>
                            </div>
                            <div className="w-30">
                              <img
                                src={nfts.qrCode}
                                alt="Qr Code"
                                className="w-100 qr-code"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => handleClick(nfts.id)}
                          className="w-100 my-5"
                        >
                          ACTIVATE
                        </button>
                      </div>
                    </div>
                  ))}
                </ChooseNft>
              </>
            )}
          </div>
        </div>
      </div>
    </Banner>
  );
};

export default ActiveNft;
