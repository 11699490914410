import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import WalletRow from '../WalletRow';

import { Container } from './styles';
import title from '~/assets/logo/ai-creator.svg';

interface ILoading {
  loading?: boolean;
}

const AiCreatorTitle: React.FC<ILoading> = ({ loading }) => {
  const location = useLocation();

  const url = [
    '/products/ai-art/collections/all',
    '/products/ai-art/collections/private',
    '/products/ai-art/collections/public',
    '/products/ai-art/collections-private',
  ];

  return (
    <Container>
      <div className="container-fluid">
        <div className="row justify-content-between mt-2">
          <div className="col-3">
            <img src={title} alt="Logo ai art creator" className="w-100" />
          </div>
          <div className="col-3 d-flex align-items-center">
            {!loading && (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/products/ai-art/nft-history`}
                  className={`history-collec ms-4 ${
                    location.pathname === '/products/ai-art/nft-history' &&
                    'active'
                  }`}
                >
                  History
                </Link>

                <Link
                  to={`${process.env.PUBLIC_URL}/products/ai-art/collections/all`}
                  className={`history-collec ms-4 ${
                    url.includes(location.pathname) && 'active'
                  }`}
                >
                  Collections
                </Link>
              </>
            )}
          </div>
          <div className="col-6">
            <WalletRow />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AiCreatorTitle;
