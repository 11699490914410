import styled from 'styled-components';

export const Container = styled.div`
  background: #ff1a50;
  box-shadow: 0px 4px 4px rgba(255, 26, 80, 0.15);

  svg {
    height: 30px;
    width: 30px;
  }

  @media screen and (max-width: 1799px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    svg {
      height: 25px;
      width: 25px;
    }
  }

  @media screen and (max-width: 767px) {
    svg {
      height: 25px;
      width: 25px;
    }
  }
`;
