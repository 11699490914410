import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { web3store } from '../../store';
import { Container, Welcome, Modal, ModalFinish } from './styles';
import WalletRow from '~/components/WalletRow';
import logo from '~/assets/logo/logo-p-purple.svg';
import api from '~/services/api';
import ModalSwap from '~/components/ModalSwap';

import { useAuth } from '~/hooks/Auth';
import { formatPrice } from '~/utils/format';
import stakingTerms from '~/assets/animations/staking-terms-details.json';

import walletFinish from '~/assets/defaults/wallet-finish.png';
import logoWhite from '~/assets/logo/logo-p-white.svg';
import Loading from '~/components/Loading';
import Rewards from './Rewards';
import swalError from '~/utils/swalError';
import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';

interface ICirculation {
  circ: {
    circulating: string;
    current_supply: string;
    max_supply: string;
    burned: string;
  };
}

interface IStaking {
  staking: {
    locked: string;
    price: string;
    balance: string;
    earned: string;
  };
}

let timeoutId: any;

const TokenVault: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [circulation, setCirculation] = useState({} as ICirculation);
  const [staking, setStaking] = useState({} as IStaking);
  const [, setHarvestStake] = web3store.useState('harvestStake');
  const [countdown, setCountdown] = useState(0);
  const [filter, setFilter] = useState('all');
  const [buyStakingAmt, setBuyStakingAmt] = web3store.useState('buyStakingAmt');
  const [balusdpi] = web3store.useState('balusdpi');
  const [chainMode, setChainMode] = web3store.useState('chainMode');

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [modalCongratulations, setModalCongratulations] = useState(false);
  const [exchangeFinished, setExchangeFinished] = useState(false);
  const [, setCartPurchaseStake] = web3store.useState('cartPurchaseStake');
  const [cartPurchaseStakeTX, setCartPurchaseStakeTX] = web3store.useState(
    'cartPurchaseStakeTX'
  );

  const [withdrawalDripResult, setWithdrawalDripResult] = web3store.useState(
    'withdrawalDripResult'
  );
  const [withdrawalStakingResult, setWithdrawalStakingResult] =
    web3store.useState('withdrawalStakingResult');

  const usdpi = useMemo(
    () => parseFloat((parseInt(balusdpi, 10) / 10 ** 18).toFixed(2)),
    [balusdpi]
  );

  const handleAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setBuyStakingAmt(Number(event.target.value));
    },
    [setBuyStakingAmt]
  );

  useEffect(() => {
    api
      .get('v1/prfti/circulation')
      .then(async (responseCirculation) => {
        setCirculation({
          circ: {
            circulating: formatPrice(
              parseFloat(
                (
                  parseInt(responseCirculation.data.circ.circulating, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            current_supply: formatPrice(
              parseFloat(
                (
                  parseInt(responseCirculation.data.circ.current_supply, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            max_supply: formatPrice(
              parseFloat(
                (
                  parseInt(responseCirculation.data.circ.max_supply, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            burned: formatPrice(
              parseFloat(
                (
                  parseInt(responseCirculation.data.circ.burned, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
          },
        });
        let responseStaking = null;
        if (user?.id) {
          responseStaking = await api.get(`v1/staking/totals/${user?.id}`);
          setStaking({
            staking: {
              locked: formatPrice(
                parseFloat(
                  (
                    parseInt(responseStaking.data.staking.locked, 10) /
                    10 ** 18
                  ).toFixed(2)
                )
              ),
              price: formatPrice(
                parseFloat(
                  (
                    parseInt(responseStaking.data.staking.price, 10) /
                    10 ** 18
                  ).toFixed(2)
                )
              ),
              balance: formatPrice(
                parseFloat(
                  (
                    parseInt(responseStaking.data.staking.balance, 10) /
                    10 ** 18
                  ).toFixed(2)
                )
              ),
              earned: formatPrice(
                parseFloat(
                  (
                    parseInt(responseStaking.data.staking.earned, 10) /
                    10 ** 18
                  ).toFixed(2)
                )
              ),
            },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, withdrawalDripResult, withdrawalStakingResult]);

  useEffect(() => {
    clearTimeout(timeoutId);
    if (countdown > 0) {
      timeoutId = setTimeout(() => {
        setCountdown((state) => state - 1);
      }, 1000);
    }
  }, [countdown]);

  const days = useMemo(() => {
    return Math.floor(countdown / (60 * 60 * 24));
  }, [countdown]);

  const hours = useMemo(() => {
    return Math.floor(countdown / (60 * 60)) % 24;
  }, [countdown]);

  const minutes = useMemo(() => {
    return Math.floor(countdown / 60) % 60;
  }, [countdown]);

  const seconds = useMemo(() => {
    return countdown % 60;
  }, [countdown]);

  const handleClaimClick = useCallback(
    (id: number, available: string) => {
      console.log('Claim button clicked! %s, %s', id, available);
      setHarvestStake(id);
      // do something else here
    },
    [setHarvestStake]
  );

  const handleClickBuyStaking = useCallback(() => {
    if (usdpi >= buyStakingAmt) {
      networkSwitch('NETWORK')
        .then((response) => {
          setCartPurchaseStake(true);
          setLoading(true);
        })
        .catch((error) => {
          swalError({
            message: 'There was a problem with your transaction.',
            textButton: 'Try Again',
          });
        });
    } else {
      swalError({
        message: 'Insufficient balance',
        textButton: 'Try Again',
      });
    }
    setExchangeFinished(false);
  }, [buyStakingAmt, setCartPurchaseStake, usdpi]);

  const handleExchangeFinished = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setExchangeFinished(true);
    }, 3000);
  }, []);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setModalCongratulations(false);
  }, []);

  useEffect(() => {
    if (exchangeFinished) {
      handleClickBuyStaking();
    }
  }, [exchangeFinished, handleClickBuyStaking, usdpi]);

  useEffect(() => {
    if (cartPurchaseStakeTX !== '') {
      // ToDo: Close mask
      setLoading(false);
      setCartPurchaseStake(false);
      if (cartPurchaseStakeTX.startsWith('ERROR')) {
        swalError({
          message: cartPurchaseStakeTX,
          textButton: 'Try Again',
        });
      } else {
        setModalCongratulations(true);
      }
      setCartPurchaseStakeTX('');
    }
  }, [cartPurchaseStakeTX, history, setCartPurchaseStakeTX]);

  useEffect(() => {
    setChainMode('NETWORK');
  }, []);

  return (
    <Container>
      {Object.keys(circulation).length > 0 && Object.keys(staking).length > 0 && (
        <div className="container-fluid container-xxl">
          <div className="row justify-content-center">
            <div className="col-sm-11 col-lg-12 mt-4">
              <Welcome>
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-lg-6 d-flex align-items-center px-0">
                      <h1 className="h3 h2-lg fw-bold mb-lg-0">Token Vault</h1>
                      <div className="ms-4 border-gradient-light rounded-pill text-center">
                        <button
                          type="button"
                          onClick={() =>
                            history.push(`${process.env.PUBLIC_URL}/tokenomics`)
                          }
                          className="w-100 border-0 px-4 py-2 bg-transparent d-flex align-items-center justify-content-center"
                        >
                          Tokenomics
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 px-0">
                      <WalletRow />
                    </div>
                  </div>
                </div>
              </Welcome>
            </div>
          </div>
          <div className="row bd-botton py-3">
            <div className="col-lg-3">
              <div className="value-row text-center">
                Circulating
                <span>
                  &nbsp;
                  {circulation.circ.circulating}
                </span>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="value-row text-center">
                Total&nbsp;Supply
                <span>
                  &nbsp;
                  {circulation.circ.current_supply.slice(0, -3)}
                </span>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="value-row text-center">
                Max&nbsp;Supply
                <span>
                  &nbsp;
                  {circulation.circ.max_supply.slice(0, -3)}
                </span>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="value-row text-center">
                Burned
                <span>
                  &nbsp;
                  {circulation.circ.burned}
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-3 px-3 pb-3 box-title">
              <div className="bg-box h-100 p-4">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0">Total Value Locked</h3>
                  <img src={logo} alt="Logo" />
                </div>
                <h5 className="my-3">${staking.staking.locked}</h5>
                {/* <h6>+1.4% Compared to last week</h6> */}
              </div>
            </div>
            <div className="col-lg-3 px-3 pb-3 box-title">
              <div className="bg-box h-100 p-4">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0">PRFTI Price</h3>
                  <img src={logo} alt="Logo" />
                </div>
                <h5 className="my-3">${staking.staking.price}</h5>
                {/* <h6>+4% Compared to last day</h6> */}
              </div>
            </div>
            <div className="col-lg-3 px-3 pb-3 box-title">
              <div className="bg-box h-100 p-4">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0">My Staking Balance</h3>
                  <img src={logo} alt="Logo" />
                </div>
                <h5 className="my-3">
                  {staking.staking.balance}
                  <span>PRFTI</span>
                </h5>
                {/* <h6>+5% Compared to last month</h6> */}
              </div>
            </div>
            <div className="col-lg-3 px-3 pb-3 box-title">
              <div className="bg-box h-100 p-4">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0">My Staking Earnings</h3>
                  <img src={logo} alt="Logo" />
                </div>
                <h5 className="my-3">
                  {staking.staking.earned}
                  <span>PRFTI</span>
                </h5>
                {/* <h6>+5% Compared to last month</h6> */}
              </div>
            </div>
            <div className="col-lg-4 box-lock px-3 my-2">
              <div className="bg-box pt-4 px-4">
                <h3 className="mb-0">Lock Period</h3>

                <div className="d-flex date justify-content-between mt-4 px-3">
                  <div className="text-center">
                    {days.toString().padStart(2, '0')}
                    <span className="d-block">Day</span>
                  </div>
                  <div className="text-center">
                    {hours.toString().padStart(2, '0')}
                    <span className="d-block">Hour</span>
                  </div>
                  <div className="text-center">
                    {minutes.toString().padStart(2, '0')}
                    <span className="d-block">Mins</span>
                  </div>
                  <div className="text-center">
                    {seconds.toString().padStart(2, '0')}
                    <span className="d-block">Sec</span>
                  </div>
                </div>
                <div className="position-relative bg-box-blur overflow-hidden mt-4 mx-4 px-3 py-4">
                  <div className="position-relative text-center index1">
                    <div className="apy">
                      <span className="d-block">APY</span>
                      <span className="d-block">60%</span>
                      <span className="d-block mb-4">5% Per Month</span>
                    </div>
                  </div>
                  <div className="position-absolute bg-grad-blur" />
                </div>
              </div>
            </div>

            {/* <div className="col-lg-8 px-3 my-2 max-height">
              {transactions.map((transaction) => (
                <div className="bg-box d-flex amount p-4 mb-3 justify-content-between align-items-center">
                  <div>
                    <img src={logo} alt="Logo" />
                  </div>
                  <div>
                    <h3 className="mb-1">Contract Amount</h3>
                    <span>{transaction.contract_id}</span>
                    <span>{transaction.tokens}</span>
                  </div>
                  <div>
                    <h3 className="mb-1">APY</h3>
                    <span>{transaction.apy}%</span>
                  </div>
                  <div>
                    <div className="d-flex">
                      <h3 className="mb-1">Available</h3>
                      <button
                        type="button"
                        onClick={() =>
                          handleClickReloadAvailable(transaction.contract_id)
                        }
                        className="btn-reload border-0 d-flex align-items-center justify-content-center mx-1"
                      >
                        <img src={updateIcon} alt="update-icon" />
                      </button>
                    </div>
                    <span>{transaction.available}</span>
                  </div>
                  <div>
                    <h3 className="mb-1">Claimed</h3>
                    <span>{transaction.claimed}</span>
                  </div>
                  <div>
                    <h3 className="mb-1">Maturity Date</h3>
                    <span>{transaction.maturity_date} Days</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="px-3 py-2 border-0"
                      onClick={() =>
                        handleClaimClick(
                          transaction.contract_id,
                          transaction.available
                        )
                      }
                    >
                      Claim
                    </button>
                  </div>
                </div>
              ))}
            </div> */}

            <div className="col-lg-8 px-3 my-2">
              <div className="bd-gray px-3">
                <div className="d-flex py-3 btns-filter justify-content-evenly">
                  <button
                    type="button"
                    className={`${filter === 'all' && 'active'}`}
                    onClick={() => setFilter('all')}
                  >
                    All
                  </button>
                  <button
                    type="button"
                    className={`${filter === 'rewards' && 'active'}`}
                    onClick={() => setFilter('rewards')}
                  >
                    Member Rewards
                  </button>
                  <button
                    type="button"
                    className={`${filter === 'staking' && 'active'}`}
                    onClick={() => setFilter('staking')}
                  >
                    Staking
                  </button>
                  <button
                    type="button"
                    className={`${filter === 'profiti' && 'active'}`}
                    onClick={() => setFilter('profiti')}
                  >
                    PROFITi Pool
                  </button>
                  <button
                    type="button"
                    className={`${filter === 'performance' && 'active'}`}
                    onClick={() => setFilter('performance')}
                  >
                    Performance Pool
                  </button>
                </div>
                <Rewards filter={filter} setCountdown={setCountdown} />
              </div>
            </div>

            <div className="col-lg-7 px-3 mt-3">
              <div className="bg-box amount-stake p-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="mb-0">Amount to Stake</h3>

                  <div className="d-flex align-items-center">
                    <h3 className="mb-0 me-1">
                      Available PRFTI Rewards to Claim: <span>0.00</span>
                    </h3>
                    {/* <button
                      type="button"
                      className="px-3 py-2 border-0 ms-4 claim"
                    >
                      Claim
                    </button> */}
                  </div>
                </div>
                <div className="bd-input d-flex justify-content-between mt-3 p-3">
                  <input
                    type="number"
                    placeholder="Enter the amount you wish to stake"
                    className="w-90"
                    name=""
                    id=""
                  />
                  <button type="button" className="btn-max px-3">
                    Max
                  </button>
                </div>
                <button type="button" className="w-100 mt-3 btn-stake">
                  Stake
                </button>
              </div>
            </div>

            <div className="col-lg-5 px-3 mt-3">
              <div className="bg-box box-buy h-100 p-4">
                <h3 className="mb-0 pb-1">Buy Staking Contract</h3>

                <div className="bd-input d-flex justify-content-between mt-3 p-3">
                  <input
                    type="number"
                    placeholder="Choose amount (minimum $100)"
                    className="w-100"
                    min={100}
                    name=""
                    id=""
                    onChange={handleAmountChange}
                    value={buyStakingAmt > 0 ? buyStakingAmt : ''}
                  />
                </div>
                <button
                  type="button"
                  onClick={handleShow}
                  className="w-100 mt-3 btn-buy"
                  disabled={buyStakingAmt < 100}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: stakingTerms,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={220}
              width={300}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mb-4 fw-bold text-center w-100">
            Please Review Staking Terms
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4">Here is the transaction hash:</p>
            <div className="d-flex terms justify-content-between px-5 mb-2">
              <span>Term:</span>
              <span className="color-span">12 Months</span>
            </div>

            <div className="d-flex terms justify-content-between px-5 mb-2">
              <span>Amount Staking:</span>
              <span className="color-span">
                {formatPrice(buyStakingAmt)} PRFTI
              </span>
            </div>

            <div className="d-flex terms justify-content-between px-5 mb-2">
              <span>Exchange Rate:</span>
              <span className="color-span">1 PRFTI = 1.00 USD</span>
            </div>

            <div className="d-flex terms justify-content-between px-5 mb-2">
              <span>Token Bonus APY:</span>
              <span className="color-span">60%</span>
            </div>

            <div className="d-flex terms justify-content-between px-5 mb-2">
              <span>Released Ratio:</span>
              <span className="color-span">Every Second</span>
            </div>

            <div className="d-flex terms justify-content-between px-5 mb-2">
              <span>Total Amount To Receive:</span>
              <span className="color-span">
                {formatPrice(buyStakingAmt * 0.6)} PRFTI
              </span>
            </div>

            {usdpi < buyStakingAmt ? (
              <ModalSwap
                btnText="Confirm Purchase"
                className="btn-confirm w-100 my-4"
                onExchangeFinished={handleExchangeFinished}
              />
            ) : (
              <button
                type="button"
                onClick={handleClickBuyStaking}
                className="btn-confirm w-100 my-4"
              >
                Confirm Purchase{' '}
                <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
              </button>
            )}
            <button
              type="button"
              onClick={handleClose}
              className="btn-cancel w-100 mb-3"
            >
              <span className="">Cancel</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>

      <ModalFinish
        size="xl"
        show={modalCongratulations}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-5 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={walletFinish} alt="Wallet" />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mt-n4 mb-5 fw-bold text-center w-100">
            Congratulations!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4">
              Your staking contract purchase was successfully completed.
            </p>
            <p className="text-center mb-4 px-4">
              You can now follow the progress of your earnings on the dashboard.
            </p>

            <button
              type="button"
              onClick={() =>
                history.push(`${process.env.PUBLIC_URL}/dashboard`)
              }
              className="btn-confirm w-100 mt-5 mb-4"
            >
              <span className="">Back to dashboard</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </ModalFinish>
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="PLEASE WAIT..."
        active={loading}
      />
    </Container>
  );
};

export default TokenVault;
