import React, { useCallback, useState } from 'react';
import Player from '~/components/Player';
import ModalSwap from '~/components/ModalSwap';
import { Button, Modal } from './styles';
import thumb from '~/assets/defaults/thumb-need-usdpi.png';

interface IButton {
  btnText: string;
  onExchangeFinished?(): void;
  showNft?: boolean;
}

const ModalNeedUsdpi: React.FC<IButton> = ({
  btnText,
  showNft,
  onExchangeFinished,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback(() => setShow(true), []);

  return (
    <>
      <Button type="button" onClick={handleShow} className="w-100 mt-4">
        {btnText}
      </Button>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        className="modal-signin"
      >
        <button
          type="button"
          className="h4 modal-close m-3 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>

        <Modal.Header className="container border-0">
          <div className="row justify-content-center">
            <div className="col-sm-11 col-md-12 col-xl-9 text-center">
              <h2 className="fw-bold mb-4">You Need USDPI To Mint Your NFT</h2>
              <h3 className="px-4 px-sm-5">
                Profiti uses the stable token <b>USDPI</b> to record
                transactions on the blockchain but your connected wallet{' '}
                <span>doesn’t have enough</span>. Below you can see how you can
                easily convert BUSD to USDPI and generate your{' '}
                <span>FREE NFT</span>
              </h3>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="container">
          <div className="row px-4 px-sm-5 justify-content-center">
            <div className="col-sm-11 col-md-12 col-xl-9 coin">
              <Player
                src="https://prfti.s3.amazonaws.com/4.mov"
                thumbnail={thumb}
              />
            </div>
            <div className="col-sm-11 col-md-12 col-xl-9">
              <ModalSwap
                btnText="GET USDPI NOW"
                className="btn-swap w-100 btn mt-4 fw-bold"
                onExchangeFinished={onExchangeFinished}
                showNft={showNft}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 px-4 pb-5" />
      </Modal>
    </>
  );
};

export default ModalNeedUsdpi;
