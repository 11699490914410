import styled from 'styled-components';

interface IProps {
  src: string;
}

export const Container = styled.div`
  h2 {
    color: #cbccce;
  }
`;

export const BgImage = styled.button<IProps>`
  background-image: url(${(props) => props.src});
  border-radius: 20px;
  height: 400px;

  span {
    font-weight: 600;
    color: #cbccce;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 0px 0px 20px 20px;
  }

  @media screen and (max-width: 400px) {
    height: 300px;
  }
`;
