import React, { useMemo } from 'react';
import WalletRow from '~/components/WalletRow';

import { Container } from './styles';
import title from '~/assets/logo/ai-launchpad.svg';
import sample1 from '~/assets/defaults/sample_1.png';
import qrCode from '~/assets/defaults/qr-code-nft.svg';

const Launchpad: React.FC = () => {
  const samples = [
    {
      img: sample1,
      qrCode,
      serial: '0000123',
    },
    {
      img: sample1,
      qrCode,
      serial: '0000123',
    },
    {
      img: sample1,
      qrCode,
      serial: '0000123',
    },
    {
      img: sample1,
      qrCode,
      serial: '0000123',
    },
    {
      img: sample1,
      qrCode,
      serial: '0000123',
    },
    {
      img: sample1,
      qrCode,
      serial: '0000123',
    },
  ];

  return (
    <Container>
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center mt-2">
          <div className="col-3">
            <img src={title} alt="Logo ai art creator" className="w-100" />
          </div>

          <div className="col-6">
            <WalletRow />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5">
          {samples.map((item) => (
            <div className="col-lg-4 px-2 my-2">
              <div className="item-group">
                <div className="bg-nft p-2 ">
                  <div className="position-relative">
                    <img
                      src={item.img}
                      alt="NFT Generated"
                      className="w-100 nft"
                    />

                    <div className="d-flex pt-4 pb-3 bg-serial-qrcode w-100 justify-content-center align-items-center">
                      <div className="w-60">
                        <span>
                          Serial #<br />
                          <span className="fw-bold">{item.serial}</span>
                        </span>
                      </div>
                      <div className="w-30 text-end">
                        <img
                          src={item.qrCode}
                          alt="Qr Code"
                          className="w-75 qr-code bg-qrcode p-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Launchpad;
