/* eslint-disable react/button-has-type */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Modal, ModalCongrat } from './styles';
import ModalNftWallet from '../ModalNftWallet';
import Player from '~/components/Player';

import thumb from '~/assets/defaults/thumb-congratulations.png';
import qrCode from '~/assets/defaults/qr-code-nft.svg';
import telegram from '~/assets/defaults/telegram.svg';
import discord from '~/assets/defaults/discord.svg';
import twitter from '~/assets/defaults/twitter.svg';
import tikTok from '~/assets/defaults/tik-tok.svg';
import facebook from '~/assets/defaults/facebook.svg';
import instagram from '~/assets/defaults/instagram.svg';
import youtube from '~/assets/defaults/youtube.svg';
import reddit from '~/assets/defaults/reddit.svg';
import { web3store } from '~/store';

interface IModalYouGotUsdpi {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  showCongrats: boolean;
  setShowCongrat: React.Dispatch<React.SetStateAction<boolean>>;
  onClickActivated(): void;
}

export const ModalYouGotUsdpi: React.FC<IModalYouGotUsdpi> = ({
  show,
  setShow,
  showCongrats,
  setShowCongrat,
  onClickActivated,
}) => {
  const [imgURL] = web3store.useState('imgURL');
  const [nftimg] = web3store.useState('nftimg');

  const handleActivate = useCallback(() => {
    onClickActivated();
  }, [onClickActivated]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      className="modal-signin"
      backdrop="static"
    >
      <button
        type="button"
        className="h4 modal-close mt-3 mb-0 mx-3 ms-auto border-0 bg-transparent"
        onClick={handleClose}
      >
        x
      </button>

      <Modal.Header className="container border-0">
        <div className="row w-100 justify-content-center">
          <div className="col-7 text-center">
            <h2 className="fw-bold mb-4">Congrats! You got USDPI</h2>
            <h3 className="px-5">
              You are now ready to Mint your FREE NFT!
              <br />
              Click below to Mint now.
            </h3>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body className="container">
        <div className="row px-4 px-sm-5 justify-content-center">
          <div className="col-sm-6 col-lg-5">
            <div className="bg-nft p-2 position-relative">
              <img
                src={nftimg || imgURL}
                alt="NFT Generated"
                className="w-100 nft"
              />
              <div className="d-flex pt-4 pb-3 bg-serial-qrcode justify-content-center align-items-center">
                <div className="w-60">
                  <span>
                    Serial #<br />
                    <span className="fw-bold">0000123</span>
                  </span>
                </div>
                <div className="w-30 text-end">
                  <img
                    src={qrCode}
                    alt="Qr Code"
                    className="w-75 qr-code bg-qrcode p-3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-9">
            <ModalNftWallet
              showNft={showCongrats}
              setShowNft={setShowCongrat}
              onActivate={handleActivate}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 px-4 pb-5" />
    </Modal>
  );
};

interface ICongratsModal {
  show: boolean;
  onHide(): void;
}

export const CongratsModal: React.FC<ICongratsModal> = ({ show, onHide }) => {
  const history = useHistory();

  return (
    <ModalCongrat
      size="xl"
      show={show}
      onHide={onHide}
      className="modal-signin"
    >
      <button
        type="button"
        className="h4 modal-close m-3 ms-auto border-0 bg-transparent"
        onClick={onHide}
      >
        x
      </button>

      <Modal.Header className="container border-0">
        <div className="row justify-content-center">
          <div className="col-10 col-xl-9 text-center">
            <h2 className="fw-bold mb-4">Congratulations!</h2>
            <h3 className="px-5">
              Your account has been successfully activated, you can log in to
              your dashboard by clicking the button below
            </h3>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body className="container">
        <div className="row px-4 px-lg-3 px-xl-5 justify-content-center">
          <div className="col-10 col-xl-9 coin">
            <Player
              src="https://prfti.s3.amazonaws.com/5.mov"
              thumbnail={thumb}
            />
          </div>
          <div className="col-10 col-xl-9">
            <button
              type="button"
              className="w-100 to-dashboard mt-4 fw-bold"
              onClick={() =>
                history.push(`${process.env.PUBLIC_URL}/dashboard`)
              }
            >
              GO TO DASHBOARD
            </button>
          </div>
          <div className="col-xl-11 mt-4 overflow-auto bg-lg-social-medias">
            <div className="d-lg-flex justify-content-sm-around justify-content-lg-center social-medias  px-0 px-lg-5">
              <div className="bg-mobile-social-medias text-center px-2 px-lg-0">
                <a
                  target="_blank"
                  href="https://t.me/ProfitiAi"
                  rel="noreferrer"
                >
                  <img src={telegram} alt="Telegram" />
                </a>
                <a
                  target="_blank"
                  href="https://discord.gg/KxdwygBsKk"
                  rel="noreferrer"
                >
                  <img src={discord} alt="Discord" />
                </a>
                <a
                  target="_blank"
                  href="https://twitter.com/profitiai"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="Twitter" />
                </a>
                <a
                  target="_blank"
                  href="https://www.tiktok.com/@profitiai"
                  rel="noreferrer"
                >
                  <img src={tikTok} alt="Tik-Tok" />
                </a>
              </div>
              <div className="bg-mobile-social-medias text-center px-2 px-lg-0 mt-3 mt-lg-0">
                <a
                  target="_blank"
                  href="https://www.facebook.com/profitiai"
                  rel="noreferrer"
                >
                  <img src={facebook} alt="Facebook" />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/profitiai/"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="Instagram" />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/@profitiai/channels"
                  rel="noreferrer"
                >
                  <img src={youtube} alt="Youtube" />
                </a>
                <a
                  target="_blank"
                  href="https://www.reddit.com/r/profitiai/"
                  rel="noreferrer"
                >
                  <img src={reddit} alt="Reddit" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalCongrat>
  );
};

interface IModalConfirmation {
  showCongrat: boolean;
  setShowCongrat: React.Dispatch<React.SetStateAction<boolean>>;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const ModalConfirmation: React.FC<IModalConfirmation> = ({
  type,
  disabled,
  showCongrat,
  setShowCongrat,
}) => {
  const handleClose = useCallback(() => {
    setShowCongrat(false);
  }, [setShowCongrat]);

  return (
    <>
      <Button className="position-absolute" disabled={disabled}>
        <div className="gradient-swap rounded-circle position-absolute" />
        <div className="transparent rounded-circle position-absolute" />
        <div className="white rounded-circle position-absolute" />
        <button
          type={type}
          className="h5 h-100 w-100 border-0 text-black fw-bold rounded-circle position-absolute"
          disabled={disabled}
        >
          Exchange
        </button>
      </Button>
      <CongratsModal show={showCongrat} onHide={handleClose} />
    </>
  );
};

export default ModalConfirmation;
