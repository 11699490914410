import React from 'react';

import { ResizeProvider } from './Resize';
import { AuthProvider } from './Auth';
import { LanguageProvider } from './Language';

const AppProvider: React.FC = ({ children }) => (
  <ResizeProvider>
    <AuthProvider>
      <LanguageProvider>{children}</LanguageProvider>
    </AuthProvider>
  </ResizeProvider>
);

export default AppProvider;
