import { darken } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  background: #222527;
  border-radius: 20px;

  .error-message {
    margin-top: 10px;
    margin-left: 10px;
    color: white;
  }

  .bd-input {
    border: 1px solid #4f4f4f;
    border-radius: 15px;
  }

  .bg-box-input {
    background: transparent;
    border-radius: 20px;
  }

  .bd-input input {
    background: transparent;
    border: none;
    font-weight: 300;
    font-size: 16px;
    color: #828282;

    ::placeholder {
      font-weight: 300;
      font-size: 12px;
      color: #4b4848;
    }
  }

  .bd-input button {
    background: #1d2023;
    border: 1px solid #2f2f2f;
    border-radius: 10px;
    font-weight: 700;
    font-size: 10px;
    color: #797979;
  }

  .TitleBox {
    color: white;
    display: table;
    margin: 0 auto;
    font-weight: 500;
    font-size: 24px;
    color: #f5f6f8;
  }

  .ValueBox {
    display: table;
    margin: 0 auto;
    color: white;
    font-weight: 500;
    margin-bottom: 5%;
    font-size: 48px;
  }

  .TimeBox {
    color: #828282;
    margin-top: 1%;
    margin-bottom: 5%;
  }

  .Active {
    color: white;
    font-weight: bold;
  }

  .MonthBox {
    margin-top: 1.5em;
    margin-bottom: 4em;
  }

  .ItemMonthBox {
    background-color: #20202000 !important;
    border: none;
    padding: 10px 5px;
    background-image: linear-gradient(180deg, #24252600, #24252600),
      linear-gradient(269.95deg, #e323ff00 0.02%, #79d1f800 97.45%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    color: #c7c7c7;
    font-size: 14px;
    min-width: 88px;
    font-weight: 400;
    height: 35px;
  }

  .border-gradient-light {
    padding: 1px;
  }

  .ItemMonthBox.Active {
    background-color: #202020 !important;
    border: none;
    padding: 1px;
    background-image: linear-gradient(180deg, #1d2023, #1d2023),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    color: #c7c7c7;
    font-weight: 600;
  }

  .WithDrawBox {
    background: linear-gradient(0deg, #18191a, #18191a);
    box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
  }

  .WithDrawBox .TitleWithDrawBox {
    color: #828282;
  }

  .WithDrawBox .ValueWithDrawBox {
    color: white;
    font-weight: bolder;
    font-size: 20px;
  }

  .border-rad {
    border-radius: 20px;
  }

  .WithDrawBox .ButtonWithDrawBox {
    background-color: #242526;
    color: white;
    border-radius: 20px;
    font-size: 20px;
    padding: 12px;
    transition-duration: 0.3s;
  }

  .ButtonWithDrawBox:hover {
    opacity: 0.8;
  }

  .WithDrawBox .ButtonBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .WithDrawBox .ButtonBox .ButtonWithDrawBox {
  }

  .ItemTimeBox {
    transition-duration: 0.3s;
  }

  .ItemTimeBox:hover,
  .ItemTimeBox.Active {
    color: #f5f6f8;
  }

  .slick-dots li.slick-active button:before {
    color: #cbccce !important;
  }

  .slick-dots li button:before {
    color: #4f4f4f !important;
  }

  .slick-dots {
    bottom: -35px;
  }

  .slick-dots li {
    margin: 0 !important;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #cbccce;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 400px) {
    .ItemMonthBox {
      font-size: 12px;
      min-width: 70px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    img {
      width: 750px;
      margin: -4rem;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .texts p:nth-child(1) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 24px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts p:nth-child(2) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 20px;
      color: #7d7777;
    }

    .texts div:nth-child(3) {
      border: 1px solid #4f4f4f;
      border-radius: 20px;
      font-weight: 300;
      font-size: 14px;
      align-items: center;
      text-align: center;
      color: #8767ed;
      padding: 23px;
    }

    .texts p:nth-child(4) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 20px;
      line-height: 118%;
      text-align: center;
      color: #a6a2a2;
    }

    .btn-add-usdpi {
      background: #01c2c5;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-add-prfti {
      background: #6d50d0;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;

export const ModalFinish = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    img {
      width: 750px;
      margin: -4rem;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .texts p:nth-child(1) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 24px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts p:nth-child(2) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 20px;
      color: #7d7777;
    }

    .texts div:nth-child(3) {
      border: 1px solid #4f4f4f;
      border-radius: 20px;
      font-weight: 300;
      font-size: 14px;
      align-items: center;
      text-align: center;
      color: #8767ed;
      padding: 23px;
    }

    .texts p:nth-child(4) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 20px;
      line-height: 118%;
      text-align: center;
      color: #a6a2a2;
    }

    .btn-add-usdpi {
      background: #01c2c5;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-add-prfti {
      background: #6d50d0;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;
