import React, { useCallback, useEffect, useState } from 'react';

import { differenceInDays, differenceInSeconds } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useAuth } from '~/hooks/Auth';
import { formatPrice } from '~/utils/format';
import ModalTokensClaimed from '~/components/ModalTokensClaimed';
import Loading from '~/components/Loading';

import { Container, ModalRewards } from './styles';
import logo from '~/assets/logo/logo-p-purple.svg';
import walletFinish from '~/assets/defaults/wallet-finish.png';

import updateIcon from '~/assets/icons/update.svg';
import api from '~/services/api';
import { web3store } from '../../../store';
import logoWhite from '~/assets/logo/logo-p-white.svg';
import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';
import swalError from '~/utils/swalError';

interface IRewards {
  filter: string;
  setCountdown: React.Dispatch<React.SetStateAction<number>>;
}

interface IDripResponse {
  id: number;
  amount: string;
  rate: string;
  available: string;
  claimed: string;
  maturity_date: number;
}

interface IDrip {
  id: number;
  member: number;
  rate: string;
  available: string | number;
  claimed: number;
  maturity_date: number;
  maturity: number;
}

interface ITransaction {
  contract_id: number;
  tokens: string;
  apy: number;
  available: string;
  claimed: string;
  maturity_date: number;
  maturity: number;
}

interface IProfitiResponse {
  pool: {
    available: string;
    claimed: string;
  };
}

interface IProfiti {
  id: number;
  available: string;
  claimed: string;
}

interface IPerformanceResponse {
  ppool: {
    available: string;
    claimed: string;
  };
}

interface IPerformance {
  id: number;
  available: string;
  claimed: string;
}

const Rewards: React.FC<IRewards> = ({ filter, setCountdown }) => {
  const { user } = useAuth();
  const history = useHistory();
  const [rewards, setRewards] = useState<IDrip[]>([]);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [profiti, setProfiti] = useState<IProfiti[]>([]);
  const [performance, setPerformance] = useState<IPerformance[]>([]);
  // Claim Revenue Share USDPI
  const [runClaimPoolUsdpi, setRunClaimPoolUsdpi] =
    web3store.useState('runClaimPoolUsdpi');
  const [withdrawalPoolUsdpiResult, setWithdrawalPoolUsdpiResult] =
    web3store.useState('withdrawalPoolUsdpiResult');
  // Claim Performance Pool USDPI
  const [runClaimPerfPoolUsdpi, setRunClaimPerfPoolUsdpi] = web3store.useState(
    'runClaimPerfPoolUsdpi'
  );
  const [withdrawalPerfPoolUsdpiResult, setWithdrawalPerfPoolUsdpiResult] =
    web3store.useState('withdrawalPerfPoolUsdpiResult');
  // Claim Performance Share USDPI
  const [runClaimDripPrfti, setRunClaimDripPrfti] =
    web3store.useState('runClaimDripPrfti');
  const [withdrawalDripResult, setWithdrawalDripResult] = web3store.useState(
    'withdrawalDripResult'
  );
  // Claim Performance Share USDPI
  const [runClaimStakingPrfti, setRunClaimStakingPrfti] = web3store.useState(
    'runClaimStakingPrfti'
  );
  const [withdrawalStakingResult, setWithdrawalStakingResult] =
    web3store.useState('withdrawalStakingResult');
  const [modalPerformanceRewards, setModalPerformanceRewards] = useState(false);
  const [modalProfitiRewards, setModalProfitiRewards] = useState(false);
  // Refresh balances flag
  const [refBalances, setRefBalances] = web3store.useState('refBalances');
  const explorerURL = process.env.REACT_APP_NETWORK_BLOCK_EXPLORER_URLS;
  const [showBefore, setShowBefore] = useState(false);
  const [showAfter, setShowAfter] = useState(false);
  const [claimType, setClaimType] = useState('');
  const [claimID, setClaimID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resultHash, setResultHash] = useState('');

  useEffect(() => {
    setRefBalances(true);
    if (filter === 'rewards' || filter === 'all') {
      api
        .get(`v1/member/drips/${user?.id}`)
        .then(async (responseTransactions) => {
          // console.log(responseTransactions.data);
          const data = responseTransactions.data.drips.drips.map(
            (drip: IDripResponse) => ({
              id: drip.id,
              member: formatPrice(
                parseFloat((parseInt(drip.amount, 10) / 10 ** 18).toFixed(2))
              ),
              rate: drip.rate,
              // available: drip.available,
              available: formatPrice(
                parseFloat((parseInt(drip.available, 10) / 10 ** 18).toFixed(2))
              ),
              claimed: formatPrice(
                parseFloat((parseInt(drip.claimed, 10) / 10 ** 18).toFixed(2))
              ),
              maturity_date: differenceInDays(
                new Date(drip.maturity_date * 1000),
                new Date()
              ),
              maturity: drip.maturity_date * 1000,
            })
          );

          if (data.length > 0) {
            const dripSelected = data.reduce(
              (acumulador: any, transaction: any) => {
                if (
                  transaction.maturity !== 0 &&
                  transaction.maturity < acumulador.maturity
                ) {
                  return transaction;
                }
                return acumulador;
              }
            );
            setCountdown((state) =>
              state === 0
                ? differenceInSeconds(dripSelected.maturity, new Date())
                : state
            );
            setRewards(data);
          }
        });
    }
    if (filter === 'staking' || filter === 'all') {
      api
        .get(`v1/staking/member/${user?.id}`)
        .then(async (responseTransactions) => {
          const data = responseTransactions.data.stakes.transactions.map(
            (transaction: ITransaction) => ({
              ...transaction,
              tokens: formatPrice(
                parseFloat(
                  (parseInt(transaction.tokens, 10) / 10 ** 18).toFixed(2)
                )
              ),
              claimed: formatPrice(
                parseFloat(
                  (parseInt(transaction.claimed, 10) / 10 ** 18).toFixed(2)
                )
              ),
              available: formatPrice(
                parseFloat(
                  (parseInt(transaction.available, 10) / 10 ** 18).toFixed(2)
                )
              ),
              maturity_date: differenceInDays(
                new Date(transaction.maturity_date * 1000),
                new Date()
              ),
              maturity: transaction.maturity_date * 1000,
            })
          );

          if (data.length > 0) {
            const stakingSelected = data.reduce(
              (acumulador: any, transaction: any) => {
                if (
                  transaction.maturity !== 0 &&
                  transaction.maturity < acumulador.maturity
                ) {
                  return transaction;
                }
                return acumulador;
              }
            );
            setCountdown((state) =>
              state === 0
                ? differenceInSeconds(stakingSelected.maturity, new Date())
                : state
            );
            setTransactions(data);
          }
        });
    }
    if (filter === 'profiti' || filter === 'all') {
      api
        .get<IProfitiResponse>(`v1/member/pool/${user?.id}`)
        .then(async (responseProfiti) => {
          setProfiti([
            {
              id: 1,
              // available: responseProfiti.data.pool.available,
              available: formatPrice(
                parseFloat(
                  (
                    parseInt(responseProfiti.data.pool.available, 10) /
                    10 ** 18
                  ).toFixed(2)
                )
              ),
              claimed: formatPrice(
                parseFloat(
                  (
                    parseInt(responseProfiti.data.pool.claimed, 10) /
                    10 ** 18
                  ).toFixed(2)
                )
              ),
            },
          ]);
        });
    }
    if (filter === 'performance' || filter === 'all') {
      api
        .get<IPerformanceResponse>(`v3/member/perfpool/${user?.id}`)
        .then(async (responsePerfpool) => {
          setPerformance([
            {
              id: 1,
              // available: responsePerfpool.data.ppool.available,
              available: formatPrice(
                parseFloat(
                  (
                    parseInt(responsePerfpool.data.ppool.available, 10) /
                    10 ** 18
                  ).toFixed(2)
                )
              ),
              claimed: formatPrice(
                parseFloat(
                  (
                    parseInt(responsePerfpool.data.ppool.claimed, 10) /
                    10 ** 18
                  ).toFixed(2)
                )
              ),
            },
          ]);
        });
    }
    setLoading(false);
    if (
      withdrawalPerfPoolUsdpiResult !== '' &&
      withdrawalPerfPoolUsdpiResult !== 'ERROR'
    ) {
      setModalPerformanceRewards(true);
    }
    if (
      withdrawalPoolUsdpiResult !== '' &&
      withdrawalPoolUsdpiResult !== 'ERROR'
    ) {
      setModalProfitiRewards(true);
    }
    if (withdrawalDripResult !== '' && withdrawalDripResult !== 'ERROR') {
      setResultHash(withdrawalDripResult);
      setShowAfter(true);
    }
    if (withdrawalStakingResult !== '' && withdrawalStakingResult !== 'ERROR') {
      setResultHash(withdrawalStakingResult);
      setShowAfter(true);
    }
    // console.log('withdrawalDripResult: %s', withdrawalDripResult);
    // console.log('withdrawalStakingResult: %s', withdrawalStakingResult);
  }, [
    filter,
    setCountdown,
    user,
    withdrawalDripResult,
    withdrawalStakingResult,
    withdrawalPerfPoolUsdpiResult,
    withdrawalPoolUsdpiResult,
  ]);

  const handleClickReloadAvailable = useCallback(
    async (item, type) => {
      console.log(item);
      if (type === 'staking') {
        const responseAvailable = await api.get(
          `v1/staking/available/${user?.id}/${item.contract_id}`
        );

        const newTransactions = transactions.slice();
        const transactionIndex = newTransactions.findIndex(
          (transaction) => transaction.contract_id === item.contract_id
        );

        if (transactionIndex >= 0) {
          newTransactions[transactionIndex].available = formatPrice(
            parseFloat(
              (
                parseInt(responseAvailable.data.available.rewards, 10) /
                10 ** 18
              ).toFixed(2)
            )
          );

          setTransactions(newTransactions);
        }
      } else if (type === 'rewards') {
        const responseAvailable = await api.get(
          `v1/member/drip/available/${user?.id}/${item.id}`
        );

        const newRewards = rewards.slice();
        const rewardsIndex = newRewards.findIndex(
          (reward) => reward.id === item.id
        );

        if (rewardsIndex >= 0) {
          newRewards[rewardsIndex].available = formatPrice(
            parseFloat(
              (
                parseInt(responseAvailable.data.available, 10) /
                10 ** 18
              ).toFixed(2)
            )
          );

          setRewards(newRewards);
        }

        console.log(responseAvailable);
      } else if (type === 'profiti') {
        console.log('reload pool rewards usdpi');
        api
          .get<IProfitiResponse>(`v1/member/pool/${user?.id}`)
          .then(async (responseProfiti) => {
            setProfiti([
              {
                id: 1,
                available: formatPrice(
                  parseFloat(
                    (
                      parseInt(responseProfiti.data.pool.available, 10) /
                      10 ** 18
                    ).toFixed(2)
                  )
                ),
                claimed: formatPrice(
                  parseFloat(
                    (
                      parseInt(responseProfiti.data.pool.claimed, 10) /
                      10 ** 18
                    ).toFixed(2)
                  )
                ),
              },
            ]);
          });
      } else if (type === 'performance') {
        console.log('reload performance pool rewards usdpi');
        api
          .get<IPerformanceResponse>(`v3/member/perfpool/${user?.id}`)
          .then(async (responsePerf) => {
            setPerformance([
              {
                id: 1,
                available: formatPrice(
                  parseFloat(
                    (
                      parseInt(responsePerf.data.ppool.available, 10) /
                      10 ** 18
                    ).toFixed(2)
                  )
                ),
                claimed: formatPrice(
                  parseFloat(
                    (
                      parseInt(responsePerf.data.ppool.claimed, 10) /
                      10 ** 18
                    ).toFixed(2)
                  )
                ),
              },
            ]);
          });
      }
    },
    [transactions, user?.id]
  );

  const handleClickMaturity = useCallback(
    (maturity) => {
      setCountdown(differenceInSeconds(maturity, new Date()));
    },
    [setCountdown]
  );

  const handleClickClaimPoolUsdpi = useCallback(() => {
    networkSwitch('NETWORK')
      .then((response) => {
        setLoading(true);
        setRunClaimPoolUsdpi(true);
      })
      .catch((error) => {
        swalError({
          message: 'There was a problem with your transaction.',
          textButton: 'Try Again',
        });
      });
  }, [setCountdown]);

  const handleClickClaimPerfPoolUsdpi_v1 = useCallback(() => {
    networkSwitch('NETWORK')
      .then((response) => {
        setLoading(true);
        setRunClaimPerfPoolUsdpi(true);
      })
      .catch((error) => {
        swalError({
          message: 'There was a problem with your transaction.',
          textButton: 'Try Again',
        });
      });
  }, [setCountdown]);

  const handleClickClaimPerfPoolUsdpi = useCallback(() => {
    api
      .get('/v1/net/fee/estimate')
      .then(function (response) {
        console.log(response);
        // processClaimPerfPool(response.data.estimate.fee);
        api
          .post('/v3/perfpool/claimrewards', {
            nftId: user?.id,
            fee: parseInt(response.data.estimate.fee, 10),
          })
          .then(function (claimresponse) {
            // setRunClaimPerfPoolUsdpi(false);
            setWithdrawalPerfPoolUsdpiResult(claimresponse.data.tx);
            setModalPerformanceRewards(true);
          })
          .catch(function (error) {
            console.log('================= ERROR ===================');
            console.log(error);
            // setRunClaimPerfPoolUsdpi(false);
            setWithdrawalPerfPoolUsdpiResult('ERROR');
          });
      })
      .catch(function (error) {
        console.log('================= ERROR ===================');
        console.log(error);
        setRunClaimPerfPoolUsdpi(false);
        setWithdrawalPerfPoolUsdpiResult('ERROR');
      });
  }, [setCountdown]);

  const handleClickClaimPoolPrfti = useCallback(
    (contract_id) => {
      setClaimType('pool');
      setClaimID(contract_id);
      setShowBefore(true);
    },
    [setCountdown]
  );

  const handleClickClaimDripPrfti = useCallback(
    (contract_id) => {
      setClaimType('drip');
      setClaimID(contract_id);
      setShowBefore(true);
    },
    [setCountdown]
  );

  const handleClose = useCallback(() => {
    setWithdrawalPerfPoolUsdpiResult('');
    setWithdrawalPoolUsdpiResult('');
    setModalPerformanceRewards(false);
    setModalProfitiRewards(false);
  }, [withdrawalPerfPoolUsdpiResult, withdrawalPoolUsdpiResult]);

  const handleCloseBefore = () => {
    console.log('++++++++++++++++++++++++++++++++++');
    console.log('handleCloseBefore');
    console.log('++++++++++++++++++++++++++++++++++');
    setShowBefore(false);
    if (claimType === 'pool') {
      setLoading(true);
      setRunClaimStakingPrfti(claimID);
    } else if (claimType === 'drip') {
      setLoading(true);
      setRunClaimDripPrfti(claimID);
    }
  };

  const handleCancelBefore = () => {
    setShowBefore(false);
  };

  const handleCloseAfter = () => {
    setResultHash('');
    setWithdrawalDripResult('');
    setWithdrawalStakingResult('');
    setShowAfter(false);
  };

  return (
    <Container>
      {(filter === 'rewards' || filter === 'all') && (
        <>
          {rewards.map((item) => (
            <div
              key={item.id}
              className="bg-box d-flex p-4 mb-3 justify-content-between align-items-center"
            >
              <div>
                <img src={logo} alt="Logo" />
              </div>
              <div>
                <h3 className="mb-1">Member Rewards</h3>
                <span>{item.member}</span>
              </div>
              <div>
                <h3 className="mb-1">Unlock Rate</h3>
                <span>{item.rate}</span>
              </div>
              <div>
                <div className="d-flex">
                  <h3 className="mb-1">Available</h3>
                  <button
                    type="button"
                    className="btn-reload border-0 d-flex align-items-center justify-content-center mx-1"
                    onClick={() => handleClickReloadAvailable(item, 'rewards')}
                  >
                    <img src={updateIcon} alt="update-icon" />
                  </button>
                </div>
                <span>{item.available}</span>
              </div>
              <div>
                <h3 className="mb-1">Claimed</h3>
                <span>{item.claimed}</span>
              </div>
              <div>
                <h3 className="mb-1">Maturity Date</h3>
                <button
                  type="button"
                  className="border-0 bg-transparent btn-mature"
                  onClick={() => handleClickMaturity(item.maturity)}
                >
                  {item.maturity_date} Days
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="claim px-3 py-2 border-0"
                  disabled={item.available === 0}
                  onClick={() => handleClickClaimDripPrfti(item.id)}
                >
                  Claim
                </button>
              </div>
            </div>
          ))}
        </>
      )}
      {(filter === 'staking' || filter === 'all') && (
        <>
          {transactions.map((item, index) => (
            <div
              key={index.toString()}
              className="bg-box d-flex p-4 mb-3 justify-content-between align-items-center"
            >
              <div>
                <img src={logo} alt="Logo" />
              </div>
              <div>
                <h3 className="mb-1">Staking</h3>
                <span>{item.tokens}</span>
              </div>
              <div>
                <h3 className="mb-1">APY</h3>
                <span>{item.apy}%</span>
              </div>
              <div>
                <div className="d-flex">
                  <h3 className="mb-1">Available</h3>
                  <button
                    type="button"
                    className="btn-reload border-0 d-flex align-items-center justify-content-center mx-1"
                    onClick={() => handleClickReloadAvailable(item, 'staking')}
                  >
                    <img src={updateIcon} alt="update-icon" />
                  </button>
                </div>
                <span>{item.available}</span>
              </div>
              <div>
                <h3 className="mb-1">Claimed</h3>
                <span>{item.claimed}</span>
              </div>
              <div>
                <h3 className="mb-1">Maturity Date</h3>
                <button
                  type="button"
                  className="border-0 bg-transparent btn-mature"
                  onClick={() => handleClickMaturity(item.maturity)}
                >
                  {item.maturity_date} Days
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="claim px-3 py-2 border-0"
                  disabled={parseFloat(item.available) === 0}
                  onClick={() => handleClickClaimPoolPrfti(item.contract_id)}
                >
                  Claim
                </button>
              </div>
            </div>
          ))}
        </>
      )}
      {(filter === 'profiti' || filter === 'all') && (
        <>
          {profiti.map((item) => (
            <div
              key={item.id}
              className="bg-box rewards d-flex p-4 mb-3 justify-content-between align-items-center"
            >
              <div>
                <img src={logo} alt="Logo" />
              </div>

              <div>
                <div className="d-flex">
                  <h3 className="mb-1">PROFITi Pool Rewards Available</h3>
                  <button
                    type="button"
                    className="btn-reload border-0 d-flex align-items-center justify-content-center mx-1"
                    onClick={() => handleClickReloadAvailable(item, 'profiti')}
                  >
                    <img src={updateIcon} alt="update-icon" />
                  </button>
                </div>
                <span>${item.available}</span>
              </div>
              <div>
                <h3 className="mb-1">Claimed</h3>
                <span>${item.claimed}</span>
              </div>

              <div>
                <button
                  type="button"
                  className="claim px-3 py-2 border-0"
                  disabled={parseFloat(item.available) === 0}
                  onClick={() => handleClickClaimPoolUsdpi()}
                >
                  Claim
                </button>
              </div>
            </div>
          ))}
        </>
      )}
      {(filter === 'performance' || filter === 'all') && (
        <>
          {performance.map((item) => (
            <div
              key={item.id}
              className="bg-box d-flex p-4 mb-3 performance-rewards justify-content-between align-items-center"
            >
              <div>
                <img src={logo} alt="Logo" />
              </div>

              <div>
                <div className="d-flex">
                  <h3 className="mb-1">Performance Pool Rewards</h3>
                  <button
                    type="button"
                    className="btn-reload border-0 d-flex align-items-center justify-content-center mx-1"
                    onClick={() =>
                      handleClickReloadAvailable(item, 'performance')
                    }
                  >
                    <img src={updateIcon} alt="update-icon" />
                  </button>
                </div>
                <span>${item.available}</span>
              </div>
              <div>
                <h3 className="mb-1">Claimed</h3>
                <span>${item.claimed}</span>
              </div>

              <div>
                <button
                  type="button"
                  className="claim px-3 py-2 border-0"
                  disabled={parseFloat(item.available) === 0}
                  // disabled
                  onClick={() => handleClickClaimPerfPoolUsdpi()}
                >
                  Claim
                </button>
              </div>
            </div>
          ))}
        </>
      )}

      <ModalRewards
        size="xl"
        show={modalPerformanceRewards}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <ModalRewards.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-5 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={walletFinish} alt="Wallet" />
          </div>
        </ModalRewards.Header>
        <ModalRewards.Body className="px-4 px-sm-5">
          <h2 className="mt-n5 mb-4 fw-bold text-center w-100">
            Rewards Claimed!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4 px-xl-2">
              Your Performance Pool shares have been successfully claimed and
              applied to your account.
            </p>
            <p className="text-center mb-4 px-xl-2">
              Please note: If you still have a balance due for your next month
              subscription the rewards will be allocated ot that wallet first.
            </p>
            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${explorerURL}/tx/${withdrawalPerfPoolUsdpiResult}`}
                target="_blank"
                rel="noreferrer"
              >
                {withdrawalPerfPoolUsdpiResult}
              </a>
            </div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn-confirm w-100 mt-0 mb-4"
            >
              <span className="">Done</span>
            </button>
          </div>
        </ModalRewards.Body>
        <ModalRewards.Footer className="border-0 py-4" />
      </ModalRewards>

      <ModalRewards
        size="xl"
        show={modalProfitiRewards}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <ModalRewards.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-5 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={walletFinish} alt="Wallet" />
          </div>
        </ModalRewards.Header>
        <ModalRewards.Body className="px-4 px-sm-5">
          <h2 className="mt-n5 mb-4 fw-bold text-center w-100">
            Rewards Claimed!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4 px-xl-4">
              Your PROFITi Revenue Share rewards have been successfully claimed
              and applied to your account.
            </p>
            <p className="text-center mb-4 px-xl-2">
              Please note: If you still have a balance due for your next month
              subscription the rewards will be allocated ot that wallet first.
            </p>
            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${explorerURL}/tx/${withdrawalPoolUsdpiResult}`}
                target="_blank"
                rel="noreferrer"
              >
                {withdrawalPoolUsdpiResult}
              </a>
            </div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn-confirm w-100 mt-0 mb-4"
            >
              <span className="">Done</span>
            </button>
          </div>
        </ModalRewards.Body>
        <ModalRewards.Footer className="border-0 py-4" />
      </ModalRewards>
      <ModalTokensClaimed
        showBefore={showBefore}
        handleCloseBefore={handleCloseBefore}
        handleCancelBefore={handleCancelBefore}
        showAfter={showAfter}
        handleCloseAfter={handleCloseAfter}
        resultHash={resultHash}
      />
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="CLAIMING TOKENS"
        className="zoom-1-3"
        active={loading}
      />
    </Container>
  );
};

export default Rewards;
