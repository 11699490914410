import styled, { css } from 'styled-components';

import dashboard from '~/assets/icons/dashboard-icon.svg';
import dashboardActive from '~/assets/icons/dashboard-icon-active.svg';
import profile from '~/assets/icons/profile-icon.svg';
import profileActive from '~/assets/icons/profile-icon-active.svg';
import products from '~/assets/icons/products-icon.svg';
import productsActive from '~/assets/icons/products-icon-active.svg';
import business from '~/assets/icons/business-icon.svg';
import businessActive from '~/assets/icons/business-icon-active.svg';
import trader from '~/assets/icons/trader-icon.svg';
import traderActive from '~/assets/icons/trader-icon-active.svg';
import news from '~/assets/icons/news-icon.svg';
import newsActive from '~/assets/icons/news-icon-active.svg';
import token from '~/assets/icons/token-icon.svg';
import tokenActive from '~/assets/icons/token-icon-active.svg';
import subscriptions from '~/assets/icons/subscriptions-icon.svg';
import subscriptionsActive from '~/assets/icons/subscriptions-icon-active.svg';
import smartTrade from '~/assets/icons/smart-trade-icon.svg';
import smartTradeActive from '~/assets/icons/smart-trade-icon-active.svg';
import aiArt from '~/assets/icons/ai-art-icon.svg';
import aiArtActive from '~/assets/icons/ai-art-icon-active.svg';
import launchpad from '~/assets/icons/launchpad-icon.svg';
import launchpadActive from '~/assets/icons/launchpad-icon-active.svg';
import chatbot from '~/assets/icons/chatbot-icon.svg';
import chatbotActive from '~/assets/icons/chatbot-icon-active.svg';
import arrow from '~/assets/icons/arrow-icon.svg';
import arrowActive from '~/assets/icons/arrow-icon-active.svg';
import referrers from '~/assets/icons/referrers-icon.svg';
import referrersActive from '~/assets/icons/referrers-icon-active.svg';
import matrix from '~/assets/icons/matrix-icon.svg';
import matrixActive from '~/assets/icons/matrix-icon-active.svg';
import logout from '~/assets/icons/log-out-icon.svg';
import logoutActive from '~/assets/icons/log-out-icon-active.svg';
import wallet from '~/assets/icons/wallet-icon.svg';
import walletActive from '~/assets/icons/wallet-icon-active.svg';
import links from '~/assets/icons/links-icon.svg';
import linksActive from '~/assets/icons/links-icon-active.svg';
import resources from '~/assets/icons/resources-icon.svg';
import resourcesActive from '~/assets/icons/resources-icon-active.svg';
import learning from '~/assets/icons/learning-icon.svg';
import learningActive from '~/assets/icons/learning-icon-active.svg';
import leaders from '~/assets/icons/leaders-icon.svg';
import leadersActive from '~/assets/icons/leaders-icon-active.svg';
import cube from '~/assets/icons/cube-icon.svg';
import cubeActive from '~/assets/icons/cube-icon-active.svg';
import trading from '~/assets/icons/trading-icon.svg';
import tradingActive from '~/assets/icons/trading-icon-active.svg';
import courses from '~/assets/icons/courses-icon.svg';
import coursesActive from '~/assets/icons/courses-icon-active.svg';
import exchanges from '~/assets/icons/exchanges-icon.svg';
import exchangesActive from '~/assets/icons/exchanges-icon-active.svg';

interface IMenuProps {
  active: boolean;
}

export const Container = styled.header`
  position: fixed;
  top: 0;
  z-index: 790;

  .width {
    width: 65px;
    height: 65px;
  }

  @media screen and (max-width: 1399px) {
    .width {
      width: 50px;
      height: 50px;
    }
  }

  @media screen and (min-width: 992px) {
    width: 160px !important;
  }

  @media screen and (max-width: 991px) {
    background: #1d2023;
    box-shadow: 0px 0px 10px 5px rgba(24, 25, 26, 0.7);
    height: 75px;
    width: 100% !important;

    .width {
      width: 50px;
      height: 50px;
    }
  }
`;

export const Menu = styled.div<IMenuProps>`
  > button {
    width: 30px;
    height: 30px;

    span {
      display: block;
      width: 100%;
      height: 3px;
      background-color: #dadada;
      border-radius: 30px;
      transition-duration: 0.3s;

      :nth-child(2) {
        width: 90%;
        margin: 6px 0 6px auto;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(45deg);
          }

          :nth-child(2) {
            opacity: 0;
          }

          :nth-child(3) {
            transform: rotate(-45deg);
            margin-top: -18px;
          }
        `}
    }
  }

  .nft-not-activate {
    filter: brightness(0.5);
    pointer-events: none;
  }

  .activate-nft {
    left: 0;
    top: 26rem;
    z-index: 1000;
    button {
      background: #ff1a50;
      border: 1px solid #2b2e31;
      border-radius: 12px;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      transition: all 0.3s ease;
      :hover {
        opacity: 0.9;
      }
    }
  }

  .bg {
    background-color: #1d2023;
  }

  .menu-group {
    height: calc(100vh - 100px);

    .menus {
      height: 100%;

      .menu {
        transition-duration: 0.3s;
        left: -500px;
        transform: translateX(0);
        z-index: 1;
        background: #1d2023;
      }

      .menu.fixed {
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }

      .menu.active {
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }
    }
  }

  .menu,
  .logoff {
    width: 135px;

    a,
    button {
      color: #7d7777;
      transition-duration: 0.3s;

      div,
      .div {
        width: 20px;
        height: 20px;
        transition-duration: 0.3s;
      }

      .dashboard {
        background-image: url(${dashboard});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .profile {
        background-image: url(${profile});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .products {
        background-image: url(${products});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .business {
        background-image: url(${business});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .trader {
        background-image: url(${trader});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .news {
        background-image: url(${news});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .token {
        background-image: url(${token});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .subscriptions {
        background-image: url(${subscriptions});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 24px;
      }

      .smart-trade {
        background-image: url(${smartTrade});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 24px;
      }

      .ai-art {
        background-image: url(${aiArt});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .launchpad {
        background-image: url(${launchpad});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .chatbot {
        background-image: url(${chatbot});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .cube {
        background-image: url(${cube});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .user-profile {
        background-image: url(${news});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .arrow {
        background-image: url(${arrow});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .referrers {
        background-image: url(${referrers});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .matrix {
        background-image: url(${matrix});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .wallet {
        background-image: url(${wallet});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .links {
        background-image: url(${links});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .resources {
        background-image: url(${resources});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .learning {
        background-image: url(${learning});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .leaders {
        background-image: url(${leaders});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .trading {
        background-image: url(${trading});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .courses {
        background-image: url(${courses});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .exchanges {
        background-image: url(${exchanges});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .logout {
        background-image: url(${logout});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    a:hover,
    button:hover,
    a.active,
    button.active {
      text-decoration: none;
      color: #dadada;

      .dashboard {
        background-image: url(${dashboardActive});
      }

      .profile {
        background-image: url(${profileActive});
      }

      .products {
        background-image: url(${productsActive});
      }

      .business {
        background-image: url(${businessActive});
      }

      .trader {
        background-image: url(${traderActive});
      }

      .news {
        background-image: url(${newsActive});
      }

      .token {
        background-image: url(${tokenActive});
      }

      .subscriptions {
        background-image: url(${subscriptionsActive});
      }

      .smart-trade {
        background-image: url(${smartTradeActive});
      }

      .ai-art {
        background-image: url(${aiArtActive});
      }

      .launchpad {
        background-image: url(${launchpadActive});
      }

      .chatbot {
        background-image: url(${chatbotActive});
      }

      .cube {
        background-image: url(${cubeActive});
      }

      .user-profile {
        background-image: url(${newsActive});
      }

      .arrow {
        background-image: url(${arrowActive});
      }

      .referrers {
        background-image: url(${referrersActive});
      }

      .matrix {
        background-image: url(${matrixActive});
      }

      .wallet {
        background-image: url(${walletActive});
      }

      .links {
        background-image: url(${linksActive});
      }

      .resources {
        background-image: url(${resourcesActive});
      }

      .learning {
        background-image: url(${learningActive});
      }

      .leaders {
        background-image: url(${leadersActive});
      }

      .trading {
        background-image: url(${tradingActive});
      }

      .courses {
        background-image: url(${coursesActive});
      }

      .exchanges {
        background-image: url(${exchangesActive});
      }

      .logout {
        background-image: url(${logoutActive});
      }
    }
  }

  @media screen and (max-width: 991px) {
    a {
      line-height: 3.5rem;
    }

    .activate-nft {
      top: 40rem;
    }

    .vh {
      height: 100vh;
    }

    .overflow {
      overflow: auto;
      overflow-x: hidden;
    }

    .shadow-menu {
      box-shadow: 0 1.1rem 1rem rgba(0, 0, 0, 0.8) !important;
    }

    .menu-group {
      width: 50%;
      height: calc(100vh - 60px);
      background: #1d2023;
      transition-duration: 0.3s;
      top: 75px;
      right: ${(props) => (props.active ? '0px' : '-1000px')};
      z-index: 900;

      .menus {
        height: 100%;

        .menu {
          transition-duration: 0.3s;
          right: -1500px;
          left: unset !important;
          transform: translateX(0);
          z-index: 1;
          background: #1d2023;
        }

        .menu.fixed {
          right: 50%;
          left: unset !important;
          transform: translateX(50%);
          z-index: 1;
        }

        .menu.active {
          right: 50%;
          left: unset !important;
          transform: translateX(50%);
          z-index: 2;
        }
      }
    }

    .menu,
    .logoff {
      width: 300px;

      a,
      button {
        div {
          margin: 10px;
        }

        p,
        span {
          text-align: left;
          opacity: 1;
          width: 110px;
          margin-bottom: 0;
          line-height: 5rem;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    a,
    button {
      font-size: 1.5rem;
    }

    .menu,
    .logoff {
      a,
      button {
        div,
        .div {
          width: 25px;
          height: 25px;
        }
        p,
        span {
          width: 153px;
        }
      }
    }
    .menu-group {
      width: 100%;
    }
    .menu-group {
      .menus {
        height: 372px;
      }
    }
  }

  @media screen and (max-height: 600px) {
    .mt-30px {
      margin-top: 30px;
    }
  }

  @media (max-width: 575px) and (max-height: 800px) {
    .activate-nft {
      top: 30rem;
    }
    .menu,
    .logoff {
      a,
      button {
        p,
        span {
          line-height: 3rem;
        }
      }
    }
  }
`;
