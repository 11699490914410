import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

import { lighten } from 'polished';
import background from '~/assets/banners/background.png';
import thumb from '~/assets/icons/thumb.svg';
import check from '~/assets/icons/check-icon.svg';

interface IRangePercentage {
  percentage: number;
}

export const Banner = styled.div`
  background-color: #18191a;
  min-height: 100vh;

  .transition {
    transition-duration: 0.3s;
  }

  h1 {
    background: linear-gradient(269.95deg, #fff 0.02%, #fff 97.45%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;

    > span {
      background: linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      );
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }
  }

  .btn-example {
    color: #f2f2f2;
    font-weight: 300;
    font-style: italic;
    font-size: 14px;
    :hover {
      text-decoration: underline;
    }
  }

  .gradient-parts span {
    color: #f2f2f2;
    font-weight: 300 !important;
  }

  .text-gray {
    color: #b7b7b7;
  }

  b {
    font-weight: bold;
  }

  .text-gradient {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  .video-container {
    > div {
      width: 80px;
      height: 50%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.096) 100%
      );
      opacity: 0.3;
      box-shadow: inset 0px 39px 52px -29px rgba(255, 255, 255, 0.5),
        inset 6px 1px 1px -4px #ffffff, inset -6px -7px 8px -4px #ffffff,
        inset 0px 7px 3px -4px #ffffff,
        inset 51px -73px 72px -60px rgba(255, 255, 255, 0.3),
        inset 0px 98px 100px -48px rgba(255, 255, 255, 0.3),
        inset 0px 4px 18px rgba(255, 255, 255, 0.3),
        inset 0px 1px 40px rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2px);
      border-radius: 30px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }

    div.left {
      left: -10px;
    }

    video {
      width: 100%;
      border-radius: 50px;
    }

    div.right {
      right: -10px;
    }
  }

  @media screen and (max-width: 1799px) {
    .zoom-resum {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .zoom-resum {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 524px) {
    h1 {
      > span {
        background: unset;
        background-clip: unset;
        -webkit-background-clip: unset;

        span {
          background: linear-gradient(
            239.23deg,
            #9df3ff 7.84%,
            #bcffe5 28.62%,
            #deefd1 38.94%,
            #ffe0be 49.96%,
            #fbc2d3 64.82%,
            #fabcd6 67.63%,
            #f281ff 91.88%
          );
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  @media screen and (max-width: 399px) {
    h1 {
      font-size: 9vw;
    }
  }
`;

export const Card = styled.div`
  .border-grad {
    cursor: pointer;
    border: none;
    padding: 3px;
    background-image: linear-gradient(180deg, #242526, #242526),
      linear-gradient(269.95deg, #e323ff 0.02%, #6a9aae 97.45%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 20px;
    transition-duration: 0.3s;
    color: #fff;

    span {
      padding: 5px 10px;
      display: block;
    }
  }

  .mg-bottom {
    margin-bottom: 38px;
  }

  button.border-grad {
    transition-duration: 0.3;
    height: 50px;

    :hover {
      opacity: 0.7;
    }
  }

  .color-p {
    color: #828282;
  }

  .card-price {
    background: #282828;
    border: 5px solid #4f4f4f;
    border-radius: 30px;

    h2 {
      font-weight: bold;
    }

    ul {
      list-style: none;

      li {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        :before {
          content: '';
          background: #f2f2f2;
          background-image: url(${check});
          background-position: center;
          background-size: 13px;
          background-repeat: no-repeat;
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: block;
        }

        small {
          color: #f2f2f2;
          width: 85%;
        }
      }
    }
  }

  .commom-price {
    .value {
      margin: 0 auto;

      span {
        left: 0;
        top: 20px;
        font-weight: bold !important;
        font-size: 1.6rem;
      }
      p {
        font-size: 8rem;
        font-weight: bold !important;
      }
    }
  }

  .best-price {
    padding: 5px;

    .value {
      span {
        left: 0;
        top: 20px;
        font-weight: bold !important;
        font-size: 2rem;
        background: linear-gradient(
          239.23deg,
          #9df3ff 7.84%,
          #bcffe5 28.62%,
          #deefd1 38.94%,
          #ffe0be 49.96%,
          #fbc2d3 64.82%,
          #fabcd6 67.63%,
          #f281ff 91.88%
        );
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
      }

      p {
        font-size: 100px;
        line-height: 90px;
        font-weight: bold !important;
        background: linear-gradient(
          239.23deg,
          #9df3ff 7.84%,
          #bcffe5 28.62%,
          #deefd1 38.94%,
          #ffe0be 49.96%,
          #fbc2d3 64.82%,
          #fabcd6 67.63%,
          #f281ff 91.88%
        );
        background-clip: text;
        -webkit-background-clip: text;
        color: #fff;
        -webkit-text-fill-color: transparent;
      }
    }
    .value-token {
      span {
        left: 0;
        top: 20px;
        font-weight: bold !important;
        font-size: 2rem;
        color: #e9e9e9;
      }
      input {
        background: transparent;
        font-size: 72px;
        font-weight: bold !important;
        color: #e9e9e9;
      }
    }
  }

  .slider-pic {
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    height: 2px;
    border-radius: 2px;
    background: #595757;
    outline: none;
    opacity: 1;
  }

  .slider-pic::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    border: 0;
    background: url(${thumb});
    background-position: center;
    background-size: cover;
    cursor: pointer;
  }

  .btn-gradient-light {
    border: 2px solid #ffffff;
  }

  .btn-gradient-light,
  .btn-gradient-gray {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border-radius: 20px;
    padding: 16px 20px;
    color: #000;
    font-size: 20px;
    font-weight: 700;
    transition: 0.3s;
  }

  .btn-gradient-light:hover {
    background: linear-gradient(
      239.23deg,
      #f281ff 7.84%,
      #fabcd6 28.62%,
      #fbc2d3 38.94%,
      #ffe0be 49.96%,
      #deefd1 64.82%,
      #bcffe5 67.63%,
      #9df3ff 91.88%
    );
  }

  .btn-gradient-gray {
    background: #bdbcbc !important;
    :hover {
      background-color: ${lighten(0.1, '#bdbcbc')}!important;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (min-width: 1400px) {
    .max-width {
      max-width: 315px;
    }

    .max-wid {
      max-width: 450px;
    }
  }

  @media screen and (max-width: 991px) {
    .commom-price {
      .value {
        width: 55%;

        span {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
  }
  @media screen and (max-width: 500px) {
    .best-price .value .input-text {
      font-size: 22vw;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  h2 {
    color: #dadada;
    font-size: 64px;
    font-family: 'Poppins', sans-serif;
  }

  h3 {
    font-weight: 400;
    font-size: 24px;
    color: #dadada;
  }

  h6 {
    font-weight: 400;
    font-size: 12px;
    color: #dadada;
  }

  .h-main-progress {
    height: 54px;
  }

  .progress {
    background: rgba(34, 34, 34, 30%);
    border: 1px solid rgba(122, 122, 122, 30%);
    border-radius: 30px;
    height: 50px;
  }

  .selector {
    position: absolute;
    width: 22px;
    height: 54px;
    left: calc(45% - 11px);
    top: -2px;
    background-color: #ffffff;
    border: 1px solid #ececec;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.08),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(200, 199, 199, 0.5);
    border-radius: 20px;
  }

  .slider-progress-bar {
    position: relative;
    z-index: 1;
    width: 100%;
    -webkit-appearance: none;
    background: rgba(34, 34, 34, 0%);

    border: 1px solid rgba(122, 122, 122, 30%);
    border-radius: 30px;
    height: 50px;
    outline: none;
    opacity: 1;
  }

  .slider-progress-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 54px;
    border: 0;
    background-color: #ffffff;
    border: 1px solid #ececec;

    cursor: pointer;
    border-radius: 20px;
  }

  .progress-bar {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border: 1px solid #f2f2f2;
    border-radius: 27.5px 0px 0px 27.5px;
  }

  .values-total-members {
    font-weight: 400;
    font-size: 14px;
    color: #6e6e6e;
  }

  .bg-value-revenue {
    background: #1d1c1c;
    height: 54px;
    border-radius: 40px;
    margin-top: 1.25rem;
  }

  .value-revenue {
    width: 100%;
    border: none;
    padding: 2px;
    font-size: 18px;
    color: #fff;
    height: 42px;
    font-weight: 700;
    filter: drop-shadow(0px 12px 10px rgba(0, 0, 0, 0.2));
    background-image: linear-gradient(180deg, #1d1c1c, #1d1c1c),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 20px;
    transition-duration: 0.3s;
  }

  .bg-number {
    background: rgba(34, 37, 39, 0.3);
    border: 1px solid #393c3d;
    border-radius: 20px;
  }

  .number {
    font-size: 64px;
    color: #dadada;
    border-bottom: 1px solid #393c3d;
  }

  .color {
    color: #5e5e5e;
  }

  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  .network {
    span:nth-child(2) {
      padding-left: 8%;
    }

    span:nth-child(3) {
      padding-left: 8%;
    }

    span:nth-child(4) {
      padding-left: 8%;
    }

    span:nth-child(5) {
      padding-left: 8%;
    }

    span:nth-child(6) {
      padding-left: 5%;
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }
  @media screen and (max-width: 1399px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 45px;
    }
  }

  @media screen and (max-width: 991px) {
    h6 {
      font-size: 18px;
    }

    .number {
      font-size: 53px;
    }

    .value-revenue {
      width: 97%;
      font-size: 26px;
      height: 55px;
      border-radius: 50px;
    }
  }

  @media screen and (min-width: 992px) {
  }
`;

export const Range = styled.div<IRangePercentage>`
  .progress-bar-centers {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border: 1px solid #f2f2f2;
    border-radius: 27.5px 0px 0px 27.5px;
    position: absolute;
    width: ${(props) => props.percentage}%;
    top: 0px;
    height: 50px;
  }
`;
