import styled from 'styled-components';
import { lighten, darken } from 'polished';

import background from '~/assets/banners/background.png';

export const Banner = styled.div`
  //background-image: url(${background});
  background-color: #18191a;
  background-position: center;
  background-size: cover;
  min-height: 100vh;

  h1 {
    background: linear-gradient(269.95deg, #fff 0.02%, #fff 97.45%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;

    > span {
      background: linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      );
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      font-weight: 900;
    }
  }

  p {
    color: #f4f4f4;
    font-weight: 300 !important;
  }

  .btn-claim button {
    background-color: #00f9ab;
    color: #000;
    border-radius: 20px;
    font-size: 24px;
    padding: 16px;
    transition-duration: 0.3s;

    :hover {
      background-color: ${darken(0.1, '#00f9ab')};
    }
  }

  .logo {
    right: 2rem;
    top: 2rem;
  }

  .text-gray {
    color: #b7b7b7;
  }

  .timeline-box {
    > div {
      height: 100%;
    }
  }

  .back-retangle-right {
    position: absolute;
    right: -27px;
    top: 20%;
    height: 18vw;
    max-height: 400px;
  }

  .back-retangle-left {
    position: absolute;
    left: -27px;
    top: 20%;
    height: 18vw;
    max-height: 400px;
  }

  @media screen and (max-width: 1799px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    button {
      font-size: 20px;
    }
    .back-retangle-right {
      height: 25vw;
      right: -1vw;
    }
    .back-retangle-left {
      height: 25vw;
      left: -1vw;
    }
  }

  @media screen and (min-width: 2160px) {
    .max-width {
      max-width: 1800px;
      margin: 0 auto;
    }
  }
`;

export const Timeline = styled.div`
  background: #000000;
  box-shadow: 8px 16px 40px rgba(0, 0, 0, 0.25);

  .sinalizer {
    > div:nth-child(1) {
      height: 100px;
      width: 1px;
      background: linear-gradient(180deg, #7d40ff 19.44%, #02a4ff 81.9%);
      margin: -10px auto;
      opacity: 0;
    }

    > div:nth-child(2) {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #333333;
      }
    }

    > div:nth-child(3) {
      height: 100px;
      width: 1px;
      background: linear-gradient(0deg, #7d40ff 19.44%, #02a4ff 81.9%);
      margin: -10px auto;
      opacity: 0;
    }
  }

  p {
    width: 130px;
    color: #404040;
    font-weight: 700 !important;
  }

  .active {
    .sinalizer {
      > div:nth-child(1),
      div:nth-child(3) {
        opacity: 1;
      }

      > div:nth-child(2) {
        width: 50px;
        height: 50px;

        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0.096) 100%
        );
        box-shadow: inset 0px 39px 52px -29px rgba(255, 255, 255, 0),
          inset 6px 1px 1px -4px rgba(255, 255, 255, 0.2),
          inset -6px -7px 8px -4px rgba(255, 255, 255, 0.2),
          inset 0px 7px 3px -4px rgba(255, 255, 255, 0.2),
          inset 51px -73px 72px -60px rgba(255, 255, 255, 0),
          inset 0px 98px 100px -48px rgba(255, 255, 255, 0),
          inset 0px 4px 18px rgba(255, 255, 255, 0),
          inset 0px 1px 40px rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(2px);
        border-radius: 50%;

        div {
          background: linear-gradient(269.95deg, #e323ff 0.02%, #79d1f8 97.45%);
        }
      }
    }

    p {
      background: linear-gradient(269.95deg, #e323ff 0.02%, #79d1f8 97.45%);
      background: linear-gradient(269.95deg, #e323ff 0.02%, #79d1f8 97.45%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }
  }
`;
