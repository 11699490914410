import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';
import { XyzTransition } from '@animxyz/react';
import { useParams, useLocation, useHistory, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Lottie from 'react-lottie';
import { HiMenuAlt4 } from 'react-icons/hi';
import Swal from 'sweetalert2';

import { web3store } from '../../store';
import { useResize } from '~/hooks/Resize';

import Loading from '~/components/Loading';
import {
  Banner,
  Circle,
  TextRide,
  PeopleAttention,
  ToogleTop,
  LoadingHome,
  Video,
  Modal,
} from './styles';

import graph from '~/assets/defaults/graph-token.svg';
import graphMobile from '~/assets/defaults/graph-token-mobi.svg';
import noReferrer from '~/assets/animations/no-referrer.json';
import logoPurple from '~/assets/logo/logo-purple.svg';
import graphSup from '~/assets/defaults/token-mobi.svg';
import timeLine from '~/assets/defaults/time-line.svg';

import WalletLink from '~/components/WalletLink';

import telegram from '~/assets/defaults/telegram.svg';
import discord from '~/assets/defaults/discord.svg';
import twitter from '~/assets/defaults/twitter.svg';
import tikTok from '~/assets/defaults/tik-tok.svg';
import facebook from '~/assets/defaults/facebook.svg';
import instagram from '~/assets/defaults/instagram.svg';
import youtube from '~/assets/defaults/youtube.svg';
import reddit from '~/assets/defaults/reddit.svg';
import peopleLearning from '~/assets/defaults/people-learning.png';

interface IParams {
  reference: string;
  place: string;
  aaUser: string;
}

const cookies = new Cookies();

const Home: React.FC = () => {
  const params = useParams<IParams>();
  const location = useLocation();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [navButton, setNavButton] = useState(11);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { width } = useResize();
  const [question, setQuestion] = useState(0);
  const [index, setIndex] = useState(0);
  const [account, setAccount] = web3store.useState('account');
  const [invalidRef] = web3store.useState('invalidRef');
  const [reference, setReference] = web3store.useState('reference');
  const [progressVideo, setProgressVideo] = useState(0);
  const [upOrDown, setUpOrDown] = useState('down');
  const currentVideo: string = progressVideo.toFixed(1);

  useEffect(() => {
    // console.log('+++++++++++++++++++++++++++++++++++++++++++++++++');
    // console.log('+++++++++++++++++++++++++++++++++++++++++++++++++');
    // console.log('invalidRef: %s', invalidRef);
    setShow(invalidRef);
    // console.log(invalidRef, 'ref');
  }, [invalidRef]);

  useEffect(() => {
    const timeVideo = parseFloat(currentVideo);
    if (timeVideo >= 7.9 && timeVideo <= 8.2) {
      setIndex(0);
    } else if (
      (timeVideo >= 2.9 && timeVideo <= 4.2) ||
      (timeVideo >= 16.9 && timeVideo <= 17.2)
    ) {
      setIndex(1);
    } else if (
      (timeVideo >= 11.6 && timeVideo <= 13.1) ||
      (timeVideo >= 26.2 && timeVideo <= 26.7)
    ) {
      setIndex(2);
    } else if (
      (timeVideo >= 19.7 && timeVideo <= 22.4) ||
      (timeVideo >= 33.4 && timeVideo <= 33.7)
    ) {
      // console.log('D');
      setIndex(3);
    } else if (
      (timeVideo >= 29.6 && timeVideo <= 30.2) ||
      (timeVideo >= 42 && timeVideo <= 42.4)
    ) {
      // console.log('E');
      setIndex(4);
    }
  }, [currentVideo]);

  const handleLoop = useCallback(() => {
    if (videoRef.current) {
      const timeVideo = parseFloat(currentVideo);
      // console.log(timeVideo);
      // navButton 11 scroll loop
      if (timeVideo > 8 && timeVideo < 8.5) {
        videoRef.current.currentTime = 0;
        setTimeout(() => {
          if (videoRef.current) {
            videoRef.current.pause();
          }
        }, 10);
      }
      // navButton 57 scroll loop
      else if (
        (timeVideo > 2.8 && timeVideo < 5.7) ||
        (timeVideo >= 17.1 && timeVideo < 17.5)
      ) {
        videoRef.current.play();
        videoRef.current.currentTime = 2.8;

        // console.log(timeVideo, 'navButton 57');
      }
      // navButton 103 scroll loop
      else if (
        (timeVideo >= 13 && timeVideo < 14) ||
        (timeVideo >= 26.7 && timeVideo <= 27.2)
      ) {
        videoRef.current.currentTime = 12;
        // console.log(timeVideo, 'navButton 103');
      }

      // navButton 149 scroll loop
      else if (
        (timeVideo > 22.3 && timeVideo < 22.9) ||
        (timeVideo >= 33.5 && timeVideo <= 34)
      ) {
        videoRef.current.currentTime = 20.3;
        // console.log(timeVideo, 'navButton 149');
      }

      // navButton 195 scroll loop
      else if (
        (timeVideo >= 30.5 && timeVideo < 31) ||
        (timeVideo >= 42 && timeVideo <= 42.4)
      ) {
        videoRef.current.currentTime = 30;
        // console.log(timeVideo, 'navButton 195');
      }

      // navButton 241 scroll loop
      else if (
        (timeVideo > 38.3 && timeVideo < 39) ||
        (timeVideo > 49.7 && timeVideo <= 50.2)
      ) {
        videoRef.current.currentTime = 37.5;
        setIndex(5);
        // console.log(timeVideo, 'navButton 241');
      }

      // navButton 287 scroll loop
      else if (
        (timeVideo > 46.1 && timeVideo <= 46.7) ||
        (timeVideo >= 58 && timeVideo < 59.5)
      ) {
        videoRef.current.currentTime = 46.1;
        setIndex(6);

        // console.log(timeVideo, 'navButton 287');
        console.log(upOrDown);
      }

      // navButton 333 scroll loop
      else if (
        (timeVideo >= 55.5 && timeVideo <= 56) ||
        (timeVideo > 67.5 && timeVideo < 68)
      ) {
        videoRef.current.currentTime = 54;
        setIndex(7);

        // console.log(timeVideo, 'navButton 333');
        // console.log(upOrDown);
      }

      // navButton 379 scroll loop
      else if (
        (timeVideo > 63.5 && timeVideo < 64) ||
        (timeVideo > 81 && timeVideo <= 81.5)
      ) {
        videoRef.current.currentTime = 62.8;
        setIndex(8);

        // console.log(timeVideo, 'navButton 379');
      }

      // navButton 425 scroll loop
      else if (timeVideo > 74.5 && timeVideo < 76) {
        videoRef.current.currentTime = 72.5;
        setIndex(9);

        // console.log(timeVideo, 'navButton 425');
      }
    }
  }, [currentVideo, upOrDown]);

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (upOrDown) {
        if (upOrDown === 'down' && navButton === 11 && progressVideo === 0) {
          videoRef.current.pause();
        } else if (
          upOrDown === 'up' &&
          navButton === 11 &&
          (progressVideo >= 2.8 || progressVideo <= 5.7)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 5.8;
        } else if (
          upOrDown === 'down' &&
          navButton === 57 &&
          (progressVideo === 0 || progressVideo > 4)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 0;
        } else if (
          upOrDown === 'up' &&
          navButton === 57 &&
          (progressVideo < 14 || progressVideo > 17.5)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 14;
        } else if (
          upOrDown === 'down' &&
          navButton === 103 &&
          (progressVideo < 9 || progressVideo > 13.9)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 9;
        } else if (
          upOrDown === 'up' &&
          navButton === 103 &&
          (progressVideo > 20.2 || progressVideo < 27)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 23;
        } else if (
          upOrDown === 'down' &&
          navButton === 149 &&
          (progressVideo < 17.6 || progressVideo > 22.9)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 17.6;
        } else if (
          upOrDown === 'up' &&
          navButton === 149 &&
          (progressVideo < 31 || progressVideo > 34)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 31;
        } else if (
          upOrDown === 'down' &&
          navButton === 195 &&
          (progressVideo < 27.5 || progressVideo > 31)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 27.5;
        } else if (
          upOrDown === 'up' &&
          navButton === 195 &&
          (progressVideo > 38 || progressVideo < 42)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 39;
        } else if (
          upOrDown === 'down' &&
          navButton === 241 &&
          (progressVideo < 34.1 || progressVideo > 39)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 34.1;
        } else if (
          upOrDown === 'up' &&
          navButton === 241 &&
          (progressVideo < 47.2 || progressVideo > 50)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 47;
        } else if (
          upOrDown === 'down' &&
          navButton === 287 &&
          (progressVideo < 42.5 || progressVideo > 46.7)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 42.5;
        } else if (
          upOrDown === 'up' &&
          navButton === 287 &&
          (progressVideo < 55 || progressVideo > 59.5)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 55;
        } else if (
          upOrDown === 'down' &&
          navButton === 333 &&
          (progressVideo < 50.5 || progressVideo > 54.7)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 50.5;
        } else if (
          upOrDown === 'up' &&
          navButton === 333 &&
          (progressVideo < 64 || progressVideo > 68)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 64;
        } else if (
          upOrDown === 'down' &&
          navButton === 379 &&
          (progressVideo < 59 || progressVideo > 64)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 60;
        } else if (
          upOrDown === 'up' &&
          navButton === 379 &&
          (progressVideo < 76 || progressVideo > 81)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 76;
        } else if (
          navButton === 425 &&
          (progressVideo < 68 || progressVideo > 76)
        ) {
          videoRef.current.play();
          videoRef.current.currentTime = 68;
        }

        setUpOrDown('');
      }
      handleLoop();
    }
  }, [handleLoop, navButton, progressVideo, upOrDown]);

  const handleNavButton = useCallback((e) => {
    setNavButton(e);
  }, []);

  const handleUpOrDown = useCallback((e) => {
    setUpOrDown(e);
  }, []);

  const handleQuestion = useCallback((num) => {
    setQuestion(num);
  }, []);

  const handleShow = useCallback(() => {
    if (invalidRef) {
      setShow(true);
    }
  }, [invalidRef]);

  return (
    <>
      <ReactScrollWheelHandler
        upHandler={() => {
          if (width > 991) {
            if (navButton > 11) {
              handleNavButton(navButton - 46);
            }
            handleUpOrDown('up');
          }
        }}
        downHandler={() => {
          if (width > 991) {
            if (navButton < 425) {
              handleNavButton(navButton + 46);
            }
            handleUpOrDown('down');
          }
        }}
      >
        <Banner>
          {/* <PeopleAttention className="d-flex align-items-center position-absolute learning p-2">
            <img src={peopleLearning} alt="People Learning" />
            <div className="mx-4">112</div>
            <div className="me-5">
              <h2 className="mt-2 mb-1">People Learning</h2>
              <span>
                about PROFITi right <br />
                now!
              </span>
            </div>
          </PeopleAttention> */}
          <Video
            autoPlay
            loop
            muted
            playsInline
            onTimeUpdate={() => {
              if (videoRef.current !== null) {
                setProgressVideo(videoRef.current.currentTime);
              }
            }}
            ref={videoRef}
            className="bg_video"
          >
            <source
              // src="https://wiserr.s3.amazonaws.com/p-project/FINAL+HD.mp4"
              src="https://prfti.s3.amazonaws.com/FINAL+HD.mp4"
              type="video/mp4"
            />
          </Video>

          <div className="nav">
            <button
              type="button"
              className="ancor"
              onClick={() => {
                handleNavButton(11);
                handleUpOrDown('up');
              }}
            >
              <p className="text-black nav-text">Home</p>
              <div className="circle">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="black" />
                </svg>
              </div>
            </button>

            <button
              type="button"
              className="ancor"
              onClick={() => {
                handleNavButton(57);
                handleUpOrDown('down');
              }}
            >
              <p className="text-black nav-text">What is it</p>
              <div className="circle">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="black" />
                </svg>
              </div>
            </button>
            <button
              type="button"
              className="ancor"
              onClick={() => {
                handleNavButton(103);
                handleUpOrDown('down');
              }}
            >
              <p className="text-black nav-text">Why join</p>
              <div className="circle">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="black" />
                </svg>
              </div>
            </button>
            <button
              type="button"
              className="ancor"
              onClick={() => {
                handleNavButton(149);
                handleUpOrDown('down');
              }}
            >
              <p className="text-black nav-text ps-3 ps-lg-0">Our products</p>
              <div className="circle">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="black" />
                </svg>
              </div>
            </button>
            <button
              type="button"
              className="ancor"
              onClick={() => {
                handleNavButton(195);
                handleUpOrDown('down');
              }}
            >
              <p className="text-black nav-text">Earn with us</p>
              <div className="circle">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="black" />
                </svg>
              </div>
            </button>
            <button
              type="button"
              className="ancor"
              onClick={() => {
                handleNavButton(241);
                handleUpOrDown('down');
              }}
            >
              <p className="text-black nav-text">Get started</p>
              <div className="circle">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="black" />
                </svg>
              </div>
            </button>
            <button
              type="button"
              className="ancor"
              onClick={() => {
                handleNavButton(287);
                handleUpOrDown('down');
              }}
            >
              <p className="text-black nav-text">Tokenomics</p>
              <div className="circle">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="black" />
                </svg>
              </div>
            </button>
            <button
              type="button"
              className="ancor"
              onClick={() => {
                handleNavButton(333);
                handleUpOrDown('down');
              }}
            >
              <p className="text-black nav-text">Roadmap</p>
              <div className="circle">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="black" />
                </svg>
              </div>
            </button>
            <button
              type="button"
              className="ancor"
              onClick={() => {
                handleNavButton(379);
                handleUpOrDown('down');
              }}
            >
              <p className="text-black nav-text">Join now</p>
              <div className="circle">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="black" />
                </svg>
              </div>
            </button>
            <button
              type="button"
              className="ancor"
              onClick={() => {
                handleNavButton(425);
                handleUpOrDown('down');
              }}
            >
              <p className="text-black nav-text">FAQ</p>
              <div className="circle">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="black" />
                </svg>
              </div>
            </button>

            <Circle top={navButton} className="current">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="11" stroke="black" />
              </svg>
            </Circle>
          </div>

          <TextRide className="">
            <XyzTransition xyz="fade up flip-down-25%">
              {navButton === 11 && index === 0 && (
                <div className="nav-11 w-total w-xl-50 ms-4 mx-sm-auto vh-100 d-flex flex-column">
                  <h1 className="text-black">
                    Explore the Frontier of Technology with PROFITi:
                    <br />
                    <span className="">Your Gateway to the Future</span>
                  </h1>
                  <div className="d-sm-flex mt-sm-4 mb-4">
                    <div className="w-sm-15 w-100">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-85 w-lg-65 ms-lg-4 text-black">
                      Welcome to PROFITi, where cutting-edge technology and
                      automated systems are not just concepts but realities
                      transforming how you interact with the digital world.{' '}
                      <b>
                        Here at PROFITi, we believe in financial empowerment
                        through technological innovation, making advanced tech
                        accessible to everyone
                      </b>
                      , regardless of their prior experience.
                    </h5>
                  </div>
                  <div>
                    {account === '' && (
                      <WalletLink
                        btnText="GET STARTED NOW"
                        className="text-black rounded-pill border-0 mt-2 mt-xxl-5 py-3 px-5 fw-bold"
                        onShowNoReferrer={handleShow}
                      />
                    )}
                  </div>
                </div>
              )}
            </XyzTransition>

            <XyzTransition xyz="fade down">
              {navButton === 57 && index === 1 && (
                <div className="nav-57 w-total w-xl-50 ms-4 mx-sm-auto vh-100 d-flex flex-column">
                  <h1 className="text-black">
                    PROFITi Is An Exclusive, Invite-Only and
                    NFT&nbsp;-&nbsp;Based
                    <br />
                    <span className="">Decentralized Community</span>
                  </h1>
                  <div className="d-sm-flex mt-sm-4 mb-4">
                    <div className="w-sm-15 w-100">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-85 w-lg-65 ms-lg-4 text-black">
                      At PROFITi, we foster a unique and exclusive community,
                      accessible through invitation,{' '}
                      <b>
                        where like-minded technology enthusiasts can connect and
                        thrive.
                      </b>{' '}
                      Our platform empowers members to leverage the power of
                      disruptive technologies, including{' '}
                      <b>AI, blockchain, smart contracts, and gaming</b>. By
                      joining PROFITi, you become part of a forward-thinking
                      group dedicated to exploring and shaping the future of
                      these innovative technologies.
                    </h5>
                  </div>
                </div>
              )}
            </XyzTransition>

            <XyzTransition xyz="fade in-down out-big">
              {navButton === 103 && index === 2 && (
                <>
                  <div className="nav-103 w-total w-xl-50 ms-4 mx-sm-auto">
                    <div className="title">
                      <h1 className="text-black">
                        PROFITi Is Crafting a
                        <br />
                        <span className="">
                          Secure, Transparent & Revolutionary
                        </span>
                        <br />
                        Tech Ecosystem!
                      </h1>
                      <div className="d-sm-flex mt-sm-4 mb-4">
                        <div className="w-sm-15 w-100">
                          <hr className="w-50 w-sm-75" />
                        </div>
                        <h5 className="w-sm-85 w-lg-65 ms-lg-4 text-black">
                          Our commitment to reliability and innovation has led
                          us to create a tech ecosystem that’s both secure and a
                          testament to the power of smart technology. We offer
                          an immersive experience that transcends traditional
                          boundaries.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="w-all-buttons mx-md-auto">
                    <div className="d-sm-flex z-buttons justify-content-around align-items-center vh-sm-100 position-relative">
                      <button
                        type="button"
                        className="position-relative button btn-hover w-button text-start text-black border-0"
                      >
                        <span className="mg-top d-block mb-2 mb-xxl-3">01</span>

                        <span className="d-block fw-bold mb-2 mb-xxl-3">
                          Tech-Driven Products:
                        </span>

                        <span className="d-block">
                          Our cutting-edge products blend the latest tech trends
                          with smart, user-friendly design.
                        </span>
                      </button>
                      <button
                        type="button"
                        className="position-relative button btn-hover w-button text-start text-black border-0"
                      >
                        <span className="mg-top d-block mb-2 mb-xxl-3">02</span>

                        <span className="d-block fw-bold mb-2 mb-xxl-3">
                          Innovative Affiliate Program:
                        </span>

                        <span className="d-block">
                          oin our blockchain-powered affiliate program for
                          transparent and rewarding opportunities. [Note:
                          Commissions earned on referrals.]
                        </span>
                      </button>
                      <button
                        type="button"
                        className="position-relative button btn-hover w-button text-start text-black border-0 mt-3 mt-sm-0"
                      >
                        <span className="mg-top d-block mb-2 mb-xxl-3">03</span>

                        <span className="d-block fw-bold mb-2 mb-xxl-3">
                          Core Decentralization:{' '}
                        </span>

                        <span className="d-block">
                          Discover the power of decentralization with our
                          blockchain and NFT-based platform.{' '}
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </XyzTransition>

            <XyzTransition xyz="fade down flip-up-25%">
              {navButton === 149 && index === 3 && (
                <div className="nav-149 w-total w-xl-50 ms-4 mx-sm-auto vh-100 d-flex flex-column">
                  <h1 className="text-black">
                    Our Innovative Platform Can Help You
                    <br />
                    <span className="">Leverage the Power of Web 3.0</span>
                  </h1>
                  <div className="d-sm-flex mt-sm-4 mb-4">
                    <div className="w-sm-15 w-100">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-85 w-lg-65 ms-lg-4 text-black">
                      With PROFITi, step into a new digital identity. Our
                      automated systems{' '}
                      <b>
                        guide you through the complexities of the digital age
                      </b>
                      , empowering you to harness the potential of Web 3.0.
                    </h5>
                  </div>
                </div>
              )}
            </XyzTransition>

            <XyzTransition xyz="fade up flip-up-25%">
              {navButton === 195 && index === 4 && (
                <div className="nav-195 w-total w-xl-50 ms-4 mx-sm-auto vh-100 d-flex flex-column">
                  <h1 className="text-black mb-4">
                    Diverse Earning
                    <br />
                    <span className="">Opportunities with PROFITi</span>
                  </h1>
                  <h3 className="mb-0 mb-sm-2">
                    PROFITi is crafted to <b>prioritize security</b> and{' '}
                    <b>transparency</b>, offering a platform where users can
                    engage with blockchain and DeFi technologies.
                  </h3>
                  <div className="d-sm-flex mt-sm-4 mb-sm-4">
                    <div className="w-sm-15 w-100">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-85 ms-lg-4 text-black mb-0 mb-sm-2">
                      <b>Embrace the Power of Blockchain & DeFi:</b> PROFITi
                      offers a secure and transparent platform designed to
                      explore the potential of blockchain and DeFi technologie
                    </h5>
                  </div>
                  <div className="d-sm-flex mt-sm-4 mb-4">
                    <div className="w-sm-15 w-100">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-85 ms-lg-4 text-black mb-0 mb-sm-2">
                      <b>Rewarding Affiliate Program:</b> Join our affiliate
                      program and have the opportunity to earn by sharing your
                      experience with others. [Note: Earnings vary based on
                      individual effort and are not guaranteed.]
                    </h5>
                  </div>
                </div>
              )}
            </XyzTransition>

            <XyzTransition xyz="fade up">
              {navButton === 241 && index === 5 && (
                <>
                  <div className="vh-100 d-flex flex-column justify-content-between">
                    <div className="nav-241 w-total w-xl-50 ms-4 mx-sm-auto vh-lg-100 d-flex flex-column">
                      <h1 className="text-black fw-bold mb-sm-4 mb-xxl-5">
                        Get Started Today!
                      </h1>
                      <h3 className="mb-0">
                        Join our vibrant community and{' '}
                        <b>
                          unlock exclusive access to all our innovative products
                        </b>{' '}
                        at a remarkably affordable subscription. To enhance your
                        experience, we're offering an <b>exceptional bonus:</b>{' '}
                        up to 200% PRFTI tokenback with your membership,{' '}
                        <b>making it an irresistible offer!</b>
                      </h3>
                    </div>
                    <div>
                      <div className="d-flex justify-content-center products">
                        <Link
                          to={`${process.env.PUBLIC_URL}/roadmap`}
                          target="_blank"
                          className="h4 text-black rounded-pill border-0 py-3 px-5 fw-bold"
                        >
                          View Our Product Roadmap
                        </Link>
                      </div>

                      <div className="d-flex pb-2 w-boxes">
                        <div className="w-lg-20">
                          <div className="bg-box mx-1 ms-2 d-flex flex-column justify-content-center">
                            <span>01</span>
                            <h4 className="text-black my-2 my-xxl-3">
                              AI Art Creator
                            </h4>
                            <p className="h6 small mb-0 mb-xxl-3">
                              Unleash your creativity and craft stunning
                              artworks using your words with our AI-driven tool.
                            </p>
                          </div>
                        </div>
                        <div className="w-lg-20">
                          <div className="bg-box mx-1 d-flex flex-column justify-content-center">
                            <span>02</span>
                            <h4 className="text-black my-2 my-xxl-3">
                              NFT Launpad
                            </h4>
                            <p className="h6 small mb-0 mb-xxl-3">
                              Transform your AI-created art into rare and unique
                              NFTs with ease, tapping into the world of digital
                              collectibles.
                            </p>
                          </div>
                        </div>
                        <div className="w-lg-20">
                          <div className="bg-box mx-1 d-flex flex-column justify-content-center">
                            <span>03</span>
                            <h4 className="text-black my-2 my-xxl-3">
                              Metaverse
                            </h4>
                            <p className="h6 small mb-0 mb-xxl-3">
                              Hang out, learn or play games on our private
                              metaverse community
                            </p>
                          </div>
                        </div>
                        <div className="w-lg-20">
                          <div className="bg-box mx-1 d-flex flex-column justify-content-center">
                            <span>04</span>
                            <h4 className="text-black my-2 my-xxl-3">Games</h4>
                            <p className="h6 small mb-0 mb-xxl-3">
                              Discover a variety of games, including
                              play-to-earn and ticketed events with reward
                              opportunities.{' '}
                              <span className="small fst-italic">
                                [Note: Game outcomes vary; no guaranteed
                                success.]
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="w-lg-20">
                          <div className="bg-box mx-1 me-5 me-lg-2 d-flex flex-column justify-content-center">
                            <span>05</span>
                            <h4 className="text-black my-2 my-xxl-3">
                              DeFi Trading
                            </h4>
                            <p className="h6 small mb-0 mb-xxl-3">
                              Utilize our AI-powered bots for informed trading
                              and arbitrage in the DeFi space.{' '}
                              <span className="small fst-italic">
                                [Note: Trading involves risks and the
                                performance of bots may vary.]
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </XyzTransition>

            <XyzTransition xyz="fade down flip-up-25%">
              {navButton === 287 && index === 6 && (
                <div className="nav-287 vh-100 position-relative">
                  <div className="w-total w-xl-50 ms-4 mx-sm-auto">
                    <h1 className="text-black position-absolute">
                      Token <span className="fw-bold">Allocation</span>
                    </h1>
                  </div>
                  <div className="d-md-none graph-width ms-4 mx-sm-auto mt-5 pt-5">
                    <img src={graphSup} alt="Graph" className="w-100" />
                  </div>
                  <div className="d-md-none graph-width-mobile mx-sm-auto mt-5">
                    <img src={graphMobile} alt="Graph" className="w-100" />
                  </div>
                  <div className="d-none d-md-block graph-width mx-auto">
                    <img src={graph} alt="Graph" className="w-100" />
                  </div>
                </div>
              )}
            </XyzTransition>

            <XyzTransition xyz="fade up">
              {navButton === 333 && index === 7 && (
                <div className="nav-333 vh-100">
                  <div className="w-total w-50 ms-4 mx-sm-auto">
                    <h1 className="text-black">
                      Our <span className="fw-bold">Roadmap</span>
                    </h1>
                  </div>
                  <div className="w-100 w-lg-75 mx-auto line-padding rotate-mobile">
                    <div className="width-mobile position-relative">
                      <img src={timeLine} alt="Time Line" className="w-100" />
                      <div className="bg-circle-1 position-absolute">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="8" fill="white" />
                        </svg>
                        <div className="box-date-bottom">
                          <div className="triangule-bottom" />
                          <h3 className="p-3 p-lg-2 p-xxl-3 mb-0 text-center">
                            May,&nbsp;2022
                          </h3>
                          <h4 className="p-3 p-lg-2 p-xxl-3 mb-0 text-center">
                            Official Project Kickoff
                          </h4>
                        </div>
                      </div>
                      <div className="bg-circle-2 position-absolute">
                        <div className="box-date-top">
                          <h4 className="p-3 p-lg-2 p-xxl-3 mb-0 text-center">
                            Project Beta Launch
                          </h4>

                          <h3 className="p-3 p-lg-2 p-xxl-3 mb-0 text-center">
                            Feb,&nbsp;2023
                          </h3>

                          <div className="triangule-top" />
                        </div>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="8" fill="white" />
                        </svg>
                      </div>
                      <div className="bg-circle-not-active left-percentage-1 position-absolute">
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="4" cy="4" r="4" fill="#312E32" />
                        </svg>
                        <div className="box-date-not-active bottom-not-active">
                          <h3 className="p-3 p-lg-2 p-xxl-3 mb-0 text-center">
                            Mar,&nbsp;2023
                          </h3>
                          <h4 className="p-lg-2 p-xxl-3 mb-0 text-center">
                            The Cube Game Launch
                          </h4>
                        </div>
                      </div>
                      <div className="bg-circle-not-active left-percentage-2 position-absolute">
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="4" cy="4" r="4" fill="#312E32" />
                        </svg>
                        <div className="box-date-not-active top-not-active">
                          <h4 className="p-lg-2 p-xxl-3 mb-0 text-center">
                            Third-Party <br />
                            Audit
                          </h4>
                          <h3 className="p-3 p-lg-2 p-xxl-3 mb-0 text-center">
                            Apr,&nbsp;2023
                          </h3>
                        </div>
                      </div>
                      <div className="bg-circle-not-active left-percentage-3 position-absolute">
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="4" cy="4" r="4" fill="#312E32" />
                        </svg>
                        <div className="box-date-not-active bottom-not-active">
                          <h3 className="p-3 p-lg-2 p-xxl-3 mb-0 text-center">
                            Jun,&nbsp;2023
                          </h3>
                          <h4 className="p-lg-2 p-xxl-3 mb-0 text-center">
                            Second Game Launch
                          </h4>
                        </div>
                      </div>
                      <div className="bg-circle-not-active left-percentage-4 position-absolute">
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="4" cy="4" r="4" fill="#312E32" />
                        </svg>
                        <div className="box-date-not-active top-not-active">
                          <h4 className="p-lg-2 p-xxl-3 mb-0 text-center">
                            AI DeFi <br />
                            Trading
                          </h4>
                          <h3 className="p-3 p-lg-2 p-xxl-3 mb-0 text-center">
                            Aug,&nbsp;2023
                          </h3>
                        </div>
                      </div>
                      <div className="bg-circle-not-active left-percentage-5 position-absolute">
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="4" cy="4" r="4" fill="#312E32" />
                        </svg>
                        <div className="box-date-not-active bottom-not-active">
                          <h3 className="p-3 p-lg-2 p-xxl-3 mb-0 text-center">
                            Nov,&nbsp;2023
                          </h3>
                          <h4 className="p-lg-2 p-xxl-3 mb-0 text-center">
                            Metaverse <br />
                            Launch
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </XyzTransition>

            <XyzTransition xyz="fade down">
              {navButton === 379 && index === 8 && (
                <div className="nav-379 w-total w-xl-50 ms-4 mx-sm-auto vh-100 d-flex flex-column">
                  <h1 className="text-black mb-4">
                    By Participating In Our Community
                    <br />
                    <span className="">Today You Get</span>
                  </h1>
                  <div className="d-sm-flex mt-xxl-2 mb-sm-4 mb-lg-3">
                    <div className="w-100 w-sm-15">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-75 ms-lg-4 mb-0 text-black">
                      RISK-FREE MEMBERSHIP SUSCRIPTION
                    </h5>
                  </div>
                  <div className="d-sm-flex mt-xxl-2 mb-sm-4 mb-lg-3">
                    <div className="w-100 w-sm-15">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-75 ms-lg-4 mb-0 text-black">
                      A SUPER RARE NFT CREATED BY YOU
                    </h5>
                  </div>
                  <div className="d-sm-flex mt-xxl-2 mb-sm-4 mb-lg-3">
                    <div className="w-100 w-sm-15">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-75 ms-lg-4 mb-0 text-black">
                      ACCESS TO AN EXCLUSIVE METAVERSE COMMUNITY
                    </h5>
                  </div>
                  <div className="d-sm-flex mt-xxl-2 mb-sm-4 mb-lg-3">
                    <div className="w-100 w-sm-15">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-75 ms-lg-4 mb-0 text-black">
                      INSTANT AFFILIATE PAYOUTS VIA FULLY DECENTRALIZED SMART
                      CONTRACTS
                    </h5>
                  </div>
                  <div className="d-sm-flex mt-xxl-2 mb-sm-4 mb-lg-3">
                    <div className="w-100 w-sm-15">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-75 ms-lg-4 mb-0 text-black">
                      ACCESS TO E-LEARNING PORTAL
                    </h5>
                  </div>
                  <div className="d-sm-flex mt-xxl-2 mb-sm-4 mb-lg-3">
                    <div className="w-100 w-sm-15">
                      <hr className="w-50 w-sm-75" />
                    </div>
                    <h5 className="w-sm-75 ms-lg-4 mb-0 text-black">
                      ACCESS TO RELEASED PRODUCTS... AND MUCH MORE
                    </h5>
                  </div>
                  <div>
                    {account === '' && (
                      <WalletLink
                        btnText="GET STARTED NOW"
                        className="text-black rounded-pill border-0 mt-4 mb-4 mb-xl-0 mt-xxl-5 py-3 px-5 fw-bold"
                        onShowNoReferrer={handleShow}
                      />
                    )}
                  </div>
                </div>
              )}
            </XyzTransition>

            <XyzTransition xyz="fade up">
              {navButton === 425 && index === 9 && (
                <div className="nav-425 vh-lg-100 d-flex flex-column">
                  <div className="w-total w-xl-50 ms-4 mx-sm-auto">
                    <h1 className="text-black">
                      Got Questions?
                      <br />
                      <span className="">We Got Answers</span>
                    </h1>
                  </div>
                  <div className="w-75 w-sm-60 w-lg-100 d-lg-flex h-100 justify-content-lg-center ms-4 ms-lg-0 my-1 mx-sm-auto mt-xxl-5 mb-xxl-2">
                    <div className="w-100 w-lg-20">
                      <button
                        type="button"
                        className={`px-4 btn-faq w-100 ${
                          question === 1 && 'btn-faq-active'
                        }`}
                        onClick={() => {
                          if (question === 1) {
                            setQuestion(0);
                          } else {
                            handleQuestion(1);
                          }
                        }}
                      >
                        How do I get started with&nbsp;PROFITi?
                      </button>
                      <div
                        className={`answer ${
                          question === 1 && 'answer-active'
                        }`}
                      >
                        <p className="p-3 text-start p-xxl-4 mb-0">
                          •&nbsp;&nbsp;Create a Metamask wallet.
                          <br />
                          •&nbsp;&nbsp;Add BUSD or BNB to your Metamask wallet.
                          <br />
                          •&nbsp;&nbsp;Visit the PROFITi website and sign up for
                          a free account with your wallet.
                          <br />
                          •&nbsp;&nbsp;Activate your account by paying the
                          activation fee and monthly subscription cost.
                          <br />
                          •&nbsp;&nbsp;Start participating in the affiliate
                          program and earning from the revenue share pool.
                        </p>
                      </div>
                      <button
                        type="button"
                        className={`px-4 btn-faq w-100 mt-2 ${
                          question === 2 && 'btn-faq-active'
                        }`}
                        onClick={() => {
                          if (question === 2) {
                            setQuestion(0);
                          } else {
                            handleQuestion(2);
                          }
                        }}
                      >
                        What are the benefits of PROFITi's&nbsp;community?
                      </button>
                      <div
                        className={`answer ${
                          question === 2 && 'answer-active'
                        }`}
                      >
                        <p className="p-3 p-xxl-4 mb-0">
                          Being a part of PROFITi's community offers access to
                          cutting-edge products, resources, knowledge, and
                          support to achieve financial freedom. Members can
                          connect with others and build relationships,
                          participate in the affiliate program, and access
                          exclusive deals and promotions.
                        </p>
                      </div>
                    </div>
                    <div className="w-100 w-lg-40 d-lg-flex align-items-end mx-lg-2 mx-xl-5">
                      <div className="w-100 w-lg-50 me-1">
                        <button
                          type="button"
                          className={`px-4 btn-faq w-100 mt-2 mt-lg-0 ${
                            question === 3 && 'btn-faq-active'
                          }`}
                          onClick={() => {
                            if (question === 3) {
                              setQuestion(0);
                            } else {
                              handleQuestion(3);
                            }
                          }}
                        >
                          What are the PROFITi tokens used&nbsp;for?
                        </button>
                        <div
                          className={`answer ${
                            question === 3 && 'answer-active'
                          }`}
                        >
                          <p className="p-3 p-xxl-4 mb-0">
                            PROFITi operates as a two-token system: USDPI, a
                            staple coin backed 1:1 with BUSD which used for
                            payment transactions, and PRFTI, a utility token
                            used as credit for accessing products and services
                            and earning rewards.
                          </p>
                        </div>
                      </div>
                      <div className="w-100 w-lg-50 ms-lg-1">
                        <button
                          type="button"
                          className={`px-4 btn-faq w-100 mt-2 mt-lg-0 ${
                            question === 4 && 'btn-faq-active'
                          }`}
                          onClick={() => {
                            if (question === 4) {
                              setQuestion(0);
                            } else {
                              handleQuestion(4);
                            }
                          }}
                        >
                          Was there a pre-sale for PRFTI&nbsp;token?
                        </button>
                        <div
                          className={`answer ${
                            question === 4 && 'answer-active'
                          }`}
                        >
                          <p className="p-3 p-xxl-4 mb-0">
                            No, the tokens are strategically designed for pure
                            utility and rewards, they were created to be fairly
                            distributed among community members who join the
                            program and through staking. This helps prevent
                            large buyers (known as "whales") from influencing
                            the market and stops harmful individuals from
                            hurting the project.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 w-lg-20">
                      <button
                        type="button"
                        className={`px-4 btn-faq w-100 mt-2 mt-lg-0 ${
                          question === 5 && 'btn-faq-active'
                        }`}
                        onClick={() => {
                          if (question === 5) {
                            setQuestion(0);
                          } else {
                            handleQuestion(5);
                          }
                        }}
                      >
                        Can I earn a recurring income with&nbsp;PROFITi?
                      </button>
                      <div
                        className={`answer ${
                          question === 5 && 'answer-active'
                        }`}
                      >
                        <p className="p-3 p-xxl-4 mb-0">
                          Yes, members can earn a recurring income by
                          participating in the PROFITi affiliate program,
                          staking PRFTI tokens, and the Revenue Share Pool. Last
                          but not least, by utilizing our products to help grow
                          your own business such as the AI Art creator and NFT
                          launchpad.
                        </p>
                      </div>
                      <button
                        type="button"
                        className={`px-4 btn-faq w-100 mt-2 ${
                          question === 6 && 'btn-faq-active'
                        }`}
                        onClick={() => {
                          if (question === 6) {
                            setQuestion(0);
                          } else {
                            handleQuestion(6);
                          }
                        }}
                      >
                        What makes PROFITi&nbsp;unique?
                      </button>
                      <div
                        className={`answer mb-3 mb-lg-0 ${
                          question === 6 && 'answer-active'
                        }`}
                      >
                        <p className="p-3 p-xxl-4">
                          PROFITi's main focus is creating a decentralized
                          community with a strong focus on financial freedom
                          through blockchain and disruptive technologies.
                          Besides our innovative products, we have created the
                          world’s affiliate program 100% on the blockchain and a
                          smart contracts ecosystem.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </XyzTransition>
          </TextRide>
        </Banner>
      </ReactScrollWheelHandler>
      <Modal
        className="modal-connect-wallet text-white d-flex"
        size="xl"
        show={show}
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <div className="animation-gray">
              <div className="d-flex align-items-center">
                <Lottie
                  options={{
                    animationData: noReferrer,
                    autoplay: true,
                    loop: true,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={165}
                  width={198}
                />
              </div>
            </div>
            <div className="d-flex flex-column justify-content-around align-items-center text-center description">
              <h3>We could not find your referral code \=</h3>
              <p>
                PROFITi is a invite-only community,{' '}
                <b>please double check the referral link</b> that brought you
                here other wise if you do not have a referral code yet, you can
                find one by asking around in our social channels below.
              </p>
              <p>We hope to see you inside soon!</p>
            </div>
            <div className="overflow-auto bg-lg-social-medias">
              <div className="d-lg-flex justify-content-sm-around social-medias  px-2 px-lg-5">
                <div className="bg-mobile-social-medias px-4 px-lg-0">
                  <a
                    target="_blank"
                    href="https://t.me/ProfitiAi"
                    rel="noreferrer"
                  >
                    <img src={telegram} alt="Telegram" />
                  </a>
                  <a
                    target="_blank"
                    href="https://discord.gg/KxdwygBsKk"
                    rel="noreferrer"
                  >
                    <img src={discord} alt="Discord" />
                  </a>
                  <a
                    target="_blank"
                    href="https://twitter.com/profitiai"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt="Twitter" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.tiktok.com/@profitiai"
                    rel="noreferrer"
                  >
                    <img src={tikTok} alt="Tik-Tok" />
                  </a>
                </div>
                <div className="bg-mobile-social-medias px-4 px-lg-0 mt-3 mt-lg-0">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/profitiai"
                    rel="noreferrer"
                  >
                    <img src={facebook} alt="Facebook" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/profitiai/"
                    rel="noreferrer"
                  >
                    <img src={instagram} alt="Instagram" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/@profitiai/channels"
                    rel="noreferrer"
                  >
                    <img src={youtube} alt="Youtube" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.reddit.com/r/profitiai/"
                    rel="noreferrer"
                  >
                    <img src={reddit} alt="Reddit" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Loading
        type="white"
        srcImg={logoPurple}
        text="THE FUTURE IS HERE..."
        active={loading}
      />
    </>
  );
};

export default Home;
