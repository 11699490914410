import styled, { css } from 'styled-components';

interface IContainerProps {
  active: boolean;
  statusConfirm: boolean;
  paymentConclude: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  display: ${(props) => (props.paymentConclude ? 'none' : 'block')};

  > div {
    transition-duration: 0.3s;
    opacity: ${(props) => (props.active ? 1 : 0)};
    visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  }

  .bg-status {
    background: #222527;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
      inset 6px 28px 35px rgba(21, 21, 21, 0.25);
    border-radius: 0px 0px 20px 20px;
    width: 35%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
  }

  .text-gradient-status {
    ${(props) =>
      props.statusConfirm
        ? css`
            background: linear-gradient(
              239.23deg,
              #00f9ab 7.84%,
              #00f9ab 28.62%,
              #00f9ab 38.94%,
              #00f9ab 49.96%,
              #00f9ab 64.82%,
              #00f9ab 67.63%,
              #00f9ab 91.88%
            );
          `
        : css`
            background: linear-gradient(
              239.23deg,
              #9df3ff 7.84%,
              #bcffe5 28.62%,
              #deefd1 38.94%,
              #ffe0be 49.96%,
              #fbc2d3 64.82%,
              #fabcd6 67.63%,
              #f281ff 91.88%
            );
          `}
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  .status-payment-page {
    height: 120px;
  }

  > div > div:not(.animation) {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .point-none > div {
    pointer-events: none;
  }

  span:not(.status) {
    font-size: 80%;
    width: 80px;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @media screen and (max-width: 991px) {
    .bg-status {
      width: 60%;
    }
  }

  @media screen and (max-width: 727px) {
    .bg-status {
      width: 95%;

      .loading-animation {
        > div {
          width: 45px !important;
          height: 45px !important;
        }
      }

      span {
        font-size: 90%;
        width: 60%;
      }
    }
  }
`;
