import React, { useState, useCallback, useEffect } from 'react';

import { Container, Modal } from './styles';
import { web3store } from '../../store';
import fox from '~/assets/icons/fox.svg';
import btnBlue from '~/assets/icons/btn-blue.svg';

interface IProps {
  className?: string;
}

const ConnectWallet: React.FC<IProps> = ({ className }) => {
  // const [show, setShow] = useState(false);
  const [noWallet, setNoWallet] = web3store.useState('noWallet');

  const handleClose = useCallback(() => {
    // setShow(false);
    setNoWallet(false);
  }, []);

  // const handleShow = useCallback(() => setShow(true), []);
  const handleShow = useCallback(() => setNoWallet(true), []);

  // useEffect(() => {
  //   const noWallet = typeof window.ethereum === 'undefined';
  //   setShow(noWallet);
  // }, []);

  return (
    <>
      <Modal
        className="modal-connect-wallet text-white d-flex"
        size="xl"
        // show={show}
        show={noWallet}
        // onHide={handleClose}
      >
        <Modal.Header className="border-0 ">
          {/* <button
            type="button"
            className="h4 modal-close ms-auto fw-bold border-0 bg-transparent"
            onClick={handleClose}
          >
            x
          </button> */}
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 text-center left-ride">
                <div className="img-metamask d-inline-block text-center bg-transparent border-0">
                  <img src={fox} alt="Logo fox" />
                </div>
                <h2 className="my-4">
                  Uh-oh! It seems you do not have Metamask&nbsp;installed.
                </h2>
                <p>
                  To create your account with PROFITi you need to have a
                  Metamask wallet installed in your browser. Please click the
                  button below to install it now.
                </p>
              </div>

              <div className="col-lg-8 my-4">
                <a
                  href="https://metamask.io/download/"
                  target="_blank"
                  className="btn btn-nft rounded-pill border-0 fw-bold color-white justify-content-center d-flex mx-auto w-100 w-xl-75 my-xxl-5"
                  rel="noreferrer"
                >
                  <span className="d-none d-lg-block">
                    CLICK HERE INSTALL METAMASK
                  </span>
                  <span className="d-lg-none d-block">
                    Download Metamask Here
                  </span>
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectWallet;
