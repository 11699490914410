import styled from 'styled-components';

export const Container = styled.div`
  .bg-gray {
    background: #1d2023;
    border-radius: 20px;

    .border-right {
      border-color: #393c3d !important;
    }

    h2 {
      color: #dadada;
    }

    .search {
      border-radius: 20px;
      padding: 5px 10px;
    }

    .search input {
      background-color: transparent;
      border: none;
    }

    .exportable {
      border: solid 1px #333;
      border-radius: 20px;
    }

    .react-datepicker__input-container {
      padding: 5px 10px;
      border: solid 1px #333;
      border-radius: 20px;
    }

    .react-datepicker__input-container input {
      background-color: transparent;
      border: none;
    }

    .input {
      border: 1.5px solid #393c3d;
      box-sizing: border-box;
      border-radius: 15px;
      height: 40px;

      input {
        color: #efefef !important;
      }
    }

    button.btn-gradient {
      border-radius: 15px;
    }
  }

  .table {
    .rdt_TableHeadRow,
    .rdt_TableRow {
      > div:nth-child(2),
      > div:nth-child(4) {
        max-width: 175px;
        width: 175px;
      }
    }
  }

  .rdt_TableRow {
    border-bottom: 1px solid #383939;
  }

  .rdt_Table {
    min-width: 870px;
  }

  .contact {
    cursor: pointer;

    input {
      color: #8c8c8c;
      font-size: 13px;
      background-color: transparent;
      border: none;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #dadada;
  }

  p {
    color: #969696;
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }
`;

export const NameTable = styled.div`
  img {
    width: 50px;
    height: 50px;
    border-radius: 15px;
  }
`;
