import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { format, parseISO } from 'date-fns';

import Table from '~/components/Table';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { useAuth } from '~/hooks/Auth';

// interface ITransactionResponse {
//   transactions: {
//     total_count: number;
//     transactions: {
//       id: number;
//       type: string;
//       date: number;
//       amount: string;
//       txid: string;
//     }[];
//   };
// }

interface ITransactionResponse {
  transactions: {
    total_count: number;
    transactions: ITransaction[];
  };
}

interface ITransaction {
  id: number;
  type: string;
  date: number;
  amount: string;
}

const TransactionHistory: React.FC = () => {
  const [coin, setCoin] = useState('usdpi');
  const { user } = useAuth();
  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  // useEffect(() => {
  //   api
  //     .get<ITransactionResponse>(`/v2/member/transaction/history/${user?.id}`)
  //     .then(async (response) => {
  //       const data = response.data.transactions.transactions.map<ITransaction>(
  //         (transaction) => ({
  //           id: transaction.id,
  //           type: transaction.type,
  //           // date: format(new Date(transaction.date), 'dd-MMM-yy'),
  //           date: new Date(transaction.date * 1000).toLocaleDateString(),
  //           amount: formatPrice(
  //             parseFloat(
  //               (parseInt(transaction.amount, 10) / 10 ** 18).toFixed(2)
  //             )
  //           ),
  //         })
  //       );
  //       setTransactions(data);
  //     });
  // }, [user]);

  useEffect(() => {
    if (user && user.id !== undefined) {
      api
        .get<ITransactionResponse>(`/v2/member/transaction/history/${user?.id}`)
        .then(async (response) => {
          const data =
            response.data.transactions.transactions.map<ITransaction>(
              (transaction) => ({
                id: transaction.id,
                type: transaction.type,
                date: transaction.date, // Keep the date as a number
                amount: formatPrice(
                  parseFloat(
                    (parseInt(transaction.amount, 10) / 10 ** 18).toFixed(2)
                  )
                ),
              })
            );
          setTransactions(data);
        });
    }
  }, [user]);

  const transactionsTest = [
    { type: 'Profit Cycle 3', date: '02/25/2022', amount: '$500.00' },
    { type: 'Profit Cycle 4', date: '02/25/2022', amount: '$300.00' },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Type',
        selector: 'type',
        sortable: true,
      },
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
      },
      {
        name: 'Amount',
        selector: 'amount',
        sortable: true,
      },
    ],
    []
  );

  const handleCoin = useCallback((e) => {
    setCoin(e);
  }, []);

  // A function to format the date as "dd-mon-yy"
  function formatDateAsDDMonYY(timestamp: number) {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const day = date.getDate().toString().padStart(2, '0');
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    return `${day}-${month}-${year}`;
  }

  return (
    <>
      <div className="position-relative d-flex flex-column h-100 justify-content-between">
        <div className="position-absolute btns-history">
          <button
            type="button"
            className={`py-2 px-5 active-${coin === 'usdpi' ? 'usdpi' : ''}`}
            onClick={() => handleCoin('usdpi')}
          >
            USDPI
          </button>
          {/* <button
            type="button"
            className={`py-2 px-5 active-${coin === 'prfti' ? 'prfti' : ''}`}
            onClick={() => handleCoin('prfti')}
          >
            PRFTI
          </button> */}
        </div>

        {/* <Table
          columns={columns}
          data={coin === 'usdpi' ? transactions : transactionsTest}
          pagination
          title="History"
        /> */}
        <Table
          columns={columns}
          data={transactions.map((transaction) => ({
            ...transaction,
            date: formatDateAsDDMonYY(transaction.date),
          }))}
          pagination
          title="History"
        />
      </div>
    </>
  );
};

export default TransactionHistory;
