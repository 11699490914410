import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BsArrowDownShort } from 'react-icons/bs';
import { Container, Welcome, BoxMatrix } from './styles';

import { useAuth } from '~/hooks/Auth';
import abbreviate from '~/utils/abbreviate';

import defaultAvatar from '~/assets/defaults/default-avatar.png';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

interface IReferrer {
  id: string;
  user_id: string;
  owner_id: string;
  name: string;
  avatar: string;
}

interface IUserMatrix {
  id: string;
  avatar: string;
  status: string;
  name: string;
  user_id: string;
  owner_id: string;
  referral_code: string;
  purchased: boolean;
  referrers: IReferrer[];
}

interface IOrder {
  status: string;
}

interface IReferrerResponse {
  affiliate_id: string;
  order: IOrder;
}

interface IAffiliate {
  id: string;
  user_id: string;
}

interface IAvatar {
  id: string;
  avatar_url: string;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  phone: string;
  birthdate: string;
  referral_code: string;
  avatar: IAvatar;
  referrer: IReferrer;
}

interface IUserDataResponse {
  id: string;
  name: string;
  referral_code: string;
  avatar: IAvatar;
  referrer: IReferrerResponse;
  team: number;
  indication: number;
  referrers: IReferrerResponse[];
  affiliates: IAffiliate[];
  affiliate: {
    level: string;
    slot: number;
    owner_id: string;
  };
}

interface IUserData {
  id: string;
  name: string;
  team: number;
  indication: number;
  week_value: string;
  month_value: string;
  referral_code: string;
  avatar: IAvatar;
  referrer: IReferrerResponse;
}

interface IMatrix {
  id: string;
  owner_id: string;
  user_id: string;
  level: string;
  slot: number;
  user: IUser;
}

const Matrix5x4: React.FC = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  const [userId, setUserId] = useState(user?.id);
  const [ownerId, setOwnerId] = useState('');
  const [usersMatrix, setUsersMatrix] = useState<IUserMatrix[]>([
    {
      id: '1',
      avatar: '',
      status: '',
      name: '',
      owner_id: '',
      referral_code: '',
      purchased: false,
      user_id: '',
      referrers: [
        {
          id: '1',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '2',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '3',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '4',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '5',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
      ],
    },
    {
      id: '2',
      avatar: '',
      status: '',
      name: '',
      owner_id: '',
      referral_code: '',
      purchased: false,
      user_id: '',
      referrers: [
        {
          id: '1',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '2',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '3',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '4',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '5',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
      ],
    },
    {
      id: '3',
      avatar: '',
      status: '',
      name: '',
      owner_id: '',
      referral_code: '',
      purchased: false,
      user_id: '',
      referrers: [
        {
          id: '1',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '2',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '3',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '4',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '5',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
      ],
    },
    {
      id: '4',
      avatar: '',
      status: '',
      name: '',
      owner_id: '',
      referral_code: '',
      purchased: false,
      user_id: '',
      referrers: [
        {
          id: '1',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '2',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '3',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '4',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '5',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
      ],
    },
    {
      id: '5',
      avatar: '',
      status: '',
      name: '',
      owner_id: '',
      referral_code: '',
      purchased: false,
      user_id: '',
      referrers: [
        {
          id: '1',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '2',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '3',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '4',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
        {
          id: '5',
          avatar: '',
          name: '',
          user_id: '',
          owner_id: '',
        },
      ],
    },
  ]);
  const [isPurchasedSlot, setIsPurchasedSlot] = useState(false);
  const [lineSelected, setLineSelected] = useState(0);
  const [loading, setLoading] = useState(false);

  const [dropToogle, setDropToogle] = useState(false);
  const location = useLocation();
  const profitCenterNumber = location.pathname.split('-')[2];

  const profitCenter = [
    { number: 1 },
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
    { number: 6 },
    { number: 7 },
    { number: 8 },
    { number: 9 },
    { number: 10 },
    { number: 11 },
    { number: 12 },
    { number: 13 },
    { number: 14 },
    { number: 15 },
    { number: 16 },
    { number: 17 },
    { number: 18 },
    { number: 19 },
    { number: 20 },
    { number: 21 },
    { number: 22 },
    { number: 23 },
    { number: 24 },
    { number: 25 },
  ];

  const handleDropToogle = () => {
    setDropToogle(!dropToogle);
  };

  const reduceReferenceCode = useCallback((str: string) => {
    return str && str.length > 8 ? `${str.slice(0, 7)}…` : str;
  }, []);

  useEffect(() => {
    // setLoading(true);
    // if (userId.length === 36) {
    //   api
    //     .get<IMatrix[]>(`affiliates/matrix/${userId}`)
    //     .then(async (response) => {
    //       const levelA: IUserMatrix[] = [];
    //       const ownersId: string[] = [];
    //       response.data.forEach((matrixData) => {
    //         let name: string;
    //         let referral_code: string;
    //         let user_id: string;
    //         if (matrixData.user) {
    //           if (matrixData.owner_id === matrixData.user_id) {
    //             name = `Centro de volume ${matrixData.slot}`;
    //             referral_code = `CV ${matrixData.slot}`;
    //             user_id = `${matrixData.user_id}CV${matrixData.slot}`;
    //           } else {
    //             name = abbreviate(matrixData.user.name);
    //             referral_code = reduceReferenceCode(
    //               matrixData.user.referral_code
    //             );
    //             user_id = matrixData.user_id;
    //           }
    //         } else {
    //           name = '';
    //           referral_code = '';
    //           user_id = '';
    //         }
    //         if (matrixData.level === 'A') {
    //           ownersId.push(user_id);
    //           const data = {
    //             id: matrixData.id,
    //             avatar: matrixData.user
    //               ? matrixData.user.avatar.avatar_url
    //               : '',
    //             status: 'Ativo',
    //             name,
    //             owner_id: matrixData.owner_id,
    //             user_id,
    //             referral_code,
    //             purchased: true,
    //             referrers: [
    //               {
    //                 id: '1',
    //                 user_id: '1',
    //                 owner_id: '',
    //                 name: '',
    //                 avatar: '',
    //               },
    //               {
    //                 id: '2',
    //                 user_id: '2',
    //                 owner_id: '',
    //                 name: '',
    //                 avatar: '',
    //               },
    //               {
    //                 id: '3',
    //                 user_id: '3',
    //                 owner_id: '',
    //                 name: '',
    //                 avatar: '',
    //               },
    //               {
    //                 id: '4',
    //                 user_id: '4',
    //                 owner_id: '',
    //                 name: '',
    //                 avatar: '',
    //               },
    //               {
    //                 id: '5',
    //                 user_id: '5',
    //                 owner_id: '',
    //                 name: '',
    //                 avatar: '',
    //               },
    //             ],
    //           };
    //           levelA.push(data);
    //         } else if (matrixData.level === 'B' && matrixData.slot <= 5) {
    //           const data = {
    //             id: matrixData.id,
    //             name,
    //             user_id: matrixData.user_id || '',
    //             owner_id: ownersId[0],
    //             avatar: matrixData.user
    //               ? matrixData.user.avatar.avatar_url
    //               : '',
    //           };
    //           levelA[0].referrers[matrixData.slot - 1] = data;
    //         } else if (matrixData.level === 'B' && matrixData.slot <= 10) {
    //           const data = {
    //             id: matrixData.id,
    //             name,
    //             user_id: matrixData.user_id || '',
    //             owner_id: ownersId[1],
    //             avatar: matrixData.user
    //               ? matrixData.user.avatar.avatar_url
    //               : '',
    //           };
    //           levelA[1].referrers[matrixData.slot - 6] = data;
    //         } else if (matrixData.level === 'B' && matrixData.slot <= 15) {
    //           const data = {
    //             id: matrixData.id,
    //             name,
    //             user_id: matrixData.user_id || '',
    //             owner_id: ownersId[2],
    //             avatar: matrixData.user
    //               ? matrixData.user.avatar.avatar_url
    //               : '',
    //           };
    //           levelA[2].referrers[matrixData.slot - 11] = data;
    //         } else if (matrixData.level === 'B' && matrixData.slot <= 20) {
    //           const data = {
    //             id: matrixData.id,
    //             name,
    //             user_id: matrixData.user_id || '',
    //             owner_id: ownersId[3],
    //             avatar: matrixData.user
    //               ? matrixData.user.avatar.avatar_url
    //               : '',
    //           };
    //           levelA[3].referrers[matrixData.slot - 16] = data;
    //         } else if (matrixData.level === 'B' && matrixData.slot <= 25) {
    //           const data = {
    //             id: matrixData.id,
    //             name,
    //             user_id: matrixData.user_id || '',
    //             owner_id: ownersId[4],
    //             avatar: matrixData.user
    //               ? matrixData.user.avatar.avatar_url
    //               : '',
    //           };
    //           levelA[4].referrers[matrixData.slot - 21] = data;
    //         }
    //       });
    //       setUsersMatrix(levelA);
    //       // const responseUser = await api.get<IUserDataResponse>(
    //       //   `users/matrix/${userId}`
    //       // );
    //       // if (responseUser.data) {
    //       //   const data: IUserData = {
    //       //     id: responseUser.data.id,
    //       //     name: abbreviate(responseUser.data.name),
    //       //     avatar: responseUser.data.avatar,
    //       //     referrer: responseUser.data.referrer,
    //       //     team: responseUser.data.team,
    //       //     indication: responseUser.data.indication,
    //       //     referral_code: responseUser.data.referral_code,
    //       //     week_value: formatPrice(0),
    //       //     month_value: formatPrice(0),
    //       //   };
    //       //   setUserData(data);
    //       //   let ownerUserId = responseUser.data.affiliate
    //       //     ? responseUser.data.affiliate.owner_id
    //       //     : '';
    //       //   if (
    //       //     responseUser.data.affiliate &&
    //       //     responseUser.data.affiliate.level === 'B'
    //       //   ) {
    //       //     if (responseUser.data.affiliate.slot <= 5) {
    //       //       ownerUserId = `${responseUser.data.affiliate.owner_id}CV1`;
    //       //     } else if (responseUser.data.affiliate.slot <= 10) {
    //       //       ownerUserId = `${responseUser.data.affiliate.owner_id}CV2`;
    //       //     } else if (responseUser.data.affiliate.slot <= 15) {
    //       //       ownerUserId = `${responseUser.data.affiliate.owner_id}CV3`;
    //       //     } else if (responseUser.data.affiliate.slot <= 20) {
    //       //       ownerUserId = `${responseUser.data.affiliate.owner_id}CV4`;
    //       //     } else if (responseUser.data.affiliate.slot <= 25) {
    //       //       ownerUserId = `${responseUser.data.affiliate.owner_id}CV5`;
    //       //     }
    //       //   }
    //       //   setOwnerId(ownerUserId);
    //       // }
    //     })
    //     .finally(() => setLoading(false));
    // } else {
    //   setLoading(false);
    // }
  }, [user?.id, userId, reduceReferenceCode]);

  const handleClick = useCallback(
    async (
      userIdSelected,
      line,
      isPurchased,
      matrixSelect?: IUserMatrix | IReferrer
    ) => {
      setLoading(true);
      if (line < 3 || (line === 3 && isPurchased)) {
        if (matrixSelect) {
          const userName = matrixSelect.name.slice(0, -2);
          if (
            userIdSelected.length > 1 &&
            userIdSelected.length === 36 &&
            userName !== 'Centro de volume'
          ) {
            setIsPurchasedSlot(isPurchased);
            setLineSelected(line);
            setUserId(userIdSelected);
            setUsersMatrix([]);
            setOwnerId(matrixSelect.owner_id);
          } else if (userIdSelected.length > 36) {
            const slotSelected = parseInt(matrixSelect.name.slice(-2), 10);

            // const response = await api.get<IMatrix[]>(
            //   `affiliates/matrix/${userIdSelected.slice(0, -3)}`
            // );
            const levelA: IUserMatrix[] = [];
            const ownersId: string[] = [];
            // response.data.forEach((matrixData) => {
            //   let name: string;
            //   let referral_code: string;
            //   let user_id: string;
            //   if (matrixData.user) {
            //     if (matrixData.owner_id === matrixData.user_id) {
            //       name = `Centro de volume ${matrixData.slot}`;
            //       referral_code = `CV ${matrixData.slot}`;
            //       user_id = `${matrixData.user_id}CV${matrixData.slot}`;
            //     } else {
            //       name = abbreviate(matrixData.user.name);
            //       referral_code = reduceReferenceCode(
            //         matrixData.user.referral_code
            //       );
            //       user_id = matrixData.user_id;
            //     }
            //   } else {
            //     name = '';
            //     referral_code = '';
            //     user_id = '';
            //   }
            //   if (
            //     matrixData.level === 'B' &&
            //     matrixData.slot > slotSelected * 5 - 5 &&
            //     matrixData.slot <= slotSelected * 5
            //   ) {
            //     ownersId.push(user_id);
            //     const data = {
            //       id: matrixData.id,
            //       avatar: matrixData.user
            //         ? matrixData.user.avatar.avatar_url
            //         : '',
            //       status: 'Ativo',
            //       name,
            //       owner_id: matrixData.owner_id,
            //       user_id,
            //       referral_code,
            //       purchased: true,
            //       referrers: [
            //         {
            //           id: '1',
            //           user_id: '1',
            //           name: '',
            //           avatar: '',
            //           owner_id: '',
            //         },
            //         {
            //           id: '2',
            //           user_id: '2',
            //           name: '',
            //           avatar: '',
            //           owner_id: '',
            //         },
            //         {
            //           id: '3',
            //           user_id: '3',
            //           name: '',
            //           avatar: '',
            //           owner_id: '',
            //         },
            //         {
            //           id: '4',
            //           user_id: '4',
            //           name: '',
            //           avatar: '',
            //           owner_id: '',
            //         },
            //         {
            //           id: '5',
            //           user_id: '5',
            //           name: '',
            //           avatar: '',
            //           owner_id: '',
            //         },
            //       ],
            //     };
            //     levelA.push(data);
            //   } else if (
            //     matrixData.level === 'C' &&
            //     matrixData.slot > 5 * 5 * slotSelected - 25 &&
            //     matrixData.slot <= 5 * 5 * slotSelected - 20
            //   ) {
            //     const index = matrixData.slot - 1 - 25 * (slotSelected - 1);
            //     const data = {
            //       id: matrixData.id,
            //       name,
            //       user_id: matrixData.user_id && '',
            //       owner_id: ownersId[0],
            //       avatar: matrixData.user
            //         ? matrixData.user.avatar.avatar_url
            //         : '',
            //     };
            //     levelA[0].referrers[index] = data;
            //   } else if (
            //     matrixData.level === 'C' &&
            //     matrixData.slot > 5 * 5 * slotSelected - 20 &&
            //     matrixData.slot <= 5 * 5 * slotSelected - 15
            //   ) {
            //     const index = matrixData.slot - 6 - 25 * (slotSelected - 1);
            //     const data = {
            //       id: matrixData.id,
            //       name,
            //       user_id: matrixData.user_id && '',
            //       owner_id: ownersId[1],
            //       avatar: matrixData.user
            //         ? matrixData.user.avatar.avatar_url
            //         : '',
            //     };
            //     levelA[1].referrers[index] = data;
            //   } else if (
            //     matrixData.level === 'C' &&
            //     matrixData.slot > 5 * 5 * slotSelected - 15 &&
            //     matrixData.slot <= 5 * 5 * slotSelected - 10
            //   ) {
            //     const index = matrixData.slot - 11 - 25 * (slotSelected - 1);

            //     const data = {
            //       id: matrixData.id,
            //       name,
            //       user_id: matrixData.user_id && '',
            //       owner_id: ownersId[2],
            //       avatar: matrixData.user
            //         ? matrixData.user.avatar.avatar_url
            //         : '',
            //     };
            //     levelA[2].referrers[index] = data;
            //   } else if (
            //     matrixData.level === 'C' &&
            //     matrixData.slot > 5 * 5 * slotSelected - 10 &&
            //     matrixData.slot <= 5 * 5 * slotSelected - 5
            //   ) {
            //     const index = matrixData.slot - 16 - 25 * (slotSelected - 1);
            //     const data = {
            //       id: matrixData.id,
            //       name,
            //       user_id: matrixData.user_id && '',
            //       owner_id: ownersId[3],
            //       avatar: matrixData.user
            //         ? matrixData.user.avatar.avatar_url
            //         : '',
            //     };
            //     levelA[3].referrers[index] = data;
            //   } else if (
            //     matrixData.level === 'C' &&
            //     matrixData.slot > 5 * 5 * slotSelected - 5 &&
            //     matrixData.slot <= 5 * 5 * slotSelected - 0
            //   ) {
            //     const index = matrixData.slot - 21 - 25 * (slotSelected - 1);
            //     const data = {
            //       id: matrixData.id,
            //       name,
            //       user_id: matrixData.user_id || '',
            //       owner_id: ownersId[4],
            //       avatar: matrixData.user
            //         ? matrixData.user.avatar.avatar_url
            //         : '',
            //     };
            //     levelA[4].referrers[index] = data;
            //   }
            // });
            setUsersMatrix(levelA);
            const newUserData = { ...userData };

            newUserData.name = `Centro de volume ${slotSelected}`;
            newUserData.referral_code = `CV ${slotSelected}`;
            setUserData(newUserData);
            setOwnerId(userIdSelected.slice(0, -3));
            setLineSelected(line);
          }
        } else if (userIdSelected.length > 1 && userIdSelected.length === 36) {
          setIsPurchasedSlot(isPurchased);
          setLineSelected(line);
          setUserId(userIdSelected);
          setUsersMatrix([]);
        } else if (userIdSelected.length > 36) {
          const slotSelected = parseInt(userIdSelected.slice(-1), 10);

          const userDataId = userIdSelected.slice(0, -3);

          // const response = await api.get<IMatrix[]>(
          //   `affiliates/matrix/${userDataId}`
          // );
          const levelA: IUserMatrix[] = [];
          const ownersId: string[] = [];
          // response.data.forEach((matrixData) => {
          //   let name: string;
          //   let referral_code: string;
          //   let user_id: string;
          //   if (matrixData.user) {
          //     if (matrixData.owner_id === matrixData.user_id) {
          //       name = `Centro de volume ${matrixData.slot}`;
          //       referral_code = `CV ${matrixData.slot}`;
          //       user_id = `${matrixData.user_id}CV${matrixData.slot}`;
          //     } else {
          //       name = abbreviate(matrixData.user.name);
          //       referral_code = reduceReferenceCode(
          //         matrixData.user.referral_code
          //       );
          //       user_id = matrixData.user_id;
          //     }
          //   } else {
          //     name = '';
          //     referral_code = '';
          //     user_id = '';
          //   }
          //   if (
          //     matrixData.level === 'B' &&
          //     matrixData.slot > slotSelected * 5 - 5 &&
          //     matrixData.slot <= slotSelected * 5
          //   ) {
          //     ownersId.push(user_id);
          //     const data = {
          //       id: matrixData.id,
          //       avatar: matrixData.user
          //         ? matrixData.user.avatar.avatar_url
          //         : '',
          //       status: 'Ativo',
          //       name,
          //       owner_id: matrixData.owner_id,
          //       user_id,
          //       referral_code,
          //       purchased: true,
          //       referrers: [
          //         {
          //           id: '1',
          //           user_id: '1',
          //           name: '',
          //           avatar: '',
          //           owner_id: '',
          //         },
          //         {
          //           id: '2',
          //           user_id: '2',
          //           name: '',
          //           avatar: '',
          //           owner_id: '',
          //         },
          //         {
          //           id: '3',
          //           user_id: '3',
          //           name: '',
          //           avatar: '',
          //           owner_id: '',
          //         },
          //         {
          //           id: '4',
          //           user_id: '4',
          //           name: '',
          //           avatar: '',
          //           owner_id: '',
          //         },
          //         {
          //           id: '5',
          //           user_id: '5',
          //           name: '',
          //           avatar: '',
          //           owner_id: '',
          //         },
          //       ],
          //     };
          //     levelA.push(data);
          //   } else if (
          //     matrixData.level === 'C' &&
          //     matrixData.slot > 5 * 5 * slotSelected - 25 &&
          //     matrixData.slot <= 5 * 5 * slotSelected - 20
          //   ) {
          //     const index = matrixData.slot - 1 - 25 * (slotSelected - 1);
          //     const data = {
          //       id: matrixData.id,
          //       name,
          //       user_id: matrixData.user_id && '',
          //       owner_id: ownersId[0],
          //       avatar: matrixData.user
          //         ? matrixData.user.avatar.avatar_url
          //         : '',
          //     };
          //     levelA[0].referrers[index] = data;
          //   } else if (
          //     matrixData.level === 'C' &&
          //     matrixData.slot > 5 * 5 * slotSelected - 20 &&
          //     matrixData.slot <= 5 * 5 * slotSelected - 15
          //   ) {
          //     const index = matrixData.slot - 6 - 25 * (slotSelected - 1);
          //     const data = {
          //       id: matrixData.id,
          //       name,
          //       user_id: matrixData.user_id && '',
          //       owner_id: ownersId[1],
          //       avatar: matrixData.user
          //         ? matrixData.user.avatar.avatar_url
          //         : '',
          //     };
          //     levelA[1].referrers[index] = data;
          //   } else if (
          //     matrixData.level === 'C' &&
          //     matrixData.slot > 5 * 5 * slotSelected - 15 &&
          //     matrixData.slot <= 5 * 5 * slotSelected - 10
          //   ) {
          //     const index = matrixData.slot - 11 - 25 * (slotSelected - 1);

          //     const data = {
          //       id: matrixData.id,
          //       name,
          //       user_id: matrixData.user_id && '',
          //       owner_id: ownersId[2],
          //       avatar: matrixData.user
          //         ? matrixData.user.avatar.avatar_url
          //         : '',
          //     };
          //     levelA[2].referrers[index] = data;
          //   } else if (
          //     matrixData.level === 'C' &&
          //     matrixData.slot > 5 * 5 * slotSelected - 10 &&
          //     matrixData.slot <= 5 * 5 * slotSelected - 5
          //   ) {
          //     const index = matrixData.slot - 16 - 25 * (slotSelected - 1);
          //     const data = {
          //       id: matrixData.id,
          //       name,
          //       user_id: matrixData.user_id && '',
          //       owner_id: ownersId[3],
          //       avatar: matrixData.user
          //         ? matrixData.user.avatar.avatar_url
          //         : '',
          //     };
          //     levelA[3].referrers[index] = data;
          //   } else if (
          //     matrixData.level === 'C' &&
          //     matrixData.slot > 5 * 5 * slotSelected - 5 &&
          //     matrixData.slot <= 5 * 5 * slotSelected - 0
          //   ) {
          //     const index = matrixData.slot - 21 - 25 * (slotSelected - 1);
          //     const data = {
          //       id: matrixData.id,
          //       name,
          //       user_id: matrixData.user_id || '',
          //       owner_id: ownersId[4],
          //       avatar: matrixData.user
          //         ? matrixData.user.avatar.avatar_url
          //         : '',
          //     };
          //     levelA[4].referrers[index] = data;
          //   }
          // });
          setUsersMatrix(levelA);

          // const responseUser = await api.get<IUserDataResponse>(
          //   `users/matrix/${userId}`
          // );
          // if (responseUser.data) {
          //   const data: IUserData = {
          //     id: responseUser.data.id,
          //     name: `Centro de volume ${slotSelected}`,
          //     avatar: responseUser.data.avatar,
          //     referrer: responseUser.data.referrer,
          //     team: responseUser.data.team,
          //     indication: responseUser.data.indication,
          //     referral_code: `CV ${slotSelected}`,
          //     week_value: formatPrice(0),
          //     month_value: formatPrice(0),
          //   };
          //   setUserData(data);
          // }

          setOwnerId(userIdSelected.slice(0, -3));
          setLineSelected(line);
        }
        setUserId(userIdSelected);
      }
      setLoading(false);
    },
    [userData, reduceReferenceCode, userId]
  );

  return (
    <Container>
      <div className="container-fluid px-lg-0 px-xxl-3 container-xxl">
        {/* <div className="row mb-4 mt-4">
          <div className="col-12 mb-2">
            <Welcome>
              <div className="container-fluid">
                <div className="row justify-content-center justify-content-md-start">
                  <div className="col-lg-5 px-0 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <h1 className="h4 h3-xxl mb-0">
                      <span className="font-weight-bold text-white">
                        Matriz 5x4 -
                      </span>{' '}
                      Licença Anual
                    </h1>
                    <button
                      type="button"
                      className="border-gradient rounded-pill ml-3"
                    >
                      <span className="d-block px-4">Lista</span>
                    </button>
                  </div>
                  <div className="col-lg-7 d-flex flex-column flex-xl-row justify-content-end  align-items-center align-items-lg-end align-items-xl-center mt-4 mt-xl-0 px-0 px-md-3">
                    <p className="mr-xl-3 mb-2 mb-xl-0">
                      Configurar posicionamento na matriz
                    </p>
                    <div className="position-relative btn-group rounded-pill d-flex">
                      <div
                        className={`btn-background rounded-pill ${
                          matrixType === 'Alternado' ? 'right' : 'left'
                        }`}
                      />
                      <button
                        type="button"
                        className={`btn-check rounded-pill mr-n2 ${
                          matrixType === 'Sequêncial' ? 'active' : ''
                        }`}
                        onClick={() => handleSelectMatrixType('Sequêncial')}
                      >
                        Sequêncial
                      </button>
                      <button
                        type="button"
                        className={`btn-check rounded-pill ml-n2 ${
                          matrixType === 'Alternado' ? 'active' : ''
                        }`}
                        onClick={() => handleSelectMatrixType('Alternado')}
                      >
                        Alternado
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
        </div> */}
        <BoxMatrix className="bg-gray py-5 px-4">
          {/* <div
            className={`row justify-content-between button-group ${
              user?.id === userId ? 'hide my-n5' : ''
            }`}
          >
            <div className="col-md-2 my-2 my-md-0 d-flex align-items-center justify-content-center">
              <button
                type="button"
                className="btn-actions border-gradient rounded-pill"
                onClick={() => handleClick(user?.id, 0, false)}
                disabled={user?.id === userId}
              >
                <span>Voltar ao topo</span>
              </button>
            </div>
            <div className="col-md-2 my-2 my-md-0 d-flex align-items-center justify-content-center">
              <button
                type="button"
                className="btn-actions border-gradient rounded-pill"
                onClick={() => handleClick(ownerId, lineSelected - 1, false)}
                disabled={ownerId.length === 0}
              >
                <span>Subir um</span>
              </button>
            </div>
          </div> */}
          <Welcome className="row">
            <div className="col-12 d-sm-flex">
              <h1 className="h3 h2-sm fw-bold me-sm-5 mb-4 mb-sm-2">
                Profit Center {profitCenterNumber} -{' '}
                <span>
                  ${parseInt(profitCenterNumber, 10) * 5}&nbsp;Activation
                </span>
              </h1>
              <div className="dropdown d-flex d-sm-block justify-content-end">
                <div className="bg-dropdown rounded-pill">
                  <button
                    className="btn dropdown-toggle rounded-pill py-1 px-5"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={handleDropToogle}
                  />
                </div>
                <ul
                  className={`dropdown-menu ${dropToogle && 'show'}`}
                  aria-labelledby="dropdownMenuButton1"
                >
                  {profitCenter.map((profit) => (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={`${process.env.PUBLIC_URL}/atividades/profit-center-${profit.number}`}
                        onClick={handleDropToogle}
                      >
                        {profit.number}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Welcome>
          <div className="row my-5">
            <div className="col-12 px-1 px-xxl-3">
              <div className="box-top-user d-flex flex-wrap flex-lg-nowrap justify-content-center justify-content-sm-around align-items-center py-3">
                <div className="my-2 my-lg-0 order-1 order-lg-0 text-center w-100 w-sm-50 w-md-auto">
                  <p className="mb-0 text-light-gray size">5 of 6 Qualified</p>
                  <p className="mb-0 small normal-xl">Membros Total</p>
                  <hr className="mb-0 mx-auto w-25 d-md-none" />
                </div>

                <div className="px-auto d-none d-lg-block bd-gray order-lg-1" />

                <div className="my-2 my-lg-0 order-2 order-lg-2 text-center w-100 w-sm-50 w-md-auto">
                  <p className="mb-0 text-light-gray size">4</p>
                  <p className="mb-0 small normal-xl">Referidos Diretos</p>
                  <hr className="mb-0 mx-auto w-25 d-md-none " />
                </div>
                <div className="my-2 my-lg-0 order-0 order-lg-3 d-flex justify-content-center align-items-center w-100 w-lg-auto">
                  <img
                    src={defaultAvatar}
                    alt="Avatar"
                    className="rounded-circle border-gradient mr-3"
                  />
                  <div className="ps-3 size">
                    <p className="mb-0 text-light-gray">Name</p>
                    <p className="mb-0 text-light-gray">Major</p>
                    <p className="mb-0 small normal-xl">@NFT1</p>
                  </div>
                </div>
                <div className="my-2 my-lg-0 order-3 order-lg-4 text-center w-100 w-sm-50 w-md-auto">
                  <p className="mb-0 text-light-gray size">13</p>
                  <p className="mb-0 small normal-xl">Volume Esta Semana</p>
                  <hr className="mb-0 mx-auto w-25 d-sm-none" />
                </div>
                <div className="px-auto d-none d-lg-block bd-gray order-lg-5" />
                <div className="my-2 my-lg-0 order-4 order-lg-5 text-center w-100 w-sm-50 w-md-auto">
                  <p className="mb-0 text-light-gray size">$275</p>
                  <p className="mb-0 small normal-xl">Volume Este Mês</p>
                </div>
              </div>
              <div className="row px-sm-3">
                <div className="col-xl-6 ps-xl-0">
                  <div className="row">
                    <div className="col-xl-12 px-2 mt-4">
                      <div className="h-100 box-sub-user d-flex align-items-center justify-content-center rounded-pill w-100 py-3">
                        <div className="w-custom ps-3">
                          <p className="mb-0 text-light-gray">name</p>
                          <p className="mb-0 text-light-gray">consultor</p>
                        </div>
                        <div className="bg-no-avatar mx-2 text-center">
                          <img
                            src={defaultAvatar}
                            alt="Avatar"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="w-custom ps-2">
                          <p className="mb-0">@NFT22</p>
                          <p className="mb-0 text-green">Active</p>
                          <p className="mb-0">@NFT22</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 px-2 mt-4">
                      <div className="h-100 box-sub-user d-flex align-items-center justify-content-center rounded-pill w-100 py-3">
                        <div className="w-custom ps-3">
                          <p className="mb-0 text-light-gray">name</p>
                          <p className="mb-0 text-light-gray">consultor</p>
                        </div>
                        <div className="bg-no-avatar mx-2 text-center">
                          <img
                            src={defaultAvatar}
                            alt="Avatar"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="w-custom ps-2">
                          <p className="mb-0">@NFT22</p>
                          <p className="mb-0 text-green">Active</p>
                          <p className="mb-0">@NFT22</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 px-2 mt-4">
                      <div className="h-100 box-sub-user d-flex align-items-center justify-content-center rounded-pill w-100 py-3">
                        <div className="w-custom ps-3">
                          <p className="mb-0 text-light-gray">name</p>
                          <p className="mb-0 text-light-gray">consultor</p>
                        </div>
                        <div className="bg-no-avatar mx-2 text-center">
                          <img
                            src={defaultAvatar}
                            alt="Avatar"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="w-custom ps-2">
                          <p className="mb-0">@NFT22</p>
                          <p className="mb-0 text-green">Active</p>
                          <p className="mb-0">@NFT22</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 pe-xl-0">
                  <div className="row">
                    <div className="col-xl-12 px-2 mt-4">
                      <div className="h-100 box-sub-user d-flex align-items-center justify-content-center rounded-pill w-100 py-3">
                        <div className="w-custom ps-3">
                          <p className="mb-0 text-light-gray">name</p>
                          <p className="mb-0 text-light-gray">consultor</p>
                        </div>
                        <div className="bg-no-avatar mx-2 text-center">
                          <img
                            src={defaultAvatar}
                            alt="Avatar"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="w-custom ps-2">
                          <p className="mb-0">@NFT22</p>
                          <p className="mb-0 text-green">Active</p>
                          <p className="mb-0">@NFT22</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 px-2 mt-4">
                      <div className="h-100 box-sub-user d-flex align-items-center justify-content-center rounded-pill w-100 py-3">
                        <div className="w-custom ps-3">
                          <p className="mb-0 text-light-gray">name</p>
                          <p className="mb-0 text-light-gray">consultor</p>
                        </div>
                        <div className="bg-no-avatar mx-2 text-center">
                          <img
                            src={defaultAvatar}
                            alt="Avatar"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="w-custom ps-xxl-2">
                          <p className="mb-0">@NFT22</p>
                          <p className="mb-0 text-red">Not Qualified</p>
                          <p className="mb-0">@NFT22</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 px-2 mt-4">
                      <div className="h-100 box-sub-user d-flex align-items-center justify-content-center rounded-pill w-100 py-3">
                        <div className="w-custom ps-3">
                          <p className="mb-0 text-light-gray">&nbsp;</p>
                          <p className="mb-0 text-light-gray">&nbsp;</p>
                        </div>
                        <div className="bg-no-avatar mx-2 text-center">
                          {false && (
                            <img
                              src={defaultAvatar}
                              alt="Avatar"
                              className="rounded-circle"
                            />
                          )}
                        </div>
                        <div className="w-custom ps-xxl-2">
                          <p className="mb-0">&nbsp;</p>
                          <p className="mb-0 text-red">&nbsp;</p>
                          <p className="mb-0">&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BoxMatrix>
      </div>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Matrix5x4;
