import React, { useCallback, useState } from 'react';
import Slider from 'react-slick';

import { BiBookmarkPlus, BiEditAlt } from 'react-icons/bi';

import {
  Container,
  Welcome,
  NewsCarousel,
  TimeLine,
  Modal,
  BgTitle,
} from './styles';

const News: React.FC = () => {
  const [show, setShow] = useState(false);

  const [slideSelected, setSlideSelected] = useState(0);

  const breakingNews = [
    {
      src: 'https://placeimg.com/1140/350/animals',
      title: 'Mais uma grande noticia!1',
      date: '12/04/2021',
      subtitle: 'Mais uma grande noticia!SUB',
      paragraph: [
        'Lorem ipsum torquent eros porta magna cursus accumsan inceptos quam congue, curabitur commodo sollicitudin varius suspendisse risus pharetra ad. mauris platea sapien sit aenean sociosqu porta aliquet accumsan placerat, lacinia sociosqu porta maecenas cursus consequat quam lobortis, litora eu volutpat proin porta hendrerit accumsan aliquet.',
        'Lorem ipsum torquent eros porta magna cursus accumsan inceptos quam congue, curabitur commodo sollicitudin varius suspendisse risus pharetra ad. mauris platea sapien sit aenean sociosqu porta aliquet accumsan placerat, lacinia sociosqu porta maecenas cursus consequat quam lobortis, litora eu volutpat proin porta hendrerit accumsan aliquet.',
      ],
    },
    {
      src: 'https://placeimg.com/1140/350/nature',
      title: 'Mais uma grande noticia!2',
      date: '12/05/2021',
      subtitle: 'Mais uma grande noticia!SUB',
      paragraph: [
        'Lorem ipsum torquent eros porta magna cursus accumsan inceptos quam congue, curabitur commodo sollicitudin varius suspendisse risus pharetra ad. mauris platea sapien sit aenean sociosqu porta aliquet accumsan placerat, lacinia sociosqu porta maecenas cursus consequat quam lobortis, litora eu volutpat proin porta hendrerit accumsan aliquet.',
        'Lorem ipsum torquent eros porta magna cursus accumsan inceptos quam congue, curabitur commodo sollicitudin varius suspendisse risus pharetra ad. mauris platea sapien sit aenean sociosqu porta aliquet accumsan placerat, lacinia sociosqu porta maecenas cursus consequat quam lobortis, litora eu volutpat proin porta hendrerit accumsan aliquet.',
      ],
    },
    {
      src: 'https://placeimg.com/1140/350/people',
      title: 'Mais uma grande noticia!3',
      date: '12/06/2021',
      subtitle: 'Mais uma grande noticia!SUB',
      paragraph: [
        'Lorem ipsum torquent eros porta magna cursus accumsan inceptos quam congue, curabitur commodo sollicitudin varius suspendisse risus pharetra ad. mauris platea sapien sit aenean sociosqu porta aliquet accumsan placerat, lacinia sociosqu porta maecenas cursus consequat quam lobortis, litora eu volutpat proin porta hendrerit accumsan aliquet.',
        'Lorem ipsum torquent eros porta magna cursus accumsan inceptos quam congue, curabitur commodo sollicitudin varius suspendisse risus pharetra ad. mauris platea sapien sit aenean sociosqu porta aliquet accumsan placerat, lacinia sociosqu porta maecenas cursus consequat quam lobortis, litora eu volutpat proin porta hendrerit accumsan aliquet.',
      ],
    },
    {
      src: 'https://placeimg.com/1140/350/tech',
      title: 'Mais uma grande noticia!4',
      date: '12/07/2021',
      subtitle: 'Mais uma grande noticia!SUB',
      paragraph: [
        'Lorem ipsum torquent eros porta magna cursus accumsan inceptos quam congue, curabitur commodo sollicitudin varius suspendisse risus pharetra ad. mauris platea sapien sit aenean sociosqu porta aliquet accumsan placerat, lacinia sociosqu porta maecenas cursus consequat quam lobortis, litora eu volutpat proin porta hendrerit accumsan aliquet.',
        'Lorem ipsum torquent eros porta magna cursus accumsan inceptos quam congue, curabitur commodo sollicitudin varius suspendisse risus pharetra ad. mauris platea sapien sit aenean sociosqu porta aliquet accumsan placerat, lacinia sociosqu porta maecenas cursus consequat quam lobortis, litora eu volutpat proin porta hendrerit accumsan aliquet.',
      ],
    },
  ];

  const systemUpdates = [
    {
      date: '10.30.2020',
      update: ['Registration via Telegram.', 'Minor bug fixes.'],
    },
    {
      date: '12.30.2020',
      update: ['Registration via Telegram.', 'Minor bug fixes.'],
    },
    {
      date: '11.18.2020',
      update: ['Registration via Telegram.', 'Minor bug fixes.'],
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settingsModal = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: slideSelected,
  };

  const handleShow = useCallback((slide) => {
    setShow(true);
    setSlideSelected(slide);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 mb-2">
            <Welcome>
              <div className="container-fluid mt-4 ">
                <div className="row align-items-center justify-content-end justify-content-sm-between">
                  <div className="col-sm-6 px-0 order-1 order-sm-0 mt-4 mt-sm-0">
                    <h1 className="h3 h2-lg ps-3 mb-0 mt-2 mt-lg-0 fw-bold">
                      News
                    </h1>
                  </div>
                  {/* <div className="col-8 col-sm-6 col-md-3 mt-4 mt-sm-0 order-0 order-sm-1">
                    <div className="ms-auto border-gradient-light rounded-pill text-center">
                      <button
                        type="button"
                        className="w-100 border-0 py-2 bg-transparent d-flex align-items-center justify-content-center"
                      >
                        <BiBookmarkPlus
                          size={25}
                          color="#dadada"
                          className="me-2"
                        />
                        New update
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </Welcome>
          </div>
        </div>

        <div className="row my-5">
          {/* <div className="col-lg-6 text-end mb-4">
            <button type="button" className="btn-edit rounded-pill me-2">
              Edit
              <BiEditAlt size={13} color="#8c8c8c" className="ms-2" />
            </button>
          </div>
          <div className="col-lg-6 d-none d-md-block" /> */}
          <div className="col-lg-6 mb-5 mb-lg-0">
            <Slider {...settings}>
              {breakingNews.map((news, index) => (
                <div className="px-2">
                  <NewsCarousel
                    type="button"
                    src={news.src}
                    className="w-100 border-0"
                    onClick={() => handleShow(index)}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="col-lg-6">
            <TimeLine className="py-5 px-4 px-sm-5 px-lg-4 px-xl-5">
              <h2 className="h5 mb-5 fw-bold">System Updates</h2>
              <div className="px-2 overflow-auto">
                <ul className="timeline">
                  {systemUpdates.map((update) => (
                    <li className="timeline-item ps-4 mb-5">
                      <h3 className=" mb-4">{update.date}</h3>
                      <div className="bg-paragraph p-4">
                        {update.update.map((paragrapf) => (
                          <p className="mb-2">{paragrapf}</p>
                        ))}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </TimeLine>
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose} className="modal-news">
        <button
          type="button"
          className="h4 modal-close ms-auto fw-bold border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Slider {...settingsModal}>
          {breakingNews.map((news) => (
            <>
              <BgTitle src={news.src} className="d-flex align-items-end">
                <h2 className="text-white mb-0 px-4 px-sm-5 py-3 bg-title w-100">
                  {news.title}
                </h2>
              </BgTitle>

              <Modal.Body>
                <h3 className="h6 text-end text-white my-4 px-4 px-sm-5">
                  {news.date}
                </h3>
                <h2 className="mb-4 px-4 px-sm-5">{news.subtitle}</h2>
                {news.paragraph.map((paragraph) => (
                  <p className="h6 p-color mb-4 px-4 px-sm-5">{paragraph}</p>
                ))}
                <p className="h6 px-4 px-sm-5">Team</p>
              </Modal.Body>
              <Modal.Footer className="border-0 py-4" />
            </>
          ))}
        </Slider>
      </Modal>
    </Container>
  );
};
export default News;
