import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { FiEdit3 } from 'react-icons/fi';
import { Container, Modal, ModalChangeNft } from './styles';
import star from '~/assets/icons/star-icon.svg';
import aiArt from '~/assets/defaults/ai-art-creator.png';
import nftLaunchpad from '~/assets/defaults/nft-launchpad.png';
import cube from '~/assets/defaults/the-cube.png';
import aiDefi from '~/assets/defaults/ai-defi-arbitrage.png';
import voyagers from '~/assets/defaults/voyagers-game.png';
import basketExample from '~/assets/defaults/basket-example.png';
import qrCode from '~/assets/defaults/qr-code-nft.svg';
import cubeLarge from '~/assets/defaults/cube-large.png';
import cubeSmall from '~/assets/defaults/cube-small.png';
import cubeTitle from '~/assets/defaults/the-cube-title.svg';

import iconNft from '~/assets/icons/user-profile-icon.svg';
import plusUploadImage from '~/assets/icons/plus-upload-image.svg';
import { web3store } from '~/store';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

interface IProfile {
  id: string;
  screen_name: string;
  rank: string;
  sponsor: string;
  earnings: string;
  referrals: string;
}

const UserProfile: React.FC = () => {
  const { user } = useAuth();
  const [show, setShow] = useState(false);
  const [profile, setProfile] = useState({} as IProfile);
  const [screenName, setScreenName] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [showChangeImg, setShowChangeImg] = useState(false);
  const [image, setImage] = useState('');
  const [imageChoise, setImageChoise] = useState('');

  const history = useHistory();

  const name = useMemo(() => user?.screen_name, [user]);

  useEffect(() => {
    api.get(`v1/member/profile/${user?.id}`).then((response) => {
      setProfile(response.data.profile);
      if (name) {
        setScreenName(name);
      }
    });
  }, [profile.earnings, name, user?.id]);

  useEffect(() => {
    const btnEdit = document.querySelector('.nft-number') as HTMLElement;
    if (btnEdit && disabled === false) {
      btnEdit.focus();
    }
  }, [disabled]);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleChangeImg = useCallback(() => {
    setImage('');
    setShowChangeImg(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowChangeImg(false);
    setImage('');
  }, []);

  const handleImg = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setImage(URL.createObjectURL(file));
    }
  }, []);

  const handleImageChoise = useCallback((e) => {
    setImageChoise(e);
  }, []);

  const handleUploadAgain = useCallback(() => {
    setImage('');
  }, []);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setScreenName(e.target.value);
  }, []);

  const handleDisabled = useCallback(() => {
    setDisabled(!disabled);
    if (disabled === false) {
      const formData = {
        profile: {
          screen_name: screenName,
        },
      };

      api.put(`v1/member/profile/${user?.id}`, formData).then((response) => {
        console.log('put');
      });
    }
  }, [disabled, screenName, user?.id]);

  const earnings = useMemo(() => {
    return {
      value: formatPrice(
        parseFloat((parseInt(profile.earnings, 10) / 10 ** 18).toFixed(2))
      ),
    };
  }, [profile.earnings]);

  return (
    <>
      <Container>
        <div className="pt-4 zoom position-absolute">
          <Link
            to={`${process.env.PUBLIC_URL}/dashboard`}
            className="d-flex align-items-center"
          >
            <BiArrowBack size={30} color="#7D7777" className="me-4" />
            Back Home
          </Link>
        </div>
        <div className="container h-100">
          <div className="row justify-content-between">
            <div className="col-5 zoom d-flex align-items-center mt-5">
              <div className="row">
                <div className="col-12 mt-5 mt-xxl-0">
                  <span className="d-block member-id">Member ID</span>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="d-block bg-transparent border-0 nft-number w-50"
                      onChange={handleChange}
                      value={screenName}
                      disabled={disabled}
                    />
                    <button
                      className="btn-edit mb-2 mt-auto"
                      type="submit"
                      onClick={handleDisabled}
                    >
                      <FiEdit3 size={20} color="#5A5B5B" className="me-2" />
                      {disabled === false && 'Save edit'}
                    </button>
                  </div>

                  <div className="d-flex mt-5">
                    <div className="w-50">
                      <div className="d-flex align-items-center mb-3">
                        <img src={star} alt="Star" className="pe-3" />
                        <p className="rank mb-0">
                          Rank <span className="refered">{profile.rank}</span>
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <img src={star} alt="Star" className="pe-3" />
                        <p className="rank mb-0">
                          Refered by{' '}
                          <span className="refered">{profile.sponsor}</span>
                        </p>
                      </div>
                    </div>
                    <div className="w-50">
                      <div className="d-flex align-items-center mb-3">
                        <img src={star} alt="Star" className="pe-3" />
                        <p className="rank mb-0">
                          Earnings{' '}
                          <span className="refered">${earnings.value}</span>
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <img src={star} alt="Star" className="pe-3" />
                        <p className="rank mb-0">
                          Total referrals{' '}
                          <span className="refered">{profile.referrals}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 my-5">
                  <span className="d-block member-id">Member</span>
                  <span className="d-block nft-number">PRODUCTS</span>
                </div>

                <div className="col-4 p-3 btn-image">
                  <button
                    type="button"
                    className="w-100 border-0 bg-transparent"
                    onClick={() => {
                      history.push(
                        `${process.env.PUBLIC_URL}/products/ai-art/generation`
                      );
                    }}
                  >
                    <img src={aiArt} alt="Ai Art Creator" className="w-100" />
                  </button>
                </div>

                <div className="col-4 p-3 btn-image">
                  <button
                    type="button"
                    className="w-100 border-0 bg-transparent"
                  >
                    <img
                      src={nftLaunchpad}
                      alt="Nft Launchpad"
                      className="w-100"
                    />
                  </button>
                </div>

                <div className="col-4 p-3 btn-image">
                  <button
                    type="button"
                    className="w-100 border-0 bg-transparent"
                    onClick={handleShow}
                  >
                    <img src={cube} alt="The cube" className="w-100" />
                  </button>
                </div>

                <div className="col-4 p-3 btn-image">
                  <button
                    type="button"
                    className="w-100 border-0 bg-transparent"
                  >
                    <img
                      src={aiDefi}
                      alt="Ai Defi Arbitrage"
                      className="w-100"
                    />
                  </button>
                </div>

                <div className="col-4 p-3 btn-image">
                  <button
                    type="button"
                    className="w-100 border-0 bg-transparent"
                  >
                    <img src={voyagers} alt="Voyagers Game" className="w-100" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="bg-right-side d-flex flex-column justify-content-center align-items-center">
                <div className="bg-nft p-2 position-relative">
                  <img
                    src={user?.nft_img}
                    alt="NFT Generated"
                    className="w-100 nft"
                  />
                  <div className="d-flex pt-4 pb-3 bg-serial-qrcode justify-content-center align-items-center">
                    <div className="w-60">
                      <span>
                        Serial #<br />
                        <span className="fw-bold">0000123</span>
                      </span>
                    </div>
                    <div className="w-30 text-end">
                      <img
                        src={qrCode}
                        alt="Qr Code"
                        className="w-75 qr-code bg-qrcode p-3"
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="mt-5 border-0"
                  onClick={handleChangeImg}
                >
                  <img src={iconNft} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-user-profile text-white d-flex"
        size="xl"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0 p-0 bg-close">
          <div className="d-flex justify-content-between w-100 header">
            <button
              type="button"
              className="h4 modal-close mb-0 pe-3 ms-auto border-0 bg-transparent"
              onClick={handleClose}
            >
              x
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <img
                  src={cubeLarge}
                  alt="Cube"
                  className="w-100 mt-n4 pb-1 mb-4"
                />
              </div>
              <div className="col-lg-7 ps-4">
                <div className="row">
                  <div className="col-12">
                    <img src={cubeTitle} alt="The cube" className="mt-4" />
                    <div className="d-flex mt-5">
                      <div className="w-50">
                        <div className="d-flex align-items-center mb-3">
                          <img src={star} alt="Star" className="pe-3" />
                          <p className="rank mb-0">
                            Game Plays <span className="refered">28</span>
                          </p>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          <img src={star} alt="Star" className="pe-3" />
                          <p className="rank mb-0">
                            Wins <span className="refered">7</span>
                          </p>
                        </div>
                      </div>
                      <div className="w-50">
                        <div className="d-flex align-items-center mb-3">
                          <img src={star} alt="Star" className="pe-3" />
                          <p className="rank mb-0">
                            Earnings <span className="refered">$100,878</span>
                          </p>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          <img src={star} alt="Star" className="pe-3" />
                          <p className="rank mb-0">
                            Rank <span className="refered">Master</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 avaliable mt-4">Available games</div>
                  <div className="col-4 ps-0 pt-3">
                    <button
                      type="button"
                      className="d-flex w-100 border-0 bg-btn p-1"
                      onClick={() =>
                        history.push(`${process.env.PUBLIC_URL}/game`)
                      }
                    >
                      <img src={cubeSmall} alt="Cube Small" />
                      <span className="d-block prize ps-3 text-start">
                        Prize
                        <br />
                        <span className="d-block prize-value">$50</span>
                      </span>
                    </button>
                  </div>
                  <div className="col-4 ps-0 pt-3">
                    <button
                      type="button"
                      className="d-flex w-100 border-0 bg-btn p-1"
                      onClick={() =>
                        history.push(`${process.env.PUBLIC_URL}/game`)
                      }
                    >
                      <img src={cubeSmall} alt="Cube Small" />
                      <span className="d-block prize ps-3 text-start">
                        Prize
                        <br />
                        <span className="d-block prize-value">$100</span>
                      </span>
                    </button>
                  </div>
                  <div className="col-4 ps-0 pt-3">
                    <button
                      type="button"
                      className="d-flex w-100 border-0 bg-btn p-1"
                      onClick={() =>
                        history.push(`${process.env.PUBLIC_URL}/game`)
                      }
                    >
                      <img src={cubeSmall} alt="Cube Small" />
                      <span className="d-block prize ps-3 text-start">
                        Prize
                        <br />
                        <span className="d-block prize-value">$150</span>
                      </span>
                    </button>
                  </div>
                  <div className="col-4 ps-0 pt-3">
                    <button
                      type="button"
                      className="d-flex w-100 border-0 bg-btn p-1"
                      onClick={() =>
                        history.push(`${process.env.PUBLIC_URL}/game`)
                      }
                    >
                      <img src={cubeSmall} alt="Cube Small" />
                      <span className="d-block prize ps-3 text-start">
                        Prize
                        <br />
                        <span className="d-block prize-value">$500</span>
                      </span>
                    </button>
                  </div>
                  <div className="col-4 ps-0 pt-3">
                    <button
                      type="button"
                      className="d-flex w-100 border-0 bg-btn p-1"
                      onClick={() =>
                        history.push(`${process.env.PUBLIC_URL}/game`)
                      }
                    >
                      <img src={cubeSmall} alt="Cube Small" />
                      <span className="d-block prize ps-3 text-start">
                        Prize
                        <br />
                        <span className="d-block prize-value">$1000</span>
                      </span>
                    </button>
                  </div>
                  <div className="col-4 ps-0 pt-3">
                    <button
                      type="button"
                      className="d-flex w-100 border-0 bg-btn p-1"
                      onClick={() =>
                        history.push(`${process.env.PUBLIC_URL}/game`)
                      }
                    >
                      <img src={cubeSmall} alt="Cube Small" />
                      <span className="d-block prize ps-3 text-start">
                        Prize
                        <br />
                        <span className="d-block prize-value">$5000</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ModalChangeNft
        className="modal-img-upload text-white d-flex"
        size="xl"
        show={showChangeImg}
        onHide={handleClose}
      >
        <Modal.Body className="p-0">
          <div className="container h-100 p-0">
            <div className="row h-100 justify-content-center">
              <div className="col-12 text-center">
                {image ? (
                  <div className="pb-5 preview text-center">
                    <img src={image} alt="image preview to upload" />
                    <p className="mt-5 mb-1">Does it look good?</p>
                    <button
                      type="button"
                      className="mt-4 mb-1 py-3 border-0 fw-bold w-75"
                      onClick={() => {
                        handleImageChoise(image);
                        handleClose();
                      }}
                    >
                      Yes,&nbsp;let'd&nbsp;continue
                    </button>
                    <button
                      type="button"
                      className="mt-4 border-0 bg-transparent d-block mx-auto"
                      onClick={handleUploadAgain}
                    >
                      Upload again
                    </button>
                  </div>
                ) : (
                  <>
                    <button
                      type="button"
                      className="modal-close fw-bold position-absolute border-0 bg-transparent"
                      onClick={handleClose}
                    >
                      x
                    </button>
                    <form className="h-100 py-5">
                      <label
                        htmlFor="picture_file"
                        className="py-5 h-100 d-flex flex-column justify-content-center"
                      >
                        <img
                          src={plusUploadImage}
                          className="icon-plus mb-4"
                          alt="Plus icon"
                        />
                        <p>Upload Image</p>

                        <input
                          type="file"
                          id="picture_file"
                          className="d-none"
                          onChange={handleImg}
                          accept="image/*"
                        />
                      </label>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </ModalChangeNft>
    </>
  );
};

export default UserProfile;
