import React from 'react';
import { FiLock, FiHeart } from 'react-icons/fi';
import { BiPlus } from 'react-icons/bi';

import { Container } from './styles';

interface IModal {
  onClick(): void;
}

const Public: React.FC<IModal> = ({ onClick }) => {
  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="d-flex align-items-center mb-4">
              <span className="ms-2 pt-1">Public collections</span>
            </h3>
          </div>

          {/* <div className="col-md-3 mt-2">
            <div>
              <button
                onClick={onClick}
                type="button"
                className="btn-create bg-transparent d-flex px-5 pt-5 w-100"
              >
                <BiPlus color="#dadada" size={25} className="me-3" /> Create
                Collection
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </Container>
  );
};

export default Public;
