import React, { useCallback, useState, useEffect } from 'react';
import { ethers, utils, BigNumber } from 'ethers';
import { BiArrowBack } from 'react-icons/bi';
import { HiWifi } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import arrow from '~/assets/icons/arrow-bottom.svg';
import cubeTitle from '~/assets/defaults/the-cube-title.svg';
import { Container } from './styles';
import logoSwap from '~/assets/logo/logo-swap.png';
import logoPurple from '~/assets/logo/logo-p-purple.svg';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';
import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';
import {
  getConfigAddress,
  getConfigNumber,
  getConfigString,
} from '~/utils/configContract';
import { web3store } from '../../../store';

interface Cube {
  cube_id: number;
  balance: number;
  cube_max_players: number;
  cube_payoff: number;
  entry_fee: number;
  free_available: number;
}

interface IActiveCubes {
  cubes: Cube[];
}

interface ICubeProps {
  selectedCube: Cube | null;
}

interface WithdrawalRequest {
  nftId: number;
  userAddress: string;
  message: string;
  deadline: number;
  transaction_fee: BigNumber;
}

interface Player {
  id: number;
  cubeID: number;
  nftID: number;
  avatar: string;
  name: string;
  value: number;
  isMe: boolean;
}

export function formatUSDValue(valueInWei: number): string {
  // Convert Wei to Ether
  const valueInEther = utils.formatUnits(valueInWei.toString(), 'ether');
  // Assuming 1 Ether = $100, replace with your actual conversion rate
  const usdValue = parseFloat(valueInEther);
  return `$${usdValue.toFixed(2)}`;
}

/* eslint no-underscore-dangle: 0 */
// const CurrentPayout: React.FC<ICubeProps> = ({ selectedCube }) => {
const CurrentPayout: React.FC<ICubeProps & { players: Player[] }> = ({
  selectedCube,
  players,
}) => {
  const { user } = useAuth();
  // const CurrentPayout: React.FC = () => {
  const [activeCube, setActiveCube] = useState(true);
  const [manualAuto, setManualAuto] = useState('manual');
  // const [playsAvaliable, setPlaysAvaliable] = useState(0);
  const [selectedValue, setSelectedValue] = useState<number | null>(1); // Default selected value
  const [account, setAccount] = web3store.useState('account');
  const [balusdpi] = web3store.useState('balusdpi');
  // const [players, setPlayers] = useState<Player[]>([]);
  const playersTest = [
    { id: 1, avatar: logoPurple, name: 'NFT 093', value: '5.0' },
    { id: 2, avatar: logoPurple, name: 'NFT 883', value: '5.0' },
    { id: 3, avatar: logoPurple, name: 'NFT 112', value: '5.0' },
    { id: 4, avatar: logoPurple, name: 'NFT 008', value: '5.0' },
    { id: 5, avatar: logoPurple, name: 'NFT 656', value: '5.0' },
    { id: 6, avatar: logoPurple, name: 'NFT 320', value: '5.0' },
  ];

  const [colorTest, setColorTest] = useState(true);

  const handleActiveCube = useCallback(() => {
    setActiveCube(!activeCube);
  }, [activeCube]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const joinCubeFromAcct = useCallback(
    (cube: Cube, fee: string): Promise<string> => {
      return new Promise((resolve, reject) => {
        if (user && account) {
          networkSwitch('NETWORK')
            .then((netResponse) => {
              // console.log(netResponse);
              getConfigAddress('contract_cubepay_v1') // ToDo: change for production
                .then((configResult) => {
                  const cubePay = configResult?.toString();
                  const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
                  // console.log('deadline: %s', deadline);
                  const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
                  const chainInt = parseInt(chainNo || '0', 16);
                  // console.log(chainInt);
                  const domain = {
                    name: 'CubePay',
                    version: '1',
                    chainId: chainInt,
                    verifyingContract: cubePay,
                  };
                  // console.log('++++++++++++++++++++++++++++++++++++++++++');
                  // console.log(domain);
                  // console.log('++++++++++++++++++++++++++++++++++++++++++');
                  const types = {
                    Request: [
                      { name: 'nftId', type: 'uint256' },
                      { name: 'userAddress', type: 'address' },
                      { name: 'message', type: 'string' },
                      { name: 'deadline', type: 'uint256' },
                      { name: 'transaction_fee', type: 'uint256' },
                    ],
                  };
                  const displayAmt = cube.entry_fee / 10 ** 18;
                  const transaction_fee = BigNumber.from(fee);
                  const transaction_fee_in_ether = parseFloat(
                    ethers.utils.formatEther(transaction_fee)
                  );
                  const message = `I authorize this bid of $${displayAmt.toFixed(
                    2
                  )}, from funds in my Cube account.`;
                  // console.log(message);
                  const request: WithdrawalRequest = {
                    nftId: parseInt(user.id, 10),
                    userAddress: account,
                    message,
                    deadline,
                    transaction_fee: BigNumber.from(fee),
                  };
                  const tprovider = new ethers.providers.Web3Provider(
                    window.ethereum
                  );
                  // setProcessText('Waiting for signature from wallet.');
                  const tsigner = tprovider.getSigner();
                  tsigner
                    ._signTypedData(domain, types, request)
                    .then((sig) => {
                      // xxxx
                      /// console.log(sig);
                      api
                        .post('/v1/cube/join/account', {
                          tokenID: user?.id,
                          cubeID: cube.cube_id,
                          amt: cube.entry_fee,
                          fee,
                          message,
                          deadline,
                          signature: sig,
                          owner: account,
                        })
                        .then((acctResponse) => {
                          // console.log(acctResponse);
                          resolve('ok');
                        })
                        .catch((acctError) => {
                          reject(acctError);
                        });
                    })
                    .catch((sigerror) => {
                      // Handle any errors that occur during the function call
                      console.log(sigerror);
                      // setLoading(false);
                    });
                })
                .catch((purserError) => {
                  console.log(purserError);
                  // setLoading(false);
                  // swalError({
                  //   message:
                  //     'Sorry, there was a problem processing your withdrawal',
                  //   textButton: 'Close',
                  // });
                });
            })
            .catch((error) => {
              console.log(error);
              reject(error);
              // setLoading(false);
            });
        }
      });
    },
    [user, account]
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const joinCubeFromMemberAcct = useCallback(
    (cube: Cube, fee: string): Promise<string> => {
      return new Promise((resolve, reject) => {
        if (user && account) {
          networkSwitch('NETWORK')
            .then((netResponse) => {
              // console.log(netResponse);
              getConfigAddress('contract_cubepay_v1') // ToDo: change for production
                .then((configResult) => {
                  const cubePay = configResult?.toString();
                  // console.log('+++++ cubePay: %s +++++', cubePay);
                  const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
                  // console.log('deadline: %s', deadline);
                  const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
                  const chainInt = parseInt(chainNo || '0', 16);
                  // console.log(chainInt);
                  const domain = {
                    name: 'CubePay',
                    version: '1',
                    chainId: chainInt,
                    verifyingContract: cubePay,
                  };
                  // console.log('++++++++++++++++++++++++++++++++++++++++++');
                  // console.log(domain);
                  // console.log('++++++++++++++++++++++++++++++++++++++++++');
                  const types = {
                    Request: [
                      { name: 'nftId', type: 'uint256' },
                      { name: 'userAddress', type: 'address' },
                      { name: 'message', type: 'string' },
                      { name: 'deadline', type: 'uint256' },
                      { name: 'transaction_fee', type: 'uint256' },
                    ],
                  };
                  const displayAmt = cube.entry_fee / 10 ** 18;
                  const transaction_fee = BigNumber.from(fee);
                  const transaction_fee_in_ether = parseFloat(
                    ethers.utils.formatEther(transaction_fee)
                  );
                  const message = `I authorize this bid of $${displayAmt.toFixed(
                    2
                  )}, from funds in my Member account.`;
                  // console.log(message);
                  const request: WithdrawalRequest = {
                    nftId: parseInt(user.id, 10),
                    userAddress: account,
                    message,
                    deadline,
                    transaction_fee: BigNumber.from(fee),
                  };
                  const tprovider = new ethers.providers.Web3Provider(
                    window.ethereum
                  );
                  // setProcessText('Waiting for signature from wallet.');
                  const tsigner = tprovider.getSigner();
                  tsigner
                    ._signTypedData(domain, types, request)
                    .then((sig) => {
                      // console.log(sig);
                      // console.log('+++++ account: %s +++++', account);
                      api
                        .post('/v1/cube/join/member', {
                          tokenID: user?.id,
                          cubeID: cube.cube_id,
                          amt: cube.entry_fee,
                          fee,
                          message,
                          deadline,
                          signature: sig,
                          owner: account,
                        })
                        .then((acctResponse) => {
                          // console.log(acctResponse);
                          resolve('ok');
                        })
                        .catch((acctError) => {
                          reject(acctError);
                        });
                    })
                    .catch((sigerror) => {
                      // Handle any errors that occur during the function call
                      console.log(sigerror);
                      // setLoading(false);
                    });
                })
                .catch((purserError) => {
                  console.log(purserError);
                  // setLoading(false);
                  // swalError({
                  //   message:
                  //     'Sorry, there was a problem processing your withdrawal',
                  //   textButton: 'Close',
                  // });
                });
            })
            .catch((error) => {
              console.log(error);
              reject(error);
              // setLoading(false);
            });
        }
      });
    },
    [user, account]
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const joinCubeFromWallet = (cube: Cube, fee: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (user && account) {
        networkSwitch('NETWORK')
          .then((netResponse) => {
            // console.log(netResponse);
            getConfigAddress('contract_cubepay_v1')
              .then((verifyingContract) => {
                if (verifyingContract != null) {
                  // console.log('verifyingContract: %s', verifyingContract);
                  const usdpi2Send = cube.entry_fee + parseInt(fee, 10);
                  // console.log('usdpi2Send: %s', usdpi2Send);
                  api
                    .get('/v1/cube/fund/', {
                      params: {
                        wallet_address: account,
                        amt2authorize: usdpi2Send,
                      },
                    })
                    .then((response) => {
                      // console.log(response.data);
                      const {
                        deadline,
                        amt2authorize,
                        nonce,
                        tokname,
                        chain,
                        spender,
                      } = response.data;
                      const version = '1';
                      const domainData = {
                        name: tokname,
                        version,
                        chainId: chain,
                        verifyingContract: process.env.REACT_APP_BSC_USDPI,
                      };
                      const types = {
                        Permit: [
                          {
                            name: 'owner',
                            type: 'address',
                          },
                          {
                            name: 'spender',
                            type: 'address',
                          },
                          {
                            name: 'value',
                            type: 'uint256',
                          },
                          {
                            name: 'nonce',
                            type: 'uint256',
                          },
                          {
                            name: 'deadline',
                            type: 'uint256',
                          },
                        ],
                      };
                      const val = {
                        owner: account,
                        spender,
                        value: amt2authorize,
                        nonce,
                        deadline,
                      };
                      // console.log('+++++++++++++++++++');
                      // console.log(domainData);
                      // console.log(types);
                      // console.log(val);
                      // console.log('+++++++++++++++++++');
                      const tprovider = new ethers.providers.Web3Provider(
                        window.ethereum
                      );
                      const tsigner = tprovider.getSigner();
                      tsigner
                        ._signTypedData(domainData, types, val)
                        .then((signature) => {
                          // console.log(signature);
                          api
                            .post('/v1/cube/fund', {
                              owner: account,
                              amt: amt2authorize,
                              deadline,
                              sig: signature,
                              nft_id: user.id,
                              cube_id: selectedCube?.cube_id,
                              fee,
                            })
                            .then((fundResult) => {
                              // console.log(fundResult);
                              // console.log(fundResult.data.tx);
                              // fetchHistory();
                              // fetchSubscription();
                              // setShowFundAccountWallet(false);
                              // setLoading(false);
                              // setDepositTX(fundResult.data.tx);
                              // setShowFinish(true);
                            })
                            .catch((fundError) => {
                              console.log(fundError);
                              // setLoading(false);
                              // swalError({
                              //   message:
                              //     'There was a problem sending your transaction to the blockchain',
                              //   textButton: 'Try Again',
                              // });
                            });
                        })
                        .catch((sigError) => {
                          console.log(sigError);
                        });
                    })
                    .catch((error) => {
                      console.log(error);
                      reject(error);
                    });
                }
              })
              .catch((purserError) => {
                console.log(purserError);
              });
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            // setLoading(false);
          });
      }
    });
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const joinCubeFromFreePlay = useCallback(
    (cube: Cube, fee: string): Promise<string> => {
      return new Promise((resolve, reject) => {
        if (user && account) {
          networkSwitch('NETWORK')
            .then((netResponse) => {
              console.log(netResponse);
              getConfigAddress('contract_cubesys_v1') // ToDo: change for production
                .then((configResult) => {
                  const purser = configResult?.toString();
                  const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
                  console.log('deadline: %s', deadline);
                  const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
                  const chainInt = parseInt(chainNo || '0', 16);
                  console.log(chainInt);
                  const domain = {
                    name: 'CubePay',
                    version: '1',
                    chainId: chainInt,
                    verifyingContract: purser,
                  };
                  console.log('++++++++++++++++++++++++++++++++++++++++++');
                  console.log(domain);
                  console.log('++++++++++++++++++++++++++++++++++++++++++');
                  const types = {
                    Request: [
                      { name: 'nftId', type: 'uint256' },
                      { name: 'userAddress', type: 'address' },
                      { name: 'message', type: 'string' },
                      { name: 'deadline', type: 'uint256' },
                      { name: 'transaction_fee', type: 'uint256' },
                    ],
                  };
                  const displayAmt = cube.entry_fee / 10 ** 18;
                  const transaction_fee = BigNumber.from(fee);
                  const transaction_fee_in_ether = parseFloat(
                    ethers.utils.formatEther(transaction_fee)
                  );
                  const message = 'I authorize this free play attempt.';
                  console.log(message);
                  const request: WithdrawalRequest = {
                    nftId: parseInt(user.id, 10),
                    userAddress: account,
                    message,
                    deadline,
                    transaction_fee: BigNumber.from(fee),
                  };
                  const tprovider = new ethers.providers.Web3Provider(
                    window.ethereum
                  );
                  // setProcessText('Waiting for signature from wallet.');
                  const tsigner = tprovider.getSigner();
                  tsigner
                    ._signTypedData(domain, types, request)
                    .then((sig) => {
                      console.log(sig);
                      api
                        .post('/v1/cube/join/free', {
                          tokenID: user?.id,
                          cubeID: cube.cube_id,
                          amt: cube.entry_fee,
                          fee,
                          message,
                          deadline,
                          signature: sig,
                        })
                        .then((acctResponse) => {
                          console.log(acctResponse);
                          resolve('ok');
                        })
                        .catch((acctError) => {
                          reject(acctError);
                        });
                    })
                    .catch((sigerror) => {
                      // Handle any errors that occur during the function call
                      console.log(sigerror);
                      // setLoading(false);
                    });
                })
                .catch((purserError) => {
                  console.log(purserError);
                  // setLoading(false);
                  // swalError({
                  //   message:
                  //     'Sorry, there was a problem processing your withdrawal',
                  //   textButton: 'Close',
                  // });
                });
            })
            .catch((error) => {
              console.log(error);
              reject(error);
              // setLoading(false);
            });
        }
      });
    },
    [user, account]
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleFreePlay = useCallback(async () => {
    if (user && selectedCube) {
      try {
        const response = await api.get(
          `v1/cube/freecount/${selectedCube.cube_id}`
        );
        console.log(response.data);
        if (response.data.available > 0) {
          joinCubeFromFreePlay(selectedCube, '0') // response.data.fee
            .then(() => {
              console.log('OK');
            })
            .catch((freeError) => {
              console.log(freeError);
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [selectedCube, user]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handlePlay = useCallback(async () => {
    // Get play type
    if (user && selectedCube) {
      try {
        const response = await api.get(
          `v1/cube/playtype/${user.id}/${selectedCube.entry_fee}`
        );
        // console.log(response.data);
        if (response.data.type === 'G') {
          // console.log('Funding from game');
          joinCubeFromAcct(selectedCube, response.data.fee)
            .then((joinResponseG) => {
              // console.log(joinResponseG);
            })
            .catch((joinErrorG) => {
              // console.log(joinErrorG);
            });
        } else if (response.data.type === 'A') {
          // console.log('Funding from member account');
          joinCubeFromMemberAcct(selectedCube, response.data.fee) // prod
            // joinCubeFromWallet(selectedCube, response.data.fee) // test
            .then((joinResponseG) => {
              // x
            })
            .catch((joinErrorG) => {
              // console.log(joinErrorG);
            });
        } else if (response.data.type === 'W') {
          // console.log('Funding from wallet');
          joinCubeFromWallet(selectedCube, response.data.fee)
            .then((joinResponseG) => {
              // x
            })
            .catch((joinErrorG) => {
              console.log(joinErrorG);
            });
        } else {
          console.log('Unknown funding source');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [selectedCube, user]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleManualAuto = useCallback((e) => {
    setManualAuto(e);
  }, []);

  // useEffect(() => {
  //   console.log('+++++ players change detected +++++');
  //   console.log(players);
  // }, [players]);

  if (selectedCube == null) {
    return null;
  }

  return (
    <Container className="pb-4">
      <div className="d-flex justify-content-between px-4 pt-4 btn-back">
        <Link
          to={`${process.env.PUBLIC_URL}/dashboard`}
          className="d-flex align-items-center"
        >
          <BiArrowBack size={30} color="#7D7777" className="me-4" />
          Back Home
        </Link>
        <button
          type="button"
          onClick={() => setColorTest(!colorTest)}
          className="btn-wifi"
        >
          <HiWifi color={colorTest ? '#00F9AB' : '#FF1A50'} size={18} />
        </button>
      </div>
      <div className="d-flex justify-content-between pb-2 px-4 mt-4">
        <img src={cubeTitle} alt="Cube title" />
        <button
          type="button"
          onClick={handleActiveCube}
          className={`${
            !activeCube && 'rotateActive'
          } bg-transparent border-0 arrow`}
        >
          <img src={arrow} alt="Arrow" />
        </button>
      </div>
      {activeCube && (
        <div className="px-4 px-xxl-4 pt-5">
          <div className="d-flex">
            <span className="d-block payout">Minimum Payout |</span>
            <span className="payout-cube pr-2">
              {selectedCube.entry_fee / 10 ** 18} X {}
              {selectedCube.cube_payoff / selectedCube.entry_fee}
            </span>
          </div>
          <span className="d-block payout-value">
            {formatUSDValue(selectedCube.cube_payoff)}
          </span>
          <div className="manual-auto my-4">
            <button
              type="button"
              className={`${
                manualAuto === 'manual' && 'manual-auto-active'
              } w-50`}
              onClick={() => handleManualAuto('manual')}
            >
              <span>Manual</span>
            </button>
            <button
              type="button"
              className={`${
                manualAuto === 'auto' && 'manual-auto-active'
              } w-50`}
              onClick={() => handleManualAuto('auto')}
            >
              <span>Auto</span>
            </button>
          </div>
          {manualAuto === 'manual' && (
            <>
              <div className="d-flex entry justify-content-between mb-3">
                <span className="h6 text-white">Entry Amount</span>
                <span className="h6">
                  {formatUSDValue(selectedCube.entry_fee)}
                </span>
              </div>
              <div className="d-flex bg-value justify-content-between align-items-center mb-3">
                <img src={logoSwap} alt="Image Value" className="m-2" />
                <span className="h5 text-white mb-0 pe-4">
                  {formatUSDValue(selectedCube.entry_fee)}
                </span>
              </div>
              <button
                type="button"
                className="w-100 bg-gradient-place mb-4"
                onClick={handlePlay}
              >
                PLACE BID
              </button>
              <div className="ps-3 mb-3">
                <span className="h6 avaliable">Free Plays Available: </span>
                <span className="h6 text-white">
                  {selectedCube.free_available}
                  {/* <small>&nbsp;(network fees apply)</small> */}
                </span>
              </div>
              <button
                type="button"
                onClick={handleFreePlay}
                className={`${
                  selectedCube.free_available <= 0
                    ? 'notActive'
                    : 'bg-free-play'
                } w-100 mb-5`}
              >
                FREE PLAY
              </button>
              <div className="d-flex entry justify-content-between mb-3">
                {/* <span className="h6 text-white">{players.length} Players</span> */}
                <span className="h6 text-white">
                  {players.length === 1
                    ? `${players.length} Player`
                    : `${players.length} Players`}
                </span>
                <span className="h6 text-white">&nbsp;</span>
                <span className="h6">
                  {formatUSDValue(selectedCube.balance)}
                </span>
              </div>
              <div className="overflow-auto height pe-1">
                {players.length > 0 ? (
                  players.map((item) => (
                    <div
                      key={item.id}
                      // className="d-flex align-items-center border-gray py-1 px-2 mt-1"
                      className={`d-flex align-items-center ${
                        item.nftID.toString() === user?.id
                          ? 'border-me'
                          : 'border-gray'
                      } py-1 px-2 mt-1`}
                    >
                      <img
                        src={item.avatar}
                        alt=""
                        className="avatar rounded-circle me-4"
                      />
                      <div className="w-50">
                        <span className="h6 text-gray">{item.name}</span>
                      </div>
                      <div className="w-25 text-end">
                        <span className="h6 text-gray">-</span>
                      </div>
                      <div className="w-25 text-end">
                        <span className="h6 text-white">
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(item.value / 10 ** 18)}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">No players...yet</div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </Container>
  );
};

export default CurrentPayout;
