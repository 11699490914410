import React from 'react';
import { BsArrowLeftShort } from 'react-icons/bs';

import { Container } from './styles';

interface IProps {
  className?: string;
  onClick(): void;
}

const ButtonBack: React.FC<IProps> = ({ onClick, className }) => {
  return (
    <Container
      type="button"
      onClick={onClick}
      className={`btn-back arrow d-flex align-items-center ${className}`}
    >
      <BsArrowLeftShort size={30} />
      <span className="mr-2 d-block">Back</span>
    </Container>
  );
};

export default ButtonBack;
