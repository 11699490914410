import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import prev from '~/assets/icons/slick-prev.svg';
import next from '~/assets/icons/slick-next.svg';
import bgProfile from '~/assets/banners/bg-dashboard.png';

interface CopyAlertProps {
  active: boolean;
}

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  .desk-small {
    p {
      font-size: 18px;
    }
  }
  .pad-earnings {
    padding-bottom: 1.8rem !important;
    padding-top: 1.8rem !important;
  }
  .text-color {
    color: #e4e4e4;
  }

  .slick-dots {
    display: flex !important;
    justify-content: end !important;
    z-index: 100;
    padding-right: 30px;
  }

  .slick-dots li.slick-active button:before {
    color: #cbccce !important;
  }

  .slick-dots li button:before {
    color: #4f4f4f !important;
  }

  .slick-dots li {
    margin: 0 !important;
  }

  .slick-prev {
    right: 80px;
    left: auto;
  }

  .slick-next {
    right: 5px;
  }

  .slick-prev,
  .slick-next {
    top: calc(100% + 15px);
    z-index: 200;
  }

  .slick-prev:before {
    content: url(${prev}) !important;
  }

  .slick-next:before {
    content: url(${next}) !important;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #8a8a8a;
    font-size: 0;
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    .height-mob {
      font-size: 0.9vw;
    }

    .img-search {
      height: 20px !important;
    }

    .img-small {
      height: 2vw !important;
    }

    .desk-small {
      img {
        height: 3vw;
      }
      p,
      input {
        font-size: 1vw;
      }
      small {
        font-size: 1vw;
      }
    }

    .btn-small {
      font-size: 1vw !important;
    }
  }

  @media screen and (max-width: 340px) {
    .slick-dots {
      justify-content: center !important;
      padding-right: 0px;
    }

    .slick-prev {
      right: 200px;
      left: auto;
    }

    .slick-next {
      right: 0px;
    }
  }
`;

export const AllCourses = styled.div``;

export const Welcome = styled.div`
  h1 {
    color: #dadada;
  }

  p {
    color: #969696;
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }
`;

export const Content = styled.div`
  background: #1d2023;
  border-radius: 20px;
  padding: 20px;
  .btn-view {
    position: absolute;
    bottom: 1.25rem;
    right: 1.25rem;
  }
  .min-h {
    min-height: 350px;
  }

  .click {
    font-weight: 300;
    font-size: 15px !important;
    line-height: 18px;
    border-radius: 12px !important;
    color: #dadada !important;
    small {
      font-size: 15px !important;
      color: #dadada !important;
    }
  }

  .padding-copy {
    padding-top: 13.5px !important;
    padding-bottom: 13.5px !important;
  }

  .box-profile {
    background: #222527;
    border-radius: 15px;
    padding: 20px;
  }

  .box-profile p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #8d8f90;
  }

  .bg-dashboard {
    background-image: url(${bgProfile});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    height: 117px;
  }

  .avatar-position {
    left: calc(50% - 30px);
    bottom: -30px;
  }

  .nft-name,
  .new-member {
    color: #dadada;
    padding: 8px 22px;
    border-radius: 10px;
    background: rgba(13, 13, 13, 0.28);
    backdrop-filter: blur(2px);
  }

  .nft-name {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }
  .new-member {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .title-chart {
    h3,
    p {
      color: #cbccce;
    }
  }

  .input-members input {
    height: 34px;
  }

  input {
    color: #cbccce;
  }

  .bg-gray {
    background: #222527;
    border-radius: 15px;
    padding: 10px 20px;

    h3,
    p {
      color: #cbccce;
    }

    div {
      border-color: #474848 !important;
    }
  }

  .volume-centers {
    width: 70px;
  }

  .icons {
    width: 30px;
    height: 30px;
  }

  .text-green {
    color: #37c694 !important;
  }

  .text-red {
    color: #ff1a50 !important;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 12px;
  }

  .text-blue {
    color: #10c3fc;
  }

  .border-gray {
    border: 1px solid #4f4f4f;
    border-radius: 15px;
    span {
      font-size: 20px !important;
    }
    p {
      font-size: 11px;
    }
  }

  .avatar-user {
    height: 60px;
    width: 60px;
    padding: 3px;
    background-image: linear-gradient(180deg, #242526, #242526),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 20px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    cursor: unset;
  }

  .my-avatar {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.24));
    border-radius: 15px;
    padding: 3px;
  }

  .btn-default {
    border: 1px solid #333333;
    color: #8d8f90;
    padding: 3px 0;
    border-radius: 8px;
    width: 125px;
    transition-duration: 0.3s;

    :hover {
      border-color: #fff;
      color: #fff;
    }

    :focus {
      outline: none;
      box-shadow: none;
    }
  }

  .bg-light-gray {
    background: #333638;
  }

  .border-light-gray {
    border: 1px solid #7e7e7e;
  }

  @media screen and (max-width: 575px) {
    .img-height {
      height: 46px;
    }
  }
`;

export const Graph = styled.div`
  background: #222527;
  border-radius: 20px;
  padding: 20px;

  .btn-dropdown {
    background: #1d2023;
    border: 1px solid #2b2e31;
    border-radius: 100px;
    font-weight: 300;
    font-size: 14px;
    color: #747474;
    padding: 0px 20px;
    height: 40px;
  }

  .Dropdown-menu {
    background: #1d2023;
    color: #747474;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid #2b2e31;
  }

  .Dropdown-option.is-selected,
  .Dropdown-option:hover {
    background-color: #222527;
  }

  .Dropdown-option {
    color: #747474;
  }

  .Dropdown-control {
    background: #1d2023;
    color: #747474;
    border: none;
    font-weight: 300;
    font-size: 14px;
    padding: 8px 25px 8px 10px;
    width: 109px;
  }

  .Dropdown-arrow {
    top: 17px;
    right: 2px;
  }

  .total span:nth-child(1) {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #dadada;
  }

  .total span:nth-child(3) {
    font-weight: 300;
    font-size: 59px;
    line-height: 60px;
    color: #00f9ab;
  }

  @media screen and (max-width: 991px) {
    padding: 10px 0;
  }
`;

export const CopyAlert = styled.div<CopyAlertProps>`
  background: #37c694;
  position: fixed;
  transition-duration: 0.3s;
  bottom: 10px;
  right: ${(props) => (props.active ? '10px' : '-1000px')};
  opacity: ${(props) => (props.active ? '1' : '0')};

  p {
    color: #fdfdfd;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-body {
    h2 {
      font-size: 48px;
    }

    p {
      color: #9f9f9f;
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
    }

    .coin img {
      width: 100%;
      max-width: 745px;
    }
  }

  .modal-close {
    position: absolute;
    right: 15px;
    top: 5px;
    z-index: 10000;
    color: #dadada;
    height: 50px;
    width: 50px;
  }

  .border-gradient-light {
    cursor: unset;
    .padding {
      padding: 1px;
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: top;
`;
