import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

import { Container } from './styles';

const Attention: React.FC = () => {
  return (
    <Container className="bg-danger">
      <div className="container">
        <div className="row">
          <div className="col zoom">
            <h2 className="h6 h5-sm h4-xl text-white mb-0 py-3 text-center">
              <FaExclamationCircle />
              <b className="ms-3">ATTENTION: </b>
              You{' '}
              <b className="font-weight-600">
                <u>Must Watch This Video</u>
              </b>{' '}
              To Complete Your Order!
            </h2>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Attention;
