import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(152, 148, 148, 0.1);
  box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(15px);
  border-radius: 30px;

  .btn-back {
    z-index: 2;
  }
  .btn-back a {
    font-weight: 300;
    font-size: 16px;
    color: #7d7777;
  }

  .btn-wifi {
    border-radius: 13px;
    background: rgba(152, 148, 148, 0.1);
    border: none;
    backdrop-filter: blur(15px);
    width: 34px;
    height: 34px;
  }

  .payout {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-right: 5px;
  }

  .payout-cube {
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .payout-value {
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: #ffffff;
  }

  .manual-auto button {
    cursor: pointer;
    border: none;
    padding: 0 0 2px 0;
    background-image: linear-gradient(180deg, #100f0f, #100f0f);
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;

    transition-duration: 0.3s;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #7d7777;
    span {
      padding: 5px 10px;
      display: block;
    }
    :hover {
      background-image: linear-gradient(180deg, #100f0f, #100f0f),
        linear-gradient(
          239.23deg,
          #9df3ff 7.84%,
          #bcffe5 28.62%,
          #deefd1 38.94%,
          #ffe0be 49.96%,
          #fbc2d3 64.82%,
          #fabcd6 67.63%,
          #f281ff 91.88%
        ) !important;
      color: #fff;
    }
  }

  .manual-auto-active {
    background-image: linear-gradient(180deg, #100f0f, #100f0f),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    color: #fff !important;
  }

  .entry :nth-child(2) {
    color: #878585;
  }

  .bg-value {
    background: rgba(24, 25, 26, 0.85);
    box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
  }

  .bg-value img {
    width: 42px;
    height: 42px;
  }

  .bg-gradient-place {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 22px;
    font-weight: 600;
    font-size: 20px;
    padding: 15px 0;
    color: #000000;
  }

  .bg-free-play {
    background: #00f9ab;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 22px;
    font-weight: 600;
    font-size: 20px;
    padding: 15px 0;
    color: #000000;
  }

  .notActive {
    background: #333;
    border: 2px solid #333;
    border-radius: 22px;
    font-weight: 600;
    font-size: 20px;
    padding: 15px 0;
    color: #000000;
    cursor: not-allowed;
  }

  .avaliable {
    color: #c8c8c8;
  }

  .border-gray {
    border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 10px;
  }

  .border-me {
    border-radius: 10px;
    border: 1px solid var(--Gradient-2, #9df3ff);
    box-shadow: 3px 4px 32px 0px rgba(0, 0, 0, 0.54);
    backdrop-filter: blur(15px);
  }

  .avatar {
    height: 19px;
    width: 19px;
    padding: 1px;
    background-image: linear-gradient(180deg, #242526, #242526),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 15px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    cursor: unset;
  }

  .text-gray {
    color: #7d7777;
  }

  .height {
    max-height: 230px;
  }
`;
