import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  span {
    color: #dadada;
  }

  p {
    color: #8c8c8c;
    font-weight: 400;
  }

  .container-fluid {
    max-width: 1800px;
  }

  .history-collec {
    font-weight: 400;
    font-size: 20px;
    color: #8c8c8c;
    transition-duration: 0.3s;
    :hover {
      color: ${lighten(0.2, '#8c8c8c')};
    }
  }

  .active {
    color: #dadada !important;
  }

  .btn-up-image {
    font-weight: 400;
    font-size: 16px;
    color: #dadada;
  }

  .border-underline {
    border: none;
    padding: 0 0 2px 0;
    background-image: linear-gradient(180deg, #18191a, #18191a),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;

    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .resolution div {
    color: #8d8d8d;
    font-size: 18px;
    font-weight: 500;
  }

  .bg-surprise {
    background-color: #1d2023;
  }

  .border-gradient {
    background-image: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    ) !important;
  }

  .border-gradient span {
    font-weight: 600;
    font-size: 18px;
    background-color: #18191a;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    margin: 1px;
  }

  .border-gradient button {
    font-size: 18px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    margin: 1px 1px 1px 0;
    color: #18191a;
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    transition: all 1s ease;
    :hover {
      background: linear-gradient(
        239.23deg,
        #f281ff 7.84%,
        #fabcd6 28.62%,
        #fbc2d3 38.94%,
        #ffe0be 49.96%,
        #deefd1 64.82%,
        #bcffe5 67.63%,
        #9df3ff 91.88%
      );
    }
  }

  .border-gradient textarea {
    flex: auto;
    resize: none;
    color: #dadada;
    font-size: 18px;
    margin: 1px 0 1px 0;
    background-color: #18191a;
  }
`;
