import styled from 'styled-components';
import banner from '~/assets/banners/nft-lp.png';
import bannerLg from '~/assets/banners/nft-lp-large.png';
import paint from '~/assets/banners/nft-lp-paint.png';
import rings from '~/assets/banners/nft-lp-rings.png';

interface IWidthScreen {
  width: number;
}

export const Container = styled.div`
  h1 {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 400;
    font-size: 180px;
    line-height: 143px;
    letter-spacing: -0.04em;
    color: #fff;
    //padding: 2.5rem 1.5rem;
    span {
      font-family: 'Expletus Sans', sans-serif !important;
    }
  }

  h2 {
    font-family: 'Source Sans Pro', sans-serif;

    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: #d6d6d6;
  }

  .first-bubble h3 {
    font-family: 'Bebas Neue', sans-serif;

    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #dadada;
  }

  .second-bubble h3 {
    font-family: 'Source Sans Pro', sans-serif;

    font-weight: 300;
    font-size: 25px;
    line-height: 31px;

    color: #dadada;
    span {
      font-weight: 700;
    }
  }

  .rings {
    background: url(${rings});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    //padding-top: 10%;
    height: 85vh;
  }

  .banner {
    background: url(${banner});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
  }

  .text-grad {
    background: linear-gradient(90.42deg, #ea82ec 2.48%, #df504c 109.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .bg-nft {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border: 1px solid #fff;
    border-radius: 40px;
    //height: 470px;
    width: 100%;
  }

  .bg-nft img {
    //height: 70%;
  }

  .bg-nft span {
    color: #fffefb;
    font-size: 30px;
    line-height: 37px;
  }

  .bg-serial-qrcode {
    position: absolute;
    bottom: 0px;
    left: 0px;

    background-color: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(7.5px);

    border-radius: 0px 0px 35px 35px;
  }

  .bg-qrcode {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.62);
    border-radius: 25px;
  }

  .nft {
    //filter: drop-shadow(5px 11px 140px rgba(0, 0, 0, 0.25));
    border-radius: 35px;
  }

  .right-side {
    width: 55%;
    margin: auto;
  }

  .text-get-free {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 400;
    font-size: 60px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    color: transparent;
    span {
      font-family: 'Expletus Sans', sans-serif;
    }
  }

  .bg-main {
    background: rgba(23, 23, 25, 0.46);
    box-shadow: 0px -15px 30px rgba(0, 0, 0, 0.265139);
    backdrop-filter: blur(5px);
    border-radius: 37px 37px 0px 0px;
    width: 100%;
  }

  .btn-mint {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 22px;
    font-weight: 600;
    padding: 22px 49px;
    font-size: 24px;
    color: #000;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }
  }

  .bottom {
    bottom: 0px;

    max-width: 1700px;
  }

  @media screen and (min-width: 1300px) {
    .zoom {
      zoom: 0.7;
    }

    .rings {
      height: 100vh;
    }

    .right-side {
      width: 45%;
    }
  }

  @media screen and (min-width: 1600px) {
    .container {
      max-width: 1500px;
    }

    .zoom {
      zoom: 0.7;
    }

    .right-side {
      width: 55%;
    }
  }

  @media screen and (min-width: 1800px) {
    .container {
      max-width: 1700px;
    }

    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1900px) {
    .zoom {
      zoom: 1;
    }

    .rings {
      height: 85vh;
    }
  }
`;

export const Retangle = styled.div<IWidthScreen>`
  background: rgba(255, 255, 255, 0.1);
  border-left: 1px solid #ffffff;
  backdrop-filter: blur(5px);
  width: 34%;
  //width: calc(${(props) => props.width - 1920 + 615}px);

  height: 100vh;
  position: absolute;
  right: 0;
  bottom: 0;

  @media screen and (min-width: 2200px) {
    width: 36%;
  }
`;
