import styled from 'styled-components';
import bannerLg from '~/assets/banners/nft-lp-large.png';
import paint from '~/assets/banners/nft-lp-paint-no-blur.png';

import allProducts from '~/assets/banners/bg-all-products.png';

export const Container = styled.div`
  .container {
    max-width: 1800px;
  }

  @media screen and (min-width: 1300px) {
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1800px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1900px) {
    .zoom {
      zoom: 1;
    }
  }
`;

export const Retangle = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-left: 1px solid #ffffff;
  backdrop-filter: blur(5px);
  width: 33%;

  height: 950px;
  position: absolute;
  right: 0;
  top: 0;

  img {
    position: absolute;
    top: 50px;
    right: 40px;
  }

  @media screen and (min-width: 2200px) {
    width: 36%;
  }
`;

export const RoadMap = styled.div`
  background: url(${paint}) center, url(${bannerLg}) left;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 400;
    font-size: 180px;
    line-height: 143px;
    letter-spacing: -0.04em;
    color: #fff;
    padding-top: 6rem;
    span {
      font-family: 'Expletus Sans', sans-serif !important;
    }
  }

  h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #d6d6d6;
    width: 60%;
    padding-top: 3rem;
  }

  .stamp {
    position: relative;
    z-index: 1;
    background-color: #121212;
    padding-bottom: 45px;
  }

  .stamp img {
    margin-top: -80px;
    padding-left: calc(67% - 72px);
  }

  .stamp hr {
    border-top: 14px solid #f1f1fc;
    opacity: 1;
    margin: 0;
  }

  .second-bubble {
    padding: 6rem 0;
  }

  .second-bubble h3 {
    font-family: 'Source Sans Pro', sans-serif;
    width: 60%;
    font-weight: 300;
    font-size: 25px;
    line-height: 31px;

    color: #dadada;
    span {
      font-weight: 700;
    }
  }

  .bottom {
    bottom: 0px;

    max-width: 1700px;
  }

  @media screen and (min-width: 1900px) {
    padding-top: 2rem;

    .second-bubble {
      padding: 8rem 0;
    }
  }

  @media screen and (min-width: 2000px) {
    background-size: unset;
  }

  @media screen and (min-width: 2200px) {
    .stamp img {
      padding-left: calc(64% - 72px);
    }
  }
`;

export const Community = styled.div`
  background-color: #121212;

  h2 {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 400;
    font-size: 60px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    color: transparent;
  }

  h3 {
    font-family: 'Bebas Neue';
    font-weight: 400;
    font-size: 120px;
    line-height: 143px;
    letter-spacing: -0.04em;

    color: #ffffff;
  }

  p {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-size: 28px;
    color: #d6d6d6;
  }

  .products img {
    padding: 0 13px;
  }

  .future img {
    margin-bottom: -309px;
  }

  .height {
    height: 173px;
  }
`;

export const ArtCreator = styled.div`
  background-color: #18191a;

  padding-top: 250px;
  padding-bottom: 100px;

  p {
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 20px;
    line-height: 134.08%;
    letter-spacing: 0.02em;
    color: #dadada;
    margin-bottom: 2rem;

    span {
      font-weight: 600;
    }
  }

  @media screen and (min-width: 1800px) {
    padding-top: 390px;
  }
`;

export const NftLaunchpad = styled.div`
  background-color: #121212;

  padding-top: 100px;
  padding-bottom: 100px;

  p {
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 20px;
    line-height: 134.08%;
    letter-spacing: 0.02em;
    color: #dadada;
    margin-bottom: 2rem;

    span {
      font-weight: 600;
    }
  }
`;

export const Paige = styled.div`
  background-color: #18191a;

  padding-top: 100px;
  padding-bottom: 100px;

  p {
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 20px;
    line-height: 134.08%;
    letter-spacing: 0.02em;
    color: #dadada;

    span {
      font-weight: 600;
    }
  }

  .paragraph p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 134.08%;
    color: #dadada;
    span {
      font-weight: 700;
    }
  }
`;

export const Cube = styled.div`
  background-color: #121212;

  padding-top: 100px;
  padding-bottom: 100px;

  p {
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 20px;
    line-height: 134.08%;
    letter-spacing: 0.02em;
    color: #dadada;
    margin-bottom: 2rem;

    span {
      font-weight: 600;
    }
  }
`;

export const Meta = styled.div`
  background-color: #18191a;

  padding-top: 100px;
  padding-bottom: 100px;

  p {
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 20px;
    line-height: 134.08%;
    letter-spacing: 0.02em;
    color: #dadada;
    margin-bottom: 2rem;

    span {
      font-weight: 600;
    }
  }
`;

export const Defi = styled.div`
  background-color: #121212;

  padding-top: 100px;
  padding-bottom: 100px;

  p {
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 20px;
    line-height: 134.08%;
    letter-spacing: 0.02em;
    color: #dadada;
    margin-bottom: 2rem;

    span {
      font-weight: 600;
    }
  }
`;

export const Ideas = styled.div`
  background-color: #fff;

  padding-top: 225px;
  padding-bottom: 160px;

  .texts {
    border: 8px solid #000000;
    border-radius: 40px;
  }

  .texts img:nth-child(1) {
    margin-top: -99px;
  }

  .texts p:nth-child(2) {
    font-family: 'Quicksand';

    font-weight: 400;
    font-size: 28px;
    color: #000000;
    margin: 6rem 0;
  }

  .texts p:nth-child(4) {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 48px;
    color: #000000;
    margin-top: 6rem;
    margin-bottom: calc(6rem + 70px);
    span {
      font-weight: 600;
    }
  }
`;

export const Final = styled.div`
  background-color: #18191a;

  padding-top: 170px;
  padding-bottom: 170px;

  .bg {
    background-color: #121212;
    border-radius: 65px;
    padding: 50px;
    margin-top: 160px;
  }

  .bg-products {
    background-image: url(${allProducts});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 426px;
  }

  .bg-products h2 {
    display: initial;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .bg-products h3 {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 56px;
    line-height: 60px;
    color: #f3f4f8;
  }

  .bg-products p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #b1b1b5;
  }

  .logo {
    margin-top: 200px;
  }

  .title h2:nth-child(1) {
    font-family: 'Quicksand';
    font-weight: 400;
    font-size: 28px;

    color: #ffffff;
  }

  .title h2:nth-child(2) {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 400;
    font-size: 50px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    color: transparent;
    margin-bottom: 0;
  }

  .title h2:nth-child(3) {
    font-family: 'Bebas Neue';
    font-weight: 400;
    font-size: 83px;

    color: #ffffff;
  }
`;
