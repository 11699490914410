import React, { useCallback, useState } from 'react';

import { Banner } from './styles';

import LiveChat from './LiveChat';
import Player from '~/components/Player';
import retangle from '~/assets/defaults/thumbnail-opportunity-rectangle.png';
import thumbnail from '~/assets/defaults/thumb-discover.png';
import logoPurple from '~/assets/logo/logo-p-purple.svg';
import arrow from '~/assets/icons/arrow-bottom.svg';

const Discover: React.FC = () => {
  const [activeChat, setActiveChat] = useState(true);

  const handleActiveChat = useCallback(() => {
    setActiveChat(!activeChat);
  }, [activeChat]);
  return (
    <Banner>
      <div className="container position-relative container-zindex pt-4">
        <div className="row">
          <div className="col-12 d-flex justify-content-between position-absolute mt-3 px-5">
            <img src={logoPurple} alt="Logo" className="ms-3" />{' '}
            <button
              type="button"
              className={`${activeChat && 'd-none'} btn-arrow`}
              onClick={handleActiveChat}
            >
              <img src={arrow} alt="Arrow" />
            </button>
          </div>
        </div>
        <div className={`row ${!activeChat && 'justify-content-center'}`}>
          <div className="col-lg-9 px-lg-5">
            <div className="row px-lg-5 mb-2 mb-lg-4">
              <div className="col-1" />
              <div className="col-11 zoom">
                <h1
                  className={`${!activeChat && 'text-center'} h2 display-lg-4`}
                >
                  <span>Discover The Digital Revolution</span>
                </h1>

                <p
                  className={`${
                    !activeChat && 'text-center'
                  } gradient-parts h5 my-4 my-lg-2`}
                >
                  <span>
                    And the exact steps you need to know, to{' '}
                    <span className="fw-bold">position yourself today</span> in
                    what will be the best and most{' '}
                    <span className="fw-bold">lucrative&nbsp;opportunity</span>{' '}
                    of the decade.
                  </span>{' '}
                  <b>The next 15 minutes will change everything.</b>
                </p>
              </div>
            </div>

            <div className="row justify-content-center px-lg-5">
              <div className="col-12 position-relative">
                <img src={retangle} alt="" className="back-retangle-right" />
                <img src={retangle} alt="" className="back-retangle-left" />

                <Player
                  src="https://prfti.s3.amazonaws.com/1.mp4"
                  thumbnail={thumbnail}
                />
              </div>
            </div>

            <div className="row justify-content-center px-lg-5 mt-4">
              <div className="col-11 zoom btn-claim">
                <button type="button" className="w-100 mt-4 mt-lg-0">
                  <b>ACTIVATE YOUR NFT ACCOUNT NOW</b>
                </button>
              </div>
            </div>
          </div>
          {activeChat && (
            <div className="col-lg-3">
              <LiveChat active={activeChat} onClick={handleActiveChat} />
            </div>
          )}
        </div>
      </div>
    </Banner>
  );
};

export default Discover;
