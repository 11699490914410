import React, { useRef, useCallback, useState, useEffect } from 'react';
import party from 'party-js';
import { Banner, Video, Modal, Maintenance, ToastAlert } from './styles';
import CurrentPayout from './CurrentPayout';
import WinStats from './WinStats';
import MoreCubes from './MoreCubes';
import LiveChat from './LiveChat';
import WalletRow from '~/components/WalletRow';
import { web3store } from '~/store';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';
import RevealEffect from '~/components/RevealEffect';
import logo from '~/assets/logo/logo-p-purple.svg';
import winner from '~/assets/defaults/winner.png';
import cubeLogo from '~/assets/defaults/cube-small.png';
import backSoon from '~/assets/defaults/back-soon.svg';

interface Cube {
  cube_id: number;
  balance: number;
  cube_max_players: number;
  cube_payoff: number;
  entry_fee: number;
  free_available: number;
  percent_filled: number;
  paid_user: number;
  is_progressive: boolean;
}

interface CubeHistory {
  cube_id: number;
  cube_description: string;
  entry_fee: string;
  paid_date: string;
  paid_user: string;
  cube_payoff: string;
}

interface IActiveCubes {
  cubes: Cube[];
}

interface IHistoryCubes {
  cubes: CubeHistory[];
}

interface Player {
  id: number;
  cubeID: number;
  nftID: number;
  avatar: string;
  name: string;
  value: number;
  isMe: boolean;
}

interface ApiPlayerResponse {
  players: Player[];
}

interface WinStats {
  play_count: number;
  win_count: number;
  amt_won: number;
}

interface PlayHistory {
  id: number;
  trans_type: string;
  amount: number;
  transaction_date: number;
  cube_id: number;
  cube_type: string;
}

const Game: React.FC = () => {
  const { user } = useAuth();
  const [activeCubes, setActiveCubes] = useState<IActiveCubes | null>(null);
  const [cubeWinners, setCubeWinners] = useState<IHistoryCubes | null>(null);
  const [selectedCube, setSelectedCube] = useState<Cube | null>(null);
  const [processText, setProcessText] = web3store.useState('processText');
  const [loading, setLoading] = useState(false);
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');
  const serverUrl = 'wss://socket.pcode.pro';
  const [ws, setWs] = useState<WebSocket | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [loopStartTime, setLoopStartTime] = useState(0);
  const [loopEndTime, setLoopEndTime] = useState(4.9); // Set a default end time
  const [selectedCubeID, setSelectedCubeID] = useState(0);
  const [players, setPlayers] = useState<Player[]>([]);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  // TOAST
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('WIN');
  const [toastLine1, setToastLine1] = useState('WINNER ALERT');
  const [toastLine2, setToastLine2] = useState('Did you win $1,000?');
  const [toastLine3, setToastLine3] = useState('on a 10x10 Cube');
  const [toastAnimation, setToastAnimation] = useState('');
  const [toastURL, setToastURL] = useState('');
  // TOAST
  const [isLoop, setIsLoop] = useState(true);
  const [showWinner, setShowWinner] = useState(false);
  const [showWinnerModal, setShowWinnerModal] = useState(false);
  const [showNft, setShowNft] = useState(true);
  const cardRef = useRef<HTMLDivElement>(null);
  const [imgURL, setImgURL] = useState('');
  const [winnerURL, setWinnerURL] = useState('');
  const [winnerNFT, setWinnerNFT] = useState(0);
  const [messageIn, setMessageIn] = useState();
  const [postWinnerDelete, setPostWinnerDelete] = useState(null);
  const [cubeBidder, setCubeBidder] = useState<number | null>(null);
  const showMaintenance = process.env.REACT_APP_CUBE_MAINT as string;

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (showToast) {
      setToastAnimation('roll-in');

      const timer = setTimeout(() => {
        setToastAnimation('roll-out');
      }, 10000);

      return () => clearTimeout(timer);
    }
    // Explicitly return undefined
    return undefined;
  }, [showToast]);

  useEffect(() => {
    if (toastAnimation === 'roll-out') {
      const hideTimer = setTimeout(() => {
        setShowToast(false);
      }, 500); // Assuming the animation duration is 500ms

      return () => clearTimeout(hideTimer);
    }
    // Explicitly return undefined
    return undefined;
  }, [toastAnimation]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const showToastAlert = useCallback(
    (type: string, amt: number, cType: string, nft: number, bidURL: string) => {
      console.log('+++++ showToastAlert +++++');
      if (type === 'WIN' && amt > 0 && cType !== '') {
        //
        setToastType('WIN');
        setToastLine1('WINNER ALERT');
        setToastLine2(`Did you win $${amt}`);
        setToastLine3(`on a ${cType} Cube`);
        setShowToast(true);
      } else if (type === 'BID' && nft > 0 && amt > 0 && cType !== '') {
        //
        setToastType('BID');
        setToastLine1(`NFT-${nft}`);
        setToastLine2(`Just bid $${amt}`);
        setToastLine3(`on a ${cType} Cube`);
        setToastURL(bidURL);
        setShowToast(true);
      } else {
        console.log('showToastAlert - parameter error');
      }
    },
    [showToast, toastType, toastLine1, toastLine2, toastLine3, toastAnimation]
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Load players for cube
  // ++++++++++++++++++++++++++++++++++++++
  const getPlayers = useCallback(async () => {
    if (selectedCube && user) {
      if (selectedCube.cube_id > 0) {
        try {
          const response = await api.get('v1/cube/players', {
            params: {
              cubeID: selectedCube.cube_id,
              nftID: user.id,
            },
          });
          const apiPlayers: Player[] = response.data.players;
          setPlayers(apiPlayers);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [selectedCube, user, players]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Set the selected cube (called from morecubes)
  // ++++++++++++++++++++++++++++++++++++++
  const selectCube = (cube: Cube) => {
    // Create a shallow copy of the cube parameter
    const copiedCube = { ...cube };

    setSelectedCubeID(copiedCube.cube_id);
    setSelectedCube(copiedCube); // Use the copiedCube in setSelectedCube
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Clear cubeBidder
  // ++++++++++++++++++++++++++++++++++++++
  const clearCubeBidder = () => {
    setCubeBidder(null);
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Clean up after winner announced
  // ++++++++++++++++++++++++++++++++++++++
  const handleCloseWinner = useCallback(() => {
    setShowWinner(false);
    setShowNft(false);
    setLoopStartTime(0);
    setLoopEndTime(4.9);
    setIsAutoPlay(true);
    setIsLoop(true);
    setWinnerURL('');
    setWinnerNFT(0);
    if (activeCubes) {
      const cubesWithoutClosed = activeCubes.cubes.filter(
        (cube) => cube.cube_id !== postWinnerDelete
      );
      setActiveCubes({ cubes: cubesWithoutClosed });
      setPostWinnerDelete(null);
      selectCube(activeCubes.cubes[0]);
    } else {
      console.log('+++++ No activeCubes +++++');
    }
    if (videoRef && videoRef.current) {
      videoRef.current.play();
    }
    setTimeout(() => {
      setImgURL('');
    }, 2000);
  }, [setImgURL, postWinnerDelete, activeCubes]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Load players when cube selected
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (selectedCube) {
      getPlayers();
    }
  }, [selectedCube]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Control video loop
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = loopStartTime;
    }
  }, [loopStartTime]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Control video loop
  // ++++++++++++++++++++++++++++++++++++++
  const handleTimeUpdate = () => {
    if (videoRef.current) {
      if (videoRef.current.currentTime >= loopEndTime) {
        videoRef.current.currentTime = loopStartTime;
      }
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Get active cubes (used on mount)
  // ++++++++++++++++++++++++++++++++++++++
  const fetchActiveCubes = useCallback(async () => {
    try {
      const response = await api.get('v1/cube/active');
      const activeCubesData = response.data;
      // Map the data to match the IActiveCubes structure
      const mappedCubes: IActiveCubes = {
        cubes: activeCubesData.map((cube: Cube) => ({
          cube_id: cube.cube_id,
          balance: cube.balance,
          cube_max_players: cube.cube_max_players,
          cube_payoff: cube.cube_payoff,
          entry_fee: cube.entry_fee,
          free_available: cube.free_available,
          percent_filled: (cube.balance / cube.cube_payoff) * 100,
          is_progressive: cube.is_progressive,
        })),
      };
      selectCube(mappedCubes.cubes[0]);
      setActiveCubes(mappedCubes);
    } catch (error) {
      console.log('Error fetching active cubes:', error);
    }
  }, [selectedCubeID, activeCubes]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Get cube history (used on mount)
  // ++++++++++++++++++++++++++++++++++++++
  const fetchCubeWinners = useCallback(async () => {
    console.log('+++++ fetchCubeWinners +++++');
    try {
      const response = await api.get('v1/cube/winners');
      const winnersCubesData = response.data;
      // Map the data to match the IActiveCubes structure
      const mappedCubes: IHistoryCubes = {
        cubes: winnersCubesData.map((cube: CubeHistory) => ({
          cube_id: cube.cube_id,
          cube_description: cube.cube_description,
          entry_fee: cube.entry_fee,
          paid_date: cube.paid_date,
          paid_user: cube.paid_user,
          cube_payoff: cube.cube_payoff,
        })),
      };
      setCubeWinners(mappedCubes);
    } catch (error) {
      console.log('Error fetching active cubes:', error);
    }
  }, [cubeWinners]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Process incoming websocket messages
  // ++++++++++++++++++++++++++++++++++++++
  const processMessage = useCallback(
    (message) => {
      setMessageIn(message);
      if (activeCubes) {
        if (message.class === 'CUBE') {
          // console.log('CUBE EVENT');
          if (message.event.type === 'UPDATE') {
            setCubeBidder(message.event.nftID);
            const updatedCube = message.event.cube;
            const updatedCubeID = message.event.cubeID;
            const newActiveCubes = { ...activeCubes }; // Shallow copy of activeCubes
            if (message.event.winnerURL !== '') {
              // Winner path
              if (updatedCubeID === selectedCube?.cube_id) {
                // Handle winning cube selected
                setWinnerURL(message.event.winnerURL);
                setWinnerNFT(message.event.cube.paid_user);
                setPostWinnerDelete(updatedCubeID);
                setShowWinnerModal(true);
              } else {
                // payoff
                const payoff = message.event.cube.cube_payoff / 10 ** 18;
                const entry = message.event.cube.entry_fee / 10 ** 18;
                const playerCnt = payoff / entry;
                setWinnerURL(message.event.winnerURL);
                setWinnerNFT(message.event.cube.paid_user);
                showToastAlert('WIN', payoff, `${entry}x${playerCnt}`, 0, '');
              }
              // Remove winning cube
              newActiveCubes.cubes = newActiveCubes.cubes.filter(
                (cube) => cube.cube_id !== updatedCubeID
              );
            } else {
              // No winner - update cube record
              let cubeFound = false;
              newActiveCubes.cubes = newActiveCubes.cubes.map((cube) => {
                if (cube.cube_id === updatedCubeID) {
                  cubeFound = true;
                  return {
                    ...cube,
                    balance: updatedCube.balance,
                    free_available: updatedCube.free_available,
                    percent_filled:
                      (updatedCube.balance / updatedCube.cube_payoff) * 100,
                    paid_user: updatedCube.paid_user,
                  };
                }
                return cube;
              });
              if (cubeFound) {
                setPlayers(message.event.players);
              }
              // payoff
              const payoff = message.event.cube.cube_payoff / 10 ** 18;
              console.log('+++++ payoff: %s +++++', payoff);
              const entry = message.event.cube.entry_fee / 10 ** 18;
              console.log('+++++ entry: %s +++++', entry);
              const playerCnt = payoff / entry;
              console.log('+++++ playerCnt: %s +++++', playerCnt);
              showToastAlert(
                'BID',
                entry,
                `${entry}x${playerCnt}`,
                message.event.bidder,
                message.event.bidderURL
              );
            }
            // Check for new cube
            if (Object.keys(message.event.new_cube).length > 0) {
              const newCube: Cube = {
                ...message.event.new_cube,
              };
              newActiveCubes.cubes.push(newCube);
            }
            setActiveCubes(newActiveCubes);
          }
        }
      }
      setMessageIn(undefined);
    },
    [activeCubes, selectedCube]
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Detect incoming websocket message
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (messageIn) {
      // console.log('messageIn change');
      console.log(messageIn);
      // console.log(activeCubes);
      const newMessage = messageIn;
      processMessage(newMessage);
    }
  }, [messageIn, activeCubes]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Open connection to websocket server on mount
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    // Fetch the active cubes when the component mounts
    fetchActiveCubes();
    fetchCubeWinners();
    const websocket = new WebSocket(serverUrl);
    setWs(websocket);
    return () => {
      if (websocket) {
        websocket.close();
      }
    };
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Set up websocket event listeners
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    // Create event handlers for the WebSocket when ws is set
    if (ws) {
      ws.onopen = (event) => {
        console.log('WebSocket connection opened');
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        setMessageIn(message);
      };

      ws.onclose = (event) => {
        console.log('WebSocket connection closed', event);
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    }
  }, [ws]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // calculate the percentage filled when players.length changes
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    let start = 0;
    let end = 4.9;
    if (selectedCube) {
      // Calculate the percentage filled
      const maxPlayers = selectedCube?.cube_payoff / selectedCube?.entry_fee; // Maximum number of players
      const currentPlayers = players.length; // Current number of players
      const calculatedPercentage = (currentPlayers / maxPlayers) * 100;
      // console.log('calculatedPercentage: %s', calculatedPercentage);
      // Set the percentageFilled state with the calculated percentage
      // setPercentageFilled(calculatedPercentage);

      // Calculate start and end times based on the percentage
      if (calculatedPercentage >= 0 && calculatedPercentage <= 14) {
        start = 0;
        end = 4.9;
      } else if (calculatedPercentage > 14 && calculatedPercentage <= 28) {
        start = 5;
        end = 10;
      } else if (calculatedPercentage > 28 && calculatedPercentage <= 42) {
        start = 10;
        end = 15;
      } else if (calculatedPercentage > 42 && calculatedPercentage <= 56) {
        start = 15;
        end = 20;
      } else if (calculatedPercentage > 56 && calculatedPercentage <= 70) {
        start = 20;
        end = 25;
      } else if (calculatedPercentage > 70 && calculatedPercentage <= 84) {
        start = 25;
        end = 30;
      } else if (calculatedPercentage > 84 && calculatedPercentage < 100) {
        start = 30;
        end = 35;
      }
    }
    // console.log('start: %s end: %s', start, end);
    setLoopStartTime(start);
    setLoopEndTime(end);
  }, [players.length, selectedCube]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleShowWinner = useCallback(() => {
    console.log('+++++ REVEAL WINNER +++++');
    setShowWinner(true);
    setTimeout(() => {
      if (cardRef.current) {
        party.confetti(cardRef.current, {
          count: 50,
          size: 2,
        });
      }
      setShowNft(true);
    }, 2000);
  }, [winnerURL]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Reveal winner after video ends
  // ++++++++++++++++++++++++++++++++++++++
  const handleVideoEnded = () => {
    // console.log('Video has ended');
    handleShowWinner();
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Play winner animation
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (showWinnerModal && winnerURL !== '') {
      console.log('+++++ WINNER ANIMATION +++++');
      setLoopStartTime(35);
      setLoopEndTime(40);
      setIsAutoPlay(false);
      setIsLoop(false);
    }
  }, [showWinnerModal, winnerURL]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Reveal winner after click on win toast
  // ++++++++++++++++++++++++++++++++++++++
  const handleWinClick = () => {
    setShowWinnerModal(true);
  };
  const handleWinClickOld = useCallback(() => {
    console.log('+++++ REVEAL WINNER +++++');
    setShowWinner(true);
    setTimeout(() => {
      if (cardRef.current) {
        party.confetti(cardRef.current, {
          count: 50,
          size: 2,
        });
      }
      setShowNft(true);
    }, 2000);
  }, [winnerURL]);

  return (
    <>
      <Banner>
        <Video
          autoPlay={isAutoPlay}
          loop={isLoop}
          muted
          className="bg_video"
          onEnded={handleVideoEnded}
          onTimeUpdate={handleTimeUpdate}
          ref={videoRef}
        >
          <source
            src="https://s3.us-east-1.amazonaws.com/livplus.io/videos/cube_2_final.mp4"
            type="video/mp4"
          />
        </Video>
        <div className="container-fluid position-relative container-zindex pt-4 ">
          {/* <div className="row">
            <div className="col-lg-2 ms-auto">
              <WalletRow />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-3 zoom">
              <CurrentPayout selectedCube={selectedCube} players={players} />
            </div>
            <div className="col-lg-3 zoom">
              <WinStats
                cubeBidder={cubeBidder}
                clearBidderFunction={clearCubeBidder}
              />
            </div>
            <div className="col-lg-3 zoom">
              <MoreCubes
                cubes={activeCubes ? { cubes: [...activeCubes.cubes] } : null}
                winners={cubeWinners ? { cubes: [...cubeWinners.cubes] } : null}
                selectFunction={selectCube}
                selected={selectedCubeID}
              />
            </div>
            {/* <div className="d-flex justify-content-center"> */}
            {/* <div className="col-lg-3">
              <LiveChat />
            </div> */}
            <div className="col-lg-3 ms-auto wallet">
              <WalletRow />
            </div>
          </div>
        </div>
      </Banner>
      <Modal
        className="modal-claim-nft text-white d-flex"
        size="xl"
        show={showWinner}
        showNft={showNft}
        onHide={() => {
          // handleHistory(valueInput);
          handleCloseWinner();
        }}
      >
        <RevealEffect
          className="d-none d-lg-block reveal-w"
          // initializeScene={showWinner}
          initializeScene
        />
        <Modal.Header className="border-0 p-3 pb-0">
          <div className="d-flex justify-content-between w-100 header">
            <img src={logo} alt="Logo" className="" />
            <button
              type="button"
              className="h4 modal-close mb-0 ms-auto border-0 bg-transparent"
              onClick={() => {
                handleCloseWinner();
              }}
            >
              x
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-9 col-lg-6 d-flex justify-content-center">
                <div className="item-group">
                  {!loading && (
                    <div ref={cardRef} className="bg-nft p-2 position-relative">
                      <img src={winnerURL} alt="Winner" className="w-100 nft" />
                      <div className="d-flex pt-3 pb-4 bg-serial-qrcode justify-content-center align-items-center">
                        <div className="text-center">
                          <span>NFT.{winnerNFT}</span>
                          <br />
                          <img src={winner} alt="Winner" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-9 col-lg-5 px-4 pt-5 mt-5">
                <div className="d-flex mx-auto py-3 justify-content-evenly bg-main px-3">
                  {/* <button
                    type="button"
                    onClick={handleCloseWinner}
                    className="w-50 btn-play-again me-2"
                  >
                    Play Again
                  </button> */}
                  <button
                    type="button"
                    className="w-100 btn-play-again ms-2"
                    onClick={handleCloseWinner}
                  >
                    PLAY AGAIN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Maintenance
        className="modal-claim-nft d-flex"
        size="xl"
        show={showMaintenance === 'ON'}
      >
        <Modal.Body className="py-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="image-container">
                <div className="maintenance-text-1">
                  We’re performing some maintenance at the moment, sorry for the
                  inconvenience.
                </div>
                <img
                  src={backSoon}
                  alt="Back Soon"
                  className="back-soon-image"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Maintenance>
      {showToast && (
        <ToastAlert className={toastAnimation}>
          <ToastAlert.Body
            onClick={toastType === 'WIN' ? handleWinClick : undefined}
          >
            <img
              src={toastType === 'BID' ? toastURL : cubeLogo}
              alt="xx"
              className="toast-img"
            />
            <div
              className={`flex-div action ${
                toastType === 'BID' ? 'bid' : 'win'
              }`}
            >
              {toastType}
            </div>
            <div className="flex-div info">
              <div className="line1">{toastLine1}</div>
              <div className={`line2 ${toastType === 'BID' ? 'bid' : 'win'}`}>
                {toastLine2}
              </div>
              <div className="line3">{toastLine3}</div>
              {toastType === 'WIN' && (
                <div className="line4">CLICK TO FIND OUT</div>
              )}
            </div>
          </ToastAlert.Body>
        </ToastAlert>
      )}
    </>
  );
};

export default Game;
