import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';
import {
  Banner,
  Card,
  Modal,
  RangeCentersYearly,
  RangeCentersMonthly,
  RangeNetworkYearly,
  RangeNetworkMonthly,
} from './styles';
import Timeline from '~/components/Timeline';
import Resum from '~/components/Resum';
import { formatPrice } from '~/utils/format';

import api from '~/services/api';
import { useResize } from '~/hooks/Resize';
import { web3store } from '~/store';
import ButtonBack from '~/components/ButtonBack';

interface IDemoEarning {
  5: string;
  30: string;
  155: string;
  780: string;
  3905: string;
  19530: string;
  FINAL: string;
}

interface IDemoEarnings {
  earnings: {
    annual: IDemoEarning[];
    monthly: IDemoEarning[];
  };
}

interface IParams {
  slug: string;
}

const Order: React.FC = () => {
  const history = useHistory();
  const params = useParams<IParams>();
  const { width } = useResize();
  const [activePayment, setActivePayment] = useState(false);
  const [percentage, setPercentage] = useState(200);
  const [btnYearlyMonthly, setBtnYearlyMonthly] = useState('yearly');
  const [cartProfitCenters, setCartProfitCenters] =
    web3store.useState('cartProfitCenters');
  const [cartPrepayMonths, setCartPrepayMonths] =
    web3store.useState('cartPrepayMonths');
  const [show, setShow] = useState(false);
  const [percentageBarCenterYearly, setPercentageBarCenterYearly] = useState(1);
  const [percentageBarNetworkYearly, setPercentageBarNetworkYearly] =
    useState(0);

  const [percentageBarCenterMonthly, setPercentageBarCenterMonthly] =
    useState(1);
  const [percentageBarNetworkMonthly, setPercentageBarNetworkMonthly] =
    useState(0);

  const [multiple, setMultiple] = useState(12);
  const [demoEarnings, setDemoEarnings] = useState({} as IDemoEarnings);
  const [demoEarningPrice, setDemoEarningPrice] = useState('$0');
  const [connectedMember] = web3store.useState('connectedMember');

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        toolbar: {
          show: false,
        },
      },

      fill: {
        type: 'solid',
        colors: ['#9DF3FF', '#884BFD'],
      },

      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        width: 0,
      },
      tooltip: {
        enabled: false,
      },
    };

    return data;
  }, []);

  const series = useMemo(() => {
    let total = 0;
    let costPercent = 0;
    let earningPercent = 0;
    const demoEarning = parseFloat(
      demoEarningPrice.replace('$', '').replaceAll(',', '')
    );
    if (btnYearlyMonthly === 'yearly') {
      total = percentageBarCenterYearly * 50 * 12 + demoEarning;
      costPercent = (percentageBarCenterYearly * 50 * 12 * 100) / total;
      earningPercent = (demoEarning * 100) / total;
    } else {
      total = percentageBarCenterMonthly * 50 + demoEarning;
      costPercent = (percentageBarCenterMonthly * 50 * 100) / total;
      earningPercent = (demoEarning * 100) / total;
    }
    costPercent = costPercent <= 5 ? 5 : costPercent;
    costPercent = costPercent >= 95 ? 95 : costPercent;
    earningPercent = earningPercent <= 5 ? 5 : earningPercent;
    earningPercent = earningPercent >= 95 ? 95 : earningPercent;

    return [costPercent, earningPercent];
  }, [
    btnYearlyMonthly,
    demoEarningPrice,
    percentageBarCenterMonthly,
    percentageBarCenterYearly,
  ]);

  useEffect(() => {
    api.get('v1/demo/earnings').then((response) => {
      setDemoEarnings(response.data);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(demoEarnings).length > 0) {
      let networkSize: 5 | 30 | 155 | 780 | 3905 | 19530 | 'FINAL' = 5;
      let data = '0';
      if (btnYearlyMonthly === 'yearly') {
        switch (percentageBarNetworkYearly) {
          case 1:
            networkSize = 30;
            break;

          case 2:
            networkSize = 155;
            break;

          case 3:
            networkSize = 780;
            break;

          case 4:
            networkSize = 3905;
            break;

          case 5:
            networkSize = 19530;
            break;

          case 6:
            networkSize = 'FINAL';
            break;

          default:
            networkSize = 5;
            break;
        }

        data =
          demoEarnings.earnings.annual[percentageBarCenterYearly][networkSize];
      } else {
        switch (percentageBarNetworkMonthly) {
          case 1:
            networkSize = 30;

            break;
          case 2:
            networkSize = 155;

            break;
          case 3:
            networkSize = 780;

            break;
          case 4:
            networkSize = 3905;

            break;
          case 5:
            networkSize = 19530;
            break;

          case 6:
            networkSize = 'FINAL';
            break;

          default:
            networkSize = 5;
            break;
        }

        data =
          demoEarnings.earnings.monthly[percentageBarCenterMonthly][
            networkSize
          ];
      }
      setDemoEarningPrice(data || '$0');
    }
  }, [
    btnYearlyMonthly,
    demoEarnings,
    percentageBarCenterMonthly,
    percentageBarCenterYearly,
    percentageBarNetworkMonthly,
    percentageBarNetworkYearly,
  ]);

  useEffect(() => {
    if (params.slug === 'skip') {
      if (connectedMember) {
        setCartProfitCenters(1);
      } else {
        setCartProfitCenters(0);
      }
    } else {
      setCartProfitCenters(6);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug, connectedMember]);

  useEffect(() => {
    setActivePayment(true);
    setTimeout(() => {
      if (width <= 991) {
        const element = document.getElementById('resum');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    }, 50);
  }, [width]);

  useEffect(() => {
    switch (percentage) {
      case 200: {
        setMultiple(600);
        break;
      }
      case 175: {
        setMultiple(300);
        break;
      }
      case 150: {
        setMultiple(150);
        break;
      }
      case 133: {
        setMultiple(50);
        break;
      }
      default:
      // console.log('multiple');
    }
  }, [cartPrepayMonths, cartProfitCenters, percentage]);

  const handleBtnYearly = useCallback((e) => {
    setBtnYearlyMonthly(e);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback(() => setShow(true), []);

  const steps = useMemo(
    () => [
      {
        value: 'Get Your Free NFT',
        active: true,
      },
      {
        value: 'How To Participate',
        active: true,
      },
      {
        value: 'Activate Your Profit Centers',
        active: true,
      },
      {
        value: 'Earn Even More!',
        active: false,
        semi: true,
      },
      {
        value: 'Final Step',
        active: false,
      },
    ],
    []
  );

  const handleClearCart = useCallback(() => {
    if (connectedMember) {
      setCartProfitCenters(1);
    } else {
      setCartProfitCenters(0);
    }
  }, [connectedMember, setCartProfitCenters]);

  const handleChangePeriod = useCallback(
    (period, percent) => {
      setCartPrepayMonths(period);
      setPercentage(percent);
    },
    [setCartPrepayMonths]
  );

  const handleChange = useCallback(
    (e) => {
      let valueSelected = 0;
      if (e.target.value > 5) {
        valueSelected = 5;
      } else if (e.target.value) {
        valueSelected = e.target.value;
      }
      setCartProfitCenters(valueSelected);
    },
    [setCartProfitCenters]
  );

  const handleSubmit = useCallback(async () => {
    // const user_id = localStorage.getItem('hash_user');
    // const responseUser = await api.get(`users/${user_id}`);
    // const formData = {
    //   user_id,
    //   volume_centers: e,
    // };
    // if (responseUser.data.order) {
    //   if (responseUser.data.order.status !== 'finished') {
    //     const response = await api.put(
    //       `orders/${responseUser.data.order.id}`,
    //       formData
    //     );
    //     if (response.data) {
    //       localStorage.setItem('hash_order', response.data.id);
    //       history.push(`${process.env.PUBLIC_URL}/efetuar-pagamento`);
    //     }
    //   } else {
    //     Swal.fire({
    //       title: 'Opa!',
    //       icon: 'info',
    //       html: 'Não foi possível alterar pedido, pagamento já realizado.',
    //       confirmButtonText: 'Fechar',
    //     }).then(() => {
    //       history.push(`${process.env.PUBLIC_URL}/finalizar-cadastro`);
    //     });
    //   }
    // } else {
    //   const response = await api.post('orders', formData);
    //   if (response.data) {
    //     localStorage.setItem('hash_order', response.data.id);
    //     history.push(`${process.env.PUBLIC_URL}/efetuar-pagamento`);
    //   }
    // }

    history.push(`${process.env.PUBLIC_URL}/efetuar-pagamento`);
  }, [history]);

  return (
    <>
      <Banner className="position-relative overflow-hidden w-100">
        <div className="container-fluid">
          <div className="row vh-lg-100 overflow-auto">
            <div className="col-lg-2 px-0">
              <Timeline steps={steps} />
            </div>
            <div
              className={`col-lg-7 col-xl-8 col-xxl-8 py-lg-4 py-xxl-5 ${
                !activePayment && 'mx-auto'
              }`}
            >
              <div className="d-none d-lg-flex ps-4 mb-n5">
                <ButtonBack
                  onClick={() => {
                    history.push(
                      `${process.env.PUBLIC_URL}/how-to-participate`
                    );
                  }}
                />
              </div>
              <div className="row justify-content-center px-xl-5 mb-4 transition">
                <div className="col-xxl-9 zoom-resum text-center d-flex justify-content-center">
                  <div className="w-100">
                    <h1 className="display-lg-4 fw-bold">
                      <span>
                        <span>Start Earning Today</span>
                      </span>
                    </h1>
                    <div className="row justify-content-center">
                      <div className="col-lg-11 col-xxl-9">
                        <p className="gradient-parts">
                          <span>Choose the best option for you and get</span>{' '}
                          <b>generous token bonus!</b>
                          <br />
                          <span>Keep in mind that these options are</span>{' '}
                          <b>only available during your registration.</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row overflow-small justify-content-sm-center mb-4 mb-xxl-5">
                <div className="col-lg-10 col-xxl-8 zoom-resum d-flex justify-content-center btn-percentage px-4">
                  <button
                    type="button"
                    onClick={() => handleChangePeriod(12, 200)}
                    className={`${
                      percentage === 200 && 'active'
                    } mx-2 w-25 border-0 rounded-pill`}
                  >
                    <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto py-2">
                      Yearly
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChangePeriod(6, 175)}
                    className={`${
                      percentage === 175 && 'active'
                    } mx-2 w-25 border-0 rounded-pill`}
                  >
                    <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto py-2">
                      6&nbsp;Months
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChangePeriod(3, 150)}
                    className={`${
                      percentage === 150 && 'active'
                    } mx-2 w-25 border-0 rounded-pill`}
                  >
                    <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto py-2">
                      3&nbsp;Months
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChangePeriod(1, 133)}
                    className={`${
                      percentage === 133 && 'active'
                    } mx-2 w-25 border-0 rounded-pill`}
                  >
                    <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto py-2">
                      Monthly
                    </span>
                  </button>
                </div>
              </div>

              <div className="row justify-content-center pb-3 pb-lg-0 px-sm-5 px-xl-2 px-xxl-5 transition">
                <Card className="col-sm-11 col-md-7 col-lg-6 col-xl-4 col-xxl-5 zoom-resum mt-3 mt-lg-0 px-4 px-lg-2 mb-5 mb-lg-0">
                  <div className="h-100 card-price max-wid best-price ms-auto">
                    <div className="p-3 w-100">
                      <h2 className="h3 text-center text-white mb-4">
                        PROFIT CENTERS
                      </h2>
                      <div className="text-center value position-relative text-center justify-content-center">
                        <p className="mb-0">{percentage}%</p>
                        <span>Token Bonus</span>
                      </div>
                      <div className="d-flex text-center value-token position-relative text-center justify-content-center">
                        <p className="mb-0 d-flex align-items-center">
                          <span className="pe-3">$</span>
                          {formatPrice(cartProfitCenters * multiple).slice(
                            0,
                            -3
                          )}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center mb-5">
                        <input
                          type="range"
                          name="teste"
                          min={connectedMember ? 1 : 0}
                          max={6}
                          onChange={(e) => {
                            setCartProfitCenters(parseInt(e.target.value, 10));
                          }}
                          value={cartProfitCenters}
                          className="slider-pic"
                        />
                        <span className="thumb" />
                        <span className="py-0 text-white h5 mb-0">
                          &nbsp;{cartProfitCenters}
                        </span>
                      </div>
                      <ul className="ps-2">
                        <li>
                          <small>
                            Exclusive and UNIQUE opportunity to have access to
                            up to 5 additional profit centers to multiply your
                            earnings.
                          </small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Card>
                <Card className="col-sm-11 col-md-7 col-lg-6 col-xl-4 col-xxl-5 zoom-resum mt-3 mt-lg-0 px-4 px-lg-2">
                  <div className="h-100 card-price max-wid best-price">
                    <div className="p-3 w-100 h-100">
                      <h2 className="h3 text-center text-white mb-4">
                        ACTIVATION
                      </h2>
                      <div className="text-center value position-relative text-center justify-content-center">
                        <p className="mb-0">{percentage}%</p>
                        <span>Token Bonus</span>
                      </div>
                      <div className="d-flex text-center value-token position-relative text-center justify-content-center">
                        <p className="mb-0 d-flex align-items-center">
                          <span className="pe-3">$</span>
                          {cartProfitCenters * 25}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center mt-3">
                        <p className="color-p">
                          Activation per PC:{' '}
                          <span className="py-0 text-white h5">
                            &nbsp;{cartProfitCenters}
                          </span>
                        </p>
                      </div>
                      <ul className="ps-2 mt-3">
                        <li>
                          <small>
                            Each Profit center must be activated with your NFT.
                            You will earn a unique NFT per profit center you
                            purchase
                          </small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Card>
                <div className="col-sm-11 col-md-7 col-lg-12 col-xl-8 col-xxl-10 text-center padding-btn zoom-resum">
                  <button
                    type="button"
                    className="btn-calculator w-100 mt-4 fw-bold"
                    onClick={handleShow}
                  >
                    <span className="">OPEN EARNINGS CALCULATOR</span>
                  </button>
                </div>
              </div>
            </div>

            <Resum
              activeResum={activePayment}
              qtd={cartProfitCenters}
              multiple={multiple}
              onChange={handleChange}
              onSubmit={handleSubmit}
              onClearCart={handleClearCart}
            />
          </div>
        </div>
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
          className="modal-signin"
        >
          <button
            type="button"
            className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
            onClick={handleClose}
          >
            x
          </button>

          <Modal.Header className="container py-0 border-0">
            <div className="row w-100 justify-content-center">
              <div className="col-12 text-center">
                <h2 className="fw-bold mb-0">Earnings Potential</h2>
              </div>
            </div>
          </Modal.Header>

          <Modal.Body className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xl-6 btn-calculator text-center mt-4">
                <button
                  type="button"
                  className={`${
                    btnYearlyMonthly === 'yearly' && 'active'
                  } mx-3 border-0 rounded-pill`}
                  onClick={() => handleBtnYearly('yearly')}
                >
                  <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto py-2">
                    Yearly
                  </span>
                </button>
                <button
                  type="button"
                  className={`${
                    btnYearlyMonthly === 'monthly' && 'active'
                  } mx-3 border-0 rounded-pill`}
                  onClick={() => handleBtnYearly('monthly')}
                >
                  <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto py-2">
                    Monthly
                  </span>
                </button>
              </div>
            </div>
            {btnYearlyMonthly === 'yearly' && (
              <div className="row px-4 px-lg-5 mt-5 justify-content-center">
                <div className="col-lg-11 col-xl-8 mb-4">
                  <div className="row">
                    <div className="col-lg-9">
                      <h3 className="mb-3">1. Profit Centers</h3>
                      <RangeCentersYearly
                        percentage={
                          percentageBarCenterYearly >= 4
                            ? (percentageBarCenterYearly - 1) * 19.7
                            : (percentageBarCenterYearly - 1) * 20.2
                        }
                        className="position-relative"
                      >
                        <>
                          <input
                            type="range"
                            name="teste"
                            min={1}
                            max={6}
                            value={percentageBarCenterYearly}
                            onChange={(e) =>
                              setPercentageBarCenterYearly(
                                parseInt(e.target.value, 10)
                              )
                            }
                            className="slider-progress-bar"
                          />
                          <span className="thumb-progress-bar" />
                          {percentageBarCenterYearly > 1 && (
                            <div
                              className="progress-bar-centers"
                              role="progressbar"
                            />
                          )}
                        </>
                      </RangeCentersYearly>

                      <div className="values-total-members d-flex justify-content-between px-2 mt-3">
                        <span className="">1</span>
                        <span className="">2</span>
                        <span className="">3</span>
                        <span className="">4</span>
                        <span className="">5</span>
                        <span className="">6</span>
                      </div>
                    </div>
                    <div className="col-lg-3 mt-4 mt-lg-0 d-flex align-items-center d-lg-block">
                      <h6 className="mb-0 w-50 w-lg-100 text-lg-center mt-lg-2">
                        Subscription Cost
                      </h6>
                      <div className="bg-value-revenue w-50 w-lg-100 d-flex align-items-center justify-content-center">
                        <div className="value-revenue w-90 justify-content-center text-center d-flex align-items-center">
                          <span className="mx-3">
                            $
                            {formatPrice(
                              percentageBarCenterYearly * 50 * 12
                            ).slice(0, -3)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-11 col-xl-8 mb-4">
                  <div className="row">
                    <div className="col-lg-9">
                      <h3 className="mb-3">2. Network Size</h3>
                      <RangeNetworkYearly
                        percentage={
                          percentageBarCenterYearly === 1
                            ? percentageBarNetworkYearly * 20
                            : percentageBarNetworkYearly * 16.7
                        }
                        className="position-relative"
                      >
                        <>
                          <input
                            type="range"
                            name="teste"
                            min={0}
                            max={percentageBarCenterYearly === 1 ? 5 : 6}
                            value={percentageBarNetworkYearly}
                            onChange={(e) =>
                              setPercentageBarNetworkYearly(
                                parseInt(e.target.value, 10)
                              )
                            }
                            className="slider-progress-bar"
                          />
                          <span className="thumb-progress-bar" />
                          {percentageBarNetworkYearly > 0 && (
                            <div
                              className="progress-bar-network"
                              role="progressbar"
                            />
                          )}
                        </>
                      </RangeNetworkYearly>

                      <div
                        className={`${
                          percentageBarCenterYearly === 1 &&
                          'justify-content-between'
                        } network values-total-members ps-2 d-flex mt-3`}
                      >
                        <span className="">5</span>
                        <span className="">30</span>
                        <span className="">155</span>
                        <span className="">780</span>
                        <span className="">3,905</span>
                        <span className="">19,530</span>
                        {percentageBarCenterYearly > 1 && (
                          <span className="">
                            {formatPrice(
                              19530 + (percentageBarCenterYearly - 1) * 15625
                            ).replace('.00', '')}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 mt-4 mt-lg-0 d-flex align-items-center d-lg-block">
                      <h6 className="mb-0 w-50 w-lg-100 text-lg-center mt-lg-2">
                        Earnings
                      </h6>
                      <div className="bg-value-revenue w-50 w-lg-100 d-flex align-items-center justify-content-center">
                        <div className="value-revenue w-90 justify-content-center text-center d-flex align-items-center">
                          <span className="mx-3">{demoEarningPrice}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-0 mt-lg-5">
                  <div className="row bg-number py-3 justify-content-center">
                    <div className="col-lg-8">
                      <div className="row justify-content-evenly align-items-center">
                        <div className="col-4 text-center ps-lg-4">
                          <span className="d-block cost mb-1 mx-auto">
                            Your Cost
                          </span>
                          <span className="d-block value-cost">
                            $
                            {formatPrice(
                              percentageBarCenterYearly * 50 * 12
                            ).slice(0, -3)}
                          </span>
                        </div>
                        <div className="col-3 col-lg-4 px-0 px-lg-3 pe-none text-center">
                          <ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            height={140}
                          />
                        </div>
                        <div className="col-4 text-center ps-lg-4">
                          <span className="d-block earning mb-1 mx-auto">
                            Your Earnings
                          </span>
                          <span className="d-block value-earnings">
                            {demoEarningPrice}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {btnYearlyMonthly === 'monthly' && (
              <div className="row px-4 px-lg-5 mt-5 justify-content-center">
                <div className="col-lg-11 col-xl-8 mb-4">
                  <div className="row">
                    <div className="col-lg-9">
                      <h3 className="mb-3">1. Profit Centers</h3>
                      <RangeCentersMonthly
                        percentage={
                          percentageBarCenterMonthly >= 4
                            ? (percentageBarCenterMonthly - 1) * 19.7
                            : (percentageBarCenterMonthly - 1) * 20.2
                        }
                        className="position-relative"
                      >
                        <>
                          <input
                            type="range"
                            name="teste"
                            min={1}
                            max={6}
                            value={percentageBarCenterMonthly}
                            onChange={(e) =>
                              setPercentageBarCenterMonthly(
                                parseInt(e.target.value, 10)
                              )
                            }
                            className="slider-progress-bar"
                          />
                          <span className="thumb-progress-bar" />
                          {percentageBarCenterMonthly > 1 && (
                            <div
                              className="progress-bar-centers"
                              role="progressbar"
                            />
                          )}
                        </>
                      </RangeCentersMonthly>

                      <div className="values-total-members d-flex justify-content-between px-2 mt-3">
                        <span className="">1</span>
                        <span className="">2</span>
                        <span className="">3</span>
                        <span className="">4</span>
                        <span className="">5</span>
                        <span className="">6</span>
                      </div>
                    </div>
                    <div className="col-lg-3 mt-4 mt-lg-0 d-flex align-items-center d-lg-block">
                      <h6 className="mb-0 w-50 w-lg-100 text-lg-center mt-lg-2">
                        Subscription Cost
                      </h6>
                      <div className="bg-value-revenue w-50 w-lg-100 d-flex align-items-center justify-content-center">
                        <div className="value-revenue w-90 justify-content-center text-center d-flex align-items-center">
                          <span className="mx-3">
                            $
                            {formatPrice(percentageBarCenterMonthly * 50).slice(
                              0,
                              -3
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-11 col-xl-8 mb-4">
                  <div className="row">
                    <div className="col-lg-9">
                      <h3 className="mb-3">2. Network Size</h3>
                      <RangeNetworkMonthly
                        percentage={
                          percentageBarCenterMonthly === 1
                            ? percentageBarNetworkMonthly * 20
                            : percentageBarNetworkMonthly * 16.7
                        }
                        className="position-relative"
                      >
                        <>
                          <input
                            type="range"
                            name="teste"
                            min={0}
                            max={percentageBarCenterMonthly === 1 ? 5 : 6}
                            value={percentageBarNetworkMonthly}
                            onChange={(e) =>
                              setPercentageBarNetworkMonthly(
                                parseInt(e.target.value, 10)
                              )
                            }
                            className="slider-progress-bar"
                          />
                          <span className="thumb-progress-bar" />
                          {percentageBarNetworkMonthly > 0 && (
                            <div
                              className="progress-bar-network"
                              role="progressbar"
                            />
                          )}
                        </>
                      </RangeNetworkMonthly>

                      <div
                        className={`${
                          percentageBarCenterMonthly === 1 &&
                          'justify-content-between'
                        } network values-total-members ps-2 d-flex mt-3`}
                      >
                        <span className="">5</span>
                        <span className="">30</span>
                        <span className="">155</span>
                        <span className="">780</span>
                        <span className="">3,905</span>
                        <span className="">19,530</span>
                        {percentageBarCenterMonthly > 1 && (
                          <span className="">
                            {formatPrice(
                              19530 + (percentageBarCenterMonthly - 1) * 15625
                            ).replace('.00', '')}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 mt-4 mt-lg-0 d-flex align-items-center d-lg-block">
                      <h6 className="mb-0 w-50 w-lg-100 text-lg-center mt-lg-2">
                        Earnings
                      </h6>
                      <div className="bg-value-revenue w-50 w-lg-100 d-flex align-items-center justify-content-center">
                        <div className="value-revenue w-90 justify-content-center text-center d-flex align-items-center">
                          <span className="mx-3">{demoEarningPrice}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-0 mt-lg-5">
                  <div className="row bg-number py-3 justify-content-center">
                    <div className="col-lg-8">
                      <div className="row justify-content-evenly align-items-center">
                        <div className="col-4 text-center ps-lg-4">
                          <span className="d-block cost mb-1 mx-auto">
                            Your Cost
                          </span>
                          <span className="d-block value-cost">
                            $
                            {formatPrice(percentageBarCenterMonthly * 50).slice(
                              0,
                              -3
                            )}
                          </span>
                        </div>
                        <div className="col-3 col-lg-4 px-0 px-lg-3 pe-none text-center">
                          <ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            height={140}
                          />
                        </div>
                        <div className="col-4 text-center ps-lg-4">
                          <span className="d-block earning mb-1 mx-auto">
                            Your Earnings
                          </span>
                          <span className="d-block value-earnings">
                            {demoEarningPrice}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="border-0 px-4" />
        </Modal>
      </Banner>
    </>
  );
};

export default Order;
