import React, {
  useCallback,
  useMemo,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';
import {
  ethers,
  utils,
  ContractReceipt,
  ContractTransaction,
  BigNumber,
} from 'ethers';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import arrow from '~/assets/icons/arrow-bottom.svg';
import { Container, Graph } from './styles';
import logoPurple from '~/assets/logo/logo-p-purple.svg';
import logo from '~/assets/logo/logo-p-purple.svg';
import { formatPrice } from '~/utils/format';
import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';
import { useAuth } from '~/hooks/Auth';
import { web3store } from '../../../store';
import api from '~/services/api';
import {
  getConfigAddress,
  getConfigNumber,
  getConfigString,
} from '~/utils/configContract';
import swalError from '~/utils/swalError';
import logoSwap from '~/assets/logo/logo-swap.png';
import Table from '~/components/Table';

interface WithdrawalRequest {
  nftId: number;
  userAddress: string;
  message: string;
  deadline: number;
  transaction_fee: BigNumber;
}

interface IWinStats {
  play_count: number;
  win_count: number;
  amt_won: number;
}

interface IPlayHistory {
  id: number;
  trans_type: string;
  amount: number;
  transaction_date: number;
  cube_id: number;
  cube_type: string;
}

interface WinStatsProps {
  cubeBidder: number | null;
  clearBidderFunction: () => void;
}

/* eslint no-underscore-dangle: 0 */
const WinStats: React.FC<WinStatsProps> = ({
  cubeBidder,
  clearBidderFunction,
}) => {
  const { user } = useAuth();
  const [months, setMonths] = useState<string[]>([]);
  const [daysCommissions, setDaysCommissions] = useState<number[]>([]);
  const [statsWallet, setStatsWallet] = useState('stats');
  // const [available, setAvailable] = useState('0.00');
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [errorTextClaim, setErrorTextClaim] = useState('');
  const [processText, setProcessText] = web3store.useState('processText');
  const [activeWin, setActiveWin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = web3store.useState('account');
  const [depositTX, setDepositTX] = useState('');
  const [showFinish, setShowFinish] = useState(false);
  const [dataPointindex, setDataPointindex] = useState<number | undefined>(
    undefined
  );
  const [winStats, setWinStats] = useState<IWinStats | null>(null);
  const [playerBalance, setPlayerBalance] = useState(0);
  const [history, setHistory] = useState<IPlayHistory[]>([]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Load user's player stats
  // ++++++++++++++++++++++++++++++++++++++
  const getWinStats = useCallback(() => {
    if (user) {
      // console.log('+++++ getWinStats +++++');
      api
        .get(`/v1/cube/stats/${user.id}`)
        .then(({ data }: { data: IWinStats }) => {
          // console.log(data);
          // Use object destructuring to extract the 'data' property
          // and assign it to the 'data' variable
          setWinStats(data);
        })
        .catch((error) => {
          console.error('Error fetching data from the API:', error);
        });
    }
  }, [user]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Load user's player history
  // ++++++++++++++++++++++++++++++++++++++
  const getPlayHistory = useCallback(() => {
    if (user) {
      // console.log('+++++ getPlayHistory +++++');
      api
        .get(`/v1/cube/history/${user.id}`)
        .then(({ data }: { data: IPlayHistory[] }) => {
          // console.log(data);
          // Use object destructuring to extract the 'data' property
          // and assign it to the 'data' variable
          setHistory(data);
        })
        .catch((error) => {
          console.error('Error fetching data from the API:', error);
        });
    }
  }, [user]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Load user's player balance
  // ++++++++++++++++++++++++++++++++++++++
  const getPlayerBalance = useCallback(() => {
    if (user) {
      // console.log('+++++ getPlayHistory +++++');
      api
        .get(`/v1/cube/player/${user.id}`)
        .then(({ data }: { data: number }) => {
          // console.log(data);
          // Use object destructuring to extract the 'data' property
          // and assign it to the 'data' variable
          setPlayerBalance(Number(data));
        })
        .catch((error) => {
          console.error('Error fetching data from the API:', error);
        });
    }
  }, [user]);

  const daysOfWeek = useMemo(() => {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }, []);

  const available = useMemo(() => {
    return Intl.NumberFormat('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.trunc((playerBalance / 10 ** 18) * 100) / 100);
  }, [playerBalance]);

  const gradientToColors = useMemo(() => {
    const data: string[] = [];
    for (let index = 0; index < 12; index += 1) {
      data.push(
        index === dataPointindex
          ? 'rgba(0, 249, 171, 0.15)'
          : 'rgba(51, 54, 56, 0.15)'
      );
    }
    return data;
  }, [dataPointindex]);

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        height: 250,
        type: 'bar',
        toolbar: {
          show: false,
        },

        events: {
          dataPointSelection: (e, chart, { dataPointIndex }) => {
            if (dataPointIndex === dataPointindex) {
              setDataPointindex(undefined);
            } else {
              setDataPointindex(dataPointIndex);
            }
          },
        },

        foreColor: '#747474',
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 6,
        },
      },

      xaxis: {
        type: 'category',
        categories: daysOfWeek,
        crosshairs: { show: false },
        axisTicks: { show: false },
      },
      yaxis: {
        labels: {
          formatter: (value: any) => {
            return formatPrice(value);
          },
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        theme: 'dark',
      },

      colors: [
        ({ dataPointIndex }: any) => {
          if (dataPointIndex === dataPointindex) {
            return '#00f9ab';
          }
          return '#333638';
        },
      ],

      fill: {
        opacity: 1,
        type: 'gradient',
        gradient: {
          gradientToColors,
          type: 'vertical',
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },

      states: {
        hover: {
          filter: {
            type: 'lighten',
            value: 0.05,
          },
        },
      },

      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
    };

    return data;
  }, [daysOfWeek, gradientToColors, dataPointindex]);

  useEffect(() => {
    setDaysCommissions([8, 5, 6, 2, 4, 3, 1]);
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    console.log('TAB CHANGE!?');
    if (user) {
      switch (statsWallet) {
        case 'stats':
          getWinStats();
          break;
        case 'wallet':
          getPlayerBalance();
          break;
        case 'history':
          getPlayHistory();
          break;
        default:
          console.log('Unknown statsWallet value');
      }
    }
  }, [user, statsWallet]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // useEffect(() => {

  // }, [setStatsWallet]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Respond to cubeBidder in incoming message
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    console.log('cubeBidder changed to: %s', cubeBidder);
    console.log(cubeBidder === null);
    if (cubeBidder !== null && user) {
      if (cubeBidder.toString() === user.id) {
        switch (statsWallet) {
          case 'stats':
            getWinStats();
            break;
          case 'wallet':
            getPlayerBalance();
            break;
          case 'history':
            getPlayHistory();
            break;
          default:
            console.log('Unknown statsWallet value');
        }
        clearBidderFunction();
      }
    }
  }, [cubeBidder]);

  const handleStatsWallet = useCallback((e) => {
    setStatsWallet(e);
  }, []);

  const series = useMemo(
    () => [
      {
        name: 'Ganhos',
        data: daysCommissions,
      },
    ],
    [daysCommissions]
  );

  const data = [
    {
      type: 'Bid',
      date: '11/05/23',
      cube: '10x10',
      amount: '$10',
    },
    {
      type: 'Win',
      date: '11/05/23',
      cube: '10x25',
      amount: '$110',
    },
    {
      type: 'Claim',
      date: '11/05/23',
      cube: '10x25',
      amount: '$110',
    },
  ];

  const column = [
    {
      name: 'Type',
      selector: 'trans_type',
      sortable: false,
    },
    {
      name: 'Date',
      selector: 'transaction_date',
      sortable: false,
    },
    // {
    //   name: 'Cube',
    //   selector: 'cube_type',
    //   sortable: false,
    // },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: false,
    },
  ];

  const handleActiveWin = useCallback(() => {
    setActiveWin(!activeWin);
  }, [activeWin]);

  const handleClearBtn = useCallback(async () => {
    // console.log('+++++ available: %s +++++', available);
    setInputValue('');
    setIsValid(true);
  }, [available]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValueClaim = e.target.value;
    // Check if there are extra decimal places
    const parts = inputValueClaim.split('.');
    if (parts.length === 2 && parts[1].length > 2) {
      // Truncate the extra decimal places
      parts[1] = parts[1].substring(0, 2);
      inputValueClaim = parts.join('.');
    }

    // Remove leading zeros before the integer part
    if (!inputValueClaim.startsWith('.') && inputValueClaim !== '0') {
      inputValueClaim = inputValueClaim.replace(/^0+/, '');
    }

    // Check if the input matches the USD format
    const usdRegex = /^(?:\$?\d{1,5}(?:,\d{3})*(\.\d{1,2})?|\$?99999.99)/;

    let isValidInput = true;
    if (inputValueClaim !== '') {
      isValidInput = usdRegex.test(inputValueClaim);
    }

    const inputValueFloat = parseFloat(inputValueClaim);

    // Add one leading zero if the float amount is less than 1 and not already present
    if (
      inputValueFloat < 1 &&
      !inputValueClaim.startsWith('0') &&
      inputValueClaim.startsWith('.')
    ) {
      inputValueClaim = '0'.concat(inputValueClaim);
    }

    const availableFloat = parseFloat(available.replace(/,/g, ''));
    let errorTextValidate = '';

    if (usdRegex.test(inputValueClaim)) {
      if (inputValueFloat === 0) {
        errorTextValidate = '';
        isValidInput = true;
      } else if (inputValueFloat < 5) {
        errorTextValidate = 'Minimum amount is $5.00';
        isValidInput = false;
      } else if (inputValueFloat > availableFloat) {
        errorTextValidate = 'Amount is greater than balance.';
        isValidInput = false;
      }
    } else {
      errorTextValidate = 'Please enter a valid USD amount.';
    }
    // Set the error text conditionally
    // const errorTextValidate =
    //   inputValueClaim === ''
    //     ? ''
    //     : 'Please enter a valid USD amount (e.g., $1,234.56)';

    // Update the state and show validation feedback
    setIsValid(isValidInput);
    if (inputValueClaim === '0') {
      setInputValue('');
    } else {
      setInputValue(inputValueClaim);
    }
    setErrorTextClaim(errorTextValidate);
    // You can perform any action here or pass the new value to another function/component
    // For example, you can call a function like handleValueChange(newValue);
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleMaxBtn = useCallback(async () => {
    // console.log('+++++ available: %s +++++', available);
    setInputValue(available.replace(/,/g, ''));
    setIsValid(true);
  }, [available]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleWithdraw = useCallback(async () => {
    const amt2Claim = parseFloat(inputValue.replace(/,/g, ''));
    if (amt2Claim > 0 && amt2Claim <= parseFloat(available.replace(/,/g, ''))) {
      if (user) {
        networkSwitch('NETWORK')
          .then((response) => {
            const amtFloat = parseFloat(inputValue.replace(/,/g, ''));
            // console.log('amtFloat: %s', amtFloat);
            if (amtFloat > 1) {
              setProcessText('Preparing withdrawal transaction');
              setLoading(true);
              // setRunWithdrawal(true);
              // GET FEE ESTIMATE
              api
                .get('/v1/cube/claim')
                .then(function (feeresponse) {
                  // console.log(feeresponse.data);
                  // const fee = feeresponse.data.estimate.fee;
                  const { fee } = feeresponse.data.estimate;
                  // console.log('+++++ fee: %s +++++', fee);
                  getConfigAddress('contract_cubepay_v1')
                    .then((payresult) => {
                      const pay = payresult?.toString();
                      // console.log('purser: %s', purser);
                      // console.log('verifyingContract: %s', purser);
                      const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
                      // console.log('deadline: %s', deadline);
                      const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
                      const chainInt = parseInt(chainNo || '0', 16);
                      // console.log(chainInt);
                      // const purserold = process.env.REACT_APP_BSC_PURSER;
                      // console.log(purserold, typeof purserold);
                      const domain = {
                        name: 'CubePay',
                        version: '1',
                        chainId: chainInt,
                        verifyingContract: pay,
                      };
                      // console.log('++++++++++++++++++++++++++++++++++++++++++');
                      // console.log(domain);
                      // console.log('++++++++++++++++++++++++++++++++++++++++++');
                      const types = {
                        Request: [
                          { name: 'nftId', type: 'uint256' },
                          { name: 'userAddress', type: 'address' },
                          { name: 'message', type: 'string' },
                          { name: 'deadline', type: 'uint256' },
                          { name: 'transaction_fee', type: 'uint256' },
                        ],
                      };
                      const transaction_fee = BigNumber.from(fee);
                      const transaction_fee_in_ether = parseFloat(
                        ethers.utils.formatEther(transaction_fee)
                      );
                      const message = `I authorize this withdrawal of $${amtFloat.toFixed(
                        2
                      )}, less a transaction fee of $${transaction_fee_in_ether.toFixed(
                        2
                      )}`;
                      // console.log(message);
                      const request: WithdrawalRequest = {
                        nftId: parseInt(user.id, 10),
                        userAddress: account,
                        message,
                        deadline,
                        transaction_fee: BigNumber.from(fee),
                      };
                      const tprovider = new ethers.providers.Web3Provider(
                        window.ethereum
                      );
                      // console.log('+++++ Wei Value +++++ ');
                      // console.log((amtFloat * 1e18).toString());
                      // console.log(
                      //   ethers.utils.parseEther(amtFloat.toString()).toString()
                      // );
                      // console.log('+++++ Wei Value +++++ ');
                      setProcessText('Waiting for signature from wallet.');
                      const tsigner = tprovider.getSigner();
                      tsigner
                        ._signTypedData(domain, types, request)
                        .then((sig) => {
                          // Handle the result (sig) here
                          // console.log('signature: %s', sig);
                          setProcessText(
                            'Sending transaction to the blockchain.'
                          );
                          api
                            .post('/v1/cube/claim', {
                              nftId: user.id,
                              userAddress: account,
                              message,
                              deadline,
                              signature: sig,
                              transaction_fee: fee,
                              amount_requested: ethers.utils
                                .parseEther(amtFloat.toString())
                                .toString(),
                            })
                            .then(function (withdrawalresponse) {
                              // console.log(withdrawalresponse);
                              setLoading(false);
                              setShowFinish(true);
                            })
                            .catch(function (withdrawalerror) {
                              console.log('============ ERROR ==============');
                              console.log(withdrawalerror);
                              setLoading(false);
                              // setWithdrawalResult('ERROR');
                              swalError({
                                message:
                                  'Sorry, there was a problem processing your withdrawal',
                                textButton: 'Close',
                              });
                            });
                        })
                        .catch((sigerror) => {
                          // Handle any errors that occur during the function call
                          // console.log(sigerror);
                          setLoading(false);
                        });
                    })
                    .catch((purserError) => {
                      console.log(purserError);
                      setLoading(false);
                      swalError({
                        message:
                          'Sorry, there was a problem processing your withdrawal',
                        textButton: 'Close',
                      });
                    });
                })
                .catch(function (feeerror) {
                  console.log('================= ERROR ===================');
                  console.log(feeerror);
                  setLoading(false);
                  swalError({
                    message:
                      'Sorry, there was a problem getting network fees for this transaction',
                    textButton: 'Close',
                  });
                });
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
      //  if (amt2Claim > 0 && amt2Claim <= parseFloat(available.replace(/,/g, '')))
    } else {
      console.log('Invalid withdrawal value');
    }
  }, [available, inputValue]);

  return (
    <Container className={`${activeWin && 'pb-5'} pt-3`}>
      <div className="d-flex justify-content-between align-items-center px-2 px-xxl-3 pb-4">
        <div className="d-flex align-items-center w-100 stats-wallet my-2">
          <button
            type="button"
            className={`${
              statsWallet === 'stats' && 'stats-wallet-active'
            } w-50`}
            onClick={() => handleStatsWallet('stats')}
          >
            <span>Win&nbsp;Stats</span>
          </button>
          <button
            type="button"
            className={`${
              statsWallet === 'wallet' && 'stats-wallet-active'
            } w-50`}
            onClick={() => handleStatsWallet('wallet')}
          >
            <span>Game&nbsp;Wallet</span>
          </button>
          <button
            type="button"
            className={`${
              statsWallet === 'history' && 'stats-wallet-active'
            } w-50`}
            onClick={() => handleStatsWallet('history')}
          >
            <span>History</span>
          </button>
        </div>
        <button
          type="button"
          onClick={handleActiveWin}
          className={`${
            !activeWin && 'rotateActive'
          } bg-transparent border-0 arrow`}
        >
          <img src={arrow} alt="Arrow" />
        </button>
      </div>
      {activeWin && (
        <>
          {statsWallet === 'stats' && (
            <>
              <div className="d-flex px-3 pb-3">
                <div className="w-50 bg-win p-3">
                  <span className="h6 text-white ps-3">Amount Won</span>
                  <div className="d-flex align-items-center justify-content-between value-profiti p-2 mt-2">
                    <img src={logoSwap} alt="Logo" />
                    <span>
                      {winStats
                        ? new Intl.NumberFormat('en-US').format(
                            winStats.amt_won / 10 ** 18
                          )
                        : 'Loading...'}
                    </span>
                  </div>
                </div>
                <div className="w-25 bg-win p-3 text-center ms-2">
                  <span className="h6 text-white d-block mb-3">Wins</span>
                  <span className="h6 text-gradient d-block mb-0">
                    {winStats?.win_count}
                  </span>
                </div>
                <div className="w-25 bg-win p-3 text-center ms-2">
                  <span className="h6 text-white d-block mb-3">Plays</span>
                  <span className="h6 text-gray d-block mb-0">
                    {winStats?.play_count}
                  </span>
                </div>
              </div>
              {/* <div className="mb-n4 px-3">
                <Graph className="">
                  <div className="h6 small text-white ps-3">Win Chart</div>
                  <ReactApexChart
                    type="bar"
                    options={options}
                    series={series}
                  />
                </Graph>
              </div> */}
            </>
          )}
          {statsWallet === 'wallet' && (
            <div className="col-12 col-sm-12 p-0 py-0 WithDrawBox">
              <div className="row justify-content-center">
                <div className="col-12 px-5 mt-3 mb-0">
                  <div className="BalanceBox">
                    <div className="text-start BalanceBoxLabel">
                      Wallet Balance
                    </div>
                    <div className="BalanceBoxInner d-flex col-sm-12 col-lg-12 align-items-center">
                      <img
                        src={logoSwap}
                        alt="Image Value"
                        className="BalanceBoxLogo"
                      />
                      <div className="ms-auto">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(
                          Math.trunc((playerBalance / 10 ** 18) * 100) / 100
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-5 mb-0">
                  <div className="amount-stake">
                    <div className="bg-box-input bd-input d-flex justify-content-between mt-3 p-3">
                      <button
                        type="button"
                        className="btn-clear px-3"
                        onClick={handleClearBtn}
                      >
                        Clear
                      </button>
                      <input
                        type="text"
                        placeholder="Enter amount"
                        className="w-90 text-center"
                        name=""
                        id=""
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                      <button
                        type="button"
                        className="btn-max px-3 ms-3"
                        onClick={handleMaxBtn}
                      >
                        Max
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-3 px-5 mt-3 ButtonBox">
                  <div className="w-100">
                    {isValid ? (
                      <button
                        className="btn ButtonWithDrawBox w-100"
                        type="button"
                        onClick={() => {
                          handleWithdraw();
                          // handleShow();
                        }}
                      >
                        Claim Balance
                      </button>
                    ) : (
                      <p className="error-message text-danger text-center pt-1">
                        {errorTextClaim}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {statsWallet === 'history' && (
            <div className="row">
              <div className="col-12 px-4 mb-n4">
                <Table
                  title="History"
                  columns={column}
                  data={history}
                  className="table"
                  pagination
                />
              </div>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default WinStats;
