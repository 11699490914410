import React, { useCallback, useEffect, useState } from 'react';
import TypistComponent from 'react-typist';
import Highlight from 'react-highlight';

interface ISubElement {
  content: string;
  code?: boolean;
  inactiveHighlight?: boolean;
  id: number;
}

interface IElement {
  content: ISubElement[];
  pre: boolean;
  id: number;
  length: number;
  language?: string;
}

interface IElementTemp {
  content: string;
  pre: boolean;
  id: number;
  length: number;
  language?: string;
}

interface ITypistProps {
  text: string;
  className?: string;
  speed?: number;
  onTypingDone?(): void;
  cursor?: TypistComponent.CursorProps;
}

const Typist: React.FC<ITypistProps> = ({
  text,
  className,
  // speed = 70,
  speed = 30,
  onTypingDone,
  cursor,
}) => {
  const [elements, setElements] = useState<IElement[]>([]);

  useEffect(() => {
    const content = text
      .replaceAll('</p><p>', '\n')
      .replace(/^([^\n]*\n)([\s\S]*)(\n[^\n]*)$/, '<Paige>$1$2$3</Paige>')
      .replace(/```([\s\S]*?)```/g, '<pre>$1</pre>')
      .replace(/`([\s\S]*?)`/g, '<code>$1</code>');
    let activePreTag = false;
    let activeCodeTag = false;
    let inactiveHighlight = false;
    const contentParts: IElement[] = content
      .split(/(<pre>|<\/pre>)/g)
      .map((contentPart, index) => {
        if (contentPart === '<pre>') {
          activePreTag = true;
          return '';
        }
        if (contentPart === '</pre>') {
          activePreTag = false;
          return '';
        }

        let partData = {} as IElementTemp;
        if (activePreTag) {
          const firstBreakLineIndex = contentPart.indexOf('\n');
          const language = contentPart.slice(0, firstBreakLineIndex);
          const code = contentPart.slice(firstBreakLineIndex + 1);
          partData = {
            content: code,
            pre: activePreTag,
            id: index,
            length: contentPart.length,
            language,
          };
        } else {
          partData = {
            content: contentPart.replace(/<\/?p>/gi, ''),
            pre: activePreTag,
            id: index,
            length: contentPart.length,
          };
        }

        const codeParts = partData.content
          .split(/(<code>|<\/code>)/g)
          .map((codePart: string, idx: number) => {
            if (codePart === '<code>') {
              activeCodeTag = true;
              return '';
            }
            if (codePart === '</code>') {
              activeCodeTag = false;
              return '';
            }

            return {
              content: codePart,
              code: activeCodeTag,
              id: idx,
            };
          });

        const brParts = partData.content
          .split(/(<Paige>|<\/Paige>)/g)
          .map((brPart: string, idx: number) => {
            if (brPart === '<Paige>') {
              inactiveHighlight = true;
              return '';
            }
            if (brPart === '</Paige>') {
              inactiveHighlight = false;
              return '';
            }

            return {
              content: brPart,
              inactiveHighlight,
              id: idx,
            };
          });

        const dataContent = JSON.parse(JSON.stringify(partData));
        console.log();
        console.log(brParts);
        if (codeParts.length > 1) {
          const filteredCodePearts = codeParts.filter((codePart) => !!codePart);
          dataContent.content = filteredCodePearts.map((codePart: any) => ({
            ...codePart,
            content: codePart.content
              .replace('<Paige>', '')
              .replace('</Paige>', ''),
          }));
        } else if (brParts.length > 0) {
          dataContent.content = brParts.filter((brPart) => !!brPart);
        }
        return dataContent;
      });

    const contentData = contentParts.filter((contentPart) => !!contentPart);
    console.log(contentData);
    setElements(contentData);
  }, [text]);

  const handleTypingDone = useCallback(() => {
    if (onTypingDone) {
      onTypingDone();
    }
  }, [onTypingDone]);

  return (
    <div className={className}>
      {elements.map((element, index) => (
        <TypistComponent
          className="text-right"
          startDelay={
            index > 0 ? elements[index - 1].length * speed * index : undefined
          }
          cursor={cursor}
          onTypingDone={
            index + 1 === elements.length ? handleTypingDone : undefined
          }
          avgTypingDelay={speed}
        >
          {element.pre ? (
            <p>
              <Highlight className={element.language}>
                {element.content.map((content) => {
                  if (content.code) {
                    return `<code>${content.content}</code>`;
                  }
                  return content.content;
                })}
              </Highlight>
            </p>
          ) : (
            <p>
              {element.content.map((content) => {
                if (content.code) {
                  return <code className="tag-code">{content.content}</code>;
                }
                if (content.inactiveHighlight) {
                  return <pre className="tag-pre">{content.content}</pre>;
                }
                return content.content;
              })}
            </p>
          )}
        </TypistComponent>
      ))}
    </div>
  );
};

export default Typist;
