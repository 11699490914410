import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Web3 from 'web3';
// import Web3Modal from 'web3modal';
import { ethers, utils } from 'ethers';
import { setSyntheticLeadingComments } from 'typescript';
import { truncate } from 'truncate-ethereum-address';
import axios from 'axios';

/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */
import { AbiItem } from 'web3-utils';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';
import { Container, Modal } from './styles';
import fox from '~/assets/icons/fox.svg';
import btnBlue from '~/assets/icons/btn-blue.svg';
import { web3store } from '../../store';
import { abi } from '../../abi/usdpi.json';
import { abibusd } from '../../abi/busd.json';
import { abibank } from '../../abi/bank.json';
/* eslint no-underscore-dangle: 0 */

interface IProps {
  btnText: string;
  className?: string;
  onShowNoReferrer(): void;
}

interface IParams {
  reference: string;
  optionalCode: string;
  username: string;
}

const WalletLink: React.FC<IProps> = ({
  btnText,
  className,
  onShowNoReferrer,
}) => {
  // states
  const history = useHistory();
  const { setStatus, setConnected } = useAuth();
  const params = useParams<IParams>();
  // console.log('walletlink-params:');
  // console.log(params);
  const [show, setShow] = useState(false);
  // web3store ---------------------------
  const [invalid, setInvalid, updateInvalid] = web3store.useState('invalid');
  const [refW3, setRefW3] = web3store.useState('refW3');
  const [, setReference] = web3store.useState('reference');
  const [, setPlacementRef] = web3store.useState('placementRef');
  const [, setUsername] = web3store.useState('username');

  const [w3, setW3, updateW3] = web3store.useState('w3');
  // const [provider, setProvider] = web3store.useState('provider');
  // const [signer, setSigner] = web3store.useState('signer');
  const [account, setAccount] = web3store.useState('account');
  // const [refBalances, setRefBalances] = web3store.useState('refBalances');
  // const [chain, setChain] = web3store.useState('chain');
  // const [nftlist, setNftlist] = web3store.useState('nftlist');
  // const [memberStatus, setMemberStatus] = web3store.useState('memberStatus');
  // const [invalidRef] = web3store.useState('invalidRef');
  const [lastPageServed, setLastPageServed] =
    web3store.useState('lastPageServed');
  const [noWallet, setNoWallet] = web3store.useState('noWallet');
  // web3store ---------------------------
  // const { reference } = useParams<{ reference: string }>();
  const { reference, placementRef, username } =
    useParams<{ reference: string; placementRef: string; username: string }>();
  // console.log('---------------------------------');
  // console.log('URL PARAMS');
  // console.log('---------------------------------');
  // console.log('reference: %s', reference);
  // console.log('placementRef: %s', placementRef);
  // console.log('username: %s', username);
  // console.log('---------------------------------');

  const providerOptions = {
    /* Additional providers here */
  };

  const handleClick = useCallback(async () => {
    // console.log('WalletLink - handleClick');
    if (typeof window.ethereum !== 'undefined') {
      setNoWallet(false);
      setReference(reference);
      setPlacementRef(placementRef);
      setUsername(username);
      try {
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        await tprovider.send('eth_requestAccounts', []);
        const tsigner = tprovider.getSigner();
        const acct0 = await tsigner.getAddress();
        // console.log('+++++++++ acct0: %s', acct0);
        // console.log('+++++++++ account: %s', account);
        if (account === '') {
          api
            .get('/v1/nft/list', {
              params: {
                registered: 2,
                owner: acct0,
              },
            })
            .then(function (response) {
              // console.log(response.data.nft_list);
              if (response.data.nft_list.length === 0) {
                if (
                  reference === undefined ||
                  placementRef === undefined ||
                  username === undefined
                ) {
                  history.push({
                    pathname: '/autoaffiliate-member',
                  });
                } else {
                  const paramData = {
                    selectedAddress: acct0,
                    reference,
                    placementRef,
                    username,
                  };
                  history.push({
                    pathname: '/autoaffiliate-member/order',
                    state: paramData,
                  });
                }
              } else {
                // console.log('has nft id: %s', response.data.nft_list[0]);
                setAccount(acct0);
              }
            })
            .catch(function (error) {
              console.log('================= ERROR ===================');
              console.log(error);
            });
        } // (account === '')
      } catch (error) {
        console.log('error connecting');
        console.log(error);
      }
      // setRefBalances(true);
      // console.log('Address: %s', acct0);
    } else {
      setNoWallet(true);
    }
  }, [account]);
  //   }, [setAccount, setChain, setRefW3, setW3]);

  if (lastPageServed.length > 0) {
    handleClick();
  }

  return (
    <>
      {account === '' ? (
        <Container type="button" className={className} onClick={handleClick}>
          {btnText}
        </Container>
      ) : (
        <Container type="button" className={className}>
          Connected to: {truncate(account)}
        </Container>
      )}
    </>
  );
};

export default WalletLink;
