import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';

import AllResources from './AllResources';
import Downloads from './Downloads';
import FutureResources from './FutureResources';
import { Container, Welcome, Search } from './styles';

import magnifier from '~/assets/icons/magnifier.svg';
import WalletRow from '~/components/WalletRow';

const Resources: React.FC = () => {
  const location = useLocation();
  return (
    <Container>
      <div className="container-fluid container-xl">
        <div className="row">
          <div className="col-12 mb-2">
            <Welcome>
              <div className="container-fluid mt-4 ">
                <div className="row align-items-center justify-content-end justify-content-sm-between">
                  <div className="col-sm-6 col-lg-3 px-0">
                    <h1 className="h3 h2-lg ps-3 mb-0 mt-2 mt-sm-0">
                      Resources
                    </h1>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 mt-5 mt-sm-0">
                    <Search className="d-flex align-items-center">
                      <input className="w-100" placeholder="Search" />
                      <img src={magnifier} alt="Search" className="mx-2" />
                    </Search>
                  </div>
                  <div className="col-lg-5 px-0">
                    <div className="zoom">
                      <WalletRow />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center mt-5">
                  <div className="col-sm-9 px-0 text-center text-sm-start">
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/business/resources`}
                      activeClassName="active pb-1"
                      exact
                    >
                      All Resources
                    </NavLink>
                  </div>
                  {/* <div className="col-sm-3 col-lg-9 px-0 text-center text-sm-start my-3 my-sm-0">
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/business/resources/downloads`}
                      activeClassName="active pb-1"
                    >
                      Downloads
                    </NavLink>
                  </div> */}

                  {/* <div className="col-sm-3 px-0 text-center text-sm-start">
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/business/resources/future-resources`}
                      activeClassName="active pb-1"
                    >
                      Future Resources
                    </NavLink>
                  </div> */}
                  <hr className="d-none d-sm-block d-md-none w-100 w-sm-50 my-5" />

                  {/* <div className="col-sm-5 col-md-3 ms-auto my-5 my-sm-0">
                    <div className="ms-sm-auto border-gradient-light rounded-pill text-center">
                      <button
                        type="button"
                        className="w-100 border-0 bg-transparent d-flex align-items-center justify-content-center"
                      >
                        add New resource
                        <BiPlus size={20} color="#8c8c8c" className="ms-2" />
                      </button>
                    </div>
                  </div> */}
                  <hr className="d-none d-md-block w-100 w-sm-50 my-5" />
                </div>
              </div>
            </Welcome>
          </div>
        </div>
      </div>

      <AllResources
        className={`${
          location.pathname === '/business/resources' ? 'd-block' : 'd-none'
        }`}
      />

      <Downloads
        className={`${
          location.pathname === '/business/resources/downloads'
            ? 'd-block'
            : 'd-none'
        }`}
      />

      {/* <FutureResources
        className={`${
          location.pathname === '/business/resources/future-resources'
            ? 'd-block'
            : 'd-none'
        }`}
      /> */}
    </Container>
  );
};
export default Resources;
