import styled from 'styled-components';

interface ICanvasProps {
  finished: boolean;
}

export const Canvas = styled.canvas<ICanvasProps>`
  opacity: ${(props) => (props.finished ? 0 : 1)};
  visibility: ${(props) => (props.finished ? 'hidden' : 'visible')};
  width: 1140px !important;
  height: 696px !important;
  position: absolute;
  top: 28px;
  z-index: 10000;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 30px;
`;
