import styled from 'styled-components';

interface IContainer {
  active: boolean;
  type: 'dark' | 'white';
}

export const Container = styled.div<IContainer>`
  z-index: 2000;
  position: fixed;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};

  .loading-box {
    height: 100%;
    background: ${(props) =>
      props.type === 'dark'
        ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.25) -38.97%, rgba(0, 0, 0, 0.25) 75.53%)'
        : 'rgba(214, 203, 210, 0.69)'};
    backdrop-filter: blur(25px);

    span,
    small {
      font-weight: 400;
      color: ${(props) => (props.type === 'dark' ? '#fff' : '#6f51c4')};
      text-align: center;
    }

    span {
      font-size: 20px;
    }
  }

  .animation-loading {
    height: 152px;
    width: 152px;

    img {
      position: relative;
      z-index: 300;
      top: 10px;
      left: 10px;
      height: 134px;
      width: 134px;
      background: ${(props) =>
        props.type === 'dark' ? 'rgba(0, 0, 0)' : '#ddd'};
      backdrop-filter: blur(5px);
    }
  }

  .box-1 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: ${(props) => (props.type === 'dark' ? '#fff' : '#6f51c4')};
    animation: position-box-1 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-1 {
    0% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    25% {
      top: calc(100% - 37px);
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    50% {
      top: calc(100% - 114px);
      left: 0;
      width: 37px;
      height: 114px;
    }

    75% {
      top: 0;
      left: 0;
      width: 114px;
      height: 37px;
    }

    100% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }
  }

  .box-2 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: ${(props) => (props.type === 'dark' ? '#fff' : '#6f51c4')};
    animation: position-box-2 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-2 {
    0% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }

    25% {
      top: 0;
      left: 0;
      width: 37px;
      height: 114px;
    }

    50% {
      top: 0;
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    75% {
      top: calc(100% - 114px);
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    100% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }
  }
`;
