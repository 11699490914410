import styled from 'styled-components';

import background from '~/assets/banners/background.png';
import check from '~/assets/icons/check-icon.svg';

export const Banner = styled.div`
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  min-height: 100vh;

  h2 {
    background: linear-gradient(269.95deg, #fff 0.02%, #fff 97.45%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;

    span {
      background: linear-gradient(269.95deg, #e323ff 0.02%, #6a9aae 97.45%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }
  }

  h1 {
    background: linear-gradient(269.95deg, #fff 0.02%, #fff 97.45%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;

    > span {
      background: linear-gradient(269.95deg, #e323ff 0.02%, #6a9aae 97.45%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }
  }

  p {
    color: #f4f4f4;
    font-weight: 300 !important;

    b {
      font-weight: 600;
    }
  }

  .border-gradient {
    background-image: linear-gradient(180deg, #242526, #242526),
      linear-gradient(269.95deg, #e323ff 0.02%, #79d1f8 97.45%) !important;
  }

  .color-code {
    color: #828282;
  }

  .bg-gray {
    background-color: #17171a;
    border-radius: 30px;
  }

  .text-gray {
    color: #b7b7b7;
  }

  .arrow-pos {
    right: 100px;
  }

  .pos-30 {
    top: 35px;
    left: -106px;
  }

  span:not(.error, .title) {
    background: linear-gradient(
      239.23deg,
      #fff 7.84%,
      #fff 28.62%,
      #fff 38.94%,
      #fff 49.96%,
      #fff 64.82%,
      #fff 67.63%,
      #fff 91.88%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  b {
    font-weight: bold;
  }

  .text-gradient {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  .video-container {
    > div {
      width: 80px;
      height: 50%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.096) 100%
      );
      opacity: 0.3;
      box-shadow: inset 0px 39px 52px -29px rgba(255, 255, 255, 0.5),
        inset 6px 1px 1px -4px #ffffff, inset -6px -7px 8px -4px #ffffff,
        inset 0px 7px 3px -4px #ffffff,
        inset 51px -73px 72px -60px rgba(255, 255, 255, 0.3),
        inset 0px 98px 100px -48px rgba(255, 255, 255, 0.3),
        inset 0px 4px 18px rgba(255, 255, 255, 0.3),
        inset 0px 1px 40px rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2px);
      border-radius: 30px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }

    div.left {
      left: -10px;
    }

    video {
      width: 100%;
      border-radius: 50px;
    }

    div.right {
      right: -10px;
    }
  }

  form {
    .input {
      border: 1.5px solid #333333;
      box-sizing: border-box;
      border-radius: 15px;
      background-color: transparent;
      padding-top: 10px;
      padding-bottom: 10px;
      color: #efefef;

      input {
        color: #efefef;
      }
    }

    label {
      color: #bdbdbd;
    }

    div.checkbox {
      width: 20px;
      padding: 0;
      background-color: none;
      border: none;
      margin-right: 10px;

      > div {
        display: none;
      }

      :before {
        content: '';
        width: 20px;
        height: 20px;
        border: 1px solid #aeaeae;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    div.checkbox.checked {
      :before {
        content: url(${check});
      }
    }
  }

  @media screen and (max-width: 991px) {
    .pos-30 {
      top: -60px;
      left: 0;
    }
  }

  @media screen and (max-width: 524px) {
    h1 {
      > span {
        background: unset;
        background-clip: unset;
        -webkit-background-clip: unset;

        span {
          background: linear-gradient(269.95deg, #e323ff 0.02%, #6a9aae 97.45%);
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
`;

export const AvatarInput = styled.label`
  width: 125px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding: 0px !important;
    > img {
      width: 125px;
      height: 125px;
      padding: 3px !important;
      border-radius: 40px !important;
    }
  }

  .camera {
    right: 10px;
    bottom: -15px;
    background-color: #1b1b1b;
    border-radius: 50%;
    padding: 10px;
  }
`;
