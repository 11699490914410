import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';

import { FormHandles } from '@unform/core';
import axios from 'axios';
import { useAuth } from '~/hooks/Auth';
import { Avatar, Nfts } from './styles';

import bookmark from '~/assets/icons/bookmark-icon.svg';
import bookmarkAct from '~/assets/icons/bookmark-icon-active.svg';
import download from '~/assets/icons/download-icon.svg';

import nftIcon from '~/assets/icons/nft-icon.svg';

import AiCreatorTitle from '~/components/AiCreatorTitle';

import { web3store } from '~/store';

import ModalDownloadShare from '~/components/ModalDownloadShare';
import ButtonDownload from '~/components/ButtonDownload';

interface ImageHistoryItem {
  id: number;
  img: string;
  description: string;
  seed: string;
  collection: number;
}

const Nft: React.FC = () => {
  const { user } = useAuth();

  const formRef = useRef<FormHandles>(null);

  const [show, setShow] = useState(false);
  const [imgNft, setImgNft] = useState('');
  const [descriptionNft, setDescriptionNft] = useState('');
  const [avatar, setAvatar] = useState('');
  const [collection, setCollection] = useState<number>();

  const apiUrl = process.env.REACT_APP_API as string;
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');

  const [imageHistory, setImageHistory] = useState<ImageHistoryItem[]>([]);

  useEffect(() => {
    if (user) {
      setAvatar(user.nft_img);

      axios
        .get(apiUrl.concat('v1/ai/image/history/', connectedMember.memberId))
        .then(function (acctresponse) {
          console.log(acctresponse.data);
          setImageHistory(acctresponse.data.images);
        })
        .catch(function (error) {
          console.log('================= ERROR ===================');
          console.log(error);
        });
    }
  }, [user]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback((nft, desc) => {
    setShow(true);

    setImgNft(nft);
    setDescriptionNft(desc);
  }, []);

  const handleBookmark = useCallback((id) => {
    console.log('Bookmark ID: %s', id);
    const member = connectedMember.memberId;
    console.log('Member: %s', member);
    axios
      .post(apiUrl.concat('v1/ai/image/bookmark'), {
        tokenID: member,
        imageID: id,
      })
      .then(function (acctresponse) {
        console.log(acctresponse.data);

        setImageHistory((prevImageHistory) =>
          prevImageHistory.map((item) =>
            item.id === id
              ? {
                  ...item,
                  img: acctresponse.data.img,
                  collection: acctresponse.data.collection,
                }
              : item
          )
        );
      })
      .catch(function (error) {
        console.log('================= ERROR ===================');
        console.log(error);
        // setImgCommand('');
      });
  }, []);

  return (
    <>
      <AiCreatorTitle />
      <Nfts ref={formRef} onSubmit={() => '/'}>
        <div className="container">
          <div className="row img-example">
            <div className="col-12 mt-5">
              <div className="row h-100">
                {imageHistory.map((item) => (
                  <div key={item.id} className="col-3 my-2 px-2">
                    <div className="border-nft p-2">
                      <div className="hover-btns position-relative">
                        <button
                          type="button"
                          className="hover border-0 bg-transparent position-relative w-100"
                          onClick={() => handleShow(item.img, item.description)}
                        >
                          <img
                            src={item.img}
                            alt={item.img}
                            className="w-100"
                          />
                          <div className="position-absolute text-ai p-3 bg" />
                        </button>
                        {/* <div className="btns d-flex justify-content-between position-absolute w-100">
                          <button type="button">Mint NFT</button>
                          <button type="button">Regenerate</button>
                        </div> */}
                      </div>

                      <div className="d-flex justify-content-between align-items-center px-3 py-3">
                        <div className="d-flex align-items-center w-auto avatar-text">
                          <div className="avatar me-2 d-flex align-items-center text-center rounded-circle">
                            <Avatar src={avatar} className="rounded-circle" />
                          </div>
                          {user?.screen_name}
                        </div>
                        <div>
                          <button
                            type="button"
                            className="border-0 bg-transparent"
                            onClick={() =>
                              handleShow(item.img, item.description)
                            }
                          >
                            <img
                              src={nftIcon}
                              title="Nft"
                              alt="Nft"
                              className="px-2"
                            />
                          </button>
                          <ButtonDownload
                            className="border-0 bg-transparent"
                            img={item.img}
                          >
                            <img
                              src={download}
                              title="Download"
                              alt="Download"
                              className="px-2"
                            />
                          </ButtonDownload>

                          <button
                            type="button"
                            className="border-0 bg-transparent"
                            onClick={() => handleBookmark(item.id)}
                          >
                            <img
                              src={item.collection ? bookmarkAct : bookmark}
                              title="Save"
                              alt="Bookmark"
                              className="px-2"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Nfts>

      <ModalDownloadShare
        selectImg={imgNft}
        description={descriptionNft}
        show={show}
        onCloseShare={handleClose}
      />
    </>
  );
};

export default Nft;
