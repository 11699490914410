import styled from 'styled-components';

export const Container = styled.div``;

export const Welcome = styled.div`
  h1 {
    color: #dadada;
  }

  button {
    color: #8c8c8c;
    font-size: 13px;
  }

  hr {
    border-bottom: solid 1px #333;
  }

  a {
    border: none;
    background: none;
    color: #cbccce;
    font-weight: 400;

    :hover {
      color: #e4e4e4;
      font-weight: 600;
      text-decoration: none;
    }
  }

  .zoom {
    zoom: 0.9;
  }

  a.active {
    color: #e4e4e4;
    font-weight: 600;
  }

  .border-gradient-light {
    max-width: 200px;
    padding: 1px;

    button {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  @media screen and (max-width: 575px) {
    .border-gradient-light {
      margin: auto;
    }
  }
`;

export const Search = styled.div`
  padding: 5px;
  border-radius: 100px;
  border: 1px solid #242526;
  background-color: transparent;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: transparent;
    color: #cbccce !important;

    ::placeholder {
      color: #cbccce !important;
    }
  }

  img {
    height: 25px;
    width: 25px;
  }
`;
