import styled from 'styled-components';

export const Container = styled.div``;

export const Welcome = styled.div`
  h1 {
    color: #dadada;
  }

  p {
    color: #cbccce;
  }
`;

export const Places = styled.div`
  a {
    text-decoration: none;
    pointer-events: none;
  }
  .placing {
    img {
      width: 50px;
      height: 50px;
    }

    p {
      font-weight: 600;
    }
  }

  .first {
    p {
      color: #dadada;
    }
  }

  .second {
    p {
      color: #afafaf;
    }
  }

  .third {
    p {
      color: #4f4f4f;
    }
  }

  .your-position {
    .font-weight-semibold {
      font-weight: 600;
    }

    .text-light-gray {
      color: #cbccce;
    }

    .text-green {
      color: #37c694;
    }
  }

  .box {
    height: 290px;
    border: none;
    padding: 2px;
    background-image: linear-gradient(180deg, #1d2023, #1d2023),
      linear-gradient(269.95deg, #1d2023 0.02%, #1d2023 97.45%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 20px;
    transition-duration: 0.3s;

    h2,
    .text-light-gray {
      color: #dadada;
    }

    .text-gray {
      color: #8e8f8f;
    }

    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }

    hr {
      background: linear-gradient(269.95deg, #e323ff 0.02%, #79d1f8 97.45%);
      width: 80px;
      height: 1px;
      border: none;
    }
  }

  .first-place {
    background-image: linear-gradient(180deg, #1d2023, #1d2023),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
  }

  .second-place {
    background-image: linear-gradient(180deg, #1d2023, #1d2023),
      linear-gradient(269.95deg, #afafaf 0.02%, #afafaf 97.45%) !important;
  }

  .third-place {
    background-image: linear-gradient(180deg, #1d2023, #1d2023),
      linear-gradient(269.95deg, #4f4f4f 0.02%, #4f4f4f 97.45%) !important;
  }

  .details-box {
    height: 290px;
    border: none;
    padding: 2px;
    background-image: linear-gradient(180deg, #1d2023, #1d2023),
      linear-gradient(269.95deg, #1d2023 0.02%, #1d2023 97.45%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 20px;
    transition-duration: 0.3s;

    p {
      color: #dadada;
    }

    .thumb {
      filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.2));
      border-radius: 15px;
    }
  }

  @media screen and (max-width: 1199px) {
    .box {
      height: 360px;
    }

    .height {
      height: 360px;
    }
  }

  @media screen and (max-width: 991px) {
  }
`;

export const RankingTable = styled.div`
  .ranking > div:nth-child(2) {
    margin-top: 3rem;
  }
  .ranking {
    background-color: #1d2023;
    border-radius: 20px;
    overflow: hidden;

    h3 {
      color: #cbccce;
    }

    .rdt_TableHeadRow,
    .rdt_TableRow {
      > div {
        text-align: center;
        max-width: 180px;
        width: 180px;

        div {
          width: 100%;
        }
      }

      > div:first-child {
        max-width: 300px;
        width: 300px;
      }
    }

    .rdt_TableHeadRow {
      div {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .rdt_TableRow {
      height: 90px;
    }

    @media screen and (min-width: 1400px) {
      .rdt_TableHeadRow,
      .rdt_TableRow {
        > div:first-child {
          max-width: 400px;
          width: 400px;
        }
      }
    }

    @media screen and (max-width: 1199px) {
      .rdt_TableHeadRow,
      .rdt_TableRow {
        > div {
          text-align: center;
          max-width: 100px;
          width: 100px;
        }

        > div:first-child {
          max-width: 290px;
          width: 290px;
        }
      }
    }
  }
`;

export const Ranking = styled.div`
  img.profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .name {
    text-align: left;
    width: 135px;
  }

  .hidden {
    opacity: 0;
  }

  .visible {
    opacity: 1;
  }
`;
