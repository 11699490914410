import React from 'react';

import { Link } from 'react-router-dom';
import { Container } from './styles';
import logo from '~/assets/logo/logo-p-purple.svg';
import notFound from '~/assets/defaults/not-found-image.png';

const NotFoundPage: React.FC = () => {
  return (
    <Container className="container">
      <div className="row justify-content-center">
        <div className="col-lg-7 col-xl-6 text-center">
          <div>
            <img src={logo} alt="Logo" className="my-4" />
          </div>
          <div>
            <img
              src={notFound}
              alt="404 not found"
              className="w-100 mt-2 mb-3"
            />
          </div>
          <h1>
            It looks like this page doesn't exist, but don't let that stop you.
          </h1>
          <div className="d-flex justify-content-center start-text mt-5">
            Start at&nbsp;
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard`}
              className="border-underline"
            >
              <span className="gradient-home fw-bold">Home</span>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NotFoundPage;
