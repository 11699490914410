import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #5c5c5c;
  }

  .gradient-home {
    background: linear-gradient(
      270.04deg,
      #fb5a43 0.03%,
      #c341ae 50.77%,
      #9445c2 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .border-underline {
    border: none;
    padding: 0 0 2px 0;
    background-image: linear-gradient(180deg, #18191a, #18191a),
      linear-gradient(270.04deg, #fb5a43 0.03%, #c341ae 50.77%, #9445c2 100%);
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    -webkit-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .start-text {
    font-weight: 400;
    font-size: 20px;

    color: #ffffff;
  }
`;
