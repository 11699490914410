/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useMemo, useState } from 'react';
import DataTable, {
  IDataTableColumn,
  IDataTableStyles,
} from 'react-data-table-component';
import { useLocation } from 'react-router-dom';

import { MdFindInPage } from 'react-icons/md';
import { CSVLink } from 'react-csv';

import { TableContent, Pagination, NotFound } from './styles';
import InputDate from '~/components/InputDate';

import magnifier from '~/assets/icons/magnifier.svg';
import table from '~/assets/icons/table-icon.svg';

interface IData {
  [key: string]: any;
}
interface ITableProps {
  title?: string;
  className?: string;
  data: IData[];
  totalValueData?: number;
  columns: IDataTableColumn[];
  style?: IDataTableStyles;
  searchable?: boolean;
  onSearch?(value: string): void;
  date?: boolean;
  initialDate?: Date;
  finalDate?: Date;
  onChangeStartDate?(date: Date): void;
  onChangeLastDate?(date: Date): void;
  exportable?: boolean;
  pagination?: boolean;
  onRowClicked?(e: IData): void;
}

const Table: React.FC<ITableProps> = ({
  title,
  className,
  data,
  totalValueData,
  columns,
  style,
  searchable,
  onSearch,
  date,
  initialDate,
  finalDate,
  onChangeStartDate,
  onChangeLastDate,
  exportable,
  pagination,
  onRowClicked,
}) => {
  const [startDate, setStartDate] = useState(
    initialDate || new Date(new Date().getFullYear(), 0, 1)
  );
  const [lastDate, setLastDate] = useState(
    finalDate || new Date(new Date().getFullYear(), 11, 31)
  );
  const [totalData, setTotalData] = useState(0);
  const [partialData, setPartialData] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [firstButton, setFirstButton] = useState(2);
  const [centerButton, setCenterButton] = useState(3);
  const [lastButton, setLastButton] = useState(4);

  const location = useLocation();

  const customStyles: IDataTableStyles = useMemo(() => {
    return {
      noData: {
        style: {
          backgroundColor: '#202020',
          color: '#dadada',
          fontWeigth: 'bold',
          fontSize: '2rem',
        },
      },
      header: {
        style: {
          backgroundColor: '#202020',
          color: '#dadada',
        },
      },
      headRow: {
        style: {
          border: 'none',
        },
      },
      rows: {
        style: {
          backgroundColor: '#202020',
          '&:not(:last-of-type)': {
            border: 'none',
          },
        },
      },
      headCells: {
        style: {
          backgroundColor: '#202020',
          color: '#dadada',
        },
        activeSortStyle: {
          color: '#dadada',
          '&:focus': {
            outline: 'none',
          },
          '&:hover:not(:focus)': {
            color: '#dadada',
          },
        },
        inactiveSortStyle: {
          color: '#dadada',
          '&:focus': {
            outline: 'none',
            color: '#dadada',
          },
          '&:hover': {
            color: '#dadada',
          },
        },
      },
      cells: {
        style: {
          color: '#8c8c8c',
          backgroundColor: '#202020',
        },
      },
      contextMenu: {
        style: {
          color: '#dadada',
        },
      },
      action: {
        button: '#8c8c8c',
        hover: '#8c8c8c',
        disabled: '#8c8c8c',
      },
    };
  }, []);

  const tableData = useMemo(() => {
    setTotalData(data.length);
    return data;
  }, [data]);

  const tableDataShow = useMemo(() => {
    const newDataList = tableData.filter(
      (_, index) =>
        index >= (selectedPage - 1) * 10 && index < selectedPage * 10
    );
    if (partialData === 0) {
      setPartialData(newDataList.length);
    }
    return newDataList;
  }, [partialData, selectedPage, tableData]);

  const dataToExport = useMemo(() => {
    const datasExport: IData[] = [];
    tableDataShow.forEach((dataExport: IData) => {
      const datas = {
        '#': dataExport.id,
        Nome: dataExport.name,
        'E-mail': dataExport.email,
        'Telefone/Celular': dataExport.contact,
        Cidade: dataExport.city,
        'Data de registro': dataExport.created_at,
      };
      datasExport.push(datas);
    });

    return datasExport;
  }, [tableDataShow]);

  const totalPages = useMemo(() => {
    const pages = totalValueData
      ? Math.ceil(totalValueData / 10)
      : Math.ceil(tableData.length / 10);
    return pages;
  }, [totalValueData, tableData]);

  const onChangePage = useCallback(
    (page) => {
      setSelectedPage(page);
      if (page >= 3) {
        if (page <= totalPages - 2) {
          setFirstButton(page - 1);
        } else if (page === totalPages) {
          if (totalPages === 3) {
            setFirstButton(page - 1);
          } else if (totalPages === 4) {
            setFirstButton(page - 2);
          } else {
            setFirstButton(page - 3);
          }
        } else if (totalPages === 4) {
          setFirstButton(page - 1);
        } else {
          setFirstButton(page - 2);
        }
      } else if (page === 1) {
        setFirstButton(page + 1);
      } else {
        setFirstButton(page);
      }

      if (page >= 3) {
        if (page <= totalPages - 2) {
          setCenterButton(page);
        } else if (page === totalPages) {
          if (totalPages === 4) {
            setCenterButton(page - 1);
          } else {
            setCenterButton(page - 2);
          }
        } else if (totalPages === 4) {
          setCenterButton(page);
        } else {
          setCenterButton(page - 1);
        }
      } else if (page === 1) {
        setCenterButton(page + 2);
      } else {
        setCenterButton(page + 1);
      }

      if (page >= 3) {
        if (page <= totalPages - 2) {
          setLastButton(page + 1);
        } else if (page === totalPages) {
          setLastButton(page - 1);
        } else {
          setLastButton(page);
        }
      } else if (page === 1) {
        setLastButton(page + 3);
      } else {
        setLastButton(page + 2);
      }

      setPartialData(10 * page);
    },
    [totalPages]
  );

  const handleChange = useCallback(
    (e) => {
      if (onSearch) {
        setSelectedPage(1);
        onSearch(e.target.value);
      }
    },
    [onSearch]
  );

  const handleChangeStartDate = useCallback(
    (e) => {
      setStartDate(e);
      if (onChangeStartDate) {
        onChangeStartDate(e);
      }
    },
    [onChangeStartDate]
  );

  const handleChangeLastDate = useCallback(
    (e) => {
      setLastDate(e);
      if (onChangeLastDate) {
        onChangeLastDate(e);
      }
    },
    [onChangeLastDate]
  );

  return (
    <TableContent
      className={`${className} position-relative h-100 d-flex flex-column justify-content-between`}
      showHeader={!!(title || searchable || date || exportable)}
      rowIsClickable={!!onRowClicked}
    >
      <div className="row w-100 align-items-center justify-content-between px-3 mt-3 header-tab">
        {title && (
          <>
            <div
              className={
                !searchable && !date && !exportable ? 'col-12' : 'col-lg-2'
              }
            >
              <h2 className="h5 mb-0">{title}</h2>
            </div>
            <div
              className={`col-lg-1 spacer ${
                location.pathname === '/atividades/carteira' ||
                location.pathname === '/atividades/ganhos' ||
                location.pathname === '/atividades/indicados'
                  ? 'd-none'
                  : ''
              } `}
            />
          </>
        )}
        {searchable && (
          <div className="col-lg-4 my-2 my-lg-0">
            <div className="border-gray search">
              <div className="w-100 d-flex align-items-center pe-2">
                <input
                  className="w-100"
                  placeholder="Search"
                  onChange={handleChange}
                />
                <img src={magnifier} alt="Magnifier" className="height" />
              </div>
            </div>
          </div>
        )}
        {(date || exportable) && (
          <div
            className={`date-and-export ${
              location.pathname === '/atividades/carteira'
                ? 'col-lg-12 col-xl-7 mt-4 mt-xl-0'
                : 'col-lg-5'
            } ${
              location.pathname === '/atividades/ganhos'
                ? 'col-lg-7 mt-4 mt-lg-0'
                : 'col-lg-5'
            }`}
          >
            <div className="d-flex w-100">
              {date && (
                <>
                  <div className="col-5 my-2 my-lg-0 px-1">
                    <InputDate
                      onChange={handleChangeStartDate}
                      selected={startDate}
                      className="w-100 text-center inputDate size-xxs"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                  <div className="col-5 my-2 my-lg-0 px-1">
                    <InputDate
                      onChange={handleChangeLastDate}
                      selected={lastDate}
                      className="w-100 text-center inputDate size-xxs"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </>
              )}
              {exportable && (
                <div className="col-2 d-flex align-items-center exportable text-center px-1 px-lg-0 mx-lg-1 my-2 my-lg-0">
                  <CSVLink
                    data={dataToExport}
                    separator=";"
                    filename={`${
                      title ? title.replace(/\s/g, '-') : 'data-of-table'
                    }.csv`}
                    className="d-block w-100 inputDate svg-xxs"
                  >
                    <img src={table} alt="Table" />
                  </CSVLink>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="tableData my-2 mt-lg-0">
        <DataTable
          data={tableDataShow}
          columns={columns}
          customStyles={style || customStyles}
          onRowClicked={onRowClicked}
          noDataComponent={
            <NotFound className="pt-5">
              <p className="mb-0 ms-0 ms-lg-3 text-center text-lg-center h5 h4-lg">
                <MdFindInPage size={35} color="#8D8D8D" />
                &nbsp;&nbsp;
                <br className="d-block d-lg-none" />
                There are no records to&nbsp;display
              </p>
            </NotFound>
          }
        />
      </div>
      {pagination && (
        <Pagination className="row pb-1 pt-3 w-100 align-items-center justify-content-center menu-table mx-0">
          <div
            className={`${
              location.pathname === '/products/cube'
                ? 'col-12'
                : 'col-lg-6  text-lg-start'
            } px-lg-4 text-center`}
          >
            <p className="mb-lg-0">
              Showing {partialData < 10 ? 1 : partialData - 9}-
              {partialData > totalData ? totalData : partialData} of {totalData}
            </p>
          </div>
          <div
            className={`${
              location.pathname === '/products/cube' ? 'col-12' : 'col-lg-6'
            }`}
          >
            <div
              className={`${
                location.pathname !== '/products/cube' &&
                'justify-content-lg-end'
              } d-flex w-100 justify-content-center `}
            >
              <button
                type="button"
                className="border-0 bg-transparent mx-1 px-1"
                onClick={() => onChangePage(1)}
                disabled={selectedPage === 1}
              >
                First
              </button>
              <button
                type="button"
                className={`border-0 bg-transparent mx-0 mx-sm-0 px-1 btn-page ${
                  selectedPage === 1 ? 'active' : ''
                }`}
                onClick={() => onChangePage(1)}
              >
                01
              </button>
              {totalPages > 1 && (
                <>
                  {totalPages > 5 && (
                    <>
                      {selectedPage > 3 && (
                        <span className="border-0 bg-transparent mx-1 px-1 btn-page">
                          ...
                        </span>
                      )}
                    </>
                  )}
                  {totalPages > 2 && (
                    <button
                      type="button"
                      className={`border-0 bg-transparent mx-1 px-1 btn-page ${
                        selectedPage === firstButton ? 'active' : ''
                      }`}
                      onClick={() => onChangePage(firstButton)}
                    >
                      {`0${firstButton}`.slice(-2)}
                    </button>
                  )}
                  {totalPages > 3 && (
                    <button
                      type="button"
                      className={`border-0 bg-transparent mx-1 px-1 btn-page ${
                        selectedPage === centerButton ? 'active' : ''
                      }`}
                      onClick={() => onChangePage(centerButton)}
                    >
                      {`0${centerButton}`.slice(-2)}
                    </button>
                  )}
                  {totalPages > 4 && (
                    <button
                      type="button"
                      className={`border-0 bg-transparent mx-1 px-1 btn-page ${
                        selectedPage === lastButton ? 'active' : ''
                      }`}
                      onClick={() => onChangePage(lastButton)}
                    >
                      {`0${lastButton}`.slice(-2)}
                    </button>
                  )}
                  {totalPages > 5 && (
                    <>
                      {selectedPage < totalPages - 2 && (
                        <span className="border-0 bg-transparent mx-1 px-1 btn-page">
                          ...
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
              {totalPages > 1 && (
                <button
                  type="button"
                  className={`border-0 bg-transparent mx-1 px-1 btn-page ${
                    selectedPage === totalPages ? 'active' : ''
                  }`}
                  onClick={() => onChangePage(totalPages)}
                >
                  {`0${totalPages}`.slice(-2)}
                </button>
              )}
              <button
                type="button"
                className="border-0 bg-transparent mx-1 px-1"
                onClick={() => onChangePage(totalPages)}
                disabled={selectedPage === totalPages}
              >
                Last
              </button>
            </div>
          </div>
        </Pagination>
      )}
    </TableContent>
  );
};

export default Table;
