import React, { useCallback, useState, useMemo } from 'react';
import Lottie from 'react-lottie';
import { HiArrowNarrowRight } from 'react-icons/hi';
import attention from '~/assets/animations/attention.json';
import { Modal } from './styles';
import InputCheckbox from '~/components/InputCheckbox';

interface IShow {
  show: boolean;
  aaName: string;
  walletAddress: string;
  aaId: string;
  memberName: string;
  handleCancel(): void;
  handleAccept(): void;
}

const ModalPleaseConfirm: React.FC<IShow> = ({
  show,
  aaName,
  walletAddress,
  aaId,
  memberName,
  handleCancel,
  handleAccept,
}) => {
  const [checkedAddress, setCheckedAddress] = useState(false);
  const [checkedAaId, setCheckedAaId] = useState(false);
  const [checkedMemberName, setCheckedMemberName] = useState(false);
  const [checkedIGree, setCheckedIGree] = useState(false);

  const handleClose = useCallback(() => {
    setCheckedAddress(false);
    setCheckedAaId(false);
    setCheckedMemberName(false);
    setCheckedIGree(false);
  }, []);
  return (
    <>
      <Modal
        size="lg"
        centered
        show={show}
        className="modal-wrong-network"
        // onHide={handleCancel}
      >
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-4">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden pe-none">
            <Lottie
              options={{
                animationData: attention,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={160}
              width={160}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-0 mt-n5">
          <h2 className="mb-1 fw-bold text-center w-100">Please Confirm...</h2>
          <p className="text-center complete mb-4">
            You are about to complete your registration
          </p>
          <div className="bg-members py-4">
            {' '}
            <h3 className="text-center">
              This is the <span className="fw-semibold">NEW MEMBER</span>{' '}
              referrer to the platform:
            </h3>
            <h4 className="text-center mb-4">{aaName}</h4>
            <p className="new-members text-center">
              Please confirm new members info below:
            </p>
            <div className="d-flex justify-content-center">
              <label
                htmlFor="check-address"
                className="address-check d-flex ps-2 pe-4"
              >
                <InputCheckbox
                  name="check-address"
                  options={[
                    {
                      labelHtml: true,
                      label: `<b>Members Wallet Address:</b>  ${walletAddress}`,
                      value: `${walletAddress}`,
                      selected: checkedAddress,
                    },
                  ]}
                  onChange={() => setCheckedAddress(!checkedAddress)}
                />
              </label>
              <label
                htmlFor="check-aaid"
                className="address-check d-flex ps-2 pe-4"
              >
                <InputCheckbox
                  name="check-aaid"
                  options={[
                    {
                      labelHtml: true,
                      label: `<b>AA ID:</b>  ${aaId}`,
                      value: `${aaId}`,
                      selected: checkedAaId,
                    },
                  ]}
                  onChange={() => setCheckedAaId(!checkedAaId)}
                />
              </label>
              <label
                htmlFor="check-member"
                className="address-check d-flex ps-2 pe-4"
              >
                <InputCheckbox
                  name="check-member"
                  options={[
                    {
                      labelHtml: true,
                      label: `<b>Member Name:</b>  ${memberName}`,
                      value: `${memberName}`,
                      selected: checkedMemberName,
                    },
                  ]}
                  onChange={() => setCheckedMemberName(!checkedMemberName)}
                />
              </label>
            </div>
          </div>
          <div className="texts w-lg-90 mx-auto px-4">
            <p className="anything-text text-center my-4">
              If anything seems incorrect, please reach back out to
              AutoAffiliate support immediately. Remember, transactions on the
              blockchain are irreversible.
            </p>
            <div className="d-flex justify-content-center">
              <label
                htmlFor="check-gree"
                className="igree-check d-flex text-center ps-2 pe-4 mb-4"
              >
                <InputCheckbox
                  name="check-gree"
                  options={[
                    {
                      labelHtml: true,
                      label: `<b>I AGREE:</b>  All <b>subscription sales are final.</b> Due to the nature of blockchain, <b>refunds are not available.</b>`,
                      value:
                        'I AGREE: All subscription sales are final. Due to the nature of blockchain, refunds are not available.',
                      selected: checkedIGree,
                    },
                  ]}
                  onChange={() => setCheckedIGree(!checkedIGree)}
                />
              </label>
            </div>

            <button
              type="button"
              title={
                !checkedAaId ||
                !checkedAddress ||
                !checkedIGree ||
                !checkedMemberName
                  ? 'Check All Text Boxes'
                  : 'Finalize Registration'
              }
              disabled={
                !checkedAaId ||
                !checkedAddress ||
                !checkedIGree ||
                !checkedMemberName
              }
              className={`${
                checkedAaId &&
                checkedAddress &&
                checkedIGree &&
                checkedMemberName
                  ? 'opacity-100'
                  : 'opacity-10 cursor-allowed'
              } btn-confirm w-100 mb-3`}
              onClick={handleAccept}
            >
              FINALIZE REGISTRATION{' '}
              <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
            </button>

            <button
              type="button"
              onClick={() => {
                handleCancel();
                handleClose();
              }}
              className="btn-cancel w-100"
            >
              <span className="">Cancel</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>
    </>
  );
};

export default ModalPleaseConfirm;
