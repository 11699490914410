import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Button = styled.button`
  padding: 16px;
  border-radius: 20px;
  font-weight: bold;
  background-color: #00f9ab;
  color: #000;
  font-weight: 700;
  font-size: 20px;
  transition-duration: 0.3s;
  :hover {
    background-color: ${darken(0.1, '#00f9ab')};
  }
`;

export const Modal = styled(ModalComponent)`
  h2 {
    font-family: 'Poppins', sans-serif;
    color: #dadada;
    font-size: 48px;
  }

  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 118%;
    text-align: center;
    color: #aeabab;
    b {
      color: #ddd;
    }
    span {
      color: #ddd;
      font-weight: 500;
    }
  }

  .btn-swap {
    background-color: #00f9ab;
    color: #000;
    border-radius: 20px;
    font-size: 24px;
    padding: 16px;
    transition-duration: 0.3s;

    :hover {
      background-color: ${darken(0.1, '#00f9ab')};
    }
  }

  .coin video {
    border: 1px solid #565656;
    border-radius: 30px;
  }
  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 40px;
    }
  }
`;
