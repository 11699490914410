import React, { useEffect, useState } from 'react';
// import { io } from 'socket.io-client';

import Lottie from 'react-lottie';
import { useLocation } from 'react-router-dom';
import { Container } from './styles';

import statusLoading from '~/assets/animations/status-loading.json';
import api from '~/services/api';

interface IStatusPaymentProps {
  activeInPage?: boolean;
}

// const socket = io(
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:3000'
//     : 'https://api.livplus.io',
//   { reconnection: true }
// );

const StatusPayment: React.FC<IStatusPaymentProps> = ({ activeInPage }) => {
  const location = useLocation();
  const [status, setStatus] = useState('Esperando confirmação de pagamento');
  const [active, setActive] = useState(false);
  const [paymentConclude, setPaymentConclude] = useState(true);

  useEffect(() => {
    const order_id = localStorage.getItem('hash_order');
    // socket.emit('connectRoom', 'payment');
    // socket.on(`order-${order_id}`, (data: any) => {
    //   if (data === 'finished') {
    //     setStatus('Pagamento Confirmado');
    //     localStorage.setItem(`payment-${order_id}`, 'finished');
    //     setActive(true);
    //     setTimeout(() => {
    //       setActive(false);
    //     }, 1500);
    //   }
    // });
  }, []);

  useEffect(() => {
    const order_id = localStorage.getItem('hash_order');
    if (order_id) {
      // api.get(`orders/${order_id}`).then((response) => {
      //   if (response.data.status !== 'finished') {
      //     setPaymentConclude(false);
      //   }
      // });
    }
  }, []);

  useEffect(() => {
    setActive(location.pathname !== '/efetuar-pagamento');
  }, [location.pathname]);

  return (
    <Container
      active={
        (status === 'Pagamento Confirmado' &&
          location.pathname === '/efetuar-pagamento') ||
        activeInPage ||
        active
      }
      statusConfirm={status === 'Pagamento Confirmado'}
      paymentConclude={paymentConclude}
    >
      {location.pathname !== '/efetuar-pagamento' ? (
        <div className="fixed-top d-flex align-items-center justify-content-center flag-status bg-status py-2 px-4 w-75 w-sm-50 w-xxl-25">
          <div className="animation h-100 point-none loading-animation">
            <Lottie
              options={{
                animationData: statusLoading,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={62}
              width={62}
            />
          </div>
          <span className="mb-0 ml-2 text-gradient-status font-weight-bold status text-center">
            {status}
          </span>
        </div>
      ) : (
        <div className="position-relative status-payment-page point-none loading-animation">
          <Lottie
            options={{
              animationData: statusLoading,
              autoplay: true,
              loop: true,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={120}
            width={120}
          />
          <span className="position-absolute mb-0 text-gradient-status font-weight-bold">
            {status}
          </span>
        </div>
      )}
    </Container>
  );
};

export default StatusPayment;
