import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .container-fluid {
    max-width: 1650px;
  }
  .bg-gray {
    background: #1d2023;
    border-radius: 30px;

    .border-right {
      border-color: #393c3d !important;
    }

    h2 {
      color: #dadada;
    }

    .input {
      border: 1.5px solid #393c3d;
      box-sizing: border-box;
      border-radius: 15px;
      height: 40px;

      input {
        color: #efefef !important;
      }
    }

    button.btn-gradient {
      border-radius: 15px;
    }
  }

  .table {
    .rdt_TableHeadRow,
    .rdt_TableRow {
      > div:nth-child(2),
      > div:nth-child(4) {
        max-width: 175px;
        width: 175px;
      }
    }
  }

  .contact {
    cursor: pointer;

    input {
      color: #8c8c8c;
      font-size: 13px;
      background-color: transparent;
      border: none;
    }
  }

  .bg-earning-gray {
    > div {
      background: #1d2023;
      border-radius: 30px;
    }
  }

  div.tableData div {
    background-color: transparent !important;
  }

  .rdt_TableHeader {
    display: none !important;
  }

  .line-side {
    border-right: solid 1px #393c3d;
  }

  .share-pool h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #cdcecf;
    span {
      display: block;
      font-weight: 700;
      font-size: 29px;
      line-height: 46px;
    }
  }

  .share-pool h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #828282;
  }

  .share-pool button {
    background: #00f9ab;
    border-radius: 20px;
    border: none;
    padding: 12px;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    transition-duration: 0.3s;
    :hover {
      background: ${darken(0.1, '#00f9ab')};
    }
  }

  .share-pool .bg-border {
    background: #171719;
    border: 1px solid #3a3a3a;
    border-radius: 30px;
  }

  .share-pool .bg-value-revenue {
    background: #1d1c1c;
    height: 68px;
    border-radius: 40px;
  }

  .share-pool .value-revenue {
    border: none;
    padding: 1px;
    font-size: 18px;
    color: #fff;
    height: 52px;
    font-weight: 700;
    //filter: drop-shadow(0px 12px 10px rgba(0, 0, 0, 0.2));
    background-image: linear-gradient(180deg, #1d1c1c, #1d1c1c),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 30px;
    width: 93%;
  }

  .bg-box {
    background: rgba(34, 37, 39, 60%);
    border-radius: 24px;
    //height: 210px;
  }

  .bg-grad-blur {
    background-image: linear-gradient(
        262.29deg,
        rgba(227, 35, 255, 0.6) 2.98%,
        rgba(54, 109, 250, 0.6) 184.94%
      ),
      linear-gradient(
        265.14deg,
        rgba(227, 35, 255, 0.6) -104.79%,
        rgba(54, 109, 250, 0.6) 129.19%
      );

    filter: blur(50px);
    width: 40%;
    height: 40%;
    top: 30%;
    left: 30%;
    z-index: 0;
  }

  .index1 {
    z-index: 1;
  }

  .paragraph {
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: #dadada;
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #f0eeee;
    }
  }

  .size {
    font-size: 15px !important;
  }

  .btns-history {
    z-index: 1000;
    background: #18191a;
    border-radius: 38px;
    left: 130px;
    top: 7px;
  }

  .btns-history button {
    font-weight: 400;
    font-size: 12px;
    color: #828282;
    transition: all 0.3s ease;
    background: #18191a;
    border-radius: 38px;
    border: 2px solid #18191a;
    opacity: 0.7;

    :hover {
      opacity: 9;
    }
  }

  .active-usdpi,
  .active-prfti {
    background: #17171a !important;
    border: 2px solid #bebebe !important;
    opacity: 9 !important;
  }

  @media screen and (max-width: 991px) {
    .padding-x {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    .padding-y {
      padding: 1.5rem !important;
    }

    .rdt_TableHeader {
      display: block !important;
    }
  }

  @media screen and (max-width: 850px) {
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #dadada;
  }

  p {
    color: #969696;
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }
`;

export const Option = styled.div`
  .Link:hover {
    text-decoration: none;
  }

  .Active {
    color: #dadada;
    font-weight: bold;
  }

  .Disable {
    color: #484848;
  }
`;
