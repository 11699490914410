import React, { useCallback } from 'react';
import axios from 'axios';

interface ButtonDownloadProps {
  img: string;
  className?: string;
  children: React.ReactNode;
}

const ButtonDownload: React.FC<ButtonDownloadProps> = ({
  img,
  className,
  children,
}) => {
  const handleClikDownload = useCallback(async () => {
    const response = await axios.get(img, {
      responseType: 'arraybuffer',
    });

    const link = document.createElement('a');
    link.href = `data:image/png;base64, ${Buffer.from(
      response.data,
      'binary'
    ).toString('base64')}`;
    link.download = 'my-image.png';
    link.click();
  }, [img]);

  return (
    <button type="button" onClick={handleClikDownload} className={className}>
      {children}
    </button>
  );
};

export default ButtonDownload;
