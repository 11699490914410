import styled from 'styled-components';

export const Container = styled.div`
  img {
    width: 42px;
    height: 42px;
  }

  .bg-values {
    background-color: #1d1d1f;
    border-radius: 24px 10px 10px 24px;
  }

  .bg-values span {
    min-width: 130px;
    font-weight: 600;
  }

  .wm-tooltip {
    position: relative;
  }

  .wm-tooltip:after {
    background-color: #212122;
    color: #8c8c8c;
    border: 1px solid #262626;
    border-radius: 15px;
    box-sizing: border-box;
    content: attr(data-tooltip);
    display: none;
    padding: 15px;
    font-size: 10px;
    position: absolute;
    right: -82px;
    width: 110px;
    bottom: 23px;
    z-index: 10000;
    box-shadow: -2px 7px 15px 0px rgba(0, 0, 0, 0.16);
  }

  .wm-tooltip:hover:after {
    display: block;
  }

  .addPRFTI {
    top: 104px;
    background: #18191a;
    border: 1px solid #373737;
    box-shadow: 4px 15px 40px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    width: 350px;
    z-index: 1055;

    h2 {
      color: #dadada;
    }

    .btn-yes {
      background: #8767ed;
      border-radius: 15px;
      font-weight: 600;
      font-size: 22px;
      color: #f0ebeb;
      border: none;
      transition: all 0.3s ease;
      :hover {
        opacity: 0.9;
      }
    }

    .btn-no {
      background: #2b3247;
      border-radius: 15px;
      font-weight: 600;
      font-size: 22px;
      color: #f0ebeb;
      border: none;
      transition: all 0.3s ease;
      :hover {
        opacity: 0.9;
      }
    }
  }

  .addUSDPI {
    top: 104px;
    background: #18191a;
    border: 1px solid #373737;
    box-shadow: 4px 15px 40px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    width: 350px;
    z-index: 1055;

    h2 {
      color: #dadada;
    }

    .btn-yes {
      background: #01c2c5;
      border-radius: 15px;
      font-weight: 600;
      font-size: 22px;
      color: #f0ebeb;
      border: none;
      transition: all 0.3s ease;
      :hover {
        opacity: 0.9;
      }
    }

    .btn-no {
      background: #2b3247;
      border-radius: 15px;
      font-weight: 600;
      font-size: 22px;
      color: #f0ebeb;
      border: none;
      transition: all 0.3s ease;
      :hover {
        opacity: 0.9;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1399px) {
    zoom: 0.7;
  }
`;
