import React from 'react';
import WalletRow from '~/components/WalletRow';

import { Container } from './styles';

import picasso from '~/assets/defaults/picasso-example.png';
import sun from '~/assets/defaults/sun-example.png';
import monkey from '~/assets/defaults/monkey-example.png';
import car from '~/assets/defaults/car-example.png';
import hero from '~/assets/defaults/hero-example.png';
import rabit from '~/assets/defaults/rabit-example.png';
import qrCode from '~/assets/defaults/qr-code-example.png';

const MyNfts: React.FC = () => {
  const nftExample = [
    {
      id: 1,
      img: picasso,
      description: 'Picasso painting of an angry monkey',
    },

    {
      id: 2,
      img: sun,
      description:
        'A synthwave style sunset above the reflecting water of the sea, digital art',
    },

    {
      id: 3,
      img: monkey,
      description: 'High quality photo of a monkey astronaut',
    },

    {
      id: 4,
      img: car,
      description: 'A Formula 1 car driving on a neon road',
    },

    {
      id: 5,
      img: hero,
      description: 'A comic book cover of a superhero wearing headphones',
    },

    {
      id: 6,
      img: rabit,
      description:
        'bored rabbit, cyberpunk 2077 cyborg face cybernetics, realistic, uplight, dramatic light,',
    },
  ];
  return (
    <Container>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 px-0 text-end">
            <WalletRow />
          </div>
          <div className="col-12">
            <h1 className="h3 h2-lg text-center mt-4 mb-0 mt-lg-0 fw-bold">
              NTFs
            </h1>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          {nftExample.map((img) => (
            <div className="col-sm-6 col-lg-4 px-4 py-3">
              <div className="bg-nft">
                <img
                  src={img.img}
                  alt="Basket Example"
                  className="w-100 nft p-2"
                />
                <div className="d-flex pt-4 pb-3 justify-content-center align-items-center">
                  <div className="w-60">
                    <span>
                      Serial #<br />
                      <span className="fw-bold">0000123</span>
                    </span>
                  </div>
                  <div className="w-30">
                    <img src={qrCode} alt="Qr Code" className="w-100 qr-code" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default MyNfts;
