import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(152, 148, 148, 0.1);
  box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(15px);
  border-radius: 30px;

  .stats {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #ffffff;
  }

  .bg-win {
    background: rgba(152, 148, 148, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 20px;
  }

  .value-profiti {
    background: rgba(0, 0, 0, 0.46);
    box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);

    border-radius: 15px;
  }

  .value-profiti img {
    height: 28px;
    width: 28px;
  }

  .value-profiti span {
    color: #fff;
    font-weight: 600;
  }

  .text-gradient {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .text-gray {
    color: #7b7b7b;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
  }

  .stats-wallet {
    height: 40px;
  }
  .stats-wallet button {
    cursor: pointer;
    border: none;
    padding: 0 0 2px 0;
    background: transparent;
    /* background-image: linear-gradient(180deg, #100f0f, #100f0f);
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important; */

    transition-duration: 0.3s;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #7d7777;
    span {
      padding: 5px 10px;
      display: block;
    }
    /* :hover {
      background-image: linear-gradient(180deg, #100f0f, #100f0f),
        linear-gradient(
          239.23deg,
          #9df3ff 7.84%,
          #bcffe5 28.62%,
          #deefd1 38.94%,
          #ffe0be 49.96%,
          #fbc2d3 64.82%,
          #fabcd6 67.63%,
          #f281ff 91.88%
        ) !important;
      color: #fff;
      font-size: 22px;
    } */
  }

  .stats-wallet-active {
    /* background-image: linear-gradient(180deg, #100f0f, #100f0f),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important; */
    color: #fff !important;
    font-size: 22px !important;
  }
  // .WithDrawBox {
  //   background: linear-gradient(0deg, #18191a, #18191a);
  //   box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
  //   border-radius: 40px;
  // }

  .BalanceBox {
    border-radius: 20px;
    background: rgba(152, 148, 148, 0.1);
    box-shadow: 3px 4px 32px 0px rgba(0, 0, 0, 0.54);
    backdrop-filter: blur(15px);
    height: 85px;
    padding: 11px;
  }

  .BalanceBoxInner {
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.46);
    box-shadow: 3px 4px 32px 0px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    height: 41px;
    padding: 7px 10.7px 6px 7px;
    color: #ddd;
    text-align: right;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .BalanceBoxLabel {
    color: #fff;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5.93px;
    margin-left: 10.2px;
  }

  .BalanceBoxLogo {
    width: 28px;
    height: 28px;
  }

  .WithDrawBox .TitleWithDrawBox {
    color: #828282;
  }

  .WithDrawBox .ValueWithDrawBox {
    color: white;
    font-weight: bolder;
    font-size: 20px;
  }

  .border-rad {
    border-radius: 20px;
  }

  .WithDrawBox .ButtonWithDrawBox {
    background: rgba(152, 148, 148, 0.1);
    color: white;
    border-radius: 20px;
    font-size: 20px;
    padding: 12px;
    transition-duration: 0.3s;
    border: 1.5px solid var(--Gradient-2, #9df3ff) !important;
    backdrop-filter: blur(15px);
  }

  .ButtonWithDrawBox:hover {
    opacity: 0.8;
  }

  .WithDrawBox .ButtonBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .WithDrawBox .ButtonBox .ButtonWithDrawBox {
  }
  .bd-input {
    border: 1px solid #4f4f4f;
    border-radius: 15px;
  }

  .bg-box-input {
    border-radius: 20px;
    border: 1px solid #4f4f4f;
    background: rgba(24, 25, 26, 0.3);
  }

  .btn-clear {
    color: #252525;
    text-align: center;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid #2f2f2f;
    background: rgba(255, 255, 255, 0.4);
  }

  .btn-max {
    color: #797979;
    text-align: center;
    font-family: 'Inter';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .bd-input input {
    background: transparent;
    border: none;
    font-weight: 300;
    font-size: 16px;
    color: #989898;

    ::placeholder {
      font-weight: 300;
      font-size: 12px;
      color: #989898;
    }
  }

  .bd-input .btn-max {
    background: #1d2023;
    border: 1px solid #2f2f2f;
    border-radius: 10px;
    font-weight: 700;
    font-size: 10px;
    color: #797979;
  }

  div.tableData .rdt_TableHeader {
    display: none;
  }

  div.rdt_TableCol .rdt_TableCol_Sortable div {
    color: #e9e9e9 !important;
    font-size: 14px !important;
  }

  div.tableData {
    min-height: unset;
    max-height: 250px;
    overflow: auto;
  }

  div.rdt_TableCell div {
    color: #929292 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .table {
    border-radius: 20px;
    background: rgba(152, 148, 148, 0.1);
    box-shadow: 3px 4px 32px 0px rgba(0, 0, 0, 0.54);
    backdrop-filter: blur(15px);
    justify-content: center !important;
    h2 {
      font-size: 14px !important;
      color: #fff;
      margin-bottom: -10px !important;
    }
  }

  div.tableData div {
    background-color: transparent;
  }
`;

export const Graph = styled.div`
  background: rgba(152, 148, 148, 0.1);
  box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(15px);
  border-radius: 20px;
  padding: 20px 0 0 0;

  @media screen and (max-width: 991px) {
    padding: 10px 0;
  }
`;
