import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Banner } from './styles';

import Timeline from '~/components/Timeline';
import Player from '~/components/Player';
import WalletAvatar from '~/components/WalletAvatar';
import retangle from '~/assets/defaults/thumbnail-opportunity-rectangle.png';
import thumbnail from '~/assets/defaults/thumbnail-opportunity-new.png';

const AiExpect: React.FC = () => {
  const [claimNft, setClaimNft] = useState(false);
  localStorage.setItem('@PROFITi:lastPage', `FUNNEL`);

  const history = useHistory();
  const steps = useMemo(
    () => [
      {
        value: 'Ai avatar',
        active: true,
      },
      {
        value: 'What To Expect',
        active: true,
        semi: true,
      },
      {
        value: 'Activate Your Profit Centers',
        active: false,
      },
      {
        value: 'Earn Even More!',
        active: false,
      },
      {
        value: 'Final Step',
        active: false,
      },
    ],
    []
  );

  const handleClick = useCallback(() => {
    setClaimNft(true);
  }, []);

  return (
    <>
      <Banner className="position-relative overflow-hidden w-100">
        <div className="container-fluid">
          <div className="row vh-lg-100 overflow-auto">
            <div className="bg-gray col-lg-2 px-0">
              <Timeline steps={steps} />
            </div>

            <div className="col-lg-10 max-width pb-3 p-lg-5 text-center text-lg-end">
              <div className="d-none d-lg-flex justify-content-end logo mb-n5">
                <WalletAvatar />
              </div>
              <div className="row justify-content-center px-lg-5 mb-2 mb-lg-4">
                <div className="col-lg-9 text-center zoom">
                  <h1 className="h2 display-lg-4">
                    <span>What To Expect</span>
                  </h1>
                  <div className="row justify-content-center">
                    <div className="col-lg-12 col-xxl-10">
                      <p className="gradient-parts h5 my-4 my-lg-2">
                        <span>Elevate your social media game with</span>{' '}
                        <b>mind-blowing personalized avatars</b>{' '}
                        <span>
                          that will leave your friends and followers speechless
                        </span>{' '}
                        <b>generated by state-of-the-art AI</b>{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center px-lg-5">
                <div className="col-11 col-md-9 position-relative">
                  <img src={retangle} alt="" className="back-retangle-right" />
                  <img src={retangle} alt="" className="back-retangle-left" />

                  <Player
                    src="https://prfti.s3.amazonaws.com/1.mp4"
                    thumbnail={thumbnail}
                  />
                </div>
              </div>

              <div className="row justify-content-center px-lg-5 mt-4">
                <div className="col-11 col-md-9 zoom btn-claim">
                  <button
                    type="button"
                    // onClick={handleClick}
                    // onClick={() =>
                    //   history.push(`${process.env.PUBLIC_URL}/dashboard`)
                    // }
                    className="w-100 mt-4 mt-lg-0"
                  >
                    <b>GET YOURS FREE NOW</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Banner>
    </>
  );
};

export default AiExpect;
