import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { BsArrowLeftShort } from 'react-icons/bs';

import { Banner, AvatarInput } from './styles';
import Timeline from '~/components/Timeline';
import Input from '~/components/Input';
import InputDate from '~/components/InputDate';
import StatusPayment from '~/components/StatusPayment';

import defaultAvatar from '~/assets/defaults/default-avatar.png';
import camera from '~/assets/icons/camera-icon.svg';
import api from '~/services/api';
import { useResize } from '~/hooks/Resize';
import getValidationErros from '~/utils/getValidationsErrors';
import { formatToBrazilianDate } from '~/utils/convertTime';
import swalError from '~/utils/swalError';

interface IUser {
  id: string;
  avatar_id: string;
  name: string;
  email: string;
  phone: string;
  birthdate: Date;
  referral_code: string;
}

interface userFormData {
  name: string;
  email: string;
  phone: string;
  birthdate: string;
  referral_code: string;
}

const Register: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { width } = useResize();
  const [confirmData, setConfirmData] = useState(false);
  const [phone, setPhone] = useState('');
  const [user, setUser] = useState<IUser>({} as IUser);
  const [userData, setUserData] = useState<userFormData>({} as userFormData);
  const [checked, setChecked] = useState(false);
  const [birthdate, setBirthdate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [avatarSelected, setAvatarSelected] = useState<File | null>(null);
  const [referralCode, setReferralCode] = useState('@');
  const [dateError, setDateError] = useState(false);

  const steps = useMemo(
    () => [
      {
        value: 'Entenda a Oportunidade',
        active: width > 991,
      },
      {
        value: 'Como fazer parte',
        active: width > 991,
      },
      {
        value: 'Efetuar Pagamento',
        active: true,
      },
      {
        value: 'Finalizar Cadastro',
        active: true,
      },
    ],
    [width]
  );

  useEffect(() => {
    // setLoading(true);
    // const user_id = localStorage.getItem('hash_user');
    // api
    //   .get(`users/${user_id}`)
    //   .then(async (responseUser) => {
    //     if (
    //       responseUser.data.step === 'como-fazer-parte' ||
    //       responseUser.data.step === 'efetuar-pagamento' ||
    //       responseUser.data.step === 'finalizar-cadastro'
    //     ) {
    //       // await api.patch(`users/${user_id}`, {
    //       //   step: 'finalizar-cadastro',
    //       // });
    //     }
    //     // const response = await api.get(`users/${user_id}`);
    //     // if (response.data) {
    //     //   setUser(response.data);
    //     //   setReferralCode(response.data.referral_code);
    //     // }
    //   })
    //   .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [confirmData]);

  const handleBlurName = useCallback((e) => {
    const value = e.target.value.toLowerCase();
    const nameParts = value.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    setReferralCode(`@${firstName}-${lastName}`);
  }, []);

  const handleChangeReferralCode = useCallback((e) => {
    const value = e.target.value.toLowerCase();
    if (value.charAt(0) !== '@') {
      setReferralCode(`@${value}`);
    } else {
      setReferralCode(value);
    }
  }, []);

  const handleChange = useCallback((e) => {
    setChecked(e.target.checked);
  }, []);

  const handleSelectAvatar = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setAvatar(URL.createObjectURL(file));
        setAvatarSelected(file);
      } else {
        setAvatar('');
        setAvatarSelected(null);
      }
    }
  }, []);

  const handleNext = useCallback(
    async (data: userFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('Deve ser um e-mail válido')
            .required('O e-mail é obrigatório'),
          phone: Yup.string().required('O telefone é obrigatório'),
          referral_code: Yup.string().required(
            'O codigo de referencia é obrigatório'
          ),
          birthdate: Yup.string().required('O nascimento é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        const newData = data;
        newData.birthdate = formatToBrazilianDate(birthdate);
        newData.referral_code = referralCode;
        setUserData(newData);
        setConfirmData(true);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (errors.birthdate) {
            setDateError(true);
          } else {
            setDateError(false);
          }
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          const err: any = error;
          swalError({
            message: err.response.data.message,
            textButton: 'Close',
          }).then(() => setLoading(false));
        }
      }
    },
    [birthdate, referralCode]
  );

  const handleChangeBirthday = useCallback((e) => {
    setBirthdate(e);
  }, []);

  const handleChangePhone = useCallback((e) => {
    const phoneNumber = e.target.value.replace(/\D/gim, '');
    setPhone(phoneNumber);
  }, []);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const responseAvatar = {
        data: {
          id: user.avatar_id,
        },
      };

      if (avatarSelected) {
        const avatarData = new FormData();
        avatarData.append('avatar', avatarSelected as File);
        // responseAvatar = await api.post('avatars', avatarData);
      }

      if (responseAvatar.data) {
        const { name, email, referral_code } = userData;

        const formData = {
          avatar_id: responseAvatar.data.id,
          name,
          email,
          phone: phone.replace(/\D/gim, ''),
          birthdate,
          referral_code,
        };
        // const response = await api.put(`users/${user?.id}`, formData);
        // if (response.data) {
        setLoading(false);
        history.push(`${process.env.PUBLIC_URL}/verifique-seu-email`);
        // }
      }
    } catch (error) {
      const err: any = error;
      swalError({
        message: err.response.data.message,
        textButton: 'Close',
      }).then(() => setLoading(false));
    }
  }, [avatarSelected, birthdate, history, phone, user.avatar_id, userData]);

  return (
    <>
      <StatusPayment />
      <Banner className="position-relative overflow-hidden w-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 px-0">
              <Timeline steps={steps} />
            </div>
            <div
              className={`col-lg-9 pb-3 py-lg-5 ${
                !confirmData ? 'd-block' : 'd-none'
              }`}
            >
              <div className="row justify-content-center px-lg-5 mb-4">
                <div className="col-lg-10 text-center justify-content-center d-flex">
                  <div className="align-items-center position-relative d-none d-lg-flex">
                    <button
                      type="button"
                      onClick={() => history.goBack()}
                      className="position-absolute arrow d-flex align-items-center pos-30"
                    >
                      <BsArrowLeftShort size={30} />
                      <span className="mr-2 d-block">Voltar</span>
                    </button>
                  </div>
                  <div>
                    <h1 className="display-lg-4 my-lg-4 font-weight-bold">
                      <span className="title">
                        <span className="title">Informações</span>{' '}
                        <span className="title">pessoais</span>
                      </span>
                    </h1>
                    <div className="row justify-content-center">
                      <div className="col-lg-9">
                        <p>
                          <span>
                            Preencha os dados abaixo para finalizar seu cadastro
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center align-items-center px-lg-5">
                <div className="col-11 col-md-10 col-lg-11 col-xxl-9 bg-gray py-5 px-md-5">
                  <Form ref={formRef} initialData={user} onSubmit={handleNext}>
                    <div className="row justify-content-center">
                      <div className="col-lg-3 d-flex flex-column align-items-center text-center">
                        <AvatarInput
                          htmlFor="avatar"
                          className="position-relative mb-3"
                        >
                          <span>
                            <img
                              src={avatar || defaultAvatar}
                              alt="Avatar"
                              className="border-gradient"
                            />
                          </span>
                          <div className="camera position-absolute">
                            <img src={camera} alt="Camera" />
                          </div>
                        </AvatarInput>
                        <Input
                          type="file"
                          name="avatar"
                          id="avatar"
                          className="d-none"
                          onChange={handleSelectAvatar}
                        />
                      </div>
                      <div className="col-12 mt-4">
                        <label htmlFor="name">Nome Completo</label>
                        <Input
                          name="name"
                          id="name"
                          className="input"
                          onBlur={handleBlurName}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label htmlFor="email">E-mail</label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          className="input"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label htmlFor="referral_code">
                          Criar Código de indicação
                        </label>
                        <Input
                          name="referral_code"
                          id="referral_code"
                          className="input"
                          onChange={handleChangeReferralCode}
                          value={referralCode}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label htmlFor="phone">Número de Telefone</label>
                        <Input
                          name="phone"
                          id="phone"
                          className="input"
                          onChange={handleChangePhone}
                          value={phone}
                        />
                      </div>
                      <div className="col-md-6 mt-3 d-flex flex-column">
                        <label htmlFor="birthdate">Data de Nascimento</label>
                        <InputDate
                          name="birthdate"
                          onChange={handleChangeBirthday}
                          selected={birthdate}
                          className="input px-2 w-100"
                          isError={dateError}
                        />
                      </div>
                      <div className="col-12 mt-5">
                        <button
                          type="submit"
                          className="btn btn-gradient w-100 font-weight-bold"
                        >
                          Próximo
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div
              className={`col-lg-9 py-5 ${confirmData ? 'd-block' : 'd-none'}`}
            >
              <div className="row justify-content-center px-lg-5 mb-4">
                <div className="col-lg-10 text-center">
                  <div className="align-items-center p.relative d-none d-lg-flex">
                    <button
                      type="button"
                      onClick={() => history.goBack()}
                      className="position-absolute arrow d-flex align-items-center pos-30"
                    >
                      <BsArrowLeftShort size={30} />
                      <span className="mr-2 d-block">Voltar</span>
                    </button>
                  </div>
                  <h2 className="h1 mt-lg-4 display-lg-4 font-weight-bold">
                    <span className="title">Confirmar dados</span>
                  </h2>
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <p className="text-gradient">
                        <span>
                          Verifique se tudo está correto antes de finalizar
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center align-items-center px-lg-5">
                <div className="col-11 col-md-10 col-lg-11 col-xxl-9 bg-gray py-5 px-md-5">
                  <Form onSubmit={handleSubmit}>
                    <div className="row justify-content-center">
                      <div className="col-lg-3 d-flex justify-content-center text-center">
                        <AvatarInput htmlFor="avatar">
                          <span>
                            <img
                              src={avatar || defaultAvatar}
                              alt="Avatar"
                              className="border-gradient"
                            />
                          </span>
                        </AvatarInput>
                      </div>
                      <div className="col-12 mt-4 text-center">
                        <p className="h5">
                          <b>{userData.name}</b>
                        </p>
                        <p className="h5 mb-5 color-code">
                          <b>{userData.referral_code}</b>
                        </p>
                        <div className="mb-0 mt-3 d-lg-flex w-100 w-lg-75 mx-auto justify-content-around">
                          <p className="mb-0 text-break">{userData.email}</p>
                          <p className="mb-0 d-none d-lg-block">|</p>
                          <p className="mb-0">{userData.phone}</p>
                          <p className="mb-0 d-none d-lg-block">|</p>
                          <p className="mb-0">{userData.birthdate}</p>
                        </div>
                        <hr />
                        <label
                          htmlFor="accept"
                          className="w-100 w-md-75 w-xxl-50 d-flex align-items-center justify-content-center mx-auto checkbox"
                        >
                          <Input
                            type="checkbox"
                            name="accept"
                            id="accept"
                            className={`checkbox ${checked ? 'checked' : ''}`}
                            onChange={handleChange}
                          />
                          <span>
                            Aceito e concordo com os{' '}
                            <br className="d-block d-md-none" />
                            <a href="#/" className="font-weight-bold">
                              termos de uso
                            </a>
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6 mt-2 mt-lg-5">
                        <button
                          type="button"
                          className="btn border-gradient w-100"
                          onClick={() => setConfirmData(false)}
                        >
                          <span>CORRIGIR DADOS</span>
                        </button>
                      </div>
                      <div className="col-md-6 mt-2 mt-lg-5">
                        <button
                          type="submit"
                          className="btn btn-gradient w-100"
                          disabled={!checked}
                        >
                          <b>FINALIZAR</b>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Banner>
    </>
  );
};

export default Register;
