import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface Iprops {
  src: string;
}

export const Container = styled.div`
  .btn-edit {
    font-size: 12px;
    color: #8c8c8c;
    font-weight: 300;
    border: 1px solid #4a4b4b;
    background-color: #1d2023;
    padding: 5px 20px;
  }
  .bg-gray {
    background: #1d2023;
    border-radius: 20px;

    .border-right {
      border-color: #393c3d !important;
    }

    h2 {
      color: #dadada;
    }
  }

  .slick-prev {
    left: 15px;
    z-index: 100;
  }
  .slick-next {
    right: 15px;
    z-index: 100;
  }

  .slick-dots li.slick-active button:before {
    color: #cbccce !important;
    font-size: 13px !important;
  }

  .slick-dots li button:before {
    color: #4f4f4f !important;
    font-size: 13px !important;
  }

  .slick-dots {
    bottom: -35px;
  }

  .slick-dots li {
    margin: 0 !important;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #cbccce;
  }
`;

export const Welcome = styled.div`
  h1,
  button {
    color: #dadada;
  }

  .border-gradient-light {
    max-width: 200px;
  }

  p {
    color: #969696;
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }
`;

export const NewsCarousel = styled.button<Iprops>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  height: 580px;
  border-radius: 20px;
  @media screen and (max-width: 991px) {
    height: 56vw;
  }
`;

export const TimeLine = styled.div`
  background-color: #1d2023;
  border-radius: 20px;
  h2,
  h3 {
    color: #dadada;
  }

  h3 {
    font-weight: 400;
    font-size: 18px;
  }
  .timeline {
    background-color: #1d2023 !important;
    border: none;
    padding: 0 0 0 1px;
    background-image: linear-gradient(180deg, #1d2023, #1d2023),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    list-style: none;
  }

  .timeline .timeline-item {
    position: relative;
  }

  .timeline .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
  }

  .timeline .timeline-item:after {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    left: -8px;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    content: '';
  }

  .bg-paragraph {
    background-color: #222527;
    border-radius: 20px;

    p {
      color: #929292;
      font-weight: 400;
      font-size: 18px;
    }
  }

  @media screen and (min-width: 992px) {
    h2 + div {
      height: 412px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-body {
    h2 {
      color: #dadada;
      font-size: 24px;
      font-weight: 600;
    }
    .p-color {
      color: #828282 !important;
    }
    p {
      color: #c4c4c4;
    }
  }

  .modal-close {
    position: absolute;
    right: 15px;
    top: 5px;
    z-index: 10000;
    color: #dadada;
    height: 50px;
    width: 50px;
  }
`;

export const BgTitle = styled.div<Iprops>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 30px 30px 0 0;
  height: 348px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  h2 {
    font-size: 24px;
  }
`;
