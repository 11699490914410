import styled, { css } from 'styled-components';

interface ISidebar {
  active: boolean;
}

interface IAvatar {
  theme: string;
  src: string;
}

export const Container = styled.div`
  min-height: 100vh;
  transition-duration: 0.3s;

  ${(props) => {
    switch (props.theme) {
      case 'light':
        return css`
          background-color: #f2f4f5;
        `;

      default:
        return css`
          background-color: #18191a;
        `;
    }
  }}

  .text-disconnect {
    font-weight: 600;
    font-size: 36px;
    color: #dadada;
  }

  .disconnect {
    top: 15%;
    left: 90%;
    background: #18191a;
    border: 1px solid #373737;
    box-shadow: 4px 15px 40px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    width: 350px;
    z-index: 1055;
  }

  .exit {
    top: 5%;
    left: 0%;
    background: #18191a;
    border: 1px solid #373737;
    box-shadow: 4px 15px 40px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    width: 350px;
    z-index: 1055;
  }

  .btn-to-dashboard {
    background: #3778ff !important;
  }

  .btn-yes {
    background: #ff1a50;
    border-radius: 15px;
    font-weight: 600;
    font-size: 22px;
    color: #f0ebeb;
    border: none;
    transition: all 0.3s ease;
    :hover {
      opacity: 0.9;
    }
  }

  .btn-no {
    background: #2b3247;
    border-radius: 15px;
    font-weight: 600;
    font-size: 22px;
    color: #f0ebeb;
    border: none;
    transition: all 0.3s ease;
    :hover {
      opacity: 0.9;
    }
  }
`;

export const Menu = styled.div`
  transition-duration: 0.3s;

  button {
    font-weight: 300;
    line-height: 19px;

    > span {
      border: 1px solid transparent;
      border-radius: 14px;
      height: 46px;
      width: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 13px;
      transition-duration: 0.3s;
    }
  }

  ${(props) => {
    // console.log('********************');
    // console.log(props);
    // console.log('********************');
    switch (props.theme) {
      case 'light':
        return css`
          button {
            color: #626262;

            > span {
              box-shadow: 1px 16px 30px rgba(65, 65, 65, 0.06);
              background-color: #fafafa;
              border-color: #ffffff;
            }
          }
        `;

      default:
        return css`
          button {
            color: #c4c4c4;

            > span {
              box-shadow: 1px 12px 30px rgba(0, 0, 0, 0.1);
              background-color: #1f2020;
              border-color: #282929;
            }
          }
        `;
    }
  }}

  .btn-back {
    z-index: 100;
    left: 1rem;
    top: 1.5rem;
  }

  .btn-back img {
    height: 30px;
  }
`;

export const Sidebar = styled.div<ISidebar>`
  border: 1px solid transparent;
  border-radius: 30px;
  font-family: 'Quicksand', sans-serif !important;
  width: 100%;
  max-width: 377px;
  height: calc(100vh - 2rem);
  top: 0;
  left: ${(props) => (props.active ? 0 : '-1000px')};
  transition-duration: 0.3s;

  padding: 5rem 1rem;

  button,
  a {
    font-family: 'Quicksand', sans-serif !important;
  }

  .all-talks {
    height: calc(100vh - 500px);
  }

  .all-talks .chat-title {
    font-size: 18px;
  }

  .hover-conversation {
    height: 59px;
  }

  .hover-conversation p {
    white-space: nowrap;
    overflow: hidden;
    color: #a9a9a9;
    font-weight: 600;
  }

  .btn-talk {
    white-space: nowrap;
    overflow: hidden;
    color: #a9a9a9;
    font-weight: 600;
  }

  .div-background {
    background: linear-gradient(
      270deg,
      #1f2020 18.23%,
      rgba(31, 32, 32, 0) 74.72%
    );
    width: 100px;
    height: 30px;
    right: 0;
  }

  .btn-new {
    border: 1px solid transparent;
    border-radius: 18px;
    font-weight: 700;
    transition-duration: 0.3s;
    font-size: 22px;
  }

  p {
    color: #5a5b5b;
    font-size: 18px;
  }

  .btn-clear {
    color: #ff1a50;
    font-weight: 400;
    font-size: 16px;
  }

  hr {
    margin-top: 5px;
    opacity: 1;
    height: 1px;
    width: 100%;
    border: 0;
    transition-duration: 0.3s;
  }

  .talk {
    a {
      font-weight: 600;
      font-size: 18px;
      line-height: 134.08%;
      letter-spacing: 0.09em;
      display: flex;
      transition-duration: 0.3s;
    }

    button:not(.btn-talk) {
      opacity: 0;
      visibility: hidden;
      transition-duration: 0.3s;

      svg {
        transition-duration: 0.3s;
      }
    }

    :hover {
      background: rgba(24, 25, 26, 0.5);
      border-radius: 12px;
      .div-background {
        display: none !important;
      }
    }
    :hover,
    :focus {
      background: rgba(24, 25, 26, 0.88);
      border-radius: 12px;
      button {
        opacity: 1;
        visibility: visible;
      }
      .div-background {
        display: none !important;
      }
      .btns-edit-trash {
        background: rgba(24, 25, 26, 1);
        height: 30px;
      }
    }

    /* + .talk {
      margin-top: 20px;
    } */
  }

  .active {
    background: rgba(24, 25, 26, 0.88);
    border-radius: 12px;
    button {
      opacity: 1 !important;
      visibility: visible !important;
    }
    .div-background {
      display: none !important;
    }
    .btns-edit-trash {
      background: rgba(24, 25, 26, 1);
      height: 30px;
    }
  }

  .btns-edit-trash {
    right: 15px;
  }

  .btn-show-more {
    border: 1px solid transparent;
    border-radius: 18px;
    font-weight: 600;
    font-size: 18px;
    line-height: 134.08%;
    letter-spacing: 0.09em;
    transition-duration: 0.3s;
    margin-bottom: 3rem;
  }

  .more-options {
    a {
      font-weight: 600;
      font-size: 18px;
      line-height: 134.08%;
      letter-spacing: 0.09em;
      display: flex;

      svg {
        display: block;
        margin-right: 10px;
      }

      + a {
        margin-top: 20px;
      }
    }

    .faq {
      color: #5a5b5b;
    }
  }

  ${(props) => {
    switch (props.theme) {
      case 'light':
        return css`
          background-color: #fafafa;
          border-color: #ffffff;
          box-shadow: 1px 16px 30px rgba(65, 65, 65, 0.06);

          .btn-new {
            border-color: #c4c4c4;
            color: #626262;
          }

          hr {
            background-color: #c4c4c4;
          }

          .talk {
            a {
              color: #626262;
            }

            button:nth-child(1) {
              svg {
                fill: #828282;
              }
            }

            button:nth-child(2) {
              svg {
                stroke: #828282;
              }
            }
          }

          .btn-show-more {
            background-color: #fff;
            border-color: #f1f1f1;
            color: #626262;
          }

          .more-options {
            a {
              color: #626262;
            }

            .faq {
              color: #626262;
            }
          }
        `;

      default:
        return css`
          background-color: #1f2020;
          border-color: #282929;
          box-shadow: 1px 12px 30px rgba(0, 0, 0, 0.1);

          .btn-new {
            border-color: #5a5c65;
            color: #c4c4c4;
          }

          hr {
            background-color: #333333;
          }

          .talk {
            a {
              color: #c4c4c4;
            }

            button:nth-child(1) {
              svg {
                fill: #5a5b5b;
              }
            }

            button:nth-child(2) {
              svg {
                stroke: #5a5b5b;
              }
            }
          }

          .btn-show-more {
            background-color: rgba(24, 25, 26, 0.38);
            border-color: #2c2d2e;
            color: #c4c4c4;
          }

          .more-options {
            a {
              color: #c4c4c4;
            }

            .faq {
              color: #5a5b5b;
            }
          }
        `;
    }
  }}
`;

export const Content = styled.div`
  min-height: calc(100vh - 72px);
  transition-duration: 0.3s;

  h1 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 48px;
    text-align: center;
    transition-duration: 0.3s;
  }

  p {
    font-family: 'Quicksand', sans-serif;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    transition-duration: 0.3s;
  }

  .fade-chat {
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 120px;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(24, 25, 26, 0) 7.58%,
      rgba(24, 25, 26, 0.55) 23.61%,
      rgba(24, 25, 26, 0.75) 33.61%,
      #18191a 42.47%
    );
  }

  .title-edit {
    button {
      opacity: 0;
      transition-duration: 0.3s;
    }
    :hover {
      button {
        opacity: 1;
      }
    }
  }

  .chat-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 48px;
    text-align: center;
    transition-duration: 0.3s;
  }

  .action-buttons {
    button {
      margin-right: 15px;
      border: 1px solid transparent;
      backdrop-filter: blur(5px);
      border-radius: 14px;
      height: 42px;
      width: 230px;
      transition-duration: 0.3s;

      svg {
        margin-right: 10px;
        transition-duration: 0.3s;
      }

      span {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        transition-duration: 0.3s;
      }
    }
  }

  .input {
    border: 1px solid transparent;
    border-radius: 22px;
    height: 82px;
    display: flex;
    align-items: center;
    padding: 32px 79px 32px 47px;
    margin-top: 38px;
    transition-duration: 0.3s;

    > div {
      background: transparent;
      border: none;
      padding: 0;

      input {
        font-weight: 300;
        line-height: 19px;
        transition-duration: 0.3s;

        ::placeholder {
          transition-duration: 0.3s;
        }
      }
      input[disabled] {
        opacity: 0.6;
        //pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  button[disabled] {
    opacity: 0.6;
    //pointer-events: none;
    cursor: not-allowed;
  }

  button[type='submit'] {
    svg {
      display: block;
      margin-right: 11px;
      transition-duration: 0.3s;
    }

    .tiping {
      position: relative;
      width: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #c4c4c4;

        :nth-child(1) {
          /* transform: translateY(0px); */
          animation: jump1 1s infinite;
        }

        :nth-child(2) {
          /* transform: translateY(-5px); */
          animation: jump2 1s infinite;
          animation-delay: 0.25s;
        }

        :nth-child(3) {
          /* transform: translateY(0px); */
          animation: jump3 1s infinite;
          animation-delay: 0.5s;
        }

        @keyframes jump1 {
          0% {
            transform: translateY(5px);
          }
          50% {
            transform: translateY(-5px);
          }
          100% {
            transform: translateY(5px);
          }
        }

        @keyframes jump2 {
          0% {
            transform: translateY(5px);
          }
          50% {
            transform: translateY(-5px);
          }
          100% {
            transform: translateY(5px);
          }
        }

        @keyframes jump3 {
          0% {
            transform: translateY(5px);
          }
          50% {
            transform: translateY(-5px);
          }
          100% {
            transform: translateY(5px);
          }
        }
      }
    }
  }

  ${(props) => {
    switch (props.theme) {
      case 'light':
        return css`
          h1 {
            color: #3a3a3a;
          }

          p {
            color: #626262;
          }

          .action-buttons {
            button {
              background-color: #fafafa;
              border-color: #ffffff;

              svg {
                stroke: #828282;
              }

              span {
                color: #828282;
              }
            }
          }

          .input {
            background-color: #fafafa;
            border-color: #ffffff;
            box-shadow: 1px 16px 30px rgba(65, 65, 65, 0.06);

            > div {
              input {
                ::placeholder {
                  color: #9a9b9b;
                }
              }
            }
          }

          button[type='submit'] {
            color: #c4c4c4;

            svg {
              stroke: #9a9b9b;
            }
          }
        `;

      default:
        return css`
          h1 {
            color: #cdcecf;
          }

          .chat-title {
            color: #cdcecf;
          }

          p {
            color: #afafaf;
          }

          .action-buttons {
            button {
              background-color: rgba(31, 32, 32, 0.22);
              border-color: #282929;

              svg {
                stroke: #5a5b5b;
              }

              span {
                color: #7d7777;
              }
            }
          }

          .input {
            background-color: #1f2020;
            border-color: #282929;
            box-shadow: 1px 16px 30px rgba(0, 0, 0, 0.1);

            > div {
              input {
                ::placeholder {
                  color: #5a5b5b;
                }
              }
            }
          }

          button[type='submit'] {
            color: #c4c4c4;

            svg {
              stroke: rgba(196, 196, 196, 0.6);
            }
          }
        `;
    }
  }}
`;

export const Messages = styled.div`
  max-height: calc(100vh - 300px);
  overflow: auto;
  //padding-left: 20px;

  pre {
    background-color: #1e1e1e;
    border: 1px solid #282929;
    border-radius: 10px;
    padding: 30px;
    overflow-x: auto; /* rolagem horizontal automática */

    code {
      padding: 0 !important;
    }
  }

  pre.tag-pre {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0px !important;
    overflow-x: unset !important;
    white-space: pre-wrap !important;
    font-family: 'Quicksand', sans-serif !important;
    font-size: 20px !important;
  }

  code.tag-code {
    font-family: monospace;
    color: #a9a9a9;
    background-color: #1f2020;
    border: 1px solid #282929;
    padding: 2px;
    border-radius: 3px;
    padding: 0px 5px;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    transition-duration: 0.3s;
  }

  ${(props) => {
    switch (props.theme) {
      case 'light':
        return css`
          ::-webkit-scrollbar-thumb {
            background: #c4c4c4;
          }
        `;

      default:
        return css`
          ::-webkit-scrollbar-thumb {
            background: #333333;
          }
        `;
    }
  }}
`;

export const Message = styled.div`
  .message {
    width: calc(100% - 23px);
    //width: calc(100% - 60px);
    //margin-right: 20px;

    border-radius: 16px;

    p,
    li {
      font-family: 'Quicksand', sans-serif;
      text-align: left;
      transition-duration: 0.3s;
      list-style-type: none;
      font-weight: 300;
      font-size: 20px;
      padding: 0 10px;
    }
  }

  .skeleton {
    background-color: #18191a !important;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    height: 40px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-flex;
    box-shadow: none;

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #18191a 0%,
        rgba(31, 32, 33, 0) 0%,
        rgba(31, 32, 33, 0) 10%,
        rgba(31, 32, 33, 0.2) 20%,
        rgba(31, 32, 33, 0.5) 40%,
        rgba(31, 32, 33, 0.8) 50%,
        rgba(31, 32, 33, 0.2) 80%,
        rgba(31, 32, 33, 0) 90%,
        rgba(31, 32, 33, 0) 100%
      ) !important;
      animation: glow 1.8s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .p-skeleton {
    font-weight: 300;
    font-size: 17px !important;
    color: #5a5b5b !important;
  }

  /* .skeleton {
    height: 40px;
    border-radius: 10px;
    background: linear-gradient(
      89.99deg,
      #18191a 7.12%,
      rgba(27, 27, 28, 0.81) 96.14%
    );
  } */

  .message-user {
    background: #2446fc;
    p {
      color: #eee !important;
    }
  }

  .message-paige {
    background: #111212;
  }

  .avatar {
    padding-top: 29px;
    left: 0;
  }

  + div {
    .message {
      border-radius: 16px;
      padding-bottom: 20px;
      padding-top: 29px;

      transition-duration: 0.3s;
    }
  }

  ${(props) => {
    switch (props.theme) {
      case 'light':
        return css`
          .message {
            p,
            li {
              color: #626262;
            }
          }

          + div {
            .message {
              border-color: #c4c4c4;
            }
          }
        `;

      default:
        return css`
          .message {
            p,
            li {
              color: #eee;
            }
          }

          + div {
            .message {
              p,
              li {
                color: #a9a9a9;
              }
            }
          }
        `;
    }
  }}

  .btn-like {
    bottom: 30px;
  }

  .btn-dislike {
    transform: rotate(180deg);
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: top;
  border: 1.5px solid transparent;
  border-radius: 14px;
  height: 46px;
  width: 46px;
  //margin-right: 38px;
  transition-duration: 0.3s;

  ${(props) => {
    // console.log('++++++++++++++++++++++');
    // console.log(props);
    // // console.log(props.src);
    // console.log('++++++++++++++++++++++');
    switch (props.theme) {
      case 'light':
        return css`
          box-shadow: 1px 16px 30px rgba(65, 65, 65, 0.06);
          background-color: #fafafa;
          border-color: #ffffff;
        `;

      default:
        return css`
          background-color: #1f2020;
          border-color: rgba(255, 255, 255, 0.42);
          box-shadow: 1px 12px 30px rgba(0, 0, 0, 0.1);
        `;
    }
  }}
`;

export const Settings = styled.div`
  right: 3rem;
  top: 3rem;

  button {
    border: 1px solid transparent;
    border-radius: 14px;
    height: 46px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${(props) => {
    switch (props.theme) {
      case 'light':
        return css`
          button {
            box-shadow: 1px 16px 30px rgba(65, 65, 65, 0.06);
            background-color: #fafafa;
            border-color: #ffffff;
          }
        `;

      default:
        return css`
          button {
            box-shadow: 1px 12px 30px rgba(0, 0, 0, 0.1);
            background-color: #1f2020;
            border-color: #282929;
          }
        `;
    }
  }}
`;
