import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';
import bgNft from '~/assets/banners/bg-modal.png';

export const Button = styled.button`
  background-color: #00f9ab;
  color: #000;
  border-radius: 20px;
  font-size: 24px;
  padding: 16px;
  transition-duration: 0.3s;

  :hover {
    background-color: ${darken(0.1, '#00f9ab')};
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background-image: url(${bgNft});
    background-position: center;
    background-size: cover;
    background-color: #17171a;
    box-shadow: 19px 49px 112px rgba(0, 0, 0, 0.4),
      inset 1px 1px 2px rgba(107, 107, 107, 0.3),
      inset -1px -1px 2px rgba(43, 43, 43, 0.5);
    border-radius: 30px;
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    color: #dadada;
    font-size: 48px;
  }

  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 118%;
    text-align: center;
    color: #aeabab;
    b {
      color: #a6a2a2;
    }
  }

  .header img {
    height: 52px;
    width: 52px;
  }

  .bg-main {
    background: rgba(23, 23, 25, 0.46);
    box-shadow: 0px -15px 30px rgba(0, 0, 0, 0.265139);
    backdrop-filter: blur(5px);
    border-radius: 37px 37px 0px 0px;
  }

  .bg-main p {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #f2f2f2;
  }

  .bg-nft {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #fff;
    backdrop-filter: blur(5px);
    border-radius: 40px;
  }

  /* .bg-nft img {
    height: 70%;
  } */

  .bg-nft span {
    color: #fffefb;
    font-size: 35px;
    line-height: 50px;
  }

  .nft {
    filter: drop-shadow(5px 11px 140px rgba(0, 0, 0, 0.25));
    border-radius: 35px;
  }

  .bg-serial-qrcode {
    position: absolute;
    bottom: 0.5rem;
    left: 8px;
    width: 512px;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(7.5px);

    border-radius: 0px 0px 35px 35px;
  }

  .bg-qrcode {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.62);
    border-radius: 25px;
  }

  .w-60 {
    width: 60%;
  }

  .w-30 {
    width: 30%;
  }

  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  .modal-xl {
    width: 1140px;
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }
`;
