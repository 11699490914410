import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  .bg-gray {
    background: #1d2023;
    border-radius: 20px;

    .border-right {
      border-color: #393c3d !important;
    }

    h2 {
      color: #dadada;
    }
  }
`;

export const Welcome = styled.div`
  h1,
  button {
    color: #dadada;
  }

  .border-gradient-light {
    max-width: 200px;
  }

  p {
    color: #969696;
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }
`;

export const ContainerVideoListing = styled(Link)`
  transition-duration: 0.3s;
  color: #828282;

  .thumbnail {
    overflow: hidden;
    border-radius: 10px;
  }

  .details-link {
    transition-duration: 0.3s;
    color: #dadada;
  }

  .duration {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 72px;
    height: 33px;
    color: #ffffff;
    font-size: 14px;
  }

  .avatar {
    width: 30px;
    height: 30px;
  }

  :hover {
    text-decoration: none;
    color: #828282;

    .details-link {
      color: #ffffff;
    }
  }
`;
