import styled from 'styled-components';
import { Form, Modal as ModalComponent } from 'react-bootstrap';

import { lighten } from 'polished';
import selector from '~/assets/defaults/selector.svg';

export const Container = styled.div`
  h2 {
    font-size: 20px;
    color: #dadada;
  }

  h3 {
    font-weight: 300;
    font-size: 16px;
    color: #5d5b5b;
  }
  .container {
    max-width: 1700px;
  }

  .btn-back-art {
    color: #dadada !important;
    span {
      color: #dadada !important;
      font-size: 16px;
    }
  }
`;

export const Navigator = styled.div`
  a {
    font-weight: 400;
    font-size: 20px;
    color: #8c8c8c;
  }

  .active {
    font-size: 20px;
    color: #dadada;
  }
`;

export const Modal = styled(ModalComponent)`
  label span {
    font-weight: 400;
    font-size: 20px;
    color: #dadada;
  }

  .modal-xl {
    max-width: 700px;
  }

  .form-switch .form-check-input {
    width: 3em;
    margin-left: 0px;
    height: 1.5em;
    background-image: url(${selector});
    border: 1px solid #4f4f4f90;
  }

  .form-check-input {
    background-color: transparent;
  }

  .form-check-input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  .form-check-input:checked[type='checkbox'] {
    background-color: #05050590;
    border: 1px solid #05050590;
  }

  .form-switch {
    padding-left: 0px;
  }
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    .modal-title {
      color: #dadada;
      font-size: 24px;
    }

    .input {
      border: 1px solid #4f4f4f;
      border-radius: 20px;
      height: 72px !important;
      background: transparent;

      input::placeholder {
        color: #5d5b5b;
        font-weight: 300;
        font-size: 14px;
      }
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btns {
      background: #0f0f0f;
      border-radius: 20px;
      border: none;
      height: 75px;
      color: #dadada;
      font-weight: 600;
      font-size: 22px;
      padding: 20px 40px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .create {
      padding: 20px 80px;
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;
