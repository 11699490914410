import { lighten } from 'polished';
import styled from 'styled-components';
import banner from '~/assets/banners/bg-value-cryp.png';

export const Container = styled.div`
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;

  h1,
  h2,
  p {
    color: #ffffff;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
  }

  h1 {
    font-weight: 700;
    font-size: 50px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .btn-back {
    span {
      color: #dadada !important;
    }
    svg {
      color: #dadada !important;
    }
  }

  .shield {
    position: absolute;
    top: 0;
    right: 0;
  }

  .cube {
    position: absolute;
    bottom: 0;
    right: 60px;
  }

  .image {
    width: 100%;
  }

  .list {
    transition: all 0.3s ease;
  }

  .semi {
    transform: scale(1.1);
  }

  .grow-item {
    transform: scale(1.2);
  }

  .list h3 {
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
  }

  .list p {
    font-weight: 300;
    font-size: 14px;
    color: #cbccce;
  }

  .list a {
    font-weight: 400;
    font-size: 14px;
    color: #505873;
    transition: all 0.2s ease;
  }

  .list a:hover {
    color: ${lighten(0.2, '#505873')};
  }

  .list h3 span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 16px;
  }

  .grad-1 {
    background: linear-gradient(135deg, #fff6b7 0%, #f6416c 100%), #ffffff;
  }

  .grad-2 {
    background: linear-gradient(135deg, #6b73ff 0%, #000dff 100%), #ffffff;
  }

  .grad-3 {
    background: linear-gradient(135deg, #72edf2 0%, #5151e5 100%), #ffffff;
  }

  .grad-4 {
    background: linear-gradient(135deg, #79f1a4 0%, #0e5cad 100%), #ffffff;
  }

  .grad-5 {
    background: linear-gradient(135deg, #fff3b0 0%, #ca26ff 100%), #ffffff;
  }

  .bd-left {
    width: 15px;
    backdrop-filter: blur(10px);
    border-radius: 18px 0px 0px 18px;
  }

  .bg-grad-1 {
    background: linear-gradient(135deg, #fff6b7 0%, #f6416c 100%);
  }

  .bg-grad-2 {
    background: linear-gradient(135deg, #6b73ff 0%, #000dff 100%);
  }

  .bg-grad-3 {
    background: linear-gradient(135deg, #72edf2 0%, #5151e5 100%),
      rgba(32, 25, 112, 0.2);
  }

  .bg-grad-4 {
    background: linear-gradient(135deg, #79f1a4 0%, #0e5cad 100%),
      rgba(32, 25, 112, 0.2);
  }

  .bg-grad-5 {
    background: linear-gradient(135deg, #fff3b0 0%, #ca26ff 100%),
      rgba(32, 25, 112, 0.2);
  }

  .bd-blur {
    background: rgba(123, 115, 225, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 0 18px 18px 0;
    padding: 20px;
    width: 576px;
  }

  .tokens-value {
    margin-left: auto;
  }

  .tokens-value span:nth-child(1) {
    font-weight: 500;
    font-size: 14px;
    color: #cbccce;
  }

  .tokens-value span:nth-child(2) {
    font-weight: 300;
    font-size: 14px;
    color: #cbccce;
  }

  @media screen and (min-width: 1800px) {
    .container {
      max-width: 1520px;
    }

    .bd-blur {
      width: 650px;
    }

    .image {
      width: 90%;
    }
  }
`;
