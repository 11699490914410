import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { convertTypeAcquisitionFromJson } from 'typescript';
import WalletRow from '~/components/WalletRow';

import { Container } from './styles';

interface IStep {
  value: string;
  active: boolean;
  semi?: boolean;
  url?: string;
}

interface ITimelineProps {
  steps: IStep[];
}

const Timeline: React.FC<ITimelineProps> = ({ steps }) => {
  const [stepsData, setStepsData] = useState<IStep[]>([]);

  useEffect(() => {
    const data: IStep[] = steps.map((step) => ({
      value: step.value,
      active: step.active,
      semi: step.semi,
      url: step.value.replace(/\s+/g, '-').replace('!', '').toLowerCase(),
    }));
    setStepsData(data);

    setTimeout(() => {
      document
        .querySelector('.scroll')
        ?.scrollTo({ left: 900, behavior: 'smooth' });
    }, 1000);
  }, [steps]);

  return (
    <Container className="scroll">
      <div className="timeline-box w-100 h-100 d-flex flex-lg-column py-4">
        {stepsData.map((step, index) => (
          <Link
            key={step.value}
            to={`${process.env.PUBLIC_URL}/${step.url}`}
            className={`d-flex flex-column flex-lg-row align-items-center ${
              index !== 0 && index !== steps.length - 1
                ? 'align-items-center'
                : ''
            } ${index === steps.length - 1 ? 'align-items-end' : ''} ${
              step.active ? 'active' : ''
            } ${step.semi ? 'semi' : ''}`}
          >
            <div className="sinalizer d-flex d-lg-block align-items-center">
              <div className={index === 0 ? 'opacity-0' : ''} />
              <div>
                <div />
              </div>
              <div className={index === steps.length - 1 ? 'opacity-0' : ''} />
            </div>
            <p className="mx-2 mt-2 mt-lg-0 mb-0 w-100 w-md-auto">
              {step.value}
            </p>
          </Link>
        ))}
        <WalletRow />
      </div>
    </Container>
  );
};

export default Timeline;
